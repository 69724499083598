import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { filter, takeUntil, take, tap, catchError, map, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import * as fromReducer from '@ptg-reducers';

import { ACTION_COLUMN, Column, ColumnType, Row } from '@ptg-shared/controls/grid';

import { ACTION, DEFAULT_PAGE_SIZE, SORT_TYPE, STATE, SortType } from '@ptg-shared/constance';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import {
  CalculationState,
  removeCalculationBenefitSelector,
  clearRemoveCalculationBenefitAction,
  getRetirementBenefitDownloadDocumentSelector,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  checkRetirementDocumentCanRemoveAction,
  getRetirementBenefitDocumentsAction,
  getRetirementBenefitDocumentSelector,
  getRefundCalculationRecordsAction,
  getRefundCalculationRecordsSelector,
  removeRemoveOverviewDocumentSelector,
  removeOverviewDocumentAction,
  clearCheckRetirementDocumentCanRemoveStateAction,
  clearGetRetirementBenefitDocumentsStateAction,
  clearGetRefundCalculationRecordsStateAction,
  clearRemoveOverviewDocumentStateAction,
  createRetirementBenefitUploadDocumentsAction,
  clearCreateRetirementBenefitUploadDocumentsStateAction,
  editRetirementBenefitDocumentAction,
  clearEditRetirementBenefitDocumentStateAction,
} from '../../store';
import {
  BenefitDocumentsList,
  GetRetirementBenefitRequest,
  RetirementBenefitHistory,
  StepConfiguration,
  GetRefundCalculationRecords,
  GetRefundCalculationRecordsRequest,
  CreateRetirementBenefitUploadDocumentsRequest,
} from '../../services/models';
import { deepClone, downloadFile, showBanner } from '@ptg-shared/utils/common.util';
import { Sort } from '@angular/material/sort';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { CalculationBenefitHistoryStatus, CalculationType } from '../../types/enums';
import { AccidentParticipantsState } from '@ptg-member/features/accident-claims/store/reducers/accident-participants.reducer';
import { BenefitType, SURVIVOR_BANNER_MESSAGE, REFUND_DOCUMENT_TYPE_OPTIONS } from '../../constants';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { Observable, combineLatest, of, timer } from 'rxjs';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { DatePipe } from '@angular/common';
import { checkExistCheckRetirementDocumentCanRemoveSelector } from '../../store/selectors/check-retirement-document-can-remove.selector';
import { MenuItemTitle, MenuItemSubTitle } from '@ptg-member/constants';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import {
  createRetirementBenefitUploadDocumentsSelector,
  editRetirementBenefitDocumentsSelector,
} from '../../store/selectors/retirement-benefit-upload-document.selector';
import { CheckExistsDocumentNameResponse } from '@ptg-employer/models/employer-document.model';
import { USED_FOR_MENU, DOCUMENT_LOCATION } from '@ptg-shared/constance/document-location.const';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
} from 'src/app/admin/features/file/store/actions';

const PAGE_SIZE_CONST = '-ptg-refunds-detail-pageSize';

@Component({
  selector: 'ptg-refunds-detail',
  templateUrl: './refunds-detail.component.html',
  styleUrls: ['./refunds-detail.component.scss'],
})
export class RefundsDetailComponent extends BaseListComponent {
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs;
  settings: Breadcrumb[] = this.getSettings();
  memberId: string = '';
  calculationBenefitId: string = '';

  message = '';
  messageSurvivor = SURVIVOR_BANNER_MESSAGE;
  bannerType: BannerType = BannerType.Hidden;
  bannerTypeSurvivor: BannerType = BannerType.Hidden;

  sortInfo?: Sort;
  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber = FIRST_PAGE;
  sortType = SortType.ASC;
  retirementBenefitDocuments: BenefitDocumentsList[] = [];
  totalBenefitDocuments: number = 0;
  isDocumentLoading?: boolean = false;
  pageHistoryIndex: number = FIRST_PAGE;
  pageHistorySize: number = DEFAULT_PAGE_SIZE;

  totalBenefitHistories: number = 0;
  isHistoryLoading?: boolean = false;
  benefitCalculationHistories: RetirementBenefitHistory[] = [];
  noDataHistories: string = 'No Refund Calculation to Display';
  pageDocumentIndex: number = FIRST_PAGE;
  pageDocumentSize: number = DEFAULT_PAGE_SIZE;

  calculationType = CalculationType.Refund;
  currentFund: any = {};
  stepConfigurations: (StepConfiguration & Row)[] = [];

  removedHistoryId: string = '';
  downloadFileName: string = 'sample.pdf';
  removedDocumentId: string = '';

  isShowGearIcon: boolean = false;
  benefitHistoryLabel: string = '';
  benefitDocumentLabel: string = '';
  isHavingDateOfDeath: boolean = true;

  refundCalculationRecords!: GetRefundCalculationRecords[];
  isInitiateStatus: boolean = false;

  menuItemTitle: string = MenuItemTitle.Refunds;
  menuItemSubTitle: string = MenuItemSubTitle.ReturnsProcessing;

  columnsRefundCalculationRecord: Column[] = [
    {
      name: 'createdDate',
      header: {
        title: 'Created Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'noOfSelectedYear',
      header: {
        title: 'No. Selected Year',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'netRefund',
      header: {
        title: 'Net Refund',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'displayStatus',
      header: {
        title: 'Status',
      },
      truncate: true,
      sortable: true,
      cell: (row) => {
        return CalculationBenefitHistoryStatus[row.status];
      },
    },
  ];

  columnsBenefitDocuments: Column[] = [
    {
      name: 'uploadDate',
      header: {
        title: 'Upload Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      truncate: true,
      sortable: false,
    },
    {
      name: 'documentTypeName',
      header: {
        title: 'Document Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      truncate: true,
    },
  ];

  constructor(
    public layoutService: LayoutService,
    public route: ActivatedRoute,
    private router: Router,
    private calculationStore: Store<CalculationState>,
    private documentStore: Store<DocumentsState>,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private store: Store<AccidentParticipantsState>,
    private retirementBenefitDialogService: RetirementBenefitDialogService,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentFundAndRouteData();
    this.showBannerFunction();

    // store selector functions
    this.registerGetRefundDetailDocumentData();
    this.selectorDownloadFile();
    this.selectorCheckDocumentCanRemove();
    this.selectorRemoveDocument();
    this.selectorRefundCalculation();
    this.uploadDocumentSelector();
    this.registerEditDocumentSelector();
  }

  showBannerFunction() {
    this.calculationStore
      .select(removeCalculationBenefitSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.store.dispatch(clearRemoveCalculationBenefitAction());
          // Keep 'Benefit' is common message
          showBanner.call(this, state.state, 'Benefit', state.action);
          if (state.action === ACTION.REMOVE && state?.state === STATE.SUCCESS) {
            if (this.pageHistoryIndex !== 1 && this.benefitCalculationHistories.length <= 1) {
              this.pageHistoryIndex = this.pageHistoryIndex - 1;
            }
            this.getRefundCalculation();
          }
        }
      });
  }

  /**
   * Return the breadcrumb data
   */
  private get getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: 'Overview',
        url: `/member/refunds-overview/${this.memberId}`,
      },
      {
        name: `Refund Detail`,
      },
    ];
  }

  getCurrentFundAndRouteData() {
    combineLatest([this.route.params, this.store.select(fromReducer.selectCurrentFundState)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, currentFund]) => {
        this.defaultPageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
        this.currentFund = currentFund;
        this.memberId = params.memberId;
        this.calculationBenefitId = params.calculationBenefitId;
        this.listBreadcrumbs = this.getBreadcrumbs;
        this.setPageSize();
        this.settings = this.getSettings();
        this.getRefundCalculation();
        this.getRefundDetailDocumentData();
      });
  }

  setPageSize() {
    const benefitHistoryPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + BenefitType.BenefitHistory + PAGE_SIZE_CONST,
      ),
    );
    const benefitDocumentPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + BenefitType.BenefitDocument + PAGE_SIZE_CONST,
      ),
    );
    this.pageHistorySize =
      benefitHistoryPageSizeInSession === 0 ? this.defaultPageSize : benefitHistoryPageSizeInSession;
    this.pageDocumentSize =
      benefitDocumentPageSizeInSession === 0 ? this.defaultPageSize : benefitDocumentPageSizeInSession;
  }

  getSettings() {
    if (!this.isShowGearIcon) {
      return [];
    }
    return [
      {
        name: 'Input/Output',
        url: `/member/calculation/retirement-benefit-input-outputs/${this.memberId}`,
      },
      {
        name: 'Calculation List',
        url: `/member/calculation/retirement-benefit-calculation-list/${this.memberId}`,
      },
      {
        name: 'Sub - Header',
        url: '',
      },
      {
        name: 'Step Configuration',
        url: `/member/calculation/step-configuration/${this.memberId}`,
      },
      {
        name: 'Calculation Parameter',
        url: `/member/calculation/calculation-parameter-configuration/${this.memberId}`,
      },
      {
        name: 'Benefit Calculation',
        url: `/member/calculation/benefit-calculation-configuration/${this.memberId}`,
      },
      {
        name: 'Exception Configuration',
        url: `/member/calculation/exception-configuration/${this.memberId}`,
      },
    ];
  }

  getRefundCalculation() {
    const request: GetRefundCalculationRecordsRequest = {
      memberId: this.memberId,
      calculationBenefitId: this.calculationBenefitId,
    };
    this.calculationStore.dispatch(getRefundCalculationRecordsAction({ request }));
  }

  setBenefitRequest(sortParam: { name: string; direction: number }, pageSize: number, pageNumber: number) {
    let sortType = sortParam.direction;
    let sortNames = sortParam.name;

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    const requestDocument: GetRetirementBenefitRequest = {
      pageNumber,
      pageSize,
      sortNames,
      sortType,
    };

    return requestDocument;
  }

  selectorRefundCalculation() {
    this.calculationStore
      .select(getRefundCalculationRecordsSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (!response?.isLoading && response?.success && response?.payload) {
          this.calculationStore.dispatch(clearGetRefundCalculationRecordsStateAction());
          this.refundCalculationRecords = deepClone(response?.payload).map((refund) => {
            return {
              ...refund,
              createdDate: getDateString(refund.createdDate),
            };
          });
          this.isInitiateStatus = this.refundCalculationRecords[0].status === CalculationBenefitHistoryStatus.Initiated;
        }
      });
  }

  getRefundDetailDocumentData() {
    const requestDocument: GetRetirementBenefitRequest = this.setBenefitRequest(
      { name: '', direction: SortType.DESC },
      this.pageDocumentSize,
      this.pageDocumentIndex,
    );
    this.calculationStore.dispatch(
      getRetirementBenefitDocumentsAction({
        request: requestDocument,
        memberId: this.memberId,
        calculationType: this.calculationType,
        calculationBenefitId: this.calculationBenefitId,
      }),
    );
  }

  registerGetRefundDetailDocumentData() {
    this.calculationStore
      .select(getRetirementBenefitDocumentSelector)
      .pipe(
        tap((res) => (this.isDocumentLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetRetirementBenefitDocumentsStateAction());

        this.totalBenefitDocuments = response?.total ?? 0;
        this.retirementBenefitDocuments = (response?.payload ?? []).map((paramMapping: any) => {
          return {
            ...paramMapping,
            uploadDate: getDateString(paramMapping?.uploadDate),
            documentName: paramMapping?.documentName,
            fileName: paramMapping?.fileName,
            documentTypeName: paramMapping?.documentTypeName,
            documentType: paramMapping?.documentType,
            id: paramMapping?.id,
          };
        });
      });
  }

  onChangeSortHistories(event: Sort) {
    if (event.active === 'displayStatus') {
      this.sortInfo = {
        active: 'status',
        direction: event.direction,
      };
    } else if (event.active === 'benefitBeginDate') {
      this.sortInfo = {
        active: 'calcAsOfDate',
        direction: event.direction,
      };
    } else {
      this.sortInfo = event;
    }
    this.getRefundCalculation();
  }

  onChangeHistoriesPage(event: PageEvent) {
    super.onChangePage(event);
    this.pageHistorySize = event.pageSize;
    this.pageHistoryIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + BenefitType.BenefitHistory + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getRefundCalculation();
  }

  openBenefitCalculationDetail(row: GetRefundCalculationRecords) {
    const { calculationBenefitId, id } = row;

    this.router.navigateByUrl(
      `member/refunds-overview/${this.memberId}/calculation-detail/${calculationBenefitId}/${id}`,
    );
  }

  onUploadDocuments() {
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    }
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(''),
      defaultShowOnOverview: true
    }
    const specificMenuData = {
      shouldUseCommonBreadcrumbs: true,
      usedForMenu: USED_FOR_MENU.REFUND_DETAIL,
      documentTypeOptionList: deepClone(REFUND_DOCUMENT_TYPE_OPTIONS),
      acceptFile: '.pdf',
      checkPattern: new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i'),
    }

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
        specificMenuData,
      }
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const files = objectUpload.files;

        const request: CreateRetirementBenefitUploadDocumentsRequest = {
          memberId: this.memberId,
          calculationType: this.calculationType,
          calculationBenefitId: this.calculationBenefitId,
          file: [
            {
              file: files[0],
              uploadDate: deepClone(objectUpload.uploadDate),
              documentName: objectUpload.documentName,
              calculationDocumentType: objectUpload.type,
            },
          ],
          fileName: files[0]?.name,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentLocationTitle: DOCUMENT_LOCATION.REFUND,
          documentLocationRouter: `/member/refunds-overview/${this.memberId}/detail/${this.calculationBenefitId}`,
          documentDescription: objectUpload.description,
        };
        this.calculationStore.dispatch(
          createRetirementBenefitUploadDocumentsAction({ request })
        );
      }
    });
  }

  onChangeSortDocuments(event: Sort) {
    if (event.active === 'documentType') {
      this.sortInfo = {
        active: 'documentTypeName',
        direction: event.direction,
      };
    } else if (event.active === 'uploadDate') {
      this.sortInfo = {
        active: 'uploadDateNoTime',
        direction: event.direction,
      };
    } else {
      this.sortInfo = event;
    }
    this.getRefundDetailDocumentData();
  }

  onChangeDocumentsPage(event: PageEvent) {
    super.onChangePage(event);
    this.pageDocumentSize = event.pageSize;
    this.pageDocumentIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + BenefitType.BenefitDocument + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getRefundDetailDocumentData();
  }

  onDownloadFile(row: any) {
    if (!row) {
      return;
    }
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
    );
  }

  selectorDownloadFile() {
    this.calculationStore
      .select(getRetirementBenefitDownloadDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((downloadDocument) => {
        if (!downloadDocument?.isLoading && downloadDocument?.success) {
          let blobFile = downloadDocument?.payload ? downloadDocument?.payload[0] : new Blob();
          downloadFile.call(this, blobFile, this.downloadFileName);
          this.calculationStore.dispatch(clearGetRetirementBenefitDownloadDocumentStateAction());
        }
      });
  }

  onEditDocument(file: any) {
    // Open form upload
    let rowData = deepClone(file);
    rowData['tagDescriptionsList'] = rowData?.tags;
    rowData['showOnOverview'] = rowData?.showOnOverview === true ? 'Yes' : 'No';
    rowData['documentLocation'] = DOCUMENT_LOCATION.REFUND;
    rowData['documentLocationRouter'] = `/member/refunds-overview/${this.memberId}/detail/${this.calculationBenefitId}`;
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    }
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(rowData?.fileId ?? ''),
    }
    const specificMenuData = {
      usedForMenu: USED_FOR_MENU.EDIT_COMMON_DOCUMENT,
      documentTypeOptionList: deepClone(REFUND_DOCUMENT_TYPE_OPTIONS),
    }
    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: rowData,
        infoForm,
        specificMenuData,
      }
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const request = {
          documentName: objectUpload.documentName,
          tags: objectUpload.tags,
          documentDescription: objectUpload.description,
          showOnOverview: objectUpload.showOnOverview,
          documentType: objectUpload.type,
        }

        this.store.dispatch(editRetirementBenefitDocumentAction({
          memberId: this.memberId,
          calculationBenefitDocumentId: rowData?.id,
          request: request,
        }));
      }
    });
  }

  registerEditDocumentSelector() {
    this.store.pipe(select(editRetirementBenefitDocumentsSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        if (data?.state?.state === STATE.FAIL && data?.errorMsg) {
          showBanner.call(this, data?.state?.state, '', '', { customMessage: data?.errorMsg });
        } else if (data?.state?.state === STATE.FAIL && !data?.errorMsg) {
          showBanner.call(this, data?.state?.state, 'Refund Document', data?.state?.action);
        } else {
          showBanner.call(this, data?.state?.state, 'Refund Document', data?.state?.action);
          this.getRefundDetailDocumentData();
        }
        this.store.dispatch(clearEditRetirementBenefitDocumentStateAction());
      }
    });
  }

  onRemoveDocument(row: any) {
    this.removedDocumentId = row.id;
    const request = {
      calculationBenefitDocumentId: this.removedDocumentId,
      calculationBenefitId: this.calculationBenefitId,
      calculationType: this.calculationType,
    };
    this.calculationStore.dispatch(checkRetirementDocumentCanRemoveAction({ request }));
  }

  removeDocumentEvent() {
    const ALERT_MESSAGE =
      'After removed, this document cannot be used for Refund Initiation. Are you sure you want to proceed?';
    const confirmRemove = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.Destruct,
        cancelButtonTitle: 'Cancel',
      },
    });

    confirmRemove
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          showBanner.call(this, STATE.SUCCESS, 'Document', ACTION.REMOVE);
          this.calculationStore.dispatch(removeOverviewDocumentAction({ id: this.removedDocumentId }));
        }
      });
  }

  selectorCheckDocumentCanRemove() {
    this.calculationStore
      .select(checkExistCheckRetirementDocumentCanRemoveSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const canRemoveDoc = response?.payload?.isValid;
        this.calculationStore.dispatch(clearCheckRetirementDocumentCanRemoveStateAction());
        if (canRemoveDoc) {
          this.removeDocumentEvent();
        } else {
          const calculationBenefitStatus = response?.payload?.calculationBenefitStatus;
          this.removeDocumentErrorMessage(calculationBenefitStatus);
        }
      });
  }

  selectorRemoveDocument() {
    this.calculationStore
      .select(removeRemoveOverviewDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response && !response?.isLoading) {
          this.calculationStore.dispatch(clearRemoveOverviewDocumentStateAction());
          if (response?.success) {
            this.getRefundDetailDocumentData();
          } else {
            this.bannerType = BannerType.Fail;
            showBanner.call(this, this.bannerType, '', ACTION.REMOVE);
          }
        }
      });
  }

  private removeDocumentErrorMessage(calculationBenefitStatus?: number) {
    if (typeof calculationBenefitStatus !== 'number') return;
    let alertMessage = '';
    switch (calculationBenefitStatus) {
      case CalculationBenefitHistoryStatus.Initiated:
      case CalculationBenefitHistoryStatus['Pending Approval']:
        alertMessage =
          'This document has been used for a Refund Calculation Benefit and can only be removed in Calculation Details screen.';
        break;
      case CalculationBenefitHistoryStatus.Approved:
        alertMessage = 'This document has been used for an approved Refund Calculation Benefit and cannot be removed.';
        break;
      default:
        break;
    }
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: alertMessage,
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        title: 'Error',
        hideConfirmButton: true,
      },
    });
  }

  validateExistDocumentNameExist(documentId?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.retirementBenefitDialogService
              .checkExits({
                memberId: this.memberId || '',
                name: control.value.toString(),
                fileId: documentId,
              })
              .pipe(
                map((response: CheckExistsDocumentNameResponse) => {
                  if (response?.exists) {
                    return { errMsgDocumentName: 'Document Name already exists.' };
                  }
                  return null;
                }),
                catchError(({ error }) => {
                  return of({ errMsgDocumentName: error?.errorMessage });
                }),
              ),
        ),
      );
    };
  }

  uploadDocumentSelector() {
    this.calculationStore
      .select(createRetirementBenefitUploadDocumentsSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (retirementBenefitDocument && !retirementBenefitDocument?.isLoading) {
          showBanner.call(this, retirementBenefitDocument?.success ? STATE.SUCCESS : STATE.FAIL, 'Document', ACTION.UPLOAD);

          if (retirementBenefitDocument?.success) {
            this.getRefundDetailDocumentData();
          }
          this.calculationStore.dispatch(clearCreateRetirementBenefitUploadDocumentsStateAction())
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.calculationStore.dispatch(clearGetRefundCalculationRecordsStateAction());
    this.calculationStore.dispatch(clearGetRetirementBenefitDocumentsStateAction());
  }
}
