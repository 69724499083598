import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { finalize, takeUntil } from 'rxjs/operators';

import * as fromReducer from '@ptg-reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { LayoutActions } from '@ptg-shared/layout/actions';

import { FixedProperty, GetInitiationPropertiesResponse, InitiationProperty } from '@ptg-entity-management/services/models';
import { MemberState } from '@ptg-member/store/reducers';
import { clearCreateMemberStateAction, clearUpdateMemberCardStateAction, createMemberAction, updateMemberCardAction } from '@ptg-member/store/actions';
import { createMemberSelector, updateMemberCardSelector } from '@ptg-member/store/selectors/member.selector';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { MemberService } from '@ptg-member/services/member.service';
import { PERSON_BASE_PROPERTIES } from '@ptg-entity-management/constants';
import { CheckMemberExistsBySsnRequest, ValidateSSNAndServiceHistoryRequest } from '@ptg-member/types/models';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { FundType } from '@ptg-shared/types/enums';
import { DatePipe } from '@angular/common';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { DateTime } from 'luxon';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { OVERLAP_SERVICE_HISTORY_ERROR_MESSAGE, SERVICE_HISTORY_PROPERTIES } from '@ptg-member/constants';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
@Component({
  selector: 'ptg-upsert-member',
  templateUrl: './upsert-member.component.html',
  styleUrls: ['./upsert-member.component.scss'],
})
export class UpsertMemberComponent extends BaseComponent {
  FundType = FundType;
  title: string = '';
  entityId: string = '';
  properties: InitiationProperty[] = [];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isAddSuccess = false;
  isSubmitting: boolean = false;
  memberId: string = '';
  seviceHistoryFixedProperties: FixedProperty[] = [
    {
      key: SERVICE_HISTORY_PROPERTIES.Municipality.Key,
      label: SERVICE_HISTORY_PROPERTIES.Municipality.Label,
      type: EntityPropertyType.Lookup,
      value: this.layoutService.currentEmployer$?.value?.id,
      isDisabled: true,
      options: [
        {
          displayValue: this.layoutService.currentEmployer$?.value?.name,
          value: this.layoutService.currentEmployer$?.value?.id,
        }
      ],
      class: 'w-100',
    },
    {
      key: SERVICE_HISTORY_PROPERTIES.BeginDate.Key,
      label: SERVICE_HISTORY_PROPERTIES.BeginDate.Label,
      type: EntityPropertyType.Date,
      isRequired: true,
      class: 'w-50',
    },
    {
      key: SERVICE_HISTORY_PROPERTIES.EndDate.Key,
      label: SERVICE_HISTORY_PROPERTIES.EndDate.Label,
      type: EntityPropertyType.Date,
      class: 'w-50',
    },
  ];
  municipalityId?: string = this.layoutService.currentEmployer$?.value?.id;
  municipalityName?: string = this.layoutService.currentEmployer$?.value?.name;

  constructor(
    private memberStore: Store<MemberState>,
    public dialogRef: MatDialogRef<UpsertMemberComponent>,
    private dialog: MatDialog,
    private store: Store<fromReducer.State>,
    @Inject(MAT_DIALOG_DATA) public data: GetInitiationPropertiesResponse,
    private memberService: MemberService,
    public layoutService: LayoutService,
    public auth0Service: Auth0Service,
  ) {
    super();
  }

  ngOnInit(): void {
    this.title = this.data.entityLabel;
    this.entityId = this.data.entityId;
    this.properties = this.data.properties;

    super.ngOnInit();
    this._createMemberSelector();
    this._updateMemberSelector();
    this.getOverviewURL();

    const beginDateProperty = this.seviceHistoryFixedProperties.find(p => p.key === SERVICE_HISTORY_PROPERTIES.BeginDate.Key);
    beginDateProperty!.customValidators = this.beginDateCustomValidator();
    const endDateProperty = this.seviceHistoryFixedProperties.find(p => p.key === SERVICE_HISTORY_PROPERTIES.EndDate.Key);
    endDateProperty!.customValidators = this.endDateCustomValidator();
  }

  getOverviewURL() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        let url = '';
        if (state?.menu && state?.menu.length > 0 && this.isAddSuccess) {
          this.isAddSuccess = false;
          const overviewView = state.memberNavigationList as any;
          if ((state.memberNavigationList as any)?.isOverviewDetailView) {
            url = `/member/detail-view/true/${overviewView.id}/${overviewView.overviewViewId}/${state.memberId}`;
          } else {
            url = `/member/summary-view/true/${overviewView.id}/${overviewView.overviewViewId}/${state.memberId}`;
          }
          this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
          this.store.dispatch(
            LayoutActions.selectTab({
              tab: DisplayedTabName.IndividualParticipant,
              url,
            }),
          );

          this.dialogRef.close();
        } else if (state?.isReloading && this.isAddSuccess) {
          this.isAddSuccess = false;
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: 'Screen not yet configured. Please contact your administrator for support.',
              title: 'Warning',
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            },
          });
          this.dialogRef.close(true);
        }
      });
  }

  private _createMemberSelector(): void {
    this.memberStore
      .pipe(
        select(createMemberSelector),
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isSubmitting = false)),
      )
      .subscribe((el) => {
        if (el) {
          this.layoutService.showLoading = !!el?.isLoading;
          if (el?.success) {
            this.isAddSuccess = true;
            this.store.dispatch(LayoutActions.profileNavigationItemRequest({ memberId: el.payload?.memberId || '' }));
          } else if (el.success === false) {
            this.bannerType = BannerType.Fail;
            this.message = `Error occurred adding ${this.title}. Please try again.`;
          }
          this.memberStore.dispatch(clearCreateMemberStateAction());
        }
      });
  }

  private _updateMemberSelector(): void {
    this.memberStore.pipe(
      select(updateMemberCardSelector),
      takeUntil(this.unsubscribe$),
      finalize(() => (this.isSubmitting = false)),
    ).subscribe((state) => {
      if (state) {
        this.layoutService.showLoading = !!state?.isLoading;
        if (state?.success) {
          this.isAddSuccess = true;
          this.store.dispatch(LayoutActions.profileNavigationItemRequest({ memberId: this.memberId || '' }));
        } else if (state?.success === false) {
          this.bannerType = BannerType.Fail;
          this.message = `Error occurred adding ${this.title}. Please try again.`;
        }
        this.memberStore.dispatch(clearUpdateMemberCardStateAction());
      }
    });
  }

  onSubmit(request: any): void {
    if (this.layoutService.fundType === FundType.BVFF && this.auth0Service.isMunicipalityPortal$?.value) {
      this.validateSSNAndServiceHistoryAndSaveData(request);
    } else {
      this.saveData(request);
    }
  }

  validateSSNAndServiceHistoryAndSaveData(request: any) {
    const identityPropertyIds = [
      PERSON_BASE_PROPERTIES.SSN,
      PERSON_BASE_PROPERTIES.Name,
      PERSON_BASE_PROPERTIES.DateOfBirth,
    ];
    const isNeedValidateSsn = identityPropertyIds.every(propId => this.properties.some(p => p.entityPropertyId.toLowerCase() === propId.toLowerCase()));

    const datepipe = new DatePipe('en-US');
    const ssnValue = request?.entityPropertyValues?.find((p: any) => p.entityPropertyId.toLowerCase() === PERSON_BASE_PROPERTIES.SSN.toLowerCase())?.value;
    const nameValue = request?.entityPropertyValues?.find((p: any) => p.entityPropertyId.toLowerCase() === PERSON_BASE_PROPERTIES.Name.toLowerCase())?.value;
    const nameObj = nameValue ? JSON.parse(nameValue) : null;
    const dateOfBirthValue = request?.entityPropertyValues?.find((p: any) => p.entityPropertyId.toLowerCase() === PERSON_BASE_PROPERTIES.DateOfBirth.toLowerCase())?.rawValue;
    
    const beginDate= request?.fixedPropertyValues?.find((p: any) => p.key === SERVICE_HISTORY_PROPERTIES.BeginDate.Key)?.value;
    const serviceHistoryBeginDate = beginDate ? datepipe.transform(beginDate, 'yyyy-MM-dd') : null;
    const endDate = request?.fixedPropertyValues?.find((p: any) => p.key === SERVICE_HISTORY_PROPERTIES.EndDate.Key)?.value;
    const serviceHistoryEndDate = endDate ? datepipe.transform(endDate, 'yyyy-MM-dd') : null;

    const validateRequest: ValidateSSNAndServiceHistoryRequest = {
      municipalityId: this.municipalityId,
      isNeedValidateSsn,
      ssn: ssnValue,
      firstName: nameObj?.First,
      lastName: nameObj?.Last,
      dateOfBirth: dateOfBirthValue ? datepipe.transform(dateOfBirthValue, 'MM/dd/yyyy') : null,
      serviceHistoryBeginDate,
      serviceHistoryEndDate,
    }

    request = {
      ...request,
      serviceHistoryData: {
        municipalityId: this.municipalityId,
        beginDate: serviceHistoryBeginDate,
        endDate: serviceHistoryEndDate,
      }
    }

    this.memberService.validateSSNAndServiceHistoryBeforeAddMember(validateRequest).subscribe(res => {
      // display popup error
      if (res.isError && res.message) {
        this.showErrorPopup(res.message);
        return;
      }

      // display inline error
      if (res.isError && res.targetFieldsError?.length) {
        this.seviceHistoryFixedProperties.forEach(p => {
          p.customErrorMessage = '';
          p.isNoNeedResetValidate = false;
          const fieldError = res.targetFieldsError.find(f => f.propertyKey === p.key);
          if (fieldError) {
            p.customErrorMessage = fieldError.errorMessage?.replace('{0}', this.municipalityName ?? '');
            p.isNoNeedResetValidate = fieldError.isNoNeedResetValidate;
          }
        });
        this.seviceHistoryFixedProperties = [...this.seviceHistoryFixedProperties];
        return;
      }

      if (!res.isError && !res.isFullyMatched && !res.isNotFullyMatched) {
        this.saveData(request);
        return;
      }

      if (res.isNotFullyMatched && !res.existedMemberId) {
        this.showWConfirmPopupAndSaveData(request);
        return;
      }

      if (res.isFullyMatched && res.existedMemberId) {
        this.memberId = res.existedMemberId;
        const confirmDialogRef = this.showConfirmPopup();
        confirmDialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.memberStore.dispatch(
              updateMemberCardAction({
                memberId: res.existedMemberId,
                cardName: '',
                request,
              }),
            );
          }
        });
      }
    });
  }

  saveData(request: any) {
    this.isSubmitting = true;
    this.memberStore.dispatch(
      createMemberAction({
        request,
      }),
    );
  }

  showWConfirmPopupAndSaveData(request: any) {
    const confirmDialogRef = this.showConfirmPopup();
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.saveData(request);
      }
    });
  }

  showConfirmPopup() {
    return this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'Confirmation',
          text: 'Are you sure you want to add this New Member?',
          type: ConfirmType.ConfirmSave,
          saveButtonTitle: 'Yes',
          cancelButtonTitle: 'Cancel',
          hideSaveAsButton: true,
          hideConfirmButton: true,
        },
      }
    );
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  private showErrorPopup(message: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Warning',
        type: ConfirmType.Warning,
        text: message,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
        iconConfig: {
          icon: 'warning',
          style: { color: '#FD8A03', fontSize: '40px' }
        },
      },
    });
  }

  private beginDateCustomValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const beginDateFormControl = control?.parent?.get(SERVICE_HISTORY_PROPERTIES.BeginDate.Key) as FormControl;
      const endDateFormControl = control?.parent?.get(SERVICE_HISTORY_PROPERTIES.EndDate.Key) as FormControl;
      if (!beginDateFormControl || !endDateFormControl) {
        return null;
      }

      const currentDate = DateTime.now().startOf('day');
      const beginDate = beginDateFormControl.value?.startOf('day');
      const endDate = endDateFormControl.value?.startOf('day');

      if (beginDate > currentDate) {
        return { customErrorMessage: `${ SERVICE_HISTORY_PROPERTIES.BeginDate.Label } must not be a future date.` };
      }

      if (endDate && beginDate > endDate) {
        return { customErrorMessage: `Begin Date must be earlier or equal to End Date.` };
      }

      return null;
    };
  }

  private endDateCustomValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const beginDateFormControl = control?.parent?.get(SERVICE_HISTORY_PROPERTIES.BeginDate.Key) as FormControl;
      const endDateFormControl = control?.parent?.get(SERVICE_HISTORY_PROPERTIES.EndDate.Key) as FormControl;
      if (!beginDateFormControl || !endDateFormControl) {
        return null;
      }

      const currentDate = DateTime.now().startOf('day');
      const beginDate = beginDateFormControl.value?.startOf('day');
      const endDate = endDateFormControl.value?.startOf('day');

      if (endDate > currentDate) {
        return { customErrorMessage: `${ SERVICE_HISTORY_PROPERTIES.EndDate.Label } must not be a future date.` };
      }

      if (beginDate && endDate && beginDate > endDate) {
        return { customErrorMessage: `Begin Date must be earlier or equal to End Date.` };
      }

      return null;
    };
  }

}
