import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MemberNavigationList } from '@ptg-member/types/models';
import { ProfileNavigationConfigurationService } from '@ptg-member/services/profile-navigation-configuration.service';

import { LayoutActions } from '../actions';
import { BulkContentItem } from '../models/bulk-content-item.model';
import { AppContentService } from '../services/app-content.service';
import { BulkContentServiceService } from '../services/bulk-content-service.service';
import { ParticipantReportService } from '@ptg-member/services/participant-report.service';
import { EmployerDetail } from '../models/layout.model';
import { getEmployerInfoForHeaderAction, getEmployerInfoForHeaderSuccessAction, getEmployerInfoForHeaderFailureAction } from '../actions/layout.actions';


@Injectable()
export class LayoutEffects {
  constructor(
    private actions$: Actions,
    private bulkContentService: BulkContentServiceService,
    private appContentService: AppContentService,
    private profileNavigationService: ProfileNavigationConfigurationService,
    private participantReportService: ParticipantReportService,
  ) {}

  getBulkContentItemRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.getBulkContentItemRequest),
      switchMap(() => {
        return this.bulkContentService.getMenuItems().pipe(
          map((items: BulkContentItem[]) => {
            return LayoutActions.getBulkContentItemRequestSuccess({ items });
          }),
          catchError((err) => {
            return of(
              LayoutActions.getBulkContentItemRequestFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  getAppContentItemRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.getAppContentItemRequest),
      switchMap(() => {
        return this.appContentService.getMenuItems().pipe(
          map((items: any[]) => {
            return LayoutActions.getAppContentItemRequestSuccess({ items });
          }),
          catchError((error) => {
            return of(LayoutActions.getAppContentItemRequestFailure({ error }));
          })
        );
      })
    )
  );

  getReportCategorySidebar$ = createEffect(() =>
  this.actions$.pipe(
    ofType(LayoutActions.getReportCategorySidebar),
    switchMap(() => {
      return this.participantReportService.getReportCategorySideBar().pipe(
        map((res) => {
          return LayoutActions.getReportCategorySidebarSuccess({ res : res });
        }),
        catchError((error) => {
          return of(LayoutActions.getReportCategorySidebarFailure({ error }));
        })
      );
    })
  )
);

  getProfileNavigationItemRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.profileNavigationItemRequest),
      switchMap(({ memberId, entityReferenceLinkedId, isHyperlink }) => {
        return this.profileNavigationService
          .getMemberNavigationList(memberId, entityReferenceLinkedId)
          .pipe(
            map((memberNavigationList: MemberNavigationList) => {
              return LayoutActions.profileNavigationItemSuccess({
                memberNavigationList,
                memberId,
                isHyperlink
              });
            }),
            catchError((err) => {
              return of(
                LayoutActions.profileNavigationItemFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  getProfileRelatedPersonItemRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.relatedPersonNavigationItemRequest),
      switchMap(({ memberId, isRelatedPerson }) => {
        return this.profileNavigationService
          .getRelatedPersonNavigationList(memberId)
          .pipe(
            map((memberNavigationList: MemberNavigationList) => {
              return LayoutActions.profileNavigationItemSuccess({
                memberNavigationList,
                memberId,
                isRelatedPerson
              });
            }),
            catchError((err) => {
              return of(
                LayoutActions.profileNavigationItemFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  getEmployerInfoForPostCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEmployerInfoForHeaderAction),
      switchMap(({ employerId }) =>
        this.appContentService.getEmployerInfoForHeader(employerId).pipe(
          map((response: EmployerDetail) => getEmployerInfoForHeaderSuccessAction({ response })),
          catchError((error) => of(getEmployerInfoForHeaderFailureAction({ error })))
        )
      )
    )
  );
}
