import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  EntityListConfig,
  EntityListViewConfigurations,
  EntityPropertyConfigs,
} from './models/entity-list-view-configuration.model';

@Injectable()
export class EntityListViewConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getEntityPropertyList(entityId: string, screenId?: string): Observable<EntityPropertyConfigs> {    
    let params =  new HttpParams();
    if(screenId){
      params = params.append('screenId', screenId);
    }
    return this.httpClient.get<EntityPropertyConfigs>(
      `${environment.apiUrl}/entity/entities/${entityId}/properties/list-view-configuration`,
      {params}
    );
  }

  getParticipantListConfiguration(entityId: string): Observable<EntityListConfig> {
    return this.httpClient.get<EntityListConfig>(
      `${environment.apiUrl}/entity/entities/${entityId}/list-view-configuration`
    );
  }

  setParticipantListConfiguration(entityId: string, body: EntityListViewConfigurations) {
    return this.httpClient.post<EntityListViewConfigurations>(
      `${environment.apiUrl}/entity/entities/${entityId}/list-view-configuration`,
      body
    );
  }
}
