import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { Option } from '@ptg-shared/controls/select/select.component';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BaseComponent } from '@ptg-shared/components';

import { UpdateBenefitRequest } from '../../types/models/defined-benefits.model';
import { MetadataService } from '../../services/metadata.service';
import * as BenefitTypeAction from '../../store/actions/defined-benefits.actions';

import * as fromMemberBenefit from '../../store/reducers';

@Component({
  selector: 'ptg-edit-benefit',
  templateUrl: './edit-benefit.component.html',
  styleUrls: ['./edit-benefit.component.scss'],
})
export class EditBenefitComponent extends BaseComponent {
  editForm?: FormGroup;
  lookupTables: Option[] = [];
  qdroBenefitTypes: Option[] = [];
  qdroMetadatas: Option[] = [];
  qdroBeneditDefaultValue: string = '';
  qdroMetadataDefaultValue: string = '';

  get amountCtrl() {
    return this.editForm?.get('minimumAmount') as FormControl;
  }
  get lookupTableIdCtrl() {
    return this.editForm?.get('lookupTableId') as FormControl;
  }
  get qdroBenefitTypeIdCtrl() {
    return this.editForm?.get('qdroBenefitTypeId') as FormControl;
  }
  get qdroSectionKeyCtrl() {
    return this.editForm?.get('qdroSectionKey') as FormControl;
  }
  get isActiveQDROCtrl() {
    return this.editForm?.get('isActiveQDRO') as FormControl;
  }

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditBenefitComponent>,
    public router: Router,
    public metadataService: MetadataService,
    private store: Store<fromMemberBenefit.MemberState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.store
      .pipe(
        select(fromMemberBenefit.selectGetQDROBenefitTypesState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (!state?.isLoading && state?.success && state?.payload?.length) {
          this.qdroBenefitTypes = state.payload.map((item) => ({
            value: item.id,
            displayValue: item.name,
          }));
        }
      });

    this.store
      .pipe(
        select(fromMemberBenefit.selectGetLookupTablesState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (!state?.isLoading && state?.success && state?.payload?.length) {
          //edit
          if (this.data?.benefit) {
            this.lookupTables = state.payload
              .filter(
                (item) =>
                  item.isActive || item.id == this.data.benefit.lookupTableId
              )
              .map((item) => ({
                value: item.id,
                displayValue: item.name,
              }));
          }
          //create
          else {
            this.lookupTables = state.payload
              .filter((item) => item.isActive)
              .map((item) => ({
                value: item.id,
                displayValue: item.name,
              }));
          }
        }
      });

    this.store
      .pipe(
        select(fromMemberBenefit.selectGetQDROMetadatasState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (!state?.isLoading && state?.success && state?.payload?.length) {
          this.qdroMetadatas = state.payload.map((item) => ({
            value: item.key,
            displayValue: item.name,
          }));
        }
      });

    this.store.dispatch(BenefitTypeAction.getQDROBenefitTypesRequest());
    this.store.dispatch(BenefitTypeAction.getLookupTablesRequest());
    this.store.dispatch(BenefitTypeAction.getQDROMetadatasRequest());

    this.initFormGroup();
  }

  initFormGroup(): void {
    this.editForm = new FormGroup({
      minimumAmount: new FormControl(null),
      lookupTableId: new FormControl('', {
        validators: [Validators.required],
      }),
      qdroBenefitTypeId: new FormControl(''),
      qdroSectionKey: new FormControl(''),
      isActiveQDRO: new FormControl(false),
    });

    if (this.data?.benefit) {
      this.editForm.setValue({
        minimumAmount: this.data.benefit.minimumAmount,
        lookupTableId: this.data.benefit.lookupTableId,
        qdroBenefitTypeId: this.data.benefit.qdroBenefitTypeId,
        qdroSectionKey: this.data.benefit.qdroMetadataSection,
        isActiveQDRO: this.data.benefit.isActiveQDRO,
      });
    }
  }

  onSubmit(): void {
    this.editForm?.markAllAsTouched();
    if (!this.editForm?.valid) {
      return;
    }

    const formData = this.editForm?.getRawValue();
    const request: UpdateBenefitRequest = {
      id: this.data?.benefit?.id,
      minimumAmount: formData?.minimumAmount,
      lookupTableId: formData?.lookupTableId,
      isActiveQDRO: formData?.isActiveQDRO,
      qdroBenefitTypeId: formData?.isActiveQDRO
        ? formData?.qdroBenefitTypeId
        : null,
      qdroSectionKey: formData?.isActiveQDRO ? formData?.qdroSectionKey : null,
    };
    this.store.dispatch(BenefitTypeAction.updateBenefit({ request }));
    this.dialogRef.close();
  }

  onChangeToggle(event: boolean): void {
    if (event) {
      this.qdroBenefitTypeIdCtrl?.enable();
      this.qdroSectionKeyCtrl?.enable();
      this.qdroBenefitTypeIdCtrl?.setValidators(
        event ? [Validators.required] : []
      );
      this.qdroSectionKeyCtrl?.setValidators(
        event ? [Validators.required] : []
      );
    } else {
      this.qdroBenefitTypeIdCtrl?.disable();
      this.qdroSectionKeyCtrl?.disable();
      this.qdroBenefitTypeIdCtrl?.clearValidators();
      this.qdroSectionKeyCtrl?.clearValidators();
      if (this.qdroBeneditDefaultValue !== '') {
        this.qdroBenefitTypeIdCtrl?.setValue(this.qdroBeneditDefaultValue);
      } else {
        this.qdroBenefitTypeIdCtrl?.setValue(null);
      }

      if (this.qdroMetadataDefaultValue !== '') {
        this.qdroSectionKeyCtrl?.setValue(this.qdroMetadataDefaultValue);
      } else {
        this.qdroSectionKeyCtrl?.setValue(null);
      }
    }
  }

  onClickCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
