import { PropertyType } from '@ptg-shared/types/enums/entity.enum';
import { DateTime } from 'luxon';
import { SetBenefitDetailProperties } from '../services/models';
import { ComparisonOperator, ComparisonOperatorLabel } from '../types/enums';
import { BenefitDetail } from '../services/models/exception-configuration.model';

export function validationData(data: BenefitDetail[] | undefined, properties: SetBenefitDetailProperties[]) {
  let result = null;
  if (!data || data?.length <= 0) {
    return null;
  }
  const dataLength = data?.length ?? 0;
  for (let idx = 0; idx < dataLength; idx++) {
    let sourceItem = properties.find((it) => it.calculationParamMappingId === data[idx].additionalInfos[0].value);
    if (!sourceItem) {
      sourceItem = {
        propertyLabel: data[idx].additionalInfos[0].name,
        type: data[idx].additionalInfos[0].type ?? PropertyType.Date,
        value: data[idx].additionalInfos[0].valueEx ?? '',
      };
    }
    let targetItem = properties.find((it) => it.calculationParamMappingId === data[idx].additionalInfos[2].value);
    if (!targetItem) {
      targetItem = {
        propertyLabel: data[idx].additionalInfos[2].name,
        type: data[idx].additionalInfos[2].type ?? PropertyType.Date,
        value: data[idx].additionalInfos[2].valueEx ?? '',
      };
    }
    result = compareDataFields(sourceItem, targetItem, data[idx].additionalInfos[1].value);
    if (result) {
      return result;
    }
  }
  return result;
}

export function compareDataFields(
  sourceItem: SetBenefitDetailProperties | undefined,
  targetItem: SetBenefitDetailProperties | undefined,
  operatorType: string,
) {
  if (!sourceItem?.value || !targetItem?.value) {
    return null;
  }
  const sourceLabel = getDisplayFieldName(sourceItem?.propertyLabel ?? sourceItem.label);
  const targetLabel = getDisplayFieldName(targetItem?.propertyLabel ?? targetItem.label);
  const customError = `${sourceLabel} must be ${ComparisonOperatorLabel[+operatorType].toLowerCase()} ${targetLabel}`;
  const sourceItemValue = getPropertyValue(sourceItem?.value, sourceItem?.type)?.toMillis();
  const targetItemValue = getPropertyValue(targetItem?.value, targetItem.type)?.toMillis();
  switch (+operatorType) {
    case ComparisonOperator.EqualTo: {
      if (sourceItemValue !== targetItemValue) {
        return {
          customError,
        };
      }
      break;
    }
    case ComparisonOperator.GreaterThan: {
      if (sourceItemValue <= targetItemValue) {
        return {
          customError,
        };
      }
      break;
    }
    case ComparisonOperator.GreaterThanOrEqual: {
      if (sourceItemValue < targetItemValue) {
        return {
          customError,
        };
      }
      break;
    }
    case ComparisonOperator.LessThan: {
      if (sourceItemValue >= targetItemValue) {
        return {
          customError,
        };
      }
      break;
    }
    case ComparisonOperator.LessThanOrEqual: {
      if (sourceItemValue > targetItemValue) {
        return {
          customError,
        };
      }
      break;
    }
    default: {
      return null;
    }
  }
  return null;
}

export function getPropertyValue(value: any, type: any) {
  if (type === PropertyType.Date || type === 'Date') {
    return DateTime.fromISO(value);
  }
  return value;
}

export function getDisplayFieldName(input: string | undefined): string {
  if (!input) {
    return '';
  }
  const data = input.split('|');
  if (data.length < 2) {
    return input;
  }
  return `${data[1]} (${data[2]})`;
}
