import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import * as fromReducer from 'src/app/reducers';
import { ConfirmType } from 'src/app/shared/constance/confirm-type.const';
import { ERROR_NAME } from 'src/app/shared/constance/value.const';
import { ConfirmPopupComponent } from 'src/app/shared/controls/confirm-popup/confirm-popup.component';
import { InterceptorActions } from 'src/app/shared/layout/actions';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {
  showError$ = new Subject<HttpErrorResponse>();
  dialogRef!: MatDialogRef<ConfirmPopupComponent>

  constructor(
    public dialog: MatDialog,
    private store: Store<fromReducer.State>
  ) {
    this.showError$.subscribe((error) => {
      this.openErrorDialog(error);
    });
  }


  openErrorDialog(error: HttpErrorResponse) {
    const errorMessage = `${(ERROR_NAME[error.status] || 'An unexpected')} error occurred. Please try again later.`;
    const data = {
      text: errorMessage,
      type: ConfirmType.Warning,
      title: 'Error',
      hideConfirmButton: true,
      cancelButtonTitle: 'Cancel'
    };
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.data = data;
      return;
    }
    this.dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(InterceptorActions.setError({ error: undefined }));
    });
  }
}
