import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Option } from '@ptg-shared/controls/select/select.component';
import { Observable, Subject, timer } from 'rxjs';
import { deepClone } from '@ptg-shared/utils/common.util';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BUTTON_LABEL_CLOSE, BUTTON_LABEL_NO, DEFAULT_PAGE_SIZE, SortType } from '@ptg-shared/constance';
import { DocumentsState, TagsState } from 'src/app/admin/features/file/store/reducers';
import { select, Store } from '@ngrx/store';
import {
  clearGenerateParticipantDocumentsStateAction,
  clearGetDocumentDownloadStateAction,
  clearGetGeneratedReportsInformationStateAction,
  clearValidateGenerateAge65LetterStateAction,
  generateParticipantDocumentsAction,
  getDocumentDownloadAction,
  getGeneratedReportsInformationAction,
  getTagListAction,
  validateGenerateAge65LetterAction,
} from 'src/app/admin/features/file/store/actions';
import {
  generateDocumentSelector,
  getGeneratedReportsInformationSelector,
  getTagListSelector,
  selectDowloadMemberDocumentState,
  validateGenerateAge65LetterSelector,
} from 'src/app/admin/features/file/store/selectors';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Align, Column, Row } from '@ptg-shared/controls/grid';
import { Sort } from '@angular/material/sort';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { Router } from '@angular/router';
import { ValidateGenerateAge65LetterResponse } from 'src/app/admin/features/file/services/models/documents.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { DOCUMENT_LOCATION } from '@ptg-shared/constance/document-location.const';
import { FileType, GENERATE_DOCUMENT_STATUS } from 'src/app/admin/features/file/constants';
import { DatePipe } from '@angular/common';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { GenerateDocument } from '@ptg-member/types/models/generate-document.models';
import { DOCUMENT_TEMPLATE } from 'src/app/admin/features/template/constants';

@Component({
  selector: 'ptg-generate-age65-letter',
  templateUrl: './generate-age65-letter.component.html',
  styleUrls: ['./generate-age65-letter.component.scss'],
})

export class GenerateAge65LetterComponent extends BaseListComponent implements OnInit {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: DOCUMENT_TEMPLATE.AGE65_LETTER,
    },
  ];
  isLoadingInfoBanner!: boolean;
  isHideCloseButton!: boolean;

  editForm!: FormGroup;
  minDate = new Date();
  formSubmit$ = new Subject<boolean>();
  filteredByTypingTagOptions!: Observable<Option[]>;
  currentTagsChipValue!: Option;
  currentTagsChipValueList: string[] = [];
  listOptionTag: Option[] = [];
  isTableLoading: boolean = true;
  isGenerateLoading: boolean = false;
  isValidateLoading: boolean = false;
  templateId!: string;
  tableData: any[] = [];
  sortInfo!: Sort;
  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  pageSize: number = 50;
  pageIndex: number = FIRST_PAGE;
  columns: Column[] = [
    {
      name: 'generatedAt',
      header: {
        title: 'Generated at',
      },
      width: '30px',
      sortable: true,
      truncate: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      width: '150px',
      sortable: true,
      truncate: true,
    },
    {
      name: 'thruDate',
      header: {
        title: 'Thru Date',
      },
      width: '100px',
      sortable: true,
      truncate: true,
    },
    {
      name: 'numberOfRecords',
      header: {
        title: 'Number of Records',
      },
      width: '100px',
      sortable: true,
      truncate: true,
      align: Align.Right,
    },
    {
      name: 'generatedBy',
      header: {
        title: 'Generated by',
      },
      width: '100px',
      sortable: true,
      truncate: true,
    },
    {
      name: 'status',
      header: {
        title: 'Status',
      },
      width: '100px',
      sortable: true,
      truncate: true,
    },
  ];
  totalRecords: number | any;
  downloadFileName: string = '';
  validateGenerateReportData?: ValidateGenerateAge65LetterResponse = undefined;

  constructor(
    public layoutService: LayoutService,
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private tagStore: Store<TagsState>,
    private documentStore: Store<DocumentsState>,
  ) {
    super(layoutService);
  }

  get tagsAutoCompleteControl(): FormControl {
    return this.editForm?.get('tags') as FormControl;
  }

  get tagsChipListControl(): FormArray {
    return this.editForm?.get('tagsChipList') as FormArray;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormGroup();
    this.submitForm();
    this.getTagList();
    this.getData();
    this.selectDataTagListSelector();
    this.selectGeneratedReportsInformationSelector();
    this.checkValidateGenerateReportSelector();
    this.selectGenerateDocumentSelector();
    this.selectDownloadMemberDocumentSelector();
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.showGenerateReportConfirmDialog();
      });
  }

  initFormGroup() {
    this.editForm = this.fb.group({
      thruDate: this.fb.control('', { validators: [Validators.required] }),
      tags: this.fb.control(''),
      tagsChipList: this.fb.array([]),
    });
  }

  validateTags(): void {
    if (this.tagsAutoCompleteControl.value) {
      if (
        this.tagsAutoCompleteControl.enabled &&
        typeof this.tagsAutoCompleteControl.value === 'string' ||
        this.currentTagsChipValueList?.includes(this.tagsAutoCompleteControl.value.value)
      ) {
        this.tagsAutoCompleteControl.setErrors({ inValidAsync: true });
        return;
      }
    }
  }

  tagsFocus(event: any) {
    event.stopPropagation();
    this.currentTagsChipValue = deepClone(this.tagsAutoCompleteControl?.value);
    this.filteredByTypingTagOptions = this.tagsAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.listOptionTag.filter(item =>
          item?.displayValue?.toLowerCase()?.includes((event.target.value as string).toLowerCase()) &&
          !this.currentTagsChipValueList?.includes(item?.value),
        )));
  }

  displayTagFn(value: Option): string {
    return value ? value.displayValue : '';
  }

  onAddNewChip() {
    if (!this.tagsAutoCompleteControl.value ||
      this.tagsAutoCompleteControl.invalid) {
      this.tagsAutoCompleteControl.markAsTouched();
      return;
    }

    const chip = this.currentTagsChipValue;
    this.tagsAutoCompleteControl.reset('');
    const chipControl = new FormControl(chip);

    this.tagsChipListControl.push(chipControl);

    if (!this.currentTagsChipValueList.includes(chip.value)) {
      this.currentTagsChipValueList.push(chip.value);
    }
  }

  onRemoveChip(value: string, index: number) {
    if (!value) return;
    this.currentTagsChipValueList.splice(index, 1);
    this.tagsChipListControl.removeAt(index);
  }

  getData() {
    let templateName = DOCUMENT_TEMPLATE.AGE65_LETTER;
    let sortType = SortType.DESC;
    let sortNames = 'GeneratedAt';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.documentStore.dispatch(getGeneratedReportsInformationAction({
      request: {
        templateName,
        sortNames,
        sortType,
        pageNumber: this.pageIndex,
        pageSize: this.pageSize,
      },
    }));
  }

  selectGeneratedReportsInformationSelector() {
    this.documentStore
      .select(getGeneratedReportsInformationSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        tap((response) => {
          if (this.isTableLoading) {
            this.isTableLoading = !!response?.isLoading;
          }
        }),
        map((response) => response?.payload),
        tap((data) => {
          this.templateId = data?.templateId ?? '';
          this.totalRecords = data?.total as number;
          this.tableData =
            data?.generatedReports?.map((item: any) => {
              return {
                ...item,
                generatedAt: this.datePipe.transform(getDateString(item.generatedAt), 'MM/dd/yyyy h:mm a'),
                thruDate: this.datePipe.transform(getDateString(
                  JSON.parse(item.customInformation)?.ToDate,
                ), 'MM/dd/yyyy') ?? '',
              };
            }) ?? [];

          // set tags, tagsChipList
          const previousTagsChipList = this.tagsChipListControl.getRawValue();
          data?.tags?.map((item: any) => {
            const tagExists = previousTagsChipList.find(prev => prev.value === item.id);
            if (!tagExists) {
              const newTagsChipListFormControl = this.fb.group({
                value: item.id,
                displayValue: item.name,
              });
              this.currentTagsChipValueList.push(item.id);
              this.tagsChipListControl.push(newTagsChipListFormControl);
            }
          });

          this.documentStore.dispatch(clearGetGeneratedReportsInformationStateAction());
        }),
        switchMap(() => timer(5000)),
        takeUntil(this.unsubscribe$),
      ).subscribe(() => {
      this.getData();
    });
  }

  getTagList() {
    let sortType = SortType.ASC;
    let sortNames = 'Name';
    this.tagStore.dispatch(getTagListAction({
      request: {
        sortNames,
        sortType,
      },
    }));
  }

  selectDataTagListSelector() {
    this.tagStore
      .pipe(select(getTagListSelector),
        takeUntil(this.unsubscribe$),
      ).subscribe((data) => {
      if (data) {
        if (data?.success && data?.payload?.length) {
          this.listOptionTag = data?.payload.map((item) => ({
            value: item.id,
            displayValue: item.name,
          } as Option));
        }
      }
    });
  }

  validateGenerateReport() {
    setTimeout(() => this.isValidateLoading = true, 0);
    const thruDateValue = this.editForm.get('thruDate')?.value;
    const thruDate = new Date(thruDateValue).toISOString();
    this.documentStore.dispatch(validateGenerateAge65LetterAction({ request: { thruDate } }));
  }

  checkValidateGenerateReportSelector() {
    this.documentStore
      .select(validateGenerateAge65LetterSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        map((response) => response?.payload ?? ({} as ValidateGenerateAge65LetterResponse)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.isValidateLoading = false;
        this.validateGenerateReportData = response;
        if (response.count >= 1) {
          this.showGenerateReportConfirmDialog();
        } else {
          this.showErrorDialog();
        }
        this.documentStore.dispatch(clearValidateGenerateAge65LetterStateAction());
      });
  }

  showErrorDialog() {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      disableClose: true,
      data: {
        title: BannerType.Error,
        text: 'No member turns 65 or older through selected date.',
        type: ConfirmType.Warning,
        hideConfirmButton: true,
        cancelButtonTitle: BUTTON_LABEL_CLOSE,
      },
    });
  }

  showGenerateReportConfirmDialog() {
    const confirmDialog = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      disableClose: true,
      data: {
        title: BannerType.Warning,
        text: `The report will be generated for eligible member(s) and take a few minutes. Are you sure you want to proceed?`,
        type: ConfirmType.Warning,
        cancelButtonTitle: BUTTON_LABEL_NO,
      },
    });

    confirmDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((confirm) => {
        if (!confirm) {
          return;
        }
        this.generateReport();
      });
  }

  generateReport() {
    setTimeout(() => this.isGenerateLoading = true, 0);
    const thruDateValue = this.editForm.get('thruDate')?.value;
    const thruDate = new Date(thruDateValue).toISOString();
    const body: GenerateDocument = {
      templateName: DOCUMENT_TEMPLATE.AGE65_LETTER,
      exportType: FileType.docx,
      tagsId: this.currentTagsChipValueList,
      currentDate: new Date().toISOString(),
      timeZoneOffset: new Date().getTimezoneOffset(),
      documentLocationTitle: DOCUMENT_LOCATION.AGE65_LETTER,
      documentLocationRouter: 'member/generate-age65-letter',
      isPreview: false,
      toDate: thruDate,
      zipFiles: true,
    };
    this.documentStore.dispatch(generateParticipantDocumentsAction({ body }));
  }

  selectGenerateDocumentSelector() {
    this.documentStore
      .pipe(select(generateDocumentSelector), takeUntil(this.unsubscribe$))
      .subscribe((generateDocumentState) => {
        if (generateDocumentState) {
          this.isGenerateLoading = false;
          this.getData();
          this.documentStore.dispatch(clearGenerateParticipantDocumentsStateAction());
        }
      });
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageNumber;
    this.isTableLoading = true;
    this.getData();
  }

  onSortChange(event: Sort) {
    this.sortInfo = event;
    this.isTableLoading = true;
    this.getData();
  }

  downloadLetter(row: Row) {
    if (!row) {
      return;
    }
    this.downloadFileName = row?.fileName;
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.zipFileId as string, fileName: row.fileName as string }),
    );
  }

  selectDownloadMemberDocumentSelector() {
    this.documentStore
      .pipe(select(selectDowloadMemberDocumentState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
        }
      });
  }

  selectRow(row: any) {
    if (row.status !== GENERATE_DOCUMENT_STATUS.COMPLETED) {
      return;
    }
    this.router.navigateByUrl(`/member/generate-age65-letter/${row.generateId}`);
  }

}
