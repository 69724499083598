import { Component, Inject, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';

import { BaseComponent } from '@ptg-shared/components';
import { ACTION, CANCEL_CONFIRM_MESSAGE, STATE } from '@ptg-shared/constance';
import { InputType } from '@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum';
import { PersonNamePipe } from '@ptg-shared/pipes/person-name.pipe';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { filter, takeUntil } from 'rxjs/operators';

import {
  Properties,
  SetBenefitDetailProperties,
  UpdateCalculationBenefitDetailRequest,
} from '@ptg-member/features/calculation/services/models';
import { RetirementBenefitDataInput } from '@ptg-member/features/calculation/services/models/retirement-benefit.model';
import {
  clearUpdateCalculationBenefitDetailByTypeStateAction,
  clearGetStepConfigurationsStateAction,
  clearSetCalculationParameterStateAction,
  updateCalculationBenefitDetailByTypeAction,
  clearGetValidationExceptionConfigurationStateAction,
  getValidationExceptionConfigurationAction,
} from '@ptg-member/features/calculation/store/actions';
import { CalculationState } from '@ptg-member/features/calculation/store/reducers';
import {
  getValidationExceptionConfigurationSelector,
  setCalculationParameterSelector,
} from '@ptg-member/features/calculation/store/selectors';
import { FormControlDetail } from '@ptg-shared/types/models';
import { DateTime } from 'luxon';
import { CalculationBenefitDetailType } from '../../services/models/retirement-benefit-detail.model';
import { isEmpty, stringToBoolean } from '@ptg-shared/utils/string.util';
import { formatUtcDateString, showBanner, showCancelDialog } from '@ptg-shared/utils/common.util';
import { PropertyType } from '@ptg-shared/types/enums/entity.enum';
import { FundType } from '@ptg-shared/types/enums';
import { CalculationType, ExceptionType } from '../../types/enums';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { BenefitDetail, GetValidationExceptionRequest } from '../../services/models/exception-configuration.model';
import { validationData } from '../component.util';

@Component({
  selector: 'ptg-edit-calculation-parameter',
  templateUrl: './edit-calculation-parameter.component.html',
  styleUrls: ['./edit-calculation-parameter.component.scss'],
  providers: [PersonNamePipe],
})
export class EditCalculationParameterComponent extends BaseComponent {
  @ViewChild('fileDocument')
  PropertyType = PropertyType;
  DEFAULT_BENEFIT_TYPE = 'Retirement';
  listBreadcrumbs: Breadcrumb[] = [{ name: 'Edit Calculation Parameter' }];

  message = '';
  bannerType: BannerType = BannerType.Hidden;

  formSubmit$: any;
  formData!: FormGroup;
  memberId = '';
  checkPattern = new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i');

  listSection: any[] = [];
  dialogTitle: string = 'Edit Calculation Parameter';
  parametersSysConfigs: FormControlDetail[] = [];
  parametersOverriddenConfigs: FormControlDetail[] = [];

  isShowHeader: boolean = false;
  isCustomRequired: boolean = false;
  requiredFields: string[] = [
    'Payable Date',
    'Benefit Begin Date',
    'Monthly Benefit Amount',
    'Number of Checks 1st Payment',
    '1st Check Amount',
  ];
  requiredFieldsByEligible: string[] = ['Annual Increase Effective Date', 'Annual Increase Amount'];
  validationExceptionInfo: BenefitDetail[] | undefined;

  isJointSurvivor: boolean | null = null;
  enableFieldsInJointSurvivorCase: string[] = ['benefitbegindate'];

  get isChicagoParks() {
    return this.data.currentFund.fundType === FundType.ChicagoParks;
  }

  get isQdro() {
    return this.data.calculationType === CalculationType.QDRO;
  }

  constructor(
    public dialogRef: MatDialogRef<EditCalculationParameterComponent>,
    public dialog: MatDialog,
    public switchConfirmPopupService: SwitchConfirmPopupService,
    @Inject(MAT_DIALOG_DATA) public data: RetirementBenefitDataInput,
    private fb: FormBuilder,
    public calculationStore: Store<CalculationState>,
  ) {
    super();
  }

  get parameterSystemArray(): FormArray {
    return this.formData?.get('systemParameters') as FormArray;
  }
  get parameterOverriddenArray(): FormArray {
    return this.formData?.get('overriddenParameters') as FormArray;
  }

  ngOnInit(): void {
    this.memberId = this.data.memberId;
    this.formData = this.initForm;
    this.isJointSurvivor = this.data.calculationType === CalculationType.JointSurvivor;
    this.getValidationExceptionConfigurationData();
    this.selectValidationExceptionState();

    this.otherComponentMapping();

    this.updateCalculationBenefitDetailByTypeListener();
  }

  otherComponentMapping() {
    this.isShowHeader = !this.isChicagoParks && !this.isQdro;
    this.data?.properties?.forEach((property) => {
      const lsOption = this.handleListOptions(property);

      const item = {
        ...property,
        id: property.benefitDetailKey,
        configs: property.config,
        type: PropertyType[property.type],
        label: property.label,
        property: property,
        isRequired: false,
        lstOption: lsOption,
      } as any;
      let valueEdit = property.value;
      let overridenValue: any = property.userOverridenValue;
      if (property.type === PropertyType.Date) {
        valueEdit = property.value ? this.processDateValue(property.value) : property.value;
        overridenValue = property.userOverridenValue
          ? this.processDateValue(property.userOverridenValue)
          : property.userOverridenValue;
      }
      const itemSys = { ...item, isDisabled: this.isShowHeader, value: valueEdit };

      const requiredField = this.isChicagoParks && this.requiredFields.includes(property.label);
      const itemOverridden = { ...item, isRequired: requiredField, value: overridenValue || '' };
      if (this.isJointSurvivor === true && this.checkEnableJointSurvivorFields(property?.label) === false) {
        itemOverridden['isDisabled'] = true;
      }
      if (this.isShowHeader) {
        itemOverridden.config = { ...itemOverridden.config, required: 'false' };
        itemOverridden.configs = { ...itemOverridden.configs, required: 'false' };
      }
      this.parametersSysConfigs.push(itemSys);

      this.parametersOverriddenConfigs.push(itemOverridden);
    });

    this.initFormGroup();
  }

  processDateValue(date: string) {
    return !DateTime.fromJSDate(new Date(date)).isValid
      ? null
      : formatUtcDateString(new Date(date).toISOString(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'");
  }

  handleListOptions(property: any) {
    if (property.type === PropertyType.Lookup) {
      return (property.options as any[]).map((option) => {
        return {
          value: option.id,
          displayValue: option.text,
        };
      });
    }
    if (property?.type === PropertyType.Employer || property?.type === PropertyType.Department) {
      return property.options;
    }
    return [];
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef, CANCEL_CONFIRM_MESSAGE, () => {
      this.calculationStore.dispatch(clearGetStepConfigurationsStateAction());
    });
  }

  onSave(): void {
    if (this.isChicagoParks || this.isQdro) {
      this.formData.markAllAsTouched();
    }
    if (!this.formData.valid) {
      return;
    }

    const properties: Properties[] = (this.data.properties ?? []).map((property) => {
      const overriddenItem = (this.formData.getRawValue().overriddenParameters).filter(
        (item: any) => item.id === property.benefitDetailKey,
      );
      const isDatePropertyType = property.type === PropertyType.Date;
      let value: any;
      let userOverridden: any;
      if (!isDatePropertyType) {
        value = property.value;
        userOverridden = overriddenItem[0].value?.toString();
      } else {
        value = this.handleDateValue(
          property.value,
          new Date(property.value === '' ? null : property.value).toISOString(),
        );
        userOverridden = this.handleDateValue(overriddenItem[0].value, overriddenItem[0].value as string);
      }

      if (this.isChicagoParks || this.isQdro) {
        value = userOverridden;
      }
      return {
        benefitDetailKey: property.benefitDetailKey,
        type: property.type,
        value,
        userOverridden,
      };
    });

    this.dispatchUpdateCalculationBenefitDetailByType(properties);
  }

  handleDateValue(input: any, isoStringData: string): string {
    const result =
      input && DateTime.fromJSDate(new Date(input)).isValid
        ? DateTime.fromISO(isoStringData).toFormat('MM/dd/yyyy')
        : '';
    return result;
  }

  private dispatchUpdateCalculationBenefitDetailByType(properties: Properties[] = []): void {
    const request: UpdateCalculationBenefitDetailRequest = {
      benefitEntityId: this.data?.benefitEntityId ?? '',
      targetType: this.data?.calculationType ?? CalculationType.RetirementBenefit,
      targetId: this.data?.calculationBenefitId ?? '',
      properties,
    };
    const payload = {
      memberId: this.data.memberId,
      calculationBenefitId: this.data?.calculationBenefitId ?? '',
      detailType: CalculationBenefitDetailType.CalculationParameter,
      request,
    };
    this.calculationStore.dispatch(updateCalculationBenefitDetailByTypeAction(payload));
  }

  private updateCalculationBenefitDetailByTypeListener(): void {
    this.calculationStore
      .select(setCalculationParameterSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const isSuccess = response?.success;
        this.calculationStore.dispatch(clearUpdateCalculationBenefitDetailByTypeStateAction());
        this.calculationStore.dispatch(clearSetCalculationParameterStateAction());
        showBanner.call(this, isSuccess ? STATE.SUCCESS : STATE.FAIL, this.data?.sectionLabel ?? '', ACTION.EDIT);

        if (isSuccess) {
          this.dialogRef.close(response);
        }
      });
  }

  initFormGroup(): void {
    this.parameterSystemArray.clear();
    this.parameterOverriddenArray.clear();

    this.parametersSysConfigs.forEach((property: any) => {
      const objSysItem = this.fb.group({
        id: property.id,
        options: property.options,
        order: property.order,
        orderColumn: property.orderColumn,
        sectionKey: property.key,
        value: this.fb.control(property.value ?? ''),
        isDisabled: property.isDisabled,
        option: property.option,
      });
      this.parameterSystemArray.push(objSysItem);
    });

    this.parametersOverriddenConfigs.forEach((property: any) => {
      const objOverriddenItem = this.fb.group({
        id: property.id,
        options: property.options,
        order: property.order,
        orderColumn: property.orderColumn,
        sectionKey: property.key,
        isRequired: property.isRequired,
        option: property.option,
        label: property.label,
        value: this.fb.control(this.initFieldsValue(property), [this.customValidation(property)]),
        calculationParamMappingId: property.calculationParamMappingId,
      });
      this.parameterOverriddenArray.push(objOverriddenItem);
    });
  }

  initFieldsValue(property: any): any {
    if (isEmpty(property.value) || property.value === '') {
      return null;
    }
    if (property.type === InputType.Binary) {
      return stringToBoolean(property.value);
    }
    return property.value;
  }

  get initForm(): FormGroup {
    return this.fb.group({
      systemParameters: this.fb.array([]),
      overriddenParameters: this.fb.array([]),
    });
  }
  changeValue(event: any) {
    const listControl = this.formData.controls.overriddenParameters as FormArray;
    const listData = this.formData.getRawValue().overriddenParameters;
    let listIndex: number[] = [];
    listData.forEach((item: any, index: number) => {
      if (this.requiredFieldsByEligible.includes(item.label)) {
        listIndex.push(index);
      }
    });
    if (event) {
      listControl.controls.forEach((element, index) => {
        if (listIndex.includes(index)) {
          (element as FormGroup).controls.value.setValidators([Validators.required]);
          (element as FormGroup).controls.value.updateValueAndValidity();
        }
      });
    }
    if (!event) {
      listControl.controls.forEach((element, index) => {
        if (listIndex.includes(index)) {
          (element as FormGroup).controls.value.clearValidators();
          (element as FormGroup).controls.value.updateValueAndValidity();
        }
      });
    }
  }

  customValidation(field: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const properties: SetBenefitDetailProperties[] = this.formData.getRawValue().overriddenParameters;

      const data = this.validationExceptionInfo?.filter(
        (item) =>
          item.benefitId === this.data.benefitEntityId &&
          item.additionalInfos.length > 0 &&
          item.additionalInfos[0].value === field.calculationParamMappingId,
      );
      return validationData(data, properties);
    };
  }

  private getValidationExceptionConfigurationData(): void {
    this.calculationStore.dispatch(clearGetValidationExceptionConfigurationStateAction());
    const request: GetValidationExceptionRequest = {
      memberId: this.data.memberId,
      exceptionType: ExceptionType.DateValueValidation,
      calculationBenefitId: this.data?.calculationBenefitId === '' ? undefined : this.data?.calculationBenefitId,
    };
    this.calculationStore.dispatch(getValidationExceptionConfigurationAction({ request }));
  }

  private selectValidationExceptionState(): void {
    this.calculationStore
      .pipe(
        select(getValidationExceptionConfigurationSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetValidationExceptionConfigurationStateAction());
        if (response?.payload) {
          this.validationExceptionInfo = response?.payload?.benefitDetails;
        }
      });
  }

  checkEnableJointSurvivorFields(label: string) {
    if (!label) return true;
    const rawLabel = label
      .trim()
      .replace(/[^A-Za-z0-9]/g, '')
      .toLowerCase();
    return this.enableFieldsInJointSurvivorCase.includes(rawLabel);
  }
}
