import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { BaseListComponent } from "@ptg-shared/components/base-list.component";
import { DEFAULT_PAGE_SIZE, SortType } from "@ptg-shared/constance";
import { BannerType } from "@ptg-shared/controls/banner/types/banner.model";
import { Align, Column, ColumnType, RADIO_COLUMN, Row } from "@ptg-shared/controls/grid";
import { FIRST_PAGE, PageEvent } from "@ptg-shared/controls/pagination";
import { LayoutService } from "@ptg-shared/services/layout.service";
import { Breadcrumb } from "@ptg-shared/types/models/breadcrumb.model";
import { filter, takeUntil, tap } from "rxjs/operators";
import { EntityListData, PayeeListRequest, UpdatePayeeInfoRequest } from "../../services/models";
import { capitalizeFirstLetter } from "@ptg-shared/utils/string.util";
import { Sort } from "@angular/material/sort";
import { Store, select } from "@ngrx/store";
import { CalculationState, clearGetPayeeListStateAction, getPayeeListAction, getPayeeListSelector, updatePayeeInformationAction, updatePayeeInformationSelector } from "../../store";
import { ConfirmPopupComponent } from "@ptg-shared/controls/confirm-popup/confirm-popup.component";
import { ConfirmType } from "@ptg-shared/constance/confirm-type.const";
import { CardProperty, EntityViewItem } from "@ptg-entity-management/services/models";
import { getColumConfig, getEntityPropertyName, getEntityValue } from "@ptg-member/helper";
import { deepClone, getSortType } from "@ptg-shared/utils/common.util";
import { PropertyType } from '@ptg-member/constance/metadataPropertyType.const';
import { SCREEN_ID } from "@ptg-member/constance/lookupTable.const";

@Component({
  selector: 'ptg-edit-payee-info',
  templateUrl: './edit-payee-info.component.html',
  styleUrls: ['./edit-payee-info.component.scss'],
})

export class EditPayeeInfoComponent extends BaseListComponent {
  readonly RADIO_COLUMN = RADIO_COLUMN;
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs();
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  totalRecords: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber: number = FIRST_PAGE;
  sortInfo?: Sort;
  cards: EntityViewItem[] = [];
  viewId: string = '';
  entitiesData: (EntityListData & Row)[] = [];
  propertiesConfig!: any;
  entityComponentId = '';
  payeeColumns: Column[] = [];
  payeeSelected: any = null;
  private cardConfig?: EntityViewItem;

  isLoading: boolean = false;

  payeeList: any[] = [];

  constructor(
    public route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public payeeData: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditPayeeInfoComponent>,
    public layoutService: LayoutService,
    private store: Store<CalculationState>,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkRoute();
    this.getData();
    this.selectEntityListState();

    this.store.select(updatePayeeInformationSelector).pipe(takeUntil(this.unsubscribe$)).subscribe(state => {
      if(state?.payload) {
        this.onCloseEditDialog('close');
      }
    })
  }

  checkRoute() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.listBreadcrumbs = this.getBreadcrumbs();
    });
  }

  getBreadcrumbs() {
    return [
      {
        name: 'Edit Payee',
      },
    ];
  }

  selectEntityListState() {
    this.store
      .select(getPayeeListSelector)
      .pipe(
        tap((state) => (this.isLoading = !!state?.isLoading)),
        filter((state) => !!state && !state.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (!state) {
          return;
        }
        this.entityComponentId = state.payload?.entityComponentId ?? '';
        this.totalRecords = state.total ?? 0;
        this.propertiesConfig = state.payload?.cardProperties ?? [];
        this.getColumnConfigs();
        const listDataMappingId = (state.payload?.cardProperties ?? []).map((element: any) => {
          return {
            id: this.getColumnName(element),
            type: element.type,
            config: element.config,
          };
        });
        this.entitiesData = (state.payload?.rowData || [])?.map((item: any) => {
          const result: any = {};
          item?.values?.forEach((element: any) => {
            let columnName = element.entityReferencePropertyId ?? '';
            columnName = columnName && element.entityPropertyId
                ? `${columnName}_${element.entityPropertyId}`
                : element.entityPropertyId;

            if (element.option) {
              columnName = `${columnName}_${element.option}`;
            }
            const options = listDataMappingId.find((item) => item.id === columnName);
            result['checked'] = item.id === this.payeeData.rowId ? true : false;
            result[columnName] = getEntityValue(element, options);
          });
          return { ...result, id: item.id, noOfAttachments: item?.noOfAttachments, values: item.values };
        });
      });
  }

  getColumnName(column: CardProperty): string {
    let columnName = '';
    columnName = column.propertyId;
    if (column.entityReferencePropertyId) {
      columnName = columnName + '_' + column.entityReferencePropertyId;
    }

    if (column.option) {
      columnName = columnName + '_' + column.option;
    }

    return columnName;
  }

  getColumnConfigs() {
    this.payeeColumns = deepClone(this.propertiesConfig).map((column: CardProperty) => {
      const columConfig = getColumConfig(getEntityPropertyName(column.type), column.config);
      return {
        name: this.getColumnName(column),
        header: {
          title: column.propertyLabel,
        },
        truncate: true,
        sortable: true,
        type: [ColumnType.Address, ColumnType.PersonName, ColumnType.Binary].includes(columConfig.type as ColumnType)
          ? ColumnType.Text
          : columConfig.type,
        align: column.type === PropertyType.TYPE_REGISTER ? Align.Center : undefined,
        templateArgs: columConfig.templateArgs,
      };
    });
    this.payeeColumns = [{name: 'radio'}].concat(this.payeeColumns);
  }

  getData() {
    let request: PayeeListRequest = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      memberId: this.payeeData.memberId,
      cardId: this.payeeData.cardId,
      isSummary: false,
      entityMemberId: this.payeeData.entityMemberId,
      screenId: SCREEN_ID.Participant,
    };
    if (this.sortInfo?.direction) {
      const sortNames = this.sortInfo.active;
      request = {
        ...request,
        sortNames,
        sortType: getSortType(this.sortInfo),
      };
    }

    this.store.dispatch(getPayeeListAction({ request }));
  }

  onChangeRadioButton(row: any): void {
    if (!row) {
      return;
    }

    this.entitiesData.forEach((item) => {
      item.checked = false;
    });
    row.checked = true;
    this.payeeSelected = row;
  }

  onSavePayeeInfo() {
    if(!this.payeeSelected) {
      if(this.entitiesData.filter(item => item.checked).length === 0) {
        this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          autoFocus: false,
          data: {
            title: 'Notice',
            text: 'Please select a Payee',
            type: ConfirmType.Confirm,
            hideConfirmButton: true,
          },
        });
      } else {
        if(this.payeeData.dateOfDeath === null) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              title: 'Confirmation',
              text: 'Member is not deceased, cannot choose payee.',
              type: ConfirmType.Confirm,
              hideConfirmButton: true,
            },
          });
        } else {
          const request: UpdatePayeeInfoRequest = {
            id: this.payeeData.payeeInformationId,
            memberId: this.payeeData.memberId,
            entityComponentId: this.entityComponentId,
            recordId: this.payeeData.calculationRecordId,
            rowId: this.entitiesData.filter(item => item.checked)[0].id,
          }
          this.store.dispatch(updatePayeeInformationAction({ request }));
        }
      }
    } else {
      const request: UpdatePayeeInfoRequest = {
        id: this.payeeData.payeeInformationId,
        memberId: this.payeeData.memberId,
        entityComponentId: this.entityComponentId,
        recordId: this.payeeData.calculationRecordId,
        rowId: this.payeeSelected.id,
      }
      this.store.dispatch(updatePayeeInformationAction({ request }));
    }
  }

  onCloseEditDialog(event: string) {
    this.dialogRef.close({
      event: event,
    });
  }

  onCancelPayeeInfo() {
    const ALERT_MESSAGE = 'Are you sure you want to cancel any/all changes?';
    const confirmDialog = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.ConfirmWithDontShowAgain,
        title: 'Cancel Action',
        cancelButtonTitle: 'No',
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.onCloseEditDialog('close');
      }
    });
  }

  changePage(event: PageEvent) {
    super.onChangePage(event);
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.isLoading = true;
    this.getData();
  }

  sortChange(sort: Sort) {
    this.sortInfo = sort;
    this.isLoading = true;
    this.getData();
  }
}
