import { createAction, props } from '@ngrx/store';
import { ParameterType } from '../../constants';
import {
  CheckMasterParameterUsingResponse,
  GetPropertiesByLinkedIdResponse,
  GetReferenceByLinkedIdResponse,
  GetRetirementBenefitInputOutputListQuery,
  GetRetirementBenefitInputOutputListResponse,
  LinkedProperty,
  MasterInputOutput,
} from '../../services/models';
import { PropertyType } from '@ptg-shared/types/enums/entity.enum';

export const GetRetirementBenefitInputOutputsRequest = '[GetRetirementBenefitInputOutputs/API] Send Request';
export const GetRetirementBenefitInputOutputsSuccess = '[GetRetirementBenefitInputOutputs/API] Success';
export const GetRetirementBenefitInputOutputsFailure = '[GetRetirementBenefitInputOutputs/API] Failure';

export const GetLinkedPropertiesRequest = '[GetLinkedProperties/API] Send Request';
export const GetLinkedPropertiesSuccess = '[GetLinkedProperties/API] Success';
export const GetLinkedPropertiesFailure = '[GetLinkedProperties/API] Failure';

export const AddMasterInputOutputRequest = '[AddMasterInputOutput/API] Send Request';
export const AddMasterInputOutputSuccess = '[AddMasterInputOutput/API] Success';
export const AddMasterInputOutputFailure = '[AddMasterInputOutput/API] Failure';

export const EditMasterInputOutputRequest = '[EditMasterInputOutput/API] Send Request';
export const EditMasterInputOutputSuccess = '[EditMasterInputOutput/API] Success';
export const EditMasterInputOutputFailure = '[EditMasterInputOutput/API] Failure';

export const DeleteMasterInputOutputRequest = '[DeleteMasterInputOutput/API] Send Request';
export const DeleteMasterInputOutputSuccess = '[DeleteMasterInputOutput/API] Success';
export const DeleteMasterInputOutputFailure = '[DeleteMasterInputOutput/API] Failure';

export const GetPropertiesByLinkedIdRequest = '[GetPropertiesByLinkedId/API] Send Request';
export const GetPropertiesByLinkedIdSuccess = '[GetPropertiesByLinkedId/API] Success';
export const GetPropertiesByLinkedIdFailure = '[GetPropertiesByLinkedId/API] Failure';

export const GetEntityReferenceByLinkedIdRequest = '[GetEntityReferenceByLinkedId/API] Send Request';
export const GetEntityReferenceByLinkedIdSuccess = '[GetEntityReferenceByLinkedId/API] Success';
export const GetEntityReferenceByLinkedIdFailure = '[GetEntityReferenceByLinkedId/API] Failure';
export const ClearEntityReferenceByLinkedIdState = '[GetEntityReferenceByLinkedId] Clear';

export const ClearRetirementBenefitInputOutputState = '[ClearRetirementBenefitInputOutput] Clear';

export const getRetirementBenefitInputOutputsRequest = createAction(
  GetRetirementBenefitInputOutputsRequest,
  props<{ query: GetRetirementBenefitInputOutputListQuery }>(),
);
export const getRetirementBenefitInputOutputsSuccess = createAction(
  GetRetirementBenefitInputOutputsSuccess,
  props<{ response: GetRetirementBenefitInputOutputListResponse }>(),
);
export const getRetirementBenefitInputOutputsFailure = createAction(GetRetirementBenefitInputOutputsFailure);
export const clearGetRetirementBenefitInputOutputsAction = createAction(
  '[GetRetirementBenefitInputOutputs] Clear'
);

export const getLinkedPropertiesRequest = createAction(
  GetLinkedPropertiesRequest,
  props<{ dataType: PropertyType; lookupTableId?: string }>(),
);
export const getLinkedPropertiesSuccess = createAction(
  GetLinkedPropertiesSuccess,
  props<{ linkedProperties: LinkedProperty[] }>(),
);
export const getLinkedPropertiesFailure = createAction(GetLinkedPropertiesFailure);

export const addMasterInputOutputRequest = createAction(
  AddMasterInputOutputRequest,
  props<{ body: MasterInputOutput }>(),
);
export const addMasterInputOutputSuccess = createAction(
  AddMasterInputOutputSuccess,
  props<{ parameterType: ParameterType }>(),
);
export const addMasterInputOutputFailure = createAction(
  AddMasterInputOutputFailure,
  props<{ parameterType: ParameterType }>(),
);

export const editMasterInputOutputRequest = createAction(
  EditMasterInputOutputRequest,
  props<{ id: string; body: MasterInputOutput }>(),
);
export const editMasterInputOutputSuccess = createAction(
  EditMasterInputOutputSuccess,
  props<{ parameterType: ParameterType }>(),
);
export const editMasterInputOutputFailure = createAction(
  EditMasterInputOutputFailure,
  props<{ parameterType: ParameterType }>(),
);

export const clearRetirementBenefitInputOutputState = createAction(ClearRetirementBenefitInputOutputState);

export const deleteMasterInputOutputRequest = createAction(
  DeleteMasterInputOutputRequest,
  props<{ id: string; parameterType: ParameterType }>(),
);
export const deleteMasterInputOutputSuccess = createAction(
  DeleteMasterInputOutputSuccess,
  props<{ parameterType: ParameterType }>(),
);
export const deleteMasterInputOutputFailure = createAction(
  DeleteMasterInputOutputFailure,
  props<{ parameterType: ParameterType }>(),
);

export const checkMasterParameterUsingAction = createAction(
  '[GetCheckMasterParameterUsing/API] Send Request',
  props<{ id: string; parameterType: ParameterType }>(),
);

export const checkMasterParameterUsingSuccessAction = createAction(
  '[GetCheckMasterParameterUsing/API] Success',
  props<{ response: CheckMasterParameterUsingResponse; id: string; parameterType: ParameterType }>(),
);

export const checkMasterParameterUsingFailureAction = createAction(
  '[GetCheckMasterParameterUsing/API] Failure',
  props<{ error?: any }>(),
);

export const clearCheckMasterParameterUsingStateAction = createAction('[GetCheckMasterParameterUsing] Clear');

export const getPropertiesByLinkedIdRequest = createAction(
  GetPropertiesByLinkedIdRequest,
  props<{ dataType: PropertyType; linkedPropertyId?: string }>(),
);
export const getPropertiesByLinkedIdSuccess = createAction(
  GetPropertiesByLinkedIdSuccess,
  props<{ response: GetPropertiesByLinkedIdResponse }>(),
);
export const getPropertiesByLinkedIdFailure = createAction(GetPropertiesByLinkedIdFailure);

export const getEntityReferenceByLinkedIdRequest = createAction(
  GetEntityReferenceByLinkedIdRequest,
  props<{ dataType: PropertyType; linkedPropertyId?: string; lookupTableId?: string }>(),
);
export const getEntityReferenceByLinkedIdSuccess = createAction(
  GetEntityReferenceByLinkedIdSuccess,
  props<{ response: GetReferenceByLinkedIdResponse }>(),
);
export const getEntityReferenceByLinkedIdFailure = createAction(GetEntityReferenceByLinkedIdFailure);
export const clearEntityReferenceByLinkedIdStateAction = createAction(ClearEntityReferenceByLinkedIdState);
