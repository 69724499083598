<div class="member-detail" #memberDetail *ngIf="!isLoading && isViewMemberOverviewScreen">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    *ngIf="!(isLoadingMemberData || isLoadingLockMember)"
    (clickBreadcrumb)="backToMemberList()"
  ></ptg-breadcrumb>
  <ptg-overview-header-entity
    #overViewHeader
    [isLocked]="isLocked"
    [identityKey]="identityKey"
    [actionItems]="actionItems"
    [isDeactivate]="isDeactivate"
    [isShowChangeMemberDetailBtn]="!isProfileNavigate"
    (removeMemberEvent)="removeMember()"
    (lockAccountEvent)="lockAccount($event)"
    (clickActionItem)="onClickActionItem($event)"
    (deactivateMemberEvent)="onDeactivateMember()"
    [shouldHideStatusDateProperty]="true"
    [shouldHideStatusEventProperty]="true"
  ></ptg-overview-header-entity>

  <div class="member-detail-container flex flex-col p-6 gap-6">
    <div
      class="banner acc-locked-banner"
      *ngIf="
      isLocked &&
      identityKey &&
      !(isLoadingMemberData || isLoadingLockMember)
      "
    >
      <ng-container>
        <mat-icon style="color: #a8a8a8">lock</mat-icon>
        <span style="vertical-align: super">This Account is Locked.</span>
      </ng-container>
    </div>
    <ptg-banner
      *ngIf="bannerType"
      [isLoadingInfo]="isLoadingInfo"
      [(bannerType)]="bannerType"
      [message]="message"
      (closeBanner)="closeBanner()"
      [isHideCloseButton]="isLoadingInfo"
    ></ptg-banner>

    <ptg-banner
      *ngIf="bannerTypeWarning === BannerType.Warning &&
      !isLoadingViewItems &&
      isOverview &&
      listCard.length !== 0 &&
      !identityKey &&
      !isShowBanner"
      [(bannerType)]="bannerTypeWarning"
      [message]="'This Participant is not Registered.'"
    ></ptg-banner>

    <div
      *ngIf="
        !(isLoadingMemberData || isLoadingLockMember)
      "
      class="content-detail"
    >
      <div class="cell-detail" *ngFor="let direction of ['left', 'right']">
        <ng-container *ngFor="let card of listCard">
          <div  *ngIf="
            ((direction === 'left' && !card.isRight) ||
            (direction === 'right' && card.isRight))
            && !card.isHideCard
            ">
            <ptg-member-summary-card
            *ngIf="!card.profileFixType"
            [configCard]="card"
            [profileOverviewConfig]="detailInfo?.profileOverviewConfig"
            [memberId]="memberId"
            [entityId]="entityId"
            [memberStatus]="memberStatus"
            [menuName]="menuName"
            (deleteSectionEvent)="deleteSection($event)"
            (editCardEvent)="editCard($event)"
            (manageSectionEvent)="manageSection($event)"
            (downloadPdfEvent)="downloadPdf($event)"
            (showAttachmentsEvent)="showAttachments($event)"
            (viewDetailBenefitEntity)="viewDetailBenefitEntity($event)"
            [viewName]="isOverview ? menuName : viewName"
            [viewUrl]="router.url"
            ></ptg-member-summary-card>
          </div>
          <div *ngIf="
            ((direction === 'left' && !card.isRight) ||
            (direction === 'right' && card.isRight))
            && card.profileFixType === EntityProfileFixType.StatusHistory
            "
            class="mb-6">
            <!-- Status History -->
            <ptg-status-history-entity
            [memberId]="memberId"
            [configCard]="card"
            [viewName]="isOverview ? menuName : viewName"
            [viewUrl]="router.url"
          ></ptg-status-history-entity>
          </div>
          <div *ngIf="
          ((direction === 'left' && !card.isRight) ||
          (direction === 'right' && card.isRight))
            && card.profileFixType === EntityProfileFixType.AddressHistory
            "
            class="mb-6">
            <!-- Address History -->
            <ptg-address-history-entity
              [configCard]="card"
              [memberId]="memberId"
              [entityId]="entityId"
              [viewName]="isOverview ? menuName : viewName"
              [viewUrl]="router.url"
            ></ptg-address-history-entity>
          </div>
          <div *ngIf="
            ((direction === 'left' && !card.isRight) ||
            (direction === 'right' && card.isRight))
            && card.profileFixType === EntityProfileFixType.RelatedPerson
            "
            class="mb-6">
            <!-- related person -->
            <ptg-related-person-entity
              [memberId]="memberId"
              [screenId]="screenId"
              [configCard]="card"
            ></ptg-related-person-entity>
          </div>

          <div *ngIf="
            ((direction === 'left' && !card.isRight) ||
            (direction === 'right' && card.isRight))
            && card.profileFixType === EntityProfileFixType.ServiceHistory
            && isViewServiceHistoryCard
            "
            class="mb-6">
            <!-- Service History -->
            <ptg-service-history-entity
            [memberId]="memberId"
          ></ptg-service-history-entity>
          </div>

          <div *ngIf="
            ((direction === 'left' && !card.isRight) ||
            (direction === 'right' && card.isRight))
            && card.profileFixType === EntityProfileFixType.Totals
            "
            class="mb-6">
            <!-- Service History -->
            <ptg-total-service-entity
            [memberId]="memberId"

          ></ptg-total-service-entity>
          </div>
          <div *ngIf="
            ((direction === 'left' && !card.isRight) ||
            (direction === 'right' && card.isRight))
            && card.profileFixType === EntityProfileFixType.Documents
            "
            class="mb-6">
            <ptg-participant-documents [menuId]="menuId" [viewId]="viewId" [memberId]="memberId" [cardNameDoc]="cardNameDoc" [configCard]="card"></ptg-participant-documents></div>
        </ng-container>
      </div>
      <div
        *ngIf="listCard?.length === 0 && !(isLoadingViewItems || isLoadingMemberData || isLoadingLockMember) && !isLoading && menuName"
        class="data-not-found"
        style="width: 100%;"
      >
        <div class="not-configured-card">
          <span class="message">
            {{menuName}} not yet configured
          </span>
        </div>
      </div>
    </div>

    <div id="loading-table" *ngIf="!noProfileConfigured && (isLoadingViewItems || isLoadingMemberData || isLoadingLockMember)">
      <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </div>

    <div id="loading-table" *ngIf="detailInfo.isDeleting">
      <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Deleting participant...</p>
      </div>
    </div>
    <div
      *ngIf="noProfileConfigured && !(isLoadingViewItems || isLoadingMemberData || isLoadingLockMember) && !isLoading"
      class="data-not-found"
      [ngClass]="{ 'not-registered': isLocked || !identityKey }"
    >
      <div class="not-configured">
        <mat-icon class="material-icons-outlined">visibility_off</mat-icon>
        <span class="message">
          Profile has not been configured yet
        </span>
      </div>
    </div>
  </div>
</div>
<div id="loading-table" *ngIf="isLoading">
  <div class="wrap-item">
    <mat-progress-spinner diameter="50" mode="indeterminate">
    </mat-progress-spinner>
    <p>Loading...</p>
  </div>
</div>
<div *ngIf="!isViewMemberOverviewScreen" class="no-permission">
  <p>You don't have permission to view this screen.</p>
</div>
