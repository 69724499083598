import { PERMISSION_KEY } from '@ptg-shared/constance/permission.const';
import { Tab } from '@ptg-shared/layout/components/captionbar/types/caption-bar.model';
import { DisplayedTabName, HiddenTabName } from '@ptg-shared/layout/constance/layout.const';

export const DEFAULT_TAB: Tab[] = [
  // 'admin' | 'participants' | 'individualParticipant' | 'searchedParticipant' | 'processing' | 'app'
  {
    permission: PERMISSION_KEY.EMPLOYERS,
    tabIcon: 'apartment',
    tabLabel: 'Employers',
    tabName: DisplayedTabName.Employers,
    url: '/employer/employer-list',
  },
  {
    permission: PERMISSION_KEY.PARTICIPANTS,
    tabIcon: 'switch_account',
    tabLabel: 'Participants',
    tabName: DisplayedTabName.Participants,
    url: '/member',
  },
  {
    permission: PERMISSION_KEY.PROCESSING,
    tabIcon: 'published_with_changes',
    tabLabel: 'Processing',
    tabName: DisplayedTabName.Processing,
    url: '/processing',
  },
  {
    permission: PERMISSION_KEY.APP_CONTENT,
    tabIcon: 'phonelink_setup',
    tabLabel: 'App',
    tabName: DisplayedTabName.AppContent,
    url: '/fund-management/navigation',
  },
  {
    permission: PERMISSION_KEY.PARTICIPANT_LIST,
    tabIcon: '',
    tabLabel: '',
    tabName: HiddenTabName.ParticipantOverviewGroup,
    url: '',
    children: [
      {
        permission: PERMISSION_KEY.PARTICIPANT_LIST,
        tabIcon: 'person_pin_circle',
        tabLabel: '',
        tabName: DisplayedTabName.IndividualParticipant,
        url: '',
      },
      {
        permission: PERMISSION_KEY.PARTICIPANT_LIST,
        tabIcon: 'person_search',
        tabLabel: '',
        tabName: DisplayedTabName.SearchedParticipant,
        url: '',
      },
    ]
  },
];

export const EMPLOYER_PORTAL_DEFAULT_TAB: Tab[] = [
  // 'admin' | 'participants' | 'individualParticipant' | 'searchedParticipant'
  {
    permission: PERMISSION_KEY.EMPLOYERS,
    tabIcon: 'apartment',
    tabLabel: 'Employers',
    tabName: DisplayedTabName.Employers,
    url: '/employer/employer-list',
  },
  {
    permission: PERMISSION_KEY.PARTICIPANTS,
    tabIcon: 'switch_account',
    tabLabel: 'Participants',
    tabName: DisplayedTabName.Participants,
    url: '/member',
  },
  {
    permission: PERMISSION_KEY.PARTICIPANT_LIST,
    tabIcon: '',
    tabLabel: '',
    tabName: HiddenTabName.ParticipantOverviewGroup,
    url: '',
    children: [
      {
        permission: PERMISSION_KEY.PARTICIPANT_LIST,
        tabIcon: 'person_pin_circle',
        tabLabel: '',
        tabName: DisplayedTabName.IndividualParticipant,
        url: '',
      },
      {
        permission: PERMISSION_KEY.PARTICIPANT_LIST,
        tabIcon: 'person_search',
        tabLabel: '',
        tabName: DisplayedTabName.SearchedParticipant,
        url: '',
      },
    ]
  },
];
