import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormArray,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';

import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';

import { BaseComponent } from '@ptg-shared/components';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { analyzeIdentifierPattern, stringToBoolean } from '@ptg-shared/utils/string.util';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { Row } from '@ptg-shared/controls/grid';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';

import { EntityPropertyType } from '../../types/enums';
import {
  BooleanPropertyConfigType,
  BooleanPropertyConfigTypeValue,
  DefaultBooleanValue,
  EntityPropertyDetail,
  GetEntityPropertiesRequest,
  GetEntityPropertyDetailRequest,
  PropertyConfig,
  PropertyConfigOption,
  SetEntityPropertyRequest,
} from '../../services/models';
import { getEntityPropertiesSelector, getEntityPropertyDetailSelector } from '../../store/selectors';
import {
  getEntityPropertiesAction,
  getEntityPropertyDetailAction,
  setEntityPropertyAction,
} from '../../store/actions';
import { EntityService } from '../../services';
import {
  Validation,
  ValidationType,
  ValidationOptions,
  ValidationTypeOptions,
} from '@ptg-member/constance/dateValidationExpression.const';
import * as fromMember from '@ptg-member/store/reducers';
import * as LookupTableActions from '@ptg-member/store/actions/lookup-table.actions';
import { IDENTIFIER_PATTERN_DEFAULT_VALUE } from '../../constants';
import { EntityState } from '../../store/reducers';

@Component({
  selector: 'ptg-edit-property-item',
  templateUrl: './edit-property-item.component.html',
  styleUrls: ['./edit-property-item.component.scss'],
})
export class EditPropertyItemComponent extends BaseComponent {
  readonly EntityPropertyType = EntityPropertyType;
  readonly PropertyConfigOption = PropertyConfigOption;

  formSubmit$: Subject<void> = new Subject();
  editForm: FormGroup = this.fb.group({
    name: this.fb.control('', {
      validators: [Validators.required],
      asyncValidators: checkApiValidator(
        this.entityService.checkPropertyNameExist,
        'name',
        undefined,
        {
          params: {
            entityId: this.data?.entityId,
            entityPropertyId: this.data?.entityPropertyId,
            entityComponentId: this.data?.entityComponentId,
          },
        }
      ),
    }),
    importLabel: this.fb.control(''),
    description: this.fb.control('', {}),
    bulkUpload: this.fb.control(false),
    readonly: this.fb.control(false),
    excludeFutureDates: this.fb.control(false),
    excludePastDates: this.fb.control(false),
  });

  propertyDetail?: EntityPropertyDetail;
  currentLookupTable: string = '';
  elementNames: BooleanPropertyConfigTypeValue[] = [];
  showMasked: boolean = false;
  isUnique: boolean = false;
  isShowUnique: boolean = false;
  isEncrypted: boolean = false;
  isShowEncrypted: boolean = false;
  addNewDateValidation: boolean = true;
  customValidators: ValidatorFn[] = [];
  lookupTableOptions: Option[] = [];
  propertiesMasked = [
    EntityPropertyType.Phone,
    EntityPropertyType.SSN,
    EntityPropertyType.Email,
  ];

  propertiesEncrypt = [
    EntityPropertyType.SSN,
    EntityPropertyType.Text,
    EntityPropertyType.Phone,
    EntityPropertyType.Email,
    EntityPropertyType.Address,
    EntityPropertyType['Person Name'],
  ];

  propertiesUnique = [
    EntityPropertyType.Currency,
    EntityPropertyType.Text,
    EntityPropertyType.Decimal,
    EntityPropertyType.Email,
    EntityPropertyType['Whole Number'],
    EntityPropertyType.Phone,
    EntityPropertyType.Date,
    EntityPropertyType.Percentage,
    EntityPropertyType.SSN,
  ];

  DateValidation = Validation;
  DateValidationType = ValidationType;
  ValidationOptions = ValidationOptions;
  ValidationTypeOptions = ValidationTypeOptions;
  dataPropertylookupOptions: Option[] = [];

  // Use for Identifier Property only
  isIdentifierPropertyType: boolean | null | undefined = null;
  identifierPropertyTypeIsAutoGenerated: boolean | null | undefined = null;
  identifierTypeOptions: RadioOption[] = [
    {
      label: 'Auto Generated',
      value: true,
    },
    {
      label: 'User-Defined',
      value: false,
    },
  ];
  isIdentifierPropertyShouldBeGreyOut: boolean | undefined = undefined;
  patternRegex: RegExp = new RegExp('\#+');
  currentPrefix: string = '';
  currentPattern: string = '';
  currentSuffix: string = '';
  importLabelDefaultValueForIdentifier: string = '';
  // End of variables used Identifier Property only

  isLoading: boolean = true;
  propertiesData: (any & Row)[] = [];

  get nameCtrl() {
    return this.editForm?.get('name') as FormControl;
  }
  get requiredConditionCtrl() {
    return this.editForm?.get('requiredCondition') as FormControl;
  }
  get includeInBulkUpdateCtrl() {
    return this.editForm?.get('includeInBulkUpdate') as FormControl;
  }
  get importLabelCtrl() {
    return this.editForm?.get('importLabel') as FormControl;
  }
  get readOnlyConditionCtrl() {
    return this.editForm?.get('readOnlyCondition') as FormControl;
  }
  get maskedConditionCtrl() {
    return this.editForm?.get('maskedCondition') as FormControl;
  }
  get inclusiveInRangeCtrl() {
    return this.editForm?.get('inclusiveInRange') as FormControl;
  }
  get minInputCtrl() {
    return this.editForm?.get('minInput') as FormControl;
  }
  get maxInputCtrl() {
    return this.editForm?.get('maxInput') as FormControl;
  }
  get maximumLengthCtrl() {
    return this.editForm?.get('maximumLength') as FormControl;
  }
  get maxLengthInputCtrl() {
    return this.editForm?.get('maxLengthInput') as FormControl;
  }
  get fractionalLengthCtrl() {
    return this.editForm?.get('fractionalLength') as FormControl;
  }
  get fractionalLengthInputCtrl() {
    return this.editForm?.get('fractionalLengthInput') as FormControl;
  }
  get prefixListCtrl() {
    return this.editForm?.get('prefixList') as FormControl;
  }
  get listValuesCtrl() {
    return this.editForm?.get('listValues') as FormControl;
  }
  get lookupTableCtrl() {
    return this.editForm?.get('lookupTable') as FormControl;
  }
  get affirmativeCtrl() {
    return this.editForm?.get('affirmative') as FormControl;
  }
  get negativeCtrl() {
    return this.editForm?.get('negative') as FormControl;
  }
  get usedStateLookupTableCtrl() {
    return this.editForm?.get('usedStateLookupTable') as FormControl;
  }
  get stateLookupTableCtrl() {
    return this.editForm?.get('stateLookupTable') as FormControl;
  }
  get usedCountryLookupTableCtrl() {
    return this.editForm?.get('usedCountryLookupTable') as FormControl;
  }
  get countryLookupTableCtrl() {
    return this.editForm?.get('countryLookupTable') as FormControl;
  }
  get booleanCtrl() {
    return this.editForm?.get('boolean') as FormControl;
  }
  get smsEnabledCtrl() {
    return this.editForm?.get('smsEnabled') as FormControl;
  }
  get dateValidationCtrl() {
    return this.editForm?.get('dateValidation') as FormControl;
  }
  get excludeFutureDatesCtrl() {
    return this.editForm?.get('excludeFutureDates') as FormControl;
  }
  get excludePastDatesCtrl() {
    return this.editForm?.get('excludePastDates') as FormControl;
  }
  get dateExpressions() {
    return this.editForm.controls['dateExpressions'] as FormArray;
  }
  get identifierTypeRadioCtrl() {
    return this.editForm?.get('identifierType') as FormControl;
  }
  get patternTextCtrl() {
    return this.editForm?.get('pattern') as FormControl;
  }
  get startingValueTextCtrl() {
    return this.editForm?.get('startingValue') as FormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityId: string;
      entityComponentId: string;
      entityPropertyId: string;
      entityProperties: (any & Row)[];
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditPropertyItemComponent>,
    public entityStore: Store<EntityState>,
    private memberStore: Store<fromMember.MemberState>,
    public layoutService: LayoutService,
    private entityService: EntityService,
    private switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formSubmit();

    this.getEntityPropertyDetailAction();
    this.getLookupTableListAction();
   
    this.getEntityPropertySelector();
    this.getEntityPropertiesSelector();
  }

  getEntityPropertiesAction(): void {
    let request: GetEntityPropertiesRequest = {
      entityId: this.data.entityId,
    };

    this.entityStore.dispatch(getEntityPropertiesAction({ request }));
  }

  getEntityPropertiesSelector(): void {
    this.entityStore
      .pipe(select(getEntityPropertiesSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !state.isLoading) {
        
          this.propertiesData = (state?.payload?.propertyItems ?? []).map(
            (item: any) => {
              return {
                id: item?.id,
                propertyName: item?.propertyName,
                type: item?.type,
                dataType: item?.type,
                importKey: item?.importKey,
                inheritance: item?.inheritance,
                isSystem: item?.type === EntityPropertyType.System,
                entityComponentId: item.entityComponentId,
                entityPropertyId: item.id,
              };
            }
          );
          this.initDataPropertyLookup();
        }
      });
  }

  serializeDate() {
    const expressionArray = this.dateExpressions?.controls?.map((epx) => {
      return {
        validation: Number(epx?.value['validation']),
        validationType: Number(epx?.value['validationType']),
        value: epx?.value['value'],
      };
    });
    return JSON.stringify(expressionArray);
  }

  initFormGroup(disabledToggles: any): void {
    const isDisabledEditConfig = stringToBoolean(
      this.propertyDetail?.configs?.disabledEditConfig
    );

    // Used for Identifier Property only
    const {
      // Used for Auto-Generated case
      autogenerated,
      pattern,
      patternPrefix,
      patternSuffix,
      startingValue,
      
      // Used for User-Defined case
      required,
      readOnly,
      includeInBulkUpdate,
      maximumLength,
      maxLengthInput,
    } = this.propertyDetail?.configs || {};
    const fullPatternValue = patternPrefix + pattern + patternSuffix;
    this.currentPrefix = patternPrefix;
    this.currentPattern = pattern || IDENTIFIER_PATTERN_DEFAULT_VALUE; // Used 7 `#` for default values
    this.currentSuffix = patternSuffix;
    // End of using variables for Identifier Property only

    this.editForm = this.fb.group({
      name: this.fb.control(this.propertyDetail?.propertyName, {
        validators: [Validators.required, Validators.maxLength(255)],
        asyncValidators: checkApiValidator(
          this.entityService.checkPropertyNameExist,
          'name',
          undefined,
          {
            params: {
              entityId: this.data?.entityId,
              entityPropertyId: this.data?.entityPropertyId,
              entityComponentId: this.data?.entityComponentId,
            },
          }
        ),
      }),
      requiredCondition: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.required),
        disabled:
          (stringToBoolean(this.propertyDetail?.configs?.readOnly) ||
          isDisabledEditConfig ||
          disabledToggles?.includes(BooleanPropertyConfigType.Required)) &&
          !this.isIdentifierPropertyType, // -> Should Enable in case Identifier Property Type
      }),
      readOnlyCondition: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.readOnly),
        disabled: 
          isDisabledEditConfig ||
          disabledToggles?.includes(BooleanPropertyConfigType.ReadOnly),
      }),
      maskedCondition: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.masked),
        disabled: isDisabledEditConfig,
      }),
      includeInBulkUpdate: this.fb.control({
        value: this.isIdentifierPropertyType ? // -> Should not be controlled by Read-Only in case Identifier Property Type,
          stringToBoolean(this.propertyDetail?.configs?.includeInBulkUpdate)
        : (stringToBoolean(this.propertyDetail?.configs?.readOnly)
          ? false
          : !!this.propertyDetail?.importKey),
        disabled:
          (stringToBoolean(this.propertyDetail?.configs?.readOnly) ||
          isDisabledEditConfig ||
          disabledToggles?.includes(BooleanPropertyConfigType.IncludeInBulkUpdate)) &&
          !this.isIdentifierPropertyType, // -> Should Enable in case Identifier Property Type,
      }),
      prefixList: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.prefixList),
        disabled:
          isDisabledEditConfig ||
          disabledToggles?.includes(BooleanPropertyConfigType.PrefixList),
      }),
      boolean: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.boolean),
        disabled:
          isDisabledEditConfig ||
          disabledToggles?.includes(BooleanPropertyConfigType.Boolean),
      }),
      importLabel: this.fb.control(this.propertyDetail?.importKey, {
        validators: [Validators.required, Validators.maxLength(250)],
        asyncValidators: checkApiValidator(
          this.entityService.checkPropertyNameExist,
          'importKey',
          undefined,
          {
            params: {
              entityId: this.data?.entityId,
              entityPropertyId: this.data?.entityPropertyId,
              entityComponentId: this.data?.entityComponentId,
            },
          }
        ),
      }),
      excludeFutureDates: this.fb.control({
        value: stringToBoolean(
          this.propertyDetail?.configs?.excludeFutureDates
        ),
        disabled: isDisabledEditConfig,
      }),
      excludePastDates: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.excludePastDates),
        disabled: isDisabledEditConfig,
      }),
      dateValidation: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.dateValidation),
        disabled: isDisabledEditConfig,
      }),
      dateExpressions: this.fb.array([]),
      maximumLength: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.maximumLength),
        disabled:
          isDisabledEditConfig ||
          disabledToggles?.includes(BooleanPropertyConfigType.MaximumLength),
      }),
      maxLengthInput: this.fb.control({
        value: this.propertyDetail?.configs?.maxLengthInput,
        disabled:
          isDisabledEditConfig ||
          disabledToggles?.includes(PropertyConfigOption.MaxLengthInput),
      }),
      // Used for Identifier Property Type only
      identifierType: this.fb.control({
        value: stringToBoolean(autogenerated),
        disabled: isDisabledEditConfig
      }),
      pattern: this.fb.control({
        value: fullPatternValue || IDENTIFIER_PATTERN_DEFAULT_VALUE,
        disabled: isDisabledEditConfig
      }, autogenerated ? {
        validators: [Validators.required]
      } : null),
      startingValue: this.fb.control({
        value: startingValue || '',
        disabled: isDisabledEditConfig
      }),
      // End of using for Identifier Property Type only
    });

    this.elementNames = this.getProperties(this.propertyDetail?.type);
    this.elementNames.forEach((propertyConfigType) =>
      this.addFormControls(propertyConfigType)
    );
    if (this.propertyDetail?.configs?.isStatic) {
      Object.keys(this.editForm.controls).forEach((key) => {
        if (
          (key === BooleanPropertyConfigType.IncludeInBulkUpdate &&
            !stringToBoolean(
              this.editForm.get(BooleanPropertyConfigType.ReadOnly)?.value
            )) ||
          key === 'name' ||
          this.propertyDetail?.type === EntityPropertyType.Phone ||
          this.propertyDetail?.type === EntityPropertyType.Email ||
          key === PropertyConfigOption.LookupTable ||
          (this.propertyDetail?.type === EntityPropertyType['Person Name'] &&
            key === 'requiredCondition')
        ) {
          if (this.propertyDetail?.type === EntityPropertyType.Email) {
            this.editForm
              .get(BooleanPropertyConfigType.IncludeInBulkUpdate)
              ?.setValue(true);
          }
          if (this.propertyDetail?.type === EntityPropertyType.Phone) {
            this.editForm
              .get(BooleanPropertyConfigType.SMSEnabled)
              ?.disable({ emitEvent: false });
            this.editForm
              .get(BooleanPropertyConfigType.IncludeInBulkUpdate)
              ?.setValue(true);
          }
          return;
        }
        this.editForm.get(key)?.disable({ emitEvent: false });
      });
    }
    this.checkDisabledImportLabel(
      this.editForm.get(BooleanPropertyConfigType.IncludeInBulkUpdate)?.value
    );
    this.initDateValidationExpressions();
  }

  refreshError(index: number) {
    (this.dateExpressions.at(index) as FormGroup).setControl(
      'value',
      this.fb.control('')
    );
    this.dateExpressions.markAsPending();
    this.dateExpressions.at(index).markAsPending();
 
    const expressionArray = this.dateExpressions?.controls?.map((epx) => {
      return {
        validation: Number(epx?.value['validation']),
        validationType: Number(epx?.value['validationType']),
        value: epx?.value['value'],
      };
    });

    if (expressionArray.find((item: any) => item.validationType === ValidationType.Property)) {
      this.getEntityPropertiesAction();
    }
  }

  initDataPropertyLookup() {
    this.dataPropertylookupOptions = [];
    let dateProperties =
      this.propertiesData?.filter(
        (prop) =>
          prop.type === EntityPropertyType.Date &&
          prop.propertyName !== this.nameCtrl.value
      ) || []?.sort((a: any, b: any) => b.localeCompare(a));

    this.dataPropertylookupOptions = dateProperties?.map((prop) => {
      const option: Option = {
        value: prop.id,
        displayValue: prop.propertyName,
      };
      return option;
    });
  }

  initDateValidationExpressions() {
    if (this.propertyDetail?.type === EntityPropertyType.Date) {
      if (this.propertyDetail?.configs?.dateValidationExpressions) {
        const expressions = JSON.parse(
          this.propertyDetail.configs.dateValidationExpressions
        );
        if (expressions.length > 0) {
          this.dateValidationCtrl.setValue(true); 
          expressions.forEach((exp: any) => {
            let newValue = exp.value;
            if (exp.validationType === ValidationType.Property)
              newValue = (exp.value || '').toLowerCase();
            const dateExpression = this.fb.group({
              validation: [exp.validation, Validators.required],
              validationType: [exp.validationType, Validators.required],
              value: [newValue, Validators.required],
            });
            this.dateExpressions.push(dateExpression);
          });
        }
        
      }
    }
    return;
  }

  onClickRemoveExpression(index: number) {
    this.dateExpressions.removeAt(index);
    if (!this.dateExpressions.length) {
      this.editForm
        .get(BooleanPropertyConfigType.DateValidation)
        ?.setValue(false);
    }
  }

  removeAllDateExpressions() {
    const expressionCount = this.dateExpressions?.controls?.length;
    for (let i = 0; i <= expressionCount; i++) {
      this.dateExpressions.removeAt(0);
    }
  }

  onClickNewValidation() {
    const dateExpression = this.fb.group({
      validation: [null, Validators.required],
      validationType: [null, Validators.required],
      value: [null, Validators.required],
    });
    this.dateExpressions.push(dateExpression);
  }

  getEntityPropertyDetailAction(): void {
    let request: GetEntityPropertyDetailRequest = {
      entityPropertyId: this.data?.entityPropertyId,
    };
    this.isLoading = true;
    this.entityStore.dispatch(getEntityPropertyDetailAction({ request }));
  }

  getLookupTableListAction(): void {
    this.memberStore.dispatch(
      LookupTableActions.getLookupTableList({
        query: { SortNames: 'Name', SortType: 0 },
      })
    );
  }

  getEntityPropertySelector(): void {
    combineLatest([
      this.entityStore.select(fromMember.selectLookupTable),
      this.entityStore.select(getEntityPropertyDetailSelector),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([lookUp, entity]) => {
        if (lookUp && !lookUp.isLoading && entity && !entity.isLoading) {
          this.isLoading = false;
          this.lookupTableOptions = lookUp?.lookupTableList?.map(
            (item: any) => {
              return {
                displayValue: item?.name,
                value: item?.id,
              };
            }
          );

          this.propertyDetail = entity.payload;

          // Used for Identifier Property only
          this.isIdentifierPropertyType = entity.payload?.type !== EntityPropertyType.Identifier ? null : true;
          this.identifierPropertyTypeIsAutoGenerated = 
            this.isIdentifierPropertyType === true ?
              stringToBoolean(entity.payload?.configs?.autogenerated) :
              null;
          this.isIdentifierPropertyShouldBeGreyOut = 
            this.isIdentifierPropertyType === true ?
              entity.payload?.isIdentifierPropertyHasValue :
              undefined;
          this.importLabelDefaultValueForIdentifier = entity.payload?.importKey || entity.payload?.propertyName || '';
          // End of using variables for Identifier Property only

          this.isUnique = stringToBoolean(this.propertyDetail?.configs?.unique);
          this.isEncrypted = stringToBoolean(
            this.propertyDetail?.configs?.encrypted
          );
          this.showMasked = this.propertiesMasked.includes(
            this.propertyDetail!.type
          );

          this.currentLookupTable =
            (lookUp?.lookupTableList || []).find(
              (lookupTable) =>
                lookupTable.id ===
                this.propertyDetail?.configs?.[PropertyConfigOption.LookupTable]
            )?.name || '';

          const disabledToggles =
            this.propertyDetail?.configs?.disabledToggles?.replace(' ', '')?.split(',');

          this.isShowEncrypted = this.propertyDetail?.type
            ? this.propertiesEncrypt?.includes(this.propertyDetail.type)
            : false;
          this.isShowUnique = this.propertyDetail?.type
            ? this.propertiesUnique?.includes(this.propertyDetail.type)
            : false;
          this.initFormGroup(disabledToggles);
          // this.initDataPropertyLookup();
        }
      });
  }

  changeToggle(value: boolean, toggleName: string = '') {
    switch (toggleName) {
      case BooleanPropertyConfigType.InclusiveInRange: {
        if (!value) {
          this.editForm.get(PropertyConfigOption.MinInput)?.setValue('');
          this.editForm.get(PropertyConfigOption.MaxInput)?.setValue('');
          this.editForm
            .get(PropertyConfigOption.MinInput)
            ?.disable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigOption.MaxInput)
            ?.disable({ onlySelf: true });
        } else {
          this.editForm
            .get(PropertyConfigOption.MinInput)
            ?.enable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigOption.MaxInput)
            ?.enable({ onlySelf: true });
        }
        break;
      }
      case BooleanPropertyConfigType.PrefixList: {
        if (!value) {
          const defaultValue =
            this.propertyDetail?.configs?.[
              PropertyConfigOption.DefaultTitleLookupTable
            ] || '';
          this.editForm.setControl(
            PropertyConfigOption.LookupTable,
            this.fb.control({ value: defaultValue, disabled: true })
          );
        } else {
          this.editForm
            .get(PropertyConfigOption.LookupTable)
            ?.enable({ onlySelf: true });
        }
        break;
      }
      case BooleanPropertyConfigType.MaximumLength: {
        if (!value) {
          this.editForm.get(PropertyConfigOption.MaxLengthInput)?.setValue('');
          this.editForm
            .get(PropertyConfigOption.MaxLengthInput)
            ?.disable({ onlySelf: true });
        } else {
          this.editForm
            .get(PropertyConfigOption.MaxLengthInput)
            ?.enable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigOption.MaxLengthInput)
            ?.addValidators(Validators.required);
        }
        break;
      }
      case BooleanPropertyConfigType.FractionalLength: {
        if (!value) {
          this.editForm
            .get(PropertyConfigOption.FractionalLengthInput)
            ?.setValue('');
          this.editForm
            .get(PropertyConfigOption.FractionalLengthInput)
            ?.disable({ onlySelf: true });
        } else {
          this.editForm
            .get(PropertyConfigOption.FractionalLengthInput)
            ?.enable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigOption.FractionalLengthInput)
            ?.addValidators(Validators.required);
        }
        break;
      }
      case BooleanPropertyConfigType.IncludeInBulkUpdate: {
        this.checkDisabledImportLabel(value);
        break;
      }
      case BooleanPropertyConfigType.ReadOnly: {
        // Not disable and set value of IncludeBulkUpdate to OFF with Identifier Property Type
        if (!this.isIdentifierPropertyType) {
          if (value) {
          this.changeToggle(
            false,
            BooleanPropertyConfigType.IncludeInBulkUpdate
          );
          this.editForm
            .get(BooleanPropertyConfigType.IncludeInBulkUpdate)
            ?.setValue(false);
          this.editForm
            .get(BooleanPropertyConfigType.IncludeInBulkUpdate)
            ?.disable({
              onlySelf: true,
              emitEvent: false,
            });
          this.editForm
            .get('requiredCondition')
            ?.setValue(false);
          this.editForm
            .get('requiredCondition')
            ?.disable({
              onlySelf: true,
              emitEvent: false,
            });
          } else {
            this.editForm
              .get('requiredCondition')?.enable();
            this.includeInBulkUpdateCtrl?.enable();
          }
        }
        break;
      }
      case BooleanPropertyConfigType.Boolean: {
        if (value) {
          this.editForm
            .get(PropertyConfigOption.Affirmative)
            ?.enable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigOption.Negative)
            ?.enable({ onlySelf: true });
        } else {
          this.editForm
            .get(PropertyConfigOption.Affirmative)
            ?.setValue(DefaultBooleanValue.Affirmative);
          this.editForm
            .get(PropertyConfigOption.Negative)
            ?.setValue(DefaultBooleanValue.Negative);
          this.editForm
            .get(PropertyConfigOption.Affirmative)
            ?.disable({ onlySelf: true, emitEvent: false });
          this.editForm
            .get(PropertyConfigOption.Negative)
            ?.disable({ onlySelf: true, emitEvent: false });
        }
        break;
      }
      case BooleanPropertyConfigType.UsedStateLookupTable: {
        if (!value) {
          const defaultValue =
            this.propertyDetail?.configs?.[
              PropertyConfigOption.DefaultStateLookupTable
            ] || '';
          this.editForm.setControl(
            PropertyConfigOption.StateLookupTable,
            this.fb.control({ value: defaultValue, disabled: true })
          );
        } else {
          this.editForm
            .get(PropertyConfigOption.StateLookupTable)
            ?.enable({ onlySelf: true });
        }
        break;
      }
      case BooleanPropertyConfigType.UsedCountryLookupTable: {
        if (!value) {
          const defaultValue =
            this.propertyDetail?.configs?.[
              PropertyConfigOption.DefaultCountryLookupTable
            ] || '';
          this.editForm.setControl(
            PropertyConfigOption.CountryLookupTable,
            this.fb.control({ value: defaultValue, disabled: true })
          );
        } else {
          this.editForm
            .get(PropertyConfigOption.CountryLookupTable)
            ?.enable({ onlySelf: true });
        }
        break;
      }
      case BooleanPropertyConfigType.ExcludeFutureDates: {
        if (value) {
          this.editForm
            .get(BooleanPropertyConfigType.ExcludePastDates)
            ?.setValue(false);
        }
        break;
      }
      case BooleanPropertyConfigType.ExcludePastDates: {
        if (value) {
          this.editForm
            .get(BooleanPropertyConfigType.ExcludeFutureDates)
            ?.setValue(false);
        }
        break;
      }
      case BooleanPropertyConfigType.DateValidation: {
        if (!value) {
          this.removeAllDateExpressions();
        } else {
          this.onClickNewValidation();
        }
        break;
      }
    }
  }

  checkDisabledImportLabel(value: boolean): void {
    if (value) {
      this.editForm
        .get('importLabel')
        ?.enable({ onlySelf: true, emitEvent: false });
    } else {
      this.editForm.get('importLabel')?.setValue('');
      this.editForm
        .get('importLabel')
        ?.disable({ onlySelf: true, emitEvent: false });
    }
  }

  getProperties(type?: EntityPropertyType): BooleanPropertyConfigTypeValue[] {
    const properties = [];
    switch (type) {
      case EntityPropertyType.Currency:
        properties.push(BooleanPropertyConfigType.InclusiveInRange);
        break;
      case EntityPropertyType.Text:
      case EntityPropertyType.RichText:
      case EntityPropertyType.Identifier:
        properties.push(BooleanPropertyConfigType.MaximumLength);
        break;
      case EntityPropertyType.Decimal:
        properties.push(BooleanPropertyConfigType.InclusiveInRange);
        properties.push(BooleanPropertyConfigType.FractionalLength);
        break;
      case EntityPropertyType['Whole Number']:
        properties.push(BooleanPropertyConfigType.InclusiveInRange);
        break;
      case EntityPropertyType.Phone:
        properties.push(BooleanPropertyConfigType.SMSEnabled);
        break;
      case EntityPropertyType['Person Name']:
        properties.push(BooleanPropertyConfigType.PrefixList);
        break;
      case EntityPropertyType.Binary:
        properties.push(BooleanPropertyConfigType.Boolean);
        break;
      case EntityPropertyType.Address:
        properties.push(BooleanPropertyConfigType.UsedStateLookupTable);
        properties.push(BooleanPropertyConfigType.UsedCountryLookupTable);
        break;
      default:
        break;
    }
    return properties as BooleanPropertyConfigTypeValue[];
  }

  addFormControls(type: BooleanPropertyConfigTypeValue): void {
    this.setCustomValidator();
    const disabledToggles = this.propertyDetail?.configs?.disabledToggles?.split(',');
    switch (type) {
      case BooleanPropertyConfigType.InclusiveInRange: {
        this.editForm.addControl(
          BooleanPropertyConfigType.InclusiveInRange,
          this.fb.control(
            stringToBoolean(
              this.propertyDetail?.configs?.[
                BooleanPropertyConfigType.InclusiveInRange
              ]
            )
          )
        );
        this.editForm.addControl(
          PropertyConfigOption.MinInput,
          this.fb.control(
            this.propertyDetail?.configs?.[PropertyConfigOption.MinInput] || ''
          )
        );
        this.editForm.addControl(
          PropertyConfigOption.MaxInput,
          this.fb.control(
            this.propertyDetail?.configs?.[PropertyConfigOption.MaxInput] || ''
          )
        );
        break;
      }
      case BooleanPropertyConfigType.MaximumLength: {
        const maxLengthValue = stringToBoolean(
          this.propertyDetail?.configs?.[
            BooleanPropertyConfigType.MaximumLength
          ]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.MaximumLength,
          this.fb.control(maxLengthValue)
        );
        this.editForm.addControl(
          PropertyConfigOption.MaxLengthInput,
          this.fb.control(
            this.propertyDetail?.configs?.[
              PropertyConfigOption.MaxLengthInput
            ] || ''
          )
        );
        if (maxLengthValue) {
          this.editForm
            .get(PropertyConfigOption.MaxLengthInput)
            ?.addValidators(Validators.required);
        }
        break;
      }
      case BooleanPropertyConfigType.FractionalLength: {
        const fractionalLengthValue = stringToBoolean(
          this.propertyDetail?.configs?.[
            BooleanPropertyConfigType.FractionalLength
          ]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.FractionalLength,
          this.fb.control(fractionalLengthValue)
        );
        this.editForm.addControl(
          PropertyConfigOption.FractionalLengthInput,
          this.fb.control(
            this.propertyDetail?.configs?.[
              PropertyConfigOption.FractionalLengthInput
            ] || ''
          )
        );
        if (fractionalLengthValue) {
          this.editForm
            .get(PropertyConfigOption.FractionalLengthInput)
            ?.addValidators(Validators.required);
        }
        break;
      }
      case BooleanPropertyConfigType.SMSEnabled: {
        this.editForm.addControl(
          BooleanPropertyConfigType.SMSEnabled,
          this.fb.control(
            stringToBoolean(
              this.propertyDetail?.configs?.[
                BooleanPropertyConfigType.SMSEnabled
              ]
            )
          )
        );
        break;
      }
      case BooleanPropertyConfigType.PrefixList: {
        const prefixListValue = stringToBoolean(
          this.propertyDetail?.configs?.[BooleanPropertyConfigType.PrefixList]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.PrefixList,
          this.fb.control(prefixListValue)
        );
        this.editForm.addControl(
          PropertyConfigOption.LookupTable,
          this.fb.control(
            {
              value:
                this.propertyDetail?.configs?.[
                  PropertyConfigOption.LookupTable
                ] || '',
              disabled: !prefixListValue,
            },
            Validators.required
          )
        );
        break;
      }
      case BooleanPropertyConfigType.Boolean: {
        this.editForm.addControl(
          BooleanPropertyConfigType.Boolean,
          this.fb.control({
            value: stringToBoolean(
              this.propertyDetail?.configs?.[BooleanPropertyConfigType.Boolean]
            ),
            disabled: stringToBoolean(
              this.propertyDetail?.configs?.disabledEditConfig
            ),
          })
        );
        this.editForm.addControl(
          PropertyConfigOption.Affirmative,
          this.fb.control(
            {
              value: this.propertyDetail?.configs?.[PropertyConfigOption.Affirmative] || '',
              disabled: stringToBoolean(disabledToggles?.includes(PropertyConfigOption.Affirmative)),
            },
            [Validators.required, this.booleanDisplayValueValidator()]
          )
        );
        this.editForm.addControl(
          PropertyConfigOption.Negative,
          this.fb.control(
            {
              value: this.propertyDetail?.configs?.[PropertyConfigOption.Negative] || '',
              disabled: stringToBoolean(disabledToggles?.includes(PropertyConfigOption.Negative)),
            },
            [Validators.required, this.booleanDisplayValueValidator()]
          )
        );
        break;
      }
      case BooleanPropertyConfigType.UsedStateLookupTable: {
        const usedStateLookupTable = stringToBoolean(
          this.propertyDetail?.configs?.[
            BooleanPropertyConfigType.UsedStateLookupTable
          ]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.UsedStateLookupTable,
          this.fb.control(usedStateLookupTable)
        );
        this.editForm.addControl(
          PropertyConfigOption.StateLookupTable,
          this.fb.control(
            {
              value:
                this.propertyDetail?.configs?.[
                  PropertyConfigOption.StateLookupTable
                ] || '',
              disabled: !usedStateLookupTable,
            },
            Validators.required
          )
        );
        break;
      }
      case BooleanPropertyConfigType.UsedCountryLookupTable: {
        const usedCountryLookupTable = stringToBoolean(
          this.propertyDetail?.configs?.[
            BooleanPropertyConfigType.UsedCountryLookupTable
          ]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.UsedCountryLookupTable,
          this.fb.control(usedCountryLookupTable)
        );
        this.editForm.addControl(
          PropertyConfigOption.CountryLookupTable,
          this.fb.control(
            {
              value:
                this.propertyDetail?.configs?.[
                  PropertyConfigOption.CountryLookupTable
                ] || '',
              disabled: !usedCountryLookupTable,
            },
            Validators.required
          )
        );
        break;
      }
      default:
        return;
    }
  }

  setCustomValidator() {
    this.customValidators = [this.validateRequired(), this.minMaxValidator()];
  }

  validateRequired(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent || !control.parent.get('inclusiveInRange')?.value) {
        return null;
      }
      const { value: minValue } = control.parent.get('minInput') as FormControl;
      const { value: maxValue } = control.parent.get('maxInput') as FormControl;
      if (!minValue?.length && !maxValue?.length) {
        return { required: true };
      }
      return null;
    };
  }

  minMaxValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !control.parent?.get('inclusiveInRange')?.value ||
        !control.parent?.get('minInput') ||
        !control.parent?.get('maxInput')
      ) {
        return null;
      }
      const { value: minValue } = control.parent?.get(
        'minInput'
      ) as FormControl;
      const { value: maxValue } = control.parent?.get(
        'maxInput'
      ) as FormControl;
      return maxValue.length && minValue.length && +minValue > +maxValue
        ? { minHigherThanMax: true }
        : null;
    };
  }

  booleanDisplayValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !control.parent?.get(BooleanPropertyConfigType.Boolean)?.value ||
        !control.parent?.get(PropertyConfigOption.Affirmative) ||
        !control.parent?.get(PropertyConfigOption.Negative)
      ) {
        return null;
      }
      let { value: affirmativeValue } = control.parent?.get(
        PropertyConfigOption.Affirmative
      ) as FormControl;
      let { value: negativeValue } = control.parent?.get(
        PropertyConfigOption.Negative
      ) as FormControl;
      return affirmativeValue &&
        negativeValue &&
        affirmativeValue === negativeValue
        ? { duplicateValue: true }
        : null;
    };
  }

  markMinMaxAsTouched(): void {
    this.editForm
      .get(PropertyConfigOption.MinInput)
      ?.markAsTouched({ onlySelf: true });
    this.editForm
      .get(PropertyConfigOption.MinInput)
      ?.updateValueAndValidity({ emitEvent: false });
    this.editForm
      .get(PropertyConfigOption.MaxInput)
      ?.markAsTouched({ onlySelf: true });
    this.editForm
      .get(PropertyConfigOption.MaxInput)
      ?.updateValueAndValidity({ emitEvent: false });
  }

  markDisplayValueAsTouched(): void {
    this.editForm
      .get(PropertyConfigOption.Affirmative)
      ?.markAsTouched({ onlySelf: true });
    this.editForm
      .get(PropertyConfigOption.Affirmative)
      ?.updateValueAndValidity({ emitEvent: false });
    this.editForm
      .get(PropertyConfigOption.Negative)
      ?.markAsTouched({ onlySelf: true });
    this.editForm
      .get(PropertyConfigOption.Negative)
      ?.updateValueAndValidity({ emitEvent: false });
  }

  formSubmit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.editForm.get('name')?.updateValueAndValidity();
          this.editForm.get('importLabel')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit(): void {
    const configKey = Object.values(PropertyConfigOption);
    const booleanKey = Object.values(BooleanPropertyConfigType);
    const formValue = this.editForm.getRawValue();
    let config: PropertyConfig = {} as PropertyConfig;
    Object.keys(formValue).forEach((key) => {
      if (configKey.includes(key as any) && formValue[key] != null) {
        (config as any)[key] = Array.isArray(formValue[key])
          ? JSON.stringify(formValue[key])
          : formValue[key].toString() || '';
      }
      if (booleanKey.includes(key as any)) {
        (config as any)[key] = (!!formValue[key]).toString();
      }
    });

    config.required = this.editForm.get('requiredCondition')?.value.toString();
    config.readOnly = this.editForm.get('readOnlyCondition')?.value.toString();
    config.masked = this.editForm.get('maskedCondition')?.value.toString();

    if (this.propertyDetail?.type === EntityPropertyType.Date) {
      config.excludeFutureDates = this.editForm
        .get('excludeFutureDates')
        ?.value.toString();
      config.excludePastDates = this.editForm
        .get('excludePastDates')
        ?.value.toString();
      config.dateValidation = this.editForm
        .get('dateValidation')
        ?.value.toString();
      config.dateValidationExpressions = this.serializeDate();
    }

    // Used for Identifier Property Type only
    if (this.propertyDetail?.type === EntityPropertyType.Identifier) {
      // When save switch between Auto Generated & User-Defined, values of not chosen radio case should be set to default
      config.autogenerated = this.identifierTypeRadioCtrl?.value.toString();
      config.pattern = this.identifierPropertyTypeIsAutoGenerated ? this.currentPattern : '';
      config.patternPrefix = this.identifierPropertyTypeIsAutoGenerated ? this.currentPrefix : '';
      config.patternSuffix = this.identifierPropertyTypeIsAutoGenerated ? this.currentSuffix : '';
      config.startingValue = this.identifierPropertyTypeIsAutoGenerated ? this.startingValueTextCtrl?.value : '';

      config.readOnly = this.identifierPropertyTypeIsAutoGenerated ? "false" : this.readOnlyConditionCtrl?.value.toString();
      config.required = this.identifierPropertyTypeIsAutoGenerated ? "false" : this.requiredConditionCtrl?.value.toString();
      config.maximumLength = this.identifierPropertyTypeIsAutoGenerated ? "false" : this.maximumLengthCtrl?.value.toString();
      config.maxLengthInput = this.identifierPropertyTypeIsAutoGenerated ? "" : (this.maximumLengthCtrl?.value ? this.maxLengthInputCtrl?.value : "");
      config.includeInBulkUpdate = this.identifierPropertyTypeIsAutoGenerated ? "false" : this.includeInBulkUpdateCtrl?.value.toString();
    }
    // End of used for Identifier Property only

    const request: SetEntityPropertyRequest = {
      entityComponentId: this.data?.entityComponentId,
      entityPropertyId: this.data?.entityPropertyId,
      name: this.nameCtrl.value.trim(),
      importKey: this.editForm.get(
        BooleanPropertyConfigType.IncludeInBulkUpdate
      )?.value
        ? this.importLabelCtrl.value?.trim()
        : '',
      configs: config as PropertyConfig,
    };

    // Adjust body request only for Identifier case to avoid affected to other Property Type
    if (this.propertyDetail?.type === EntityPropertyType.Identifier) {
      if (this.identifierPropertyTypeIsAutoGenerated) {
        request.importKey = '';
      }

      // Delete redundant fields on request (Only with Identifier)
      delete request.configs.prefixList;
      delete request.configs.boolean;
      delete request.configs.excludeFutureDates;
      delete request.configs.excludePastDates;
      delete request.configs.dateValidation;
      delete request.configs.masked;
    }
    // End of adjustment

    this.entityStore.dispatch(setEntityPropertyAction({ request }));
    this.dialogRef.close({ propertyName: this.nameCtrl?.value });
  }

  onClickCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  //Fix the bug when the form is considered valid if the Age Limit controller is focused for the first time
  hideAddNewButton() {
    return (
      this.dateExpressions.length === 0 ||
      this.dateExpressions.status !== AbstractControlStatus.VALID ||
      !this.addNewDateValidation
    );
  }

  ageLimitFieldFocus(item: any) {
    if (item?.status !== AbstractControlStatus.VALID) {
      this.addNewDateValidation = false;
      this.dateExpressions.markAsPending();
    } else {
      this.addNewDateValidation = true;
    }
  }

  // Used for Identifier Property only
  onChangeIdentifierTypeRadio($event: boolean) {
    this.identifierPropertyTypeIsAutoGenerated = $event;
    if ($event === false) {
      this.setUserDefinedTogglesToDefaultValues();
      this.patternTextCtrl.reset();
      this.patternTextCtrl.removeValidators([Validators.required]);
      this.patternTextCtrl.updateValueAndValidity();
      this.startingValueTextCtrl.reset();

      this.importLabelCtrl.setValue(this.importLabelDefaultValueForIdentifier);
      this.importLabelCtrl.addValidators([Validators.required]);
      this.importLabelCtrl.updateValueAndValidity();
    } else {
      this.patternTextCtrl.setValue(IDENTIFIER_PATTERN_DEFAULT_VALUE);
      this.patternTextCtrl.addValidators([Validators.required]);
      this.patternTextCtrl.updateValueAndValidity();

      this.importLabelCtrl.removeValidators([Validators.required]);
      this.importLabelCtrl.updateValueAndValidity();
      this.checkDisabledImportLabel(this.includeInBulkUpdateCtrl.value);
    }
  }

  onChangePattern() {
    const val = this.patternTextCtrl.value as string;
    if (val !== '' && this.patternRegex.test(val)) {
      const { prefix, pattern, suffix } = analyzeIdentifierPattern(val);
      this.currentPrefix = prefix;
      this.currentPattern = pattern;
      this.currentSuffix = suffix;
    }
    this.validateStartingValue();
  }


  validateStartingValue() {
    if (this.currentPattern && this.startingValueTextCtrl.value && this.startingValueTextCtrl.value.length !== this.currentPattern.length) {
      this.startingValueTextCtrl?.setErrors({ 'incorrect': true, 'errorMessage': 'Starting Value does not match the defined pattern.' });
      this.startingValueTextCtrl?.markAsTouched();
    } else {
      this.startingValueTextCtrl?.setErrors({ 'incorrect': false, 'errorMessage': '' });
      this.startingValueTextCtrl?.updateValueAndValidity();
    }
  }

  setUserDefinedTogglesToDefaultValues() {
    this.requiredConditionCtrl.setValue(false);
    this.includeInBulkUpdateCtrl.setValue(true);
    this.checkDisabledImportLabel(true);
    this.readOnlyConditionCtrl.setValue(false);
    this.maximumLengthCtrl.setValue(false);
    this.maxLengthInputCtrl.reset();
  }
  // End of functions used for Identifier Property only
}
