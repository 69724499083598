import { createAction, props } from '@ngrx/store';
import {
  GetGeneratedReportDetailRequest,
  GetGeneratedReportsInformationRequest,
  GetGeneratedReportsInformationResponse,
  ValidateGenerateAge65LetterRequest,
  ValidateGenerateAge65LetterResponse,
  PrepareDocumentResponse,
  MunicipalityGenerateDocument,
  DocumentTemplateResponse,
} from '../../services/models/documents.model';
import {
  GetListDocumentTemplateRequest,
  GetListDocumentTemplateResponse,
} from 'src/app/admin/features/template/services/models';
import { GenerateDocument, GenerateDocumentResponse } from '@ptg-member/types/models/generate-document.models';
import { GetRetirementBenefitDownloadDocumentRequest } from '@ptg-member/features/calculation/services/models/retirement-benefit-history.model';

export const getDocumentDownloadAction = createAction(
  '[GetDocumentDownload/API] Send Request',
  props<{ fileId: string; fileName?: string }>(),
);
export const getDocumentDownloadSuccessAction = createAction('[GetDocumentDownload/API] Success');
export const getDocumentDownloadFailureAction = createAction(
  '[GetDocumentDownload/API] Failure',
  props<{ error?: unknown }>(),
);
export const clearGetDocumentDownloadStateAction = createAction('[GetDocumentDownload] Clear');

export const prepareDownloadMultipleDocumentsAction = createAction(
  '[PrepareDownloadMultipleDocuments/API] Send Request',
  props<{ fileIds: any }>(),
);

export const prepareDownloadMultipleDocumentsSuccessAction = createAction(
  '[PrepareDownloadMultipleDocuments/API] Success',
  props<{ response: PrepareDocumentResponse }>(),
);

export const prepareDownloadMultipleDocumentsFailureAction = createAction(
  '[PrepareDownloadMultipleDocuments/API] Failure',
  props<{ error?: any }>(),
);

export const clearPrepareDownloadMultipleDocumentsStateAction = createAction(
  '[PrepareDownloadMultipleDocuments] Clear',
);

export const downloadMultipleDocumentsAction = createAction(
  '[DownloadMultipleDocuments/API] Send Request',
  props<{ zipId: string }>(),
);

export const downloadMultipleDocumentsSuccessAction = createAction('[DownloadMultipleDocuments/API] Success');

export const downloadMultipleDocumentsFailureAction = createAction(
  '[DownloadMultipleDocuments/API] Failure',
  props<{ error?: any }>(),
);

export const clearDownloadMultipleDocumentsStateAction = createAction('[DownloadMultipleDocuments] Clear');

export const checkHaveZipFileAction = createAction('[CheckHaveZipFile/API] Send Request', props<{ zipId?: string }>());
export const checkHaveZipFileSuccessAction = createAction('[CheckHaveZipFile/API] Success', props<{ response: any }>());
export const checkHaveZipFileFailureAction = createAction(
  '[CheckHaveZipFile/API] Failure',
  props<{ error?: unknown }>(),
);
export const clearCheckHaveZipFileStateAction = createAction('[CheckHaveZipFile] Clear');

// Get List Template
export const getListTemplateAction = createAction(
  '[GetListTemplate/API] Send Request',
  props<{ request: GetListDocumentTemplateRequest }>(),
);
export const getListTemplateSuccessAction = createAction(
  '[GetListTemplate/API] Success',
  props<{ response: GetListDocumentTemplateResponse }>(),
);
export const getListTemplateFailureAction = createAction('[GetListTemplate/API] Failure', props<{ error?: unknown }>());
export const clearGetListTemplateStateAction = createAction('[GetListTemplate] Clear');

export const generateParticipantDocumentsAction = createAction(
  '[GenerateParticipantDocuments/API] Send Request',
  props<{ body: GenerateDocument }>(),
);
export const generateParticipantDocumentsSuccessAction = createAction(
  '[GenerateParticipantDocuments/API] Success',
  props<{ response: GenerateDocumentResponse }>(),
);
export const generateParticipantDocumentsFailureAction = createAction(
  '[GenerateParticipantDocuments/API] Failure',
  props<{ err?: any }>(),
);
export const clearGenerateParticipantDocumentsStateAction = createAction('[ClearGenerateParticipantDocuments]');

export const generateMunicipalityDocumentsAction = createAction(
  '[GenerateMunicipalityDocuments/API] Send Request',
  props<{ body: MunicipalityGenerateDocument }>(),
);
export const generateMunicipalityDocumentsSuccessAction = createAction(
  '[GenerateMunicipalityDocuments/API] Success',
  props<{ response: GenerateDocumentResponse }>(),
);
export const generateMunicipalityDocumentsFailureAction = createAction(
  '[GenerateMunicipalityDocuments/API] Failure',
  props<{ error?: any }>(),
);
export const clearGenerateMunicipalityDocumentsStateAction = createAction('[ClearGenerateMunicipalityDocuments]');

export const getRetirementBenefitDownloadDocumentAction = createAction(
  '[GetRetirementBenefitDowloadDocument/API] Send Request',
  props<{ request: GetRetirementBenefitDownloadDocumentRequest }>(),
);
export const getRetirementBenefitDownloadDocumentSuccessAction = createAction(
  '[GetRetirementBenefitDowloadDocument/API] Success',
  props<{ response: Blob[] }>(),
);
export const getRetirementBenefitDownloadDocumentFailureAction = createAction(
  '[GetRetirementBenefitDowloadDocument/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetRetirementBenefitDownloadDocumentStateAction = createAction(
  '[GetRetirementBenefitDowloadDocument] Clear',
);

// validateGenerateAge65Letter
export const validateGenerateAge65LetterAction = createAction(
  '[ValidateGenerateAge65Letter/API] Send Request',
  props<{ request: ValidateGenerateAge65LetterRequest }>(),
);
export const validateGenerateAge65LetterSuccessAction = createAction(
  '[ValidateGenerateAge65Letter/API] Success',
  props<{ response: ValidateGenerateAge65LetterResponse }>(),
);
export const validateGenerateAge65LetterFailureAction = createAction(
  '[ValidateGenerateAge65Letter/API] Failure',
  props<{ error?: any }>(),
);
export const clearValidateGenerateAge65LetterStateAction = createAction(
  '[ValidateGenerateAge65Letter] Clear',
);

// GetGeneratedReportsInformation
export const getGeneratedReportsInformationAction = createAction(
  '[GetGeneratedReportsInformation/API] Send Request',
  props<{ request: GetGeneratedReportsInformationRequest }>(),
);
export const getGeneratedReportsInformationSuccessAction = createAction(
  '[GetGeneratedReportsInformation/API] Success',
  props<{ response: GetGeneratedReportsInformationResponse }>(),
);
export const getGeneratedReportsInformationFailureAction = createAction(
  '[GetGeneratedReportsInformation/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetGeneratedReportsInformationStateAction = createAction(
  '[GetGeneratedReportsInformation] Clear',
);

// GetGeneratedReportDetail
export const getGeneratedReportDetailAction = createAction(
  '[GetGeneratedReportDetail/API] Send Request',
  props<{ request: GetGeneratedReportDetailRequest }>(),
);
export const getGeneratedReportDetailSuccessAction = createAction(
  '[GetGeneratedReportDetail/API] Success',
  props<{ response: GetGeneratedReportsInformationResponse }>(),
);
export const getGeneratedReportDetailFailureAction = createAction(
  '[GetGeneratedReportDetail/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetGeneratedReportDetailStateAction = createAction(
  '[GetGeneratedReportDetail] Clear',
);

export const getListTemplateTagsAction = createAction(
  '[GetListTagsTemplate/API] Send Request',
  props<{ fileId: string }>()
);
export const getListTemplateTagsSuccessAction = createAction(
  '[GetListTagsTemplate/API] Success',
  props<{ response: DocumentTemplateResponse }>()
);
export const getListTemplateTagsFailureAction = createAction(
  '[GetListTagsTemplate/API] Failure',
  props<{ error?: unknown }>()
);
export const clearListTemplateTagsStateAction = createAction(
  '[GetListTagsTemplate] Clear'
);

