import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Column, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';

import { LIST_DISPLAY_PREFERENCE_TYPE, NON_RECORD_LIST_DISPLAY_TYPE } from '../../types/enums';
import { CardProperty } from '@ptg-entity-management/services/models';
import { MemberManageAttachmentListComponent } from '../member-manage-attachment-list/member-manage-attachment-list.component';

@Component({
  selector: 'ptg-member-summary-card-list',
  templateUrl: './member-summary-card-list.component.html',
  styleUrls: ['./member-summary-card-list.component.scss'],
})
export class MemberSummaryCardListComponent {
  readonly LIST_DISPLAY_PREFERENCE_TYPE = LIST_DISPLAY_PREFERENCE_TYPE;
  readonly NON_RECORD_LIST_DISPLAY_TYPE = NON_RECORD_LIST_DISPLAY_TYPE;

  currentRowIndex: number = 0;
  dataCardList: any;
  dataCardNoneList: any;

  @Input() memberId!: string;
  @Input() menuName!: string;
  @Input() propertiesConfig: CardProperty[] = [];
  @Input() data!: any[];
  @Input() columns!: Column[];
  @Input() cardData!: any;
  @Input() configDataless: any;
  @Input() lengthPg!: number;
  @Input() pageSize: number = 10;
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() notFoundMessage: string = '';
  @Input() cardId!: string;
  @Input() isLoading: boolean = true;
  @Input() isMuniPortalBenefic?: boolean;
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() pageChange = new EventEmitter<PageEvent>();
  @Output() rowClick = new EventEmitter<any & Row>();

  constructor(
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cardData) {
      this.dataCardList = this.cardData[this.cardId + '_list'];
      this.dataCardNoneList = this.cardData[this.cardId];

      if (this.dataCardList?.currentPageIndex) {
        this.pageNumber = this.dataCardList?.currentPageIndex;
      }
    }
  }

  onSortChange(event: Sort): void {
    this.sortChange.emit(event);
  }

  onChangePage(event: PageEvent): void {
    this.pageChange.emit(event);
  }

  onRowClick(row: any & Row): void {
    this.rowClick.emit(row);
  }

  viewAttachment(event: any) {
    const listBreadcrumbs = [{
      name: this.menuName || '',
      url: this.router.url
    },
    {
      name:'Attachments'
    }];
    this.dialog.open(MemberManageAttachmentListComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        breadcrumbs: listBreadcrumbs,
        recordId: event?.id,
        menuName: `${this.menuName}`,
        entityComponentId: event?.['values']?.[0]?.entityComponentId
      },
    });
  }
}
