<div class="member-navigation-page">
  <ptg-breadcrumb
    *ngIf="
      (currentSectionLayout !== SectionLayout.Payment && currentSectionLayout !== SectionLayout.PaymentHistory) ||
      !historyService.isBackNextPayroll
    "
    [listBreadcrumbs]="listBreadcrumbs"
    [isDisabledGear]="isDisabledGear"
  >
  </ptg-breadcrumb>

  <ng-container [ngSwitch]="currentSectionLayout">
    <ng-container *ngSwitchCase="SectionLayout.StatusHistory">
      <ptg-status-history></ptg-status-history>
    </ng-container>
    <ng-container *ngSwitchCase="SectionLayout.MunicipalityPayment">
      <ptg-municipality-payment [currentPageName]="listBreadcrumbs[0].name"></ptg-municipality-payment>
    </ng-container>
    <ng-container *ngSwitchCase="SectionLayout.MunicipalityServiceRecord">
      <ptg-municipality-service-history [currentPageName]="listBreadcrumbs[0].name"></ptg-municipality-service-history>
    </ng-container>
    <ng-container *ngSwitchCase="SectionLayout.Payment">
      <ptg-next-payment></ptg-next-payment>
    </ng-container>
    <ng-container *ngSwitchCase="SectionLayout.PaymentHistory">
      <ptg-payment-history-screen></ptg-payment-history-screen>
    </ng-container>
    <ng-container *ngSwitchCase="SectionLayout.ParticipantRelationship">
      <ptg-participant-relationship></ptg-participant-relationship>
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultSection"> </ng-container>
  </ng-container>

  <ng-template #defaultSection>
    <ptg-overview-header-entity #overViewHeader [shouldHideStatusDateProperty]="true" [shouldHideStatusEventProperty]="true"></ptg-overview-header-entity>
    <div class="flex flex-col p-6 gap-6">
      <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

      <!--Detail Section-->
      <ptg-panel
        *ngIf="viewType === ViewType.ListAndProperty || viewType === ViewType.PropertyOnly"
        [title]="detailViewLabel"
        [noPaddingBody]="true"
        [ngClass]="{ 'background-color': displayRule === StandalonePropertiesDisplayRule.HideEditButtonAndHighlight }"
      >
        >
        <ng-template panelExtraHeader>
          <div
            *ngIf="displayRule === StandalonePropertiesDisplayRule.None"
            class="edit-btn"
            [ngClass]="{'disabled': standaloneIsLoading}"
            (click)="onClickEditDetail()"
          >
            <mat-icon>edit</mat-icon>
            <span class="title-edit"> Edit</span>
          </div>
        </ng-template>
        <ng-template panelBody>
          <ptg-member-navigation-standalone-property
            *ngIf="viewType === ViewType.ListAndProperty || viewType === ViewType.PropertyOnly"
            [isEditable]="isEditable"
            [currentRow]="standAlonePropertyValue"
            [metadataSection]="standAloneProperty"
            [currentSectionLayout]="standAloneViewType"
            [isLoading]="standaloneIsLoading"
          >
          </ptg-member-navigation-standalone-property>
        </ng-template>
      </ptg-panel>

      <ng-container
        *ngIf="viewType === ViewType.ListAndProperty || viewType === ViewType.ListOnly"
        class="member-detail-display"
      >
        <div class="detail-content">
          <div
            *ngIf="
              entitySection?.length > 0 &&
              (viewType === ViewType.ListAndProperty || viewType === ViewType.ListOnly) &&
              selectedRecordData
            "
            class="selected-record"
          >
            <!--Selected Record-->
            <ptg-member-navigation-list-view-detail
              *ngIf="
                entitySection?.length > 0 &&
                (viewType === ViewType.ListAndProperty || viewType === ViewType.ListOnly) &&
                selectedRecordData
              "
              [menuItemName]="listLabel"
              [isList]="isList"
              [currentRow]="selectedRecordData"
              [metadataSection]="entitySection"
              [isLoading]="isLoadingListView"
              [attachmentType]="attachmentType"
              [isBenefitCard]="isBenefitCard"
              (editButtonEvent)="onClickEditRecordSelected($event)"
              (removeButtonEvent)="onClickRemoveRecordSelected($event)"
              [isMuniPortalBenefic]="isMuniPortalBenefic"
            >
            </ptg-member-navigation-list-view-detail>
          </div>
          <div class="selected-record" *ngIf="attachmentType === IncludeAttachmentType.ListRecord">
            <!--Manage Attachment-->
            <ptg-manage-member-attachment
              [isEntity]="true"
              [viewId]="viewId"
              [entityComponentId]="entityComponentId"
              [isOverview]="isOverview"
              [menuItemId]="menuItemId"
              [title]="menuName"
              [rowId]="recordId"
              [dataTable]="attachments"
              [isLoading]="isLoadingListView"
              [lengthPg]="attLengthPg"
              [pageSize]="attPageSize"
              [pageNumber]="attPageNumber"
              (pageChange)="onChangePageManageAttachments($event)"
              [cardName]="cardEntity?.cardName ?? ''"
            ></ptg-manage-member-attachment>
          </div>
        </div>
        <!--List Card-->
        <ptg-member-navigation-list-view
          [isLoading]="isLoadingListView"
          [dataTable]="listCardData"
          [columns]="columns"
          [sortInfo]="sortInfo"
          [lengthPg]="lengthPg"
          [pageSize]="pageSize"
          [pageNumber]="pageNumber"
          [(currentRowIndex)]="currentRowIndex"
          [menuItemName]="listLabel"
          [menuName]="menuName"
          [memberId]="memberId"
          [errorMsg]="errorMsg"
          [isListRecord]="attachmentType === IncludeAttachmentType.ListRecord"
          [isDragDrop]="isDragDrop"
          [isBenefitCard]="isBenefitCard"
          (changePaggingEvent)="changePaggingListView($event)"
          (addNewMetaDataEvent)="openMetaDataForm($event)"
          (manageSectionEvent)="manageSection()"
          (selectRowEvent)="selectRow($event)"
          [isCourtOrder]="isCourtOrder"
          [isMuniPortalBenefic]="isMuniPortalBenefic"
        ></ptg-member-navigation-list-view>
      </ng-container>

      <div *ngIf="viewType === ViewType.NoneConfig && attachmentType === IncludeAttachmentType.NoAttachment" class="list-data-not-found no-columns-display">
        <div class="message">Display has not been configured</div>
      </div>

      <ng-container *ngIf="attachmentType === IncludeAttachmentType.CardLevel" class="member-detail-display">
        <!--Card Attachment-->
        <ptg-member-navigation-attachment-list
          [errorMsg]="errorMsg"
          [isLoading]="isLoadingCardAttachment"
          [memberAttachmentListsData]="attachments"
          [sortInfo]="sortInfo"
          [lengthPg]="attLengthPg"
          [pageSize]="attPageSize"
          [pageNumber]="attPageNumber"
          [currentRowIndex]="currentRowIndex"
          (changePaggingEvent)="changeCardAttachmentPagging($event)"
          (upLoadFileEvent)="onOpenUpLoadAttachment($event, false)"
          (removeMetaDataAttachmentEvent)="onRemoveAttachment($event)"
          (editMetadataAttachmentEvent)="onOpenUpLoadAttachment($event, true)"
          (openPDFEvent)="onOpenPDF($event)"
        ></ptg-member-navigation-attachment-list>
      </ng-container>
    </div>
  </ng-template>
</div>
