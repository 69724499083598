import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DatePipe } from '@angular/common';

import { ViewCurrentFilterModule } from '@ptg-shared/controls/view-current-filter/view-current-filter.module';
import { ViewValueModule } from '@ptg-shared/controls/view-value/view-value.module';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { SharedModule } from '@ptg-shared/shared.module';
import {
  NextPayrollService,
  PaymentDeductionsService,
  VendorMaintenanceService,
} from '@ptg-processing/features/payroll/services';
import { NextPaymentService, PaymentHistoryService } from '@ptg-processing/features/service-provider/services';
import { EntityManagementSharedModule } from '@ptg-entity-management/entity-management.shared.module';

import { BulkUpdateModule } from '../bulk-update/bulk-update.module';
import { ReplaceLookupTableValueComponent } from './components/replace-lookup-table-value/replace-lookup-table-value.component';
import { ProfileOverviewConfigurationDetailComponent } from './containers/profile-overview-configuration-detail/profile-overview-configuration-detail.component';
import { AddNoteComponent } from './components/add-note/add-note.component';
import { AddPropertyComponent } from './components/add-property/add-property.component';
import { AddSectionComponent } from './components/add-section/add-section.component';
import { AddBenefitTypeComponent } from './components/add-benefit-type/add-benefit-type.component';
import { EarningDetailComponent } from './components/earning-detail/earning-detail.component';
import { DeductionDetailComponent } from './components/deduction-detail/deduction-detail.component';
import { DemographicComponent } from './components/demographic/demographic.component';
import { EditEarningsComponent } from './components/edit-earnings/edit-earnings.component';
import { EditDeductionsComponent } from './components/edit-deductions/edit-deductions.component';
import { EditLookupTableValueComponent } from './components/edit-lookup-table-value/edit-lookup-table-value.component';
import { EditLookupTableComponent } from './components/edit-lookup-table/edit-lookup-table.component';
import { EditPayrollSettingsComponent } from './components/edit-payroll-settings/edit-payroll-settings.component';
import { EditPropertyComponent } from './components/edit-property/edit-property.component';
import { ModifyPropertyContainerComponent } from './components/modify-property-container/modify-property-container.component';
import { NoteMemoDetailComponent } from './components/note-memo-detail/note-memo-detail.component';
import { PaymentDetailComponent } from './components/payment-detail/payment-detail.component';
import { PaymentEditPopupComponent } from './components/payment-edit/payment-edit-popup.component';
import { PaymentEditBankAccountPopupComponent } from './components/payment-edit-bank-account/payment-edit-bank-account-popup.component';
import { PaymentHistoryScreenComponent } from './containers/payment-history-screen/payment-history-screen.component';
import { PaymentHistoryComponent } from './components/payment-history/payment-history.component';
import { PayrollSettingsDetailComponent } from './components/payroll-settings-detail/payroll-settings-detail.component';
import { UploadLookupTableComponent } from './components/upload-lookup-table/upload-lookup-table.component';
import { BulkContentComponent } from './containers/bulk-content/bulk-content.component';
import { LookupTableDetailComponent } from './containers/lookup-table-detail/lookup-table-detail.component';
import { LookupTableComponent } from './containers/lookup-table/lookup-table.component';
import { MembersListComponent } from './containers/members-list/members-list.component';
import { NextPaymentComponent } from './containers/next-payment/next-payment.component';
import { MemberRoutingModule } from './member-routing.module';
import { StatusComponent } from './containers/status/status.component';
import { EditStatusComponent } from './components/edit-status/edit-status.component';
import { StatusDetailComponent } from './containers/status-detail/status-detail.component';
import { EditEventComponent } from './components/edit-event/edit-event.component';
import { EditMemberStatusEventComponent } from './components/edit-member-status-event/edit-member-status-event.component';
import { ProfileOverviewConfigurationComponent } from './containers/profile-overview-configuration/profile-overview-configuration.component';
import { PropertyDisplayConfigurationComponent } from './components/property-display-configuration/property-display-configuration.component';
import { ProfileHeaderConfigurationComponent } from './containers/profile-header-configuration/profile-header-configuration.component';
import { AddMemberFilterComponent } from './components/add-member-filter/add-member-filter.component';
import { SaveMemberFilterComponent } from './components/save-member-filter/save-member-filter.component';
import { ManageMemberFilterComponent } from './components/manage-member-filter/manage-member-filter.component';
import { ProfileNavigationConfigurationComponent } from './containers/profile-navigation-configuration/profile-navigation-configuration.component';
import { AddMenuComponent } from './components/add-menu/add-menu.component';
import { ProfileNavigationConfigurationDetailComponent } from './containers/profile-navigation-configuration-detail/profile-navigation-configuration-detail.component';
import { EditProfileNavigationComponent } from './components/edit-profile-navigation/edit-profile-navigation.component';
import { EditProfileNavigationItemComponent } from './components/edit-profile-navigation-item/edit-profile-navigation-item.component';
import { MemberNavigationDetailComponent } from './components/member-navigation-detail/member-navigation-detail.component';
import { MemberNavigationListComponent } from './components/member-navigation-list/member-navigation-list.component';
import { StatusHistoryComponent } from './containers/status-history/status-history.component';
import { MunicipalityServiceHistoryComponent } from './containers/municipality-service-history/municipality-service-history.component';
import { MunicipalityPaymentComponent } from './containers/municipality-payment-history/municipality-payment.component';
import { EditMemberServiceHistoryComponent } from './components/edit-municipality-service-history/edit-municipality-service-history.component';
import { MemberSectionAttachmentComponent } from './components/member-section-attachment/member-section-attachment.component';
import { EditMemberStatusHistoryComponent } from './components/edit-member-status-history/edit-member-status-history.component';
import { MemberNavigationAttachmentListComponent } from './components/member-navigation-attachment-list/member-navigation-attachment-list.component';
import { MemberAttachmentModalComponent } from './components/member-attachment-modal/member-attachment-modal.component';
import { ProfileHeaderConfigurationListComponent } from './containers/profile-header-configuration-list/profile-header-configuration-list.component';
import { GroupAggregationComponent } from './components/group-aggregation/group-aggregation.component';
import { ParticipantSearchConfigurationComponent } from './containers/participant-search-configuration/participant-search-configuration.component';
import { MembersBenefitEligibleComponent } from './containers/members-benefit-eligible/members-benefit-eligible.component';
import { EstablishBenefitComponent } from './components/establish-benefit/establish-benefit.component';
import { DefinedBenefitsComponent } from './containers/defined-benefits/defined-benefits.component';
import { ParticipantRelationshipComponent } from './containers/participant-relationship/participant-relationship.component';
import { OrganizationChartComponent } from './components/organization-chart/organization-chart.component';
import { ParticipantDetailDialogComponent } from './components/participant-detail-dialog/participant-detail-dialog.component';
import { PendingPaymentComponent } from './components/pending-payment/pending-payment.component';
import { OptInComponent } from './components/opt-in/opt-in.component';
import { PaymentInformationConfigurationComponent } from './components/payment-information-configuration/payment-information-configuration.component';
import { MunicipalityGroupAggregationComponent } from './components/municipality-group-aggregation/municipality-group-aggregation.component';
import { ManageMemberAttachmentComponent } from './components/manage-member-attachment/manage-member-attachment.component';
import { ListStatusComponent } from './components/grid-templates';
import { EditBenefitComponent } from './components/edit-benefit/edit-benefit.component';
import { DefinedBenefitService } from './services/defined-benefits.service';
import { PaymentListConfigurationComponent } from './components/payment-list-configuration/payment-list-configuration.component';
import { PaymentListConfigurationService } from './services/payment-list-configuration.service';
import { StatusHistoryAuditComponent } from './components/status-history-audit/status-history-audit.component';
import { CheckPermissionModule } from '@ptg-shared/layout/directive/check-permission/check-permission.module';
import { CalculationService } from './services/calculation.service';
import { TierConfigurationComponent } from './containers/tier-configuration/tier-configuration.component';
import { EditTierConfigurationComponent } from './components/edit-tier-configuration/edit-tier-configuration.component';
import { ReportService } from './services/report.service';
import { ReportCategoriesComponent } from './components/participant-report/report-categories/report-categories.component';
import { GeneratedReportComponent } from './components/participant-report/generated-report/generated-report.component';
import { ManageReportComponent } from './components/participant-report/manage-report/manage-report.component';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { ReportConfigComponent } from './components/participant-report/report-config/report-config.component';
import { ReportTemplateComponent } from './components/participant-report/report-view/report-view.component';
import { ViewCreditConfirmationDialogComponent } from './components/view-credit-confirmation-dialog/view-credit-confirmation-dialog.component';
import { DropPlanComponent } from './containers/drop-plan/drop-plan.component';
import { EditDropPlanComponent } from './components/edit-drop-plan/edit-drop-plan.component';
import { AddColumnSetComponent } from './components/add-column-set/add-column-set.component';
import { ColumnSetListComponent } from './components/column-set-list/column-set-list.component';
import { LookupTableDetailDisplayFormatComponent } from './components/lookup-table-detail-display-format/lookup-table-detail-display-format.component';
import { EditDisplayFormatComponent } from './components/edit-display-format/edit-display-format.component';
import { CalculationModule } from './features/calculation/calculation.module';
import { EditDeductionsRecurringComponent } from './components/edit-deductions-recurring/edit-deductions-recurring.component';
import { AccidentParticipantsModule } from './features/accident-claims/accident-participants.module';
import { OverviewHeaderModule } from './components/overview-header/overview-header.module';
import { OverviewHeaderEntityModule } from './components/overview-header-entity/overview-header-entity.module';
import { RetirementBenefitUploadDocumentComponent } from './features/calculation/components/retirement-benefit-upload-document/retirement-benefit-upload-document.component';
import {
  MemberSummaryCardColumnComponent,
  MemberSummaryCardComponent,
  MemberSummaryCardListComponent,
  MemberSummaryCardTableComponent,
} from './components';
import { MemberEffects } from './store/effects';
import { MODULE_KEY } from './constants';
import { memberReducer } from './store/reducers';
import { ParticipantNavigationConfigurationComponent } from './containers/participant-navigation-configuration/participant-navigation-configuration.component';
import { AddNewMenuComponent } from './components/add-new-menu/add-new-menu.component';
import { CardListComponent } from './containers/card-list/card-list.component';
import { AddNewCartComponent } from './components/add-new-cart/add-new-cart.component';
import { ParticipantNavigationConfigurationDetailComponent } from './containers/participant-navigation-configuration-detail/participant-navigation-configuration-detail.component';
import { EditNewMenuComponent } from './components/edit-new-menu/edit-new-menu.component';
import { EditNewMenuItemComponent } from './components/edit-new-menu-item/edit-new-menu-item.component';
import { AddViewComponent } from './components/add-view/add-view.component';
import { EditViewComponent } from './containers/view-detail/view-detail.component';
import { ViewListComponent } from './containers/view-list/view-list.component';
import { ViewService } from './services/view.service';
import { HeaderListComponent } from './containers/header-list/header-list.component';
import { AddHeaderComponent } from './components/add-header/add-header.component';
import { HeaderService } from './services/header.service';
import { HeaderDetailComponent } from './containers/header-detail/header-detail.component';
import { ProfileListComponent } from './containers/profile-configuration/profile-configuration.component';
import { ProfileConfigService } from './services/profile-configuration.service';
import { AddProfileComponent } from './components/add-profile/add-profile.component';
import { ProfileConfigDetailComponent } from './containers/profile-detail/profile-detail.component';
import { EditCardComponent } from './containers/card-detail/card-detail.component';
import { MemberSummaryViewComponent } from './containers/member-summary-view/member-summary-view.component';
import { ColumnSetPropertyDisplayConfigurationComponent } from './components/column-set-property-display-configuration/column-set-property-display-configuration.component';
import { DatalessViewComponent } from './containers/view-dataless/view-dataless.component';
import { AddMemberFiltersComponent } from './components/add-member-filters/add-member-filters.component';
import { ViewCurrentFiltersModule } from '@ptg-shared/controls/view-current-filters/view-current-filters.module';
import { MemberNavigationCardComponent } from './containers/member-navigation-card/member-navigation-card.component';
import { MemberNavigationStandalonePropertyComponent } from './components/member-navigation-standalone-property/member-navigation-standalone-property.component';
import { MemberNavigationListViewComponent } from './components/member-navigation-list-view/member-navigation-list-view.component';
import { MemberNavigationListViewDetailComponent } from './components/member-navigation-list-view-detail/member-navigation-list-view-detail.component';
import { MemberManageAttachmentListComponent } from './components/member-manage-attachment-list/member-manage-attachment-list.component';
import { StatusHistoryEntityComponent } from './containers/status-history-entity/status-history-entity.component';
import { RelatedPersonEntityComponent } from './containers/related-person-entity/related-person-entity.component';
import { SearchPropertyDisplayConfigurationComponent } from './components/search-property-display-configuration/search-property-display-configuration.component';
import { PurchaseServicesModule } from './features/purchase-services/purchase-services.module';
import { ServiceHistoryEntityComponent } from './containers/service-history-entity/service-history-entity.component';
import { TotalServiceEntityComponent } from './containers/total-service-entity/total-service-entity.component';
import { UpsertMemberComponent } from './components/upsert-member/upsert-member.component';
import { MemberService } from './services/member.service';
import { DistributionCodeMappingComponent } from './containers/distribution-code-mapping/distribution-code-mapping.component';
import { EditDistributionMappingComponent } from './components/edit-distribution-mapping/edit-distribution-mapping.component';
import { FileSharedModule } from '../admin/features/file/file.shared.module';
import { MemberDocumentListComponent } from './containers/member-document-list/member-document-list.component';
import { MemberIndividualDocumentComponent } from './components/member-individual-document/member-individual-document.component';
import { AddressHistoryComponent } from './containers/address-history/address-history.component';
import { EditMemberAddressHistoryComponent } from './components/edit-member-address-history/edit-member-address-history.component';
import { ParticipantDocumentsComponent } from './containers/participant-documents/participant-documents.component';
import { AddressHistoryEntityComponent } from './containers/address-history-entity/address-history-entity.component';
import { GenerateAge65LetterComponent } from './containers/generate-age65-letter/generate-age65-letter.component';
import { ParticipantGeneratedAge65LetterComponent } from './containers/participant-generated-age65-letter/participant-generated-age65-letter.component';
import { ManageStatusHistoryComponent } from './containers/manage-status-history/manage-status-history.component';

const COMPONENTS = [
  MembersListComponent,
  MemberSummaryViewComponent,
  DemographicComponent,
  MemberSummaryCardComponent,
  MemberSummaryCardColumnComponent,
  MemberSummaryCardListComponent,
  MemberSummaryCardTableComponent,
  BulkContentComponent,
  EditPropertyComponent,
  AddPropertyComponent,
  ModifyPropertyContainerComponent,
  AddSectionComponent,
  AddBenefitTypeComponent,
  NextPaymentComponent,
  DeductionDetailComponent,
  PaymentDetailComponent,
  EditEarningsComponent,
  EditPayrollSettingsComponent,
  AddNoteComponent,
  NoteMemoDetailComponent,
  EarningDetailComponent,
  PayrollSettingsDetailComponent,
  PaymentHistoryComponent,
  EditDeductionsComponent,
  EditDeductionsRecurringComponent,
  PaymentEditPopupComponent,
  PaymentEditBankAccountPopupComponent,
  PaymentHistoryScreenComponent,
  LookupTableComponent,
  LookupTableDetailComponent,
  EditLookupTableComponent,
  EditLookupTableValueComponent,
  UploadLookupTableComponent,
  ReplaceLookupTableValueComponent,
  StatusComponent,
  EditStatusComponent,
  StatusDetailComponent,
  EditEventComponent,
  EditMemberStatusEventComponent,
  ProfileOverviewConfigurationComponent,
  PropertyDisplayConfigurationComponent,
  ProfileHeaderConfigurationComponent,
  AddMemberFilterComponent,
  SaveMemberFilterComponent,
  ManageMemberFilterComponent,
  ProfileNavigationConfigurationComponent,
  AddMenuComponent,
  ProfileNavigationConfigurationDetailComponent,
  EditProfileNavigationComponent,
  EditProfileNavigationItemComponent,
  MemberNavigationDetailComponent,
  MemberNavigationListComponent,
  StatusHistoryComponent,
  MunicipalityPaymentComponent,
  MunicipalityServiceHistoryComponent,
  EditMemberServiceHistoryComponent,
  MemberSectionAttachmentComponent,
  EditMemberStatusHistoryComponent,
  MemberNavigationAttachmentListComponent,
  MemberAttachmentModalComponent,
  ProfileOverviewConfigurationDetailComponent,
  ProfileHeaderConfigurationListComponent,
  GroupAggregationComponent,
  MunicipalityGroupAggregationComponent,
  ParticipantSearchConfigurationComponent,
  MembersBenefitEligibleComponent,
  EstablishBenefitComponent,
  DefinedBenefitsComponent,
  ParticipantRelationshipComponent,
  OrganizationChartComponent,
  ParticipantDetailDialogComponent,
  PendingPaymentComponent,
  OptInComponent,
  PaymentInformationConfigurationComponent,
  ManageMemberAttachmentComponent,
  ListStatusComponent,
  EditBenefitComponent,
  PaymentListConfigurationComponent,
  StatusHistoryAuditComponent,
  TierConfigurationComponent,
  EditTierConfigurationComponent,
  ReportCategoriesComponent,
  GeneratedReportComponent,
  ManageReportComponent,
  ReportTemplateComponent,
  ReportConfigComponent,
  ViewCreditConfirmationDialogComponent,
  DropPlanComponent,
  EditDropPlanComponent,
  AddColumnSetComponent,
  ColumnSetListComponent,
  LookupTableDetailDisplayFormatComponent,
  EditDisplayFormatComponent,
  CardListComponent,
  AddNewCartComponent,
  ParticipantNavigationConfigurationDetailComponent,
  ParticipantNavigationConfigurationComponent,
  AddNewMenuComponent,
  EditNewMenuComponent,
  EditNewMenuItemComponent,
  AddViewComponent,
  EditViewComponent,
  ViewListComponent,
  HeaderListComponent,
  AddHeaderComponent,
  RetirementBenefitUploadDocumentComponent,
  HeaderDetailComponent,
  ProfileListComponent,
  AddProfileComponent,
  ProfileConfigDetailComponent,
  EditCardComponent,
  MemberNavigationCardComponent,
  MemberNavigationStandalonePropertyComponent,
  MemberNavigationListViewComponent,
  MemberNavigationListViewDetailComponent,
  MemberManageAttachmentListComponent,
  ColumnSetPropertyDisplayConfigurationComponent,
  DatalessViewComponent,
  AddMemberFiltersComponent,
  StatusHistoryEntityComponent,
  RelatedPersonEntityComponent,
  SearchPropertyDisplayConfigurationComponent,
  UpsertMemberComponent,
  DistributionCodeMappingComponent,
  EditDistributionMappingComponent,
  AddressHistoryComponent,
  AddressHistoryEntityComponent,
  ServiceHistoryEntityComponent,
  TotalServiceEntityComponent,
  MemberDocumentListComponent,
  MemberIndividualDocumentComponent,
  ParticipantDocumentsComponent,
  EditMemberAddressHistoryComponent,
  GenerateAge65LetterComponent,
  ParticipantGeneratedAge65LetterComponent,
];

const SERVICES = [
  DefinedBenefitService,
  CalculationService,
  PaymentListConfigurationService,
  DatePipe,
  ReportService,
  NextPayrollService,
  NextPaymentService,
  PaymentDeductionsService,
  VendorMaintenanceService,
  PaymentHistoryService,

  ViewService,
  HeaderService,
  ProfileConfigService,
  MemberService,
];

@NgModule({
  declarations: [...COMPONENTS, ManageStatusHistoryComponent],
  imports: [
    MemberRoutingModule,
    EntityManagementSharedModule,
    FileSharedModule,
    SharedModule,
    ControlsModule,
    BulkUpdateModule,
    TelerikReportingModule,
    StoreModule.forFeature(MODULE_KEY, memberReducer),
    EffectsModule.forFeature(MemberEffects),
    ViewValueModule,
    ViewCurrentFilterModule,
    ViewCurrentFiltersModule,
    CheckPermissionModule,
    CalculationModule,
    AccidentParticipantsModule,
    OverviewHeaderModule,
    OverviewHeaderEntityModule,
    PurchaseServicesModule,
  ],
  providers: [...SERVICES],
})
export class MemberModule {}
