import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CheckExistsDocumentNameRequest,
  CheckExistsDocumentNameResponse,
  GetDocumentsRequest,
  GetDocumentsResponse, GetEmployerDocumentOverviewRequest, GetEmployerDocumentOverviewResponse,
  RemoveDocumentBodyRequest,
  ViewGeneratedDocumentRequest,
  ViewGeneratedDocumentResponse,
} from '@ptg-employer/models/employer-document.model';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { SortType } from '@ptg-shared/constance';
import { Observable } from 'rxjs';
import { DocumentPortal } from 'src/app/app.const';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployerDocumentService {
  documentFile?: File[];
  documentEmployerOverviewFile?: File[];
  constructor(private httpClient: HttpClient) { }

  getDocuments(
    request: GetDocumentsRequest
  ): Observable<GetDocumentsResponse> {
    let params = new HttpParams();
    if (request?.employerId) {
      params = params.append('employerId', request.employerId);
    }
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    if (request.filters?.documentName) {
      params = params.append(`DocumentName`, request.filters?.documentName);
    }

    if (request.filters?.fileName) {
      params = params.append(`FileName`, request.filters?.fileName);
    }

    if (request.filters?.uploadedFrom) {
      params = params.append(`UploadedFrom`, request.filters?.uploadedFrom);
    }

    if (request.filters?.uploadedTo) {
      params = params.append(`UploadedTo`, request.filters?.uploadedTo);
    }

    if (request.filters?.tagsDisplay) {
      request.filters?.tagsDisplay.forEach((item: any, idx: any) => {
        params = params.append(`DocumentTag[${idx}]`, item);
      });
    }

    if (request.filters?.types) {
      request.filters?.types.forEach((item: any, idx: any) => {
        params = params.append(`FileType[${idx}]`, item);
      });
    }

    params = params.append('TimeZoneOffset', new Date().getTimezoneOffset());

    params = params.append('DocumentPortal', request.documentPortal as DocumentPortal);

    return this.httpClient.get<GetDocumentsResponse>(
      `${environment.apiUrl}/employer-portal/employer/documents`,
      { params }
    );
  }

  getEmployerDocumentOverview(
    employerId: string,
    request: GetEmployerDocumentOverviewRequest
  ): Observable<GetEmployerDocumentOverviewResponse> {
    let params = new HttpParams();

    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    params = params.append('DocumentPortal', request.documentPortal as DocumentPortal);

    return this.httpClient.get<GetEmployerDocumentOverviewResponse>(
      `${environment.apiUrl}/employer-portal/employer/${employerId}/document-overview`,
      { params }
    );
  }

  getViewGeneratedDocument(
    request: ViewGeneratedDocumentRequest
  ): Observable<ViewGeneratedDocumentResponse> {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    return this.httpClient.get<ViewGeneratedDocumentResponse>(
      `${environment.apiUrl}/generated-document/${request.documentId}`, { params, context }
    )
  };

  editDocuments(employerId: string, fileId: string, body: any): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/employer-portal/employer/${employerId}/document/${fileId}`,
       body
    );
  }

  uploadDocuments(body: any): Observable<void> {
    const data = new FormData();
    data.append('EmployerId', body.employerId || '');
    this.documentFile?.forEach((item: any) => {
      data.append('FileContents', item || '');
    })
    data.append('FileName', body.fileName || '');
    data.append('DocumentDescription', body.description || '');
    if (body.tags.length) {
      body.tags.forEach((item: any, idx: any) => {
        data.append(`Tags[${idx}]`, item);
      });
    }
    data.append('ShowOnOverview', body.showOnOverview ?? false);
    data.append('DocumentName', body.documentName || '');
    data.append('DocumentLocationTitle', body.documentLocationTitle || '');
    data.append('DocumentLocationRouter', body.documentLocationRouter || '');
    data.append('DocumentPortal', body.documentPortal);

    return this.httpClient.post<void>(
      `${environment.apiUrl}/employer-portal/employer/upload-document`, data
    );
  }

  removeDocument(removeDocumentBodyRequest: RemoveDocumentBodyRequest): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.apiUrl}/employer-portal/employer/documents`, { body: removeDocumentBodyRequest }
    );
  }

  checkExistDocumentName(checkExistDocumentNameRequest: CheckExistsDocumentNameRequest) {
    let params = new HttpParams();
    if (checkExistDocumentNameRequest?.documentId) {
      params = params.append('fileId', checkExistDocumentNameRequest.documentId);
    }
    if (checkExistDocumentNameRequest?.documentName) {
      params = params.append('documentName', checkExistDocumentNameRequest.documentName);
    }
    return this.httpClient.get<CheckExistsDocumentNameResponse>(
      `${environment.apiUrl}/employer-portal/${checkExistDocumentNameRequest.employerId}/documents/exists`, { params }
    );
  }

  uploadDocumentEmployerOverview(body: any): Observable<any> {
    const data = new FormData();
    data.append('EmployerId', body.employerId || '');
    this.documentEmployerOverviewFile?.forEach((item: any) => {
      data.append('FileContents', item || '');
    })
    data.append('FileName', body.fileName || '');
    data.append('Description', body.description || '');
    data.append('ShowOnOverview', body.showOnOverview ?? false);
    data.append('DocumentName', body.documentName || '');
    data.append('DocumentLocationTitle', body.documentLocationTitle || '');
    data.append('DocumentLocationRouter', body.documentLocationRouter || '');
    data.append('DocumentPortal', body.documentPortal);

    if (body.tags.length) {
      body.tags.forEach((item: any, idx: any) => {
        data.append(`Tags[${idx}]`, item);
      });
    }

    return this.httpClient.post<void>(
      `${environment.apiUrl}/employer-portal/employer/upload-document`, data
    );
  }

  checkExistDocumentNameMultiple(employerId: string, body: string[]): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/employer-portal/${employerId}/multiple-documents/exists`, body
    );
  }

}
