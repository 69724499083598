import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { CANCEL_CONFIRM_MESSAGE, SortType } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone } from '@ptg-shared/utils/common.util';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TagsState } from '../../store/reducers';
import { getListTemplateAction, getListTemplateTagsAction, getTagListAction } from '../../store/actions';
import { getListTemplateSelector, getListTemplateTagSelector, getTagListSelector } from '../../store/selectors';
import { BaseComponent } from '@ptg-shared/components';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { DocumentState } from '@ptg-employer/reducers/employer-document.reducer';
import { DocumentTemplateState } from '../../../template/store/reducers';
import { EntityType } from '../../types/enums/entity-type.enum';

@Component({
  selector: 'ptg-generate-document',
  templateUrl: './generate-document.component.html',
  styleUrls: ['./generate-document.component.scss'],
})
export class GenerateDocumentComponent extends BaseComponent implements OnInit {
  title: string = 'Generate Document';
  readonly EntityType = EntityType;
  entityType!: EntityType;
  generateDocForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  isShowError: boolean = false;
  currentTagsChipValue!: Option;
  filteredByTypingTagOptions!: Observable<Option[]>;
  listOptionTag: Option[] = [];
  listTemplate: any[] = [];
  pageNumber: number = FIRST_PAGE;
  isLoading: boolean = true;
  pageSize: number = 50;
  listType = [
    { displayValue: 'PDF', value: 'pdf' },
    { displayValue: 'Word', value: 'docx' },
  ];

  currentTagsChipValueList: string[] = [];
  pensionConfirmationLetter: string = 'pension confirmation letter';

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenerateDocumentComponent>,
    public tagStore: Store<TagsState>,
    private documentStore: Store<DocumentState>,
    private documentTemplateStore: Store<DocumentTemplateState>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isPendingPayments?: boolean;
      currentEntity: any;
    },
  ) {
    super();
  }
  get templateCtrl(): FormControl {
    return this.generateDocForm?.get('template') as FormControl;
  }
  ngOnInit(): void {
    this.initFormGroup(null);
    this.selectDataTagList();
    this.getTagList();
    this.getDataTemplateList();
    this.registerGetListDocumentTemplateSelector();
    this.submitForm();
  }

  initFormGroup(formData: any | null) {
    this.entityType = this.data?.currentEntity?.entityType;
    this.generateDocForm = this.fb.group({
      template: this.fb.control(formData?.templateId || [], [Validators.required]) ?? '',
      fileType: this.fb.control([], [Validators.required]) ?? '',
      isShowPreview: this.fb.control(true), // @-> US 102907, adjust default value of Show Preview to ON
      tags: this.fb.control(''),
      tagsChipList: this.fb.array([]),
    });
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.generateDocForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.generateDocForm.statusChanges.pipe(
            startWith(this.generateDocForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    const templateName = this.listTemplate.find((temp) => temp.value === this.generateDocForm.get('template')?.value)
      ?.displayValue;
    const fileTypeName = this.listType.find((item: any) => item.value === this.generateDocForm.get('fileType')?.value)
      ?.displayValue;
    const objGenerate = this.generateDocForm.value;
    objGenerate['tags'] = this.currentTagsChipValueList;
    objGenerate['templateName'] = templateName;
    objGenerate['fileTypeName'] = fileTypeName;
    if (this.data?.isPendingPayments) {
      objGenerate['template'] = this.templateCtrl?.value;
    }
    this.dialogRef.close(objGenerate);
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  getTagList() {
    let sortType = SortType.ASC;
    let sortNames = 'Name';
    this.tagStore.dispatch(
      getTagListAction({
        request: {
          sortNames,
          sortType,
        },
      }),
    );
  }

  selectDataTagList() {
    this.tagStore.pipe(select(getTagListSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        if (data?.success && data?.payload?.length) {
          this.listOptionTag = data?.payload.map(
            (item) =>
              ({
                value: item.id,
                displayValue: item.name,
              }) as Option,
          );
        }
      }
    });
  }

  getDataTemplateList() {
    this.documentStore.dispatch(
      getListTemplateAction({
        request: {
          sortType: SortType.ASC,
          sortNames: 'TemplateName',
        },
      }),
    );
  }

  registerGetListDocumentTemplateSelector() {
    const templateUsedFor = this.entityType === EntityType.Employer ? 'Employer' : 'Member';
    this.documentStore.pipe(select(getListTemplateSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        this.listTemplate =
          data?.payload?.filter(
            item => item.usedFor === templateUsedFor)?.map((temp) => {
            return {
              value: temp.id,
              displayValue: temp.templateName,
              fileId: temp.fileId,
            };
          }) ?? [];
        if (this.listTemplate.length === 0) {
          this.templateControl.setErrors({ errMsgTemplate: 'No available templates' });
          this.templateControl.markAsTouched();
        }
        if (this.data?.isPendingPayments) {
          this.listTemplate = this.listTemplate.filter(
            (item) => item.displayValue.toLowerCase() === this.pensionConfirmationLetter,
          );
          const formData = {
            templateId: this.listTemplate[0].value,
          };
          this.initFormGroup(formData);
          this.templateCtrl.disable();
        }
      }
    });
  }

  validateTags(): void {
    if (this.tagsAutoCompleteControl.value) {
      if (
        (this.tagsAutoCompleteControl.enabled && typeof this.tagsAutoCompleteControl.value === 'string') ||
        this.currentTagsChipValueList?.includes(this.tagsAutoCompleteControl.value.value)
      ) {
        this.tagsAutoCompleteControl.setErrors({ inValidAsync: true });
        return;
      }
    }
  }

  tagsFocus(event: any) {
    const addClass = document.querySelectorAll('.cdk-overlay-connected-position-bounding-box');
    addClass[0]?.classList.add('list-tags-style');
    event.stopPropagation();
    this.currentTagsChipValue = deepClone(this.tagsAutoCompleteControl?.value);
    this.filteredByTypingTagOptions = this.tagsAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this.listOptionTag.filter(
          (item) =>
            item?.displayValue?.toLowerCase()?.includes((event.target.value as string).toLowerCase()) &&
            !this.currentTagsChipValueList?.includes(item?.value),
        );
      }),
    );
  }

  onAddNewChip() {
    if (!this.tagsAutoCompleteControl.value || this.tagsAutoCompleteControl.invalid) {
      this.tagsAutoCompleteControl.markAsTouched();
      return;
    }

    const chip = this.currentTagsChipValue;
    this.tagsAutoCompleteControl.reset('');
    const chipControl = new FormControl(chip);

    this.tagsChipListControl.push(chipControl);
    if (!this.currentTagsChipValueList.includes(chip.value)) {
      this.currentTagsChipValueList.push(chip.value);
    }

    if (this.currentTagsChipValueList.length == this.listOptionTag.length) {
      this.tagsAutoCompleteControl.disable();
    }
  }

  onRemoveChip(value: any, index: number) {
    if (!value) return;
    this.currentTagsChipValueList.splice(index, 1);
    this.tagsChipListControl.removeAt(index);
    if (this.currentTagsChipValueList.length == this.listOptionTag.length - 1) {
      this.tagsAutoCompleteControl.enable();
    }
  }

  changeToggleAdd(event: any) {}

  displayTagFn(value: Option): string {
    return value ? value.displayValue : '';
  }

  onSelectTemplate(event: any) {
    this.documentTemplateStore.dispatch(
      getListTemplateTagsAction({
        fileId: event.fileId,
      }),
    );
    this.documentStore.pipe(select(getListTemplateTagSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        if (data.payload?.documentDetail?.tags.length) {
          this.getTagsChipList(data.payload?.documentDetail?.tags);
        }
      }
    });
  }

  getTagsChipList(list: any) {
    this.tagsAutoCompleteControl.reset('');
    list
      .map((item: any) => {
        return {
          displayValue: item.name,
          value: item.id,
        };
      })
      .forEach((tag: any) => {
        const exist = this.tagsChipListControl.value.find((item: any) => item.value === tag.value);
        const tagDetail = new FormControl(tag);
        if (!exist || !this.tagsChipListControl.value.length) {
          this.tagsChipListControl.push(tagDetail);
          this.currentTagsChipValueList.push(tag.value);
        }
      });
  }

  displayValue(value: string) {
    return value.length > 20 ? value.substring(0, 20) + '...' : value;
  }

  get templateControl(): FormControl {
    return this.generateDocForm?.get('template') as FormControl;
  }

  get fileTypeControl(): FormControl {
    return this.generateDocForm?.get('fileType') as FormControl;
  }

  get isShowPreviewControl(): FormControl {
    return this.generateDocForm?.get('isShowPreview') as FormControl;
  }

  get tagsAutoCompleteControl(): FormControl {
    return this.generateDocForm?.get('tags') as FormControl;
  }

  get tagsChipListControl(): FormArray {
    return this.generateDocForm?.get('tagsChipList') as FormArray;
  }
}
