import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DocumentAttachRequest } from '@ptg-member/services/models';
import { GetParticipantDocumentsBenefitsResponse } from '@ptg-member/types/models/participant-documents.model';

@Injectable({
  providedIn: 'root',
})
export class ParticipantDocumentsService {
  constructor(private httpClient: HttpClient) {}

  getParticipantDocumentsList(query: any, memberId: string, isShowOnOverView: boolean): Observable<any> {
    let params = new HttpParams();
    if (query) {
      params = params.append('Masked', true);
      params = params.append('PageSize', query.pageSize);
      params = params.append('PageIndex', query.pageIndex);
      params = params.append('DocumentPortal', query.documentPortal);
    }
    return this.httpClient.get(`${environment.apiUrl}/member-portal/GetParticipantDocumentList?memberId=${memberId}&IsShowOnOverView=${isShowOnOverView}`, { params });
  }

  removeDocumentsParticipant(memberId: string, documentId: string) {
    return this.httpClient.delete(`${environment.apiUrl}/members/${memberId}/documents/${documentId}`);
  }

  documentAttach(request: DocumentAttachRequest): Observable<any> {
    return this.httpClient.put(
      `${environment.apiUrl}/members/documents/attach`,
      request
    );
  }

  getParticipantDocumentsUseForBenefit(memberId: string): Observable<GetParticipantDocumentsBenefitsResponse> {
    return this.httpClient.get<GetParticipantDocumentsBenefitsResponse>(`${environment.apiUrl}/members/${memberId}/benefits/documents`);
  }
}
