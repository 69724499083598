import { createReducer, on } from '@ngrx/store';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance/value.const';
import { EmployerActions } from '../actions';
import {
  DefaultLookupTableOptions,
  EmployerInfoProperty,
  EmployerList,
  EmployerListProperty,
  EmployerOverview,
  PropertyDisplayConfiguration,
  EmployerSectionActionState,
  SectionConfig,
  SectionType,
  EmployerSection,
  EmployerFileTemplate,
  EmployerFileProperty,
  SearchEmployerResponse,
  MappingFieldSection,
  EmployerFilterInformation,
  FileLayoutData,
  EmployerFilePostingField,
  EmployerCheckSectionActionState,
  RegisterCustomerStripeResponse,
  CheckStripeCustomerExistsExistResponse,
} from '../models/employer.model';
import { BaseActionState } from '@ptg-shared/types/models';
import { EmployerFilter } from '../models/employer-filter.model';
import { EmployerPropertyConfiguration, InformationPropertyConfiguration } from '@ptg-employer/models/employer-information-property.model';
import {
  createEmployersAction,
  createEmployersSuccessAction,
  createEmployersFailureAction,
  clearCreateEmployersStateAction,
  clearSetEmployersStateAction,
  setEmployersAction,
  setEmployersFailureAction,
  setEmployersSuccessAction,
  removeDocumentsEmployerSuccess,
  removeDocumentsEmployerFailure,
  clearGetPermissionForMuniPortalUserStateAction,
  getPermissionForMuniPortalUserAction,
  getPermissionForMuniPortalUserFailureAction,
  getPermissionForMuniPortalUserSuccessAction,
  clearRemoveDocumentsEmployerState,
  getValidateEditPaymentMethodAction,
  getValidateEditPaymentMethodSuccessAction,
  getValidateEditPaymentMethodFailureAction,
  clearGetValidateEditPaymentMethodStateAction,
  checkStripeCustomerExistsSuccess,
  checkStripeCustomerExistsFailure,
  registerCustomerStripeFailure,
  registerCustomerStripeSuccess,
  clearCheckStripeCustomerExistsAction,
  clearRegisterCustomerStripeAction,
} from '@ptg-employer/actions/employer.action';
import { deepClone } from '@ptg-shared/utils/common.util';

export const employerFeatureKey = 'employer';

export interface State {
  isLoading: boolean;
  isExporting: boolean;
  employerList: EmployerList;
  employerOverview: EmployerOverview;
  actionState?: ActionState;
  success?: boolean;
  error?: any;
  overviewConfigurations?: SectionConfig[];
  saveEmployerOverviewConfigState?: ActionState;
  properties: EmployerListProperty;
  newPropertyName: string;
  employerInfoProperties?: EmployerInfoProperty[];
  employerFileTemplates?: {
    isLoading: boolean;
    templates: EmployerFileTemplate[];
  };
  employerFileProperties?: {
    isLoading: boolean;
    total: number;
    properties: EmployerFileProperty[];
  };
  mappingFieldSections?: {
    isLoading: boolean;
    sections: MappingFieldSection[];
  };
  reloadData: boolean;
  defaultLookupTableOptions?: DefaultLookupTableOptions;
  employerInformationConfiguration?: {
    properties: EmployerPropertyConfiguration[]; // NEED TO UPDATE MODEL
    informationProperties: InformationPropertyConfiguration[];
  };
  propertyListConfiguration?: any;
  propertyListFilter?: any;

  propertyDisplayConfigurations?: PropertyDisplayConfiguration[];
  saveEmployerListConfigState?: ActionState;
  saveEmployerListConfigurationState?: ActionState;
  employerSectionActionState?: EmployerSectionActionState;
  employerCheckSectionActionState?: EmployerCheckSectionActionState;
  employerFileFormatActionState?: ActionState;
  employerFileLayoutActionState?: any;
  employerFileLayoutConfiguration?: {
    isLoading: boolean;
    fileLayoutData: FileLayoutData;
  };
  employerCheckDisableState: {
    checkDisableState: string;
    errorMsg?: string;
  };
  searchEmployer?: BaseActionState<SearchEmployerResponse>;
  employerFilePostingField?: EmployerFilePostingField;
  employerFilePropertyActionState?: ActionState;
  configurePostingFieldsState?: ActionState;
  createEmloyerFilterState?: string;
  listFilterInfo?: BaseActionState<EmployerFilterInformation[]>;
  removeEmployerFilterState?: string;
  updateEmployerFilterNameState?: string;
  updateFilterState?: string;
  filters?: EmployerFilter[];
  createEmployers?: BaseActionState<{ employerId: string }>;
  setEmployers?: BaseActionState<{ entityLabel: string }>;
  removeState: string;
  getPermissionForMuniPortalUserState?: BaseActionState<any>;
  listColumnSetEmployer?: any;
  updateColumnSetState?: string;
  idColumnSet?: string;
  selectedColumnSetState?: string;
  actionColumnSetState?: string;
  reorderColumnSetState?: string;
  removeColumnSetState?: string;
  removeColumnSetStatusState?: string;
  getValidateEditPaymentMethod?: BaseActionState<boolean>;
  paymentDashboardState?: any;
  checkConnectStripe?: BaseActionState<CheckStripeCustomerExistsExistResponse>;
  customerStripe?: BaseActionState<RegisterCustomerStripeResponse>;
}

const initialState: State = {
  isLoading: true,
  isExporting: false,
  employerList: {} as EmployerList,
  employerOverview: {} as EmployerOverview,
  actionState: undefined,
  properties: {} as EmployerListProperty,
  newPropertyName: '',
  reloadData: false,
  employerCheckDisableState: {
    checkDisableState: '',
    errorMsg: '',
  },
  employerFileTemplates: {
    isLoading: false,
    templates: [],
  },
  employerFileProperties: {
    isLoading: false,
    total: 0,
    properties: [],
  },
  removeState: '',
};

export const reducer = createReducer(
  initialState,
  on(EmployerActions.getEmployerList, (state, {}) => ({
    ...state,
    isLoading: true,
    actionState: undefined,
    employerList: {
      total: 0,
      employers: [],
      columns: [],
    },
  })),
  on(EmployerActions.getEmployerListSuccess, (state, { employerList }) => ({
    ...state,
    isLoading: false,
    success: true,
    error: undefined,
    employerList,
  })),
  on(EmployerActions.getEmployerListFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    success: false,
    error: error,
    employerList: {
      total: 0,
      employers: [],
      columns: [],
    },
  })),

  on(EmployerActions.setEmployerFilter, (state, { filters }) => ({
    ...state,
    filters,
  })),

  on(EmployerActions.searchEmployer, (state) => ({
    ...state,
    searchEmployer: {
      isLoading: true,
      success: false,
    },
  })),
  on(EmployerActions.searchEmployerSuccess, (state, { response }) => ({
    ...state,
    searchEmployer: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(EmployerActions.searchEmployerFailure, (state, { error }) => ({
    ...state,
    searchEmployer: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(EmployerActions.clearSearchEmployer, (state) => ({
    ...state,
    searchEmployer: undefined,
  })),

  on(EmployerActions.getEmployerOverview, (state, {}) => ({
    ...state,
    isLoading: true,
    actionState: undefined,
    employerSectionActionState: undefined,
  })),
  on(EmployerActions.getEmployerOverviewSuccess, (state, { employerOverview, sectionType }) => {
    if (sectionType || sectionType === SectionType.EmployerInformation) {
      const currentEmployerOverview = deepClone(state.employerOverview);
      const employerSections = currentEmployerOverview.sections;
      let newSections: EmployerSection[] = [];
      for (let index = 0; index < employerOverview.sections.length; index++) {
        const section = employerOverview.sections[index];
        const sectionIndex = employerSections.findIndex((item) => item.type === section.type);
        if (sectionIndex > -1) {
          newSections =
            index === 0
              ? [...employerSections.filter((item) => item.type !== section.type), section]
              : [...newSections.filter((item) => item.type !== section.type), section];
        }
      }
      return {
        ...state,
        isLoading: false,
        success: true,
        error: undefined,
        employerOverview: {
          sections: [...newSections],
          employerName: currentEmployerOverview.employerName,
        },
      };
    } else {
      return {
        ...state,
        isLoading: false,
        success: true,
        error: undefined,
        employerOverview: employerOverview,
      };
    }
  }),
  on(EmployerActions.getEmployerOverviewFailure, (state, { error, sectionType }) => ({
    ...state,
    isLoading: false,
    success: false,
    error: error,
    employerOverview:
      sectionType || sectionType === SectionType.EmployerInformation
        ? state.employerOverview
        : {
            sections: [],
          },
  })),
  on(EmployerActions.getOverViewConfig, (state) => ({
    ...state,
    saveEmployerOverviewConfigState: undefined,
  })),
  on(EmployerActions.getOverViewConfigSuccess, (state, { overviewConfigurations }) => ({
    ...state,
    overviewConfigurations,
  })),
  on(EmployerActions.clearOverViewConfig, (state) => ({
    ...state,
    overviewConfigurations: undefined,
  })),
  on(EmployerActions.saveOverViewConfigSuccess, (state, {}) => ({
    ...state,
    saveEmployerOverviewConfigState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.saveOverViewConfigFailure, (state, {}) => ({
    ...state,
    saveEmployerOverviewConfigState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),

  on(EmployerActions.getListEmployerProperty, (state) => ({
    ...state,
    isLoading: true,
    reloadData: false,
  })),

  on(EmployerActions.getListEmployerPropertySuccess, (state, { properties }) => ({
    ...state,
    isLoading: false,
    success: true,
    error: undefined,
    properties,
    newPropertyName: '',
  })),
  on(EmployerActions.getListEmployerPropertyFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    success: false,
    error: error,
  })),
  on(EmployerActions.addEmployerProperty, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(EmployerActions.addEmployerPropertySuccess, (state, { propertyName }) => ({
    ...state,
    isLoading: false,
    actionState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
    },
    reloadData: true,
    newPropertyName: propertyName,
  })),

  on(EmployerActions.clearEmployerProperty, (state) => ({
    ...state,
    isLoading: false,
    actionState: undefined,
  })),

  on(EmployerActions.getEmployerInfoPropertiesRequest, (state, {}) => ({
    ...state,
  })),
  on(EmployerActions.getEmployerInfoPropertiesSuccess, (state, { employerInfoProperties }) => ({
    ...state,
    employerInfoProperties,
  })),
  on(EmployerActions.getEmployerInfoPropertiesFailure, (state, {}) => ({
    ...state,
    employerInfoProperties: [],
  })),

  on(EmployerActions.editEmployerProperty, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(EmployerActions.editEmployerPropertySuccess, (state, { propertyName }) => {
    return {
      ...state,
      isLoading: false,
      actionState: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      },
      newPropertyName: propertyName,
    };
  }),
  on(EmployerActions.editEmployerPropertyFailure, (state, { propertyName, errorMsg }) => ({
    ...state,
    isLoading: false,
    actionState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
    newPropertyName: propertyName,
  })),

  on(EmployerActions.removeEmployerProperty, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(EmployerActions.removeEmployerPropertySuccess, (state, { propertyName }) => {
    return {
      ...state,
      isLoading: false,
      actionState: {
        action: ACTION.REMOVE,
        state: STATE.SUCCESS,
      },
      newPropertyName: propertyName,
    };
  }),
  on(EmployerActions.removeEmployerPropertyFailure, (state, { propertyName, errorMsg }) => ({
    ...state,
    isLoading: false,
    actionState: {
      action: ACTION.REMOVE,
      state: STATE.FAIL,
    },
    newPropertyName: propertyName,
  })),

  on(EmployerActions.getMunicipalityCheckDisable, (state, {}) => ({
    ...state,
    employerCheckDisableState: {
      checkDisableState: '',
    },
  })),
  on(EmployerActions.getMunicipalityCheckDisableSuccess, (state) => ({
    ...state,
    employerCheckDisableState: {
      checkDisableState: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.getMunicipalityCheckDisableFailure, (state, { error }) => {
    let errorMsg = '';
    if (error) {
      errorMsg = error;
    }
    return {
      ...state,
      employerCheckDisableState: {
        checkDisableState: STATE.FAIL,
        errorMsg,
      },
    };
  }),

  on(EmployerActions.getLookupTableOptionsSuccess, (state, { defaultLookupTableOptions }) => ({
    ...state,
    defaultLookupTableOptions,
  })),
  on(EmployerActions.addEmployerContact, (state) => ({
    ...state,
    isLoading: true,
    employerSectionActionState: undefined,
  })),
  on(EmployerActions.addEmployerContactSuccess, (state) => ({
    ...state,
    employerSectionActionState: {
      sectionType: SectionType.Contacts,
      action: ACTION.ADD,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.addEmployerContactFailure, (state, { errorMsg }) => ({
    ...state,
    employerSectionActionState: {
      sectionType: SectionType.Contacts,
      action: ACTION.ADD,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.updateEmployerContact, (state) => ({
    ...state,
    isLoading: true,
    employerSectionActionState: undefined,
  })),
  on(EmployerActions.updateEmployerContactSuccess, (state) => ({
    ...state,
    employerSectionActionState: {
      sectionType: SectionType.Contacts,
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.updateEmployerContactFailure, (state, { errorMsg }) => ({
    ...state,
    employerSectionActionState: {
      sectionType: SectionType.Contacts,
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.getPropertyList, (state) => ({
    ...state,
    employerInformationConfiguration: undefined,
  })),
  on(EmployerActions.getPropertyListSuccess, (state, { employerInformationConfiguration }) => {
    return {
      ...state,
      employerInformationConfiguration,
    };
  }),
  on(EmployerActions.getPropertyListFailure, (state) => ({
    ...state,
    employerInformationConfiguration: {} as any,
  })),
  on(EmployerActions.clearPropertyList, (state) => ({
    ...state,
    employerInformationConfiguration: undefined,
  })),

  on(EmployerActions.getPropertyListConfiguration, (state) => ({
    ...state,
    propertyListConfiguration: undefined,
  })),
  on(EmployerActions.getPropertyListConfigurationSuccess, (state, { propertyListConfiguration }) => {
    return {
      ...state,
      propertyListConfiguration,
    };
  }),
  on(EmployerActions.getPropertyListConfigurationFailure, (state) => ({
    ...state,
    propertyListConfiguration: {} as any,
  })),

  on(EmployerActions.getPropertyListFilter, (state) => ({
    ...state,
    propertyListFilter: undefined,
  })),
  on(EmployerActions.getPropertyListFilterSuccess, (state, { propertyListFilter }) => {
    return {
      ...state,
      propertyListFilter,
    };
  }),
  on(EmployerActions.getPropertyListFilterFailure, (state) => ({
    ...state,
    propertyListFilter: {} as any,
  })),

  on(EmployerActions.getEmployerListConfigurationSuccess, (state, { propertyDisplayConfigurations }) => ({
    ...state,
    propertyDisplayConfigurations,
  })),
  on(EmployerActions.getEmployerListConfigurationFailure, (state) => ({
    ...state,
    propertyDisplayConfigurations: [],
  })),
  on(EmployerActions.clearEmployerListConfiguration, (state) => ({
    ...state,
    propertyDisplayConfigurations: undefined,
  })),
  on(EmployerActions.setEmployerListConfigurationSuccess, (state, {}) => ({
    ...state,
    saveEmployerListConfigState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.setEmployerListConfigurationFailure, (state, {}) => ({
    ...state,
    saveEmployerListConfigState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.clearSetEmployerListConfigurationState, (state) => ({
    ...state,
    saveEmployerListConfigState: undefined,
  })),

  on(EmployerActions.setEmployerListViewConfigurationSuccess, (state, {}) => ({
    ...state,
    saveEmployerListConfigurationState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.setEmployerListViewConfigurationFailure, (state, {}) => ({
    ...state,
    saveEmployerListConfigurationState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),

  on(EmployerActions.clearSetEmployerListViewConfigurationState, (state) => ({
    ...state,
    saveEmployerListConfigurationState: undefined,
  })),

  on(EmployerActions.clearEmployerFileTemplatesActionState, (state) => ({
    ...state,
    employerFileFormatActionState: undefined,
    employerFileLayoutActionState: undefined,
  })),
  on(EmployerActions.getEmployerFileTemplates, (state) => ({
    ...state,
    employerFileTemplates: {
      isLoading: true,
      templates: [],
    },
  })),
  on(EmployerActions.getEmployerFileTemplatesSuccess, (state, { employerFileTemplates }) => ({
    ...state,
    employerFileTemplates: {
      isLoading: false,
      templates: employerFileTemplates.fileTemplates,
    },
  })),
  on(EmployerActions.getEmployerFileTemplatesFailure, (state, {}) => ({
    ...state,
    employerFileTemplates: {
      isLoading: false,
      templates: [],
    },
  })),

  on(EmployerActions.setEmployerFileFormat, (state) => ({
    ...state,
    isLoading: true,
    employerFileFormatActionState: undefined,
  })),
  on(EmployerActions.setEmployerFileFormatSuccess, (state) => ({
    ...state,
    isLoading: false,
    employerFileFormatActionState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.setEmployerFileFormatFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    employerFileFormatActionState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.getEmployerFileLayoutConfiguration, (state) => ({
    ...state,
    employerFileLayoutConfiguration: {
      isLoading: true,
      fileLayoutData: {
        templateInUsed: false,
        employerFileLayouts: [],
      },
    },
    employerFileLayoutActionState: undefined,
  })),
  on(EmployerActions.getEmployerFileLayoutConfigurationSuccess, (state, { fileLayoutData }) => ({
    ...state,
    employerFileLayoutConfiguration: {
      isLoading: false,
      fileLayoutData,
    },
  })),
  on(EmployerActions.getEmployerFileLayoutConfigurationFailure, (state, {}) => ({
    ...state,
    employerFileLayoutConfiguration: {
      isLoading: false,
      fileLayoutData: {
        templateInUsed: false,
        employerFileLayouts: [],
      },
    },
  })),
  on(EmployerActions.setEmployerFileLayoutConfigurationSuccess, (state, { templateName }) => ({
    ...state,
    employerFileLayoutActionState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
      templateName,
    },
  })),
  on(EmployerActions.setEmployerFileLayoutConfigurationFailure, (state, { errorMsg, templateName }) => ({
    ...state,
    employerFileLayoutActionState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
      templateName,
    },
  })),
  on(EmployerActions.getEmployerFileProperties, (state) => ({
    ...state,
    employerFileProperties: {
      isLoading: true,
      total: 0,
      properties: [],
    },
  })),
  on(EmployerActions.getEmployerFilePropertiesSuccess, (state, { total, properties }) => ({
    ...state,
    employerFileProperties: {
      isLoading: false,
      total: total,
      properties: properties,
    },
  })),
  on(EmployerActions.getEmployerFilePropertiesFailure, (state, {}) => ({
    ...state,
    employerFileProperties: {
      isLoading: false,
      total: 0,
      properties: [],
    },
  })),
  on(EmployerActions.clearEmployerFilePropetyActionState, (state) => ({
    ...state,
    employerFilePropertyActionState: undefined,
    configurePostingFieldsState: undefined,
  })),
  on(EmployerActions.getMappingFieldSections, (state) => ({
    ...state,
    mappingFieldSections: {
      isLoading: true,
      sections: [],
    },
  })),
  on(EmployerActions.getMappingFieldSectionsSuccess, (state, { sections }) => ({
    ...state,
    mappingFieldSections: {
      isLoading: false,
      sections: sections,
    },
  })),
  on(EmployerActions.getMappingFieldSectionsFailure, (state, {}) => ({
    ...state,
    mappingFieldSections: {
      isLoading: false,
      sections: [],
    },
  })),
  on(EmployerActions.createEmployerFileProperty, (state) => ({
    ...state,
    isLoading: true,
    employerFilePropertyActionState: undefined,
  })),
  on(EmployerActions.createEmployerFilePropertySuccess, (state) => ({
    ...state,
    isLoading: false,
    employerFilePropertyActionState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.createEmployerFilePropertyFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    employerFilePropertyActionState: {
      action: ACTION.ADD,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.updateEmployerFileProperty, (state) => ({
    ...state,
    isLoading: true,
    employerFilePropertyActionState: undefined,
  })),
  on(EmployerActions.updateEmployerFilePropertySuccess, (state) => ({
    ...state,
    isLoading: false,
    employerFilePropertyActionState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.updateEmployerFilePropertyFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    employerFilePropertyActionState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.removeEmployerFileProperty, (state) => ({
    ...state,
    isLoading: true,
    employerFilePropertyActionState: undefined,
  })),
  on(EmployerActions.removeEmployerFilePropertySuccess, (state) => ({
    ...state,
    isLoading: false,
    employerFilePropertyActionState: {
      action: ACTION.REMOVE,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.removeEmployerFilePropertyFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    employerFilePropertyActionState: {
      action: ACTION.REMOVE,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.setConfigurePostingFields, (state) => ({
    ...state,
    isLoading: true,
    configurePostingFieldsState: undefined,
  })),
  on(EmployerActions.setConfigurePostingFieldsSuccess, (state) => ({
    ...state,
    isLoading: false,
    configurePostingFieldsState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.getFilePropertiesForPostingFieldsSuccess, (state, { employerFilePostingField }) => ({
    ...state,
    isLoading: false,
    employerFilePostingField,
  })),
  on(EmployerActions.getFilePropertiesForPostingFieldsFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    employerFilePostingField: undefined,
  })),
  on(EmployerActions.setConfigurePostingFieldsFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    configurePostingFieldsState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),

  on(EmployerActions.clearEmployerSectionAction, (state) => ({
    ...state,
    isLoading: false,
    employerSectionActionState: undefined,
    updatedEmployerSections: undefined,
  })),
  on(EmployerActions.editEmployerInformation, (state) => ({
    ...state,
    isLoading: true,
    employerSectionActionState: undefined,
  })),
  on(EmployerActions.editEmployerInformationSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      employerSectionActionState: {
        sectionType: SectionType.EmployerInformation,
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      },
    };
  }),
  on(EmployerActions.editEmployerInformationFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    employerSectionActionState: {
      sectionType: SectionType.EmployerInformation,
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.editEmployerPaymentPreferences, (state) => ({
    ...state,
    isLoading: true,
    employerSectionActionState: undefined,
  })),
  on(EmployerActions.editEmployerPaymentPreferencesSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      employerSectionActionState: {
        sectionType: SectionType.PaymentReferences,
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      },
    };
  }),
  on(EmployerActions.editEmployerPaymentPreferencesFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    employerSectionActionState: {
      sectionType: SectionType.PaymentReferences,
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.removeEmployerContact, (state) => ({
    ...state,
    isLoading: true,
    employerSectionActionState: undefined,
  })),
  on(EmployerActions.removeEmployerContactSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      employerSectionActionState: {
        sectionType: SectionType.Contacts,
        action: ACTION.REMOVE,
        state: STATE.SUCCESS,
      },
    };
  }),
  on(EmployerActions.removeEmployerContactFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    employerSectionActionState: {
      sectionType: SectionType.Contacts,
      action: ACTION.REMOVE,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.addEmployerPortalUser, (state) => ({
    ...state,
    isLoading: true,
    employerSectionActionState: undefined,
  })),
  on(EmployerActions.addEmployerPortalUserSuccess, (state) => ({
    ...state,
    employerSectionActionState: {
      sectionType: SectionType.PortalUsers,
      action: ACTION.ADD,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.addEmployerPortalUserFailure, (state, { errorMsg }) => ({
    ...state,
    employerSectionActionState: {
      sectionType: SectionType.PortalUsers,
      action: ACTION.ADD,
      state: STATE.FAIL,
      message: errorMsg,
    },
  })),
  on(EmployerActions.checkEmployerPortalUser, (state, { body }) => ({
    ...state,
    employerCheckSectionActionState: {
      isLoading: true,
    },
  })),
  on(EmployerActions.checkEmployerPortalUserSuccess, (state, { res }) => ({
    ...state,
    employerCheckSectionActionState: {
      isLoading: false,
      otherExists: res.otherExists,
    },
  })),
  on(EmployerActions.checkEmployerPortalUserFailure, (state, { errorMsg }) => ({
    ...state,
    employerCheckSectionActionState: {
      isLoading: false,
      errorMsg,
    },
  })),
  on(EmployerActions.clearCheckEmployerPortalUser, (state) => ({
    ...state,
    employerCheckSectionActionState: undefined,
  })),
  on(EmployerActions.updateEmployerPortalUser, (state) => ({
    ...state,
    isLoading: true,
    employerSectionActionState: undefined,
  })),
  on(EmployerActions.updateEmployerPortalUserSuccess, (state) => ({
    ...state,
    employerSectionActionState: {
      sectionType: SectionType.PortalUsers,
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerActions.updateEmployerPortalUserFailure, (state, { errorMsg }) => ({
    ...state,
    employerSectionActionState: {
      sectionType: SectionType.PortalUsers,
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.removeEmployerPortalUser, (state) => ({
    ...state,
    isLoading: true,
    employerSectionActionState: undefined,
  })),
  on(EmployerActions.removeEmployerPortalUserSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      employerSectionActionState: {
        sectionType: SectionType.PortalUsers,
        action: ACTION.REMOVE,
        state: STATE.SUCCESS,
      },
    };
  }),
  on(EmployerActions.removeEmployerPortalUserFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    employerSectionActionState: {
      sectionType: SectionType.PortalUsers,
      action: ACTION.REMOVE,
      state: STATE.FAIL,
    },
  })),
  on(EmployerActions.createEmployerFilterSuccess, (state) => {
    return {
      ...state,
      createEmloyerFilterState: STATE.SUCCESS,
    };
  }),
  on(EmployerActions.createEmployerFilterFailure, (state) => {
    return {
      ...state,
      createEmloyerFilterState: STATE.FAIL,
    };
  }),
  on(EmployerActions.getEmployerFilterRequest, (state) => {
    return {
      ...state,
      listFilterInfo: {
        isLoading: true,
        payload: [],
      },
    };
  }),
  on(EmployerActions.getEmployerFilterSuccess, (state, { listFilterInfo }) => {
    return {
      ...state,
      listFilterInfo: {
        isLoading: false,
        payload: listFilterInfo,
      },
    };
  }),
  on(EmployerActions.getEmployerFilterFailure, (state) => {
    return {
      ...state,
      listFilterInfo: {
        isLoading: false,
        payload: [],
      },
    };
  }),
  on(EmployerActions.removeEmployerFilterSuccess, (state, { id }) => {
    return {
      ...state,
      removeEmployerFilterState: id,
    };
  }),
  on(EmployerActions.updateEmployerFilterNameSuccess, (state, { id, listFilterInfo }) => {
    return {
      ...state,
      updateEmployerFilterNameState: id,
      updateFilterState: listFilterInfo.length > 0 ? STATE.SUCCESS : '',
    };
  }),
  on(EmployerActions.updateEmployerFilterNameFailure, (state, { id, listFilterInfo }) => {
    return {
      ...state,
      updateFilterState: listFilterInfo.length > 0 ? STATE.FAIL : '',
    };
  }),

  on(EmployerActions.clearFilterState, (state) => {
    return {
      ...state,
      createEmloyerFilterState: '',
      removeEmployerFilterState: '',
      updateEmployerFilterNameState: '',
      updateFilterState: '',
    };
  }),
  on(EmployerActions.exportEmployersRequest, (state, { request }) => {
    return {
      ...state,
      isExporting: true,
    };
  }),
  on(EmployerActions.downloadEmployersRequestSuccess, (state, {}) => {
    return {
      ...state,
      isExporting: false,
    };
  }),

  on(createEmployersAction, (state) => ({
    ...state,
    createEmployers: {
      isLoading: true,
    },
  })),
  on(createEmployersSuccessAction, (state, { newEmployerId }) => ({
    ...state,
    createEmployers: {
      isLoading: false,
      success: true,
      payload: { employerId: newEmployerId },
    },
  })),
  on(createEmployersFailureAction, (state, { error }) => ({
    ...state,
    createEmployers: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateEmployersStateAction, (state) => ({
    ...state,
    createEmployers: undefined,
  })),

  on(setEmployersAction, (state) => ({
    ...state,
    setEmployers: {
      isLoading: true,
    },
  })),
  on(setEmployersSuccessAction, (state, { entityLabel }) => ({
    ...state,
    setEmployers: {
      isLoading: false,
      success: true,
      payload: { entityLabel },
    },
  })),
  on(setEmployersFailureAction, (state, { error, entityLabel }) => ({
    ...state,
    setEmployers: {
      isLoading: false,
      success: false,
      error: error,
      payload: { entityLabel },
    },
  })),
  on(clearSetEmployersStateAction, (state) => ({
    ...state,
    setEmployers: undefined,
  })),

  on(removeDocumentsEmployerSuccess, (state) => ({
    ...state,
    removeState: 'Success',
  })),
  on(removeDocumentsEmployerFailure, (state) => ({
    ...state,
    removeState: 'Fail',
  })),

  on(clearRemoveDocumentsEmployerState, (state) => ({
    ...state,
    removeState: '',
  })),

  // Get Eligible Year
  on(getPermissionForMuniPortalUserAction, (state) => ({
    ...state,
    getPermissionForMuniPortalUserState: {
      isLoading: true,
    },
  })),
  on(getPermissionForMuniPortalUserSuccessAction, (state, { response }) => ({
    ...state,
    getPermissionForMuniPortalUserState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getPermissionForMuniPortalUserFailureAction, (state, { error }) => ({
    ...state,
    getPermissionForMuniPortalUserState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetPermissionForMuniPortalUserStateAction, (state) => ({
    ...state,
    getPermissionForMuniPortalUserState: undefined,
  })),

  on(EmployerActions.getListColumnSetEmployersAction, (state) => {
    return {
      ...state,
      listColumnSetEmployer: {
        isLoading: true,
        success: false,
        payload: {},
      },
    };
  }),
  on(EmployerActions.getListColumnSetEmployersSuccessAction, (state, { employerColumnConfigs }) => {
    return {
      ...state,
      listColumnSetEmployer: {
        isLoading: false,
        success: true,
        payload: employerColumnConfigs,
      },
    };
  }),
  on(EmployerActions.getListColumnSetEmployersFailureAction, (state) => {
    return {
      ...state,
      listColumnSetEmployer: {
        isLoading: false,
        success: true,
        payload: {},
      },
    };
  }),
  on(EmployerActions.createColumnSetEmployersSuccess, (state, { id }) => ({
    ...state,
    updateColumnSetState: STATE.SUCCESS,
    idColumnSet: id,
  })),

  on(EmployerActions.createColumnSetEmployersFailure, (state, {}) => ({
    ...state,
    updateColumnSetState: STATE.FAIL,
  })),

  on(EmployerActions.editColumnSetEmployersSuccess, (state, { id }) => ({
    ...state,
    updateColumnSetState: STATE.SUCCESS,
    selectedColumnSetState: id,
    actionColumnSetState: ACTION.EDIT,
  })),

  on(EmployerActions.editColumnSetEmployersFailure, (state, {}) => ({
    ...state,
    updateColumnSetState: STATE.FAIL,
    actionColumnSetState: ACTION.EDIT,
  })),
  on(EmployerActions.removeColumnSetEmployersAction, (state, { id }) => {
    return {
      ...state,
      removeColumnSetState: id,
      removeColumnSetStatusState: STATE.SUCCESS,
    };
  }),
  on(EmployerActions.removeColumnSetEmployerFailure, (state, {}) => {
    return {
      ...state,
      removeColumnSetStatusState: STATE.FAIL,
    };
  }),
  on(EmployerActions.reorderColumnSetEmployersSuccess, (state) => ({
    ...state,
    reorderColumnSetState: STATE.SUCCESS,
  })),

  on(EmployerActions.reorderColumnSetEmployersFailure, (state, {}) => ({
    ...state,
    reorderColumnSetState: STATE.FAIL,
  })),

  on(EmployerActions.clearColumnSetEmployersState, (state) => {
    return {
      ...state,
      reorderColumnSetState: '',
      removeColumnSetStatusState: '',
      removeColumnSetState: '',
      actionColumnSetState: '',
      updateColumnSetState: '',
      selectedColumnSetState: '',
      listColumnSetEmployer: {},
    };
  }),

  on(getValidateEditPaymentMethodAction, (state) => ({
    ...state,
    getValidateEditPaymentMethod: {
      isLoading: true,
    },
  })),
  on(getValidateEditPaymentMethodSuccessAction, (state, { response }) => ({
    ...state,
    getValidateEditPaymentMethod: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getValidateEditPaymentMethodFailureAction, (state, { error }) => ({
    ...state,
    getValidateEditPaymentMethod: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetValidateEditPaymentMethodStateAction, (state) => ({
    ...state,
    getValidateEditPaymentMethod: undefined,
  })),

  //check connect employer with stripe
  on(EmployerActions.checkStripeCustomerExistsAction, (state) => ({
    ...state,
    checkConnectStripe: {
      isLoading: true,
    },
  })),
  on(checkStripeCustomerExistsSuccess, (state, { response }) => ({
    ...state,
    checkConnectStripe: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(checkStripeCustomerExistsFailure, (state, { error }) => ({
    ...state,
    checkConnectStripe: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCheckStripeCustomerExistsAction, (state) => ({
    ...state,
    checkConnectStripe: undefined,
  })),

  //register employer with stripe
  on(EmployerActions.registerCustomerStripeAction, (state) => ({
    ...state,
    customerStripe: {
      isLoading: true,
    },
  })),
  on(registerCustomerStripeSuccess, (state, { response }) => ({
    ...state,
    customerStripe: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(registerCustomerStripeFailure, (state, { error }) => ({
    ...state,
    customerStripe: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearRegisterCustomerStripeAction, (state) => ({
    ...state,
    customerStripe: undefined,
  })),
);
