export enum PropertyType {
  // Primitive Types
  Text = 1,
  Email = 2,
  Phone = 3,
  Whole_Number = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  DateTime = 8,
  Binary = 9,
  Percentage = 10,
  SSN = 11,
  RichText = 12,

  // Reference Types
  Lookup = 50,
  Employer = 51,
  Department = 52,
  Benefit = 53,
  EntityReference = 54,
  Tier = 55,

  // Objects
  Address = 100,
  Person_Name = 101,
  Status = 102,
  Aggregation = 103,
  Calculation = 104,
  Register = 200,
  SmsOptedIn = 201,
  PaperlessOptedIn = 202,
  System = 203,
  Data = 204,
  Identifier = 205,
}

export enum PropertyTypeLabel {
  // Primitive Types
  'Text' = 1,
  'Email' = 2,
  'Phone' = 3,
  'Whole Number' = 4,
  'Currency' = 5,
  'Decimal' = 6,
  'Date' = 7,
  'Date Time' = 8,
  'Binary' = 9,
  'Percentage' = 10,
  'SSN' = 11,
  'RichText' = 12,

  // Reference Types
  'Lookup' = 50,
  'Employer' = 51,
  'Department' = 52,
  'Benefit' = 53,
  'Entity Reference' = 54,
  'Tier' = 55,

  // Objects
  'Address' = 100,
  'Person Name' = 101,
  'Status' = 102,
  'Aggregation' = 103,
  'Calculation' = 104,
  'Register' = 200,
  'SmsOptedIn' = 201,
  'PaperlessOptedIn' = 202,
  'System' = 203,
  'Data' = 204,
}

export enum EntityObjectType {
  None,
  PropertyList,
  List,
}

export enum EntityType {
  BaseEntity,
  FixedEntity,
  None,
}
