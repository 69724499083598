import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Breadcrumb, FunctionButtonConfig, FunctionButtonData, StepperState } from '../../types/models/breadcrumb.model';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Auth0Service } from 'src/app/shared/auth/services/auth0.service';
import { ADMIN_SYSTEM } from '../../constance/value.const';
import { MatStepper, MatStepperIntl } from '@angular/material/stepper';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { iconRestart } from '../../constance/listIcons.const';
import { STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { Store } from '@ngrx/store';
import * as fromReducer from '@ptg-reducers';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { Module } from '@ptg-fund-list/models/fund-list.model';
import { deepClone, deepFlattenArray } from '@ptg-shared/utils/common.util';
import { ColumnDetail } from '@ptg-member/types/models/member-list';

@Component({
  selector: 'ptg-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() listBreadcrumbs!: Breadcrumb[];
  @Input() buttonAddLabel?: string;
  @Input() buttonEditLabel?: string;
  @Input() buttonDeleteLabel?: string;
  @Input() settings?: Breadcrumb[];
  @Input() controlField?: AbstractControl;
  @Input() functionButtons?: FunctionButtonConfig[];
  @Input() stepperState?: StepperState;
  @Input() disableStepper?: boolean;
  @Input() isDisabledGear?: boolean;
  @Input() noTruncateButton?: boolean;
  @Input() panelClass?: string;
  @Input() completedStep?: any = 3;
  @Input() listColumnConfig: any[] = [];
  @Input() selectColumn: any;
  @Input() isEmployerPortal!: boolean;
  
  @Output() onAdd: EventEmitter<void> = new EventEmitter();
  @Output() onEdit: EventEmitter<void> = new EventEmitter();
  @Output() onDelete: EventEmitter<void> = new EventEmitter();
  @Output() editName: EventEmitter<boolean> = new EventEmitter();
  @Output() clickBreadcrumb: EventEmitter<Breadcrumb> = new EventEmitter();
  @Output() emitFunction: EventEmitter<FunctionButtonData> = new EventEmitter();
  @Output() onChangeStep: EventEmitter<number> = new EventEmitter();
  @Output() onSelectSetting: EventEmitter<string> = new EventEmitter();
  @Output() onSelectColumnConfig: EventEmitter<string> = new EventEmitter();

  @ViewChild('breadcrumbList') breadcrumbList!: ElementRef;
  @ViewChild('stepper') stepper!: MatStepper;

  ADMIN_SYSTEM = ADMIN_SYSTEM;
  listModule?: Module[];
  editColumnForm: FormGroup = this.fb.group({
    columnConfig: this.fb.control(''),
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public authService: Auth0Service,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private store: Store<fromReducer.State>,
  ) {
    super();
    iconRegistry.addSvgIconLiteral('reset-icon', sanitizer.bypassSecurityTrustHtml(iconRestart));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stepperState && this.stepper) {
      this.stepper.linear = false;
      setTimeout(() => {
        this.stepper.linear = true;
      });
    }
    if (changes.listBreadcrumbs && this.listModule?.length) {
      this.mapBreadcrumbsByModuleName();
    }
    if (changes?.listColumnConfig || changes?.selectColumn) {
      this.editColumnForm.patchValue({
        columnConfig: this.selectColumn?.columnSetName
      });
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.select(fromReducer.selectCurrentFundState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(currentFund => {
        this.listModule = currentFund?.modules;
        if (this.listModule?.length) {
          this.mapBreadcrumbsByModuleName();
        }
      });
  }

  mapBreadcrumbsByModuleName() {
    this.listBreadcrumbs.forEach(item => {
      const allModules = (deepFlattenArray(this.listModule || [], 'subModules') || []);
      const module = allModules?.find((x: Module) => x.moduleKey === item.moduleKey);
      if (module) {
        item.name = module.parentId ? module.moduleName : module.clientModuleName;
      }
    });
  }

  onClickBreadcrumb(breadcrumb: Breadcrumb) {
    if (breadcrumb.url) {
      if (breadcrumb.url.includes('/member/detail/') && this.router.url.includes(STRING_QUERY_PARAM.SEARCH)) {
        breadcrumb.url += `?${STRING_QUERY_PARAM.SEARCH}`;
      }
      //Save history state to pass value to other components
      void this.router.navigateByUrl(breadcrumb.url, { state: history.state });
    }
    this.clickBreadcrumb.emit(breadcrumb);
  }

  onClickAdd() {
    this.onAdd.emit();
  }

  onEditName(isSave: boolean = false) {
    this.editName.emit(isSave);
  }

  onClickEdit() {
    this.onEdit.emit();
  }

  onClickDelete() {
    this.onDelete.emit();
  }

  onClickFunctionButton(buttonName: string) {
    this.emitFunction.emit({ buttonName });
  }

  selectionChange(event: any) {
    if (!this.stepperState) {
      return;
    }
    this.onChangeStep.emit(event.selectedIndex);
  }

  selectSetting(setting?: Breadcrumb) {
    if (setting?.url) {
      void this.router.navigateByUrl(setting.url);

    }
    this.onSelectSetting.emit(setting?.name);
  }

  onChangeColumnConfig() {
    const { columnConfig } = this.editColumnForm.value;
    this.onSelectColumnConfig.emit(columnConfig);
  }
}
