import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckResponse } from 'src/app/fund-list/models/add-fund.model';
import { environment } from 'src/environments/environment';
import * as fromReducer from '../../reducers/index';
import { Category, ResourceCenterData, ResourceCenterQuery } from '../models/resource-center.models';

@Injectable({
  providedIn: 'root'
})
export class ResourceCenterService {
  editBody = new FormData();
  isUploadDocument = false;

  get Endpoint(): string { return `${environment.apiUrl}/Resources`; }
  activeFund: any;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>,
    private authService: Auth0Service
  ) {
    this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe(el => this.activeFund = el);
  }

  getResourceCenter(query: ResourceCenterQuery): Observable<ResourceCenterData> {
     let params = new HttpParams();
    params = params.append('ClientId', this.activeFund?.id);
    params = params.append('PageSize', query.totalPerPage);
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append('SortNames', query.sortField[0].toUpperCase() + query.sortField.substring(1));
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    return this.httpClient.get<ResourceCenterData>(
      this.Endpoint, { params }
    );
  }

  removeResourceCenter(fileId: string): Observable<any> {
    return this.httpClient.delete(`${this.Endpoint}/${fileId}`);
  }

  getCategoryList(): Observable<Category[]> {
   return this.httpClient.get<{categories: Category[]}>(`${this.Endpoint}/Categories?ClientId=${this.activeFund?.id}`).pipe(
     map(res => res.categories)
   );
  }

  uploadResourceDocument(dataEdit?: any) {
    if (!dataEdit) {
      this.isUploadDocument = true;
      return this.httpClient.post(`${this.Endpoint}?ClientId=${this.activeFund?.id}`, this.editBody);
    } else {
      this.isUploadDocument = false;
      this.editBody.set('Category.Name', dataEdit.category.name);
      return this.httpClient.put(`${this.Endpoint}/${dataEdit.id}`, dataEdit);
    }
  }

  checkCategoryExisted(categoryName: string, isAddCategory: boolean): Observable<any> {
    let body = {
      clientId: this.activeFund?.id,
      categoryName: categoryName ? categoryName.trim() : ''
    }

    if (!categoryName || !isAddCategory) {
      return of(null);
    }
    return this.httpClient.post<CheckResponse>(`${this.Endpoint}/Categories/Exists`, body);
  }

  checkExists = (data: any) => {
    if (data && data.newCategory) {
      return this.checkCategoryExisted(data.newCategory, true);
    }
    return of(null);
  }

  updateCategories(body: any) {
    return this.httpClient.put(`${this.Endpoint}/Categories`, body);
  }

  getFundByKey(key: string): Observable<FundModel> {
    return this.httpClient.get<FundModel>(`${environment.apiUrl}/ClientPortals/${key}`);
  }

  checkFileResoureceExisted(fileName: string): Observable<any> {
    let body = {
      clientId: this.activeFund?.id,
      clientKey: this.activeFund?.key,
      fileName: fileName
    }

    if (!fileName) {
      return of(null);
    }
    return this.httpClient.post<CheckResponse>(`${this.Endpoint}/Exists`, body);
  }

  bulkDownload(listFileName: string[]) {
    let body = {
      clientKey: this.activeFund?.key,
      listFileName: listFileName
    };
    return this.httpClient.post(`${this.Endpoint}/BulkDownload`, body, {responseType: 'blob'});
  }
}
