import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  checkMemberGeneratingDocumentAction,
  checkMemberGeneratingDocumentFailureAction,
  checkMemberGeneratingDocumentSuccessAction,
  clearCheckMemberGeneratingDocumentAction,
  clearEditDocumentsStateAction,
  clearGetDocumentListsStateAction,
  clearNavigateToDocumentListsAction,
  clearRemoveDocumentsStateAction,
  clearUploadDocumentsStateAction,
  editDocumentsAction,
  editDocumentsFailureAction,
  editDocumentsSuccessAction,
  getDocumentListsAction,
  getDocumentListsFailureAction,
  getDocumentListsSuccessAction,
  navigateToDocumentListsAction,
  removeDocumentsAction,
  removeDocumentsFailureAction,
  removeDocumentsSuccessAction,
  uploadDocumentsAction,
  uploadDocumentsFailureAction,
  uploadDocumentsSuccessAction,
} from '../actions/member-document.action';
import { DocumentList } from '@ptg-member/types/models/member-document.model';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';
import { GetGeneratedReportsInformationResponse } from 'src/app/admin/features/file/services/models/documents.model';

export const memberDocumentFeatureKey = 'memberDocument';
export interface DocumentListState {
  getDocumentLists?: BaseActionState<DocumentList[]>;
  removeDocuments?: ActionState;
  uploadDocuments?: ActionState;
  editDocuments?: ActionState;
  checkGeneratingDocument?: BaseActionState<GetGeneratedReportsInformationResponse>;
  getNavigateToDocumentListWithGenerateId?: string;
}

const initialState: DocumentListState = {};

export const documentListReducer = createReducer(
  initialState,
  on(getDocumentListsAction, (state) => ({
    ...state,
    getDocumentLists: {
      isLoading: true,
    },
  })),
  on(getDocumentListsSuccessAction, (state, { response }) => ({
    ...state,
    getDocumentLists: {
      isLoading: false,
      success: true,
      payload: response.participantDocuments,
      total: response.total,
    },
  })),
  on(getDocumentListsFailureAction, (state, { error }) => ({
    ...state,
    getDocumentLists: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetDocumentListsStateAction, (state) => ({
    ...state,
    getDocumentLists: undefined,
  })),

  // Remove document
  on(removeDocumentsAction, (state) => ({
    ...state,
    removeDocuments: undefined,
  })),
  on(removeDocumentsSuccessAction, (state) => ({
    ...state,
    removeDocuments: {
      state: STATE.SUCCESS,
      action: ACTION.REMOVE,
    },
  })),
  on(removeDocumentsFailureAction, (state, { error }) => ({
    ...state,
    removeDocuments: {
      state: STATE.FAIL,
      action: ACTION.REMOVE,
    },
  })),
  on(clearRemoveDocumentsStateAction, (state) => ({
    ...state,
    removeDocuments: undefined,
  })),

  // Upload Document
  on(uploadDocumentsAction, (state) => ({
    ...state,
    uploadDocuments: {
      state: 'loading',
      action: ACTION.UPLOAD,
    },
  })),
  on(uploadDocumentsSuccessAction, (state) => ({
    ...state,
    uploadDocuments: {
      state: STATE.SUCCESS,
      action: ACTION.UPLOAD,
    },
  })),
  on(uploadDocumentsFailureAction, (state, { error }) => ({
    ...state,
    uploadDocuments: {
      state: STATE.FAIL,
      action: ACTION.UPLOAD,
    },
  })),
  on(clearUploadDocumentsStateAction, (state) => ({
    ...state,
    uploadDocuments: undefined,
  })),

  //Edit document
  on(editDocumentsAction, (state) => ({
    ...state,
    editDocuments: undefined,
  })),
  on(editDocumentsSuccessAction, (state) => ({
    ...state,
    editDocuments: {
      state: STATE.SUCCESS,
      action: ACTION.UPLOAD,
    },
  })),
  on(editDocumentsFailureAction, (state, { error }) => ({
    ...state,
    editDocuments: {
      state: STATE.FAIL,
      action: ACTION.UPLOAD,
    },
  })),
  on(clearEditDocumentsStateAction, (state) => ({
    ...state,
    editDocuments: undefined,
  })),

  on(checkMemberGeneratingDocumentAction, (state) => ({
    ...state,
    checkGeneratingDocument: {
      isLoading: true,
    },
  })),
  on(checkMemberGeneratingDocumentSuccessAction, (state, { response }) => ({
    ...state,
    checkGeneratingDocument: {
      isLoading: false,
      success: true,
      payload: response
    },
  })),
  on(checkMemberGeneratingDocumentFailureAction, (state, { error }) => ({
    ...state,
    checkGeneratingDocument: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCheckMemberGeneratingDocumentAction, (state) => ({
    ...state,
    checkGeneratingDocument: undefined,
  })),

  on(navigateToDocumentListsAction, (state ,{ generateInformationId }) => ({
    ...state,
    getNavigateToDocumentListWithGenerateId: generateInformationId,
  })),
  on(clearNavigateToDocumentListsAction, (state) => ({
    ...state,
    getNavigateToDocumentListWithGenerateId: undefined,
  })),
);
