import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '@ptg-shared/components';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Subject } from 'rxjs';
import { tap, debounceTime, switchMap, startWith, take, filter } from 'rxjs/operators';
import { Option } from '@ptg-shared/controls/select/select.component';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { BenefitMappingPayload } from '@ptg-entity-management/services/models';

@Component({
  selector: 'ptg-edit-benefit-mapping',
  templateUrl: './edit-benefit-mapping.component.html',
  styleUrls: ['./edit-benefit-mapping.component.scss']
})
export class EditBenefitMappingComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[];
  editForm: FormGroup = new FormGroup({
    calculation: new FormControl(''),
  });
  typeSelect = new FormControl('');
  formSubmit$ = new Subject<boolean>();
  listChip: Option[] = [];
  listParent: Option[] = [];
  listCalculation: Option[] = [];
  message = '';
  bannerType: BannerType = BannerType.Hidden; 

  constructor(
    public dialogRef: MatDialogRef<EditBenefitMappingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      fundName: string;
      name: string;
      calculation: string;
      parentBenefitEntity: {id: string; name: string}[],
      calculationList: {id: string; name: string}[];
      parentBenefitEntityList: {id: string; name: string}[];
      isUsed: boolean;
      id: string;
    },
    public dialog: MatDialog,
  ) {
    super();
    this.listBreadcrumbs = [
      {
        name: 'Fund List',
        url: '/fund-list',
      },
      {
        name: data.fundName + ' Mapping List',
      },
      {
        name: `Edit ${data?.name} Mapping`,
      },
    ];
    this.listCalculation = this.data.calculationList.map(el => ({displayValue: el.name, value: el.id}));
    this.listParent = this.data.parentBenefitEntityList.map(el => ({displayValue: el.name, value: el.id}));
  }

  ngOnInit(): void {
    this.onSubmit();
    if (this.data.isUsed) {
      showBanner.call(
        this,
        BannerType.Warning,
        '',
        '',
        {
          customMessage: 'This Benefit Mapping is currently in-used. Please remove all related calculation record and configuration first.'
        }
      );

    }
    
    this.setValueToForm();
  }

  clickBreadcrumb(event: any) {
    this.dialogRef.close();
  }

  setValueToForm() {
    this.editForm.get('calculation')?.setValue(this.data.calculation);
    let indexM = this.listParent.findIndex(item => item.value === this.data.id);
    if (indexM > -1) {
      this.listParent.splice(indexM, 1);
    }
    this.data.parentBenefitEntity.forEach(el => {
      let index = this.listParent.findIndex(item => item.value === el.id);
      if (index > -1) {
        let temp = this.listParent.splice(index, 1);
        this.listChip.push(temp[0]);
      }
    });
    this.refreshListParent();
  }

  onSubmit() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.typeSelect.markAsTouched();
        }),
        debounceTime(500),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter(status => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.saveValue();
      });
  }

  saveValue() {
    if (this.editForm.valid && this.typeSelect.valid) {
      let parentBenefitEntity = this.listChip.map(el => ({
        id: el.value
      }));
      let payload: BenefitMappingPayload = {
        benefitMapping: {
          id: this.data.id,
          calculation: this.editForm.get('calculation')?.value,
          parentBenefitEntity,
          name: this.data.name
        }
      };
      this.dialogRef.close(payload);
    }
  }

  onCancelClick() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  addType() {
    let index = -1;
    let selectedItem = this.listParent.find((el, i) => {
      if (el.value === this.typeSelect.value) {
        index = i;
        return true;
      }
      return false;
    });
    // Add item to listChip, clear selector, remove option out of list
    if (selectedItem) {
      this.listChip.push(selectedItem);
      this.typeSelect.setValue('');
      this.listParent.splice(index, 1);
      this.refreshListParent();
    }
  }

  refreshListParent() {
    let temp = deepClone(this.listParent);
    this.listParent = [];
    this.listParent = temp;
  }

  removeChip(index: number) {
    let item = this.listChip.splice(index, 1);
    this.listParent.push(item[0]);
    this.listParent.sort((a, b) => {
      if (a.displayValue < b.displayValue) {
        return -1;
      }
      return 1;
    });
    this.refreshListParent();
  }
}
