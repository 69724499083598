import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as fromReducer from '../../../reducers/index';
import { EmployerDetail } from '../models/layout.model';

@Injectable({
  providedIn: 'root'
})
export class AppContentService {
  activeFund: any;

  constructor(private httpClient: HttpClient, private store: Store<fromReducer.State>) { 
    this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe(el => this.activeFund = el);
  }

  getMenuItems() {
    if (this.activeFund?.id) {
      return this.httpClient.get(`${environment.apiUrl}/AppContent/PageDefinitions?clientId=${this.activeFund?.id}`).pipe(
        map((res: any) => {
          if (res) {
            res.pageDefinitions.sort((a: any, b: any) => {
              if (a && b && a.order > b.order) {
                return 1;
              }
              return -1;
            })
          }
          return res ? res.pageDefinitions : [];
        })
      );
    }
    return of();
  }

  getEmployerInfoForHeader(employerId: string): Observable<EmployerDetail> {
    return this.httpClient.get<EmployerDetail>(`${environment.apiUrl}/employer-portal/employer/${employerId}/detail`);
  }
}
