<div class="datetime-picker">
  <mat-form-field
    (mousedown)="onMouseDown($event)"
    appearance="fill"
    class="{{ class }}"
    [ngClass]="{
      'invalid-required': controlField?.errors?.required && !controlField?.errors?.matDatepickerParse,
      'invalid-min-date':
        !controlField?.errors?.required &&
        !controlField?.errors?.matDatepickerParse &&
        controlField?.errors?.matDatepickerMin,
      'invalid-max-date':
        !controlField?.errors?.required &&
        !controlField?.errors?.matDatepickerParse &&
        controlField?.errors?.matDatepickerMax
    }">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [formControl]="controlField"
      [min]="minDate"
      [max]="maxDate"
      (dateChange)="onChangeValue($event)"
      (keypress)="onKeypress($event)"
      [matDatepickerFilter]="weekendsDatesFilter"
      [disabled]="isDisabled ? isDisabled : false"
      (focusout)="onFocusOut($event)" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker panelClass="date-picker-container"></mat-datepicker>
    <mat-error *ngIf="controlField?.errors?.required && !controlField?.errors?.matDatepickerParse"
      >{{ this.placeholder }} is required.</mat-error
    >
    <mat-error *ngIf="controlField?.errors?.matDatepickerParse">{{ errorInvalid }}</mat-error>
    <mat-error
      *ngIf="
        !controlField?.errors?.required &&
        !controlField?.errors?.matDatepickerParse &&
        controlField?.errors?.matDatepickerMin
      "
      >{{ checkMinDate ? this.placeholder + ' must be a future date.' : errorMinDate }}</mat-error
    >
    <mat-error
      *ngIf="
        !controlField?.errors?.required &&
        !controlField?.errors?.matDatepickerParse &&
        !controlField?.errors?.matDatepickerMin &&
        controlField?.errors?.matDatepickerMax
      "
      >{{ errorMaxDate }}</mat-error
    >
    <mat-error
      *ngIf="
        !controlField?.errors?.required &&
        !controlField?.errors?.matDatepickerParse &&
        !controlField?.errors?.matDatepickerMin &&
        !controlField?.errors?.matDatepickerMax &&
        controlField?.errors?.inRange
      "
      >{{ errorRange }}</mat-error
    >
    <mat-error
      *ngIf="
        controlField?.errors?.[customError] &&
        !controlField?.errors?.matDatepickerParse &&
        !controlField?.errors?.required
      "
      >{{ controlField.getError([customError]) }}</mat-error
    >
    <mat-error
      *ngIf="
        !controlField?.errors?.required &&
        !controlField?.errors?.matDatepickerParse &&
        !controlField?.errors?.matDatepickerMin &&
        !controlField?.errors?.matDatepickerMax &&
        !controlField?.errors?.inRange &&
        !controlField?.errors?.[customError] &&
        errorHoliday === '' &&
        controlField?.errors?.matDatepickerFilter
      "
      >{{ this.placeholder + ' must not be on the weekend.' }}</mat-error
    >

    <mat-error
      *ngIf="
        !controlField?.errors?.required &&
        !controlField?.errors?.matDatepickerParse &&
        !controlField?.errors?.matDatepickerMin &&
        !controlField?.errors?.matDatepickerMax &&
        !controlField?.errors?.inRange &&
        errorHoliday !== '' &&
        controlField?.errors?.matDatepickerFilter
      "
      >{{errorHoliday}}</mat-error
    >
    <mat-error *ngIf="controlField?.errors?.inValidAsync">{{ errorAsync }}</mat-error>
  </mat-form-field>
</div>
