<div class="dialog-container">
  <div class="header-title">Edit Property</div>
  <form class="edit-form" [formGroup]="editForm">
    <div>
      <div class="input-container">
        <ptg-textbox
          [controlField]="propertyNameCtrl"
          placeholder="Property Name"
          [hasLabel]="true"
          [isRequired]="true"
          errorAsync="Property Name or Import Label already exists."
        ></ptg-textbox>

        <ptg-select
          placeholder="Type"
          [controlField]="propertyTypeCtrl"
          [listData]="properties"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
        ></ptg-select>
      </div>
      <div class="input-container">
        <ptg-select
          *ngIf="propertyDetail?.type === EntityPropertyType.Aggregation"
          placeholder="List"
          [controlField]="listIdCtrl"
          [listData]="listOptions"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
        ></ptg-select>
        <ptg-select
          *ngIf="propertyDetail?.type === EntityPropertyType.Aggregation"
          placeholder="Property"
          [controlField]="propertyIdCtrl"
          [listData]="propertyOptions"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
        ></ptg-select>
      </div>
      <div class="input-container">
        <ptg-select
          *ngIf="propertyDetail?.type === EntityPropertyType.Aggregation"
          placeholder="Aggregate"
          [controlField]="aggregateIdCtrl"
          [listData]="aggregateOptions"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
        ></ptg-select>
      </div>
    </div>

    <div class="row-button">
      <button
        mat-raised-button
        type="button"
        class="primary"
        (click)="formSubmit$.next(true)"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
