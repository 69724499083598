<ptg-dialog
  class="upsert-member-dialog"
  [title]="'Add new ' + title"
  [footerPosition]="'top'"
  [footerAlign]="'left'"
  [isFullScreen]="true"
>
  <ng-template dialogBody>
    <div class="mb-4">
      <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>
    <ptg-upsert-entity-data
      [isSubmitting]="isSubmitting"
      [properties]="properties"
      [fixedProperties]="layoutService.fundType === FundType.BVFF && this.auth0Service.isMunicipalityPortal$?.value ? seviceHistoryFixedProperties : undefined"
      [entityId]="entityId"
      (addNewEvent)="onSubmit($event)"
      (cancelEvent)="onCancel()"
      [isEffectiveData]="true"
      [isBvff]="layoutService.fundType === FundType.BVFF"
    ></ptg-upsert-entity-data>
  </ng-template>
</ptg-dialog>
