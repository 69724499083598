import { createReducer, on } from '@ngrx/store';
import {
  checkHaveZipFileAction,
  checkHaveZipFileFailureAction,
  checkHaveZipFileSuccessAction,
  clearCheckHaveZipFileStateAction,
  clearDownloadMultipleDocumentsStateAction,
  clearGenerateMunicipalityDocumentsStateAction,
  clearGenerateParticipantDocumentsStateAction,
  clearGetDocumentDownloadStateAction,
  clearGetGeneratedReportDetailStateAction,
  clearGetGeneratedReportsInformationStateAction,
  clearGetListTemplateStateAction,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  clearListTemplateTagsStateAction,
  clearPrepareDownloadMultipleDocumentsStateAction,
  clearValidateGenerateAge65LetterStateAction,
  downloadMultipleDocumentsAction,
  downloadMultipleDocumentsFailureAction,
  downloadMultipleDocumentsSuccessAction,
  generateMunicipalityDocumentsAction, generateMunicipalityDocumentsFailureAction,
  generateMunicipalityDocumentsSuccessAction,
  generateParticipantDocumentsAction,
  generateParticipantDocumentsFailureAction,
  generateParticipantDocumentsSuccessAction,
  getDocumentDownloadAction,
  getDocumentDownloadFailureAction,
  getDocumentDownloadSuccessAction,
  getGeneratedReportDetailAction,
  getGeneratedReportDetailFailureAction,
  getGeneratedReportDetailSuccessAction,
  getGeneratedReportsInformationAction,
  getGeneratedReportsInformationFailureAction,
  getGeneratedReportsInformationSuccessAction,
  getListTemplateAction,
  getListTemplateFailureAction,
  getListTemplateSuccessAction,
  getListTemplateTagsAction,
  getListTemplateTagsFailureAction,
  getListTemplateTagsSuccessAction,
  getRetirementBenefitDownloadDocumentAction,
  getRetirementBenefitDownloadDocumentFailureAction,
  getRetirementBenefitDownloadDocumentSuccessAction,
  prepareDownloadMultipleDocumentsAction,
  prepareDownloadMultipleDocumentsFailureAction,
  prepareDownloadMultipleDocumentsSuccessAction,
  validateGenerateAge65LetterAction,
  validateGenerateAge65LetterFailureAction,
  validateGenerateAge65LetterSuccessAction,
} from '../actions';
import { BaseActionState } from '@ptg-shared/types/models';
import { DocumentTemplate } from 'src/app/admin/features/template/services/models';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';
import { DocumentTemplateResponse, GetGeneratedReportsInformationResponse } from 'src/app/admin/features/file/services/models/documents.model';

export interface GenerateDocumentState extends ActionState {
  isLoading: boolean;
  isSaved?: boolean;
  isValid?: boolean;
  message?: string;
  filePreview?: any;
  generateInformationId?: string;
}

export interface DocumentsState {
  getDocumentDownloadState?: BaseActionState<Blob[]>;
  downloadMultipleDocuments?: BaseActionState<Blob[]>;
  prepareDownloadMultipleDocuments?: BaseActionState<any>;
  checkHaveZipFileState?: BaseActionState<any>;
  getListTemplateState?: BaseActionState<DocumentTemplate[]>;
  generateDocument?: GenerateDocumentState;
  generateMunicipalityDocument?: GenerateDocumentState;
  getRetirementBenefitDownloadDocument?: BaseActionState<Blob[]>;
  validateGenerateAge65Letter?: BaseActionState<any>;
  getGeneratedReportsInformation?: BaseActionState<GetGeneratedReportsInformationResponse>;
  getGeneratedReportDetail?: BaseActionState<any>;
  getListTemplateTagsState?: BaseActionState<DocumentTemplateResponse>;
}

const initialState: DocumentsState = {};

export const documentsReducer = createReducer(
  initialState,
  //Dowload inline document
  on(getDocumentDownloadAction, (state) => ({
    ...state,
    getDocumentDownloadState: {
      isLoading: true,
    },
  })),

  on(getDocumentDownloadSuccessAction, (state) => ({
    ...state,
    getDocumentDownloadState: {
      isLoading: false,
      success: true,
    },
  })),
  on(getDocumentDownloadFailureAction, (state, { error }) => ({
    ...state,
    getDocumentDownloadState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetDocumentDownloadStateAction, (state) => ({
    ...state,
    getDocumentDownloadState: undefined,
  })),

  // Prepare Download
  on(prepareDownloadMultipleDocumentsAction, (state) => ({
    ...state,
    prepareDownloadMultipleDocuments: {
      isLoading: true,
    },
  })),
  on(prepareDownloadMultipleDocumentsSuccessAction, (state, { response }) => ({
    ...state,
    prepareDownloadMultipleDocuments: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(prepareDownloadMultipleDocumentsFailureAction, (state, { error }) => ({
    ...state,
    prepareDownloadMultipleDocuments: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearPrepareDownloadMultipleDocumentsStateAction, (state) => ({
    ...state,
    prepareDownloadMultipleDocuments: undefined,
  })),

  // Download After Zipping
  on(downloadMultipleDocumentsAction, (state) => ({
    ...state,
    downloadMultipleDocuments: {
      isLoading: true,
    },
  })),
  on(downloadMultipleDocumentsSuccessAction, (state) => ({
    ...state,
    downloadMultipleDocuments: {
      isLoading: false,
      success: true,
    },
  })),
  on(downloadMultipleDocumentsFailureAction, (state, { error }) => ({
    ...state,
    downloadMultipleDocuments: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearDownloadMultipleDocumentsStateAction, (state) => ({
    ...state,
    downloadMultipleDocuments: undefined,
  })),

  on(checkHaveZipFileAction, (state) => ({
    ...state,
    checkHaveZipFileState: {
      isLoading: true,
    },
  })),

  on(checkHaveZipFileSuccessAction, (state, { response }) => ({
    ...state,
    checkHaveZipFileState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(checkHaveZipFileFailureAction, (state, { error }) => ({
    ...state,
    checkHaveZipFileState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCheckHaveZipFileStateAction, (state) => ({
    ...state,
    checkHaveZipFileState: undefined,
  })),

  // Get Template List
  on(getListTemplateAction, (state) => ({
    ...state,
    getListTemplateState: {
      isLoading: true,
    },
  })),
  on(getListTemplateSuccessAction, (state, { response }) => ({
    ...state,
    getListTemplateState: {
      isLoading: false,
      success: true,
      payload: response.templates,
      total: response.total,
    },
  })),
  on(getListTemplateFailureAction, (state, { error }) => ({
    ...state,
    getListTemplateState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetListTemplateStateAction, (state) => ({
    ...state,
    getListTemplateState: undefined,
  })),

  //generateParticipantDocument
  on(generateParticipantDocumentsAction, (state, {}) => ({
    ...state,
    generateDocument: {
      isLoading: true,
      action: ACTION.GENERATE,
      state: '',
    },
  })),
  on(generateParticipantDocumentsSuccessAction, (state, { response }) => ({
    ...state,
    generateDocument: {
      isLoading: false,
      action: ACTION.GENERATE,
      state: STATE.SUCCESS,
      isSaved: response.isSaved,
      isValid: response.isValid,
      message: response.message,
      filePreview: response.filePreview,
      generateInformationId: response.generateInformationId,
    },
  })),
  on(generateParticipantDocumentsFailureAction, (state, { err }) => ({
    ...state,
    generateDocument: {
      isLoading: false,
      action: ACTION.GENERATE,
      state: STATE.FAIL,
      isSaved: false,
    },
  })),
  on(clearGenerateParticipantDocumentsStateAction, (state) => ({
    ...state,
    generateDocument: undefined,
  })),

  //generateMunicipalityDocument
  on(generateMunicipalityDocumentsAction, (state, {}) => ({
    ...state,
    generateMunicipalityDocument: {
      isLoading: true,
      action: ACTION.GENERATE,
      state: '',
    },
  })),
  on(generateMunicipalityDocumentsSuccessAction, (state, { response }) => ({
    ...state,
    generateMunicipalityDocument: {
      isLoading: false,
      action: ACTION.GENERATE,
      state: STATE.SUCCESS,
      isSaved: response.isSaved,
      isValid: response.isValid,
      message: response.message,
      filePreview: response.filePreview,
    },
  })),
  on(generateMunicipalityDocumentsFailureAction, (state, { error }) => ({
    ...state,
    generateMunicipalityDocument: {
      isLoading: false,
      action: ACTION.GENERATE,
      state: STATE.FAIL,
      isSaved: false,
      error
    },
  })),
  on(clearGenerateMunicipalityDocumentsStateAction, (state) => ({
    ...state,
    generateMunicipalityDocument: undefined,
  })),

  // Download Retirement Benefit Document
  on(getRetirementBenefitDownloadDocumentAction, (state) => ({
    ...state,
    getRetirementBenefitDownloadDocument: {
      isLoading: true,
    },
  })),
  on(getRetirementBenefitDownloadDocumentSuccessAction, (state, { response }) => ({
    ...state,
    getRetirementBenefitDownloadDocument: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getRetirementBenefitDownloadDocumentFailureAction, (state, { error }) => ({
    ...state,
    getRetirementBenefitDownloadDocument: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetRetirementBenefitDownloadDocumentStateAction, (state) => ({
    ...state,
    getRetirementBenefitDownloadDocument: undefined,
  })),

  //validateGenerateAge65Letter
  on(validateGenerateAge65LetterAction, (state) => ({
    ...state,
    validateGenerateAge65Letter: {
      isLoading: true,
    },
  })),
  on(validateGenerateAge65LetterSuccessAction, (state, { response }) => ({
    ...state,
    validateGenerateAge65Letter: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(validateGenerateAge65LetterFailureAction, (state, { error }) => ({
    ...state,
    validateGenerateAge65Letter: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearValidateGenerateAge65LetterStateAction, (state) => ({
    ...state,
    validateGenerateAge65Letter: undefined,
  })),

  //getGeneratedReportsInformation
  on(getGeneratedReportsInformationAction, (state) => ({
    ...state,
    getGeneratedReportsInformation: {
      isLoading: true,
    },
  })),
  on(getGeneratedReportsInformationSuccessAction, (state, { response }) => ({
    ...state,
    getGeneratedReportsInformation: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getGeneratedReportsInformationFailureAction, (state, { error }) => ({
    ...state,
    getGeneratedReportsInformation: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearGetGeneratedReportsInformationStateAction, (state) => ({
    ...state,
    getGeneratedReportsInformation: undefined,
  })),

  //GetGeneratedReportDetail
  on(getGeneratedReportDetailAction, (state) => ({
    ...state,
    getGeneratedReportDetail: {
      isLoading: true,
    },
  })),
  on(getGeneratedReportDetailSuccessAction, (state, { response }) => ({
    ...state,
    getGeneratedReportDetail: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getGeneratedReportDetailFailureAction, (state, { error }) => ({
    ...state,
    getGeneratedReportDetail: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearGetGeneratedReportDetailStateAction, (state) => ({
    ...state,
    getGeneratedReportDetail: undefined,
  })),

  //get list template tags
  on(getListTemplateTagsAction, (state) => ({
    ...state,
    getListTemplateTagsState: {
      isLoading: true,
    },
  })),
  on(getListTemplateTagsSuccessAction, (state, { response }) => ({
    ...state,
    getListTemplateTagsState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getListTemplateTagsFailureAction, (state, { error }) => ({
    ...state,
    getListTemplateTagsState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearListTemplateTagsStateAction, (state) => ({
    ...state,
    getListTemplateTagsState: undefined,
  })),
);
