import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FIRST_PAGE } from '@ptg-shared/controls/pagination';

import { MetadataService } from '../../services/metadata.service';
import { MemberDetailActions } from '../../store/actions';
import {
  ClientSetting,
  ConfirmRetireRehireResponse,
  MemberDetail,
  MemberDetailWithPaging,
  MetadataSection,
  MetadataSectionsNonListResponse,
} from '../../types/models';
import { MemberDetailService } from '../../services/member-detail.service';
import { StatusHistoryService } from '../../services/status-history.service';
import { getClientSettingAction, getClientSettingFailureAction, getClientSettingSuccessAction } from '../actions/member-detail.actions';
import { GetCardsByViewResponse, GetListPropertyForCardsResponse, GetMemberEntityAttachmentsResponse, GetStandAlonePropertyOnlyCardDataResponse } from '@ptg-entity-management/services/models';

@Injectable()
export class MemberDetailEffects {
  getMemberDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberDetail),
      switchMap(({ id, listSection, menuId, pageSize }) => {
        return this.memberDetailService
          .memberDetail(id, listSection, true, false, menuId, pageSize)
          .pipe(
            map((memberDetail: MemberDetail) => {
              return MemberDetailActions.getMemberDetailSuccess({
                memberDetail,
              });
            }),
            catchError((err) => {
              console.log('---------Error-----------------');
              console.log(err);
              return of(
                MemberDetailActions.getMemberDetailFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  lockEntityMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.lockEntityMember),
      switchMap(({ id }) => {
        return this.memberDetailService.lockEntityMember(id).pipe(
          map(() => {
            return MemberDetailActions.lockEntityMemberSuccess();
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.lockEntityMemberFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  unLockEntityMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.unLockEntityMember),
      switchMap(({ id }) => {
        return this.memberDetailService.unlockEntityMember(id).pipe(
          map(() => {
            return MemberDetailActions.unLockEntityMemberSuccess();
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.unLockEntityMemberFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  lockMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.lockMember),
      switchMap(({ id }) => {
        return this.memberDetailService.lockMember(id).pipe(
          map(() => {
            return MemberDetailActions.lockMemberSuccess();
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberDetailActions.lockMemberFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  unlockMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.unlockMember),
      switchMap(({ id }) => {
        return this.memberDetailService.unlockMember(id).pipe(
          map(() => {
            return MemberDetailActions.unlockMemberSuccess();
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberDetailActions.unlockMemberFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  removeMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.removeMember),
      switchMap(({ id }) => {
        return this.memberDetailService.removeMember(id).pipe(
          map(() => {
            return MemberDetailActions.removeMemberSuccess();
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberDetailActions.removeMemberFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  deactivateMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.deactivateMember),
      switchMap(({ id, actionCode }) => {
        return this.memberDetailService.deactivateMember(id, actionCode).pipe(
          map(() => {
            return MemberDetailActions.deactivateMemberSuccess();
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberDetailActions.deactivateMemberFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  removeSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.removeMemberSection),
      switchMap(({ id, sectionKey }) => {
        return this.memberDetailService.removeSection(id, sectionKey).pipe(
          map(() => {
            return MemberDetailActions.removeMemberSectionSuccess({
              sectionKey: sectionKey,
            });
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberDetailActions.removeMemberSectionFailure({
                errorMsg: err.message,
                sectionKey: sectionKey,
              })
            );
          })
        );
      })
    )
  );

  createMemberSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.addSectionTable),
      switchMap(({ memberId, metadataItem, body, sectionData }) => {
        return this.memberDetailService
          .addSection(memberId, metadataItem, body)
          .pipe(
            map(() => {
              return MemberDetailActions.addSectionTableSuccess({
                sectionData,
                memberId,
              });
            }),
            catchError((error) => {
              return of(MemberDetailActions.addSectionTableFailure({ error }));
            })
          );
      })
    )
  );

  getSectionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getSectionData),
      switchMap(({ memberId, sectionKey, query }) => {
        return this.memberDetailService
          .getSection(memberId, sectionKey, query)
          .pipe(
            map((metaDataPropertyValues) => {
              return MemberDetailActions.getSectionDataSuccess({
                memberId,
                sectionKey,
                metaDataPropertyValues,
              });
            }),
            catchError((error) => {
              return of(
                MemberDetailActions.getSectionDataFailure({ error, sectionKey })
              );
            })
          );
      })
    )
  );

  addSectionDataListView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.addSectionDataListView),
      switchMap(({ body, sectionData }) => {
        return this.memberDetailService.addSectionDataListView(body).pipe(
          map(() => {
            return MemberDetailActions.addSectionDataListViewSuccess({
              sectionData,
            });
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.addSectionDataListViewFailure({ sectionData })
            );
          })
        );
      })
    )
  );

  addSectionDataFixedStatusSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.addSectionDataFixedStatusSection),
      switchMap(({ body, sectionData }) => {
        return this.statusHistoryService.createMemberStatus(body).pipe(
          map(() => {
            return MemberDetailActions.addSectionDataListViewSuccess({
              sectionData,
            });
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.addSectionDataListViewFailure({ sectionData })
            );
          })
        );
      })
    )
  );

  reorderSectionTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.reorderSectionTable),
      switchMap(({ memberId, metadataItem, body }) => {
        return this.memberDetailService
          .reorderSection(memberId, metadataItem, body)
          .pipe(
            map(() => {
              return MemberDetailActions.reorderSectionTableSuccess();
            }),
            catchError((error) => {
              return of(
                MemberDetailActions.reorderSectionTableFailure({ error })
              );
            })
          );
      })
    )
  );

  editSectionDataListView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.editSectionDataListView),
      switchMap(({ body, sectionData }) => {
        return this.memberDetailService.editSectionDataListView(body).pipe(
          map(() => {
            return MemberDetailActions.editSectionDataListViewSuccess({
              sectionData,
            });
          }),
          catchError((error) => {
            return of(
              MemberDetailActions.editSectionDataListViewFailure({
                error,
                sectionData,
              })
            );
          })
        );
      })
    )
  );

  editSectionDataFixedStatusSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.editSectionDataFixedStatusSection),
      switchMap(({ body, sectionData }) => {
        return this.statusHistoryService.editMemberStatus(body).pipe(
          map(() => {
            return MemberDetailActions.editSectionDataListViewSuccess({
              sectionData,
            });
          }),
          catchError((error) => {
            return of(
              MemberDetailActions.editSectionDataListViewFailure({
                error,
                sectionData,
              })
            );
          })
        );
      })
    )
  );

  getSectionMetadata$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MemberDetailActions.getSectionOverview),
      switchMap(({ memberId, sectionKey, isMetadataSection }) => {
        return forkJoin([
          this.memberDetailService.getSection(
            memberId,
            sectionKey,
            !isMetadataSection
              ? ({
                  pageIndex: FIRST_PAGE,
                  totalPerPage: 10,
                } as MemberDetailWithPaging)
              : undefined
          ),
          this.metadataService.getMetadata(),
          this.metadataService.getMetadataItemByProfileOverviewConfig(memberId),
        ]).pipe(
          map((response) => {
            const sectionOverview = {
              metaDataPropertyValues: response[0],
              sectionData: isMetadataSection
                ? (response[1]?.metadata?.find(
                    (item) => item.key === sectionKey
                  ) as MetadataSection)
                : (response[2]?.metadataItems?.find(
                    (item) => item.key === sectionKey
                  ) as MetadataSection),
            };
            return MemberDetailActions.getSectionOverviewSuccess({
              sectionOverview,
            });
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.getSectionOverviewFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    );
  });

  editMemberSectionTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.editSectionTable),
      switchMap(({ memberId, metadataItem, body, sectionData }) => {
        return this.memberDetailService
          .editSectionTableView(memberId, metadataItem, body)
          .pipe(
            map(() => {
              return MemberDetailActions.editSectionTableSuccess({
                sectionData,
                memberId,
              });
            }),
            catchError((error) => {
              return of(MemberDetailActions.editSectionTableFailure({ error }));
            })
          );
      })
    )
  );

  removeMemberSectionTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.removeSectionTable),
      switchMap(({ sectionData, body }) => {
        return this.memberDetailService.removeSectionTable(body).pipe(
          map(() => {
            return MemberDetailActions.removeSectionTableSuccess({
              sectionData,
            });
          }),
          catchError((error) => {
            return of(
              MemberDetailActions.removeSectionTableFailure({
                error,
                sectionData,
              })
            );
          })
        );
      })
    )
  );

  uploadFileSectionTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.uploadFileSectionTable),
      switchMap(({ memberId, body }) => {
        return this.memberDetailService.uploadFileSection(memberId, body).pipe(
          map(() => {
            return MemberDetailActions.uploadFileSectionTableSuccess();
          }),
          catchError((error) => {
            return of(
              MemberDetailActions.uploadFileSectionTableFailure({ error })
            );
          })
        );
      })
    )
  );

  getMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMetadata),
      switchMap(({ query }) => {
        return this.metadataService.getMetadata(query).pipe(
          map((res) => {
            return MemberDetailActions.getMetaDataSuccess({
              metadata: res.metadata,
              listStatus: res.listStatus,
            });
          }),
          catchError(() => {
            return of(MemberDetailActions.getMetaDataFailure());
          })
        );
      })
    )
  );

  getMetadataNonList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMetadataNonListAction),
      switchMap(({ memberNavigationItemId }) => {
        return this.metadataService
          .getMetadataNonList(memberNavigationItemId)
          .pipe(
            map((response: MetadataSectionsNonListResponse) => {
              return MemberDetailActions.getMetadataNonListSuccessAction({
                response,
              });
            }),
            catchError((error) => {
              return of(
                MemberDetailActions.getMetadataNonListFailureAction({ error })
              );
            })
          );
      })
    )
  );

  getMemberById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberById),
      switchMap(({ id, screenId }) => {
        return this.memberDetailService.getMemberDetail(id, screenId).pipe(
          map((res: any) => {
            const memberInfo = {
              memberInfo: {
                ...res?.member,
              },
            };
            return MemberDetailActions.getMemberByIdSuccess(memberInfo);
          }),
          catchError(() => {
            return of(MemberDetailActions.getMemberByIdFailure());
          })
        );
      })
    )
  );

  editMemberEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.setMemberEvent),
      switchMap(({ body }) => {
        return this.memberDetailService.editMemberEvent(body).pipe(
          map(() => {
            return MemberDetailActions.setMemberEventSuccess();
          }),
          catchError((err) => {
            return of(MemberDetailActions.setMemberEventFailure());
          })
        );
      })
    )
  );

  getMemberDetailWithPaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberDetailWithPaging),
      switchMap(({ id, itemKey, query, menuId }) => {
        return this.memberDetailService
          .memberDetailWithPaging(
            id,
            itemKey,
            query as MemberDetailWithPaging,
            menuId
          )
          .pipe(
            map((memberDetailList: any) => {
              return MemberDetailActions.getMemberDetailWithPagingSuccess({
                memberDetailList,
              });
            }),
            catchError((error) => {
              return of(
                MemberDetailActions.getMemberDetailWithPagingFailure({ error })
              );
            })
          );
      })
    )
  );

  createMemberUploadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.createMemberUploadAttachments),
      switchMap(({ body }) => {
        return this.memberDetailService.uploadMetaDataAttachment(body).pipe(
          map(() => {
            return MemberDetailActions.createMemberUploadAttachmentsSuccess();
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.createMemberUploadAttachmentsFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  updateMemberUploadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.updateMemberUploadAttachments),
      switchMap(({ body }) => {
        return this.memberDetailService.updateMetaDataAttachment(body).pipe(
          map(() => {
            return MemberDetailActions.updateMemberUploadAttachmentsSuccess();
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.updateMemberUploadAttachmentsFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  removeMemberUploadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.removeMemberUploadAttachments),
      switchMap(({ body }) => {
        return this.memberDetailService.removeMetaDataAttachment(body).pipe(
          map(() => {
            return MemberDetailActions.removeMemberUploadAttachmentsSuccess({
              body,
            });
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.removeMemberUploadAttachmentsFailure({
                errorMsg: err.message,
                documentName: body.documentName,
              })
            );
          })
        );
      })
    )
  );

  getMetadataBasedOnProfileOverviewConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMetadataBasedOnProfileOverviewConfig),
      switchMap(({ memberId, screenId }) => {
        return this.metadataService
          .getMetadataItemByProfileOverviewConfig(memberId, screenId)
          .pipe(
            map((res) => {
              return MemberDetailActions.getMetadataBasedOnProfileOverviewConfigSuccess(
                {
                  metadataOverview: res.metadataItems,
                  listStatus: res.listStatus,
                  profileOverviewName: res.profileOverviewName,
                  profileOverviewConfig: res.metadataOverview,
                }
              );
            }),
            catchError(() => {
              return of(
                MemberDetailActions.getMetadataBasedOnProfileOverviewConfigFailure()
              );
            })
          );
      })
    )
  );

  getViewConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getViewConfigsAction),
      switchMap(({ viewId }) => {
        return this.metadataService
          .getViewConfig(viewId)
          .pipe(
            map((entityViewItems) => {
              return MemberDetailActions.getViewConfigsActionSuccess(entityViewItems);
            }),
            catchError(() => {
              return of(
                MemberDetailActions.getViewConfigsActionFailure()
              );
            })
          );
      })
    )
  );

  getMemberDataSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberDataSummaryAction),
      switchMap(({ memberId, entityId, listCard, isSummary, pageSize, screenId }) => {
        return this.memberDetailService
          .getMemberDataSummary(memberId, entityId, listCard, isSummary, pageSize, screenId)
          .pipe(
            map((memberDataSummary: any) => {
              return MemberDetailActions.getMemberDataSummarySuccess({ memberDataSummary });
            }),
            catchError((err) => {
              return of(
                MemberDetailActions.getMemberDataSummaryFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  getMemberDataCarđ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberDataCardAction),
      switchMap(({ memberId, cardId, entityId, isSummary, query, screenId }) => {
        return this.memberDetailService
          .getMemberDataCard(memberId, cardId, entityId, isSummary, query, screenId)
          .pipe(
            map((memberDataCard) => {
              return MemberDetailActions.getMemberDataCardSuccess({
                memberDataCard,
                cardId,
                currentPageIndex: query?.pageIndex
              });
            }),
            catchError((error) => {
              return of(
                MemberDetailActions.getMemberDataCardFailure({ cardId })
              );
            })
          );
      })
    )
  );

  getMemberDetailUnMaskedRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberDetailUnMaskedRequest),
      switchMap(({ memberId, listSection, isAllData, menuId }) => {
        return this.memberDetailService
          .memberDetail(memberId, listSection, false, isAllData, menuId)
          .pipe(
            map((memberDetailUnMasked: MemberDetail) => {
              return MemberDetailActions.getMemberDetailUnMaskedSuccess({
                memberDetailUnMasked,
              });
            }),
            catchError((err) => {
              console.log('---------Error-----------------');
              console.log(err);
              return of(MemberDetailActions.getMemberDetailUnMaskedFailure());
            })
          );
      })
    )
  );

  getMemberConfiguredIdName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberConfiguredIdNameRequest),
      switchMap(() => {
        return this.memberDetailService.getMemberConfiguredIdName().pipe(
          map((payrollConfigurationInfo) => {
            return MemberDetailActions.getMemberConfiguredIdNameSuccess({
              payrollConfigurationInfo,
            });
          }),
          catchError((err) => {
            return of(MemberDetailActions.getMemberConfiguredIdNameFailure());
          })
        );
      })
    )
  );

  getMemberAttachmentsListSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberAttachmentsListSection),
      switchMap(({ memberId, itemKey, query }) => {
        return this.memberDetailService
          .getMemberAttachmentsListSection(memberId, itemKey, query)
          .pipe(
            map((memberAttachmentsListSection: any) => {
              return MemberDetailActions.getMemberAttachmentsListSectionSuccess(
                { memberAttachmentsListSection }
              );
            }),
            catchError((err) => {
              return of(
                MemberDetailActions.getMemberAttachmentsListSectionFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  confirmRetireRehire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.confirmRetireRehireRequest),
      switchMap(({ participantId, isRetireRehire }) => {
        return this.memberDetailService
          .confirmRetireRehire(participantId, isRetireRehire)
          .pipe(
            map((res: ConfirmRetireRehireResponse) => {
              return MemberDetailActions.confirmRetireRehireSuccess({
                isRetireRehire,
                isShowRetireRehireConfirmPopup: res.isShowConfirmPopup,
              });
            }),
            catchError(() => {
              return of(MemberDetailActions.confirmRetireRehireFailure());
            })
          );
      })
    )
  );

  getCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getCardsByViewAction),
      switchMap(({ request }) =>
        this.memberDetailService.getCardsByView(request).pipe(
          map((response: GetCardsByViewResponse) =>
            MemberDetailActions.getCardsByViewSuccessAction({ response })
          ),
          catchError((error) =>
            of(MemberDetailActions.getCardsByViewFailureAction({ error }))
          )
        )
      )
    )
  );

  getListPropertyForCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getListPropertyForCardsAction),
      switchMap(({ request }) =>
        this.memberDetailService.getListPropertyForCards(request).pipe(
          map((response: GetListPropertyForCardsResponse) =>
            MemberDetailActions.getListPropertyForCardsSuccessAction({
              response,
            })
          ),
          catchError((error) =>
            of(
              MemberDetailActions.getListPropertyForCardsFailureAction({
                error,
              })
            )
          )
        )
      )
    )
  );

  getListDataSnapshot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getListDataSnapshotAction),
      switchMap(({ request }) =>
        this.memberDetailService.getListDataSnapshot(request).pipe(
          map((response: GetListPropertyForCardsResponse) =>
            MemberDetailActions.getListDataSnapshotSuccessAction({
              response,
            })
          ),
          catchError((error) =>
            of(
              MemberDetailActions.getListDataSnapshotFailureAction({
                error,
              })
            )
          )
        )
      )
    )
  );

  getMemberEntityAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberEntityAttachmentsAction),
      switchMap(({ request }) =>
        this.memberDetailService.getMemberEntityAttachments(request).pipe(
          map((response: GetMemberEntityAttachmentsResponse) =>
            MemberDetailActions.getMemberEntityAttachmentsSuccessAction({
              response,
            })
          ),
          catchError((error) =>
            of(
              MemberDetailActions.getMemberEntityAttachmentsFailureAction({
                error,
              })
            )
          )
        )
      )
    )
  );

  createUploadMemberEntityAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.createUploadMemberEntityAttachmentsAction),
      switchMap(({ body }) => {
        return this.memberDetailService
          .createUploadMemberEntityAttachments(body)
          .pipe(
            map(() => {
              return MemberDetailActions.createUploadMemberEntityAttachmentsSuccessAction();
            }),
            catchError((error) => {
              return of(
                MemberDetailActions.createUploadMemberEntityAttachmentsFailureAction(
                  {
                    error,
                  }
                )
              );
            })
          );
      })
    )
  );

  getStandAlonePropertyOnlyCardData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getStandAlonePropertyOnlyCardDataAction),
      switchMap(({ request }) =>
        this.memberDetailService
          .getStandAlonePropertyOnlyCardData(request)
          .pipe(
            map((response: GetStandAlonePropertyOnlyCardDataResponse) =>
              MemberDetailActions.getStandAlonePropertyOnlyCardDataSuccessAction(
                { response }
              )
            ),
            catchError((error) =>
              of(
                MemberDetailActions.getStandAlonePropertyOnlyCardDataFailureAction(
                  { error }
                )
              )
            )
          )
      )
    )
  );

  removeMemberEntityAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.removeMemberEntityAttachmentsAction),
      switchMap(({ memberId, attachmentId }) =>
        this.memberDetailService
          .removeMemberEntityAttachments(memberId, attachmentId)
          .pipe(
            map(() =>
              MemberDetailActions.removeMemberEntityAttachmentsSuccessAction()
            ),
            catchError((error) =>
              of(
                MemberDetailActions.removeMemberEntityAttachmentsFailureAction({
                  error,
                })
              )
            )
          )
      )
    )
  );
  
  checkExitAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.checkRemoveExitAttachmentAction),
      switchMap(({ body }) =>
        this.memberDetailService
          .checkRemoveAttachmentExists(body)
          .pipe(
            map((response) =>
              MemberDetailActions.checkRemoveExitAttachmentSuccess({response})
            ),
            catchError((error) =>
              of(
                MemberDetailActions.checkRemoveExitAttachmentFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );              
  getClientSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientSettingAction),
      switchMap(({ clientSettingKey }) =>
        this.memberDetailService.getClientSetting(clientSettingKey).pipe(
          map((response: ClientSetting) => getClientSettingSuccessAction({ response })),
          catchError((error) => of(getClientSettingFailureAction({ error })))
        )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private memberDetailService: MemberDetailService,
    private metadataService: MetadataService,
    private statusHistoryService: StatusHistoryService
  ) {}
}
