import { createSelector } from '@ngrx/store';
import { selectFileModuleState } from './selector';

export const documentsSelector = createSelector(
  selectFileModuleState,
  (state) => state?.documents
);

export const selectDowloadMemberDocumentState = createSelector(
  documentsSelector,
  (state) => state?.getDocumentDownloadState
);

export const selectPrepareDownloadMultipleDocumentState = createSelector(
  documentsSelector,
  (state) => state?.prepareDownloadMultipleDocuments
);

export const selectDownloadMultipleDocumentState = createSelector(
  documentsSelector,
  (state) => state?.downloadMultipleDocuments
);

export const selectcheckHaveZipFileState = createSelector(
  documentsSelector,
  (state) => state?.checkHaveZipFileState
);

export const getListTemplateSelector = createSelector(
  documentsSelector,
  (state) => state?.getListTemplateState
);

export const generateDocumentSelector = createSelector(
  documentsSelector,
  (state) => state?.generateDocument
);

export const generateMunicipalityDocumentSelector = createSelector(
  documentsSelector,
  (state) => state?.generateMunicipalityDocument
);

export const getRetirementBenefitDownloadDocumentSelector = createSelector(
  documentsSelector,
  (state) => state?.getRetirementBenefitDownloadDocument
);

export const validateGenerateAge65LetterSelector = createSelector(
  documentsSelector,
  (state) => state?.validateGenerateAge65Letter
);

export const getGeneratedReportsInformationSelector = createSelector(
  documentsSelector,
  (state) => state?.getGeneratedReportsInformation
);

export const getGeneratedReportDetailSelector = createSelector(
  documentsSelector,
  (state) => state?.getGeneratedReportDetail
);

export const getListTemplateTagSelector = createSelector(
  documentsSelector,
  (state) => state?.getListTemplateTagsState
);




