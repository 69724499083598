<ptg-dialog class="buyback-makeup-dialog-container flex flex-col">
  <ng-template dialogBody>
    <ptg-title-bar
      [isProfile]="false"
      [name]="''"
      viewName="Buyback/Make-up"
      [onlyTitle]="true"
    ></ptg-title-bar>

    <ptg-overview-header-entity
      [injectedMemberId]="memberId"
      [shouldDisableNavigationButtonsInAllCases]="true"
      [shouldDisableCloseButtonInAllCases]="true"
    ></ptg-overview-header-entity>

    <div class="flex flex-col">
      <div class="flex justify-start gap-6 p-6">
        <ptg-button
          [isDisabled]="isEdittingOrAdding"
          (click)="onNext()"
          classInput="mat-flat-button primary"
          buttonName="Next"></ptg-button>
        <ptg-button
          [isDisabled]="isEdittingOrAdding"
          (click)="onCancel()"
          classInput="mat-flat-button tertiary"
          buttonName="Cancel">
        </ptg-button>
      </div>

      <div class="flex flex-col">
        <div class="flex flex-col">
          <ptg-grid
            [columns]="tableColumns"
            [data]="tableData"
            [fitToParent]="true"
            [totalRecords]="totalRecords"
            notFoundMessage="No Data to Display"
            [isLoading]="isTableLoading"
            [paginable]="false">
            <ng-template cellContent columnName="type" let-row>
              <div class="type-selection">
                <!-- Before & After Edit section -->
                <div [ngClass]="{'type-display-section': true, 'hide-type-edit-section': row?.isShowTypeEditSection}">
                  <div class="type-display-name">{{ row?.displayType }}</div>
                  <div class="type-edit-btn">
                    <ptg-button
                      stopPropagation
                      buttonName=""
                      classInput="edit-button"
                      (clickButton)="showEditSection(row, 'Type')"
                    >
                    <mat-icon>edit</mat-icon>
                    </ptg-button>
                  </div>
                </div>

                <!-- Current Editting section -->
                <div [ngClass]="{'type-edit-section': true, 'hide-type-edit-section': !row?.isShowTypeEditSection}">
                  <div class="type-edit-dropdown">
                    <ptg-select
                      [controlField]="$any(recordArrayForm?.controls?.[row?.rowIndex])?.controls?.typeDropdown"
                      [listData]="listTypeOption"
                      [isOptionObj]="true"
                      class=""
                      (changeControlValue)="handleDropdownChange(row, 'Type')"
                    ></ptg-select>
                  </div>
                  <div class="type-commit-btn">
                    <ptg-button
                      stopPropagation
                      buttonName=""
                      classInput="done-button done-btn"
                      (clickButton)="commitEditResult(row, 'Type')"
                    >
                      <mat-icon>done</mat-icon>
                    </ptg-button>
                  </div>
                  <div class="type-discard-btn">
                    <ptg-button
                      stopPropagation
                      buttonName=""
                      classInput="clear-button clear-btn"
                      (clickButton)="discardEditResult(row, 'Type')"
                    >
                      <mat-icon>clear</mat-icon>
                    </ptg-button>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template cellContent columnName="municipalityName" let-row>
              <div class="muni-selection">
                <!-- Before & After Edit section -->
                <div [ngClass]="{'muni-display-section': true, 'hide-muni-edit-section': row?.isShowMuniEditSection}">
                  <div class="muni-display-name">{{ row?.displayMuniName }}</div>
                  <div class="muni-edit-btn">
                    <ptg-button
                      *ngIf="row?.allowToUseMuniEditBtn"
                      stopPropagation
                      buttonName=""
                      classInput="edit-button"
                      (clickButton)="showEditSection(row, 'Muni')"
                    >
                      <mat-icon>edit</mat-icon>
                    </ptg-button>
                  </div>
                </div>

                <!-- Current Editting section -->
                <div [ngClass]="{'muni-edit-section': true, 'hide-muni-edit-section': !row?.isShowMuniEditSection}">
                  <div class="muni-edit-dropdown">
                    <ptg-select
                      [controlField]="$any(recordArrayForm?.controls?.[row.rowIndex])?.controls?.muniDropdown"
                      [listData]="row?.listMuniNameOpt"
                      [isOptionObj]="true"
                      class=""
                      (changeControlValue)="handleDropdownChange(row, 'Muni')"
                    ></ptg-select>
                  </div>
                  <div class="muni-commit-btn">
                    <ptg-button
                      stopPropagation
                      buttonName=""
                      classInput="done-button done-btn"
                      (clickButton)="commitEditResult(row, 'Muni')"
                    >
                      <mat-icon>done</mat-icon>
                    </ptg-button>
                  </div>
                  <div class="muni-discard-btn">
                    <ptg-button
                      stopPropagation
                      buttonName=""
                      classInput="clear-button clear-btn"
                      (clickButton)="discardEditResult(row, 'Muni')"
                    >
                      <mat-icon>clear</mat-icon>
                    </ptg-button>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template cellContent columnName="checkbox" let-row>
              <ptg-checkbox
                label=""
                [controlField]="$any(recordArrayForm?.controls?.[row?.rowIndex])?.controls?.checkbox"
                (changeValue)="onCheckBoxChange($event, row)"
              ></ptg-checkbox>
            </ng-template>
          </ptg-grid>
        </div>
      </div>
    </div>
  </ng-template>
</ptg-dialog>
