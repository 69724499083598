import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  EditMetRequirementBody,
  EditMetRequirementResponse,
  GetListStatusHistoryQuery,
  MunicipalityList,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityPaymentService {
  constructor(private httpClient: HttpClient) {}

  getMunicipalityPaymentList(
    query: GetListStatusHistoryQuery
  ): Observable<MunicipalityList> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<MunicipalityList>(
      `${environment.apiUrl}/member-portal/Payments`,
      { params }
    );
  }

  generateServiceRecordReport(memberId: string, date: string) {
    return this.httpClient.get(`${environment.apiUrl}/member-portal/${ memberId }/report/service-record/${date}`, { responseType: 'blob' });
  }

  editMunicipalityPaymentMetRequirement(body: EditMetRequirementBody): Observable<EditMetRequirementResponse> {
    return this.httpClient.put<EditMetRequirementResponse>(`${environment.apiUrl}/member-portal/payments/met-requirement`, body);
  }
}
