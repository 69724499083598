import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { takeUntil } from 'rxjs/operators';

import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import * as fromReducer from '@ptg-reducers';

import * as fromMember from '../../store/reducers';
import * as StatusHistoryActions from '../../store/actions/status-history.action';
import { GetListStatusHistoryQuery, History, MemberNavigationResponse } from '../../types/models';
import { EditMemberStatusHistoryComponent } from '../../components/edit-member-status-history/edit-member-status-history.component';
import { OverviewHeaderComponent } from '../../components/overview-header/overview-header.component';
import { StatusHistoryAuditComponent } from '../../components/status-history-audit/status-history-audit.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';

@Component({
  selector: 'ptg-status-history',
  templateUrl: './status-history.component.html',
  styleUrls: ['./status-history.component.scss'],
})
export class StatusHistoryComponent extends BaseComponent {
  bannerType: BannerType = BannerType.Hidden;  
  listBreadcrumbs: Breadcrumb[] = [{ name: '' }];
  message: string = '';
  isLoading = false;
  currentFund: any = {};
  columns: Column[] = [
    {
      name: 'statusDate',
      header: {
        title: 'Event Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'statusName',
      header: {
        title: 'Status',
      },
      sortable: true,
    },
    {
      name: 'statusEvent',
      header: {
        title: 'Event',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'lastModifiedAt',
      header: {
        title: 'Last Modified At',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy h:mm a' },
    },
    {
      name: 'lastModifiedBy',
      header: {
        title: 'Modified By',
      },
      sortable: true,
    },
  ];
  statusHistorysData: History[] = [];
  memberId: string = '';
  viewId: string = '';
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  memberStatusHistoryId?: string;
  isEntityView: boolean = false;
  viewName!: string;
  viewUrl!: string;

  @ViewChild(OverviewHeaderEntityComponent) overViewHeaderEntity!: OverviewHeaderEntityComponent;

  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        // TODO: need remove logic after complete implement entity
        this.isEntityView = this.route.snapshot.url.some(path => path.path === 'system-view');
        this.memberStatusHistoryId = params.memberStatusHistoryId;
        if (params?.currentRowIndex) {
          this.currentRowIndex = +params.currentRowIndex;
        }
        if (params?.prevName) {
          this.viewName = params.prevName;
          this.viewUrl = params.prevUrl;
        }
      });
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });
    this.pageSize =
      Number(
        sessionStorage.getItem(
          this.currentFund.key + '-ptg-status-history-pageSize'
        )
      ) === 0
        ? this.pageSize
        : Number(
            sessionStorage.getItem(
              this.currentFund.key + '-ptg-status-history-pageSize'
            )
          );

    this.route.params.subscribe((params) => {
      this.memberId = params.id;
      this.viewId = params.viewId;
      this.getData();
      this.getViewName();
    });

    this.memberStore
      .pipe(
        select(fromMember.selectStatusHistoryState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.lengthPg = state?.statusHistoryList?.total;
        this.isLoading = state?.isLoading;
        this.statusHistorysData =
          state?.statusHistoryList?.memberStatusHistoryDto?.map(item => {
            return {
              ...item,
              lastModifiedAt: getDateString(item.lastModifiedAt as string)
            }
          });
        showBanner.call(
          this,
          state.createState || '',
          'Member Status',
          ACTION.ADD
        );
        showBanner.call(
          this,
          state.updateState || '',
          'Member Status',
          ACTION.EDIT
        );
        if (
          state.createState == STATE.SUCCESS ||
          state.updateState == STATE.SUCCESS
        ) {
          this.reloadOverviewHeader();
          this.getData();
        }
        if (state.createState || state.updateState) {
          this.memberStore.dispatch(
            StatusHistoryActions.clearStatusHistoryState()
          );
        }
      });
  }

  getData() {
    let sortType;
    let sortNames: string[] = [];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListStatusHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      ...(sortType !== undefined ? { sortType: sortType } : {}),
      ...(this.memberStatusHistoryId ? { memberStatusHistoryId: this.memberStatusHistoryId } : {})
    };
    this.memberStore.dispatch(
      StatusHistoryActions.getStatusHistoryList({ query })
    );
  }

  getViewName() {    
    this.store.select(fromLayoutReducer.selectMemberNavigationListState).pipe(takeUntil(this.unsubscribe$))
      .subscribe(menu => {
        if (menu.memberNavigationMenu) {
          const memberNavigationMenu: MemberNavigationResponse[] =
          menu?.memberNavigationMenu;
          const menuItems = memberNavigationMenu
            ?.map((item) => {
              return item.menuItems;
            })
            ?.reduce((a, b) => {
              return a.concat(b);
            });

          const menuName = menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name;
          if (this.viewName) {
            this.listBreadcrumbs = [{
              url: this.viewUrl,
              name: this.viewName,
            }, {
              name: menuName || 'Status History',
            }];
          } else
          this.listBreadcrumbs[0] = {
            name: menuName || 'Status History',
          };
        }
      });
  }

  reloadOverviewHeader() {
    this.overViewHeaderEntity.memberId = this.memberId;
    this.overViewHeaderEntity.getMemberProfileData();
    this.overViewHeaderEntity.checkMemberIndex();
  }

  onEditMemberStatus(statusHistory?: History) {
    this.dialog.open(EditMemberStatusHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: 'Member Status',
        statusHistory,
      },
    });
  }
  onClickSecondButton(id?: string) {
    this.dialog.open(StatusHistoryAuditComponent, {
      panelClass: 'dialog-full-screen',
      autoFocus: false,
      disableClose: true,
      data: {
        statusHistoryMenuName: this.listBreadcrumbs[0].name,
        memberId: this.memberId,
        statusHistoryId: id,
      },
    });
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-status-history-pageNumber',
      this.pageNumber.toString()
    );
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-status-history-pageSize',
      this.pageSize.toString()
    );
    this.getData();
  }

  selectRow(event: any) {
    this.currentRowIndex = this.statusHistorysData.findIndex(
      (item) => item.id === event.id
    );
  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }
}
