<div class="dialog-container" id="generate-doc-participant">
  <div class="header-title">{{ title }}</div>
  <form [formGroup]="generateDocForm" (ngSubmit)="formSubmit$.next()">
    <div class="form-container">
      <div class="input-container">
        <ptg-select
          [placeholder]="'Template'"
          [controlField]="generateDocForm.get('template')"
          [listData]="listTemplate"
          [isOptionObj]="true"
          [isRequired]="true"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [onlyTruncateTitle]="true"
          class="select-template"
          customError="errMsgTemplate"
          (changeOptionValue)="onSelectTemplate($event)"
        ></ptg-select>
        <ptg-select
          [placeholder]="'File Extension'"
          [controlField]="generateDocForm.get('fileType')"
          [listData]="listType"
          [isOptionObj]="true"
          [isRequired]="true"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [onlyTruncateTitle]="true"
          class="select-type"
        ></ptg-select>
      </div>
      <div class="pb-3">
        <ptg-toggle-button
          [controlField]="generateDocForm.get('isShowPreview')"
          label="Show Preview"
          (valueChange)="changeToggleAdd($event)"
        ></ptg-toggle-button>
      </div>
      <div class="flex-1 autocomplete-chip-section">
        <div class="auto-complete-section">
          <div class="select-container">
            <div class="select-tag">
              <mat-form-field class="auto-complete" appearance="fill" [ngClass]="{'disabled-tag': currentTagsChipValueList.length == listOptionTag.length}">
                <mat-label>Tags</mat-label>
                <input class="custom-input" type="text" matInput [formControl]="tagsAutoCompleteControl" [matAutocomplete]="auto" (blur)="validateTags()" (focus)="tagsFocus($event)">
                <span class="custom-arrow mat-select-arrow-wrapper"><span class="mat-select-arrow"></span></span>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTagFn">
                  <mat-option *ngFor="let option of filteredByTypingTagOptions | async" [value]="option">
                    {{ option.displayValue }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="chip-container">
              <span class="add-chip-btn-section">
                <button
                  mat-icon-button
                  (click)="onAddNewChip()"
                  [disabled]="!tagsAutoCompleteControl.value || tagsAutoCompleteControl?.errors?.inValidAsync || currentTagsChipValueList.length == listOptionTag.length"
                >
                  <mat-icon>add_circle</mat-icon>
                </button>
              </span>
              <div class="tags-chip-list-section">
                <mat-chip-list *ngIf="tagsChipListControl?.length">
                  <mat-chip *ngFor="let item of tagsChipListControl.controls; index as i" (removed)="onRemoveChip(item.value, i)">
                    <span class="chip-wrapper">
                      <span class="chip__txt--primary">
                        <span class="mr-1">{{ displayValue(item.value.displayValue) }}</span>
                      </span>
                    </span>
                    <button matChipRemove>
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
</div>
