<div class="benefit-overview-container" id="benefit-overview-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" [settings]="settings"></ptg-breadcrumb>
  <ptg-overview-header-entity #overViewHeader></ptg-overview-header-entity>

  <div class="benefit-overview-wrapper">
    <ptg-sub-header
      [memberId]="memberId"
      [menuItemTitle]="menuItemTitle"
      [menuItemSubTitle]="menuItemSubTitle"
    ></ptg-sub-header>
    <ptg-banner
      *ngIf="bannerTypeSurvivor"
      [(bannerType)]="bannerTypeSurvivor"
      [message]="messageSurvivor"
      [isHideCloseButton]="true"
    ></ptg-banner>
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div class="wrap-btn">
      <ptg-button
        *ngFor="let btn of listInitiatedButton"
        [buttonName]="btn.calculationButtonName"
        classInput="primary"
        (clickButton)="onClickInitiateBenefit(btn)"
        [isDisabled]="calculationType !== CalculationType.QDRO && btn.isDisabled"
      ></ptg-button>
    </div>

    <div class="benefit-overview-grid flex-grow overflow-hidden">
      <div class="header">
        <span class="title font-bold">{{ benefitHistoryLabel }}</span>
      </div>
      <ptg-grid
        [data]="benefitCalculationHistories"
        [columns]="
          this.calculationType === disabilityCalculationType ? columnsBenefitDisabilityHistory : columnsBenefitHistory
        "
        [totalRecords]="totalBenefitHistories"
        [isLoading]="isHistoryLoading"
        [pageSize]="pageHistorySize"
        [pageNumber]="pageHistoryIndex"
        [notFoundMessage]="noDataHistories"
        (sortChange)="onChangeSortHistories($event)"
        (pageChange)="onChangeHistoriesPage($event)"
        (rowClick)="openBenefitDetail($event)"
      >
        <ng-template cellContent columnName="benefitBeginDate" let-row>
          {{ row?.calcAsOfDate || row?.benefitBeginDate | date: 'MM/dd/yyyy' : 'UTC' }}
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            stopPropagation
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="
              this.calculationType === disabilityCalculationType ? onRemoveDisabilityHistory(row) : onRemoveHistory(row)
            "
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
        <ng-template cellContent columnName="calculationType" let-row>
          {{ row.calculationType === CalculationType.DisabilityShortTerm ? 'Short-term' : 'Long-term' }}
        </ng-template>
      </ptg-grid>
    </div>

    <div
      class="benefit-overview-grid flex-grow overflow-hidden"
      *ngIf="isShowDocumentSection && calculationType !== CalculationType.QDRO"
    >
      <div class="header document-header flex justify-between">
        <span class="title font-bold">{{ benefitDocumentLabel }}</span>
        <ptg-button
          buttonName="Upload Document"
          classInput="upload-button"
          class="upload-file"
          (clickButton)="onUploadDocuments()"
        >
          <mat-icon aria-hidden="false" aria-label="upload-icon">upload_file</mat-icon>
        </ptg-button>
      </div>
      <ptg-grid
        [data]="retirementBenefitDocuments"
        [columns]="columnsBenefitDocuments"
        [totalRecords]="totalBenefitDocuments"
        [isLoading]="isDocumentLoading"
        [pageSize]="pageDocumentSize"
        [pageNumber]="pageDocumentIndex"
        notFoundMessage="No Retirement Benefit Documents to Display"
        (sortChange)="onChangeSortDocuments($event)"
        (pageChange)="onChangeDocumentsPage($event)"
      >
        <ng-template cellContent columnName="fileName" let-row>
          <div class="flex file-document-container">
            <span (click)="onDownloadFile(row)" class="file-document-name" target="_blank"
              ><span class="truncate">{{ row?.fileName }}</span></span
            >
          </div>
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onEditDocument(row)">
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            stopPropagation
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onRemoveDocument(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
