import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { AccidentReport, AccidentReportRequest } from '../../services/models';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { AccidentParticipantsState } from '../../store/reducers/accident-participants.reducer';
import { Store } from '@ngrx/store';
import { addAccidentReportAction, editAccidentReportAction } from '../../store/actions';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import { getTimePickerString } from '@ptg-shared/utils/string.util';
import { deepClone } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-add-participant-accident-report',
  templateUrl: './add-participant-accident-report.component.html',
  styleUrls: ['./add-participant-accident-report.component.scss'],
})
export class AddParticipantAccidentReportComponent extends BaseComponent {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  isEdit = false;
  listMunicipality: any[] = [];
  accidentDateMax = new Date();
  receiveDateMax = new Date();
  municipalityDefault?: string;
  listLocation = [
    { displayValue: 'Fire station', value: 'Fire station' },
    { displayValue: 'Drill', value: 'Drill' },
    { displayValue: 'Responding to/from fire', value: 'Responding to/from fire' },
    { displayValue: 'Responding to/from aid call', value: 'Responding to/from aid call' },
    { displayValue: 'At fire scene', value: 'At fire scene' },
    { displayValue: 'At aid call scene', value: 'At aid call scene' },
    { displayValue: 'At training academy', value: 'At training academy' },
    { displayValue: 'At a drill fire', value: 'At a drill fire' },
    { displayValue: 'Water rescue training', value: 'Water rescue training' },
    { displayValue: 'Physical training', value: 'Physical training' },
    { displayValue: 'Other', value: 'Other' }
  ];
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddParticipantAccidentReportComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accidentReport: AccidentReport | null,
      memberId: string,
      accidentId: string
    },
    private fb: FormBuilder,
    private store: Store<AccidentParticipantsState>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.accidentDateMax = new Date();
    this.receiveDateMax = new Date();

    this.initFormGroup();
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  initFormGroup() {
    const accidentReport = this.data?.accidentReport;
    if (accidentReport) {
      this.isEdit = true;
    }
    this.editForm = this.fb.group({
      id: accidentReport?.accidentReportId ?? '',
      dayOffFromWork: this.fb.control(accidentReport?.dayOffFromWork ?? '0'),
      isDrSeen: this.fb.control(accidentReport?.isDrSeen) ?? false,
      isHospitalized: this.fb.control(accidentReport?.isHospitalized) ?? false,
      isFatal: this.fb.control(accidentReport?.isFatal) ?? false,
      drName: this.fb.control(accidentReport?.drName ?? ''),
      sheriffName: this.fb.control(accidentReport?.sheriffName ?? ''),
      accidentTime: this.fb.control(getTimePickerString(accidentReport?.timeInHour, accidentReport?.timeInMinute) ?? ''),
      natureOfInjury: this.fb.control(accidentReport?.natureOfInjury ?? '', {
        validators: [Validators.required],
      }),
      locationOfAccident: this.fb.control(accidentReport?.locationOfAccident ?? ''),
      otherLocation: this.fb.control(accidentReport?.otherLocation ?? '')
    });
  }

  get isHospitalizedControl(): FormControl {
    return this.editForm.get('isHospitalized') as FormControl;
  }
  get dayOffFromWorkControl(): FormControl {
    return this.editForm.get('dayOffFromWork') as FormControl;
  }
  get accidentTimeControl(): FormControl {
    return this.editForm.get('accidentTime') as FormControl;
  }
  get locationOfAccidentControl(): FormControl {
    return this.editForm.get('locationOfAccident') as FormControl;
  }
  get otherLocationControl(): FormControl {
    return this.editForm.get('otherLocation') as FormControl;
  }

  validateHospitalizedDayOffFromWork() {
    const messageError = 'Days off from work cannot be 0 or blank if participant has been hospitalized.';
    if (this.isHospitalizedControl.value && (this.dayOffFromWorkControl?.value === '0' || !this.dayOffFromWorkControl?.value)) {
      this.dayOffFromWorkControl?.setErrors({ 'incorrect': true, 'errorMessage': messageError });
      this.dayOffFromWorkControl?.markAsTouched();
    } else {
      this.dayOffFromWorkControl?.setErrors({ 'incorrect': false, 'errorMessage': '' });
      this.dayOffFromWorkControl?.updateValueAndValidity();
    }
  }

  onChangeValueLocationOfAccident() {
    if (this.locationOfAccidentControl.value === 'Other') {
      this.otherLocationControl.reset();
    }
  }

  onSubmit() {
    const accidentReportRequest = deepClone(this.editForm.value as AccidentReportRequest);

    const { hour, minute } = DateTime.fromFormat(this.accidentTimeControl?.value, 'h:mm a').toObject();
    accidentReportRequest.accidentTimeHour = hour;
    accidentReportRequest.accidentTimeMinute = minute;
    accidentReportRequest.dayOffFromWork = Number(accidentReportRequest.dayOffFromWork);
    accidentReportRequest.isDrSeen = accidentReportRequest.isDrSeen ?? false;
    accidentReportRequest.isHospitalized = accidentReportRequest.isHospitalized ?? false;
    accidentReportRequest.isFatal = accidentReportRequest.isFatal ?? false;

    if (accidentReportRequest.locationOfAccident !== 'Other') {
      accidentReportRequest.otherLocation = null;
    }
    
    if (accidentReportRequest.id) {
      this.store.dispatch(
        editAccidentReportAction({
          memberId: this.data.memberId,
          accidentId: this.data.accidentId,
          accidentReportRequest: accidentReportRequest,
        })
      );
    } else {
      this.store.dispatch(
        addAccidentReportAction({
          memberId: this.data.memberId,
          accidentId: this.data.accidentId,
          accidentReportRequest: accidentReportRequest,
        })
      );
    }
    this.dialogRef.close();
  }
}
