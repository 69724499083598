export interface EditMunicipalityServiceHistoryRequest {
  id?: string;
  memberId: string;
  beginDate: string;
  endDate?: string;
  municipalityId: string;
}

export interface MunicipalityNameList {
  employers: Municipality[];
}

export interface Municipality {
  value: string;
  description: string;
  disabled: boolean;
}

export interface ValidateTerminatedMemberBody {
	serviceHistoryEndDate: string;
	municipalityId: string;
	memberId: string;
}

export interface ValidateTerminatedMemberResponse {
	isShowPopup: boolean;
	annualCertId: string;
	year: number;
  name: string;
  nextCashJournalEntry: number;
  credit: number;
  municipalityName: string;
}

export interface CreateTransactionBody {
	municipalityId: string;
	year: number;
	memberId: string;
	annualCertId: string;
	cashJournalEntry: string;
}

export interface EditMetRequirementBody {
  municipalityId: string;
  year: number;
  memberId: string;
  metRequirement: boolean;
  validateToShowPopup: boolean;
  cashJournalEntry?: any;
  isCreateTransaction: boolean;
}

export interface EditMetRequirementResponse {
  isShowConfirmPopup: boolean;
  nextCashJournalEntry: number;
  credit: number;
  name: string;
}

export interface ValidateServiceHistoryRecordBeginDateRequest {
  oldBeginDate: string,
  newBeginDate: string,
  memberId: string,
  municipalityId: string,
}

export interface ValidateServiceHistoryRecordBeginDateResponse {
  gapYearWithPayments?: string,
  gapYears?: string,
  isPortalValidServiceBeginDate?: boolean,
  memberId?: string,
  nextCashJournalEntry?: number,
  totalCreditAmount?: number,
}

export interface CreateValidateServiceHistoryRecordBeginDateTransactionRequest {
  createDate: string,
  gapYears: string,
  totalCreditAmount: number,
  municipalityId: string,
  memberId: string,
  cashJournalEntry: string,
}

export interface CreateValidateServiceHistoryRecordBeginDateTransactionResponse {
  
}
