import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployerCheckDisableRequest } from '@ptg-employer/models/employer.model';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { UpsertEntityDataRequest, CreateEntityListDataRequest, CheckDenpendentRecordInUseResponse } from './models';
import { SearchResult } from '@ptg-shared/layout/models/search.models';
import { transformEntityListData, transformEntityToObject } from '@ptg-entity-management/helpers';
import { ENTITY_ID_GUID } from '@ptg-shared/constance';

@Injectable()
export class EntityDataService {
  constructor(private httpClient: HttpClient) {}

  createEntityData(recordId: string, request: UpsertEntityDataRequest): Observable<any> {
    const transformedData = transformEntityToObject(request);
    const body = {
      data: {
        ...transformedData.entityData,
        [ENTITY_ID_GUID]: recordId
      }
    }

    return this.httpClient.post<any>(
      `${environment.apiUrl}/entity-new/entities/${request.entityId}/entity-data`,
      body
    );
  }

  searchEntityDatas(
    searchValue: string,
    entityId: string
  ) {
    const payload = {
      searchValue,
      timeZoneOffset: new Date().getTimezoneOffset(),
      entityId,
      IsSearchAllEntity: true
    };
    return this.httpClient.post<SearchResult | any>(`${environment.apiUrl}/entity-portal/search`, payload);
  }

  getDetailsEntityDatas(
    entityId: string,
    id: string
  ) {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/entity-new/entities/${entityId}/entity-data/${id}`,
    );
  }

  createEntityListData(request: CreateEntityListDataRequest): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/entity-new/components/${request.entityComponentId}/entity-data/${request.targetId}/component-data`,
      transformEntityListData(request)
    );
  }

  checkDenpendentRecordInUse(targetId: string, recordId: string): Observable<CheckDenpendentRecordInUseResponse> {
    return this.httpClient.get<CheckDenpendentRecordInUseResponse>(
      `${environment.apiUrl}/entity-new/components/dependent/${targetId}/${recordId}/in-use`
    );
  }

  confirmRetireRehire(
    memberId: string,
    isRetireRehire: boolean
  ): Observable<{ isShowConfirmPopup: boolean }> {
    return this.httpClient.get<{ isShowConfirmPopup: boolean }>(
      `${environment.apiUrl}/municipality-portal/members/${memberId}/retire-rehire/${isRetireRehire}/confirmation`
    );
  }

  getMunicipalityCheckDisable(body: EmployerCheckDisableRequest) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    return this.httpClient.get<EmployerCheckDisableRequest>(`${ environment.apiUrl }/municipalities/${body.employerId}/check-disabled/${body.inactivationDate}`, { context });
  }

  getEntityMetadataAction() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/entity-new/entities-metadata`,
    );
  }
}
