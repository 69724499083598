import { createAction, props } from '@ngrx/store';
import { EntityPropertyDisplayConfig, EntityPropertyDisplayConfiguration, EntityListViewConfigurations } from '../../services/models';

export const ClearParticipantListConfiguration = '[ParticipantListConfiguration] Clear';
export const GetEntityPropertyListRequest = '[GetEntityPropertyList/API] Send Request';
export const GetEntityPropertyListSuccess = '[GetEntityPropertyList/API] Success';
export const GetEntityPropertyListFailure = '[GetEntityPropertyList/API] Failure';
export const GetParticipantListConfigurationRequest =
  '[GetParticipantListConfiguration/API] Send Request';
export const GetParticipantListConfigurationSuccess =
  '[GetParticipantListConfiguration/API] Success';
export const GetParticipantListConfigurationFailure =
  '[GetParticipantListConfiguration/API] Failure';
export const SetParticipantListConfigurationRequest =
  '[SetParticipantListConfiguration/API] Send Request';
export const SetParticipantListConfigurationSuccess =
  '[SetParticipantListConfiguration/API] Success';
export const SetParticipantListConfigurationFailure =
  '[SetParticipantListConfiguration/API] Failure';

export const clearParticipantListConfigurationState = createAction(
  ClearParticipantListConfiguration
);
export const getEntityPropertyList = createAction(
  GetEntityPropertyListRequest,
  props<{ entityId: string, screenId?: string }>()
);
export const getEntityPropertyListSuccess = createAction(
  GetEntityPropertyListSuccess,
  props<{ propertyConfigs: EntityPropertyDisplayConfig[], fixPersonProperties: EntityPropertyDisplayConfig[] }>()
);
export const getEntityPropertyListFailure = createAction(
  GetEntityPropertyListFailure,
  props<{ errorMsg: string }>()
);
export const getParticipantListConfiguration = createAction(
  GetParticipantListConfigurationRequest,
  props<{ entityId: string}>()
);
export const getParticipantListConfigurationSuccess = createAction(
  GetParticipantListConfigurationSuccess,
  props<{ propertyDisplayConfigurations: EntityPropertyDisplayConfiguration[] }>()
);
export const getParticipantListConfigurationFailure = createAction(
  GetParticipantListConfigurationFailure,
  props<{ errorMsg: string }>()
);
export const setParticipantListConfiguration = createAction(
  SetParticipantListConfigurationRequest,
  props<{ entityId: string, body: EntityListViewConfigurations }>()
);
export const setParticipantListConfigurationSuccess = createAction(
  SetParticipantListConfigurationSuccess
);
export const setParticipantListConfigurationFailure = createAction(
  SetParticipantListConfigurationFailure,
  props<{ errorMsg: string }>()
);
