import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PageEvent } from '@ptg-shared/controls/pagination';
import { Column, Row } from '@ptg-shared/controls/grid';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MemberManageAttachmentListComponent } from '../member-manage-attachment-list/member-manage-attachment-list.component';
@Component({
  selector: 'ptg-member-navigation-list-view',
  templateUrl: './member-navigation-list-view.component.html',
  styleUrls: ['./member-navigation-list-view.component.scss'],
})
export class MemberNavigationListViewComponent {
  @Input() dataTable: any = [];
  @Input() columns!: Column[];
  @Input() sortInfo: any = {};
  @Input() lengthPg!: number | any;
  @Input() pageSize: number = 7;
  @Input() pageNumber: number = 1;
  @Input() isLoading!: boolean;
  @Input() currentRowIndex: number = 0;
  @Input() menuItemName!: string;
  @Input() menuName?: string;
  @Input() memberId?: string;
  @Input() errorMsg!: string;
  @Input() isListRecord: boolean | undefined;
  @Input() isDragDrop: boolean = false;
  @Input() isBenefitCard: boolean = false;
  @Input() isCourtOrder: boolean = false;
  @Input() isMuniPortalBenefic?: boolean;
  @Output() selectRowEvent = new EventEmitter();
  @Output() sortChangeEvent = new EventEmitter();
  @Output() changePaggingEvent = new EventEmitter<PageEvent>();
  @Output() addNewMetaDataEvent = new EventEmitter();
  @Output() manageSectionEvent = new EventEmitter();
  @Output() currentRowIndexChange = new EventEmitter<number>();

  constructor(
    private dialog: MatDialog,
    private router: Router,
  ) {}

  selectRow(event: any) {
    this.selectRowEvent.emit(event);
    this.currentRowIndexChange.emit(this.currentRowIndex);
  }

  sortChange(event: any) {
    this.sortChangeEvent.emit(event);
  }

  changePagging(event: PageEvent) {
    this.changePaggingEvent.emit(event);
  }
  addNew(event: any) {
    if (this.isLoading) {
      return;
    }
    this.addNewMetaDataEvent.emit(event);
  }
  manageSection(event: any) {
    if (this.isLoading) {
      return;
    }
    this.manageSectionEvent.emit(event);
  }

  viewAttachment(row: Row) {
    const listBreadcrumbs = [{
      name: this.menuName || '',
      url: this.router.url
    },
    {
      name:'Attachments'
    }];
    this.dialog.open(MemberManageAttachmentListComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        breadcrumbs: listBreadcrumbs,
        recordId: row?.id,
        menuName: `${this.menuName}`,
        entityComponentId: row?.['values']?.[0]?.entityComponentId
      },
    });
  }
}
