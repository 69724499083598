import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { getDateString } from '@ptg-shared/utils/string.util';
import * as fromReducer from '@ptg-reducers';

import { BaseComponent } from '@ptg-shared/components';

import {
  ActionItem,
  MemberListQuery,
  MemberNavigationState,
  OverviewData,
} from '../../types/models';
import * as MemberNavigationActions from '../../store/actions/member-navigation';
import * as ProfileHeaderConfigurationActions from '../../store/actions/profile-header-configuration.actions';
import * as fromMember from '../../store/reducers';
import { MemberDetailService } from '../../services/member-detail.service';
import { HeaderPropertyConfig } from '@ptg-member/types/models/header-configuration.model';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { HeaderItemConfig } from '@ptg-member/types/models/header-item-config.model';
import { MatDialog } from '@angular/material/dialog';
import { EditMemberStatusEventComponent } from '../edit-member-status-event/edit-member-status-event.component';
import { EntityManagementState } from '@ptg-entity-management/store/reducers';
import { DatePipe, Location } from '@angular/common';
import { clearSetMemberEventStateAction } from '@ptg-member/store/actions';
import { setMemberEventSelector } from '@ptg-member/store/selectors/member.selector';
import { Config } from '@ptg-shared/controls/card-description/types/models';

@Component({
  selector: 'ptg-overview-header-entity',
  templateUrl: './overview-header-entity.component.html',
  styleUrls: ['./overview-header-entity.component.scss'],
})
export class OverviewHeaderEntityComponent extends BaseComponent {
  @Output() editSectionEvent = new EventEmitter();
  @Output() removeMemberEvent = new EventEmitter();
  @Output() addNoteEvent = new EventEmitter();
  @Output() uploadDocumentEvent = new EventEmitter();
  @Output() lockAccountEvent = new EventEmitter();
  @Output() changeMemberDetailEvent = new EventEmitter();
  @Output() clickActionItem = new EventEmitter<string>();
  @Output() deactivateMemberEvent = new EventEmitter();

  @Input() data: any = {};
  @Input() detail: any = {};
  @Input() overviewData!: OverviewData[];
  @Input() actionItems: ActionItem[] = [];
  @Input() isLocked: boolean = false;
  @Input() identityKey: string = '';
  @Input() memberNavigationState!: MemberNavigationState;
  @Input() canRemove: boolean = false;
  @Input() isSubHeader: boolean = false;
  @Input() targetIdFromDialog?: string;
  @Input() isShowChangeMemberDetailBtn?: boolean = true;
  @Input() injectedMemberId: string | null = null;
  @Input() shouldDisableNavigationButtonsInAllCases: boolean = false;
  @Input() shouldDisableCloseButtonInAllCases: boolean = false;
  @Input() isDeactivate: boolean = false;
  @Input() shouldHideStatusDateProperty: boolean = false;
  @Input() shouldHideStatusEventProperty: boolean = false;
  @Input() shouldHideCloseButton: boolean = false;

  readonly EntityPropertyType = EntityPropertyType;
  unsubscribe$ = new Subject<void>();
  profileHeaders: HeaderPropertyConfig[] | undefined = undefined;
  navigationDisabled: {
    previous: boolean;
    next: boolean;
  } = {
      previous: true,
      next: true,
    };
  fundId!: string;
  memberId: string = '';
  selectUrlBeforeSearch: string = '';
  isHistoryTab: boolean = false;
  accidentId?: string;
  isOverview = false;
  cardId: string = '';
  entityId: string = '';
  entityId$ = new Subject();
  isLoading: boolean = true;
  isLoadingHeaderItems = true;
  memberDetail: any;
  status: any;
  headerItems: HeaderItemConfig[] | undefined = undefined;
  profileName: string = '';
  entityReferenceLinkedId: string = '';

  menuId: string = '';
  urlMemberDetail: string = '';
  entityViewId: string = '';

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    private store: Store<fromLayoutReducer.State>,
    private memberStore: Store<fromMember.MemberState>,
    private memberDetailService: MemberDetailService,
    private entityManagementStore: Store<EntityManagementState>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getUrlBeforeSearch();
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = this.targetIdFromDialog || params.id || params.memberId || this.injectedMemberId;
      this.memberDetailService.memberId = params.id || params.memberId;
      this.accidentId = params.accidentId;
      this.checkMemberIndex();
      this.isHistoryTab = this.router.url.includes(STRING_QUERY_PARAM.SEARCH);
      this.isOverview = params.isOverview === 'true';
      this.menuId = params.menuId;
      this.entityViewId = params.viewId;
    });
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((query) => {
      const entityReferenceLinkedId = new URLSearchParams(document.location.search).get("entityReferenceLinkedId");
      if (entityReferenceLinkedId) {
        this.entityReferenceLinkedId = entityReferenceLinkedId;
      }
    });

    if (!this.targetIdFromDialog) {
      this.store.dispatch(
        LayoutActions.profileNavigationItemRequest({
          memberId: this.memberId,
          entityReferenceLinkedId: this.entityReferenceLinkedId
        })
      );
    }

    this.store
      .pipe(
        select(fromLayoutReducer.selectProfileNavigationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if ((state?.menu?.length > 0) && !state?.isHyperlink) {
          if (this.isOverview) {
            this.getMemberDetailData();
            this.cardId = (state?.memberNavigationList as any).overviewHeaderId;
            this.headerItems = ((state?.memberNavigationList as any)?.overviewHeaderItems ?? []).filter((el: {option: string}) => el.option !== 'Event');
          } else {
            this.cardId = (state?.memberNavigationList as any).subPageHeaderId;
            this.headerItems = (state?.memberNavigationList as any)?.subPageHeaderItems ?? [];
          }
          this.entityId = (state?.memberNavigationList as any).entityId;
          this.entityId$.next(this.entityId);
          this.profileName = (state?.memberNavigationList as any).profileName;

          this.getMemberProfileData();
          if (this.urlMemberDetail) {
            let url = this.urlMemberDetail;
            if (this.isOverview) {
              url = url.replace(this.menuId, state?.memberNavigationList.id as string);
              url = url.replace(this.entityViewId, state?.memberNavigationList.overviewViewId as string);
            } else {
              const allMenuItems = state?.memberNavigationList.memberNavigationMenu?.reduce((acc: any[], menu) => {
                return [...acc, ...menu?.menuItems ?? []];
              }, []);

              const menuSameView = allMenuItems.find(menuitem => menuitem.entityViewId === this.entityViewId);
              if (menuSameView) {
                url = url.replace(this.menuId, menuSameView.id as string);
              } else {
                let baseUrl = url.substr(this.router.url.lastIndexOf('/'));
                if ((state?.memberNavigationList as any)?.isOverviewDetailView) {
                  url = `/member/detail-view/true/${state?.memberNavigationList.id}/${state?.memberNavigationList.overviewViewId}${baseUrl}`;
                } else {
                  url = `/member/summary-view/true/${state?.memberNavigationList.id}/${state?.memberNavigationList.overviewViewId}${baseUrl}`;
                }
              }
            }
            this.router.navigateByUrl(url);
            this.urlMemberDetail = '';
          }
        }
      });

    this.memberStore
      .pipe(
        select(this.isOverview ? fromMember.selectProfileHeaderConfig : fromMember.selectProfileSubHeaderConfig),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {

          this.profileHeaders = state?.payload?.filter(x => x.propertyName !== null).map(item => {
              if (item.type === EntityPropertyType.Binary) {
                return {
                  ...item,
                  value: item?.value
                }
              }
              return {
                ...item,
                value: item.value?.maskedValue || item.value?.maskedValue === null ? item.value?.maskedValue : item?.value
              }
          });
          if (this.headerItems !== undefined && this.profileHeaders !== undefined) {
            this.headerItems = this.headerItems.map(item => {
              let mappedValue = this.profileHeaders?.find(x =>
                (x.entityPropertyId === item.propertyId || x.entityPropertyId === item.propertyReferenceId) &&
                (x.entityReferencePropertyId === item.propertyReferenceId || x.entityReferencePropertyId === item.propertyId)
                )
              let value =  mappedValue?.value;
              if (mappedValue?.type === EntityPropertyType.Status) {
                //TBU Option Date
                this.status = {
                  recordId: mappedValue?.recordId,
                  entityComponentId: mappedValue?.entityComponentId,
                  entityPropertyId: mappedValue?.entityPropertyId,
                  options: this.profileHeaders?.find(x => x.entityPropertyId === item.propertyId && x.entityReferencePropertyId === item.propertyReferenceId)?.options,
                  value: value
                };

                let status = mappedValue?.options?.find((item: any) => item.id === value?.status);
                let temp: any;
                if (item.option === "Name") {
                  temp = {
                    icon: status?.icon,
                    color: status?.color,
                    name: status?.name
                  };
                } else if (item.option === "Event") {
                  temp = {
                    icon: status?.icon,
                    color: status?.color,
                    tooltip: status?.name,
                    name: status?.events?.find((item: any) => item.id === value?.event)?.name
                  };
                } else {
                  temp = {
                    icon: null,
                    name: value?.statusDate ? new DatePipe('en-US').transform(value?.statusDate, 'MM/dd/yyyy') : null
                  };
                }
                value = temp;
              }
              if (mappedValue?.type === EntityPropertyType.Address || mappedValue?.type === EntityPropertyType['Person Name']) {
                if (value && typeof(value) === 'object' && Object.values(value).every(item => item === null))
                  value = null;
                value = value;
              }
              if (value && mappedValue?.type === EntityPropertyType['Date Time']) {
                value = getDateString(value);
              }
              if (value && mappedValue?.type === EntityPropertyType.Identifier) {
                if (mappedValue?.configs?.autogenerated === 'true') {
                  value =
                    (mappedValue?.configs?.patternPrefix ?? '') +
                    value +
                    (mappedValue?.configs?.patternSuffix ?? '');
                }
              }
              return {
                ...item,
                value: value,
                type: mappedValue?.type,
                options: mappedValue?.options,
                configs: mappedValue?.configs
              }
            });
            this.isLoadingHeaderItems = true;
            this.isLoading = state?.isLoading;
          }
          if (this.headerItems?.length === 0) {
            this.isLoadingHeaderItems = false;
          }
        }
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMemberDetail),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          this.memberDetail = state?.payload;
        }
      });

    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => {
        this.fundId = el.id;
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMemberNavigationState),
        debounceTime(300),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((memberNavigationState) => {
        this.memberNavigationState = memberNavigationState;
        this.checkMemberIndex();
        this.checkNavigateStatus();
      });

    this._setMemberEventSelector();
  }

  _setMemberEventSelector() {
    this.entityManagementStore
      .pipe(select(setMemberEventSelector), takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (el) {
          if (el.success === true) {
            this.getMemberProfileData();
          }

          this.memberStore.dispatch(
            clearSetMemberEventStateAction()
          );
        }
      });
  }

  checkMemberIndex() {
    if (
      !this.memberNavigationState ||
      (!this.memberId)
    ) {
      return;
    }
    const currentIndex = this.memberNavigationState.memberIndex;
    const memberIndex = this.memberNavigationState.members.findIndex(
      (member) => this.memberId === member._memberId || this.memberId === member.Id
    );
    if (currentIndex === memberIndex) {
      return;
    }
    this.memberStore.dispatch(
      MemberNavigationActions.setMemberIndex({ memberIndex })
    );
    this.checkCurrentPage(
      memberIndex,
      this.memberNavigationState.query as MemberListQuery
    );
  }

  checkNavigateStatus() {
    if (this.shouldDisableNavigationButtonsInAllCases === true) {
      this.navigationDisabled = { previous: true, next: true };
      return;
    }
    if (!this.memberNavigationState?.members?.length || this.isHistoryTab) {
      this.navigationDisabled = { previous: true, next: true };
      return;
    }
    const previous = this.memberNavigationState?.memberIndex <= 0;
    const next =
      this.memberNavigationState?.memberIndex === -1 ||
      this.memberNavigationState?.memberIndex >=
      this.memberNavigationState?.members?.length - 1;
    this.navigationDisabled = { previous, next };
  }

  getFractionalLengthDecimal(configs: Config): number | string {
    return configs?.fractionalLengthInput
      ? configs?.fractionalLengthInput
      : JSON.parse(configs?.calcucation ?? '""')?.fractionalLength;
  }

  lockAccount(isLock: boolean) {
    this.lockAccountEvent.emit(isLock);
  }

  removeMember() {
    this.removeMemberEvent.emit();
  }

  deactivateMember() {
    this.deactivateMemberEvent.emit();
  }

  close() {
    this.store.dispatch(LayoutActions.closeTab({}));

    if (this.router.url.includes(STRING_QUERY_PARAM.PROFILE_NAVIGATE)) {
      this.location.back();
      return;
    }
    if (!this.isHistoryTab) {
      this.memberStore.dispatch(
        MemberNavigationActions.clearMemberNavigationState()
      );
    }
    let url = '/member';
    if (
      this.router.url.includes(STRING_QUERY_PARAM.SEARCH) &&
      this.selectUrlBeforeSearch
    ) {
      url = this.selectUrlBeforeSearch;
    }
    this.store.dispatch(
      LayoutActions.setHistory({ history: { urlBeforeSearch: '' } })
    );
    this.router.navigate([url], { state: { beforeState: this.memberNavigationState.query } });
  }

  onEditMemberStatusEvent() {
    this.dialog.open(EditMemberStatusEventComponent, {
      panelClass: ['edit-popup', 'edit-member-status-event-dialog'],
      disableClose: true,
      height: 'auto',
      autoFocus: false,
      data: {
        entityId: this.entityId,
        memberId: this.memberId,
        recordId: this.status.recordId,
        entityComponentId: this.status.entityComponentId,
        entityPropertyId: this.status.entityPropertyId,
        eventId: this.status.value?.event,
        statusId: this.status.value?.status,
        memberStatusList: this.status.options
      },
    });
  }

  changeMemberDetail(isNext: boolean = false) {
    this.changeMemberDetailEvent.emit();
    const memberIndex =
      this.memberNavigationState.memberIndex + (isNext ? +1 : -1);
    const query = this.memberNavigationState.query as MemberListQuery;
    this.checkCurrentPage(memberIndex, query);
    this.memberStore.dispatch(
      MemberNavigationActions.setMemberIndex({ memberIndex })
    );
    const id =
      (this.memberNavigationState.members[memberIndex]?._memberId ||
      this.memberNavigationState.members[memberIndex]?.Id)  as string;
    const path = this.router.url.substr(0, this.router.url.lastIndexOf('/'));
    if (id) {
      const entityId = this.memberNavigationState.members[memberIndex]?.entityId as string;
      const url = document.location.search.includes(STRING_QUERY_PARAM.SEARCH)
        ? `${path}/${id}?${STRING_QUERY_PARAM.SEARCH}${entityId ? `&entityReferenceLinkedId=${entityId}` : ''}`
        : `${path}/${id}${entityId ? `?entityReferenceLinkedId=${entityId}` : ''}`;
      if (this.accidentId){
        this.redirectAccidentDetailPage(id);
      } else {
        if (this.entityViewId) {
          this.urlMemberDetail = url;
        } else {
          this.router.navigateByUrl(url);
        }
      }
      this.store.dispatch(
        LayoutActions.profileNavigationItemRequest({ memberId: id, entityReferenceLinkedId: entityId })
      );
    }
  }

  redirectAccidentDetailPage(memberId: string) {
    this.router.navigateByUrl(`/member/accident/${memberId}`);
  }

  getMemberProfileData() {
    if (this.memberId) {
      const request = {
        cardId: this.cardId,
        memberId: this.memberId,
        entityId: this.entityId,
        screenId: this.profileName.replace(/\s/g, '') + '_overview_'
      };
      if (this.isOverview) {
        this.memberStore.dispatch(
          ProfileHeaderConfigurationActions.getProfileHeaderDataAction(request)
        );
      } else {
        this.memberStore.dispatch(
          ProfileHeaderConfigurationActions.getProfileSubHeaderDataAction(request)
        );
      }
    }
  }

  getMemberDetailData() {
    if (this.memberId) {
      this.memberStore.dispatch(
        ProfileHeaderConfigurationActions.getMemberDetailAction({
          memberId: this.memberId
        })
      );
    }
  }

  checkCurrentPage(memberIndex: number, memberListQuery: MemberListQuery) {
    const query = JSON.parse(JSON.stringify(memberListQuery));
    let isAppend = false;
    if (memberIndex === 0 && this.memberNavigationState?.startPage > 1) {
      query.pageIndex -= 1;
    }
    if (
      memberIndex === this.memberNavigationState?.members?.length - 1 &&
      memberIndex === this.memberNavigationState?.query?.totalPerPage &&
      this.memberNavigationState.endPage < this.memberNavigationState.totalPage
    ) {
      query.pageIndex += 1;
      isAppend = true;
    }
    if (query.pageIndex !== memberListQuery.pageIndex) {
      this.memberStore.dispatch(
        MemberNavigationActions.getMoreMembers({
          query,
          idClient: this.fundId,
          isAppend,
        })
      );
    }
  }

  getUrlBeforeSearch() {
    this.store
      .pipe(select(fromLayoutReducer.selectUrlBeforeSearchState))
      .subscribe((selectUrlBeforeSearch) => {
        this.selectUrlBeforeSearch = selectUrlBeforeSearch || '/member';
      });
  }

  onClickActionItem(id: string): void {
    this.clickActionItem.emit(id);
  }

  checkNull4Address(value: any) {
    return !value || ['city', 'country', 'state', 'street1', 'street2', 'zipCode'].every(i => value[i] === null);
  }
}
