<div class="edit-benefit-mapping">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (clickBreadcrumb)="clickBreadcrumb($event)"
  ></ptg-breadcrumb>
  <div class="main gap-6 flex flex-col">
    <ptg-banner
      *ngIf="message"
      [(bannerType)]="bannerType"
      [message]="message"
      [isHideCloseButton]="true"
    ></ptg-banner>
    <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
      <div class="wrap-btn">
        <button mat-raised-button type="submit" class="save-btn" [ngClass]="{'disabled': data?.isUsed}"> Save </button>
        <button mat-raised-button type="button" class="cancel-btn" (click)="onCancelClick()">Cancel</button>
      </div>
      <div class="line gap-2">
        <div>
          <p class="label">Benefit Entity</p>
          <p>{{data.name}}</p>
        </div>

        <ptg-select
          style="width: 50%;"
          placeholder="Calculation"
          [controlField]="editForm.get('calculation')"
          [listData]="listCalculation"
          [isRequired]="true"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
        ></ptg-select>

        <div class="flex" style="width: 100%;">
          <ptg-select
            style="width: 50%;"
            placeholder="Parent Benefit Entity "
            [controlField]="typeSelect"
            [listData]="listParent"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
          ></ptg-select>
          <button
            mat-raised-button type="button"
            class="add-btn"
            type="button"
            [class.disabled]= "!listParent?.length"
            (click)="addType()"
          >
            <mat-icon>add</mat-icon>
          </button>
          <div class="chip-item-container">
            <span class="chip" *ngFor="let item of listChip; let i = index" [title]="item.displayValue">
              <span class="text truncate">{{item.displayValue}}</span>
              <button mat-button type="button" class="x-btn" (click)="removeChip(i)">✕</button>
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
