import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';
import { GetListDocumentTemplateRequest, GetListDocumentTemplateResponse, GetListDocumentTemplateFilterSettingsResponse, AddEditDocumentTemplateRequest, GetDocumentTemplateResponse, GetListMergeFieldsResponse, CheckExistFileNameRequest, CheckExistTemplateNameRequest, CheckExistResponse, CheckExistMergeFieldNameRequest } from './models/document-template.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentTemplateService {
  fileUploadForReadMergeFields?: File;

  constructor(private httpClient: HttpClient) {}

  getListDocumentTemplate(
    request: GetListDocumentTemplateRequest,
  ): Observable<GetListDocumentTemplateResponse> {

    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    if (request?.templateName) {
      params = params.append('templateName', request.templateName);
    }
    if (request?.entityId) {
      params = params.append('entityId', request.entityId);
    }
    if (request?.fileType?.length) {
      request?.fileType?.forEach(ft => {
        params = params.append('fileType', ft);
      });
    }

    return this.httpClient.get<GetListDocumentTemplateResponse>(
      `${environment.apiUrl}/template-portal`,
      { params },
    );
  }

  getListDocumentTemplateFilterSettings(): Observable<GetListDocumentTemplateFilterSettingsResponse> {
    return this.httpClient.get<GetListDocumentTemplateFilterSettingsResponse>(
      `${environment.apiUrl}/template/filter-settings`,
    );
  }

  downloadDocumentTemplate(fileId: string) {
    let params = new HttpParams();
    params = params.append('fileId', fileId);

    return this.httpClient.get(
      `${environment.apiUrl}/documents/download`,
      {
        responseType: 'blob',
        params,
      }
    );
  }

  addDocumentTemplate(
    request: AddEditDocumentTemplateRequest,
  ) {
    const body = new FormData();

    if (this.fileUploadForReadMergeFields) {
      body.append('file', this.fileUploadForReadMergeFields);
    }

    if (request.tagIds.length) {
      request.tagIds.forEach((item, idx) => {
        body.append(`tagIds[${idx}]`, item);
      });
    }

    if (request.mergeFields.length) {
      request.mergeFields.forEach((item, idx) => {
        if (item.id) {
          body.append(`mergeFields[${idx}].templateMergeFieldId`, item.id);
        }
        body.append(`mergeFields[${idx}].name`, item.name);
        body.append(`mergeFields[${idx}].templateParameterId`, item.templateParameterId);
      });
    }

    body.append('isAdd', (request.isAdd as boolean).toString());
    body.append('templateName', request.templateName);
    body.append('entityId', request.entityId);
    body.append('description', request.description);

    return this.httpClient.put(
      `${environment.apiUrl}/template`,
      body,
    );
  }

  editDocumentTemplate(
    request: AddEditDocumentTemplateRequest,
  ) {
    const body = new FormData();

    if (this.fileUploadForReadMergeFields) {
      body.append('file', this.fileUploadForReadMergeFields);
    }

    if (request.fileId) {
      body.append('fileId', request.fileId);
    }

    if (request.tagIds.length) {
      request.tagIds.forEach((item, idx) => {
        body.append(`tagIds[${idx}]`, item);
      });
    }

    if (request.mergeFields.length) {
      request.mergeFields.forEach((item, idx) => {
        if (item.id) {
          body.append(`mergeFields[${idx}].templateMergeFieldId`, item.id);
        }
        if (item.mappingId) {
          body.append(`mergeFields[${idx}].templateParameterMappingId`, item.mappingId);
        }
        body.append(`mergeFields[${idx}].name`, item.name);
        body.append(`mergeFields[${idx}].templateParameterId`, item.templateParameterId);
      });
    }

    body.append('isAdd', (request.isAdd as boolean).toString());
    body.append('templateName', request.templateName);
    body.append('entityId', request.entityId);
    body.append('description', request.description);

    return this.httpClient.post(
      `${environment.apiUrl}/template/${request.id}`,
      body,
    );
  }

  removeDocumentTemplate(documentTemplateId: string) {
    let params = new HttpParams();
    params = params.append('templateId', documentTemplateId);

    return this.httpClient.delete(
      `${environment.apiUrl}/template`,
      { params }
    );
  }

  checkExistTemplateName(checkExistTemplateNameRequest: CheckExistTemplateNameRequest) {
    return this.httpClient.post<CheckExistResponse>(
      `${environment.apiUrl}/template/check-template-name/existing`, checkExistTemplateNameRequest
    );
  }

  checkExistFileName = (checkExistFileNameRequest: CheckExistFileNameRequest) => {
    return this.httpClient.post<CheckExistResponse>(
      `${environment.apiUrl}/files/check-file-name/existing`, checkExistFileNameRequest
    );
  }

  getListMergeFields() {
    const body = new FormData();
    if (this.fileUploadForReadMergeFields) {
      body.append('file', this.fileUploadForReadMergeFields);
    }
    return this.httpClient.post<GetListMergeFieldsResponse>(`${environment.apiUrl}/template/read-merge-fields`, body);
  }

  getDocumentTemplate(documentTemplateId: string) {
    return this.httpClient.get<GetDocumentTemplateResponse>(
      `${environment.apiUrl}/template/${documentTemplateId}`,
    );
  }

  checkMergeFieldNameExist = (checkExistMergeFieldNameRequest: CheckExistMergeFieldNameRequest) => {
    let params = new HttpParams();

    if (checkExistMergeFieldNameRequest?.mergeFieldName) {
      params = params.append('mergeFieldName', checkExistMergeFieldNameRequest?.mergeFieldName);
    }

    if (checkExistMergeFieldNameRequest?.parameterId) {
      params = params.append('parameterId', checkExistMergeFieldNameRequest?.parameterId);
    }

    return this.httpClient.get<CheckExistResponse>(
      `${environment.apiUrl}/template/parameters/merge-field-name/existing`, { params }
    );
  }
}