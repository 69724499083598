import { createAction, props } from '@ngrx/store';
import { TransactionSource } from '@ptg-employer/constance/transactions.const';
import { DownloadPaymentReceiptRequest, GetPaymentReceiptRequest, PaymentReceipt } from '@ptg-employer/models/payment-receipt.model';

export const getPaymentReceiptAction = createAction(
  '[GetPaymentReceipt/API] Send Request',
  props<{ request: GetPaymentReceiptRequest }>()
);

export const getPaymentReceiptSuccessAction = createAction(
  '[GetPaymentReceipt/API] Success',
  props<{ response: PaymentReceipt }>()
);

export const getPaymentReceiptFailureAction = createAction(
  '[GetPaymentReceipt/API] Failure',
  props<{ error?: any }>()
);

export const clearGetPaymentReceiptStateAction = createAction(
  '[GetPaymentReceipt] Clear'
);

export const downloadPaymentReceiptAction = createAction(
  '[DownloadPaymentReceipt/API] Send Request',
  props<{ request: DownloadPaymentReceiptRequest, receiptPaymentMethod: TransactionSource }>()
);

export const downloadPaymentReceiptSuccessAction = createAction(
  '[DownloadPaymentReceipt/API] Success',
);

export const downloadPaymentReceiptFailureAction = createAction(
  '[DownloadPaymentReceipt/API] Failure',
  props<{ error?: any }>()
);

export const clearDownloadPaymentReceiptStateAction = createAction(
  '[DownloadPaymentReceipt] Clear'
);

