import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  ViewCreditConfirmationDialogComponent,
} from '@ptg-member/components/view-credit-confirmation-dialog/view-credit-confirmation-dialog.component';
import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { DataType } from '@ptg-shared/constance/data-type.const';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';

import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { DetailDisplay } from '@ptg-shared/types/models/detail-display.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { takeUntil } from 'rxjs/operators';
import * as MunicipalityServiceHistoryActions from '../../store/actions/municipality-service-history.action';
import {
  EditMemberServiceHistoryComponent,
} from '../../components/edit-municipality-service-history/edit-municipality-service-history.component';
import { OverviewHeaderComponent } from '../../components/overview-header/overview-header.component';
import * as fromMember from '../../store/reducers';
import { GetListStatusHistoryQuery, History, MemberNavigationResponse, ValidateTerminatedMemberResponse } from '../../types/models';
import { NumberLocalDecimalPipe } from '@ptg-shared/pipes/number-local-decimal.pipe';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { Sort } from '@angular/material/sort';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { CheckPermissionService } from '@ptg-shared/services/check-permission.service';
import { EmployerSubmoduleKey } from '@ptg-shared/constance/employer-portal-permission.const';

@Component({
  selector: 'ptg-municipality-service-history',
  templateUrl: './municipality-service-history.component.html',
  styleUrls: ['./municipality-service-history.component.scss'],
})
export class MunicipalityServiceHistoryComponent extends BaseComponent {
  @Input() currentPageName?: string;
  currentFund: any = {};
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Service History',
    },
  ];
  columns: Column[] = [
    {
      name: 'beginDate',
      header: {
        title: 'Begin Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'endDate',
      header: {
        title: 'End Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'municipalityName',
      header: {
        title: 'Municipality',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'serviceTime',
      header: {
        title: 'Service',
      },
      sortable: true,
    },
  ];
  muniPortalcolumns: Column[] = [
    {
      name: 'beginDate',
      header: {
        title: 'Begin Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
      width: '200px',
    },
    {
      name: 'endDate',
      header: {
        title: 'End Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
      width: '200px',
    },
    {
      name: 'municipalityName',
      header: {
        title: 'Municipality',
      },
      sortable: true,
      truncate: true,
    }
  ];
  isEntityView: boolean = false;
  viewId: string = '';
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isLoading = false;
  dataTable: History[] = [];
  dateOfDeath = '';
  listAggregations: any[] = [];
  items: any[] = [];
  memberId: string = '';
  selectedRowId!: string;
  sortInfo: Sort = { active: '', direction: 'asc' };
  lengthPg = 0;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  propertyDisplayed: DetailDisplay[] = [
    {
      label: 'Begin Date',
      propertyName: 'beginDate',
      type: DataType.TYPE_DATE,
    },
    { label: 'End Date', propertyName: 'endDate', type: DataType.TYPE_DATE },
    {
      label: 'Municipality',
      propertyName: 'municipalityName',
      type: DataType.TYPE_TEXT,
    },
    { label: 'Service', propertyName: 'serviceTime', type: DataType.TYPE_TEXT },
  ];
  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;
  @ViewChild(OverviewHeaderEntityComponent) overViewHeaderEntity!: OverviewHeaderEntityComponent;
  editMemberServiceHistoryData!: any;
  menuName: string = '';
  menuItems?: any;
  entityId: string = '';
  isMuniPortal: boolean = false;
  employerPortalId: string = '';

  isViewServiceHistoryScreen: boolean = false;
  isShowAddBtnByPermission: boolean = false;
  isShowEditBtnByPermission: boolean = false;

  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    public authService: Auth0Service,
    public layoutService: LayoutService,
    private checkPermissionService: CheckPermissionService,
  ) {
    super();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.selectedRowId = params.recordId;
        if (params.pageSize && params.pageIndex) {
          this.pageNumber = Number.isNaN(Number(params.pageIndex))
            ? 0
            : +params.pageIndex;
          this.pageSize = Number.isNaN(Number(params.pageSize))
            ? 10
            : +params.pageSize;
        }
      });
  }

  ngOnInit(): void {
    this.isMuniPortal = this.authService.isMunicipalityPortal$.value;
    if(this.layoutService.currentEmployer$?.value?.id){
      this.employerPortalId = this.layoutService.currentEmployer$?.value?.id
    }
    this.route.params.subscribe((params) => {
      // TODO: need remove logic after complete implement entity
      this.isEntityView = this.route.snapshot.url.some(path => path.path === 'system-view');
      this.viewId = params.viewId;
      this.memberId = params.id;
    });
    
    this.isViewServiceHistoryScreen = !!this.checkPermissionService.checkPermission(EmployerSubmoduleKey.ViewServiceHistoryCard)
      && !!this.checkPermissionService.checkPermission(EmployerSubmoduleKey.ServiceHistoryCard);
    if (this.isViewServiceHistoryScreen === false) {
      return;
    }
    this.isShowAddBtnByPermission = !!this.checkPermissionService.checkPermission(EmployerSubmoduleKey.AddServiceHistoryCard);
    this.isShowEditBtnByPermission = !!this.checkPermissionService.checkPermission(EmployerSubmoduleKey.EditServiceHistoryCard);

    this.getMenuItemName();
    this.checkValidateTerminatedMemberState();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((el) => {
        this.currentFund = el;
        this.pageSize = el.defaultPageSize ?? 50;
      });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + '-ptg-municipality-service-history-pageSize')) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + '-ptg-municipality-service-history-pageSize'));

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.id;
      this.getData();
    });
    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityServiceRecordState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.lengthPg = state?.serviceRecords?.total;
        this.isLoading = state?.isLoading;
        this.dataTable = state?.serviceRecords?.serviceRecords;
        showBanner.call(
          this,
          state.createState || '',
          this.currentPageName ?? this.menuName ?? 'Service Record',
          ACTION.ADD,
          state?.errMsg ? { customMessage: state?.errMsg } : undefined
        );
        showBanner.call(
          this,
          state.updateState || '',
          this.currentPageName ?? this.menuName ?? 'Service Record',
          ACTION.EDIT,
          state?.errMsg ? { customMessage: state?.errMsg } : undefined
        );
        if (
          state.createState == STATE.SUCCESS ||
          state.updateState == STATE.SUCCESS
        ) {
          this.isLoading = true;
          if (!this.isEntityView) {
            this.reloadOverviewHeader();
          }
          this.reloadOverviewHeaderEntity();
          this.getData();
          this.validateTerminatedMember();
        }
        if (state.createState || state.updateState) {
          this.memberStore.dispatch(
            MunicipalityServiceHistoryActions.clearMunicipalityServiceHistoryState()
          );
        }

        this.dateOfDeath = state?.serviceRecords?.dateOfDeath ?? '';

        this.listAggregations = [
          {
            items: [
              {
                name: 'Begin Date',
                value: state?.serviceRecords?.beginDate,
              },
              {
                name: 'End Date',
                value: state?.serviceRecords?.endDate,
              },
              {
                name: 'Total Service',
                value: state?.serviceRecords?.totalService,
              },
            ],
          },
        ];
        if (this.selectedRowId) {
          this.currentRowIndex = this.dataTable.findIndex(
            (row: any) => row.id === this.selectedRowId
          );
        }
      });
  }

  getMenuItemName() {
    this.store.select(fromLayoutReducer.selectProfileNavigationState)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(menuItem => {
      if (!menuItem.menu.length){
        return;
      }
      this.entityId = (menuItem?.memberNavigationList as any).entityId;
        const memberNavigationMenu: MemberNavigationResponse[] =
        menuItem.memberNavigationList?.memberNavigationMenu;
        this.menuItems = memberNavigationMenu
          ?.map((item) => {
            return item.menuItems;
          })
          ?.reduce((a, b) => {
            return a.concat(b);
          });

        this.menuName = this.menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name;
        this.listBreadcrumbs = [
          {
            name: this.menuName || '',
            url: '',
          },
        ];
    });
  }

  getData() {
    let sortType = 1;
    let sortNames = ['default'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListStatusHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      MunicipalityServiceHistoryActions.getMunicipalityServiceHistoryList({
        query,
      })
    );
  }

  reloadOverviewHeader() {
    this.overViewHeader.memberId = this.memberId;
    this.overViewHeader.getMemberProfileData();
    this.overViewHeader.checkMemberIndex();
  }

  reloadOverviewHeaderEntity() {
    this.overViewHeaderEntity.memberId = this.memberId;
    this.overViewHeaderEntity.getMemberProfileData();
    this.overViewHeaderEntity.checkMemberIndex();
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + '-ptg-municipality-service-history-pageNumber', this.pageNumber.toString());
    sessionStorage.setItem(this.currentFund.key + '-ptg-municipality-service-history-pageSize', this.pageSize.toString());
    this.getData();
  }

  selectRow(event: any) {
    this.currentRowIndex = this.dataTable.findIndex(
      (item) => item.id === event.id
    );
  }

  onEditServiceHistory(serviceHistory?: History) {
    const dialog = this.dialog.open(EditMemberServiceHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: this.currentPageName ?? this.menuName,
        serviceHistory,
        dateOfDeath: this.dateOfDeath,
      },
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((editMemberServiceHistoryData) => {
        this.editMemberServiceHistoryData = editMemberServiceHistoryData;
        this.isLoading = !!editMemberServiceHistoryData;
      });
  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }

  validateTerminatedMember() {
    this.memberStore.dispatch(
      MunicipalityServiceHistoryActions.validateTerminatedMemberRequest({
        body: {
          serviceHistoryEndDate: this.editMemberServiceHistoryData?.endDate,
          municipalityId: this.editMemberServiceHistoryData?.municipalityId,
          memberId: this.memberId,
        },
      }),
    );
  }

  checkValidateTerminatedMemberState() {
    this.memberStore
      .pipe(
        select(fromMember.selectValidateTerminatedMemberState),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((validateTerminatedMember) => {
        this.isLoading = false;
        this.memberStore.dispatch(MunicipalityServiceHistoryActions.clearMunicipalityServiceHistoryState());
        if (!validateTerminatedMember?.isShowPopup) {
          return;
        }
        const decimalPipe = new NumberLocalDecimalPipe();
        const dialogRef = this.dialog.open(ViewCreditConfirmationDialogComponent, {
          panelClass: 'edit-popup',
          disableClose: true,
          autoFocus: false,
          height: 'auto',
          width: '800px',
          data: {
            messages: [
              `${ validateTerminatedMember.name } had no activity.`,
              `${ decimalPipe.transform(validateTerminatedMember.credit, '$') } will be credited back to ${ validateTerminatedMember.municipalityName } municipality.`
            ],
            cashJournalEntry: validateTerminatedMember.nextCashJournalEntry,
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!result?.cashJournalEntry) {
            return;
          }
          this.createTransaction(result.cashJournalEntry, validateTerminatedMember);
        });
      });
  }

  createTransaction(cashJournalEntry: string, validateTerminatedMember: ValidateTerminatedMemberResponse) {
    const body = {
      municipalityId: this.dataTable?.[this.currentRowIndex]?.municipalityId,
      year: validateTerminatedMember.year,
      memberId: this.memberId,
      annualCertId: validateTerminatedMember.annualCertId,
      cashJournalEntry,
    };
    this.memberStore.dispatch(MunicipalityServiceHistoryActions.createTransactionRequest({ body }));
  }
}
