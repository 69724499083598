import { createAction, props } from '@ngrx/store';
import {
  GetAlternatePayeesRequest,
  GetAlternatePayeesResponse,
  GetQDROBenefitInformationRequest,
  GetQDROBenefitInformationResponse,
  GetQDROLabelNameRequest,
  GetQDROLabelNameResponse,
  GetQDROValidationBeforeInitializationRequest,
  GetQDROValidationBeforeInitializationResponse,
} from '../../services/models/calculation-qdro.model';

export const getQDROLabelNameAction = createAction(
  '[GetQDROLabelName/API] Send Request',
  props<{ request: GetQDROLabelNameRequest }>(),
);

export const getQDROLabelNameSuccessAction = createAction(
  '[GetQDROLabelName/API] Success',
  props<{ response: GetQDROLabelNameResponse }>(),
);

export const getQDROLabelNameFailureAction = createAction('[GetQDROLabelName/API] Failure', props<{ error?: any }>());

export const clearGetQDROLabelNameStateAction = createAction('[GetQDROLabelName] Clear');

export const getAlternatePayeesAction = createAction(
  '[GetAlternatePayees/API] Send Request',
  props<{ request: GetAlternatePayeesRequest }>(),
);

export const getAlternatePayeesSuccessAction = createAction(
  '[GetAlternatePayees/API] Success',
  props<{ response: GetAlternatePayeesResponse }>(),
);

export const getAlternatePayeesFailureAction = createAction(
  '[GetAlternatePayees/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetAlternatePayeesStateAction = createAction('[GetAlternatePayees] Clear');

export const getQDROValidationBeforeInitializationAction = createAction(
  '[GetQDROValidationBeforeInitialization/API] Send Request',
  props<{ request: GetQDROValidationBeforeInitializationRequest }>(),
);

export const getQDROValidationBeforeInitializationSuccessAction = createAction(
  '[GetQDROValidationBeforeInitialization/API] Success',
  props<{ response: GetQDROValidationBeforeInitializationResponse }>(),
);

export const getQDROValidationBeforeInitializationFailureAction = createAction(
  '[GetQDROValidationBeforeInitialization/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetQDROValidationBeforeInitializationStateAction = createAction(
  '[GetQDROValidationBeforeInitialization] Clear',
);

export const getQDROBenefitInformationAction = createAction(
  '[GetGetQDROBenefitInformation/API] Send Request',
  props<{ request: GetQDROBenefitInformationRequest }>()
);

export const getQDROBenefitInformationSuccessAction = createAction(
  '[GetGetQDROBenefitInformation/API] Success',
  props<{ response: GetQDROBenefitInformationResponse }>()
);

export const getQDROBenefitInformationFailureAction = createAction(
  '[GetGetQDROBenefitInformation/API] Failure',
  props<{ error?: any }>()
);

export const clearGetQDROBenefitInformationStateAction = createAction(
  '[GetGetQDROBenefitInformation] Clear'
);
