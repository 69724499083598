import { createReducer, on } from '@ngrx/store';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance/value.const';
import { AnnualCertificationActions } from 'src/app/employer/actions';
import { clearAnnualCertificationInformationState } from 'src/app/employer/actions/annual-certification.actions';
import {
  AnnualCertificationInformation,
  CertificationHistoryList,
  SigneeInformation,
} from 'src/app/employer/models/annual-certification.model';
import {
  AnnualCertificationConfirmationData,
  AnnualCertificationParticipant,
  AnnualCertificationParticipantList,
} from '../models/annual-certification-participants.model';

export const annualCertificationFeatureKey = 'annualCertification';

export interface State {
  isLoading: boolean;
  annualCertificationInformation: {
    isLoading: boolean;
    data: AnnualCertificationInformation;
    error?: any;
    listSignee?: SigneeInformation[];
  };
  uploadFileState: string;
  annualCertificationParticipantList: AnnualCertificationParticipantList,
  certificationHistoryList: {
    data :CertificationHistoryList;
    isLoading: boolean
  };
  success?: boolean;
  error?: any;
  actionState?: ActionState;
  updateMetRequirementState: string;
  updateMetRequirementDataTableState: string;
  updateEndOfServiceDataTableState: {
    updateEndOfServiceState: string,
    errorMsg?: string,
    originData?: AnnualCertificationParticipant,
  };
  completeAnnualCertificationState: {
    completeAnnualState: string,
    errorMsg?: string
  };
  setAnnualCertificationConfirmSignaturesState: string;
  annualCertificationConfirmationData?: {
    data: AnnualCertificationConfirmationData;
    annualCertificationState: string,
    errorMsg?: string
  };
  updateSigneeToDocuSignState?: string; 
}

const initialState: State = {
  isLoading: true,
  annualCertificationInformation: {
    isLoading: false,
    data: {} as AnnualCertificationInformation,
  },
  uploadFileState: '',
  annualCertificationParticipantList: {} as AnnualCertificationParticipantList,
  certificationHistoryList: {
    data : {}  as CertificationHistoryList,
    isLoading: false
  },
  updateMetRequirementState: '',
  updateMetRequirementDataTableState: '',
  updateEndOfServiceDataTableState: {
    updateEndOfServiceState: '',
    errorMsg: ''
  },
  completeAnnualCertificationState: {
    completeAnnualState: '',
    errorMsg: ''
  },
  setAnnualCertificationConfirmSignaturesState: '',
};

export const reducer = createReducer(
  initialState,
  on(AnnualCertificationActions.getAnnualCertificationInformation, (state, {}) => ({
    ...state,
    annualCertificationInformation: {
      isLoading: true,
      data: {} as AnnualCertificationInformation,
    },
  })),
  on(AnnualCertificationActions.getAnnualCertificationInformationSuccess, (state, { annualCertificationInformation }) => ({
    ...state,
    annualCertificationInformation: {
      isLoading: false,
      data: annualCertificationInformation,
    },
  })),
  on(AnnualCertificationActions.getAnnualCertificationInformationFailure, (state, { error }) => ({
    ...state,
    annualCertificationInformation: {
      ...state.annualCertificationInformation,
      isLoading: false,
      error,
    },
  })),

  on(AnnualCertificationActions.annualCertificationUploadFileSuccess, (state) => ({
    ...state,
    uploadFileState: STATE.SUCCESS
  })),
  on(AnnualCertificationActions.annualCertificationUploadFileFailure, (state) => ({
    ...state,
    uploadFileState: STATE.FAIL
  })),

  on(AnnualCertificationActions.getCertificationHistoryList, (state, {}) => ({
    ...state,
    actionState: undefined,
    certificationHistoryList: {
      isLoading: true,
      data: {
        total: 0,
        annualCertificationHistories: []
      },
    },
  })),
  on(
    AnnualCertificationActions.getCertificationHistorySuccess,
    (state, { data }) => ({
      ...state,
      success: true,
      error: undefined,
      certificationHistoryList : {
        isLoading: false,
        data: data
      },
    })
  ),
  on(
    AnnualCertificationActions.getCertificationHistoryFailure,
    (state, { error }) => ({
      ...state,
      success: false,
      error: error,
      certificationHistoryList: {
        isLoading: false,
        data :{
          total: 0,
          annualCertificationHistories: [],
        }
      },
    })
  ),
  on(
    AnnualCertificationActions.getAnnualCertificationParticipants,
    (state, {}) => ({
      ...state,
      isLoading: true,
      annualCertificationParticipantList: {
        total: 0,
        datas: [],
      },
      actionState: undefined,
    })
  ),
  on(
    AnnualCertificationActions.getAnnualCertificationParticipantsSuccess,
    (state, { annualCertificationParticipantList }) => ({
      ...state,
      isLoading: false,
      success: true,
      error: undefined,
      annualCertificationParticipantList,
    })
  ),
  on(
    AnnualCertificationActions.getAnnualCertificationParticipantsFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      success: false,
      error: error,
      annualCertificationParticipantList: {
        total: 0,
        datas: [],
      },
    })
  ),

  on(
    AnnualCertificationActions.clearGetAnnualCertificationParticipantsStateAction,
    (state) => ({
      ...state,
      isLoading: true,
      annualCertificationParticipantList: {
        total: 0,
        datas: [],
      },
      actionState: undefined,
    })
  ),
  on(AnnualCertificationActions.updateEndOfService, (state, {}) => ({
    ...state,
    updateEndOfServiceDataTableState: {
      updateEndOfServiceState: ''
    },
  })),
  on(AnnualCertificationActions.updateEndOfServiceSuccess, (state) => ({
    ...state,
    updateEndOfServiceDataTableState: {
      updateEndOfServiceState: STATE.SUCCESS,
    }
  })),
  on(
    AnnualCertificationActions.updateEndOfServiceFailure,(state, { error, originData }) => {
      return {
        ...state,
        updateEndOfServiceDataTableState: {
          updateEndOfServiceState:  STATE.FAIL,
          errorMsg: error ?? '',
          originData
        },
      }

    }
  ),
  on(AnnualCertificationActions.updateMetRequirement, (state, {}) => ({
    ...state,
    updateMetRequirementDataTableState: '',
  })),
  on(AnnualCertificationActions.updateMetRequirementSuccess, (state) => ({
    ...state,
    updateMetRequirementDataTableState: STATE.SUCCESS,
  })),
  on(
    AnnualCertificationActions.updateMetRequirementFailure,
    (state, { errorMsg }) => ({
      ...state,
      updateMetRequirementDataTableState: STATE.FAIL,
    })
  ),
  on(AnnualCertificationActions.clearUpdateAnnualCertificationState, (state, {}) => ({
    ...state,
    updateMetRequirementState: '',
    updateEndOfServiceDataTableState: {
      updateEndOfServiceState : ''
    },
    updateMetRequirementDataTableState: '',
    completeAnnualCertificationState: {
      completeAnnualState: ''
    },
    setAnnualCertificationConfirmSignaturesState: '',
  })),
  on(AnnualCertificationActions.saveAnnualCertificationMetRequirementsSuccess, (state) => ({
    ...state,
    updateMetRequirementState: STATE.SUCCESS,
  })),
  on(AnnualCertificationActions.saveAnnualCertificationMetRequirementsFailure, (state, { error }) => ({
    ...state,
    updateMetRequirementState: STATE.FAIL,
  })),
  on(AnnualCertificationActions.clearAnnualCertificationState, (state) => ({
    ...state,
    uploadFileState: ''
  })),
  on(AnnualCertificationActions.completeAnnualCertification, (state, {}) => ({
    ...state,
    completeAnnualCertificationState: {
      completeAnnualState: '',
    },
  })),
  on(AnnualCertificationActions.completeAnnualCertificationSuccess, (state) => ({
    ...state,
    completeAnnualCertificationState: {
      completeAnnualState: STATE.SUCCESS,
    },
  })),
  on(AnnualCertificationActions.completeAnnualCertificationFailure, (state, { error }) => {
    let errorMsg = '';
    if (error) {
      errorMsg = error
    }
    return {
      ...state,
      completeAnnualCertificationState: {
        completeAnnualState: STATE.FAIL,
        errorMsg
      }
    }

  }),
  on(AnnualCertificationActions.confirmationBeforePostingAnnualCertification, (state, {}) => ({
    ...state,
    annualCertificationConfirmationData: {
      data: {} as AnnualCertificationConfirmationData,
      annualCertificationState: '',
    }
  })),
  on(AnnualCertificationActions.confirmationBeforePostingAnnualCertificationSuccess, (state, { annualCertificationConfirmationData }) => ({
    ...state,
    annualCertificationConfirmationData: {
      data: annualCertificationConfirmationData,
      annualCertificationState: STATE.SUCCESS,
    }
  })),
  on(AnnualCertificationActions.confirmationBeforePostingAnnualCertificationFailure, (state, { error }) => {
    let errorMsg = '';
    if (error) {
      errorMsg = error
    }
    return {
      ...state,
      annualCertificationConfirmationData: {
        data: {} as any,
        annualCertificationState: STATE.FAIL,
        errorMsg
      }
    }
  }),
  on(AnnualCertificationActions.setAnnualCertificationConfirmSignatures, (state, {}) => ({
    ...state,
    setAnnualCertificationConfirmSignaturesState: '',
  })),
  on(AnnualCertificationActions.setAnnualCertificationConfirmSignaturesSuccess, (state) => ({
    ...state,
    setAnnualCertificationConfirmSignaturesState: STATE.SUCCESS,
  })),
  on(AnnualCertificationActions.setAnnualCertificationConfirmSignaturesFailure, (state, { error }) => ({
    ...state,
    setAnnualCertificationConfirmSignaturesState: STATE.FAIL,
  })),
  on(AnnualCertificationActions.clearAnnualCertificationInformationState, (state, {}) => ({
    ...state,
    annualCertificationInformation: {
      isLoading: false,
      data: {} as AnnualCertificationInformation,
    },
    annualCertificationConfirmationData: {
      data: {} as any,
      annualCertificationState: '',
      errorMsg: ''
    }
  })),
  on(AnnualCertificationActions.getListSignee, (state, {}) => ({
    ...state,
    annualCertificationInformation: {
      ...state.annualCertificationInformation,
      listSignee: undefined
    }
  })),
  on(AnnualCertificationActions.getListSigneeSuccess, (state, { list }) => ({
      ...state,
      annualCertificationInformation: {
        ...state.annualCertificationInformation,
        listSignee: list
      }
    })
  ),
  on(AnnualCertificationActions.getListSigneeFailure, (state, { error }) => ({
      ...state,
      annualCertificationInformation: {
        ...state.annualCertificationInformation,
        listSignee: undefined
      }
    })
  ),
  on(AnnualCertificationActions.sendSigneeToDocuSignSuccess, (state, {body}) => ({
      ...state,
      annualCertificationInformation: {
        ...state.annualCertificationInformation,
        data: {
          ...state.annualCertificationInformation.data,
          docuSignDocument: body
        }
      }
    })
  ),
  on(AnnualCertificationActions.updateSigneeToDocuSign, (state, {body}) => ({
    ...state,
    updateSigneeToDocuSignState: ''
  })),
  on(AnnualCertificationActions.updateSigneeToDocuSignSuccess, (state) => ({
    ...state,
    updateSigneeToDocuSignState: STATE.SUCCESS
  })),
  on(AnnualCertificationActions.updateSigneeToDocuSignFailure, (state, {error}) => ({
    ...state,
    updateSigneeToDocuSignState: STATE.FAIL
  })),
  on(AnnualCertificationActions.clearUpdateSigneeState, (state) => ({
    ...state,
    updateSigneeToDocuSignState: ''
  })),
);
