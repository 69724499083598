import { HttpClient, HttpContext } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerLevel } from '@ptg-shared/constance/log.const';
import { LogRequest } from '@ptg-shared/types/models/log.model';
import { SKIP_LOG } from 'src/app/interceptor/httpconfig.interceptor';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LoggerService implements ErrorHandler {

  constructor(private httpClient: HttpClient) {
  }

  handleError(error: any) {
    this.error(error?.toString());
    throw error;
  }

  debug(message: string) {
    this.saveLog({ level: LoggerLevel.DEBUG, message });
  }

  info(message: string) {
    this.saveLog({ level: LoggerLevel.INFO, message });
  }

  warning(message: string) {
    this.saveLog({ level: LoggerLevel.WARN, message });
  }

  error(message: string) { 
    this.saveLog({ level: LoggerLevel.ERROR, message });
  }

  saveLog(logRequest: LogRequest) {
    let context = new HttpContext().set(SKIP_LOG, 'true');
    return this.httpClient.post(`${environment.apiUrl}/log`, logRequest, { context }).subscribe();
  }
}
