export interface EntityPropertyDisplayConfiguration {
  id?: string;
  propertyName?: string;
  entityPropertyId?: string;
  type?: number;
  columnName: string;
  orderColumn?: number;
  option: string;
  orderRow?: number;
  sortType?: number;
  columnNameDescription?: string;
  order?: number;
  optionName?: string;
  deleted?: boolean;
  isUsed?: boolean;
  isEnable?: boolean;
  columnNameMaxLength?: number;
  entityNameRef?: string;
  entityReferencePropertyId?: string;
  options?: any;
  viewConfigType?: number;
}

export interface EntityPropertyDisplayConfig {
  propertyName: string;
  entityPropertyId: string;
  entityNameRef: string;
  isMemberProperty?: boolean;
  isFixedPerson?: boolean;
  entityReferencePropertyId: string;
  type: number;
  option: { key: string; value: string }[];
  options?: any;
}

export interface EntityListConfig {
  configurations: EntityPropertyDisplayConfiguration[];
}

export interface EntityPropertyConfigs {
  properties: EntityPropertyDisplayConfig[];
  fixPersonProperties: EntityPropertyDisplayConfig[];
}

export interface EntityListViewConfigurations {
  configurations: EntityPropertyDisplayConfiguration[]
}

export interface EntitySectionConfig {
  title: string;
  columnName?: string;
  propertyName?: string;
}