
export const API_ERROR_TYPE  = 'CommandException'
export const MODULE_KEY  = 'file-module'
export enum FileType {
    doc = 'doc',
    docx = 'docx',
    csv = 'csv',
    xls = 'xls',
    xlsx = 'xlsx',
    ppt = 'ppt',
    pptx = 'pptx',
    pdf = 'pdf',
    png = 'png',
    jpg = 'jpg',
    jpeg = 'jpeg'
}

export const ListFileType = [
    {
        value: FileType.doc,
        displayValue: 'doc'
    },
    {
        value: FileType.docx,
        displayValue: 'docx'
    },
    {
        value: FileType.csv,
        displayValue: 'csv'
    },
    {
        value: FileType.xls,
        displayValue: 'xls'
    },
    {
        value: FileType.xlsx,
        displayValue: 'xlsx'
    },
    {
        value: FileType.ppt,
        displayValue: 'ppt'
    },
    {
        value: FileType.pptx,
        displayValue: 'pptx'
    },
    {
        value: FileType.pdf,
        displayValue: 'pdf'
    },
    {
        value: FileType.png,
        displayValue: 'png'
    },
    {
        value: FileType.jpg,
        displayValue: 'jpg'
    },
    {
        value: FileType.jpeg,
        displayValue: 'jpeg'
    },
];

export const GENERATE_DOCUMENT_STATUS = {
  COMPLETED: 'Completed'
}

export enum ParticipantDocumentType {
    DeathCertificate,
    MarriedCertificate,
    InvoiceVoucher,
    Other,
}

export const PARTICIPANT_DOCUMENT_TYPE_OPTIONS = [
    {
        value: ParticipantDocumentType.DeathCertificate,
        displayValue: 'Death Certificate'
    },
    {
        value: ParticipantDocumentType.MarriedCertificate,
        displayValue: 'Married Certificate'
    },
    {
        value: ParticipantDocumentType.InvoiceVoucher,
        displayValue: 'Invoice Voucher'
    },
    {
        value: ParticipantDocumentType.Other,
        displayValue: 'Other'
    },
]
