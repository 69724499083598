import { createSelector } from '@ngrx/store';

import { entityManagementModuleSelector } from '../../store/selectors';

export const entityListViewConfigurationSelector = createSelector(
  entityManagementModuleSelector,
  (state) => state?.entityListViewConfiguration,
);

export const selectParticipantPropertyConfigs = createSelector(
  entityListViewConfigurationSelector,
  (state) => state?.propertyConfigs,
);

export const selectParticipantPropertyDisplayConfigurations = createSelector(
  entityListViewConfigurationSelector,
  (state) => state?.propertyDisplayConfigurations,
);

export const selectParticipantUpdateState = createSelector(
  entityListViewConfigurationSelector,
  (state) => state?.updateState,
);

export const selectPropertyConfigsState = createSelector(entityListViewConfigurationSelector, (state) => ({
  propertyConfigs: state?.propertyConfigs,
}));
