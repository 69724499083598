import { createReducer, on } from "@ngrx/store";
import { ToleranceParameterActions } from "../actions";
import { ToleranceParameterDto } from "../models/tolerance-parameter.model";

export const toleranceParameterFeatureKey = 'toleranceParameter';

export interface State {
    toleranceParameterState: {    
      isLoading: boolean;
      toleranceParameterDto: ToleranceParameterDto;
      success?: boolean,
      error?: any,
    };
    actionState?: any;
}

const initialState: State = {
    toleranceParameterState: {
        isLoading: true,
        toleranceParameterDto: {} as ToleranceParameterDto
    },
}

export const reducer = createReducer(
    initialState,
    on(ToleranceParameterActions.getToleranceParameterRequest, (state) => ({
      ...state,
      toleranceParameterState: {
        isLoading: true,
        toleranceParameterDto: {
          toleranceTypeName : '',
          toleranceTypeValue : 0,
          tolerance: '',
          isEditable: true,
        }
      }
    })),
    on(ToleranceParameterActions.getToleranceParameterRequestSuccess, (state, { tolerance }) => ({
      ...state,
      toleranceParameterState: {
        ...state.toleranceParameterState,
        isLoading: false,
        success: true,
        error: undefined,
        toleranceParameterDto: tolerance,
      },
    })),
    on(ToleranceParameterActions.getToleranceParameterRequestFailure, (state, { error }) => ({
      ...state,
      toleranceParameterState: {
        ...state.toleranceParameterState,
        isLoading: false,
        success: false,
        error: error,
        tolerance: {
          toleranceTypeName : '',
          toleranceTypeValue : 0,
          tolerance: '',
          isEditable: true,
        }
      }
    })),
)