import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  MetadataProfileHeaderConfiguration,
  ProfileHeaderData,
  ProfileHeaderList,
  PropertyConfigs,
  Status,
} from '../types/models';
import { GetPropertyType } from '../constance/member-list.const';

const AggregationType = 1;

@Injectable({
  providedIn: 'root',
})
export class ProfileHeaderConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getPropertyList() {
    // return this.httpClient.get<PropertyConfigs>(
    //   `${environment.apiUrl}/Metadata/SectionProperties/${GetPropertyType.ProfileHeader}`
    // );
 
    // TODO remove when complete module entity
   return of();
  }

  getProfileHeaderConfiguration(
    id: string
  ): Observable<MetadataProfileHeaderConfiguration> {
    // return this.httpClient.get<MetadataProfileHeaderConfiguration>(
    //   `${environment.apiUrl}/Metadata/${id}/GetProfileHeaderConfigurations`
    // );

    // TODO remove when complete module entity
   return of();
  }

  getListProfileHeaderConfiguration(query: {
    SortNames: string;
    SortType: number;
  }): Observable<ProfileHeaderList> {
    const params = new HttpParams({ fromObject: query });
    // return this.httpClient.get<ProfileHeaderList>(
    //   `${environment.apiUrl}/Metadata/ProfileHeaders`,
    //   { params }
    // );

    // TODO remove when complete module entity
   return of();
  }

  manageConfigurationMember(
    body: MetadataProfileHeaderConfiguration
  ): Observable<string> {
    // let metadataProfileHeaderConfigurations =
    //   body.metadataProfileHeaderConfigurations.map((el) => {
    //     let res = {
    //       ...el,
    //       headerItemType: el.headerType,
    //     };
    //     if (el?.headerType !== AggregationType) {
    //       res.headerItemKey = el.headerItemKey;
    //     } else {
    //       res.headerItemKey = el.aggregationId;
    //     }
    //     return res;
    //   });
    // let payload = {
    //   ...body,
    //   metadataProfileHeaderConfigurations,
    // };
    // return this.httpClient.post<string>(
    //   `${environment.apiUrl}/Metadata/ManageProfileHeaderConfig`,
    //   payload
    // );

    // TODO remove when complete module entity
   return of();
  }

  getMemberProfileHeaderData(memberId: string): Observable<ProfileHeaderData> {
    // return this.httpClient.get<ProfileHeaderData>(
    //   `${environment.apiUrl}/Members/${memberId}/ProfileHeader`
    // );

    // TODO remove when complete module entity
   return of();
  }

  getProfileHeaderData(cardId: string, memberId: string, entityId: string, screenId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/entity-portal/${entityId}/cards/${cardId}/standalone-property/${memberId}/${encodeURIComponent(screenId)}`
    );
  }

  getMemberDetail(memberId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/member-portal/${memberId}/detail`
    );
  }

  updateProfileHeaderConfiguration(
    body: MetadataProfileHeaderConfiguration
  ): Observable<string> {
    // return this.httpClient.post<string>(
    //   `${environment.apiUrl}/Metadata/ProfileHeader/ManageProfileHeaders`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }
  deleteProfileHeaderConfiguration(profileHeaderId: string) {
    // return this.httpClient.delete(
    //   `${environment.apiUrl}/Metadata/ProfileHeaders/${profileHeaderId}`
    // );

    // TODO remove when complete module entity
   return of();
  }
  getMembersStatusNotUsed(): Observable<{ listStatusUsing: Status[] }> {
    // return this.httpClient.get<{ listStatusUsing: Status[] }>(
    //   `${environment.apiUrl}/Metadata/ProfileHeaders/GetMemberStatusNotUsed`
    // );

    // TODO remove when complete module entity
   return of();
  }

  checkProfileHeaderExists = (body: {
    name: string;
    profileHeaderId?: string;
  }) => {
    // return this.httpClient.post(
    //   `${environment.apiUrl}/Metadata/ProfileHeaders/Exists`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  };
}
