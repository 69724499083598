import { Action, createReducer, on } from '@ngrx/store';
import { Tab } from '@ptg-shared/layout/components/captionbar/types/caption-bar.model';
import {
  DisplayedTabName,
  HiddenTabName,
  SidebarOpenedStatus
} from '@ptg-shared/layout/constance/layout.const';
import { DEFAULT_TAB_MENU_GROUPS } from '@ptg-shared/layout/constance/menu.const';
import { deepClone, deepFlattenArray } from '@ptg-shared/utils/common.util';
import { EmployerDetail, Loading, MenuGroup, PaymentInfoParams, TabName } from 'src/app/shared/layout/models/layout.model';
import { LayoutActions } from '../actions';
import { BaseActionState } from '@ptg-shared/types/models/base-action-state';
import { getEmployerInfoForHeaderAction, getEmployerInfoForHeaderSuccessAction, getEmployerInfoForHeaderFailureAction, clearGetEmployerInfoForHeaderStateAction } from '../actions/layout.actions';

export const LayoutFeature = 'layout';

export interface MainLayoutHistory {
  urlBeforeSearch?: string;
  paymentInfoParams?: PaymentInfoParams;
}

export interface State {
  sidebarOpenedStatus: SidebarOpenedStatus,
  tab: TabName;
  url: string;
  menuGroups?: MenuGroup[];
  allMenuGroups: Partial<Record<DisplayedTabName, MenuGroup[]>>;
  isCloseTab?: boolean;
  isChangePage?: boolean;
  tabs: Tab[];
  history: MainLayoutHistory;
  loading?: Loading;
  getEmployerInfoForHeaderState?: BaseActionState<EmployerDetail>;
}

const initialState: State = {
  sidebarOpenedStatus: SidebarOpenedStatus.Opened,
  url: '',
  tab: HiddenTabName.Hidden,
  tabs: [],
  allMenuGroups: deepClone(DEFAULT_TAB_MENU_GROUPS),
  history: {
    urlBeforeSearch: ''
  },
  getEmployerInfoForHeaderState: undefined,
};

export const layoutReducer = createReducer(
  initialState,
  on(
    LayoutActions.closeSideMenu,
    (state): State => ({
      ...state,
      sidebarOpenedStatus: SidebarOpenedStatus.Closed,
    }),
  ),
  on(
    LayoutActions.openSideMenu,
    (state): State => ({
      ...state,
      sidebarOpenedStatus: SidebarOpenedStatus.Opened,
    }),
  ),
  on(
    LayoutActions.hiddenSideMenu,
    (state): State => ({
      ...state,
      sidebarOpenedStatus: SidebarOpenedStatus.Hidden,
    }),
  ),
  on(
    LayoutActions.setSidebarOpenedStatus,
    (state, { sidebarOpenedStatus }): State => ({
      ...state,
      sidebarOpenedStatus,
    }),
  ),
  on(LayoutActions.selectTab, (state, { tab, url }): State => {
    let tabs = deepClone(state.tabs) as Tab[];
    tabs = tabs.map((item) => {
      item.isSelected = item.tabName === tab;
      if (item.isSelected && url) {
        item.url = url;
      }
      if (item.children) {
        item.children = item.children.map((child) => {
          child.isSelected = child.tabName === tab;
          if (child.isSelected && url) {
            child.url = url;
          }
          return child;
        });
      }
      return item;
    });
    return {
      ...state,
      tabs,
      url: url || state.url,
    };
  }),
  on(LayoutActions.setTabUrl, (state, { tab, url }): State => {
    let tabs = deepClone(state.tabs);
    tabs = tabs.map((item) => {
      if (item.tabName === tab && url) {
        item.url = url;
      }
      if (item.children) {
        item.children = item.children.map((child) => {
          if (child.tabName === tab && url) {
            child.url = url;
          }
          return child;
        });
      }
      return item;
    });
    return {
      ...state,
      tabs,
      url: url || state.url,
    };
  }),
  on(
    LayoutActions.hideAllTabs,
    (state): State => ({
      ...state,
      tab: HiddenTabName.Hidden,
    }),
  ),
  on(LayoutActions.setTabHiddenState, (state, { tabName, isHidden }): State => {
    let tabs = deepClone(state.tabs);
    tabs = tabs.map((item) => {
      if (item.tabName === tabName) {
        item.isHidden = isHidden;
      }
      if (item.children) {
        item.children = item.children.map((child) => {
          if (child.tabName === tabName) {
            child.isHidden = isHidden;
          }
          return child;
        });
      }
      return item;
    });
    return {
      ...state,
      tabs,
    };
  }),
  on(LayoutActions.closeTab, (state, { tabName }): State => {
    let tabs = deepClone(state.tabs);
    const closedTabName = tabName || (deepFlattenArray(tabs) as Tab[]).find((item) => item.isSelected)?.tabName;
    tabs = tabs.map((tab) => {
      if (tab.tabName === closedTabName) {
        tab.url = '';
      }
      if (tab.children) {
        tab.children = tab.children.map((child) => {
          if (child.tabName === closedTabName) {
            child.url = '';
          }
          return child;
        });
      }
      return tab;
    });
    return {
      ...state,
      tabs,
    };
  }),
  on(
    LayoutActions.changePage,
    (state): State => ({
      ...state,
      isChangePage: true,
    }),
  ),
  on(
    LayoutActions.changePageSuccess,
    (state): State => ({
      ...state,
      isChangePage: false,
    }),
  ),
  on(
    LayoutActions.setAllMenuGroups,
    (state, { allMenuGroups }): State => ({
      ...state,
      allMenuGroups,
    }),
  ),
  on(
    LayoutActions.resetAllMenuGroups,
    (state): State => ({
      ...state,
      allMenuGroups: deepClone(DEFAULT_TAB_MENU_GROUPS),
    }),
  ),
  on(
    LayoutActions.setHistory,
    (state, { history }): State => ({
      ...state,
      history: {
        ...state.history,
        ...history,
      },
    }),
  ),
  on(LayoutActions.setTabs, (state, { tabs }): State => {
    return {
      ...state,
      tabs,
    };
  }),
  on(
    LayoutActions.setCurrentMenuGroups,
    (state, { menuGroups }): State => ({
      ...state,
      menuGroups,
    }),
  ),
  on(
    LayoutActions.setCurrentUrl,
    (state, { url }): State => ({
      ...state,
      url,
    }),
  ),

  // Get Employer Info For Post Card
  on(getEmployerInfoForHeaderAction, (state) => ({
    ...state,
    getEmployerInfoForHeaderState: {
      isLoading: true,
    },
  })),
  on(getEmployerInfoForHeaderSuccessAction, (state, { response }) => ({
    ...state,
    getEmployerInfoForHeaderState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getEmployerInfoForHeaderFailureAction, (state, { error }) => ({
    ...state,
    getEmployerInfoForHeaderState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEmployerInfoForHeaderStateAction, (state) => ({
    ...state,
    getEmployerInfoForHeaderState: undefined,
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return layoutReducer(state, action);
}
