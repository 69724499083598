import { createAction, props } from '@ngrx/store';

import {
  CreateTransactionBody,
  CreateValidateServiceHistoryRecordBeginDateTransactionRequest,
  CreateValidateServiceHistoryRecordBeginDateTransactionResponse,
  EditMunicipalityServiceHistoryRequest,
  GetListStatusHistoryQuery,
  Municipality,
  MunicipalityServiceRecordList,
  MunicipalityServiceRecordListEntity,
  ValidateServiceHistoryRecordBeginDateRequest,
  ValidateServiceHistoryRecordBeginDateResponse,
  ValidateTerminatedMemberBody,
  ValidateTerminatedMemberResponse,
} from '../../types/models';

export const MunicipalityServiceHistoryListRequest =
  '[MunicipalityServiceHistoryList/API] Send Request';
export const MunicipalityServiceHistoryListSuccess =
  '[MunicipalityServiceHistoryList/API] Success';
export const MunicipalityServiceHistoryListFailure =
  '[MunicipalityServiceHistoryList/API] Failure';

export const MunicipalityServiceHistoryEntityListRequest =
  '[MunicipalityServiceHistoryEntityList/API] Send Request';
export const MunicipalityServiceHistoryEntityListSuccess =
  '[MunicipalityServiceHistoryEntityList/API] Success';
export const MunicipalityServiceHistoryEntityListFailure =
  '[MunicipalityServiceHistoryEntityList/API] Failure';

export const MunicipalityListRequest = '[MunicipalityList/API] Send Request';
export const MunicipalityListSuccess = '[MunicipalityList/API] Success';
export const MunicipalityListFailure = '[MunicipalityList/API] Failure';
export const ClearMunicipalityListState = '[MunicipalityList] Clear';

export const CreateMemberMunicipalityServiceHistoryRequest =
  '[CreateMemberMunicipalityServiceHistory/API] Send Request';
export const CreateMemberMunicipalityServiceHistorySuccess =
  '[CreateMemberMunicipalityServiceHistory/API] Success';
export const CreateMemberMunicipalityServiceHistoryFailure =
  '[CreateMemberMunicipalityServiceHistory/API] Failure';
export const EditMemberMunicipalityServiceHistoryRequest =
  '[EditMemberMunicipalityServiceHistory/API] Send Request';
export const EditMemberMunicipalityServiceHistorySuccess =
  '[EditMemberMunicipalityServiceHistory/API] Success';
export const EditMemberMunicipalityServiceHistoryFailure =
  '[EditMemberMunicipalityServiceHistory/API] Failure';
export const ClearMunicipalityServiceHistoryState =
  '[ClearMunicipalityServiceHistoryState]';

export const ValidateTerminatedMemberRequest = '[ValidateTerminatedMember/API] Send Request';
export const ValidateTerminatedMemberSuccess = '[ValidateTerminatedMember/API] Success';
export const ValidateTerminatedMemberFailure = '[ValidateTerminatedMember/API] Failure';

export const CreateTransactionRequest = '[CreateTransaction/API] Send Request';
export const CreateTransactionSuccess = '[CreateTransaction/API] Success';
export const CreateTransactionFailure = '[CreateTransaction/API] Failure';




export const getMunicipalityServiceHistoryList = createAction(
  MunicipalityServiceHistoryListRequest,
  props<{ query: GetListStatusHistoryQuery }>()
);
export const municipalityServiceHistoryListSuccess = createAction(
  MunicipalityServiceHistoryListSuccess,
  props<{ serviceRecords: MunicipalityServiceRecordList }>()
);
export const municipalityServiceHistoryListFailure = createAction(
  MunicipalityServiceHistoryListFailure
);

export const getMunicipalityServiceHistoryEntityList = createAction(
  MunicipalityServiceHistoryEntityListRequest,
  props<{ query: any, memberId: string }>()
);
export const municipalityServiceHistoryEntityListSuccess = createAction(
  MunicipalityServiceHistoryEntityListSuccess,
  props<{ serviceRecordsEntity: MunicipalityServiceRecordListEntity }>()
);
export const municipalityServiceHistoryEntityListFailure = createAction(
  MunicipalityServiceHistoryEntityListFailure
);

export const getMunicipalityList = createAction(MunicipalityListRequest);
export const getMunicipalityListSuccess = createAction(
  MunicipalityListSuccess,
  props<{ listMunicipality: Municipality[] }>()
);
export const getMunicipalityListFailure = createAction(MunicipalityListFailure);
export const clearMunicipalityListState = createAction(ClearMunicipalityListState);

export const createMemberMunicipalityServiceHistoryRequest = createAction(
  CreateMemberMunicipalityServiceHistoryRequest,
  props<{ body: EditMunicipalityServiceHistoryRequest }>()
);
export const createMemberMunicipalityServiceHistorySuccess = createAction(
  CreateMemberMunicipalityServiceHistorySuccess
);
export const createMemberMunicipalityServiceHistoryFailure = createAction(
  CreateMemberMunicipalityServiceHistoryFailure,
  props<{ errorMessage: string }>()
);
export const editMemberMunicipalityServiceHistoryRequest = createAction(
  EditMemberMunicipalityServiceHistoryRequest,
  props<{ body: EditMunicipalityServiceHistoryRequest }>()
);
export const editMemberMunicipalityServiceHistorySuccess = createAction(
  EditMemberMunicipalityServiceHistorySuccess
);
export const editMemberMunicipalityServiceHistoryFailure = createAction(
  EditMemberMunicipalityServiceHistoryFailure,
  props<{ errorMessage: string }>()
);
export const clearMunicipalityServiceHistoryState = createAction(
  ClearMunicipalityServiceHistoryState
);

export const validateTerminatedMemberRequest = createAction(
  ValidateTerminatedMemberRequest,
  props<{ body: ValidateTerminatedMemberBody }>()
);
export const validateTerminatedMemberSuccess = createAction(
  ValidateTerminatedMemberSuccess,
  props<{ validateTerminatedMember: ValidateTerminatedMemberResponse }>()
);
export const validateTerminatedMemberFailure = createAction(
  ValidateTerminatedMemberFailure
);

export const createTransactionRequest = createAction(
  CreateTransactionRequest,
  props<{ body: CreateTransactionBody }>()
);
export const createTransactionSuccess = createAction(
  CreateTransactionSuccess
);
export const createTransactionFailure = createAction(
  CreateTransactionFailure
);

export const validateServiceHistoryRecordBeginDateAction = createAction(
  '[ValidateServiceHistoryRecordBeginDate/API] Send Request',
  props<{ request: ValidateServiceHistoryRecordBeginDateRequest }>()
);
export const validateServiceHistoryRecordBeginDateSuccessAction = createAction(
  '[ValidateServiceHistoryRecordBeginDate/API] Success',
  props<{ response: ValidateServiceHistoryRecordBeginDateResponse }>()
);
export const validateServiceHistoryRecordBeginDateFailureAction = createAction(
  '[ValidateServiceHistoryRecordBeginDate/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearValidateServiceHistoryRecordBeginDateStateAction = createAction(
  '[ValidateServiceHistoryRecordBeginDate] Clear'
);

export const createValidateServiceHistoryRecordBeginDateTransactionAction = createAction(
  '[CreateValidateServiceHistoryRecordBeginDateTransaction/API] Send Request',
  props<{ request: CreateValidateServiceHistoryRecordBeginDateTransactionRequest }>()
);
export const createValidateServiceHistoryRecordBeginDateTransactionSuccessAction = createAction(
  '[CreateValidateServiceHistoryRecordBeginDateTransaction/API] Success',
  props<{ response: CreateValidateServiceHistoryRecordBeginDateTransactionResponse }>()
);
export const createValidateServiceHistoryRecordBeginDateTransactionFailureAction = createAction(
  '[CreateValidateServiceHistoryRecordBeginDateTransaction/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearCreateValidateServiceHistoryRecordBeginDateTransactionStateAction = createAction(
  '[CreateValidateServiceHistoryRecordBeginDateTransaction] Clear'
);
