import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { OverviewHeaderComponent } from '@ptg-member/components/overview-header/overview-header.component';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import {
  CalculationState,
  clearCheckExceptionConfigurationStateAction,
  clearEditDisabilityBenefitStateAction,
  clearGetDisabilityBenefitDetailStateAction,
  clearRemoveDisabilityCalculationStateAction,
  clearSetBenefitDetailStateAction,
  getDisabilityBenefitDetailAction,
  getDisabilityBenefitDetailSelector,
  initiateDisabilityBenefitSelector,
  removeOverviewDocumentAction,
  clearCheckRetirementDocumentCanRemoveStateAction,
  removeRemoveOverviewDocumentSelector,
  getRetirementBenefitDownloadDocumentSelector,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  getRetirementBenefitDocumentsAction,
  getRetirementBenefitDocumentSelector,
  checkRetirementDocumentCanRemoveAction,
  removeDisabilityCalculationAction,
  removeDisabilityCalculationSelector,
  clearGetCalculationAuditTrailStateAction,
  clearValidateRemoveCalculationAction,
  validateRemoveCalculationAction,
  validateRemoveCalculationSelector,
  clearGetRetirementBenefitDocumentsStateAction,
  createRetirementBenefitUploadDocumentsAction,
  clearCreateRetirementBenefitUploadDocumentsStateAction,
  clearEditRetirementBenefitDocumentStateAction,
  editRetirementBenefitDocumentAction,
} from '../../store';

import { ACTION, DEFAULT_PAGE_SIZE, SORT_TYPE, SortType, STATE } from '@ptg-shared/constance';
import { Column } from '@ptg-shared/controls/grid';
import {
  CalculationBenefitHistoryStatus,
  CalculationType,
  DependentType,
  ValidateDisabilityBenefitErrorType,
} from '../../types/enums';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';

import { MenuItemSubTitle, MenuItemTitle } from '@ptg-member/constants';
import { AccidentStatus } from '@ptg-member/features/accident-claims/services/models';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { deepClone, showBanner, downloadFile } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { AddDisabilityComponent } from '../../components';
import {
  BenefitDependentDetails,
  GetDisabilityBenefitDetailRequest,
  RetirementBenefitHistory,
  RetirementBenefitDetailDocument,
  CheckComputeDisabilityDataResponse,
  CreateRetirementBenefitUploadDocumentsRequest,
} from '../../services/models';

import { checkExistCheckRetirementDocumentCanRemoveSelector } from '../../store/selectors/check-retirement-document-can-remove.selector';
import { DISABILITY_DOCUMENT_TYPE_OPTIONS, DisabilityTableType } from '../../constants';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import {
  GRID_COLUMN_DETAIL_BENEFIT_DOCUMENT,
  GRID_COLUMN_DISABILITY_LONG_TERM,
  GRID_COLUMN_DISABILITY_SHORT_TERM,
  GRID_COLUMN_DISABILITY_RELATED_ACCIDENTS,
} from '../../benefit-detail.constants';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { DOCUMENT_LOCATION, USED_FOR_MENU } from '@ptg-shared/constance/document-location.const';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CheckExistsDocumentNameResponse } from '@ptg-employer/models/employer-document.model';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';
import {
  createRetirementBenefitUploadDocumentsSelector,
  editRetirementBenefitDocumentsSelector,
} from '../../store/selectors/retirement-benefit-upload-document.selector';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
} from 'src/app/admin/features/file/store/actions';
import { AccidentParticipantsState } from '@ptg-member/features/accident-claims/store/reducers/accident-participants.reducer';
import * as fromReducer from '@ptg-reducers';

const PAGE_SIZE_CONST = '-ptg-disability-detail-pageSize';

@Component({
  selector: 'ptg-disability-benefit-detail',
  templateUrl: './disability-benefit-detail.component.html',
  styleUrls: ['./disability-benefit-detail.component.scss'],
})
export class DisabilityBenefitDetailComponent extends BaseListComponent {
  CalculationBenefitHistoryStatus = CalculationBenefitHistoryStatus;
  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;
  PAGE_KEY = '-ptg-disability-benefit-detail';
  memberId = '';
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs;
  title = '';
  calculationBenefitId = '';
  calculationRecordId = '';
  calculationType: CalculationType = CalculationType.DisabilityShortTerm;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  sortInfo?: Sort;
  isShowReopenBtn: boolean = false;
  isShowTotal: boolean = false;
  isShowEditBtn: boolean = false;
  isLoading?: boolean = false;
  isLongTerm: boolean = false; //TODO

  columnsDisability: Column[] = [];
  disabilityBenefitData: any[] = [];
  private detailData?: any;
  accidentData: any[] = [];
  totalDisability: number = 0;
  isDisabilityLoading: boolean = false;
  pageDisabilitySize: number = DEFAULT_PAGE_SIZE;
  pageDisabilityIndex: number = FIRST_PAGE;

  private passedExceptionTrigger = new Subject<boolean>();

  beginDate = '';
  endDate = '';
  totalDaysUsed = '';

  menuItemTitle: string = MenuItemTitle.Disability;
  menuItemSubTitle: string = MenuItemSubTitle.BenefitsProcessing;

  removedDocumentId: string = '';

  totalBenefitDocuments: number = 0;
  isDocumentLoading?: boolean = false;
  pageDocumentIndex: number = FIRST_PAGE;
  pageDocumentSize: number = DEFAULT_PAGE_SIZE;
  disabilityBenefitDocuments: RetirementBenefitDetailDocument[] = [];
  downloadFileName: string = '';

  removedId = '';

  columnsDisabilityShortTerm: Column[] = GRID_COLUMN_DISABILITY_SHORT_TERM;

  columnsDisabilityLongTerm: Column[] = GRID_COLUMN_DISABILITY_LONG_TERM;

  columnsAccident: Column[] = GRID_COLUMN_DISABILITY_RELATED_ACCIDENTS;

  columnsBenefitDocuments: Column[] = GRID_COLUMN_DETAIL_BENEFIT_DOCUMENT;

  canRecalculate: boolean = false;

  currentFund: any = {};
  defaultPageSize: number = DEFAULT_PAGE_SIZE;

  constructor(
    public readonly route: ActivatedRoute,
    private readonly router: Router,
    public layoutService: LayoutService,
    private calculationStore: Store<CalculationState>,
    private documentStore: Store<DocumentsState>,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    public authService: Auth0Service,
    private retirementBenefitDialogService: RetirementBenefitDialogService,
    private store: Store<AccidentParticipantsState>,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentFundAndRouteData();

    this.selectBenefitDetailData();

    this.selectInitiateDisabilityBenefitState();
    this.registerGetDisabilityDetailDocumentData();
    this.selectorCheckDocumentCanRemove();
    this.selectorRemoveDocument();
    this.selectorDownloadFile();
    this.uploadDocumentSelector();

    this.registerEditDocumentSelector();
  }

  /**
   * Return the breadcrumb data
   */
  private get getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: 'Overview',
        url: `/member/disability-overview/${CalculationType.Disability}/${this.memberId}`,
      },
      {
        name: `Disability Benefit Details`,
      },
    ];
  }

  setPageSize() {
    const disabilityPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + DisabilityTableType.DisabilityProperties + PAGE_SIZE_CONST,
      ),
    );
    const benefitDocumentPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + DisabilityTableType.DisabilityDocuments + PAGE_SIZE_CONST,
      ),
    );
    this.pageDisabilitySize = disabilityPageSizeInSession === 0 ? this.defaultPageSize : disabilityPageSizeInSession;
    this.pageDocumentSize =
      benefitDocumentPageSizeInSession === 0 ? this.defaultPageSize : benefitDocumentPageSizeInSession;
  }

  getCurrentFundAndRouteData() {
    combineLatest([this.route.params, this.store.select(fromReducer.selectCurrentFundState)])
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(([params, currentFund]) => {
        this.memberId = params.memberId;
        this.calculationBenefitId = params.calculationBenefitId;
        this.listBreadcrumbs = this.getBreadcrumbs;
        this.calculationType = parseInt(params.calculationType);
        this.defaultPageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
        this.currentFund = currentFund;
        this.setPageSize();
        this.getBenefitDetailData();
        this.getDisabilityDetailDocumentData();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.passedExceptionTrigger.complete();
    this.clearStates();
  }

  private clearStates(): void {
    this.calculationStore.dispatch(clearGetDisabilityBenefitDetailStateAction());
    this.calculationStore.dispatch(clearEditDisabilityBenefitStateAction());
    this.calculationStore.dispatch(clearSetBenefitDetailStateAction());
    this.calculationStore.dispatch(clearGetCalculationAuditTrailStateAction());
    this.calculationStore.dispatch(clearGetRetirementBenefitDocumentsStateAction());
    this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());
    this.calculationStore.dispatch(clearValidateRemoveCalculationAction());
    this.calculationStore.dispatch(clearRemoveDisabilityCalculationStateAction());
  }

  private selectInitiateDisabilityBenefitState(): void {
    this.calculationStore
      .pipe(select(initiateDisabilityBenefitSelector), takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response?.success) {
          this.calculationStore.dispatch(clearSetBenefitDetailStateAction());
          this.getBenefitDetailData();
        }
      });
  }

  getBenefitDetailData() {
    const request: GetDisabilityBenefitDetailRequest = this.setBenefitRequest(
      this.pageDisabilitySize,
      this.pageDisabilityIndex,
      { name: 'CreatedDate', direction: SortType.DESC },
    );
    this.calculationStore.dispatch(getDisabilityBenefitDetailAction({ request }));
  }

  private selectBenefitDetailData() {
    this.calculationStore
      .pipe(
        select(getDisabilityBenefitDetailSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        if (!res?.payload) {
          return;
        }
        if (!res?.isLoading && res?.success) {
          this.detailData = res.payload;
          this.handleDataDisplay();
        }
      });
  }

  setBenefitRequest(pageSize: number, pageIndex: number, sortParam?: { name: string; direction: number }) {
    let sortType;
    let sortNames;

    if (sortParam) {
      sortType = sortParam.direction;
      sortNames = sortParam.name;
    }

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    const requestDetail: GetDisabilityBenefitDetailRequest = {
      pageNumber: pageIndex,
      pageSize: pageSize,
      sortNames: sortNames,
      sortType: sortType,
      memberId: this.memberId,
      calculationType: this.calculationType,
      calculationBenefitId: this.calculationBenefitId,
    };

    return requestDetail;
  }

  handleDataDisplay() {
    if (this.detailData.disabilityBenefitPropertiesRecord === undefined) {
      return;
    }
    const itemDetail = this.detailData.disabilityBenefitPropertiesRecord;
    this.isLongTerm = this.calculationType === CalculationType.DisabilityLongTerm;

    this.canRecalculate = itemDetail.calculationBenefitStatus === CalculationBenefitHistoryStatus.Approved;
    this.title = this.isLongTerm ? 'Long Term Disability Properties' : 'Short Term Disability Properties';
    this.isShowTotal = !this.isLongTerm;
    this.isShowEditBtn = this.handleShowEditButton(this.detailData);
    this.columnsDisability = this.isLongTerm ? this.columnsDisabilityLongTerm : this.columnsDisabilityShortTerm;

    this.beginDate = itemDetail.beginDate;
    this.endDate = itemDetail.endDate;

    this.disabilityBenefitData = deepClone(
      this.detailData.calculationDisabilityDetailsRecords?.map((item: any) => {
        const childNumber = item.listCalculationBenefitDependentDetails.filter(
          (dependItem: BenefitDependentDetails) => dependItem.type === DependentType.Child,
        ).length;
        return {
          ...item,
          createdDate: getDateString(item.createdDate),
          calcAsOfDate: item.calcAsOfDate,
          benefitEndDate: item.benefitEndDate,
          status: item.updatedDate
            ? `${CalculationBenefitHistoryStatus[item.status]} (${this.datePipe.transform(
                getDateString(item.updatedDate),
                'MM/dd/yyyy',
              )})`
            : CalculationBenefitHistoryStatus[item.status],
          totals: this.calculateTotalsBenefit(item, childNumber),
          childNumber: childNumber ?? '-',
          days: item?.days ?? '-',
          memberAmount: item.memberAmount,
          spouseAmount: item.spouseAmount,
          amountChild: item.amountChild,
        };
      }) ?? [],
    );
    const accidentItem = this.detailData.accident;
    this.accidentData = [
      {
        ...accidentItem,
        accidentDate: getDateString(accidentItem.accidentDate),
        receiveDate: getDateString(accidentItem.receiveDate),
        status: accidentItem.lastStatusDate
          ? `${AccidentStatus[accidentItem.accidentStatus]} (${this.datePipe.transform(
              getDateString(accidentItem.lastStatusDate),
              'MM/dd/yyyy',
            )})`
          : AccidentStatus[accidentItem.accidentStatus],
        injury: `${accidentItem.injuryCode ?? ''} - ${accidentItem.injuryCodeDescription ?? ''}`,
        municipality: `${accidentItem.municipalityCode ?? ''} ${'(' + accidentItem.municipalityName + ')' ?? ''}`,
        isFatal: accidentItem.isFatal ? 'Yes' : 'No',
      },
    ];

    this.calculateTotalDaysUsed();
    this.totalDisability = this.detailData?.total || 0;
  }

  handleShowEditButton(detailData: any) {
    const itemProperty = detailData.disabilityBenefitPropertiesRecord;
    if (this.isLongTerm) {
      return !itemProperty.endDate || new Date() <= new Date(itemProperty.endDate);
    }
    //case short-term
    const firstCalculationItem = deepClone(detailData.calculationDisabilityDetailsRecords).pop();
    return firstCalculationItem.status === CalculationBenefitHistoryStatus.Initiated;
  }

  calculateTotalDaysUsed() {
    let totalDays = 0;
    this.detailData.calculationDisabilityDetailsRecords?.forEach((item: any) => {
      totalDays += item.days;
    });

    this.totalDaysUsed = `${totalDays}/180`;
  }

  calculateTotalsBenefit(item: any, childNumber: number = 0) {
    if (!this.isLongTerm) {
      return 0;
    }
    return item.memberAmount + item.spouseAmount + item.childAmount * childNumber;
  }

  onChangeSort(event: Sort) {
    this.sortInfo = event;
    this.getBenefitDetailData();
  }

  onChangePage(event: PageEvent) {
    this.pageDisabilitySize = event.pageSize;
    this.pageDisabilityIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + DisabilityTableType.DisabilityProperties + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getBenefitDetailData();
  }

  onClickDeleteItem(row: any) {
    let contentMessage = 'Are you sure you want to proceed?';
    const numberRecords = this.detailData.calculationDisabilityDetailsRecords?.length;
    if (numberRecords == 1) {
      contentMessage =
        'Removing this calculation record will remove the corresponding benefit record. Are you sure you want to proceed?';
    }
    const confirmResult = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'This is a destructive action',
        text: contentMessage,
        type: ConfirmType.Destruct,
        cancelButtonTitle: 'Cancel',
      },
    });
    confirmResult.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.removedId = row?.id;
      this.calculationRecordId = row?.id;

      this.handleValidateRemoveCalculation(() => {
        this.handleRemoveCalculation();
      });
    });
  }

  handleValidateRemoveCalculation(callback: () => void) {
    this.calculationStore.dispatch(
      validateRemoveCalculationAction({
        memberId: this.memberId,
        calculationRecordId: this.calculationRecordId,
      }),
    );

    this.calculationStore
      .select(validateRemoveCalculationSelector)
      .pipe(
        filter((rsp) => !!rsp?.payload),
        take(1),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearValidateRemoveCalculationAction());
        if (res?.success && res?.payload?.isValid) {
          if (callback) {
            callback();
          }
        }
        if (!res?.payload?.isValid) {
          this.handleErrorData(res?.payload, 'compute');
        }
      });
  }

  handleRemoveCalculation() {
    const request = {
      memberId: this.memberId,
      calculationRecordId: this.calculationRecordId,
    };

    this.calculationStore.dispatch(removeDisabilityCalculationAction(request));
    this.calculationStore
      .select(removeDisabilityCalculationSelector)
      .pipe(
        filter((res) => !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response) {
          return;
        }
        showBanner.call(this, response?.success ? STATE.SUCCESS : STATE.FAIL, 'Disability Benefit', ACTION.REMOVE);
        if (response?.success) {
          if (response?.payload) {
            this.router.navigate([`/member/disability-overview/${CalculationType.Disability}/${this.memberId}`]);
            return;
          }
        }
        this.getBenefitDetailData();
      });
  }

  handleErrorData(payload?: CheckComputeDisabilityDataResponse, actionType: string = 'complete') {
    let content = '';
    if (payload?.errorType === ValidateDisabilityBenefitErrorType.NotInitiatedStatus) {
      content = `Can only remove disability calculation at state "Initiated". Please reload to view the latest data.`;
    }

    if (payload?.errorType === ValidateDisabilityBenefitErrorType.NotFound) {
      content = 'The calculation record no longer exists. Please reload to view the latest data.';
    }

    const confirmResult = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: content,
        title: 'Error',
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
    confirmResult.afterClosed().subscribe(() => {
      if (payload?.errorType === ValidateDisabilityBenefitErrorType.NotFound) {
        this.router.navigateByUrl(`/member/disability-overview/${CalculationType.Disability}/${this.memberId}`);
        return;
      }
      this.getBenefitDetailData();
    });
  }

  onClickEditBenefit() {
    let editItem: any = {};
    if (this.calculationType === CalculationType.DisabilityLongTerm) {
      editItem = deepClone(this.detailData.calculationDisabilityDetailsRecords).shift();
    }

    let totalDays = 0;
    if (this.calculationType === CalculationType.DisabilityShortTerm) {
      this.detailData.calculationDisabilityDetailsRecords.forEach((item: any) => {
        if (item.status !== CalculationBenefitHistoryStatus.Initiated) {
          totalDays += item.days;
        }
      });
      editItem = this.detailData.calculationDisabilityDetailsRecords?.find(
        (item: any) => item.status === CalculationBenefitHistoryStatus.Initiated,
      );
    }

    const firstCalculationItem = deepClone(this.detailData.calculationDisabilityDetailsRecords).pop();
    const dialogRef = this.dialog.open(AddDisabilityComponent, {
      panelClass: 'add-new-disability',
      disableClose: true,
      autoFocus: false,
      minWidth: '1200px',
      data: {
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationRecordId: editItem.id,
        calculationType: this.calculationType,
        detailData: {
          propertiesRecord: this.detailData.disabilityBenefitPropertiesRecord,
          editItem: editItem,
          accident: this.detailData.accident,
          firstCalculationItem,
          totalDays,
          numberRecords: this.detailData.calculationDisabilityDetailsRecords.length,
        },
        isEdit: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.getBenefitDetailData();
        }
      });
  }

  openAccidentDetail(row: any) {
    this.router.navigate([`/member/accident/${row.accidentId}/${this.memberId}`]);
  }

  onClickRecalculate() {
    this.dialog.open(AddDisabilityComponent, {
      panelClass: 'add-new-disability',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      minWidth: '1200px',
      data: {
        memberId: this.memberId,
        isRecalculate: true,
        benefitBeginDate: this.detailData.disabilityBenefitPropertiesRecord.beginDate,
        benefitEndDate: this.detailData.disabilityBenefitPropertiesRecord.endDate,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      },
    });
  }

  openBenefitCalculationDetail(row: RetirementBenefitHistory) {
    this.router.navigateByUrl(
      `member/disability-overview/${this.calculationType}/${this.memberId}/calculation-detail/${this.calculationBenefitId}/${row?.id}`,
      { state: row },
    );
  }

  getDisabilityDetailDocumentData() {
    const requestDocument = this.setBenefitRequest(this.pageDocumentSize, this.pageDocumentIndex);

    this.calculationStore.dispatch(
      getRetirementBenefitDocumentsAction({
        request: requestDocument,
        memberId: this.memberId,
        calculationType: this.calculationType,
        calculationBenefitId: this.calculationBenefitId,
      }),
    );
  }

  registerGetDisabilityDetailDocumentData() {
    this.calculationStore
      .select(getRetirementBenefitDocumentSelector)
      .pipe(
        tap((response) => (this.isDocumentLoading = !!response?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetRetirementBenefitDocumentsStateAction());

        this.totalBenefitDocuments = response?.total ?? 0;
        const payload = deepClone(response?.payload ?? []);
        this.disabilityBenefitDocuments = payload.map((paramMapping: any) => {
          return {
            ...paramMapping,
            uploadDate: getDateString(paramMapping?.uploadDate ?? ''),
            documentName: paramMapping?.documentName,
            fileName: paramMapping?.fileName,
            documentTypeName: paramMapping?.documentTypeName,
            documentType: paramMapping?.documentType,
            id: paramMapping?.id,
          };
        });
      });
  }

  onUploadDocuments() {
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(''),
      defaultShowOnOverview: true,
    };
    const specificMenuData = {
      shouldUseCommonBreadcrumbs: true,
      usedForMenu: USED_FOR_MENU.DISABILITY_DETAIL,
      documentTypeOptionList: deepClone(DISABILITY_DOCUMENT_TYPE_OPTIONS),
      acceptFile: '.pdf',
      checkPattern: new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i'),
    };

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const files = objectUpload.files;

        const request: CreateRetirementBenefitUploadDocumentsRequest = {
          memberId: this.memberId,
          calculationType: this.calculationType,
          calculationBenefitId: this.calculationBenefitId,
          file: [
            {
              file: files[0],
              uploadDate: deepClone(objectUpload.uploadDate),
              documentName: objectUpload.documentName,
              calculationDocumentType: objectUpload.type,
            },
          ],
          fileName: files[0]?.name,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentLocationTitle: DOCUMENT_LOCATION.DISABILITY,
          documentLocationRouter: `/member/disability-overview/${this.calculationType}/${this.memberId}/detail/${this.calculationBenefitId}`,
          documentDescription: objectUpload.description,
        };
        this.calculationStore.dispatch(createRetirementBenefitUploadDocumentsAction({ request }));
      }
    });
  }

  onDownloadFile(row: any) {
    if (!row) {
      return;
    }
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
    );
  }

  selectorDownloadFile() {
    this.calculationStore
      .select(getRetirementBenefitDownloadDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((downloadDocument) => {
        if (!downloadDocument?.isLoading && downloadDocument?.success) {
          let blobFile = downloadDocument?.payload ? downloadDocument?.payload[0] : new Blob();
          downloadFile.call(this, blobFile, this.downloadFileName);
          this.calculationStore.dispatch(clearGetRetirementBenefitDownloadDocumentStateAction());
        }
      });
  }

  onChangeSortDocuments(event: Sort) {
    if (event.active === 'documentType') {
      this.sortInfo = {
        active: 'documentTypeName',
        direction: event.direction,
      };
    } else if (event.active === 'uploadDate') {
      this.sortInfo = {
        active: 'uploadDateNoTime',
        direction: event.direction,
      };
    } else {
      this.sortInfo = event;
    }
    this.getDisabilityDetailDocumentData();
  }

  onChangeDocumentsPage(event: PageEvent) {
    super.onChangePage(event);
    this.pageDocumentSize = event.pageSize;
    this.pageDocumentIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + DisabilityTableType.DisabilityDocuments + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getDisabilityDetailDocumentData();
  }

  onEditDocument(file: any) {
    // Open form upload
    let rowData = deepClone(file);
    rowData['tagDescriptionsList'] = rowData?.tags;
    rowData['showOnOverview'] = rowData?.showOnOverview === true ? 'Yes' : 'No';
    rowData['documentLocation'] = DOCUMENT_LOCATION.DISABILITY;
    rowData['documentLocationRouter'] =
      `/member/disability-overview/${this.calculationType}/${this.memberId}/detail/${this.calculationBenefitId}`;
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(rowData?.fileId ?? ''),
    };
    const specificMenuData = {
      usedForMenu: USED_FOR_MENU.EDIT_COMMON_DOCUMENT,
      documentTypeOptionList: deepClone(DISABILITY_DOCUMENT_TYPE_OPTIONS),
    };
    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: rowData,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const request = {
          documentName: objectUpload.documentName,
          tags: objectUpload.tags,
          documentDescription: objectUpload.description,
          showOnOverview: objectUpload.showOnOverview,
          documentType: objectUpload.type,
        };

        this.calculationStore.dispatch(
          editRetirementBenefitDocumentAction({
            memberId: this.memberId,
            calculationBenefitDocumentId: rowData?.id,
            request: request,
          }),
        );
      }
    });
  }

  registerEditDocumentSelector() {
    this.calculationStore
      .pipe(select(editRetirementBenefitDocumentsSelector), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) {
          if (data?.state?.state === STATE.FAIL && data?.errorMsg) {
            showBanner.call(this, data?.state?.state, '', '', { customMessage: data?.errorMsg });
          } else if (data?.state?.state === STATE.FAIL && !data?.errorMsg) {
            showBanner.call(this, data?.state?.state, 'Disability Document', data?.state?.action);
          } else {
            showBanner.call(this, data?.state?.state, 'Disability Document', data?.state?.action);
            this.getDisabilityDetailDocumentData();
          }
          this.calculationStore.dispatch(clearEditRetirementBenefitDocumentStateAction());
        }
      });
  }

  onRemoveDocument(row: any) {
    this.removedDocumentId = row.id;
    const request = {
      calculationBenefitDocumentId: this.removedDocumentId,
      calculationBenefitId: this.calculationBenefitId,
      calculationType: this.calculationType,
    };
    this.calculationStore.dispatch(checkRetirementDocumentCanRemoveAction({ request }));
  }

  removeDocumentEvent() {
    const ALERT_MESSAGE =
      'After removed, this document cannot be used for Disability Benefit initiation. Are you sure you want to proceed?';
    const confirmRemove = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.Destruct,
        cancelButtonTitle: 'Cancel',
      },
    });

    confirmRemove.afterClosed().subscribe((result) => {
      if (result) {
        showBanner.call(this, STATE.SUCCESS, 'Document', ACTION.REMOVE);
        this.calculationStore.dispatch(removeOverviewDocumentAction({ id: this.removedDocumentId }));
      }
    });
  }

  selectorCheckDocumentCanRemove() {
    this.calculationStore
      .select(checkExistCheckRetirementDocumentCanRemoveSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const canRemoveDoc = response?.payload?.isValid;
        this.calculationStore.dispatch(clearCheckRetirementDocumentCanRemoveStateAction());
        if (canRemoveDoc) {
          this.removeDocumentEvent();
        } else {
          const calculationBenefitStatus = response?.payload?.calculationBenefitStatus;
          this.removeDocumentErrorMessage(calculationBenefitStatus);
        }
      });
  }

  selectorRemoveDocument() {
    this.calculationStore
      .select(removeRemoveOverviewDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response && !response?.isLoading) {
          if (response?.success) {
            this.getDisabilityDetailDocumentData();
          } else {
            this.bannerType = BannerType.Fail;
            showBanner.call(this, this.bannerType, '', ACTION.REMOVE);
          }
        }
      });
  }

  private removeDocumentErrorMessage(calculationBenefitStatus?: number) {
    if (typeof calculationBenefitStatus !== 'number') return;
    let alertMessage = '';
    switch (calculationBenefitStatus) {
      case CalculationBenefitHistoryStatus.Initiated:
      case CalculationBenefitHistoryStatus['Pending Approval']:
        alertMessage =
          'This document has been used for a Disability Calculation Benefit and can only be removed in Calculation Details screen.';
        break;
      case CalculationBenefitHistoryStatus.Approved:
        alertMessage =
          'This document has been used for an approved Disability Calculation Benefit and cannot be removed.';
        break;
      default:
        break;
    }
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: alertMessage,
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        title: 'Error',
        hideConfirmButton: true,
      },
    });
  }

  validateExistDocumentNameExist(documentId?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.retirementBenefitDialogService
              .checkExits({
                memberId: this.memberId || '',
                name: control.value.toString(),
                fileId: documentId,
              })
              .pipe(
                map((response: CheckExistsDocumentNameResponse) => {
                  if (response?.exists) {
                    return { errMsgDocumentName: 'Document Name already exists.' };
                  }
                  return null;
                }),
                catchError(({ error }) => {
                  return of({ errMsgDocumentName: error?.errorMessage });
                }),
              ),
        ),
      );
    };
  }

  uploadDocumentSelector() {
    this.calculationStore
      .select(createRetirementBenefitUploadDocumentsSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (retirementBenefitDocument && !retirementBenefitDocument?.isLoading) {
          showBanner.call(
            this,
            retirementBenefitDocument?.success ? STATE.SUCCESS : STATE.FAIL,
            'Document',
            ACTION.UPLOAD,
          );

          if (retirementBenefitDocument?.success) {
            this.getDisabilityDetailDocumentData();
          }
          this.calculationStore.dispatch(clearCreateRetirementBenefitUploadDocumentsStateAction());
        }
      });
  }
}
