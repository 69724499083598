export const EMPLOYER_MODULE_KEY: Record<string, string> = {
  EMPLOYERS: 'employers',
  PARTICIPANTS: 'participants',
  ADMIN: 'admin',
  EMPLOYER_LIST: 'employer_list',
}
export enum EmployerSubmoduleKey {
  Employers = 'employers',
  EmployerList = 'employer_list',
  EmployerDocument = 'employer_document',
  EmployerOverview = 'employer_overview',
  EmployerInformation = 'employer_information',
  ViewEmployerInfo = 'view_employer_info',
  EditEmployerInfo = 'edit_employer_info',
  ContactsCard = 'contacts',
  ViewContact = 'view_contact',
  AddContact = 'add_contact',
  EditContact = 'edit_contact',
  RemoveContact = 'remove_contact',
  NotesCard = 'notes',
  ViewNotes = 'view_notes',
  AddNotes = 'add_notes',
  EditNotes = 'edit_notes',
  RemoveNotes = 'remove_notes',
  DocumentsCard = 'documents',
  ViewDocument = 'view_document',
  DownloadDocument = 'download_document',
  UploadDocument = 'upload_document',
  EditDocument = 'edit_document',
  RemoveDocument = 'remove_document',
  PortalUsers = 'portal_users',
  ViewPortalUsers = 'view_portal_users',
  AddPortalUser = 'add_portal_user',
  EditPortalUser = 'edit_portal_user',
  RemovePortalUser = 'remove_portal_user',
  PaymentPreferences = 'payment_preferences',
  ViewPaymentPreferences = 'view_payment_preferences',
  EditPaymentPreferences = 'edit_payment_preferences',
  Departments = 'departments',
  ViewDepartment = 'view_department',
  NewDepartment = 'new_department',
  EditDepartment = 'edit_department',
  WageProcessing = 'wage_processing',
  WageFileList = 'wage_file_list',
  ViewWageFileList = 'view_wage_file_list',
  UploadWageFile = 'upload_wage_file',
  Parameters = 'parameters',
  ViewParameters = 'view_parameters',
  ContributionTaxStatus = 'contribution_tax_status',
  AddNewStatus = 'add_new_status',
  EditCtStatus = 'edit_ct_status',
  RemoveCtStatus = 'remove_ct_status',
  ContributionPlan = 'contribution_plan',
  AddContributionPlan = 'add_new_cp_plan',
  EditContributionPlan = 'edit_cp_plan',
  RemoveContributionPlan = 'remove_cp_plan',
  ReportingCalendar = 'reporting_calendar',
  ViewCalendar = 'view_calendar',
  AddFiscalYear = 'add_fiscal_year',
  EditFiscalYear = 'edit_fiscal_year',
  Remittance = 'remittance',
  ViewRemittanceProcessing = 'view_remittance_processing',
  AddRemittance = 'add_remittance',
  EditRemittanceSubmission = 'edit_remittance_submission',
  RemoveRemittance = 'remove_remittance',
  SendRemittance = 'send_remittance',
  SubmitRemittance = 'submit_remittance',
  PayRemittance = 'pay_remittance',
  RecordRemittance = 'record_payment',
  PostRemittance = 'post_remittance',
  ViewInvoiceOfRemittance = 'print_receipt_invoice',
  ViewReceiptOfRemittance = 'view_receipt_invoice',
  Participants = 'participants',
  ParticipantList = 'participant_list',
  ViewParticipantList = 'view_participant_list',
  AddParticipant = 'add_participant',
  ExportParticipantList = 'export_participant_list',
  ParticipantOverview = 'participant_overview',
  Cards = 'cards',
  ViewCards = 'view_cards',
  EditCards = 'edit_cards',
  ServiceHistoryCard = 'service_history_card',
  ViewServiceHistoryCard = 'view_service_history',
  AddServiceHistoryCard = 'add_service_history',
  EditServiceHistoryCard = 'edit_service_history',
  PaymentsCard = 'payments_card',
  ViewPayment = 'view_payments',
  EditPayment = 'edit_payments',
  Admin = 'admin',
  ViewDemographic = 'view_demographic',
  EditDemographic = 'edit_demographic',
  AccessManagement = 'access_management',
  RoleManagement = 'role_management',
  ViewRoleList = 'view_role_list',
  AddRole = 'add_role',
  EditRole = 'edit_role',
  RemoveRole = 'remove_role',
}

export const EMPLOYER_PERMISSION_KEY = {
  ...EMPLOYER_MODULE_KEY,
  ...EmployerSubmoduleKey
}

export const EMPLOYER_FIRST_SUBMODULE_URL = {
  EMPLOYER_LIST: '/employer/employer-list',
  EMPLOYER_OVERVIEW: '/employer/detail/',
  DEPARTMENTS: '/employer/department-management/',
  WAGE_PROCESSING: '/employer/wage-processing/',
  PARAMETERS: '/employer/parameters/',
  REPORTING_CALENDAR: '/employer/reporting-calendar/',
  PARTICIPANT_LIST: '/member',
  PARTICIPANT_OVERVIEW: '/member/detail/',
}

export const EMPLOYER_PORTAL_SUBMODULE_URL = {
  EMPLOYER_LIST: ['/employer/employer-list'],
  EMPLOYER_OVERVIEW: [
    '/employer/detail/',
    '/employer/employer-list',
    '/employer/employer-overview-configuration',
    '/employer/employer-information-configuration',
    '/employer/employer-list-configuration',
    '/employer/detail',
    '/employer/department-management',
    '/employer/reporting-calendar',
    '/employer/employer-file-layout-configuration',
    '/employer/wage-processing',
    '/employer/parameters',
    '/employer/tolerance/parameters',
    '/employer/contribution-interest-rate',
    '/employer/fee-amounts',
    '/employer/document/generated-document/view',
    '/employer/report-transactions',
    '/employer/report-service-list',
    '/employer/report-cash-journal',
    '/employer/transactions',
    '/employer/annual-certification',
    '/employer/annual-certification-history',
    '/employer/remittance-submission',
    '/employer/remittance-submission-history',
    '/employer/report/annual-certification',
    '/employer/report/pension-report/',
    '/employer/report/relief-report/',
    '/employer/annual-fee-letter',
    '/employer/report-disbursement-summary',
    '/employer/late-fee-reminder-letter',
    '/employer/individual-document',
    '/employer/document-list',

    // TODO: Need apply permission for entity module
    '/entity-management/entities',
    '/entity-management/entities/entity',
    '/entity-management/entities/view',
    '/entity-management/entity-list-view-configuration',
    '/entity-management/entities/cards',
    '/entity-management/benefit-mapping',
  ],
  DEPARTMENTS: ['/employer/department-management/'],
  WAGE_PROCESSING: ['/employer/wage-processing/'],
  PARAMETERS: ['/employer/parameters/'],
  REPORTING_CALENDAR: ['/employer/reporting-calendar/'],
  PARTICIPANT_LIST: ['/member'],
  PARTICIPANT_OVERVIEW: ['/member/detail/'],
}

export const EMPLOYER_SUBMODULES_GROUP_BY_MODULE: Record<string, string[]> = {
  EMPLOYERS: [
    EmployerSubmoduleKey.EmployerList,
    EmployerSubmoduleKey.EmployerOverview,
    EmployerSubmoduleKey.Departments,
    EmployerSubmoduleKey.WageProcessing,
    EmployerSubmoduleKey.Parameters,
    EmployerSubmoduleKey.ReportingCalendar
  ],
  PARTICIPANTS: [
    EmployerSubmoduleKey.ParticipantList,
    EmployerSubmoduleKey.ParticipantOverview,
  ],
  ADMIN: [EmployerSubmoduleKey.AccessManagement],
}

export const EMPLOYER_SUBMODULES_BVFF_NOT_ACCESS =
['/employer/tolerance/parameters',
 '/employer/contribution-interest-rate',
 '/employer/parameters',
 '/employer/reporting-calendar',
 '/employer/wage-processing',
 '/employer/department-management',
]
