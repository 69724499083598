import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Accident, AccidentPostCardRequest } from '../../services/models';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { AccidentParticipantsState } from '../../store/reducers/accident-participants.reducer';
import { select, Store } from '@ngrx/store';
import { addAccidentPostCardAction, clearGetEmployerInfoForPostCardStateAction, clearGetListMunicipalityStateAction, editAccidentPostCardAction, getEmployerInfoForPostCardAction, getListMunicipalityAction } from '../../store/actions';
import { getEmployerInfoForPostCardSelector, getMunicipalitySelector } from '../../store/selectors';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ptg-add-participant-accident-post-card',
  templateUrl: './add-participant-accident-post-card.component.html',
  styleUrls: ['./add-participant-accident-post-card.component.scss'],
})
export class AddParticipantAccidentPostCardComponent extends BaseComponent {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  isEdit = false;
  listMunicipality: any[] = [];
  accidentDateMax = new Date();
  receiveDateMax = new Date();
  listInjury = [
    { displayValue: '1 - Sprain - fall', value: 1 },
    { displayValue: '2 - Strain - lifting', value: 2 },
    { displayValue: '3 - Lacerations', value: 3 },
    { displayValue: '4 - Burns', value: 4 },
    { displayValue: '5 - Smoke inhalation', value: 5 },
    { displayValue: '6 - Chemical inhalation', value: 6 },
    { displayValue: '7 - Heat exhaustion', value: 7 },
    { displayValue: '8 - Eye injury', value: 8 },
    { displayValue: '9 - Contusion, abrasion', value: 9 },
    { displayValue: '10 - Fracture', value: 10 },
    { displayValue: '11 - Puncture - nails, etc.', value: 11 },
    { displayValue: '12 - Puncture - needles', value: 12 },
    { displayValue: '13 - Exp. To comm. Disease', value: 13 },
    { displayValue: '14 - Chest Pains', value: 14 },
    { displayValue: '15 - Poison oak, ivy', value: 15 },
    { displayValue: '16 - Insect bite, sting', value: 16 },
    { displayValue: '17 - Broken teeth', value: 17 },
    { displayValue: '18 - Psychological', value: 18 },
    { displayValue: '19 - Ear injury', value: 19 },
    { displayValue: '20 - Motor Vehicle Accident', value: 20 },
    { displayValue: '21 - Animal Bite', value: 21 },
    { displayValue: '22 - Head Injury/Concussion', value: 22 },
    { displayValue: '23 - Over Exertion', value: 23 },
    { displayValue: '24 - Seizure', value: 24 },
    { displayValue: '25 - Allergic Reaction', value: 25 },
    { displayValue: '26 - Dislocation', value: 26 },
    { displayValue: '27 - Electric Shock', value: 27 },
    { displayValue: '28 - Diabetic Reaction', value: 28 },
    { displayValue: '29 - Deglove/Amputation', value: 29 },
    { displayValue: '30 - Dehydration', value: 30 },
    { displayValue: '31 - Crushing Injury', value: 31 },
    { displayValue: '32 - COVID-19', value: 32 },
    { displayValue: '99 - Other', value: 99 },
  ];
  messageErrorCompareDate: string = 'Accident Date cannot be later than Receive Date.';
  municipalityDefault?: string;
  isShowError: boolean = false;
  isPortalUser: boolean = false;
  isFormLoading: boolean = true;
  isMunicipalityError: boolean = false;
  snapShotAccidentDate: Date = this.accidentDateMax;
  snapShotMuniError: boolean = this.isMunicipalityError;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddParticipantAccidentPostCardComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accidentPostCard: Accident | null,
      memberId: string,
      isAccidentPostCardEditable: () => boolean,
      isEdit: boolean,
      isPortalUser: boolean,
      employerId: string,
    },
    private fb: FormBuilder,
    private store: Store<AccidentParticipantsState>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.isPortalUser = this.data.isPortalUser;
    this.accidentDateMax = new Date();
    this.receiveDateMax = new Date();

    this.initFormGroup();

    this.getEmployerInfoForPostCard();
    this.registerGetEmployerForPostCardSelector();

    this.loadListMunicipality();
    this.registerLoadListMunicipalitySelector();

    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  initFormGroup() {
    const accidentPostCard = this.data?.accidentPostCard;
    if (accidentPostCard) {
      this.isEdit = true;
    }
    this.editForm = this.fb.group({
      id: accidentPostCard?.accidentId ?? '',
      accidentDate: this.fb.control(accidentPostCard?.accidentDate ?? this.accidentDateMax, [Validators.required, this.validateAccidentDate()]),
      receiveDate: this.isPortalUser ? this.fb.control(accidentPostCard?.receiveDate ?? new Date()) : this.fb.control(accidentPostCard?.receiveDate ?? this.receiveDateMax, [Validators.required, this.validateReceiveDate()]),
      municipalityId: this.fb.control(accidentPostCard?.municipalityId ?? '', {
        validators: [Validators.required],
      }),
      injuryCode: this.fb.control(this.isEdit ? (accidentPostCard?.injuryCode ?? '') : null),
      injuryDescription: this.fb.control(accidentPostCard?.injuryDescription ?? '', {
        validators: [Validators.required],
      }),
      submitterName: this.fb.control(accidentPostCard?.submitterName ?? ''),
      submitterEmail: this.fb.control(accidentPostCard?.submitterEmail ?? ''),
      submitterPhone: this.fb.control(accidentPostCard?.submitterPhone ?? ''),
    });
    this.snapShotAccidentDate = this.accidentDateControl.value;

    this.accidentDateControl?.valueChanges
      .subscribe(() => {
        this.receiveDateControl?.updateValueAndValidity({ emitEvent: false });
        this.receiveDateControl?.markAsTouched();
      });
    this.receiveDateControl?.valueChanges
      .subscribe(() => {
        this.accidentDateControl?.updateValueAndValidity({ emitEvent: false });
        this.accidentDateControl?.markAsTouched();
      });
  }

  loadListMunicipality() {
    setTimeout(() => this.isFormLoading = true, 0);
    const accidentDate = this.datePipe.transform(this.accidentDateControl?.value?.toString(), 'yyyy-MM-dd') ?? '';
    this.store.dispatch(
      getListMunicipalityAction({
        memberId: this.data.memberId,
        accidentDate: accidentDate
      })
    );
  }

  registerLoadListMunicipalitySelector() {
    this.store.pipe(select(getMunicipalitySelector)).subscribe((data) => {
      if (data) {
        this.isFormLoading = false;
        if (data?.success === true) {
          const muniList = data?.payload ?? [];
          this.isMunicipalityError = !(muniList?.length > 0 && muniList?.some(item => item?.municipalityId === this.data.employerId));
          this.snapShotAccidentDate = this.accidentDateControl.value;
          this.snapShotMuniError = this.isMunicipalityError;
        }

        if (data?.success === false) {
          this.accidentDateControl.setValue(this.snapShotAccidentDate);
          this.isMunicipalityError = this.snapShotMuniError;
        }
        this.store.dispatch(clearGetListMunicipalityStateAction());
      }
    });
  }

  get municipalityControl(): FormControl {
    return this.editForm?.get('municipalityId') as FormControl;
  }

  get accidentDateControl(): FormControl {
    return this.editForm?.get('accidentDate') as FormControl;
  }

  get receiveDateControl(): FormControl {
    return this.editForm?.get('receiveDate') as FormControl;
  }

  validateAccidentDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.showErrorCompareDate() ? { errorMessage: '' } : null;
    };
  }

  validateReceiveDate(): ValidatorFn {
    return (): ValidationErrors | null => {
      return this.showErrorCompareDate() ? { errorMessage: '' } : null;
    };
  }

  onChangeAccidentDate() {
    if (this.accidentDateControl?.value && this.accidentDateControl.valid) {
      this.loadListMunicipality();
    }
  }

  showErrorCompareDate() {
    if (this.accidentDateControl?.value && new Date(this.accidentDateControl?.value) <= new Date(this.accidentDateMax)
        && this.receiveDateControl?.value && new Date(this.accidentDateControl?.value) <= new Date(this.receiveDateMax)
        && this.compareAccidentReceiveDate()) {
      this.isShowError = true;
      return true;
    } else {
      this.isShowError = false;
    }
    return false;
  }

  compareAccidentReceiveDate() {
    const accidentDate = this.accidentDateControl?.value;
    const receiveDate = this.receiveDateControl?.value;
    return new Date(accidentDate) > new Date(receiveDate);
  }

  onSubmit() {
    if (this.isMunicipalityError) return;
    if (this.data?.isEdit === true && this.data?.isAccidentPostCardEditable() === false) {
      this.dialogRef.close();
      return;
    }
    const accidentPostCard = JSON.parse(
      JSON.stringify(this.editForm.value as AccidentPostCardRequest)
    );
    accidentPostCard.accidentDate = this.datePipe.transform(accidentPostCard.accidentDate, 'yyyy-MM-dd');
    accidentPostCard.municipalityId = this.editForm?.get('municipalityId')?.value;
    accidentPostCard.receiveDate = this.datePipe.transform(accidentPostCard.receiveDate ?? new Date(), 'yyyy-MM-dd');
    accidentPostCard.clientDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if (accidentPostCard.id) {
      this.store.dispatch(
        editAccidentPostCardAction({
          memberId: this.data.memberId,
          accidentPostCardRequest: accidentPostCard,
        })
      );
    } else {
      this.store.dispatch(
        addAccidentPostCardAction({
          memberId: this.data.memberId,
          accidentPostCardRequest: accidentPostCard,
        })
      );
    }
    this.dialogRef.close();
  }

  getEmployerInfoForPostCard() {
    if (this.data.employerId) {
      this.store.dispatch(
        getEmployerInfoForPostCardAction({
          employerId: this.data.employerId,
        })
      );
    }
  }

  registerGetEmployerForPostCardSelector() {
    this.store.pipe(select(getEmployerInfoForPostCardSelector)).subscribe((data) => {
      if (data) {
        this.isFormLoading = false;
        if (data.success === true) {
          const employerName = data?.payload?.name ?? '';
          const employerCode = data?.payload?.code;
          this.listMunicipality = [
            {
              value: this.data.employerId,
              displayValue: `${employerCode} - ${employerName}`,
              iconConfig: { icon: 'business', iconFirst: true }
            }
          ];
          this.municipalityControl.setValue(this.data.employerId);
        }
        this.store.dispatch(clearGetEmployerInfoForPostCardStateAction());
      }
    });
  }
}
