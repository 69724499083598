import { createAction, props } from '@ngrx/store';
import {
  DocumentBodyRequest,
  GetDocumentListsRequest,
  GetDocumentListsResponse,
} from '@ptg-member/types/models/member-document.model';
import {
  GetGeneratedReportsInformationRequest,
  GetGeneratedReportsInformationResponse,
} from 'src/app/admin/features/file/services/models/documents.model';

export const getDocumentListsAction = createAction(
  '[GetDocumentLists/API] Send Request',
  props<{ request: GetDocumentListsRequest }>(),
);

export const getDocumentListsSuccessAction = createAction(
  '[GetDocumentLists/API] Success',
  props<{ response: GetDocumentListsResponse }>(),
);

export const getDocumentListsFailureAction = createAction('[GetDocumentLists/API] Failure', props<{ error?: any }>());

export const clearGetDocumentListsStateAction = createAction('[GetDocumentLists] Clear');

export const uploadDocumentsAction = createAction('[uploadDocuments/API] Send Request', props<{ body: any }>());

export const uploadDocumentsSuccessAction = createAction('[uploadDocuments/API] Success');

export const uploadDocumentsFailureAction = createAction('[uploadDocuments/API] Failure', props<{ error?: any }>());

export const clearUploadDocumentsStateAction = createAction('[uploadDocuments] Clear');

export const editDocumentsAction = createAction(
  '[editDocuments/API] Send Request',
  props<{ memberId: string; fileId: string; body: any }>(),
);

export const editDocumentsSuccessAction = createAction('[editDocuments/API] Success');

export const editDocumentsFailureAction = createAction('[editDocuments/API] Failure', props<{ error?: any }>());

export const clearEditDocumentsStateAction = createAction('[editDocuments] Clear');

export const removeDocumentsAction = createAction(
  '[RemoveDocuments/API] Send Request',
  props<{ body: DocumentBodyRequest }>(),
);

export const removeDocumentsSuccessAction = createAction('[RemoveDocuments/API] Success');

export const removeDocumentsFailureAction = createAction('[RemoveDocuments/API] Failure', props<{ error?: any }>());

export const clearRemoveDocumentsStateAction = createAction('[RemoveDocuments] Clear');

export const checkMemberGeneratingDocumentAction = createAction(
  '[CheckMemberGeneratingDocument/API] Send Request',
  props<{ request: GetGeneratedReportsInformationRequest }>(),
);

export const checkMemberGeneratingDocumentSuccessAction = createAction(
  '[CheckMemberGeneratingDocument/API] Success',
  props<{ response: GetGeneratedReportsInformationResponse }>(),
);

export const checkMemberGeneratingDocumentFailureAction = createAction(
  '[CheckMemberGeneratingDocument/API] Failure',
  props<{ error?: any }>(),
);

export const clearCheckMemberGeneratingDocumentAction = createAction('[CheckMemberGeneratingDocument] Clear');
//navigate to document list
export const navigateToDocumentListsAction = createAction(
  '[NavigateToDocumentLists/API] Send Request',
  props<{ generateInformationId: string }>(),
);
export const clearNavigateToDocumentListsAction = createAction('[NavigateToDocumentLists] Clear');
