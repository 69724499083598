<div class="edit-property-entity-reference-dialog">
  <form id="formEditPropertyEntityReference" class="flex flex-col gap-2" [formGroup]="formGroup">
    <ptg-radio-button
      *ngIf="optionsEntity.length > 1"
      [controlField]="entityCtrl"
      [listOption]="optionsEntity"
      [isObject]="true"
      [isDisabled]="isLoading"
      (changeValue)="changeEntity($event)"
    ></ptg-radio-button>
    <div class="pt-3">
      <ptg-autocomplete
        #autoSearch
        label="Search"
        svgIcon="personSearch"
        noDataMsg="No data found."
        [searchContent]="searchContent"
        [isSelected]="(recordId && searchContent) || addToggleCtrl?.value || isNoPropertyConfig"
        [isDisabled]="addToggleCtrl?.value || isNoPropertyConfig"
        [data]="searchResultList"
        [columns]="searchResultColumns"
        [isLoading]="isSearching"
        [isError]="isSearchError"
        [searchClass]="'entity-search-panel'"
        [dialogClass]="'dialog-body'"
        [defaultSearchResult]="entityCtrl.value === ENTITY_PERSON_GUILD"
        (search)="onSearchResult($event)"
        (selectItem)="onSelectResult($event)"
        (valueChange)="onSearchInputValueChange($event)"
        (clickClear)="onClickClearSearchResult($event)"
        (onFocus)="onSearchFocus($event)"
      >
        <ng-template cellContentSearch let-row="row" let-searchContent="searchContent">
          <ng-container [ngSwitch]="row?.type">
            <span
              *ngSwitchCase="EntityPropertyType.Currency"
              [innerHTML]="row.value | numberLocalDecimal: '$' | customBold: searchContent"
            ></span>
            <span
              *ngSwitchCase="EntityPropertyType.Percentage"
              [innerHTML]="
                ((row.value | numberLocalDecimal: '') + (row.value | numberLocalDecimal: '') ? '%' : '')
                  | customBold: searchContent
              "
            ></span>
            <span
              *ngSwitchCase="EntityPropertyType.Date"
              [innerHTML]="row.value | date: 'MM/dd/yyyy' | customBold: searchContent"
            ></span>
            <span
              *ngSwitchCase="EntityPropertyType['Person Name']"
              [innerHTML]="row.value | personName | customBold: searchContent"
            ></span>
            <span
              *ngSwitchCase="EntityPropertyType.Address"
              [innerHTML]="row.value | address | customBold: searchContent"
            ></span>
            <span
              *ngSwitchCase="EntityPropertyType.Decimal"
              [innerHTML]="row.value | numberDecimal | customBold: searchContent"
            ></span>
            <span
              *ngSwitchCase="EntityPropertyType['Whole Number']"
              [innerHTML]="row.value | numberDecimal | customBold: searchContent"
            ></span>
            <span *ngSwitchCase="EntityPropertyType.Status" class="status-container">
              <div class="icon-status-container">
                <mat-icon
                  *ngIf="row?.value && row?.configs?.iconName"
                  class="icon-status"
                  [ngStyle]="{ color: row?.configs?.color }"
                >
                  {{ row?.configs?.iconName }}
                </mat-icon>
                <div [innerHTML]="row.value.status | customBold: searchContent"></div>
              </div>
            </span>
            <span
              *ngSwitchCase="EntityPropertyType.SSN"
              [innerHTML]="row.value | ssnNumber | customBold: searchContent"
            ></span>
            <ng-container *ngSwitchCase="EntityPropertyType.RichText">
              <ptg-view-rich-text
                [title]="row?.columnName"
                [content]="row.value"
                [defaultContent]="'-'"
              ></ptg-view-rich-text>
            </ng-container>
            <span
              *ngSwitchCase="EntityPropertyType['Date Time']"
              [innerHTML]="row?.value | date: 'MM/dd/yyyy hh:mm a' | customBold: searchContent"
            ></span>
            <span
              *ngSwitchCase="EntityPropertyType.Phone"
              [innerHTML]="row?.value | phoneNumber | customBold: searchContent"
            ></span>
            <span *ngSwitchCase="EntityPropertyType.Email" [innerHTML]="row?.value | customBold: searchContent"></span>
            <span *ngSwitchDefault [innerHTML]="row.value | customBold: searchContent"></span>
          </ng-container>
        </ng-template>
      </ptg-autocomplete>
    </div>
    <div class="pb-3">
      <ptg-toggle-button
        *ngIf="isShowAddNewToggle"
        [controlField]="addToggleCtrl"
        [disabled]="isNoPropertyConfig"
        label="Add New"
        (valueChange)="changeToggleAdd($event)"
      ></ptg-toggle-button>
      <p *ngIf="isNoPropertyConfig" class="error-message">
        Entity Initiation Configuration not yet configured. Please contact your administrator.
      </p>
    </div>
    <div [ngClass]="{ hidden: isLoading }" class="group-control">
      <div *ngIf="!isNoPropertyConfig">
        <div *ngFor="let f of controls" class="flex gap-4 flex-wrap">
          <ng-container [ngSwitch]="f.type">
            <div *ngSwitchCase="EntityPropertyType.Status" class="flex-1">
              <div *ngFor="let childForm of f.lstChildFormControl" class="mb-2">
                <ptg-select
                  [controlField]="childForm.formControl"
                  [placeholder]="childForm.label"
                  [listData]="childForm.key === 'status' ? childForm.lstOption || [] : listStatusEvent || []"
                  [isOptionObj]="true"
                  (changeOptionValue)="setStatus(childForm)"
                ></ptg-select>
              </div>
            </div>
            <div *ngSwitchCase="EntityPropertyType.Currency" class="flex-1">
              <ptg-textbox
                [controlField]="f.formControl"
                [hasLabel]="true"
                [placeholder]="f.label"
                [maxLength]="f.maxLength"
                [min]="f.min"
                [max]="f.max"
                [isRequired]="f.isRequired"
                inputType="Currency"
                [errorAsync]="f.label + ' already exists.'"
              ></ptg-textbox>
            </div>

            <div
              *ngSwitchCase="'EIN'"
              class="flex-1"
            >
              <ptg-textbox
                [controlField]="f.formControl"
                [placeholder]="f.label"
                [hasLabel]="true"
                [maxLength]="f.maxLength"
                [isRequired]="f.isRequired"
                [isShowIconMasked]="f?.configs?.masked == 'true'"
                [inputType]="'EIN'"
                [errorAsync]="f.label + ' already exists.'"
                [customError]="f?.configs?.unique === 'true' ? 'notUnique' : ''"
              ></ptg-textbox>
            </div>

            <div
              *ngSwitchCase="
                [
                  EntityPropertyType.Text,
                  EntityPropertyType.Email,
                  EntityPropertyType.Phone,
                  EntityPropertyType.SSN
                ].includes(f.type)
                  ? f.type
                  : ''
              "
              class="flex-1"
            >
              <ptg-textbox
                [controlField]="f.formControl"
                [placeholder]="f.label"
                [hasLabel]="true"
                [maxLength]="f.maxLength !== 'none' ? f.maxLength : '250'"
                [isRequired]="f.isRequired"
                [isShowIconMasked]="f?.configs?.masked == 'true'"
                [inputType]="EntityPropertyType[f.type]"
                [errorAsync]="f.label + ' already exists.'"
                [customError]="f?.configs?.unique === 'true' ? 'notUnique' : ''"
              ></ptg-textbox>
            </div>

            <div
              *ngSwitchCase="
                [EntityPropertyType['Whole Number'], EntityPropertyType.Decimal].includes(f.type) ? f.type : ''
              "
              class="flex-1"
            >
              <ptg-textbox
                [controlField]="f.formControl"
                [placeholder]="f.label"
                [hasLabel]="true"
                [mask]="
                  f.type === EntityPropertyType['Whole Number']
                    ? 'separator.0'
                    : f.fractionalLength
                      ? 'separator.' + f.fractionalLength
                      : 'separator'
                "
                [thousandSeparator]="','"
                [min]="f.min"
                [max]="f.max"
                inputType="Decimal"
                [isRequired]="f.isRequired"
                [errorAsync]="f.label + ' already exists.'"
                [customError]="f?.configs?.unique === 'true' ? 'notUnique' : ''"
              ></ptg-textbox>
            </div>

            <div *ngSwitchCase="EntityPropertyType.Date" class="flex-1">
              <ptg-datepicker
                [controlField]="f.formControl"
                [placeholder]="f.label"
                [isRequired]="f.isRequired"
                [minDate]="f.min"
                [maxDate]="f.max"
                [isValidation]="f.isValidation"
                [errorMinDate]="f.minMessage"
                [errorMaxDate]="f.maxMessage"
                [errorAsync]="f.label + ' already exists.'"
                customError="dateValidationError"
                (changeValue)="dateValueChange($event, f)"
              ></ptg-datepicker>
            </div>

                <div
                  *ngSwitchCase="EntityPropertyType['Person Name']"
                  class="flex flex-1 gap-4"
                >
                  <div
                    class="flex-1 person-control"
                    *ngFor="let childForm of f.lstChildFormControl"
                  >
                    <ng-container *ngIf="childForm.type === 'select'">
                      <ptg-select
                        [controlField]="childForm.formControl"
                        [placeholder]="childForm.label"
                        [listData]="childForm.lstOption || []"
                        [isOptionObj]="true"
                        [hasNoneValue]="!f.isRequired || !childForm.required"
                        [isRequired]="f.isRequired && childForm.required"
                      ></ptg-select>
                    </ng-container>
                    <ng-container *ngIf="childForm.type === 'text'">
                      <ptg-textbox
                        [controlField]="childForm.formControl"
                        [placeholder]="childForm.label"
                        [hasLabel]="true"
                        [maxLength]="childForm.maxLength"
                        [isRequired]="f.isRequired && childForm.required"
                      ></ptg-textbox>
                    </ng-container>
                  </div>
                </div>
                <ng-container *ngSwitchCase="EntityPropertyType.Address">
                  <h3 class="address-label">{{ f.label }} 
                    <span class="address-active" *ngIf="f?.isActive ">Active</span>
                  </h3>
                </ng-container>
                <div
                  *ngSwitchCase="EntityPropertyType.Address"
                  class="flex flex-1 gap-4 flex-wrap  grid-container"
                >
                  <div
                    class="flex-1"
                    [class]="childForm.key"
                    *ngFor="let childForm of f.lstChildFormControl; let i = index"
                  >
                    <div>
                      <ng-container *ngIf="childForm.type === 'select'">
                        <ptg-select
                          [controlField]="childForm.formControl"
                          [placeholder]="childForm.label"
                          [listData]="childForm.lstOption || []"
                          [isOptionObj]="true"
                          [hasNoneValue]="!f.isRequired || !childForm.required"
                          [isClearError]="(f.isRequired && childForm.required) || childForm?.isSetRequired"
                          [isRequired]="(f.isRequired && childForm.required) || childForm?.isSetRequired"
                          (changeOptionValue)="changeValueAddress(childForm, f?.lstChildFormControl, f)"
                        ></ptg-select>
                      </ng-container>
                      <ng-container *ngIf="childForm.type === 'text'">
                        <ng-container *ngIf="childForm.key === 'zipCode'">
                          <ptg-textbox
                            *ngIf="checkTextbox(f.lstChildFormControl)"
                            [controlField]="childForm.formControl"
                            [placeholder]="childForm.label"
                            [hasLabel]="true"
                            [maxLength]="childForm.maxLength"
                            [isRequired]="(f.isRequired && childForm.required) || childForm?.isSetRequired"
                            inputType="ZipCode"
                            (onOutFocus)="changeValueInputAddress(f)"
                          ></ptg-textbox>
                          <ptg-textbox
                            *ngIf="!checkTextbox(f.lstChildFormControl)"
                            [controlField]="childForm.formControl"
                            [placeholder]="childForm.label"
                            [hasLabel]="true"
                            [maxLength]="childForm.maxLength"
                            [isRequired]="(f.isRequired && childForm.required) || childForm?.isSetRequired"
                            (onOutFocus)="changeValueInputAddress(f)"
                          ></ptg-textbox>
                        </ng-container>
                        <ng-container *ngIf="childForm.key !== 'zipCode'">
                          <ptg-textbox
                            [controlField]="childForm.formControl"
                            [placeholder]="childForm.label"
                            [hasLabel]="true"
                            [maxLength]="childForm.maxLength"
                            [isRequired]="(f.isRequired && childForm.required) || childForm?.isSetRequired"
                            (onOutFocus)="changeValueInputAddress(f)"
                          ></ptg-textbox>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="childForm.type === 'date'">
                        <ptg-datepicker
                          [controlField]="childForm.formControl"
                          [placeholder]="childForm.label"
                          [isRequired]="childForm.key === 'effectFrom' ? (f.isRequired && childForm.required || childForm?.isSetRequired) : childForm.required"
                          [minDate]="MY_DATE.minDate"
                          [maxDate]="MY_DATE.maxDate"
                          [isValidation]="true"
                          customError="fromToValidation"
                          [errorAsync]="childForm?.messageError"
                          (changeValue)="dateEffectiveChange($event, f?.lstChildFormControl)"
                          (onOutFocus)="changeValueInputAddress(f)"
                        ></ptg-datepicker>
                      </ng-container>
                    </div>
                  </div>
                </div>

            <div
              *ngSwitchCase="
                [
                  EntityPropertyType.Lookup,
                  EntityPropertyType.Employer,
                  EntityPropertyType.Department,
                  EntityPropertyType.Tier
                ].includes(f.type)
                  ? f.type
                  : ''
              "
              class="flex-1"
            >
              <ptg-select
                [controlField]="f.formControl"
                [placeholder]="f.label"
                [listData]="f.lstOption"
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [hasNoneValue]="!f.isRequired"
                [isRequired]="f.isRequired"
                [isMultipleLineOption]="f.type === EntityPropertyType.Department"
              ></ptg-select>
            </div>

            <div *ngSwitchCase="EntityPropertyType.Binary" class="flex-1">
              <ptg-checkbox
                [controlField]="f.formControl"
                [label]="f.label"
                [customError]="f?.customError"
              ></ptg-checkbox>
            </div>

            <div *ngSwitchCase="EntityPropertyType.Percentage" class="flex-1">
              <ptg-textbox
                [controlField]="f.formControl"
                [placeholder]="f.label"
                [hasLabel]="true"
                [mask]="
                  f.type === EntityPropertyType['Whole Number']
                    ? 'separator.0'
                    : f.fractionalLength
                      ? 'separator.' + f.fractionalLength
                      : 'separator'
                "
                [thousandSeparator]="','"
                [min]="f.min"
                [max]="f.max"
                [isPercentage]="true"
                inputType="Decimal"
                [isRequired]="f.isRequired"
                [errorAsync]="f.label + ' already exists.'"
                [customError]="f?.configs?.unique === 'true' ? 'notUnique' : ''"
              ></ptg-textbox>
            </div>

            <div *ngSwitchCase="EntityPropertyType.RichText" class="flex-1 mb-4">
              <ptg-editor
                [controlField]="f.formControl"
                [placeholder]="f.label"
                [isRequired]="f.isRequired"
                [maxLength]="f.maxLength"
                [hasFloatLabel]="true"
              ></ptg-editor>
            </div>

            <div *ngSwitchCase="EntityPropertyType['Date Time']" class="flex-1">
              <ptg-datetime-picker
                [controlField]="f.formControl"
                [isRequired]="f.isRequired"
                [isDisabled]="f.formControl.disabled"
                [datePlaceholder]="'Date'"
                [timePlaceholder]="'Time'"
              ></ptg-datetime-picker>
            </div>

            <div *ngSwitchCase="EntityPropertyType.Identifier" class="flex-1">
              <ptg-textbox
                [controlField]="f.formControl"
                [placeholder]="f.label"
                [hasLabel]="true"
                [maxLength]="f.maxLength"
                [isRequired]="f.isRequired"
                [inputType]="EntityPropertyType[f.type]"
                [errorAsync]="f.label + ' already exists.'"
                [customError]="f?.configs?.unique === 'true' ? 'notUnique' : ''"
              ></ptg-textbox>
            </div>

            <div *ngSwitchDefault class="flex-1">
              <!-- TODO Replace EntityPropertyType[f.type] by f.type and Replace InputType by EntityPropertyType insde ptg-textbox  -->
              <ptg-textbox
                [controlField]="f.formControl"
                [placeholder]="f.label"
                [hasLabel]="true"
                [maxLength]="f.maxLength"
                [isRequired]="f.isRequired"
                [inputType]="EntityPropertyType[f.type]"
                [errorAsync]="f.label + ' already exists.'"
                [customError]="f?.configs?.unique === 'true' ? 'notUnique' : ''"
              ></ptg-textbox>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="isLoading">
      <ptg-spinner [isLoading]="true"></ptg-spinner>
    </div>
  </form>
</div>