import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import {
  RelatedPersonList,
  GetListRelatedPersonQuery,
  MemberStatusList,
  CreateMemberStatusRequest,
  EditMemberStatusRequest,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class RelatedPersonService {
  constructor(private httpClient: HttpClient) {}

  getRelatedPersonList(
    query: GetListRelatedPersonQuery
  ): Observable<RelatedPersonList> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<RelatedPersonList>(
      `${environment.apiUrl}/member-portal/related-person`,
      { params }
    );
  }

  getMemberStatusList(): Observable<MemberStatusList> {
    return this.httpClient.get<MemberStatusList>(
      `${environment.apiUrl}/Members/GetMemberStatusEvent`
    );
  }

  createMemberStatus(body: CreateMemberStatusRequest) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/CreateMemberRelatedPerson`,
      body
    );
  }

  editMemberStatus(body: EditMemberStatusRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/SetMemberRelatedPerson`,
      body
    );
  }
}
