<mat-radio-group *ngIf="!isObject; else objectOption" [formControl]="controlField">
  <ng-container *ngIf="!isObject; else objectOption">
    <mat-radio-button (change)="onChangeValue($event)" *ngFor="let option of $any(listOption)"
                      [value]="option">{{option}}</mat-radio-button>
  </ng-container>
</mat-radio-group>

<ng-template #objectOption>
  <mat-radio-group [formControl]="controlField">
    <ng-container>
      <mat-radio-button (change)="onChangeValue($event)" *ngFor="let option of $any(listOption)"
                        [value]="option.value">{{ option.label }}</mat-radio-button>
    </ng-container>
  </mat-radio-group>
</ng-template>
