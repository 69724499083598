import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SortType } from '@ptg-shared/constance';

import { environment } from 'src/environments/environment';
import { GetListRequest, GetSubHeaderConfigurationsListResponse, GetSubHeaderDataResponse, SetSubHeaderConfigurationDetailRequest, SubHeaderConfigurationDetailResponse } from './models/subheader-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class SubHeaderConfigurationService {
  constructor(private readonly httpClient: HttpClient) {}

  getSubHeaderConfigurationsList(memberId: string, request: GetListRequest): Observable<GetSubHeaderConfigurationsListResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetSubHeaderConfigurationsListResponse>(
      `${environment.apiUrl}/members/${memberId}/sub-headers`,
      { params },
    );
  }

  getSubHeaderConfigurationDetail(memberId: string, subHeaderId: string): Observable<SubHeaderConfigurationDetailResponse> {
    return this.httpClient.get<SubHeaderConfigurationDetailResponse>(
      `${environment.apiUrl}/members/${memberId}/sub-headers/${subHeaderId}`,
    );
  }

  setSubHeaderConfigurationDetail(
    memberId: string,
    subHeadersId: string,
    request: SetSubHeaderConfigurationDetailRequest,
  ): Observable<any> {
    return this.httpClient.put(
      `${environment.apiUrl}/members/${memberId}/sub-headers/${subHeadersId}`,
      request,
    );
  }

  getSubHeaderData(memberId: string): Observable<GetSubHeaderDataResponse> {
    return this.httpClient.get<GetSubHeaderDataResponse>(
      `${environment.apiUrl}/member-portal/${memberId}/sub-headers/data`,
    );
  }
}
