import { Component, Inject, OnInit } from '@angular/core';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { EntityType } from '../../types/enums/entity-type.enum';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@ptg-shared/components';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE, SortType, STATE } from '@ptg-shared/constance';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone } from '@ptg-shared/utils/common.util';
import { getTagListAction } from 'src/app/admin/features/file/store/actions';
import { DocumentsState, TagsState } from 'src/app/admin/features/file/store/reducers';
import { select, Store } from '@ngrx/store';
import { generateDocumentSelector, getTagListSelector } from 'src/app/admin/features/file/store/selectors';

@Component({
  selector: 'ptg-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent extends BaseComponent implements OnInit {
  readonly EntityType = EntityType;
  listBreadcrumbs: Breadcrumb[] = [];
  entityType!: EntityType;
  targetId?: string;
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  filteredByTypingTagOptions!: Observable<Option[]>;
  currentTagsChipValue!: Option;
  currentTagsChipValueList: string[] = [];
  listOptionTag: Option[] = [];
  isGetTagListLoading: boolean = true;
  templateName = '';
  fileTypeName = '';
  documentPreviewUrl = '';
  isLoading = false;

  constructor(
    private readonly fb: FormBuilder,
    private tagStore: Store<TagsState>,
    private documentStore: Store<DocumentsState>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DocumentPreviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentEntity: any;
      document: any;
      documentForm: any;
      isCustomParentUrl?: boolean;
    },
  ) {
    super();
  }

  get tagsAutoCompleteControl(): FormControl {
    return this.editForm?.get('tags') as FormControl;
  }

  get tagsChipListControl(): FormArray {
    return this.editForm?.get('tagsChipList') as FormArray;
  }

  getBreadcrumbs(entityType: EntityType, entityId: string): Breadcrumb[] {
    const url =
      entityType == EntityType.Employer
        ? `/employer/individual-document/${entityId}`
        : `/member/individual-document/${entityId}`;
    return [
      {
        name: this.data.isCustomParentUrl ? this.data.documentForm?.documentLocationTitle : 'Document List',
        url: this.data.isCustomParentUrl ? this.data.documentForm?.documentLocationRouter : url,
      },
      { name: `${this.templateName} Preview` },
    ];
  }

  ngOnInit(): void {
    this.registerGenerateDocument();
    this.getTagList();
    this.selectDataTagList();
    this.onInitForm();
    this.submitForm();
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onInitForm() {
    const { document, currentEntity, documentForm } = this.data;

    this.templateName = document.templateName;
    this.fileTypeName = document.fileTypeName;
    this.currentTagsChipValueList.push(...documentForm.tagsId?.map((item: any) => item));

    this.editForm = this.fb.group({
      tags: this.fb.control(''),
      tagsChipList: this.fb.array(document?.tagsChipList ?? []),
    });
    this.entityType = currentEntity.entityType;
    this.listBreadcrumbs = this.getBreadcrumbs(currentEntity.entityType, currentEntity.entityId);
    this.targetId = currentEntity.entityId;
  }

  onClickBreadcrumb() {
    this.dialogRef.close();
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onSubmit() {
    const objGenerate = { ...this.data.documentForm };
    objGenerate['tags'] = this.currentTagsChipValueList;
    this.dialogRef.close(objGenerate);
  }

  validateTags(): void {
    if (this.tagsAutoCompleteControl.value) {
      if (
        (this.tagsAutoCompleteControl.enabled && typeof this.tagsAutoCompleteControl.value === 'string') ||
        this.currentTagsChipValueList?.includes(this.tagsAutoCompleteControl.value.value)
      ) {
        this.tagsAutoCompleteControl.setErrors({ inValidAsync: true });
        return;
      }
    }
  }

  tagsFocus(event: any) {
    event.stopPropagation();
    this.currentTagsChipValue = deepClone(this.tagsAutoCompleteControl?.value);
    this.filteredByTypingTagOptions = this.tagsAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.listOptionTag.filter(
          (item) =>
            item?.displayValue?.toLowerCase()?.includes((event.target.value as string).toLowerCase()) &&
            !this.currentTagsChipValueList?.includes(item?.value),
        ),
      ),
    );
  }

  displayTagFn(value: Option): string {
    return value ? value.displayValue : '';
  }

  onAddNewChip() {
    if (!this.tagsAutoCompleteControl.value || this.tagsAutoCompleteControl.invalid) {
      this.tagsAutoCompleteControl.markAsTouched();
      return;
    }

    const chip = this.currentTagsChipValue;
    this.tagsAutoCompleteControl.reset('');
    const chipControl = new FormControl(chip);

    this.tagsChipListControl.push(chipControl);

    if (!this.currentTagsChipValueList.includes(chip.value)) {
      this.currentTagsChipValueList.push(chip.value);
    }
  }

  onRemoveChip(value: string, index: number) {
    if (!value) return;
    this.currentTagsChipValueList.splice(index, 1);
    this.tagsChipListControl.removeAt(index);
  }

  getTagList() {
    let sortType = SortType.ASC;
    let sortNames = 'Name';
    this.tagStore.dispatch(
      getTagListAction({
        request: {
          sortNames,
          sortType,
        },
      }),
    );
  }

  selectDataTagList() {
    this.tagStore.pipe(select(getTagListSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isGetTagListLoading = false;
        if (data?.success && data?.payload?.length) {
          this.listOptionTag = data?.payload.map(
            (item) =>
              ({
                value: item.id,
                displayValue: item.name,
              }) as Option,
          );
        }
      }
    });
  }

  registerGenerateDocument() {
    this.documentStore.pipe(select(generateDocumentSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isLoading = data.isLoading;
        if (data.state === STATE.SUCCESS && data.filePreview) {
          this.documentPreviewUrl = data.filePreview;
        }
      }
    });
  }
}
