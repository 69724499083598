import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { BaseComponent } from '@ptg-shared/components';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { EmployerActions } from '../../actions';
import {
  EmployerPortalUser,
  EmployerSection,
} from '../../models/employer.model';
import * as fromEmployer from '../../reducers';
import * as fromAccessMng from '../../../access-management/reducers';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { EmployerService } from '../../services/employer.service';
import * as RolesActions from '../../../access-management/actions/roles.actions';
import * as fromReducer from '@ptg-reducers/index';
import { selectEmployerCheckSectionActionState } from '../../reducers';

@Component({
  selector: 'ptg-add-employer-portal-user',
  templateUrl: './add-employer-portal-user.component.html',
  styleUrls: ['./add-employer-portal-user.component.scss'],
})
export class AddEmployerPortalUserComponent
  extends BaseComponent
  implements OnInit
{
  editForm!: FormGroup;
  listSystemRole: { displayValue?: string; value: string; id?: string }[] = [];
  formSubmit$ = new Subject<boolean>();
  isEdit = false;
  portalUserGlobal: EmployerPortalUser | undefined;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEmployerPortalUserComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      portalUser: EmployerPortalUser | null;
      configSection: EmployerSection;
      employerId: string;
    },
    private fb: FormBuilder,
    private store: Store<fromReducer.State>,
    private employerStore: Store<fromEmployer.EmployerState>,
    private accessMngStore: Store<fromAccessMng.AccessMngState>,
    private employerService: EmployerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFormGroup();
    // TODO: Get from store
    this.accessMngStore.dispatch(
      RolesActions.getRolesByPortalType({
        query: { IsForPortalType: true },
      })
    );

    this.accessMngStore
      .pipe(
        select(fromAccessMng.selectRolesByPortalTypeState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.listSystemRole = el.rolesData.roles.map((el) => {
          return {
            value: el.id,
            displayValue: el.name,
          };
        });

        if (this.data.portalUser && this.data.portalUser.systemRoleId) {
          var itemSelect = this.listSystemRole.find(
            (item) => item.value === this.data.portalUser?.systemRoleId
          );
          if (itemSelect) {
            this.listSystemRole.unshift({
              displayValue: itemSelect?.displayValue,
              value: this.data.portalUser?.systemRoleId,
            });
          }
        }
      });

    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  tranformData(obj: any, includesCode?: boolean) {
    return {
      displayValue: includesCode
        ? `${obj?.code} - ${obj?.description}`
        : obj?.description,
      value: obj?.code,
      id: obj?.id,
    };
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  initFormGroup() {
    const portalUser = this.data?.portalUser;
    if (portalUser) {
      this.isEdit = true;
      this.portalUserGlobal = portalUser ?? {};
    }
    this.editForm = this.fb.group({
      id: portalUser?.id ?? '',
      isInactive: !!portalUser?.isInactive,
      firstName: this.fb.control(portalUser?.firstName ?? '', [
        Validators.required,
        Validators.maxLength(80),
      ]),
      lastName: this.fb.control(portalUser?.lastName ?? '', [
        Validators.required,
        Validators.maxLength(80),
      ]),
      email: this.fb.control(portalUser?.email ?? '', {
        asyncValidators: checkApiValidator(
          this.employerService.checkEmailExists,
          'email',
          portalUser?.email ? portalUser?.email : '',
          { params: { employerId: this.data?.employerId } }
        ),
      }),
      systemRoleId: this.fb.control(portalUser?.systemRoleId ?? ''),
    });
  }

  onSubmit() {
    const employerPortalUser = JSON.parse(
      JSON.stringify(this.editForm.value as EmployerPortalUser)
    );
    if (employerPortalUser.id) {
      this.employerStore.dispatch(
        EmployerActions.updateEmployerPortalUser({
          employerId: this.data.employerId,
          employerPortalUser: employerPortalUser,
        })
      );
      this.dialogRef.close();
    } else {
      const body = {email: this.editForm.get('email')?.value, employerId: this.data?.employerId ?? ''};
      this.employerStore.dispatch(EmployerActions.checkEmployerPortalUser({body}));
      const checkEmail$ = this.employerStore.pipe(
        select(selectEmployerCheckSectionActionState),
        takeUntil(this.unsubscribe$)
        ).subscribe(el => {
        if (el && !el.isLoading && el.otherExists) {
          const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: { 
              text: 'This email is already existed as Portal User in other Municipalities. Are you sure to add this user?',
              type: ConfirmType.Attention,
              title: 'Attention'
            },
          });
      
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.dispatchSubmit(employerPortalUser);
            }
            this.employerStore.dispatch(EmployerActions.clearCheckEmployerPortalUser());
            checkEmail$.unsubscribe();
          });
        } else if (el && !el.isLoading && !el.otherExists) {
          this.dispatchSubmit(employerPortalUser);
          this.employerStore.dispatch(EmployerActions.clearCheckEmployerPortalUser());
          checkEmail$.unsubscribe();
        }
      });
    }
  }

  dispatchSubmit(employerPortalUser: EmployerPortalUser) {
    this.employerStore.dispatch(
      EmployerActions.addEmployerPortalUser({
        employerId: this.data.employerId,
        employerPortalUser: employerPortalUser,
      })
    );
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
      super.ngOnDestroy();
  }
}
