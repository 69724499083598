import { Component, Inject, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';

import {
  AlternatePayee,
  CalculationStep,
  CalculationStepProperty,
  SetBenefitDetailParams,
  SetBenefitDetailProperties,
  SetBenefitDetailRequest,
} from '@ptg-member/features/calculation/services/models';
import { CalculationBenefitDetailType } from '@ptg-member/features/calculation/services/models/retirement-benefit-detail.model';
import {
  BenefitDocumentsItem,
  RetirementBenefitDataInput,
} from '@ptg-member/features/calculation/services/models/retirement-benefit.model';
import {
  clearCreateRetirementBenefitDialogsStateAction,
  clearSetBenefitDetailStateAction,
  createRetirementBenefitAction,
  getDetectRecalculationBenefitAction,
  getSetBenefitDetailSelector,
  getStepConfigurationDetailByBenefitOptionAction,
  setBenefitDetailAction,
} from '@ptg-member/features/calculation/store';
import {
  clearBenefitTypeStateAction,
  clearGetDetectRecalculationBenefitStateAction,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  clearGetStepConfigurationDetailByBenefitOptionStateAction,
  clearGetValidationExceptionConfigurationStateAction,
  getBenefitTypesAction,
  getValidationExceptionConfigurationAction,
  getAlternatePayeesAction,
  clearGetAlternatePayeesStateAction,
  checkStepConfigurationExistsAction,
  clearCheckStepConfigurationExistsStateAction,
} from '@ptg-member/features/calculation/store/actions';
import { CalculationState, StepConfigurationState } from '@ptg-member/features/calculation/store/reducers';
import {
  getAlternatePayeesSelector,
  getBenefitTypesSelector,
  getCheckStepConfigurationExistsSelector,
  getRetirementBenefitDownloadDocumentSelector,
  getValidationExceptionConfigurationSelector,
} from '@ptg-member/features/calculation/store/selectors';
import {
  CalculationType,
  CheckConditionToProcessCalculationBenefitErrorType,
  ExceptionType,
  StepPropertiesType,
} from '@ptg-member/features/calculation/types/enums';

import { BaseComponent } from '@ptg-shared/components';
import { ACTION, CANCEL_CONFIRM_MESSAGE, DEFAULT_PAGE_SIZE, SortType, STATE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { InputType } from '@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum';
import { Column } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { UploadComponent } from '@ptg-shared/controls/upload/upload.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';

import { FundType } from '@ptg-shared/types/enums';
import { PropertyType } from '@ptg-shared/types/enums/entity.enum';
import { FormControlDetail } from '@ptg-shared/types/models';
import {
  deepClone,
  downloadFile,
  parseDateToSubmit,
  showBanner,
  showCancelDialog,
} from '@ptg-shared/utils/common.util';
import { isEmpty, localeCompare, stringToBoolean } from '@ptg-shared/utils/string.util';

import { DateTime } from 'luxon';

import { Observable, of, timer } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { GRID_COLUMN_INIT_BENEFIT_DOCUMENT } from '../../benefit-overview.constants';
import { BENEFIT_OPTION_FIELD, CALCULATION_OPTION, UPLOAD_DOCUMENT_RADIO_LIST } from '../../constants';
import {
  CreateRetirementBenefitRequest,
  GetDetectRecalculationBenefitResponse,
  UploadFileInfo,
} from '../../services/models/retirement-benefit-dialog.model';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';
import {
  getDetectRecalculationBenefitSelector,
  getRetirementBenefitDialogSelector,
  stepConfigurationDetailByBenefitOptionSelector,
} from '../../store/selectors/retirement-benefit-dialog.selectors';
import { RetirementBenefitDialogComponentService } from './retirement-benefit-dialog.component.service';
import { DocumentsState, TagsState } from 'src/app/admin/features/file/store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  clearTagDetailStateAction,
  getDocumentDownloadAction,
  getTagListAction,
} from 'src/app/admin/features/file/store/actions';
import { addTagListSelector, getTagListSelector } from 'src/app/admin/features/file/store/selectors';
import { AddTagComponent } from 'src/app/admin/features/file/components/add-tag/add-tag.component';
import { DOCUMENT_LOCATION } from '@ptg-shared/constance/document-location.const';
import { BenefitDetail, GetValidationExceptionRequest } from '../../services/models/exception-configuration.model';
import { validationData } from '../component.util';

@Component({
  selector: 'ptg-retirement-benefit-dialog',
  templateUrl: './retirement-benefit-dialog.component.html',
  styleUrls: ['./retirement-benefit-dialog.component.scss'],
  providers: [RetirementBenefitDialogComponentService],
})
export class RetirementBenefitDialogComponent extends BaseComponent {
  @ViewChild('fileDocument')
  private fileDocument!: UploadComponent;

  pageNumber = FIRST_PAGE;
  pageSize = DEFAULT_PAGE_SIZE;

  benefitOptions: Option[] = [];
  existDocumentList: Option[] = [];

  private formData: FormGroup = this.initForm;
  selectDocumentFormGroup: FormGroup = this.initSelectDocumentFormGroup;

  readonly newDocumentOptions: RadioOption[] = UPLOAD_DOCUMENT_RADIO_LIST;

  acceptFile = 'application/pdf';
  supportedFileTypes = 'pdf file format';
  checkPattern = new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i');
  selectedDocument?: BenefitDocumentsItem;

  stepOptionByPass = false;
  private calculationSteps: CalculationStep[] = [];
  private calculationStepProperties: CalculationStepProperty[] = [];

  private sectionLabel = '';
  dialogTitle = 'Retirement Options';
  sortInfo: Sort = { active: '', direction: 'asc' };
  documentColumns: Column[] = GRID_COLUMN_INIT_BENEFIT_DOCUMENT;
  documents: UploadFileInfo[] = [];
  filteredDocuments: UploadFileInfo[] = [];
  calculationDocumentTypeOptions: Option[] = [];
  propertyValueFromPriorStepConfigs: FormControlDetail[] = [];

  private isBenefitOptionChangedOnEdit = false;

  message = '';
  bannerType: BannerType = BannerType.Hidden;
  isSurvivor = [
    CalculationType.Survivor,
    CalculationType.JointSurvivor,
    CalculationType.LODDSurvivor,
    CalculationType.LODDDeath,
  ].includes(this.data.calculationType);

  isLoading = true;
  alternatePayeeLoading = true;
  showCustomDocumentErrMsg = false;

  isBVFFFund = this.layoutService.fundType === FundType.BVFF;
  isChicagoParks = this.layoutService.fundType === FundType.ChicagoParks;

  detectRecalculationBenefitInfo?: GetDetectRecalculationBenefitResponse;

  downloadFileName: string = '';
  file?: File;

  filteredTagOptions!: Observable<Option[]>;
  isGetTagListLoading: boolean = true;
  filteredByTypingTagOptions!: Observable<Option[]>;
  currentTagsChipValue!: Option;
  currentTagsChipValueList: string[] = [];
  listOptionTag: Option[] = [];
  isNewTagAddedSuccess: boolean = false;
  labelShowOverview: string = 'Show on Overview'; // @-> US 102907, use `Show in Overview` instead

  calculateAsOfDateValue?: string;
  recalculationReasonValue: string = '';
  validationExceptionInfo: BenefitDetail[] = [];
  calcAsOfDateInfo: any;

  isRetirement = this.data.calculationType === CalculationType.RetirementBenefit;
  isQDRO = this.data.calculationType === CalculationType.QDRO;
  isJointSurvivor = this.data.calculationType === CalculationType.JointSurvivor;

  alternatePayees: AlternatePayee[] = [];
  optionAlterPayee: Option[] = [];
  selectedAlternatePayee?: AlternatePayee;

  enableFieldsInJointSurvivorCase: string[] = ['calculationasofdate', 'recalculationreason'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: RetirementBenefitDataInput,
    public readonly dialog: MatDialog,
    public readonly calculationStore: Store<CalculationState>,
    public readonly switchConfirmPopupService: SwitchConfirmPopupService,
    public readonly stepConfigurationsStore: Store<StepConfigurationState>,
    private documentStore: Store<DocumentsState>,
    public readonly dialogRef: MatDialogRef<RetirementBenefitDialogComponent>,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly layoutService: LayoutService,
    private readonly retirementBenefitDialogService: RetirementBenefitDialogService,
    private readonly retirementBenefitDialogComponentService: RetirementBenefitDialogComponentService,
    public tagStore: Store<TagsState>,
  ) {
    super();
  }

  get benefitOptionControl(): FormControl {
    return this.formData?.get('benefitEntityId') as FormControl;
  }

  get isNewDocumentControl(): FormControl {
    return this.selectDocumentFormGroup?.get('isNewDocument') as FormControl;
  }

  get idControl(): FormControl {
    return this.selectDocumentFormGroup?.get('id') as FormControl;
  }

  get nameControl(): FormControl {
    return this.selectDocumentFormGroup.get('name') as FormControl;
  }

  get documentTypeControl(): FormControl {
    return this.selectDocumentFormGroup.get('documentType') as FormControl;
  }

  get fileControl(): FormControl {
    return this.selectDocumentFormGroup.get('file') as FormControl;
  }

  get propertyValueFromPriorStepsFormArray(): FormArray {
    return this.formData.get('propertyValueFromPriorSteps') as FormArray;
  }

  get benefitBeginDateControl(): FormControl {
    return this.formData.get('benefitBeginDate') as FormControl;
  }

  get tagsChipListControl(): FormArray {
    return this.selectDocumentFormGroup?.get('tagsChipList') as FormArray;
  }

  get tagsAutoCompleteControl(): FormControl {
    return this.selectDocumentFormGroup?.get('tags') as FormControl;
  }

  get descriptionInputAreaControl(): FormControl {
    return this.selectDocumentFormGroup?.get('description') as FormControl;
  }

  get showOnOverviewCheckboxControl(): FormControl {
    return this.selectDocumentFormGroup?.get('showOnOverview') as FormControl;
  }

  get title(): string {
    const sectionLabel = this.getConfigurationByKey(1, CALCULATION_OPTION.StepLabelKey) || this.sectionLabel;
    const defaultTitle = sectionLabel ?? 'Retirement Options';
    return !this.data.isEdit ? defaultTitle : `Edit ${defaultTitle}`;
  }

  get alternatePayeeId(): FormControl {
    return this.formData.get('alternatePayeeId') as FormControl;
  }

  get isRecalculate(): boolean {
    return !!this.detectRecalculationBenefitInfo?.isRecalculate;
  }

  ngOnInit(): void {
    this.calculationDocumentTypeOptions = this.retirementBenefitDialogComponentService.getCalculationDocumentTypeList(
      this.layoutService.fundType as unknown as FundType,
    );

    this.selectValidationExceptionConfigState();
    this.getValidationExceptionConfigState();

    if (!this.data.isEdit) {
      this.selectInitBenefitCalculationState();
    } else {
      this.selectUpdateBenefitOptionSectionState();
      this.initDisplayData();
    }

    // Retirement benefit
    if (this.isRetirement) {
      this.selectGetRetirementBenefitOptionState();
      this.selectValidateBenefitOptionExistState();
      this.selectStepConfigurationByBenefitOptionState();
    }
    // Retirement benefit - Initiation
    if (this.isRetirement && !this.data.isEdit) {
      this.selectDetectRecalculationState();
      this.getDetectRecalculationState();
      this.getExistDocuments();

      this.getTagList();
      this.selectDataTagList();
      this.registerAddNewTagSelector();
      this.tagsAutoCompleteControl.disable();
    }

    // Survivor OR QDRO benefits
    if (this.isSurvivor || this.isQDRO) {
      this.getSingleBenefitEntityOption();
    }
    // QDRO benefit - Initiation
    if (this.isQDRO && !this.data.isEdit) {
      this.formData.setControl('alternatePayeeId', this.fb.control('', Validators.required));
      this.dialogTitle = this.data.benefitStepLabel ?? '';
      this.selectAlterPayeeState();
      this.getAlternatePayeeState();
      this.selectDetectRecalculationState();
    }
  }

  onNext(): void {
    // base on step config bypass step2 or not
    // if step2 is bypass then we will save data
    // else will next to step 2

    this.showCustomDocumentErrMsg = false;
    this.formData.markAllAsTouched();

    // If edit
    if (this.data.isEdit && this.formData.valid) {
      this.updateBenefitOptionDetail();
      return;
    }

    const isUploadNewDocument = !!this.isNewDocumentControl.value;
    const selectDocumentYet = !!this.documents?.length;

    // If no document selected
    if (this.isRetirement && !this.isChicagoParks && !selectDocumentYet) {
      this.showCustomDocumentErrMsg = true;

      // If the selected radio is Upload New Document
      if (isUploadNewDocument) {
        this.fileDocument.firstTouch = true; // prevent duplicate upload error message
        this.nameControl.markAllAsTouched();
        this.documentTypeControl.markAllAsTouched();
      }
      // If the selected radio is Select Existing Document
      else {
        this.idControl.markAllAsTouched();
      }
      return;
    }

    if (this.formData.invalid) {
      return;
    }

    const request = this.getFormDataRequest();
    // TODO: Implement later
    // if (!stringToBoolean(this.getConfigurationByKey(2, CALCULATION_OPTION.ByPass))) {
    //   this.openSecondStep(request);
    //   return;
    // }
    this.calculationStore.dispatch(createRetirementBenefitAction({ request }));
  }

  private getFormDataRequest(): CreateRetirementBenefitRequest {
    const formValue = this.formData.getRawValue();

    const files: UploadFileInfo[] = this.documents.map((item) => ({
      id: item.id,
      uploadDate: item.uploadDate ?? '',
      documentName: item.documentName ?? '',
      type: item.type,
      documentTypeName: item.documentTypeName,
      file: item.file,
      calculationBenefitId: item?.calculationBenefitId ?? '',
      tags: item.tags,
      fileName: item.file?.name,
      documentLocationTitle: DOCUMENT_LOCATION.RETIREMENT,
      documentLocationRouter: `/member/benefit-overview/${this.data.calculationType}/${this.data.memberId}`,
      documentDescription: item?.description,
      showOnOverview: item?.showOnOverview,
    }));

    let request: CreateRetirementBenefitRequest = {
      benefitEntityId: formValue.benefitEntityId,
      files,
      memberId: this.data.memberId,
      calculationType: this.data.calculationType,
      propertyValueFromPriorSteps: formValue.propertyValueFromPriorSteps.map((item: any) => {
        item.value = this.getPropertyValue(item.value, this.getPropertyType(item.type));
        return item;
      }),
      fundType: this.layoutService.fundType,
      alternatePayeeId: formValue.alternatePayeeId,
    };

    formValue.propertyValueFromPriorSteps.forEach((item: any) => {
      if (item.propertyLabel.toUpperCase() === BENEFIT_OPTION_FIELD.CalculationAsOfDateField.toUpperCase()) {
        this.calculateAsOfDateValue = item.value;
      }
      if (item.propertyLabel.toUpperCase() === BENEFIT_OPTION_FIELD.RecalculationReasonField.toUpperCase()) {
        this.recalculationReasonValue = item.value;
      }
      item.value = this.getPropertyValue(item.value, this.getPropertyType(item.type));
    });

    if (this.isRecalculate) {
      request = {
        ...request,
        recalculateModel: {
          calculateAsOfDate: this.calculateAsOfDateValue ? parseDateToSubmit(this.calculateAsOfDateValue) : '',
          recalculationReason: this.recalculationReasonValue ?? '',
        },
      };
    }

    return request;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onCancel() {
    showCancelDialog(this.dialog, this.dialogRef, CANCEL_CONFIRM_MESSAGE);
  }

  private getRetirementBenefitOptionList(): void {
    let requestType = 1;
    if (this.isChicagoParks && this.data.isEdit) {
      requestType = 0;
    }
    this.calculationStore.dispatch(getBenefitTypesAction({ request: { type: requestType } }));
  }

  private selectGetRetirementBenefitOptionState(): void {
    this.calculationStore
      .select(getBenefitTypesSelector)
      .pipe(
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.calculationStore.dispatch(clearBenefitTypeStateAction());

        this.benefitOptions = deepClone(state?.payload ?? [])
          ?.sort((a, b) => localeCompare(a?.name, b?.name))
          ?.map((item) => {
            return {
              value: item.id,
              displayValue: item.name,
            };
          });

        if (this.benefitOptions.length === 1) {
          this.benefitOptionControl.setValue(this.benefitOptions[0].value);

          // Auto select the only benefit option when processing Initiation
          if (!this.data.isEdit) {
            this.onChangeBenefitOption();
          }
        }
      });
  }

  onChangeBenefitOption(): void {
    this.showCustomDocumentErrMsg = false;

    this.isBenefitOptionChangedOnEdit =
      this.data.isEdit && this.benefitOptionControl.value !== this.data.benefitEntityId;

    // Validate selected retirement benefit option
    this.validateBenefitOptionExists();
  }

  private selectInitBenefitCalculationState(): void {
    this.calculationStore
      .select(getRetirementBenefitDialogSelector)
      .pipe(
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const isSuccess = response?.success;
        this.calculationStore.dispatch(clearCreateRetirementBenefitDialogsStateAction());

        if (!isSuccess) {
          showBanner.call(this, STATE.FAIL, this.sectionLabel, ACTION.ADD);
          return;
        }

        this.dialogRef.close();
        this.router.navigateByUrl(
          `member/benefit-overview/${this.data.calculationType}/${this.data.memberId}/detail/${
            response.payload?.retirementBenefitId ?? ''
          }`,
        );
      });
  }

  private getValidationExceptionConfigState(): void {
    const request: GetValidationExceptionRequest = {
      memberId: this.data.memberId,
      exceptionType: ExceptionType.DateValueValidation,
      calculationBenefitId: this.data?.calculationBenefitId === '' ? undefined : this.data?.calculationBenefitId,
    };
    this.calculationStore.dispatch(getValidationExceptionConfigurationAction({ request }));
  }

  private selectValidationExceptionConfigState(): void {
    this.calculationStore
      .pipe(
        select(getValidationExceptionConfigurationSelector),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        map((res) => res?.payload),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetValidationExceptionConfigurationStateAction());

        this.validationExceptionInfo = response?.benefitDetails ?? [];

        if (this.isRetirement) {
          this.getRetirementBenefitOptionList();
        }
      });
  }

  handleValueChange(event: any) {
    const listControl = this.formData.controls.propertyValueFromPriorSteps as FormArray;
    listControl.controls.forEach((element, index) => {
      (element as FormGroup).controls.value.updateValueAndValidity();
    });
  }

  private initFormGroup(): void {
    this.propertyValueFromPriorStepsFormArray.clear();
    this.propertyValueFromPriorStepConfigs.forEach((property: any) => {
      this.propertyValueFromPriorStepsFormArray.push(
        this.fb.group({
          calculationParamMappingId: property.calculationParamMappingId,
          calculationStepId: property.calculationStepId,
          id: property.id,
          options: property.inputOptions ?? property.options,
          order: property.order,
          orderColumn: property.orderColumn,
          propertyLabel: property.inputLabel ?? property.label,
          value: this.fb.control(this.initFieldsValue(property), [this.customValidation(property)]),
          type: property.dataType ?? property.type,
          benefitDetailKey: property.benefitDetailKey ?? property.id,
          inputConfigs: property.inputConfigs ?? property.config,
        }),
      );
    });

    if (this.stepOptionByPass && !this.data.isEdit) {
      this.propertyValueFromPriorStepsFormArray.disable();
    }

    if (this.isRecalculate) {
      this.formData.setControl(
        'benefitBeginDate',
        this.fb.control({
          value: this.detectRecalculationBenefitInfo?.benefitBeginDate ?? '',
          disabled: true,
        }),
      );
    }
  }

  customValidation(field: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formValue = this.formData.getRawValue();
      let properties: SetBenefitDetailProperties[] = formValue.propertyValueFromPriorSteps;
      properties = properties.map((item) => {
        return {
          ...item,
          label: item.propertyLabel,
        };
      });

      const data = this.validationExceptionInfo.filter(
        (item) =>
          item.benefitId === this.benefitOptionControl.value &&
          item.additionalInfos.length > 0 &&
          item.additionalInfos[0].value === field.calculationParamMappingId,
      );
      return validationData(data, properties);
    };
  }

  private getComponentsByMode(inputData: any[] = []): CalculationStepProperty[] {
    const fieldsByType = deepClone(inputData);

    if (!this.detectRecalculationBenefitInfo) {
      return fieldsByType;
    }

    if (!this.isRecalculate) {
      return fieldsByType.filter(
        (item) => item.type === StepPropertiesType.Initiate || item.type === StepPropertiesType.Both,
      );
    } else {
      return fieldsByType.filter(
        (item) => item.type === StepPropertiesType.Recalculate || item.type === StepPropertiesType.Both,
      );
    }
  }

  private initFieldsValue(property: any): any {
    if (isEmpty(property.value) || property.value === '') {
      return null;
    }
    if (property.type === InputType.Binary) {
      return stringToBoolean(property.value);
    }
    return property.value;
  }

  private getInputType(property: any) {
    if (typeof property?.type === 'number') {
      return (InputType as any)[PropertyType[property.dataType ?? property.type] as any];
    }
    return property.dataType ?? property.type;
  }

  private propertyValueFromPriorStepMapping(calculationStepProperties: CalculationStepProperty[] = []) {
    return deepClone(calculationStepProperties).map((property) => {
      return {
        ...property,
        type: (InputType as any)[PropertyType[property.dataType] as any],
        label: property.inputLabel,
        config: { ...(property?.inputConfigs ?? (property as any)?.config) },
        options: property.inputOptions ?? property.options,
      } as any;
    });
  }

  private get initSelectDocumentFormGroup(): FormGroup {
    return this.fb.group({
      id: this.fb.control(null, [Validators.required, this.checkDuplicateDocumentName(true)]),
      isNewDocument: this.fb.control(false),
      name: this.fb.control('', [Validators.required, this.checkDuplicateDocumentName()], [this.checkExistDocument()]),
      documentType: this.fb.control('', [Validators.required]),
      file: this.fb.control({ value: null, disabled: true }, [Validators.required]),
      tags: this.fb.control(''),
      description: this.fb.control(''),
      showOnOverview: this.fb.control(true), // @-> US 102907, default value of Show on Overview should be Checked
      tagsChipList: this.fb.array([]),
    });
  }

  // TODO: Implement later
  // private openSecondStep(request: CreateRetirementBenefitRequest) {
  //   this.dialogRef.close();
  //   this.dialog.open(BeneficiaryConfirmationComponent, {
  //     panelClass: 'dialog-full-screen',
  //     disableClose: true,
  //     autoFocus: false,
  //     data: {
  //       memberId: this.data.memberId,
  //       sectionKey: this.getConfigurationByKey(2, CALCULATION_OPTION.SectionKey),
  //       calculationStep: this.getStepConfiguration(2),
  //       request,
  //       benefitEntityId: request.benefitEntityId,
  //     },
  //   });
  // }

  private get initForm(): FormGroup {
    return this.fb.group({
      benefitEntityId: this.fb.control(this.data.benefitEntityId ?? '', Validators.required),
      propertyValues: this.fb.array([]),
      files: this.fb.array([]),
      propertyValueFromPriorSteps: this.fb.array([]),
    });
  }

  private getSingleBenefitEntityOption(): void {
    this.benefitOptions = [
      { displayValue: this.data?.benefitEntityName ?? '', value: this.data?.benefitEntityId ?? '' },
    ];
    this.benefitOptionControl.setValue(this.data?.benefitEntityId);
  }

  private selectStepConfigurationByBenefitOptionState(): void {
    this.stepConfigurationsStore
      .select(stepConfigurationDetailByBenefitOptionSelector)
      .pipe(
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        map((res) => res?.payload),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetStepConfigurationDetailByBenefitOptionStateAction());

        this.calculationSteps = deepClone(response?.calculationSteps ?? []).sort((a, b) => b.order - a.order);
        this.initDisplayData();
      });
  }

  onChangeAlterPayee(v: Option) {
    const option = this.alternatePayees.find((a) => a.id === v.value);
    this.isLoading = true;
    if (option) {
      this.selectedAlternatePayee = deepClone(option);
      this.getDetectRecalculationState();
    }
  }

  private getAlternatePayeeState(): void {
    this.calculationStore.dispatch(getAlternatePayeesAction({ request: { memberId: this.data.memberId } }));
  }

  private selectAlterPayeeState(): void {
    this.calculationStore
      .select(getAlternatePayeesSelector)
      .pipe(
        tap((res) => (this.alternatePayeeLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.calculationStore.dispatch(clearGetAlternatePayeesStateAction());
        this.alternatePayees = deepClone(state?.payload) ?? [];
        this.optionAlterPayee = this.alternatePayees
          .map((v) => {
            return {
              value: v.id,
              displayValue: `${v.personName?.first ?? ''} ${v.personName?.middle ?? ''} ${v.personName?.last ?? ''}`,
            };
          })
          .sort((a: any, b: any) => a.displayValue.localeCompare(b.displayValue));
      });
  }

  private selectUpdateBenefitOptionSectionState(): void {
    this.calculationStore
      .select(getSetBenefitDetailSelector)
      .pipe(
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((setBenefitDetail) => {
        this.calculationStore.dispatch(clearSetBenefitDetailStateAction());

        if (!setBenefitDetail?.success) {
          showBanner.call(this, STATE.FAIL, setBenefitDetail?.payload?.editItem ?? this.sectionLabel, ACTION.EDIT);
          return;
        }
        this.dialogRef.close();
      });
  }

  addDocument(selectedFile?: File, isUpload?: boolean) {
    this.selectDocumentFormGroup.markAllAsTouched();
    if (!this.selectDocumentFormGroup.valid || (isUpload && !selectedFile)) {
      this.showCustomDocumentErrMsg = false;
      return;
    }
    const documentData = this.selectDocumentFormGroup.getRawValue();
    const documentType = documentData.documentType;

    // New Document
    if (documentData.isNewDocument) {
      documentData.file = selectedFile;
      documentData.uploadDate = DateTime.now().toISO();
      documentData.fileName = selectedFile?.name;
      documentData.documentName = documentData.name;
      this.file = selectedFile;
    }
    // Existing Document
    else {
      documentData.uri = this.selectedDocument?.uri ?? '';
      documentData.uploadDate = this.selectedDocument?.uploadDate ?? '';
      documentData.fileName = this.selectedDocument?.fileName ?? '';
      documentData.documentName = this.selectedDocument?.documentName ?? '';
      documentData['fileId'] = this.selectedDocument?.fileId ?? '';
    }

    documentData.index = new Date().getTime();
    documentData['showOnOverview'] = this.showOnOverviewCheckboxControl.value;
    documentData['tags'] = this.currentTagsChipValueList;

    const item = this.calculationDocumentTypeOptions.find((item) => item.value === documentType);
    if (item) {
      documentData.documentTypeName = item?.displayValue;
      documentData.type = item?.value;
    }

    this.documents = [...this.documents, documentData];

    this.getFilteredDocuments();
    this.getExistDocuments();
    this.selectDocumentFormGroup.reset({
      isNewDocument: this.selectDocumentFormGroup.value.isNewDocument,
    });

    if (this.fileDocument) {
      this.fileDocument.fileSelected = null;
      this.fileControl.reset('');
      // this.fileControl.setValue(null);
      this.fileDocument.inputFile.nativeElement.value = '';
      this.fileDocument.hasError = false;
      this.fileDocument.firstTouch = true;
      this.fileDocument.isDuplicated = false;
    }

    this.showCustomDocumentErrMsg = false;

    this.resetForm();
  }

  onBrowseFile(): void {
    this.showCustomDocumentErrMsg = false;
  }

  getDocumentNameChange(newDocumentName: string): void {
    this.showCustomDocumentErrMsg = false;
  }

  getDocumentTypeChange(newDocumentType: any): void {
    this.showCustomDocumentErrMsg = false;
  }

  onChangeDocumentName() {
    const formValue = this.selectDocumentFormGroup.getRawValue();
    if (formValue.isNewDocument) {
      this.selectDocumentFormGroup.setValue({
        id: '',
      });
      return;
    }
    const selectedDocument = this.data.benefitDocuments.find((item) => item.id === formValue.id);
    if (selectedDocument) {
      this.selectedDocument = selectedDocument;
      this.selectDocumentFormGroup.patchValue({
        documentType: selectedDocument.documentType,
        name: selectedDocument.documentName,
      });
    }
    this.showCustomDocumentErrMsg = false;
    this.tagsChipListControl.clear();
    this.currentTagsChipValueList = [];
    if (this.selectedDocument?.tags?.length) {
      this.selectedDocument?.tags.forEach((item: any) => {
        const chipControl = new FormControl({ value: item?.id, displayValue: item?.name });
        this.tagsChipListControl.push(chipControl);
        this.currentTagsChipValueList.push(item?.id);
      });
    }
    this.descriptionInputAreaControl?.setValue(this.selectedDocument?.description ?? '');
    this.showOnOverviewCheckboxControl?.setValue(this.selectedDocument?.showOnOverview ?? false);
  }

  private validateBenefitOptionExists(): void {
    this.calculationStore.dispatch(
      checkStepConfigurationExistsAction({ request: { benefitEntityId: this.benefitOptionControl.value ?? '' } }),
    );
  }

  private selectValidateBenefitOptionExistState(): void {
    this.calculationStore
      .select(getCheckStepConfigurationExistsSelector)
      .pipe(
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res?.isLoading),
        map((res) => res?.payload),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearCheckStepConfigurationExistsStateAction());

        const isValid = response?.isValid;

        // if valid then continue
        if (isValid) {
          this.stepConfigurationsStore.dispatch(
            getStepConfigurationDetailByBenefitOptionAction({
              memberId: this.data.memberId,
              benefitEntityId: this.benefitOptionControl.value ?? '',
              calculationType: this.data.calculationType,
            }),
          );
          return;
        }

        // if not then stop and show message
        const errorType = response?.errorType;
        this.propertyValueFromPriorStepsFormArray.clear();
        this.formData.reset({
          ...this.formData.getRawValue(),
          benefitEntityId: this.benefitOptionControl.value,
        });
        const benefitEntityName = this.isQDRO ? this.data.benefitEntityName : 'Retirement';
        const text =
          errorType === CheckConditionToProcessCalculationBenefitErrorType.StepConfigurationMissing
            ? `Step Configuration needs to be set up before initiating ${benefitEntityName} Benefit.`
            : `Benefit Option must be associated with a Calculation File before initiating ${benefitEntityName} Benefit.`;

        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          disableClose: true,
          autoFocus: false,
          data: {
            text,
            type: ConfirmType.Confirm,
            title: 'Setup Requiring',
          },
          restoreFocus: false,
        });
        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => {
            this.dialogRef.close(this.isChicagoParks);
          });
      });
  }

  removeDocument(row: any) {
    const index = this.documents.findIndex((document) => document.index === row.index);
    this.documents.splice(index, 1);
    this.getExistDocuments();
    this.getFilteredDocuments();
    this.showCustomDocumentErrMsg = false;
  }

  onSortDocumentsTable(sort: Sort) {
    this.sortInfo = sort;
    this.getFilteredDocuments();
  }

  clearSelectedFile($event: any) {
    this.showCustomDocumentErrMsg = false;
  }

  changeSelectedDocumentType(isNewDocument: boolean) {
    // New Document
    if (isNewDocument) {
      this.nameControl.enable();
      this.nameControl.reset('');

      this.documentTypeControl.enable();
      this.documentTypeControl.reset('');

      this.idControl.disable();
      this.idControl.reset('');

      this.documentTypeControl.reset();
      this.tagsAutoCompleteControl.enable();
    }

    // Existing Document
    else {
      this.nameControl.disable();
      this.nameControl.reset('');

      this.documentTypeControl.disable();
      this.documentTypeControl.reset('');

      this.fileControl.disable();
      this.fileControl.reset('');
      this.fileDocument.firstTouch = true;

      this.idControl.enable();
      this.idControl.reset('');
      this.tagsAutoCompleteControl.disable();
    }

    this.showCustomDocumentErrMsg = false;
    this.resetForm();
  }

  resetForm() {
    this.showOnOverviewCheckboxControl?.setValue(false);
    this.descriptionInputAreaControl?.setValue('');
    this.tagsAutoCompleteControl.setValue('');
    this.tagsChipListControl.clear();
    this.currentTagsChipValueList = [];
    this.idControl?.setValue('');
    this.documentTypeControl?.setValue('');
    this.nameControl?.setValue('');
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getFilteredDocuments();
  }

  downloadSelectedFile(row: any) {
    // For downloading file on server
    if (row?.id) {
      this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
      this.documentStore.dispatch(
        getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
      );
    }
    // For downloading file that recently upload to table
    if (this.file) downloadFile.call(this, this.file, this.file?.name);
  }

  selectorSelectedDownloadFile() {
    this.calculationStore
      .select(getRetirementBenefitDownloadDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((downloadDocument) => {
        if (!downloadDocument?.isLoading && downloadDocument?.success) {
          let blobFile = downloadDocument?.payload ? downloadDocument?.payload[0] : new Blob();
          downloadFile.call(this, blobFile, this.downloadFileName);

          this.calculationStore.dispatch(clearGetRetirementBenefitDownloadDocumentStateAction());
        }
      });
  }

  private getPropertyType(type: InputType | PropertyType): PropertyType {
    if (typeof type === 'number') {
      return type;
    }
    return (PropertyType as any)[type];
  }

  private getPropertyValue(value: any, type: PropertyType) {
    if (type === PropertyType.Date && !isEmpty(value)) {
      return DateTime.fromISO(value).toFormat('yyyy-MM-dd');
    }
    return value;
  }

  private getStepConfiguration(order: 1 | 2 | 3): CalculationStep | undefined {
    return this.retirementBenefitDialogComponentService.getStepConfigurationData(order, this.calculationSteps);
  }

  private getConfigurationByKey(order: 1 | 2 | 3, key: string): string {
    const stepConfiguration = this.getStepConfiguration(order);
    const calculationStepOptions = stepConfiguration?.calculationStepOptions ?? [];
    return calculationStepOptions.find((item) => item.key === key)?.value ?? '';
  }

  private getExistDocuments() {
    this.existDocumentList = this.data.benefitDocuments
      .filter((document) => {
        return !this.documents.find((item) => item.id === document.id);
      })
      .map((document) => {
        return {
          value: document.id,
          displayValue: document.documentName,
        };
      })
      .sort((a, b) => localeCompare(a.displayValue, b.displayValue));
  }

  private checkDuplicateDocumentName(isUploaded: boolean = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.documents?.length) {
        return null;
      }
      const isExisted = this.documents.find((document) => {
        const documentName = isUploaded
          ? this.data.benefitDocuments?.find((item) => item.id === control.value)?.documentName ?? ''
          : control.value;
        return document.documentName === documentName;
      });
      if (isExisted) {
        return {
          documentExisted: 'Document Name already exists.',
        };
      }
      return null;
    };
  }

  private checkExistDocument(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.parent || this.selectDocumentFormGroup.getRawValue().id) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.retirementBenefitDialogService
              .checkExits({ name: control.value?.toString()?.trim(), memberId: this.data.memberId })
              .pipe(
                map((response: any) => {
                  if (response && (response.exists || response.isExisted || response.isExists || response.isExist)) {
                    return { inValidAsync: true, message: response?.message };
                  }
                  return null;
                }),
              ),
        ),
      );
    };
  }

  private getListOption(type: InputType, property: any): Option[] {
    let propertyType = property.type;
    if (typeof propertyType === 'number') {
      propertyType = (InputType as any)[PropertyType[propertyType]] as InputType;
    }
    const options = property.inputOptions ?? property.options;
    if (propertyType === InputType.List || propertyType === InputType.Lookup) {
      return (options as any[]).map((option) => {
        return {
          value: option.id,
          displayValue: option.text,
        };
      });
    }
    if (property?.type === InputType.Employer || property?.type === InputType.Department) {
      return (options as any[]).map((option) => {
        return {
          displayValue: option.text,
          value: option.id,
          valueDescription: option.description,
        };
      });
    }

    if (type === InputType.List) {
      return options.map((option: any) => {
        return {
          value: option.id,
          displayValue: option.name,
        };
      });
    }
    return [];
  }

  private getFilteredDocuments() {
    this.filteredDocuments = [...this.documents]
      .sort((a, b) => {
        if (!this.sortInfo?.direction || !this.sortInfo.active) {
          if (DateTime.fromISO(a.uploadDate).startOf('day') < DateTime.fromISO(b.uploadDate).startOf('day')) {
            return 1;
          }
          if (DateTime.fromISO(a.uploadDate).startOf('day') > DateTime.fromISO(b.uploadDate).startOf('day')) {
            return -1;
          }
          return localeCompare(a?.documentName, b?.documentName);
        }
        let ascendingSort = this.sortInfo?.direction === 'asc' ? 1 : -1;
        if (this.sortInfo.active === 'uploadDate') {
          const currentDate = DateTime.fromISO(a.uploadDate).startOf('day');
          const nextDate = DateTime.fromISO(b.uploadDate).startOf('day');
          return (currentDate <= nextDate ? -1 : 1) * ascendingSort;
        }
        const currentItem = (a as any)?.[this.sortInfo.active] ?? '';
        const nextItem = (b as any)?.[this.sortInfo.active] ?? '';
        return localeCompare(currentItem, nextItem) * ascendingSort;
      })
      .slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
  }

  // !CAUTION: Should have calculationSteps OR processing Edit if want to use this function
  private initDisplayData(): void {
    this.sectionLabel = this.data?.sectionLabel ?? '';
    this.dialogTitle = this.title;

    // If editing AND for the first time (not after changing benefit option)
    if (this.data.isEdit && !this.isBenefitOptionChangedOnEdit) {
      this.propertyValueFromPriorStepConfigs = (this.data?.properties ?? []).map((property) => {
        const type = this.getInputType(property);
        const options = this.getListOption(type, property);
        const shouldDisableField = this.isJointSurvivor && !this.checkEnableJointSurvivorFields(property?.label);
        return {
          ...property,
          type,
          property,
          options,
          ...(shouldDisableField === true && { isDisabled: true }),
        } as any;
      });
      this.initFormGroup();
    }

    // If initiate or recalculation detected
    else {
      const firstStep = this.getStepConfiguration(1);
      if (firstStep) {
        this.propertyValueFromPriorStepsFormArray.clear();
        this.calculationStepProperties = this.getComponentsByMode(firstStep.calculationStepProperties);
        this.stepOptionByPass = stringToBoolean(this.getConfigurationByKey(1, CALCULATION_OPTION.ByPass));
        this.propertyValueFromPriorStepConfigs = this.propertyValueFromPriorStepMapping(this.calculationStepProperties);
        this.initFormGroup();
      }
    }
  }

  private updateBenefitOptionDetail(): void {
    const formValue = this.formData.getRawValue();
    const params: SetBenefitDetailParams = {
      memberId: this.data.memberId,
      calculationBenefitId: this.data?.calculationBenefitId ?? '',
      detailType: CalculationBenefitDetailType.RetirementOption,
    };

    const properties: SetBenefitDetailProperties[] = formValue.propertyValueFromPriorSteps.map((property: any) => {
      const type = this.getPropertyType(property.type);
      return {
        benefitDetailKey: property.benefitDetailKey,
        type,
        value: this.getPropertyValue(property.value, type),
        configs: property.inputConfigs ?? property.config,
      };
    });
    const request: SetBenefitDetailRequest = {
      benefitEntityId: formValue.benefitEntityId === this.data.benefitEntityId ? null : formValue.benefitEntityId,
      targetType: this.data.calculationType,
      targetId: this.data?.calculationBenefitId ?? '',
      properties,
    };
    this.calculationStore.dispatch(setBenefitDetailAction({ params, request, editItem: this.sectionLabel }));
  }

  private selectDetectRecalculationState(): void {
    this.calculationStore
      .select(getDetectRecalculationBenefitSelector)
      .pipe(
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res?.isLoading),
        map((res) => res?.payload),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetDetectRecalculationBenefitStateAction());

        this.detectRecalculationBenefitInfo = deepClone(response);

        if (!this.isQDRO && !this.detectRecalculationBenefitInfo?.isRecalculate) {
          return;
        }

        this.calculationSteps = deepClone(
          this.detectRecalculationBenefitInfo?.benefitStepConfigInfos.calculationSteps ?? [],
        );
        this.benefitOptionControl.setValue(this.detectRecalculationBenefitInfo?.benefitEntityId ?? '');
        this.initDisplayData();
      });
  }

  private getDetectRecalculationState(): void {
    // Check recalculation of QDRO benefit
    if (this.isQDRO) {
      this.calculationStore.dispatch(
        getDetectRecalculationBenefitAction({
          request: {
            memberId: this.data.memberId,
            calculationType: this.data.calculationType,
            benefitEntityId: this.data.benefitEntityId ?? '',
            courtOrderId: this.selectedAlternatePayee?.id ?? '',
          },
        }),
      );
    }

    // Check recalculation of Retirement benefit
    else {
      this.calculationStore.dispatch(
        getDetectRecalculationBenefitAction({
          request: {
            memberId: this.data.memberId,
            calculationType: this.data.calculationType,
          },
        }),
      );
    }
  }

  getTagList() {
    let sortType = SortType.ASC;
    let sortNames = 'Name';
    this.tagStore.dispatch(
      getTagListAction({
        request: {
          sortNames,
          sortType,
        },
      }),
    );
  }

  registerAddNewTagSelector() {
    this.tagStore.pipe(select(addTagListSelector), takeUntil(this.unsubscribe$)).subscribe((response) => {
      if (response?.state === STATE.SUCCESS && response?.action === ACTION.ADD) {
        this.isNewTagAddedSuccess = true;
      }
      this.tagStore.dispatch(clearTagDetailStateAction());
    });
  }

  selectDataTagList() {
    this.tagStore.pipe(select(getTagListSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isGetTagListLoading = false;
        if (data?.success && data?.payload?.length) {
          const previousList = deepClone(this.listOptionTag);

          this.listOptionTag = data?.payload.map(
            (item) =>
              ({
                value: item.id,
                displayValue: item.name,
              }) as Option,
          );

          if (this.isNewTagAddedSuccess) {
            const newTag = this.listOptionTag.find((item) => !previousList.some((tag) => tag.value === item.value));
            if (newTag) {
              this.tagsChipListControl.push(new FormControl(newTag));
              this.currentTagsChipValueList.push(newTag?.value);
            }
            this.isNewTagAddedSuccess = false;
          }
        }
      }
    });
  }

  onChangeTags() {}

  tagsFocus(event: any) {
    event.stopPropagation();
    this.currentTagsChipValue = deepClone(this.tagsAutoCompleteControl?.value);
    this.filteredByTypingTagOptions = this.tagsAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.listOptionTag.filter(
          (item) =>
            item?.displayValue?.toLowerCase()?.includes((event.target.value as string).toLowerCase()) &&
            !this.currentTagsChipValueList?.includes(item?.value),
        ),
      ),
    );
  }

  displayTagFn(value: Option): string {
    return value ? value.displayValue : '';
  }

  validateTags(): void {
    if (this.tagsAutoCompleteControl.value) {
      if (
        (this.tagsAutoCompleteControl.enabled && typeof this.tagsAutoCompleteControl.value === 'string') ||
        this.currentTagsChipValueList?.includes(this.tagsAutoCompleteControl.value.value)
      ) {
        this.tagsAutoCompleteControl.setErrors({ inValidAsync: true });
        return;
      }
    }
  }

  addNewTags() {
    if (this.formData.get('isNewDocument')?.value === false) {
      return;
    }
    // call add tag component
    const dialogRef = this.dialog.open(AddTagComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: true,
      data: {
        tag: null,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.isSuccess) {
        this.getTagList();
      }
    });
  }

  onAddNewChip() {
    if (!this.tagsAutoCompleteControl.value || this.tagsAutoCompleteControl.invalid) {
      this.tagsAutoCompleteControl.markAsTouched();
      return;
    }

    const chip = this.currentTagsChipValue;
    this.tagsAutoCompleteControl.reset('');
    const chipControl = new FormControl(chip);

    this.tagsChipListControl.push(chipControl);

    if (!this.currentTagsChipValueList.includes(chip.value)) {
      this.currentTagsChipValueList.push(chip.value);
    }
  }

  onRemoveChip(value: string, index: number) {
    if (!value) return;
    this.currentTagsChipValueList.splice(index, 1);
    this.tagsChipListControl.removeAt(index);
  }

  checkEnableJointSurvivorFields(label: string) {
    if (!label) return true;
    const rawLabel = label
      .trim()
      .replace(/[^A-Za-z0-9]/g, '')
      .toLowerCase();
    return this.enableFieldsInJointSurvivorCase.includes(rawLabel);
  }
}
