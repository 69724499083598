import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import { GetRetirementBenefitDetailDocumentsRequest, GetRetirementBenefitDetailDocumentsResponse } from './models';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable()
export class RetirementBenefitDetailDocumentService {
  constructor(private httpClient: HttpClient) {}

  getRetirementBenefitDetailDocuments(
    request: GetRetirementBenefitDetailDocumentsRequest,
  ): Observable<GetRetirementBenefitDetailDocumentsResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    if (request?.calculationId) {
      params = params.append('calculationRecordId', request.calculationId);
    }

    const memberId = request?.memberId;
    // const calculationType = request?.calculationType;
    const benefitTypeId = request?.benefitTypeId;

    return this.httpClient.get<GetRetirementBenefitDetailDocumentsResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-documents/${benefitTypeId}`,
      { params, context },
    );
  }
}
