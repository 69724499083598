import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EntityListConfig, EntityPropertyConfigs } from '../../services/models';
import { EntityListViewConfigurationService } from '../../services';
import {
  getEntityPropertyList,
  getEntityPropertyListFailure,
  getEntityPropertyListSuccess,
  getParticipantListConfiguration,
  getParticipantListConfigurationFailure,
  getParticipantListConfigurationSuccess,
  setParticipantListConfiguration,
  setParticipantListConfigurationFailure,
  setParticipantListConfigurationSuccess,
} from '../actions';

@Injectable()
export class EntityListViewConfigurationEffect {
  constructor(
    private actions$: Actions,
    private entityListViewConfigurationService: EntityListViewConfigurationService,
  ) {}

  getEntityPropertyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityPropertyList),
      switchMap(({ entityId, screenId }) => {
        return this.entityListViewConfigurationService.getEntityPropertyList(entityId, screenId).pipe(
          map((res: EntityPropertyConfigs) => {
            return getEntityPropertyListSuccess({
              propertyConfigs: res.properties,
              fixPersonProperties: res.fixPersonProperties,
            });
          }),
          catchError((err) => {
            return of(
              getEntityPropertyListFailure({
                errorMsg: err.message,
              }),
            );
          }),
        );
      }),
    ),
  );

  getParticipantListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getParticipantListConfiguration),
      switchMap(({ entityId }) => {
        return this.entityListViewConfigurationService.getParticipantListConfiguration(entityId).pipe(
          map((res: EntityListConfig) => {
            return getParticipantListConfigurationSuccess({ propertyDisplayConfigurations: res.configurations });
          }),
          catchError((err) => {
            return of(getParticipantListConfigurationFailure({ errorMsg: err.message }));
          }),
        );
      }),
    ),
  );

  setParticipantListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setParticipantListConfiguration),
      switchMap(({ entityId, body }) => {
        return this.entityListViewConfigurationService.setParticipantListConfiguration(entityId, body).pipe(
          map(() => {
            return setParticipantListConfigurationSuccess();
          }),
          catchError((err) => {
            return of(setParticipantListConfigurationFailure({ errorMsg: err.message }));
          }),
        );
      }),
    ),
  );
}
