import { Component, Input } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CardProperty,
  EntityListData,
  EntityViewItem,
  GetEntityListDataByIdQuery,
} from '@ptg-entity-management/services/models';
import {
  clearGetEntityListDataByIdFailureAction,
  getEntityListDataByIdAction,
} from '@ptg-entity-management/store/actions';
import { EntityState } from '@ptg-entity-management/store/reducers';
import { getEntityListDataByIdSelector } from '@ptg-entity-management/store/selectors';
import { PropertyType } from '@ptg-member/constance/metadataPropertyType.const';
import { getColumConfig, getEntityPropertyName, getEntityValue } from '@ptg-member/helper';
import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { deepClone, getSortType } from '@ptg-shared/utils/common.util';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ptg-view-beneficiary-card',
  templateUrl: './view-beneficiary-card.component.html',
  styleUrls: ['./view-beneficiary-card.component.scss'],
})
export class ViewBeneficiaryCardComponent extends BaseComponent {
  @Input() memberId = '';
  @Input() cardId = '';
  @Input() title = '';
  @Input() memberEntityId = '';

  isLoading: boolean = true;
  totalRecords: number = 0;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  sortInfo?: Sort;
  cards: EntityViewItem[] = [];
  viewId: string = '';
  entitiesData: (EntityListData & Row)[] = [];
  propertiesConfig!: any;
  entityComponentId = '';
  columns: Column[] = [];

  constructor(
    public readonly route: ActivatedRoute,
    public readonly layoutService: LayoutService,
    private store: Store<fromReducer.State>,
    public entityStore: Store<EntityState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectEntityListState();
    this.getData();
  }

  getData(): void {
    let query: GetEntityListDataByIdQuery = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      memberId: this.memberId,
      cardId: this.cardId,
      isSummary: false,
      entityId: this.memberEntityId ?? '',
    };
    if (this.sortInfo?.direction) {
      const sortField = this.sortInfo.active.split('_')[0];
      query = {
        ...query,
        sortField,
        sortType: getSortType(this.sortInfo),
      };
    }

    this.entityStore.dispatch(getEntityListDataByIdAction({ query: query }));
  }

  selectEntityListState() {
    this.entityStore
      .select(getEntityListDataByIdSelector)
      .pipe(
        tap((state) => (this.isLoading = !!state?.isLoading)),
        filter((state) => !!state && !state.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (!state) {
          return;
        }
        this.entityStore.dispatch(clearGetEntityListDataByIdFailureAction());
        this.entityComponentId = state.payload?.entityComponentId ?? '';
        this.totalRecords = state.total ?? 0;
        this.propertiesConfig = state.payload?.cardProperties ?? [];
        this.getColumnConfigs();
        const listDataMappingId = (state.payload?.cardProperties ?? []).map((element: any) => {
          return {
            id: this.getColumnName(element),
            type: element.type,
            config: element.config,
          };
        });
        this.entitiesData = (state.payload?.rowData || [])?.map((item: any) => {
          const result: any = {};
          item?.values?.forEach((element: any) => {
            let columnName = element.entityReferencePropertyId ?? '';
            columnName =
              columnName && element.entityPropertyId
                ? `${columnName}_${element.entityPropertyId}`
                : element.entityPropertyId;

            if (element.option) {
              columnName = `${columnName}_${element.option}`;
            }

            const options = listDataMappingId.find((item) => item.id === columnName);

            result[columnName] = getEntityValue(element, options);
          });
          return { ...result, id: item.id, noOfAttachments: item?.noOfAttachments, values: item.values };
        });
      });
  }

  getColumnName(column: CardProperty): string {
    let columnName = '';
    columnName = column.propertyId;
    if (column.entityReferencePropertyId) {
      columnName = columnName + '_' + column.entityReferencePropertyId;
    }

    if (column.option) {
      columnName = columnName + '_' + column.option;
    }

    return columnName;
  }

  getColumnConfigs() {
    this.columns = deepClone(this.propertiesConfig).map((column: CardProperty) => {
      const columConfig = getColumConfig(getEntityPropertyName(column.type), column.config);
      return {
        name: this.getColumnName(column),
        header: {
          title: column.propertyLabel,
        },
        truncate: true,
        sortable: false,
        type: [ColumnType.Address, ColumnType.PersonName, ColumnType.Binary].includes(columConfig.type as ColumnType)
          ? ColumnType.Text
          : columConfig.type,
        align: column.type === PropertyType.TYPE_REGISTER ? Align.Center : undefined,
        templateArgs: {
          ...columConfig.templateArgs,
          emptyValueDisplay: '-',
          emptyString: '-',
        },
      };
    });
  }

  onChangePage(event: PageEvent) {
    this.pageNumber = event.pageNumber;
    this.pageSize = event.pageSize;
    this.getData();
  }
}
