import { createSelector } from '@ngrx/store';

import { entityManagementModuleSelector } from './selector';

export const entityDataSelector = createSelector(
  entityManagementModuleSelector,
  (state) => state?.entityData
);

export const createEntityDataSelector = createSelector(
  entityDataSelector,
  (state) => state?.createEntityData
);

export const searchEntityDatasSelector = createSelector(
  entityDataSelector,
  (state) => state?.searchEntityDatas
);

export const detailsEntityDatasSelector = createSelector(
  entityDataSelector,
  (state) => state?.detailsEntityDatas
);

export const upsertComponentListDataSelector = createSelector(
  entityDataSelector,
  (state) => state?.upsertComponentListData
);

export const getEntityMetadataSelector = createSelector(
  entityDataSelector,
  (state) => state?.entityMetadata
);