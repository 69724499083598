import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Menu, MenuItem } from '@ptg-shared/layout/models/layout.model';
import { BodyChangeIndex } from '@ptg-fund-management/models/navigation-page.models';
import { Store, select } from '@ngrx/store';
import * as fromReducer from '../../reducers/index';
import { FundType } from '@ptg-shared/types/enums';

import {
  ChangeIndexRequest,
  CheckMenuExistRequest,
  CheckMenuItemExistRequest,
  CreateMemberNavigationItem,
  MemberNavigationDetailResponse,
  MemberNavigationList,
  MemberNavigationResponse,
  RemoveMemberNavigationItem,
  SectionList,
  SetMemberNavigationItemRequest,
  SetMemberNavigationRequest,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class ProfileNavigationConfigurationService {
  activeFund: any;

  constructor(private httpClient: HttpClient, public store: Store<fromReducer.State>) {
    this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe(el => this.activeFund = el);
 }

  getMemberNavigationList(memberId?: string, entityReferenceLinkedId?: string): Observable<any> {
    let params = new HttpParams();
    if (entityReferenceLinkedId) {
      params = params.append('entityId', entityReferenceLinkedId);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/member-portal/${memberId}/profile`, { params }
    ).pipe(map(item => {
      return {...item, fundType: this.activeFund.fundType };
    }));
  }

  getRelatedPersonNavigationList(memberId?: string): Observable<any> {
    let params = new HttpParams();
    if (memberId) {
      params = params.append('memberId', memberId);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/entity-new/entities/member/${memberId}/deepest-profile`
    ).pipe(map(item => {
      return {...item, fundType: this.activeFund.fundType };
    }));
  }

  reorderMemberNavigation(body: ChangeIndexRequest) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/MemberNavigation/ReorderMenu`,
      body
    );
  }

  getMenuItems() {
    return this.httpClient
      .get(`${environment.apiUrl}/Members/MemberNavigation/ViewMenu`)
      .pipe(
        map((res: any) => {
          const menuItems: Menu[] = [];
          const listMemberNavigation = res?.memberNavigationMenu.filter(
            (item: any) => item?.active
          );
          listMemberNavigation?.forEach((el: MemberNavigationResponse) => {
            let menuItem: Menu = {
              title: el.name,
              style: `color:${el.color} !important;`,
              menuItems: el?.menuItem?.map((item) => {
                return {
                  name: item.name,
                  routerLink: `/member/${item.id}/`,
                } as MenuItem;
              }),
            };
            menuItems.push(menuItem);
          });
          return menuItems;
        })
      );
  }

  checkMemberNavigationExist = (body: CheckMenuExistRequest) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/MemberNavigation/Exists`,
      body
    );
  };

  createMemberNavigation(menuName: string) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/MemberNavigation/CreateMemberNavigation`,
      { name: menuName }
    );
  }

  removeMemberNavigation(menuId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Members/MemberNavigation/RemoveMemberNavigation/${menuId}`
    );
  }

  getMemberNavigationDetail(
    menuId: string
  ): Observable<MemberNavigationDetailResponse> {
    return this.httpClient.get<MemberNavigationDetailResponse>(
      `${environment.apiUrl}/Members/MemberNavigation/${menuId}`
    );
  }

  editMemberNavigation(body: SetMemberNavigationRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/MemberNavigation/SetMemberNavigation`,
      body
    );
  }

  reorderMemberNavigationItem(menuId: string, body: BodyChangeIndex) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/MemberNavigation/${menuId}/NavigationItem/Reorder`,
      body
    );
  }

  checkMemberNavigationItemExist = (body: CheckMenuItemExistRequest) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/MemberNavigation/NavigationItem/Exist`,
      body
    );
  };

  createMemberNavigationItem(menuId: string, body: CreateMemberNavigationItem) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/MemberNavigation/${menuId}/AddSubMenu`,
      body
    );
  }

  editMemberNavigationItem(body: SetMemberNavigationItemRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/MemberNavigation/SetMemberNavigationItem`,
      body
    );
  }

  removeMemberNavigationItem(body: RemoveMemberNavigationItem) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Members/MemberNavigation/NavigationItem/RemoveMemberNavigationItem`,
      { body }
    );
  }

  getDropDownSection(): Observable<SectionList> {
    return this.httpClient.get<SectionList>(
      `${environment.apiUrl}/Members/MemberNavigation/GetDropdownMetadataSection`
    );
  }
}
