<div class="accident-claims">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
  ></ptg-breadcrumb>
  <ptg-overview-header-entity
    #overViewHeader
    [shouldHideStatusDateProperty]="true"
    [shouldHideStatusEventProperty]="true"
  >
  </ptg-overview-header-entity>
  <div class="accident-claims-content">
    <div class="accident-grid flex flex-col gap-6 p-6">
      <ptg-sub-header
        [memberId]="memberId"
        [menuItemTitle]="menuItemTitle"
        [menuItemSubTitle]="menuItemSubTitle"
      ></ptg-sub-header>
      <ptg-banner
        *ngIf="bannerType"
        [(bannerType)]="bannerType"
        [message]="message"
      ></ptg-banner>
      <div class="button-container">
        <ptg-button buttonName="New Accident Post Card" classInput="add-button" (clickButton)="onClickAddAccidentPostCard()">
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>
      <div class="flex-grow overflow-hidden">
        <div class="header">
          <span class="title">Accident History</span>
        </div>
        <ptg-grid
          [data]="listAccidentHistory"
          [columns]="columnsHistory"
          [isLoading]="isLoadingHistory"
          [totalRecords]="lengthPgHistory"
          [pageSize]="pageSizeHistory"
          [pageNumber]="pageIndexHistory"
          [fitToParent]="true"
          [hideScrollbar]="false"
          pageName="Accident History"
          (pageChange)="onChangeHistoryPage($event)"
          (sortChange)="onSortChangeHistory($event)"
          (rowClick)="onRowClick($event)"
          notFoundMessage="No Accident to Display"
        >
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
              stopPropagation
              buttonName="Remove"
              classInput="remove-button"
              (clickButton)="onRemoveAccidentHistory(row)"
          >
              <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
        </ptg-grid>
      </div>
      <div class="flex-grow overflow-hidden">
        <div class="header">
          <span class="title">Accident Claims</span>
        </div>
        <ptg-grid
          [data]="listAccidentClaims"
          [columns]="columnsClaims"
          [isLoading]="isLoadingClaims"
          [totalRecords]="lengthPgClaim"
          [pageSize]="pageSizeClaim"
          [pageNumber]="pageIndexClaim"
          [fitToParent]="true"
          [hideScrollbar]="false"
          pageName="Accident Claims"
          (pageChange)="onChangeClaimsPage($event)"
          (sortChange)="onSortChangeClaims($event)"
          (rowClick)="onRowClick($event)"
          notFoundMessage="No Accident Claims to Display"
        ></ptg-grid>
      </div>
    </div>
  </div>
</div>
