export const MODULE_KEY = 'accidentParitcipantsModule';
export enum DisbursementType {
  DeathBen = 1,
  DisComp = 2,
  DupPMT = 3,
  MedSRSV = 4,
  Mileage = 5,
  Ppd = 6,
  Reimb = 7,
  Rx = 8,
  Cancel = 9,
  Repl = 10
}

export enum AccidentType {
  AccidentHistory,
  AccidentClaim,
  AccidentDocument
}

export enum  AccidentDocumentTypeDisplay {
  'Denial Letter' = 1,
  'Invoice Voucher' = 2,
  'Invoice Voucher Paid' = 3,
  'Medical Records' = 4,
  'Other' = 5,
  'Report Of Accident' = 6,
  'Subrogation Letter' = 7,
}

export const documentTypeOptionListForBvffAdmin = [
  {
    displayValue: 'Denial Letter', value: AccidentDocumentTypeDisplay['Denial Letter']
  },
  {
    displayValue: 'Report of Accident', value: AccidentDocumentTypeDisplay['Report Of Accident']
  },
  {
    displayValue: 'Medical Records', value: AccidentDocumentTypeDisplay['Medical Records']
  },
  {
    displayValue: 'Invoice Voucher', value: AccidentDocumentTypeDisplay['Invoice Voucher']
  },
  {
    displayValue: 'Invoice Voucher - Paid', value: AccidentDocumentTypeDisplay['Invoice Voucher Paid']
  },
  {
    displayValue: 'Subrogation Letter', value: AccidentDocumentTypeDisplay['Subrogation Letter']
  },
  {
    displayValue: 'Other', value: AccidentDocumentTypeDisplay['Other']
  },
];

export const documentTypeOptionListForMunicipalityAdmin = [
  {
    displayValue: 'Report of Accident', value: AccidentDocumentTypeDisplay['Report Of Accident']
  },
  {
    displayValue: 'Invoice Voucher', value: AccidentDocumentTypeDisplay['Invoice Voucher']
  },
    {
    displayValue: 'Medical Records', value: AccidentDocumentTypeDisplay['Medical Records']
  },
  {
    displayValue: 'Other', value: AccidentDocumentTypeDisplay['Other']
  },
];
