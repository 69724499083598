import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

import { BaseComponent } from '@ptg-shared/components';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { GUID_EMPTY } from '@ptg-shared/constance';
import { STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { Menu, MenuItem, ViewFixType } from '@ptg-shared/layout/models/layout.model';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';

import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/reducers';
import * as StatusHistoryActions from '../../store/actions/status-history.action';
import { GetListStatusHistoryQuery, History } from '../../types/models';
import { clearSetMemberEventStateAction } from '@ptg-member/store/actions/member.action';
import { setMemberEventSelector } from '@ptg-member/store/selectors/member.selector';
import { EntityProfileFixType } from '@ptg-entity-management/types/enums';
import { EntityViewItem } from '@ptg-entity-management/services/models';
import { LIST_DISPLAY_PREFERENCE_TYPE } from '@ptg-member/types/enums';
import { EditMemberStatusHistoryComponent } from '@ptg-member/components/edit-member-status-history/edit-member-status-history.component';
import { ManageStatusHistoryComponent } from '../manage-status-history/manage-status-history.component';

const PAGE_SIZE_CONST = '-ptg-status-history-pageSize';

@Component({
  selector: 'ptg-status-history-entity',
  templateUrl: './status-history-entity.component.html',
  styleUrls: ['./status-history-entity.component.scss']
})
export class StatusHistoryEntityComponent extends BaseComponent {
  @Input() memberId!: string;
  @Input() configCard!: EntityViewItem;
  @Input() viewName!: string;
  @Input() viewUrl!: string;

  readonly EntityProfileFixType = EntityProfileFixType;
  readonly LIST_DISPLAY_PREFERENCE_TYPE = LIST_DISPLAY_PREFERENCE_TYPE;
  columns: Column[] = [
    {
      name: 'statusDate',
      header: {
        title: 'Event Date',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
      truncate: true
    },
    {
      name: 'statusName',
      header: {
        title: 'Status',
      },
      truncate: true
    },
    {
      name: 'statusEvent',
      header: {
        title: 'Event',
      },
      truncate: true
    },
  ];

  memberNavigationMenu!: Menu[];
  statusHistorysData: History[] = [];
  sortInfo?: Sort;
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  memberStatusHistoryId?: string;
  currentFund: any = {};
  isLoading = true;
  isOverview: boolean = false;
  menuId: string = '';
  viewId: string = '';

  cardTitle = '';
  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router
  ) {
    super()
   }

  ngOnInit(): void {
    super.ngOnInit();

    this.getCurrentFund();
    this.getParamValue();
    
    this.getCardTitle();
    this.getData();
    this.getStatusHistorySelector();
    this._setMemberEventSelector();

    this.memberStore
      .pipe(
        select(fromLayoutReducer.selectProfileNavigationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state.isReloading && state.memberId) {
          this.memberNavigationMenu = state.menu;
        }
      });
  }

  getParamValue() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params) {
        this.memberId = params?.memberId;
        this.isOverview = params?.isOverview;
        this.menuId = params?.menuId;
        this.viewId = params?.viewId;
      }
    });
  }

  getCardTitle() {
    this.store.pipe(select(fromMember.selectEntityViewItems), takeUntil(this.unsubscribe$)).subscribe((el) => {
      const statusHistoryEntity = el?.payload?.entityViewItems.find((x) => x.listLabel === 'Status History');
      this.cardTitle =
        statusHistoryEntity && statusHistoryEntity.cardName ? statusHistoryEntity?.cardName : 'Status History';
    });
  }

  _setMemberEventSelector() {
    this.memberStore
      .pipe(select(setMemberEventSelector), takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (el) {
          if (el.success === true) {
            this.getData();
          }

          this.memberStore.dispatch(
            clearSetMemberEventStateAction()
          );
        }
      });
  }

  getStatusHistorySelector() {
    this.memberStore
    .pipe(
      select(fromMember.selectStatusHistoryState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      this.lengthPg = state?.statusHistoryList?.total;
      this.isLoading = state?.isLoading;
      this.statusHistorysData = state?.statusHistoryList?.memberStatusHistoryDto.map((item, index) => ({...item, order: index}));
      if (state.createState || state.updateState) {
        this.memberStore.dispatch(
          StatusHistoryActions.clearStatusHistoryState()
        );

        this.getData();
      }
    });
  }

  getCurrentFund() {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize =
          Number(
            sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
          ) === 0
            ? this.pageSize
            : Number(
                sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
              );
      });
  }

  getData() {
    let sortType;
    let sortNames: string[] = [];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListStatusHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      ...(sortType !== undefined ? { sortType: sortType } : {}),
    };
    this.memberStore.dispatch(
      StatusHistoryActions.getStatusHistoryList({ query })
    );
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    this.getData();
  }

  selectRow(event: any) {
    this.memberStore
    .pipe(
      select(fromLayoutReducer.selectProfileNavigationState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      if (state.isReloading && state.memberId) {
        const allMenuItems = this.memberNavigationMenu.reduce((acc: MenuItem[], menu: Menu) => {
          return [...acc, ...menu?.menuItems ?? []];
        }, []);
        
        const menuSameCard = allMenuItems.find(menuitem => menuitem.viewFixType === ViewFixType.StatusHistory);
        let baseUrl = `member/system-view/status-history/${GUID_EMPTY}/${GUID_EMPTY}`;
        if (menuSameCard) {
          baseUrl = menuSameCard.routerLink;
        }
        this.router.navigateByUrl(`${baseUrl.split('/').concat([state.memberId]).filter((x) => x).join('/')}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&currentRowIndex=${event?.order}&prevName=${
          encodeURIComponent(this.viewName)
        }&prevUrl=${
          encodeURIComponent(this.viewUrl)
        }`);
      }
    });
  }

  addStatusHistory() {
    this.dialog.open(EditMemberStatusHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: this.cardTitle,
        statusHistory: undefined,
      },
    });
  }

  manageStatusHistory() {
    this.router.navigateByUrl(`member/manage-status-history/${this.isOverview}/${this.menuId}/${this.viewId}/${this.memberId}`);
  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }
}
