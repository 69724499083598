import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from '@ptg-shared/components';
import {
  ACTION_COLUMN,
  Align,
  Column,
  GridComponent,
  ReorderInfo,
  Row,
  getValidatorsFromColumns,
} from '@ptg-shared/controls/grid';
import {
  VERTICAL_LINE_SEPARATOR,
} from '@ptg-shared/constance/common.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  ADMIN_SYSTEM,
  CANCEL_CONFIRM_MESSAGE,
  SORT_TYPE,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { 
  EntityPropertyDisplayConfig, 
  EntityPropertyDisplayConfiguration,
  EntitySectionConfig 
} from '../../services/models';


@Component({
  selector: 'ptg-entity-property-display-configuration',
  templateUrl: './entity-property-display-configuration.component.html',
  styleUrls: ['./entity-property-display-configuration.component.scss'],
})
export class EntityPropertyDisplayConfigurationComponent
  extends BaseComponent
  implements OnChanges
{
  readonly ACTION_COLUMN = ACTION_COLUMN;
  ADMIN_SYSTEM = ADMIN_SYSTEM;
  @ViewChild('sortPropertyTable')
  gridview!: GridComponent<EntityPropertyDisplayConfiguration>;
  @ViewChild('sortRowTable')
  gridviewRow!: GridComponent<EntityPropertyDisplayConfiguration>;

  @Input() propertyConfigs: EntityPropertyDisplayConfig[] = [];
  @Input() propertyDisplayConfigurations:EntityPropertyDisplayConfiguration[] = [];
  @Input() addPropertySection: EntitySectionConfig = {
    title: 'Add Property',
    columnName: 'Column Name',
    propertyName: 'Property Name',
  };
  @Input() sortPropertySection: EntitySectionConfig = { title: 'Sort Property' };
  @Input() sortRowSection!: EntitySectionConfig;
  @Input() columnNameMaxLength: number = 150;
  @Input() isMemberListConfiguration: boolean = false;
  @Input() sortable: boolean = true;
  @Input() dataDetail!: any;
  @Output() onSubmitEvent: EventEmitter<EntityPropertyDisplayConfiguration[]> = new EventEmitter();
  @Output() formValueChange: EventEmitter<void> = new EventEmitter();
  @Output() onSubmitEventColumnSet: EventEmitter<any> = new EventEmitter();

  orderColumns: Column[] = [
    {
      name: 'columnName',
      editable: true,
      truncate: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(this.columnNameMaxLength),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `${fieldName} already exists.`,
        },
      },
      controlArgs: {      
        placeholder: 'Column Name'
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  sortColumns: Column[] = [
    {
      name: 'columnName',
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  ACTION = {
    ADD_SORT_ROW: 'addSortRow',
    EDIT_COLUMN_NAME: 'editColumnName',
    REMOVE: 'remove',
    SORT_CHANGE: 'sortChange',
  };
  sortPropertySectionDataTable: (EntityPropertyDisplayConfiguration & Row)[] = [];
  sortRowSectionDataTable: (EntityPropertyDisplayConfiguration & Row)[] = [];
  isLoading = true;
  formData: FormGroup = this.fb.group({
    entityPropertyId: this.fb.control('', [Validators.required]),
    option: '',
    columnName: this.fb.control('', [
      Validators.required,
      Validators.maxLength(this.columnNameMaxLength),
      this.checkDuplicated(),
    ]),
    propertyName: '',
    type: null,
    entityNameRef: null
  });
  availablePropertyConfigs!: Option[] | any[];
  propertyOptions: RadioOption[] = [];
  canSubmit: boolean = false;
  editColumnForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public route: ActivatedRoute
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.propertyConfigs || changes.propertyDisplayConfigurations) {
      this.getDataTable();
      this.getAvailablePropertyConfigs();
    }
    if (changes.columnNameMaxLength) {
      this.formData
        .get('columnName')
        ?.addValidators(Validators.maxLength(this.columnNameMaxLength));
    }
    if (changes.listColumnConfig) {
      this.editColumnForm = this.fb.group({
        columnSetName: [
          this.dataDetail?.columSelected ? this.dataDetail?.columSelected.columnSetName : '',
          [Validators.required],
        ],
        startWith: [''],
      });
    }
  }

  ngOnInit(): void {
    this.isLoading = false;
  }

  getDataTable() {
    if (!this.propertyConfigs?.length) {
      return;
    }
    this.propertyDisplayConfigurations =
      this.getPropertyDisplayConfigurations();
    this.sortPropertySectionDataTable = this.propertyDisplayConfigurations
      .map((config) => {
        const obj = {
          ...this.getPropertyDisplayConfig(config),
          order: config.orderColumn || config.order,
          isUsed: config.orderRow !== null || !this.sortRowSection,
        };
        return this.createInlineEditFormControls(obj);
      })
      .sort((a, b) => Number(a.order) - Number(b.order));
    this.sortRowSectionDataTable = this.propertyDisplayConfigurations
      .reduce((result, config) => {
        if (config.orderRow) {
          result.push({
            ...this.getPropertyDisplayConfig(config),
            order: config.orderRow,
            sortType: config.sortType,
          });
        }
        return result;
      }, [] as EntityPropertyDisplayConfiguration[])
      .sort((a, b) => Number(a.order) - Number(b.order));
  }

  getPropertyDisplayConfigurations() {
    return this.propertyDisplayConfigurations.map((config) => {
      const cloneConfig = Object.assign({}, config); 
      const item = (this.propertyConfigs as EntityPropertyDisplayConfig[]).find(
        (property) => {
          return this.isExisted(
            config,
            {
              entityPropertyId: property.entityPropertyId,
              entityReferencePropertyId: property.entityReferencePropertyId
            } as any,
            false
          );
        }
      ) as EntityPropertyDisplayConfig;
      if (item) {
        cloneConfig.propertyName = item.propertyName;
        cloneConfig.optionName = cloneConfig.option
          ?.split('|')
          .map(
            (el: any) => item.option?.find((item) => item.key === el)?.value
          )
          .join(', ');
      }
      return cloneConfig;
    });
  }

  createDescription(formValue: any, optionName: string) {    
    return ` ${formValue.entityNameRef ? 'Entity Reference' : this.getType(formValue.type)} / ${formValue.propertyName}${
      formValue.entityNameRef ? ` / ${formValue.entityNameRef}` : ''
    }${
      formValue.option ? ` / ${optionName}` : ''
    }`;
  }

  getPropertyDisplayConfig(config: EntityPropertyDisplayConfiguration) {
    const property = this.propertyConfigs.find(property => 
      property.entityPropertyId === config.entityPropertyId && 
      property.entityReferencePropertyId === config.entityReferencePropertyId) as any;
    const configs = {
      ...config,
      type: property?.type,
      entityNameRef: property?.entityNameRef,
      optionName: property?.options ? property.options[config.option as any] : ''
    };
    return {
      columnName: configs.columnName,
      columnNameDescription: this.createDescription(
        configs,
        configs.optionName as string
      ),
      id: configs.id,
      entityPropertyId: configs.entityPropertyId,
      entityReferencePropertyId: configs.entityReferencePropertyId,
      propertyName: configs.propertyName,
      option: configs.option
    };
  }

  getAvailablePropertyConfigs() {
    this.propertyConfigs = this.propertyConfigs.map(item => {
      let option: any = [];
      if (item.options) {
        Object.entries(item.options).forEach(([key, value]) => {
          option.push({
            key: key,
            value: value
          });
        })
      }
      return {
        ...item,
        option: option
      }
    })
    this.availablePropertyConfigs = (this.propertyConfigs as any).reduce(
      (result: Option[] | any, propertyConfig: any) => {
        const isExisted = this.propertyDisplayConfigurations.find((item) => {
          return this.isExisted(item, propertyConfig);
        });
        if (isExisted) {
          return result;
        }
        result.push({
          value: propertyConfig.entityNameRef ? `${propertyConfig.entityPropertyId}_${propertyConfig.entityReferencePropertyId}` :  propertyConfig.entityPropertyId,
          displayValue: propertyConfig.propertyName,
          valueDescription: `${propertyConfig.entityNameRef ? 'Entity Reference / ' + propertyConfig.entityNameRef : this.getType(propertyConfig.type)}`
        });
        return result;
      },
      [] as Option[]
    );
  }

  changeItem(event: ReorderInfo, isSortColumn = false) {
    this.canSubmit = true;
    this.formValueChange.emit();
    if (isSortColumn) {
      this.sortPropertySectionDataTable = this.gridview.dataSource;
      return;
    }
    this.sortRowSectionDataTable = this.gridviewRow.dataSource;
  }

  onSoftDeleteSectionConfig(row: EntityPropertyDisplayConfiguration & Row): void {
    row.deleted = true;
    const sortItem = this.sortPropertySectionDataTable.find(
      (item) =>
        item.entityPropertyId === row.entityPropertyId &&
        item.entityReferencePropertyId === row.entityReferencePropertyId &&
        (!item.option || item.option === row.option)
    );
    if (sortItem) {
      sortItem.deleted = true;
    }

    const index = this.sortRowSectionDataTable.findIndex(
      (item) =>
        item.entityPropertyId === row.entityPropertyId &&
        item.entityReferencePropertyId === row.entityReferencePropertyId &&
        (!item.option || item.option === row.option)
    );
    if (index > -1) {
      this.sortRowSectionDataTable[index].deleted = true;
      this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
    }
  }

  onRowActions(
    event: { row: EntityPropertyDisplayConfiguration; type: string },
    isSortColumn = false
  ) {
    this.canSubmit = true;
    this.formValueChange.emit();
    switch (event.type) {
      case this.ACTION.ADD_SORT_ROW: {
        event.row.isUsed = true;
        this.addSortRow(event.row);
        const index = this.sortPropertySectionDataTable.findIndex(
          (item) =>
            item.entityPropertyId === event.row.entityPropertyId &&
            item.entityReferencePropertyId === event.row.entityReferencePropertyId &&
            (!item.option || item.option === event.row.option)
        );
        if (index > -1) {
          this.sortPropertySectionDataTable[index].isUsed = true;
          this.sortPropertySectionDataTable = [...this.sortPropertySectionDataTable];
        }
        break;
      }
      case this.ACTION.SORT_CHANGE: {
        this.changeSortType(event.row);
        break;
      }
      case this.ACTION.REMOVE: {
        event.row.deleted = true;
        if (isSortColumn) {
          const sortItem = this.sortPropertySectionDataTable.find(
            (item) =>
              item.entityPropertyId === event.row.entityPropertyId &&
              item.entityReferencePropertyId === event.row.entityReferencePropertyId &&
              (!item.option || item.option === event.row.option)
          );
          if (sortItem) {
            sortItem.deleted = true;
          }
        }

        const index = this.sortRowSectionDataTable.findIndex(
          (item) =>
            item.entityPropertyId === event.row.entityPropertyId &&
            item.entityReferencePropertyId === event.row.entityReferencePropertyId &&
            (!item.option || item.option === event.row.option)
        );
        if (index > -1) {
          this.sortRowSectionDataTable[index].deleted = true;
          this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
        }
      }
    }
  }

  resetAddPropertyForm() {
    this.formData.reset();
    this.propertyOptions = [];
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDataTable();
        this.getAvailablePropertyConfigs();
        this.resetAddPropertyForm();
        this.canSubmit = false;
      }
    });
  }

  changeProperty() {
    // format entityPropertyId_entityReferencePropertyId
    const arrId = this.formData.value.entityPropertyId.split('_');
    const selectedConfig = (this.propertyConfigs as any).find(
      (config: EntityPropertyDisplayConfiguration | any) => {
        if (arrId.length > 1) {
          return (
            config.entityPropertyId === arrId[0] &&
            config.entityReferencePropertyId === arrId[1] 
          );
        } else {
          return (
            config.entityPropertyId === this.formData.value.entityPropertyId
          );
        }
      }
    );
    let formValue: any = {
      option: '',
      columnName: selectedConfig?.propertyName || '',
      type: selectedConfig?.type,
      entityNameRef: selectedConfig?.entityNameRef,
      entityPropertyId: this.formData.value.entityPropertyId,
      entityReferencePropertyId : selectedConfig?.entityReferencePropertyId,
    };
    let options: any[] = [];
    if (selectedConfig?.options) {
      options = Object.keys(selectedConfig.options).map((key) => {
        return { 
          key: key, 
          value: selectedConfig.options[key] 
        }
      });
    }
    
    this.propertyOptions = (options || []).reduce(
      (result: any, currentValue: any) => {
        const selectedOptions = this.sortPropertySectionDataTable.reduce(
          (selectedOptionResult, currentItem) => {
            this.sortPropertySectionDataTable.forEach((property) => {
              if (
                property.entityPropertyId === selectedConfig.entityPropertyId &&
                property.entityReferencePropertyId === selectedConfig.entityReferencePropertyId
              ) {
                selectedOptionResult.push(
                  ...property.option.split(VERTICAL_LINE_SEPARATOR)
                );
              }
            });
            return selectedOptionResult;
          },
          [] as any[]
        );
        
        let selectedItem: any = {
          value: currentValue.key,
          label: currentValue.value,
        };
        const checked = !!selectedOptions.find(
          (selectedOption: any) => selectedOption === currentValue.key
        );
        if (options?.length > 1 && checked) {
          return result;
        }
        result.push(selectedItem);
        return result;
      },
      [] as any[]
    );
    const firstUncheckedItem = (this.propertyOptions as any[]).find(
      (propertyOption: any) =>
        !propertyOption.checked && !propertyOption.disabled
    );
    if (firstUncheckedItem) {
      firstUncheckedItem.checked = true;
    }
    formValue = {
      ...formValue,
      option: firstUncheckedItem?.value || '',
      columnName: selectedConfig.propertyName,
      propertyName: selectedConfig.propertyName,
      entityPropertyId: this.formData.value.entityPropertyId,
      entityNameRef: selectedConfig?.entityNameRef,
      entityReferencePropertyId: selectedConfig?.entityReferencePropertyId,
      type: selectedConfig?.type
    };
    this.formData.patchValue(formValue);
  }

  addProperty() {
    this.formData.markAllAsTouched();
    if (!this.formData.valid) {
      return;
    }
    const formValue = this.formData.value;
    const optionName = this.getOptionName(formValue.option);
    const addedIndex = this.availablePropertyConfigs.findIndex(
      (item) =>
        item.value === formValue.entityPropertyId
    );
    let itemAdd;
    if (addedIndex > -1) {
      itemAdd = this.availablePropertyConfigs[addedIndex];
    }
    let arrId = formValue.entityPropertyId.split('_');
    var newRecord: EntityPropertyDisplayConfiguration = {
      columnName: formValue.columnName,
      columnNameDescription: this.createDescription(formValue, optionName),
      order: this.sortPropertySectionDataTable?.length + 1,
      id: undefined,
      propertyName: this.availablePropertyConfigs[addedIndex]?.displayValue,
      type: formValue.type,
      option: formValue.option,
      entityPropertyId: arrId[0],
      entityReferencePropertyId: arrId[1] ? arrId[1] : null,
      optionName: this.availablePropertyConfigs[addedIndex]?.displayValue,
      isUsed: !this.sortRowSection,
      columnNameMaxLength: this.columnNameMaxLength,
    };

    this.sortPropertySectionDataTable = [
      ...this.sortPropertySectionDataTable,
      this.createInlineEditFormControls(newRecord),
    ];

    this.setAvailablePropertyConfigs(addedIndex);
    this.resetAddPropertyForm();
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  setAvailablePropertyConfigs(addedIndex: number) {
    const selectedPropertyOptions = (this.propertyOptions as any)
      .filter((item: any) => item.checked)
      .map((item: any) => item.value.propertyName);
    if (
      this.propertyOptions?.length > 1 &&
      !!this.propertyOptions?.length &&
      selectedPropertyOptions?.length < this.propertyOptions?.length
    ) {
      return;
    }
    if (
      !this.propertyOptions ||
      this.propertyOptions.length <= 1 ||
      !this.isMemberListConfiguration
    ) {
      this.availablePropertyConfigs.splice(addedIndex, 1);
    }
  }

  getOptionName(options: string) {
    return (this.propertyOptions as any).find(
      (item: RadioOption) => item.value === options
    )?.label;
  }

  addSortRow(config: EntityPropertyDisplayConfiguration) {
    if (
      this.sortRowSectionDataTable.find(
        (item) =>
          item.entityPropertyId === config.entityPropertyId &&
          item.entityReferencePropertyId === config.entityReferencePropertyId &&
          (!item.option || item.option === config.option)
      )
    ) {
      return;
    }
    this.canSubmit = true;
    this.formValueChange.emit();
    this.sortRowSectionDataTable = [
      ...this.sortRowSectionDataTable,
      {
        ...this.getPropertyDisplayConfig(config),
        columnNameDescription: config.columnNameDescription,
        order: this.sortRowSectionDataTable?.length + 1,
        sortType: this.sortable ? SORT_TYPE.ASC : undefined,
      },
    ];
  }

  onSubmit() {
    if (this.gridview.formStatus !== AbstractControlStatus.VALID) {
      return;
    }
    const result = this.getSubmitData();
    
    this.onSubmitEvent.emit(result);
    this.resetAddPropertyForm();
    this.canSubmit = false;
  }

  getSubmitData() {
    let order = 1;
    const result: (EntityPropertyDisplayConfiguration & Row)[] =
      this.sortPropertySectionDataTable.map(
        (item: EntityPropertyDisplayConfiguration & Row) => {
          const obj = {
            id: item.id,
            entityPropertyId: item.entityPropertyId,
            entityReferencePropertyId: item.entityReferencePropertyId,
            option: item.option ? item.option : '',
            columnName: item.form?.value?.columnName,
            orderColumn: item.deleted ? -1 : order
          };
          order += item.deleted ? 0 : 1;
          return obj;
        }
      );
    order = 1;
    this.sortRowSectionDataTable.forEach((config) => {
      const index = result.findIndex(
        (item) =>
          item.entityPropertyId === config.entityPropertyId &&
          item.entityReferencePropertyId === config.entityReferencePropertyId &&
          (!item.option || item.option === config.option)
      );
      if (index < 0) {
        return;
      }
      result[index].orderRow = config.deleted ? -1 : order;
      order += 1;
      result[index].sortType = config.sortType;
    });
    return result;
  }

  changeSortType(row: EntityPropertyDisplayConfiguration) {
    const index = this.sortRowSectionDataTable.findIndex(
      (item) =>
        item.entityPropertyId === row.entityPropertyId &&
        item.entityReferencePropertyId === row.entityReferencePropertyId &&
        (!item.option || item.option === row.option)
    );
    if (index < 0) {
      return;
    }
    this.sortRowSectionDataTable[index].sortType =
      this.sortRowSectionDataTable[index].sortType === SORT_TYPE.ASC
        ? SORT_TYPE.DESC
        : SORT_TYPE.ASC;
    this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
  }

  checkDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.sortPropertySectionDataTable?.length) {
        return null;
      }
      if (
        this.sortPropertySectionDataTable?.find(
          (item: { columnName: string }) =>
            item.columnName?.trim()?.toLowerCase() ===
            control.value?.trim()?.toLowerCase()
        )
      ) {
        return {
          duplicatedValue: 'Column Name already exists.',
        };
      }
      return null;
    };
  }

  isExisted(
    item:
      | EntityPropertyDisplayConfiguration
      | EntityPropertyDisplayConfig,
    config:
      | EntityPropertyDisplayConfiguration
      | EntityPropertyDisplayConfig,
    checkSelectMultipleTimes: boolean = true
  ) {
    const hasOptions =
      config.option instanceof Array &&
      config.option &&
      config.option.length > 1;
    const hasUnselectedOptions = !((config?.option || []) as any[]).some(
      (option: any) =>
        !this.propertyDisplayConfigurations.some(
          (property) =>
            property.entityPropertyId === (config as any).entityPropertyId &&
            property.entityReferencePropertyId === (config as any).entityReferencePropertyId &&
            property.option
              ?.split(VERTICAL_LINE_SEPARATOR)
              ?.includes(option.key)
        )
    );
    const isExistedProperty = item.entityPropertyId === config.entityPropertyId && item.entityReferencePropertyId === config.entityReferencePropertyId;
    const isAllOptionSelected = hasOptions
      ? hasUnselectedOptions
      : isExistedProperty;
    if (checkSelectMultipleTimes) {
      return (isAllOptionSelected);
    }
    return isAllOptionSelected;
  }

  onChangeOrderColumns() {
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  private checkExits(obj: EntityPropertyDisplayConfiguration): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allRows = [...this.sortPropertySectionDataTable];
      const existed = allRows.some(
        (item) =>
          !(
            item.entityPropertyId === obj.entityPropertyId &&
            item.entityReferencePropertyId === obj.entityReferencePropertyId &&
            (!item.option || item.option === obj.option)
          ) &&
          item.form?.value?.columnName?.toLowerCase()?.trim() ===
            control.value.toLowerCase().trim()
      );
      return existed ? { existed: true } : null;
    };
  }

  getType(entityPropertyType: EntityPropertyType): string {
    if (
      entityPropertyType === EntityPropertyType.Calculation ||
      entityPropertyType === EntityPropertyType.System ||
      entityPropertyType === EntityPropertyType.Aggregation ||
      entityPropertyType === EntityPropertyType['Entity Reference'] ||
      entityPropertyType === EntityPropertyType.Identifier
    ) {
      return EntityPropertyType[entityPropertyType];
    } else {
      return 'Data';
    }
  }

  private createInlineEditFormControls(
    obj: EntityPropertyDisplayConfiguration
  ): EntityPropertyDisplayConfiguration {
    var result = {
      ...obj,
      form: this.fb.group({
        columnName: new FormControl(
          obj.columnName,
          getValidatorsFromColumns(
            this.orderColumns[0].name,
            this.orderColumns,
            obj
          )
        ),
      }),
    };
    return result;
  }
}
