import { createAction, props } from '@ngrx/store';

import { MemberNavigationList } from '@ptg-member/types/models';
import { EmployerNavigationList } from '@ptg-employer/models/employer-navigation-configuration.model';

import { Tab } from '../../layout/components/captionbar/types/caption-bar.model';
import { DisplayedTabName, SidebarOpenedStatus } from '../../layout/constance/layout.const';
import { MainLayoutHistory } from '../reducers/layout.reducer';
import { EmployerDetail, MenuGroup, TabName } from '../../layout/models/layout.model';
import { BulkContentItem } from '../models/bulk-content-item.model';
import { GetReportCategorySidebarResponse } from '@ptg-member/types/models/participant-report.model';

export const BulkContentItemRequest = '[BulkContent/API] Send Request';
export const BulkContentItemSuccess = '[BulkContent/API] Success';
export const BulkContentItemFailure = '[BulkContent/API] Failure';

export const AppContentItemRequest = '[AppContent/API] Send Request';
export const AppContentItemSuccess = '[AppContent/API] Success';
export const AppContentItemFailure = '[AppContent/API] Failure';

export const RelatedPersonNavigationItemRequest = '[RelatedPersonNavigation/API] Send Request';

export const ProfileNavigationItemRequest = '[ProfileNavigation/API] Send Request';
export const ProfileNavigationItemSuccess = '[ProfileNavigation/API] Success';
export const ProfileNavigationItemFailure = '[ProfileNavigation/API] Failure';

export const EmployerNavigationItemRequest = '[EmployerNavigation/API] Send Request';
export const EmployerNavigationItemSuccess = '[EmployerNavigation/API] Success';
export const EmployerNavigationItemFailure = '[EmployerNavigation/API] Failure';

export const CloseSideMenu = '[SideMenu] Close';
export const OpenSideMenu = '[SideMenu] Open';
export const HiddenSideMenu = '[SideMenu] Hidden';
export const SetSidebarOpenedStatus = '[SideMenu] SetSidebarOpenedStatus';
export const SelectTab = '[Tabs] Select';
export const SetTabUrl = '[Tabs] SetUrl';
export const HideAllTab = '[Tabs] Hide All';
export const SetTabs = '[Tabs] Set Tabs';
export const SetTabHiddenState = '[Tabs] Set Tab Hidden State';
export const CloseTab = '[Tabs] Close';
export const CloseTabSuccess = '[Tabs] Close Tab Success';
export const ChangePage = '[Page] Change Page';
export const ChangePageSuccess = '[Page] Change Page Success';
export const SetAllMenuGroups = '[SideMenu] Set All Menu Groups';
export const ResetAllMenuGroups = '[SideMenu] Reset All Menu Groups';
export const SetHistory = '[History] Set History';

export const getBulkContentItemRequest = createAction(
  BulkContentItemRequest
);
export const getBulkContentItemRequestSuccess = createAction(
  BulkContentItemSuccess,
  props<{ items: BulkContentItem[] }>()
);
export const getBulkContentItemRequestFailure = createAction(
  BulkContentItemFailure,
	props<{ errorMsg: string }>()
);

export const getAppContentItemRequest = createAction(
	AppContentItemRequest
);
export const getAppContentItemRequestSuccess = createAction(
	AppContentItemSuccess,
	props<{ items: any[] }>()
);
export const getAppContentItemRequestFailure = createAction(
	AppContentItemFailure,
	props<{ error?: any }>()
);

export const closeSideMenu = createAction(
	CloseSideMenu
);
export const openSideMenu = createAction(
  OpenSideMenu
);
export const hiddenSideMenu = createAction(
  HiddenSideMenu
);
export const setSidebarOpenedStatus = createAction(
	SetSidebarOpenedStatus,
	props<{ sidebarOpenedStatus: SidebarOpenedStatus }>()
);

export const selectTab = createAction(
  SelectTab,
  props<{ tab: TabName, url?: string }>()
);
export const setTabUrl = createAction(
  SetTabUrl,
  props<{ tab: TabName, url: string }>()
);
export const hideAllTabs = createAction(
  HideAllTab,
);
export const setTabs = createAction(
	SetTabs,
	props<{ tabs: Tab[] }>()
);
export const setTabHiddenState = createAction(
	SetTabHiddenState,
	props<{ tabName: DisplayedTabName, isHidden: boolean }>()
);

export const closeTab = createAction(
  CloseTab,
	props<{ tabName?: DisplayedTabName }>()
);

export const setHistory = createAction(
	SetHistory,
	props<{ history?: MainLayoutHistory }>()
);

export const changePage = createAction(
	ChangePage
);
export const changePageSuccess = createAction(
	ChangePageSuccess
);
export const setAllMenuGroups = createAction(
	SetAllMenuGroups,
	props<{ allMenuGroups: Partial<Record<DisplayedTabName, MenuGroup[]>> }>()
);
export const resetAllMenuGroups = createAction(
	ResetAllMenuGroups
);
export const relatedPersonNavigationItemRequest = createAction(
	RelatedPersonNavigationItemRequest,
	props<{ memberId: string, isRelatedPerson?: boolean }>()
);
export const profileNavigationItemRequest = createAction(
	ProfileNavigationItemRequest,
	props<{ memberId: string, entityReferenceLinkedId?: string, isRelatedPerson?: boolean, isHyperlink?: boolean }>()
);
export const profileNavigationItemSuccess = createAction(
	ProfileNavigationItemSuccess,
	props<{ memberNavigationList: MemberNavigationList, memberId: string, isRelatedPerson?: boolean, isHyperlink?: boolean }>()
);
export const profileNavigationItemFailure = createAction(
	ProfileNavigationItemFailure,
	props<{ errorMsg: string }>()
);
export const clearProfileNavigationItemState = createAction(
	'[ClearProfileNavigationItemState] Clear'
);
export const employerNavigationItemRequest = createAction(
	EmployerNavigationItemRequest,
	props<{ employerId: string }>()
);
export const employerNavigationItemSuccess = createAction(
	EmployerNavigationItemSuccess,
	props<{ employerNavigationList: EmployerNavigationList, employerId: string }>()
);
export const employerNavigationItemFailure = createAction(
	EmployerNavigationItemFailure,
	props<{ errorMsg: string }>()
);

export const GetReportCategorySidebarRequest = '[GetReportCategorySidebar/API] Send Request';
export const GetReportCategorySidebarSuccess = '[GetReportCategorySidebar/API] Success';
export const GetReportCategorySidebarFailure = '[GetReportCategorySidebar/API] Failure';

export const getReportCategorySidebar = createAction(GetReportCategorySidebarRequest);
export const getReportCategorySidebarSuccess = createAction(
  GetReportCategorySidebarSuccess,
  props<{ res: GetReportCategorySidebarResponse }>(),
);
export const getReportCategorySidebarFailure = createAction(GetReportCategorySidebarFailure, props<{ error: any }>());

export const setCurrentMenuGroups = createAction(
  '[SideMenu] Set Current Menu Groups',
  props<{ menuGroups: MenuGroup[] }>(),
);
export const setCurrentUrl = createAction('[SideMenu] Set Current Url', props<{ url: string }>());

// Get Employer Info For Header
export const getEmployerInfoForHeaderAction = createAction(
  '[GetEmployerInfoForHeader/API] Send Request',
  props<{ employerId: string }>()
);
export const getEmployerInfoForHeaderSuccessAction = createAction(
  '[GetEmployerInfoForHeader/API] Success',
  props<{ response: EmployerDetail }>()
);
export const getEmployerInfoForHeaderFailureAction = createAction(
  '[GetEmployerInfoForHeader/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetEmployerInfoForHeaderStateAction = createAction(
  '[GetEmployerInfoForHeader] Clear'
);
