export const VERTICAL_LINE_SEPARATOR: string = '|';
export const COMMA_SEPARATOR: string = ', ';
export const REGEX_DOT: RegExp = /%2E/g;

export const RETIREMEMNT_CALC_IGNORE = ['censusDate', 'estimatorAccessibility', 'firstDateEligibleToRetire', 'initialServiceProvided'];

export enum AppKey {
  PensionEmployer = 'PensionEmployer',
  PensionAdmin = 'PensionAdmin',
}
export enum DomainType {
  EmployerPortal = 'EmployerPortal',
  AdminPortal = 'AdminPortal',
  MunicipalityPortal = 'MunicipalityPortal',
}

export enum VersionFlag {
  BVFF_Part_1 = 'BVFF_Part_1',
  BVFF_Part_2 = 'BVFF_Part_2',
  Chicago_Park_Part_1 = 'Chicago_Park_Part_1',
  Chicago_Park_Part_2 = 'Chicago_Park_Part_2',
  Chicago_Park_Part_3 = 'Chicago_Park_Part_3',
  Parity_Legacy = 'Parity_Legacy',
  Release_2 = 'Release_2',
}

export enum VIEW_TYPE {
  System,
  Summary,
  Detail,
}
