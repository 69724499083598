import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import {
  clearParticipantListConfigurationState,
  getEntityPropertyListFailure, 
  getEntityPropertyListSuccess, 
  getParticipantListConfigurationFailure, 
  getParticipantListConfigurationSuccess, 
  setParticipantListConfigurationFailure, 
  setParticipantListConfigurationSuccess 
} from '../actions';
import { 
  EntityPropertyDisplayConfig, 
  EntityPropertyDisplayConfiguration 
} from '../../services/models';

export const entityListViewConfigurationFeatureKey = 'entityListViewConfiguration';

export interface EntityListViewConfigurationState {
  isLoading: boolean;
  propertyConfigs: EntityPropertyDisplayConfig[];
  fixPersonProperties: EntityPropertyDisplayConfig[];
  propertyDisplayConfigurations: EntityPropertyDisplayConfiguration[];
  updateState: string;
}

const initialState: EntityListViewConfigurationState = {
  isLoading: true,
  propertyConfigs: [],
  fixPersonProperties: [],
  propertyDisplayConfigurations: [],
  updateState: '',
};

export const entityListViewConfigurationReducer = createReducer(
  initialState,
  on(clearParticipantListConfigurationState,
    (state) => ({
      ...state,
      updateState: '',
    })
  ),
  on(getEntityPropertyListSuccess,
    (state, { propertyConfigs, fixPersonProperties }) => ({
      ...state,
      isLoading: false,
      propertyConfigs,
      fixPersonProperties
    })
  ),
  on(getEntityPropertyListFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyConfigs: [],
    })
  ),
  on(getParticipantListConfigurationSuccess,
    (state, { propertyDisplayConfigurations }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations,
    })
  ),
  on(getParticipantListConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
    })
  ),
  on(setParticipantListConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      updateState: STATE.SUCCESS,
    })
  ),
  on(setParticipantListConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
      updateState: STATE.FAIL,
    })
  )
);
