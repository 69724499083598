import { createAction, props } from '@ngrx/store';
import { AnnualCertificationInformation, CertificationHistoryList, CertificationHistoryQuery, DocuSignDocument, SigneeInformation } from 'src/app/employer/models/annual-certification.model';
import {
  AnnualCertificationConfirmationData,
  AnnualCertificationParticipant,
  AnnualCertificationParticipantList,
  AnnualCertificationParticipantsQuery,
  AnnualMetRequirementsSaveRequest, CompleteAnnualCertificationBody, ConfirmSignaturesRequestBody,
} from '../models/annual-certification-participants.model';
import { generateActionName } from '@ptg-shared/utils/common.util';

export const ClearUpdateAnnualCertificationState = '[ClearUpdateState] Clear';
export const ClearAnnualCertificationInformationState = '[ClearAnnualCertificationInformationState] Clear';
export const AnnualCertificationInformationRequest = '[AnnualCertificationInformation/API] Send Request';
export const AnnualCertificationInformationFailure = '[AnnualCertificationInformation/API] Failure';
export const AnnualCertificationInformationSuccess = '[AnnualCertificationInformation/API] Success';
export const AnnualCertificationUploadFileRequest = '[AnnualCertificationUploadFile/API] Send Request';
export const AnnualCertificationUploadFileSuccess = '[AnnualCertificationUploadFile/API] Success';
export const AnnualCertificationUploadFileFailure = '[AnnualCertificationUploadFile/API] Failure';
export const GetCertificationHistoryRequest = '[GetCertificationHistory/API] Send Request';
export const GetCertificationHistorySuccess = '[GetCertificationHistory/API] Success';
export const GetCertificationHistoryFailure = '[GetCertificationHistory/API] Failure';
export const AnnualCertificationParticipantsRequest = '[AnnualCertificationParticipants/API] Send Request';
export const AnnualCertificationParticipantsFailure = '[AnnualCertificationParticipants/API] Failure';
export const AnnualCertificationParticipantsSuccess = '[AnnualCertificationParticipants/API] Success';
export const ClearAnnualCertificationParticipantsState = '[ClearAnnualCertificationParticipantsState] Clear';
export const UpdateEndOfServiceRequest = '[UpdateEndOfService/API] Send Request';
export const UpdateEndOfServiceFailure = '[UpdateEndOfService/API] Failure';
export const UpdateEndOfServiceSuccess = '[UpdateEndOfService/API] Success';
export const UpdateMetRequirementRequest = '[UpdateMetRequirement/API] Send Request';
export const UpdateMetRequirementFailure = '[UpdateMetRequirement/API] Failure';
export const UpdateMetRequirementSuccess = '[UpdateMetRequirement/API] Success';
export const ClearAnnualCertificationState = '[ClearAnnualCertificationState] Clear';
export const SaveAnnualCertificationMetRequirementsRequest = '[SaveAnnualCertificationMetRequirements/API] Send Request';
export const SaveAnnualCertificationMetRequirementsSuccess = '[SaveAnnualCertificationMetRequirements/API] Success';
export const SaveAnnualCertificationMetRequirementsFailure = '[SaveAnnualCertificationMetRequirements/API] Failure';
export const CompleteAnnualCertificationRequest = '[CompleteAnnualCertification/API] Send Request';
export const CompleteAnnualCertificationSuccess = '[CompleteAnnualCertification/API] Success';
export const CompleteAnnualCertificationFailure = '[CompleteAnnualCertification/API] Failure';
export const VerifyCashJournalRequest = '[VerifyCashJournal/API] Send Request';
export const VerifyCashJournalSuccess = '[VerifyCashJournal/API] Success';
export const VerifyCashJournalFailure = '[VerifyCashJournal/API] Failure';
export const ConfirmationBeforePostingAnnualCertificationRequest = '[ConfirmationBeforePostingAnnualCertification/API] Send Request';
export const ConfirmationBeforePostingAnnualCertificationSuccess = '[ConfirmationBeforePostingAnnualCertification/API] Success';
export const ConfirmationBeforePostingAnnualCertificationFailure = '[ConfirmationBeforePostingAnnualCertification/API] Failure';
export const SetAnnualCertificationConfirmSignaturesRequest = '[SetAnnualCertificationConfirmSignatures/API] Send Request';
export const SetAnnualCertificationConfirmSignaturesSuccess = '[SetAnnualCertificationConfirmSignatures/API] Success';
export const SetAnnualCertificationConfirmSignaturesFailure = '[SetAnnualCertificationConfirmSignatures/API] Failure';


export const clearUpdateAnnualCertificationState = createAction(
  ClearUpdateAnnualCertificationState,
);
export const getAnnualCertificationInformation = createAction(
  AnnualCertificationInformationRequest,
  props<{ municipalityId: string, annualCertId?: string }>()
);
export const getAnnualCertificationInformationSuccess = createAction(
  AnnualCertificationInformationSuccess,
  props<{ annualCertificationInformation: AnnualCertificationInformation }>()
);
export const getAnnualCertificationInformationFailure = createAction(
  AnnualCertificationInformationFailure,
  props<{ error?: any }>()
);

export const annualCertificationUploadFileRequest = createAction(
  AnnualCertificationUploadFileRequest,
  props<{ annualCertId: string }>()
);
export const annualCertificationUploadFileSuccess = createAction(
  AnnualCertificationUploadFileSuccess
);
export const annualCertificationUploadFileFailure = createAction(
  AnnualCertificationUploadFileFailure
);

export const getAnnualCertificationParticipants = createAction(
  AnnualCertificationParticipantsRequest,
  props<{ annualCertId: string, query: AnnualCertificationParticipantsQuery }>()
);
export const getAnnualCertificationParticipantsSuccess = createAction(
  AnnualCertificationParticipantsSuccess,
  props<{ annualCertificationParticipantList: AnnualCertificationParticipantList }>()
);
export const getAnnualCertificationParticipantsFailure = createAction(
  AnnualCertificationParticipantsFailure,
  props<{ error?: any }>()
);
export const clearGetAnnualCertificationParticipantsStateAction = createAction(
  ClearAnnualCertificationParticipantsState
);

export const getCertificationHistoryList = createAction(
  GetCertificationHistoryRequest,
  props<{ query: CertificationHistoryQuery }>()
);
export const getCertificationHistorySuccess = createAction(
  GetCertificationHistorySuccess,
  props<{ data: CertificationHistoryList }>()
);
export const getCertificationHistoryFailure = createAction(
  GetCertificationHistoryFailure,
  props<{ error?: any }>()
);

export const updateEndOfService = createAction(
  UpdateEndOfServiceRequest,
  props<{ annualCertificationParticipant: AnnualCertificationParticipant, originData: AnnualCertificationParticipant }>()
);
export const updateEndOfServiceSuccess = createAction(
  UpdateEndOfServiceSuccess
);
export const updateEndOfServiceFailure = createAction(
  UpdateEndOfServiceFailure,
  props<{ error?: any, originData: AnnualCertificationParticipant }>()
);

export const updateMetRequirement = createAction(
  UpdateMetRequirementRequest,
  props<{ annualCertificationParticipant: AnnualCertificationParticipant }>()
);
export const updateMetRequirementSuccess = createAction(
  UpdateMetRequirementSuccess
);
export const updateMetRequirementFailure = createAction(
  UpdateMetRequirementFailure,
  props<{ errorMsg: string }>()
);

export const clearAnnualCertificationState = createAction(
  ClearAnnualCertificationState
);

export const saveAnnualCertificationMetRequirements = createAction(
  SaveAnnualCertificationMetRequirementsRequest,
  props<{ annualCertId: string, annualMetRequirementsSaveRequest: AnnualMetRequirementsSaveRequest }>()
);

export const saveAnnualCertificationMetRequirementsSuccess = createAction(
  SaveAnnualCertificationMetRequirementsSuccess,
);

export const saveAnnualCertificationMetRequirementsFailure = createAction(
  SaveAnnualCertificationMetRequirementsFailure,
  props<{ error?: any }>()
);

export const completeAnnualCertification = createAction(
  CompleteAnnualCertificationRequest,
  props<{ annualCertId: string, completeAnnualCertificationBody?: CompleteAnnualCertificationBody }>()
);
export const completeAnnualCertificationSuccess = createAction(
  CompleteAnnualCertificationSuccess,
);
export const completeAnnualCertificationFailure = createAction(
  CompleteAnnualCertificationFailure,
  props<{ error?: any }>()
);

export const verifyCashJournal = createAction(
  VerifyCashJournalRequest,
  props<{ annualCertId: string, cashJournal: string }>()
);
export const verifyCashJournalSuccess = createAction(
  VerifyCashJournalSuccess,
);
export const verifyCashJournalFailure = createAction(
  VerifyCashJournalFailure,
  props<{ error?: any }>()
);

export const confirmationBeforePostingAnnualCertification = createAction(
  ConfirmationBeforePostingAnnualCertificationRequest,
  props<{ annualCertId: string }>()
);
export const confirmationBeforePostingAnnualCertificationSuccess = createAction(
  ConfirmationBeforePostingAnnualCertificationSuccess,
  props<{ annualCertificationConfirmationData: AnnualCertificationConfirmationData }>()
);
export const confirmationBeforePostingAnnualCertificationFailure = createAction(
  ConfirmationBeforePostingAnnualCertificationFailure,
  props<{ error?: any }>()
);
export const clearAnnualCertificationInformationState = createAction(
  ClearAnnualCertificationInformationState,
);

export const setAnnualCertificationConfirmSignatures = createAction(
  SetAnnualCertificationConfirmSignaturesRequest,
  props<{ confirmSignaturesRequestBody: ConfirmSignaturesRequestBody }>()
);
export const setAnnualCertificationConfirmSignaturesSuccess = createAction(
  SetAnnualCertificationConfirmSignaturesSuccess,
);
export const setAnnualCertificationConfirmSignaturesFailure = createAction(
  SetAnnualCertificationConfirmSignaturesFailure,
  props<{ error?: any }>()
);

export const getListSignee = createAction(
  generateActionName('ListSignee/API').Send,
  props<{ municipalityId: string }>()
);
export const getListSigneeSuccess = createAction(
  generateActionName('ListSignee/API').Success,
  props<{list: SigneeInformation[]}>()
);
export const getListSigneeFailure = createAction(
  generateActionName('ListSignee/API').Failure,
  props<{ error?: any }>()
);

export const sendSigneeToDocuSign = createAction(
  generateActionName('SendSignee/API').Send,
  props<{ body: DocuSignDocument }>()
);
export const sendSigneeToDocuSignSuccess = createAction(
  generateActionName('SendSignee/API').Success,
  props<{ body: DocuSignDocument }>()
);
export const sendSigneeToDocuSignFailure = createAction(
  generateActionName('SendSignee/API').Failure,
  props<{ error?: any }>()
);

export const updateSigneeToDocuSign = createAction(
  generateActionName('UpdateSignee/API').Send,
  props<{ body: DocuSignDocument }>()
);
export const updateSigneeToDocuSignSuccess = createAction(
  generateActionName('UpdateSignee/API').Success,
  props<{ body: DocuSignDocument }>()
);
export const updateSigneeToDocuSignFailure = createAction(
  generateActionName('UpdateSignee/API').Failure,
  props<{ error?: any }>()
);

export const sendReminderToDocuSign = createAction(
  generateActionName('SendReminder/API').Send,
  props<{ documentId: string }>()
);
export const sendReminderToDocuSignSuccess = createAction(
  generateActionName('SendReminder/API').Success,
);
export const sendReminderToDocuSignFailure = createAction(
  generateActionName('SendReminder/API').Failure,
  props<{ error?: any }>()
);
export const clearUpdateSigneeState = createAction(
  generateActionName('SendReminder/API').Clear,
);
