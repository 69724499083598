import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';

import * as FundListActions from '../actions/fund-list.actions';
import { FundModel, GetNotificationAlertResponse, TimeZone } from '../models/fund-list.model';
import { STATE } from '@ptg-shared/constance/value.const';
import { EmployerSystemInfo } from '@ptg-employer/models/employer.model';

export const currentFundFeatureKey = 'fundList';

export interface State {
  currentFund: FundModel;
  getFundList: BaseActionState<FundModel[]>;
  changeStatus?: string;
  tmp?: any;
  addFund: any;
  fundLayout: any;
  allFund?: FundModel[];
  removeFund?: any;
  timeZones: TimeZone[];
  notificationAlertModel: BaseActionState<GetNotificationAlertResponse>;
  createNotificationAlert?: BaseActionState<string>;
  currentEmployer?: EmployerSystemInfo
}

const initialState: State = {
  currentFund: {} as any,
  getFundList: {
    isLoading: true
  },
  addFund: {
    isSuccess: '',
    isLoading: false
  },
  removeFund: {
    isSuccess: ''
  },
  fundLayout: {},
  timeZones: [],
  notificationAlertModel: {
    isLoading: true,
  },
};

export const reducer = createReducer(
  initialState,
  // Even though the `state` is unused, it helps infer the return type
  on(FundListActions.selectFund, (state: State, { currentFund }) => ({
    ...state,
    currentFund,
    changeStatus: ''
  })),
  on(FundListActions.resetCurrentFund, (state: State) => ({
    ...state,
    currentFund: {} as FundModel
  })),

  on(FundListActions.getFundList, (state: State, { query }) => ({
    ...state,
    getFundList: { isLoading: true },
  })),
  on(FundListActions.getFundListSuccess, (state: State, { response }) => ({
    ...state,
    getFundList: {
      isLoading: false,
      success: true,
      payload: response.clients,
      total: response.total,
      error: undefined,
    }
  })),
  on(FundListActions.getFundListFailure, (state: State, { error }) => ({
    ...state,
    getFundList: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(FundListActions.changeStatusFund, (state: State, { active, id }) => ({
    ...state,
    currentFund: { ...state.currentFund, active: active }
  })),
  on(FundListActions.changeStatusSuccess, (state: State, { active, id }) => {
    let newArr = JSON.parse(JSON.stringify([...state.getFundList.payload!]));
    newArr.find((el: any) => el.id === id).active = active;
    return { ...state, getFundList: { ...state.getFundList, clients: newArr }, allFund: newArr};
  }),
  on(FundListActions.changeStatusFailure, (state: State, { active, id }) => ({
    ...state,
    currentFund: { ...state.currentFund, active: !active },
    changeStatus: 'Fail'
  })),
  on(FundListActions.changeStatusClear, (state: State) => ({
    ...state,
    changeStatus: ''
  })),
  on(FundListActions.removeFundSuccess, (state: State) => ({
    ...state,
    removeFund: { isSuccess: 'Success'}
  })),
  on(FundListActions.removeFundFailure, (state: State, { errorMsg }) => ({
    ...state,
    removeFund: { isSuccess: 'Fail' }
  })),
  on(FundListActions.removeFundReset, (state: State) => ({
    ...state,
    removeFund: { isSuccess: '' }
  })),

  on(FundListActions.addNewFund, (state: State, { dataFund }) => ({
    ...state,
    addFund: { isLoading: true }
  })),
  on(FundListActions.addNewFundSuccess, (state: State, { response }) => ({
    ...state,
    addFund: { isLoading: false, isSuccess: response ? 'Success' : 'Fail' }
  })),
  on(FundListActions.addNewFundFailure, (state: State, { errorMsg }) => ({
    ...state,
    addFund: { isLoading: false, isSuccess: 'Fail' }
  })),
  on(FundListActions.addNewFundReset, (state: State) => ({
    ...state,
    addFund: { isSuccess: '', isLoading: false }
  })),

  on(FundListActions.updateFundLayout, (state: State, { updateLayout }) => ({
    ...state,
    fundLayout: { ...updateLayout }
  })),
  on(FundListActions.getAllFundSuccess, (state: State, { response }) => ({
    ...state,
    allFund: response.clients
  })),
  on(FundListActions.getTimeZonesSuccess, (state: State, { timeZones }) => ({
    ...state,
    timeZones: timeZones
  })),

  on(FundListActions.createNotificationAlert, (state: State, { request }) => ({
    ...state,
    createNotificationAlert: { isLoading: true },
  })),
  on(FundListActions.createNotificationAlertSuccess, (state: State, { response }) => ({
    ...state,
    createNotificationAlert: {
      isLoading: false,
      success: true,
      payload: response,
    }
  })),
  on(FundListActions.createNotificationAlertFailure, (state: State, { error }) => ({
    ...state,
    createNotificationAlert: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(FundListActions.createNotificationAlertFailure, (state: State, { error }) => ({
    ...state,
    createNotificationAlert: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(FundListActions.clearCreateNotificationAlertState, (state: State) => ({
    ...state,
    createNotificationAlert: undefined
  })),

  on(FundListActions.getNotificationAlertSuccess, (state: State, { response }) => ({
    ...state,
    notificationAlertModel: {
      isLoading: false,
      success: true,
      payload: response,
      error: undefined,
    }
  })),
  on(FundListActions.selectEmployer, (state: State, { employer }) => ({
    ...state,
    currentEmployer: employer,
  })),

  on(FundListActions.clearSelectEmnployerState, (state: State) => ({
    ...state,
    currentEmployer: undefined,
  })),
);
