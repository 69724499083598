import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { MemberListActions } from '@ptg-member/store/actions';
import { ColumnSetConfigRequest, ColumnSetPropertyDisplayConfig,
         ColumnSetPropertyDisplayConfiguration,
         SectionConfig }
from '@ptg-member/types/models';
import { BaseComponent } from '@ptg-shared/components';
import { filter, takeUntil } from 'rxjs/operators';
import * as fromMember from '../../store/reducers';
import { deepClone } from '@ptg-shared/utils/common.util';




@Component({
  selector: 'ptg-add-column-set',
  templateUrl: './add-column-set.component.html',
  styleUrls: ['./add-column-set.component.scss']
})
export class AddColumnSetComponent extends BaseComponent {
  propertyConfigs: ColumnSetPropertyDisplayConfig[] = [];
  propertyDisplayConfigurations: ColumnSetPropertyDisplayConfiguration[] = [];
  addPropertySection: SectionConfig = {
    title: 'Add Column',
    columnName: 'Column Name',
  };
  sortPropertySection: SectionConfig = { title: 'Order Columns' };
  sortRowSection: SectionConfig = { title: 'Sort Rows' };
  isMemberListConfiguration!: boolean;
  listColumnConfig = [];
  isLoading: boolean = true;
  constructor(  
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddColumnSetComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDetail: any,
    private memberStore: Store<fromMember.MemberState>
    ) { super();  }

  ngOnInit(): void {
    const query = {
      isIncludePropertyEntityReference: true,
      isIncludePropertyOfList: false,
      isUseFilter: false,
    };
    this.memberStore.dispatch(MemberListActions.getPropertyOfParticipantsRequestRequest({ entityId: this.dataDetail.entityId, query }));
    this.memberStore
    .pipe(
      select(fromMember.selectPropertyOfParticipants),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((res: any) => {
      this.isLoading = res?.isLoading;
      if( res?.propertyList?.propertyOfParticipants.length) {
        this.propertyConfigs = deepClone(res?.propertyList?.propertyOfParticipants).map((item: ColumnSetPropertyDisplayConfig) => {
          if(item.propertyNameEntityRef) {
            item.id = `${item.id}|${item.propertyRefId}`;
          }
          return item;
        });
      }
      
    });
    if(this.dataDetail.columSelected) {
      this.propertyDisplayConfigurations = deepClone(this.dataDetail.columSelected.listConfigs).map((item:ColumnSetPropertyDisplayConfiguration) => {
        if(item?.entityReferencePropertyId) {
          item.entityPropertyId = `${item.entityPropertyId}|${item.entityReferencePropertyId}`;
        }
        return item;
      });
    }
    this.listColumnConfig = this.dataDetail.listColumnConfig;
  }

  onSubmit(data: ColumnSetConfigRequest) {
    const cloneData = { ...data };
    delete cloneData.auditTrail;
    if(this.dataDetail?.columSelected) {
      this.memberStore.dispatch(MemberListActions.editColumnSetConfigurationRequest({ id: this.dataDetail.columSelected.id, body: cloneData }));
    } else {
      this.memberStore.dispatch(MemberListActions.createColumnSetConfigurationRequest({ body: cloneData, entityId: this.dataDetail.entityId  })); 
    }
    this.dialogRef.close(data);
  }
}
