import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { takeUntil, filter } from 'rxjs/operators';

import { ACTION, STATE } from '@ptg-shared/constance';
import { BaseComponent } from '@ptg-shared/components';

import {
  CalculationState,
  getExceptionConfigurationDetailAction,
  getExceptionConfigurationDetailSelector,
  clearGetExceptionConfigurationDetailStateAction,
  setExceptionConfigurationsDetailAction,
  setExceptionConfigurationDetailSelector,
  clearSetExceptionConfigurationsDetailStateAction,
} from '../../store';
import { Store, select } from '@ngrx/store';
import {
  BenefitDetail,
  AdditionalInfo,
  PropertyDetail,
  SaveExceptionConfigurationDetailRequest,
} from '../../services/models/exception-configuration.model';

import { showBanner } from '@ptg-shared/utils/common.util';
import { Option } from '@ptg-shared/controls/select/select.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { capitalizeFirstLetterOfEachWord } from '@ptg-shared/utils/string.util';
import { EditExceptionConfigurationComponentService } from './edit-exception-configuration.component.service';

@Component({
  selector: 'ptg-edit-exception-configuration',
  templateUrl: './edit-exception-configuration.component.html',
  styleUrls: ['./edit-exception-configuration.component.scss'],
  providers: [EditExceptionConfigurationComponentService],
})
export class EditExceptionConfigurationComponent extends BaseComponent {
  title = '';
  memberId = '';

  message = '';
  listBreadcrumbs: Breadcrumb[] = [];
  bannerType: BannerType = BannerType.Hidden;

  exceptionId = '';
  exceptionType?: number;
  savedDescription = '';
  savedBenefitDetailList: BenefitDetail[] = [];
  savedEntityDetailList: PropertyDetail[] = [];

  benefitOptionList: Option[] = [];
  entityList: Option[] = [];
  memberEntityData: Option[] = [];

  additionalBenefitValueObj?: AdditionalInfo;

  isShowEntityListDropdown = false;
  isShowAdditionalBenefitValueTextBox = false;

  isShowBenefitBeginDateDropdown = false;

  benefitHasInProcessingCalculation: string[] = [];
  isPropertiesEditable = true;
  isEditingValidation: boolean | null = null;

  constructor(
    public readonly dialog: MatDialog,
    public readonly route: ActivatedRoute,
    public readonly dialogRef: MatDialogRef<EditExceptionConfigurationComponent>,

    private readonly calculationStore: Store<CalculationState>,
    private readonly editExceptionConfigurationComponentService: EditExceptionConfigurationComponentService,
  ) {
    super();
  }

  ngOnInit(): void {
    // Init API's listeners
    this.getSnapshotRouteData();
    this.getExceptionConfigurationDetailData();
    this.getSetExceptionConfigurationDetailResponse();

    this.initAPIData();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.calculationStore.dispatch(clearGetExceptionConfigurationDetailStateAction());
    this.calculationStore.dispatch(clearSetExceptionConfigurationsDetailStateAction());
  }

  getSubmitEvent(data: SaveExceptionConfigurationDetailRequest): void {
    this.calculationStore.dispatch(
      setExceptionConfigurationsDetailAction({
        exceptionId: this.exceptionId,
        request: data,
      }),
    );
  }

  getCancelEvent(): void {
    this.initAPIData();
  }

  private initAPIData(): void {
    this.exceptionType = undefined;
    this.savedDescription = '';
    this.isEditingValidation = null;
    this.calculationStore.dispatch(getExceptionConfigurationDetailAction({ request: this.exceptionId }));
  }

  private getSnapshotRouteData(): void {
    const params = this.route.snapshot.params;

    if (params?.memberId) {
      this.memberId = params.memberId;
    }

    if (params?.exceptionId) {
      this.exceptionId = params.exceptionId;
    }
  }

  private getExceptionConfigurationDetailData(): void {
    this.calculationStore
      .pipe(
        select(getExceptionConfigurationDetailSelector),
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response?.payload) {
          return;
        }
        this.calculationStore.dispatch(clearGetExceptionConfigurationDetailStateAction());

        const exceptionName = response?.payload?.exceptionName ?? 'Edit Exception Configuration';
        this.title = capitalizeFirstLetterOfEachWord(exceptionName);

        this.listBreadcrumbs = this.getBreadcrumbs;

        const exceptionType = response?.payload?.exceptionType;
        this.initEditDataByExceptionType(exceptionType);

        const {
          description,
          savedBenefitDetailList,
          savedEntityDetailList,
          benefitOptionList,
          entityList,
          benefitHasInProcessingCalculation,
          isPropertiesEditable,
          isEditingValidation,
          memberEntityData,
        } = this.editExceptionConfigurationComponentService.getMappingDetailData(response?.payload);

        this.exceptionType = exceptionType;
        this.savedDescription = description ?? '';
        this.savedBenefitDetailList = savedBenefitDetailList;
        this.savedEntityDetailList = savedEntityDetailList;
        this.benefitOptionList = benefitOptionList;
        this.entityList = entityList;
        this.benefitHasInProcessingCalculation = benefitHasInProcessingCalculation;
        this.isPropertiesEditable = isPropertiesEditable;
        this.isEditingValidation = isEditingValidation;
        this.memberEntityData = memberEntityData;
      });
  }

  private getSetExceptionConfigurationDetailResponse(): void {
    this.calculationStore
      .pipe(
        select(setExceptionConfigurationDetailSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const isSuccess = response?.success;
        this.calculationStore.dispatch(clearSetExceptionConfigurationsDetailStateAction());
        showBanner.call(this, isSuccess ? STATE.SUCCESS : STATE.FAIL, this.title, ACTION.EDIT);

        if (isSuccess) {
          this.initAPIData();
        }
      });
  }

  private initEditDataByExceptionType(exceptionType: number): void {
    this.isShowBenefitBeginDateDropdown =
      this.editExceptionConfigurationComponentService.exceptionsHaveBeginDate.includes(exceptionType);

    this.isShowEntityListDropdown =
      this.editExceptionConfigurationComponentService.exceptionsHaveEntityList.includes(exceptionType);

    this.isShowAdditionalBenefitValueTextBox =
      this.editExceptionConfigurationComponentService.exceptionsHaveBenefitOptionValue.includes(exceptionType);

    this.additionalBenefitValueObj =
      this.editExceptionConfigurationComponentService.getAdditionalBenefitValueObj(exceptionType);
  }

  private get getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: 'Exception Configuration List',
        url: `/member/calculation/exception-configuration/${this.memberId}`,
      },
      { name: this.title },
    ];
  }
}
