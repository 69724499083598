import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from '@ptg-shared/shared.module';
import { ControlsModule } from '@ptg-shared/controls/controls.module';

import { MODULE_KEY } from './constants';
import { entityManagementReducer } from './store/reducers';
import {
  EntityDataService,
  EntityListViewConfigurationService,
  EntityProfileConfigService,
  EntityPropertyService,
  EntityService,
  EntityViewService,
} from './services';
import { EntityManagementEffects } from './store/effects';
import {
  EditPropertyEntityReferenceComponent,
  UpsertEntityDataComponent,
  CreateEntityDataComponent,
} from './components';
import { OverviewHeaderEntityModule } from '@ptg-member/components/overview-header-entity/overview-header-entity.module';

const COMPONENTS = [EditPropertyEntityReferenceComponent, UpsertEntityDataComponent, CreateEntityDataComponent];
const EFFECTS = [...EntityManagementEffects];
const SERVICES = [
  EntityService,
  EntityDataService,
  EntityListViewConfigurationService,
  EntityProfileConfigService,
  EntityPropertyService,
  EntityViewService,
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    SharedModule,
    ControlsModule,
    StoreModule.forFeature(MODULE_KEY, entityManagementReducer),
    EffectsModule.forFeature(EFFECTS),
    OverviewHeaderEntityModule
  ],
  exports: [UpsertEntityDataComponent],
  providers: [...SERVICES],
})
export class EntityManagementSharedModule {}
