import { createReducer, on } from '@ngrx/store';
import { getAnnualFeeLetterAction, getAnnualFeeLetterSuccessAction, getAnnualFeeLetterFailureAction, clearGetAnnualFeeLetterStateAction, generateAnnualFeeLetterAction, generateAnnualFeeLetterSuccessAction, generateAnnualFeeLetterFailureAction, clearGenerateAnnualFeeLetterStateAction, clearValidateBeforeGenerateStateAction, validateBeforeGenerateAction, validateBeforeGenerateFailureAction, validateBeforeGenerateSuccessAction, clearGetAnnualFeeLetterDownloadStateAction, getAnnualFeeLetterDownloadAction, getAnnualFeeLetterDownloadFailureAction, getAnnualFeeLetterDownloadSuccessAction } from '@ptg-employer/actions/annual-fee-letter.action';
import { AnnualFeeLetter, ValidateBeforeGenerateResponse } from '@ptg-employer/models/annual-fee-letter.model';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';

export const annualFeeLetterFeatureKey = 'annualFeeLetter';

export interface AnnualFeeLetterState {
  getAnnualFeeLetterState?: BaseActionState<AnnualFeeLetter[]>;
  generateAnnualFeeLetterState?: {
    state: ActionState,
    errorMsg?: string,
  };
  validateBeforeGenerateState?: BaseActionState<ValidateBeforeGenerateResponse>;
  getAnnualFeeLetterDownloadState?: BaseActionState<Blob[]>;
}

const initialState: AnnualFeeLetterState = {};

export const annualFeeLetterReducer = createReducer(
  initialState,
  
  // Get Annual Fee Letter
  on(getAnnualFeeLetterAction, (state) => ({
    ...state,
    getAnnualFeeLetterState: {
      isLoading: true,
    },
  })),
  on(getAnnualFeeLetterSuccessAction, (state, { response }) => ({
    ...state,
    getAnnualFeeLetterState: {
      isLoading: false,
      success: true,
      payload: response.letters,
      total: response.total,
    },
  })),
  on(getAnnualFeeLetterFailureAction, (state, { error }) => ({
    ...state,
    getAnnualFeeLetterState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAnnualFeeLetterStateAction, (state) => ({
    ...state,
    getAnnualFeeLetterState: undefined,
  })),

  // Generate Annual Fee Letter
  on(generateAnnualFeeLetterAction, (state) => ({
    ...state,
    isLoading: true,
    generateAnnualFeeLetterState: undefined,
  })),
  on(generateAnnualFeeLetterSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    generateAnnualFeeLetterState: {
      state: {
        action: ACTION.GENERATE,
        state: STATE.SUCCESS,
      }
    },
  })),
  on(generateAnnualFeeLetterFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    generateAnnualFeeLetterState: {
      state: {
        action: ACTION.GENERATE,
        state: STATE.FAIL,
      },
      errorMsg: error,
    },
  })),
  on(clearGenerateAnnualFeeLetterStateAction, (state) => ({
    ...state,
    isLoading: false,
    generateAnnualFeeLetterState: undefined,
  })),

  // Validate Before Generate
  on(validateBeforeGenerateAction, (state) => ({
    ...state,
    validateBeforeGenerateState: {
      isLoading: true,
    },
  })),
  on(validateBeforeGenerateSuccessAction, (state, { response }) => ({
    ...state,
    validateBeforeGenerateState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(validateBeforeGenerateFailureAction, (state, { error }) => ({
    ...state,
    validateBeforeGenerateState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearValidateBeforeGenerateStateAction, (state) => ({
    ...state,
    validateBeforeGenerateState: undefined,
  })),

  // Download Annual Fee Letter
  on(getAnnualFeeLetterDownloadAction, (state) => ({
    ...state,
    getAnnualFeeLetterDownloadState: {
      isLoading: true,
    },
  })),
  on(getAnnualFeeLetterDownloadSuccessAction, (state, { response }) => ({
    ...state,
    getAnnualFeeLetterDownloadState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getAnnualFeeLetterDownloadFailureAction, (state, { error }) => ({
    ...state,
    getAnnualFeeLetterDownloadState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAnnualFeeLetterDownloadStateAction, (state) => ({
    ...state,
    getAnnualFeeLetterDownloadState: undefined,
  })),
);
