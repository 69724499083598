import { DateTime } from 'luxon';

export interface AccidentClaim {
  accidentClaimId?: string;
  accidentId?: string;
  accidentNumber?: number;
  accidentDate?: string;
  warrantRegisterNumber?: string;
  warrantBatchNumber?: string;
  vendorId?: string;
  vendorName?: string;
  vendorNumber?: string;
  disbursementTypeCode?: string;
  disbursementTypeName?: string;
  paymentDate?: string;
  paymentNumber?: string;
  amount?: string;
  disbursementReportPaymentId?: string;
}

export interface GetAccidentClaimsRequest {
  pageNumber?: number;
  pageSize?: number;
  sortNames?: string;
  sortType?: number;
}

export interface GetAccidentClaimsResponse {
  claims: AccidentClaim[];
  total: number;
}

export interface AccidentHistory {
  accidentId?: string;
  accidentNumber?: string;
  accidentDate?: string;
  receiveDate?: string;
  injuryCode?: string;
  injuryDescription?: string;
  municipalityId?: string;
  municipalityCode?: string;
  municipalityName?: string;
  accidentStatus?: string;
  isFatal?: boolean;
  canEditStatus?: boolean;
  canModifyAction?: boolean;
}

export interface GetAccidentHistoriesRequest {
  pageNumber?: number;
  pageSize?: number;
  sortNames?: string;
  sortType?: number;
  forInitiateDisability?: boolean;
}

export interface AccidentPostCardRequest {
  id?: string;
  clientDate: string;
  accidentDate?: string;
  receiveDate?: string;
  injuryCode?: string;
  municipalityId?: string;
  injuryDescription?: string;
  submitterName: string;
  submitterEmail: string;
  submitterPhone: string;
}

export interface AccidentReportRequest {
  id?: string;
  dayOffFromWork: number;
  isDrSeen: boolean;
  isHospitalized: boolean;
  isFatal: boolean;
  drName: string;
  sheriffName: string;
  accidentTimeHour: number;
  accidentTimeMinute: number;
  natureOfInjury: string;
  locationOfAccident: string;
  otherLocation: string | null;
}

export interface AccidentLetterRequest {
  memberId: string;
  accidentId: string;
  documentLocation?: string;
  documentLocationRouter?: string
}

export interface AccidentLetterResponse {
  documentName?: string;
}

export interface Accident {
  accidentId: string;
  accidentNumber: number;
  accidentDate: string;
  receiveDate: string;
  injuryCode: number;
  injuryCodeDescription: string;
  injuryDescription: string;
  municipalityId: string;
  municipalityCode: string;
  municipalityName: string;
  accidentStatus: number;
  isFatal: boolean;
  daysBetween: number;
  submitterName: string;
  submitterEmail: string;
  submitterPhone: string;
  canEditStatus?: boolean;
}

export interface AccidentReport {
  accidentReportId: string;
  dayOffFromWork: number;
  isDrSeen: boolean;
  isHospitalized: boolean;
  isFatal: boolean;
  drName: string;
  sheriffName: string;
  timeInHour: number;
  timeInMinute: number;
  natureOfInjury: string;
  locationOfAccident: string;
  otherLocation: string;
  accidentTime: string;
}

export interface ActionHistory {
  actionBy: string;
  actionTime: Date;
  actionType: number;
  note: string;
}

export interface GetAccidentHistoriesResponse {
  accidents: AccidentHistory[];
  total: number;
}

export enum AccidentStatus {
  Pending,
  Open,
  Approved,
  Denied,
}

export interface GetListMunicipalityResponse {
  municipalities: Municipality[];
}

export interface Municipality {
  municipalityId: string;
  municipalityCode: string;
  municipalityName: string;
}

export interface ViewAccidentDetailsResponse {
  accident: Accident;
  report: AccidentReport;
  latestAuditTrail: ActionHistory;
}

export interface AccidentDocument {
  id?: string;
  documentName?: string;
  documentType?: number;
  attachmentName?: string;
  dateCreated?: string;
}

export interface AccidentDocumentList {
  total?: number;
  documents: AccidentDocument[];
}

export interface AccidentDocumentDownloadRequest {
  memberId?: string;
  accidentId?: string;
  accidentDocumentId?: string;
}

export interface AccidentNote {
  id?: string;
  accidentId?: string;
  dateCreated?: string;
  createdBy?: string;
  note?: string;
  updatedBy?: string;
  isTruncate?: boolean;
}

export interface GetAccidentNotesResponse {
  notes: AccidentNote[];
  total: number;
}

export interface EditParticipantAccidentStatusRequest {
  status: string;
  note: string;
}

export interface UploadAccidentReportRequest {
  documentName: string,
  type: string,
  fileName?: string,
  tags?: string[];
  documentLocationTitle?: string;
  documentLocationRouter?: string;
  documentDescription?: string;
  showOnOverview?: boolean;
}

export interface EditAccidentRequest {
  documentName: string,
  tags: string[],
  documentDescription: string,
  showOnOverview: boolean,
  documentType: number,
}

export interface EmployerDetail {
  id: string,
  clientId: string,
  code: string,
  name: string,
  disabled: boolean,
  type: number,
}
