<ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"> </ptg-breadcrumb>
<div class="add-master-input-output" id="add-master-input-output">
  <form [formGroup]="formAddInputOutput" class="add-master-input-output-form">
    <div class="wrap-btn">
      <button mat-raised-button type="button" class="btn-save" id="btn-save" (click)="formSubmit$.next()">Save</button>
      <button mat-raised-button type="button" class="btn-cancel" id="btn-cancel" (click)="onCancel()">Cancel</button>
    </div>
    <div class="input-container">
      <ptg-radio-button
        [controlField]="parameterTypeControl"
        [listOption]="parameterTypeOptions"
        [isObject]="true"
      ></ptg-radio-button>
      <ptg-textbox
        [controlField]="nameControl"
        placeholder="Input/Output Name"
        [hasLabel]="true"
        [isRequired]="true"
        [maxLength]="255"
        [asyncFn]="checkInputOutputNameExistsAsyncFn"
        errorAsync="Input/Output Name already exists."
      ></ptg-textbox>
      <ptg-select
        [controlField]="dataTypeControl"
        placeholder="Data Type"
        [isOptionObj]="true"
        [isRequired]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listDataType"
      ></ptg-select>
      <ptg-input-area
        [controlField]="descriptionControl"
        placeholder="Description"
        [maxLength]="255"
        errorMaxLength="Exceed the 255 character limit."
      ></ptg-input-area>
      <ptg-select
        *ngIf="parameterTypeControl?.value === ParameterType.Input"
        [isDisabled]="!dataTypeControl.value"
        [controlField]="linkedEntityPropertyIdControl"
        placeholder="Linked Property"
        [hasNoneValue]="true"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [isMultipleLineOption]="true"
        [listData]="linkedProperties"
        (changeOptionValue)="onValueChangesLinkedProperty($event)"
      ></ptg-select>
      <ptg-select
        *ngIf="dataTypeControl?.value === PropertyType.Lookup"
        [controlField]="lookupTableControl"
        placeholder="Lookup Table"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listLookupTable"
        [isRequired]="true"
      ></ptg-select>
      <ptg-textbox
        *ngIf="
          dataTypeControl?.value === PropertyType.Decimal &&
          (parameterTypeControl?.value === ParameterType.Output || linkedEntityPropertyIdControl?.value)
        "
        [controlField]="fractionalLengthControl"
        [hasLabel]="true"
        [isDecimal]="false"
        [isPositive]="true"
        placeholder="Fractional Length"
        inputType="Number"
        [isRequired]="true"
        [min]="0"
        [max]="10"
      ></ptg-textbox>
      <ptg-select
        *ngIf="isShowReferenceControl"
        [controlField]="referenceControl"
        placeholder="Entity Reference Property"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listReference"
        [isRequired]="isShowReferenceControl"
        [isMultipleLineOption]="true"
      ></ptg-select>
      <ptg-select
        *ngIf="isShowConditionControl"
        [controlField]="conditionControl"
        placeholder="Condition For Selected Record"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listCondition"
        [isRequired]="isShowConditionControl"
        (changeOptionValue)="onValueChangeCondition($event)"
      ></ptg-select>
      <ptg-select
        *ngIf="isShowValueControl"
        [controlField]="valueControl"
        placeholder="Value"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listValue"
        [isRequired]="isShowValueControl"
      ></ptg-select>
    </div>
  </form>
</div>
