import { createAction, props } from '@ngrx/store';

import {
  BodyChangePropertyKey,
  PropertyRequest,
} from '@ptg-member/types/models';

import {
  EmployerList,
  GetListEmployerQuery,
  SectionConfig,
  EmployerInfoProperty,
  OverviewConfigurations,
  GetEmployerOverviewQuery,
  EmployerOverview,
  EmployerListProperty,
  EditEmployerPropertyRequestDto,
  PropertyDisplayConfiguration,
  EditEmployerPaymentPreferencesRequestDto,
  SectionType,
  EmployerContact,
  DefaultLookupTableOptions,
  EditEmployerInformationRequestDto,
  EmployerFileTemplate,
  EmployerFileProperty,
  SearchEmployerResponse,
  GetEmployerFilePropertiesRequestDto,
  MappingFieldSection,
  EmployerPortalUser,
  EmployerFilterConfig,
  EmployerFilterName,
  EmployerFilterInformation,
  FileLayoutConfiguration,
  FileLayoutData,
  EmployerFilePostingField,
  EmployerCheckDisableRequest,
  CreateEmployersRequest,
  SetEmployersRequest,
  EmployerColumnConfigs,
  EmployerColumnSetConfigRequest,
  ReorderEmployerColumnSetConfigRequest,
  CheckExistResponse,
  PayloadCheckEmail,
  RegisterCustomerStripeRequest,
  RegisterCustomerStripeResponse,
} from '../models/employer.model';
import { EmployerFilter } from '../models/employer-filter.model';
import {
  EmployerInformationConfiguration,
  EmployerPropertyConfiguration,
} from '@ptg-employer/models/employer-information-property.model';
import { generateActionName } from '@ptg-shared/utils/common.util';
import { PaymentMethod } from '@ptg-employer/models/employer.model';



export const EmployerListRequest = '[EmployerList/API] Send Request';
export const EmployerListFailure = '[EmployerList/API] Failure';
export const EmployerListSuccess = '[EmployerList/API] Success';

export const SearchEmployerRequest = '[SearchEmployer/API] Send Request';
export const SearchEmployerSuccess = '[SearchEmployer/API] Success';
export const SearchEmployerFailure = '[SearchEmployer/API] Failure';
export const ClearSearchEmployer = '[SearchEmployer/API] Clear';

export const EmployerOverviewRequest = '[EmployerOverview/API] Send Request';
export const EmployerOverviewFailure = '[EmployerOverview/API] Failure';
export const EmployerOverviewSuccess = '[EmployerOverview/API] Success';
export const GetOverViewConfigRequest = '[GetOverViewConfig/API] Send Request';
export const GetOverViewConfigSuccess = '[GetOverViewConfig/API] Success';
export const GetOverViewConfigFailure = '[GetOverViewConfig/API] Failure';
export const ClearOverViewConfig = '[GetOverViewConfig/API] Clear';
export const SaveOverViewConfigRequest = '[SaveOverViewConfig/API] Send Request';
export const SaveOverViewConfigSuccess = '[SaveOverViewConfig/API] Success';
export const SaveOverViewConfigFailure = '[SaveOverViewConfig/API] Failure';
export const GetListEmployerProperty = '[EmployerProperty/API] Send Request';
export const GetListEmployerPropertySuccess = '[EmployerProperty/API] Success';
export const GetListEmployerPropertyFailure = '[EmployerProperty/API] Failure';
export const PropertyOrder = '[PropertyOrder/API] Send Request';
export const AddEmployerPropertyRequest = '[AddEmployerProperty/API] Send Request';
export const AddEmployerPropertySuccess = '[AddEmployerProperty/API] Success';
export const AddEmployerPropertyFailure = '[AddEmployerProperty/API] Failure';
export const GetEmployerInfoPropertiesRequest = '[GetEmployerInfoProperties/API] Send Request';
export const GetEmployerInfoPropertiesSuccess = '[GetEmployerInfoProperties/API] Success';
export const GetEmployerInfoPropertiesFailure = '[GetEmployerInfoProperties/API] Failure';
export const ClearEmployerPropertyState = '[ClearEmployerProperty] Clear';
export const EditEmployerPropertyRequest = '[EditEmployerProperty/API] Send Request';
export const EditEmployerPropertySuccess = '[EditEmployerProperty/API] Success';
export const EditEmployerPropertyFailure = '[EditEmployerProperty/API] Failure';
export const RemoveEmployerPropertyRequest = '[RemoveEmployerProperty/API] Send Request';
export const RemoveEmployerPropertySuccess = '[RemoveEmployerProperty/API] Success';
export const RemoveEmployerPropertyFailure = '[RemoveEmployerProperty/API] Failure';
export const GetLookupTableOptionsRequest = '[GetEmployerLookupTableOptions/API] Send Request';
export const GetLookupTableOptionsSuccess = '[GetEmployerLookupTableOptions/API] Success';
export const GetLookupTableOptionsFailure = '[GetEmployerLookupTableOptions/API] Failure';
export const AddEmployerContactRequest = '[AddEmployerContact/API] Send Request';
export const AddEmployerContactSuccess = '[AddEmployerContact/API] Success';
export const AddEmployerContactFailure = '[AddEmployerContact/API] Failure';
export const UpdateEmployerContactRequest = '[UpdateEmployerContact/API] Send Request';
export const UpdateEmployerContactSuccess = '[UpdateEmployerContact/API] Success';
export const UpdateEmployerContactFailure = '[UpdateEmployerContact/API] Failure';
export const GetPropertyListRequest = '[GetEmployerPropertyList/API] Send Request';
export const GetPropertyListSuccess = '[GetEmployerPropertyList/API] Success';
export const GetPropertyListFailure = '[GetEmployerPropertyList/API] Failure';
export const ClearPropertyList = '[ClearPropertyList/API] Clear';

export const GetPropertyListConfigurationRequest = '[GetPropertyListConfiguration/API] Send Request';
export const GetPropertyListConfigurationSuccess = '[GetPropertyListConfiguration/API] Success';
export const GetPropertyListConfigurationFailure = '[GetPropertyListConfiguration/API] Failure';

export const GetPropertyListFilterRequest = '[GetPropertyListFilter/API] Send Request';
export const GetPropertyListFilterSuccess = '[GetPropertyListFilter/API] Success';
export const GetPropertyListFilterFailure = '[GetPropertyListFilter/API] Failure';

export const GetEmployerListConfigurationRequest = '[GetEmployerListConfiguration/API] Send Request';
export const GetEmployerListConfigurationSuccess = '[GetEmployerListConfiguration/API] Success';
export const GetEmployerListConfigurationFailure = '[GetEmployerListConfiguration/API] Failure';
export const ClearEmployerListConfiguration = '[ClearEmployerListConfiguration] Clear';
export const SetEmployerListConfigurationRequest = '[SetEmployerListConfiguration/API] Send Request';
export const SetEmployerListConfigurationSuccess = '[SetEmployerListConfiguration/API] Success';
export const SetEmployerListConfigurationFailure = '[SetEmployerListConfiguration/API] Failure';
export const ClearSetEmployerListConfigurationState = '[ClearSetEmployerListConfiguration] Clear';

export const SetEmployerListViewConfigurationRequest = '[SetEmployerListViewConfiguration/API] Send Request';
export const SetEmployerListViewConfigurationSuccess = '[SetEmployerListViewConfiguration/API] Success';
export const SetEmployerListViewConfigurationFailure = '[SetEmployerListViewConfiguration/API] Failure';

export const ClearSetEmployerListViewConfigurationState = '[ClearSetEmployerListViewConfiguration] Clear';

export const ClearEmployerFileTemplatesActionState = '[ClearEmployerFileTemplatesAction] Clear';
export const GetEmployerFileTemplatesRequest = '[GetEmployerFileTemplates/API] Send Request';
export const GetEmployerFileTemplatesSuccess = '[GetEmployerFileTemplates/API] Success';
export const GetEmployerFileTemplatesFailure = '[GetEmployerFileTemplates/API] Failure';
export const SetEmployerFileFormatRequest = '[SetEmployerFileFormat/API] Send Request';
export const SetEmployerFileFormatSuccess = '[SetEmployerFileFormat/API] Success';
export const SetEmployerFileFormatFailure = '[SetEmployerFileFormat/API] Failure';
export const GetEmployerFileLayoutConfigurationRequest = '[GetEmployerFileLayoutConfiguration/API] Send Request';
export const GetEmployerFileLayoutConfigurationSuccess = '[GetEmployerFileLayoutConfiguration/API] Success';
export const GetEmployerFileLayoutConfigurationFailure = '[GetEmployerFileLayoutConfiguration/API] Failure';
export const SetEmployerFileLayoutConfigurationRequest = '[SetEmployerFileLayoutConfiguration/API] Send Request';
export const SetEmployerFileLayoutConfigurationSuccess = '[SetEmployerFileLayoutConfiguration/API] Success';
export const SetEmployerFileLayoutConfigurationFailure = '[SetEmployerFileLayoutConfiguration/API] Failure';
export const GetEmployerFilePropertiesRequest = '[GetEmployerFileProperties/API] Send Request';
export const GetEmployerFilePropertiesSuccess = '[GetEmployerFileProperties/API] Success';
export const GetEmployerFilePropertiesFailure = '[GetEmployerFileProperties/API] Failure';
export const GetEmployerAllFilePropertiesRequest = '[GetEmployerAllFileProperties/API] Send Request';
export const GetEmployerAllFilePropertiesSuccess = '[GetEmployerAllFileProperties/API] Success';
export const GetEmployerAllFilePropertiesFailure = '[GetEmployerAllFileProperties/API] Failure';

export const GetMunicipalityCheckDisable = '[GetEmployerCheckDisable/API] Send Request';
export const GetMunicipalityCheckDisableSuccess = '[GetEmployerCheckDisable/API] Success';
export const GetMunicipalityCheckDisableFailure = '[GetEmployerCheckDisable/API] Failure';

export const ClearEmployerFilePropertyActionState = '[ClearEmployerFilePropertyAction] Clear';
export const GetMappingFieldSectionsRequest = '[GetMappingFieldSections/API] Send Request';
export const GetMappingFieldSectionsSuccess = '[GetMappingFieldSections/API] Success';
export const GetMappingFieldSectionsFailure = '[GetMappingFieldSections/API] Failure';
export const CreateEmployerFilePropertyRequest = '[CreateEmployerFileProperty/API] Send Request';
export const CreateEmployerFilePropertySuccess = '[CreateEmployerFileProperty/API] Success';
export const CreateEmployerFilePropertyFailure = '[CreateEmployerFileProperty/API] Failure';
export const UpdateEmployerFilePropertyRequest = '[UpdateEmployerFileProperty/API] Send Request';
export const UpdateEmployerFilePropertySuccess = '[UpdateEmployerFileProperty/API] Success';
export const UpdateEmployerFilePropertyFailure = '[UpdateEmployerFileProperty/API] Failure';
export const RemoveEmployerFilePropertyRequest = '[RemoveEmployerFileProperty/API] Send Request';
export const RemoveEmployerFilePropertySuccess = '[RemoveEmployerFileProperty/API] Success';
export const RemoveEmployerFilePropertyFailure = '[RemoveEmployerFileProperty/API] Failure';
export const GetFilePropertiesForPostingFieldsRequest = '[GetFilePropertiesForPostingFields/API] Send Request';
export const GetFilePropertiesForPostingFieldsSuccess = '[GetFilePropertiesForPostingFields/API] Success';
export const GetFilePropertiesForPostingFieldsFailure = '[GetFilePropertiesForPostingFields/API] Failure';
export const SetConfigurePostingFieldsRequest = '[ConfigurePostingFields/API] Send Request';
export const SetConfigurePostingFieldsSuccess = '[ConfigurePostingFields/API] Success';
export const SetConfigurePostingFieldsFailure = '[ConfigurePostingFields/API] Failure';

export const ClearEmployerSectionActionState = '[ClearEmployerSectionAction] Clear';
export const EditEmployerInformationRequest = '[EditEmployerInformation/API] Send Request';
export const EditEmployerInformationSuccess = '[EditEmployerInformation/API] Success';
export const EditEmployerInformationFailure = '[EditEmployerInformation/API] Failure';
export const EditEmployerPaymentPreferencesRequest = '[EditEmployerPaymentPreferences/API] Send Request';
export const EditEmployerPaymentPreferencesSuccess = '[EditEmployerPaymentPreferences/API] Success';
export const EditEmployerPaymentPreferencesFailure = '[EditEmployerPaymentPreferences/API] Failure';
export const RemoveEmployerContactRequest = '[RemoveEmployerContact/API] Send Request';
export const RemoveEmployerContactSuccess = '[RemoveEmployerContact/API] Success';
export const RemoveEmployerContactFailure = '[RemoveEmployerContact/API] Failure';

//For filter
export const CreateEmployerFilterRequest = '[CreateEmployerFilter/API] Send Request';
export const CreateEmployerFilterSuccess = '[CreateEmployerFilter/API] Success';
export const CreateEmployerFilterFailure = '[CreateEmployerFilter/API] Failure';
export const GetEmployerFilterRequest = '[GetEmployerFilter/API] Send Request';
export const GetEmployerFilterSuccess = '[GetEmployerFilter/API] Success';
export const GetEmployerFilterFailure = '[GetEmployerFilter/API] Failure';
export const UpdateEmployerFilterNameRequest = '[UpdateEmployerFilterName/API] Send Request';
export const UpdateEmployerFilterNameSuccess = '[UpdateEmployerFilterName/API] Success';
export const UpdateEmployerFilterNameFailure = '[UpdateEmployerFilterName/API] Failure';
export const RemoveEmployerFilterRequest = '[RemoveEmployerFilter/API] Send Request';
export const RemoveEmployerFilterSuccess = '[RemoveEmployerFilter/API] Success';
export const RemoveEmployerFilterFailure = '[RemoveEmployerFilter/API] Failure';
export const ClearFilterState = '[ClearFilterState] Clear';
export const SetEmployerFilters = '[SetEmployerFilters] Set';
export const AddEmployerPortalUserRequest = '[AddEmployerPortalUser/API] Send Request';
export const AddEmployerPortalUserSuccess = '[AddEmployerPortalUser/API] Success';
export const AddEmployerPortalUserFailure = '[AddEmployerPortalUser/API] Failure';
export const UpdateEmployerPortalUserRequest = '[UpdateEmployerPortalUser/API] Send Request';
export const UpdateEmployerPortalUserSuccess = '[UpdateEmployerPortalUser/API] Success';
export const UpdateEmployerPortalUserFailure = '[UpdateEmployerPortalUser/API] Failure';
export const RemoveEmployerPortalUserRequest = '[RemoveEmployerPortalUser/API] Send Request';
export const RemoveEmployerPortalUserSuccess = '[RemoveEmployerPortalUser/API] Success';
export const RemoveEmployerPortalUserFailure = '[RemoveEmployerPortalUser/API] Failure';

export const ExportEmployersRequest = '[ExportEmployersRequest/API] Send Request';
export const ExportEmployersRequestSuccess = '[ExportEmployersRequest/API] Success';
export const ExportEmployersRequestFailure = '[ExportEmployersRequest/API] Failure';
export const DownloadEmployersRequest = '[DownloadEmployersRequest/API] Send Request';
export const DownloadEmployersRequestSuccess = '[DownloadEmployersRequest/API] Success';
export const DownloadEmployersRequestFailure = '[DownloadEmployersRequest/API] Failure';

export const voidTransactionRequest = '[VoidTransaction/API] Send Request';
export const voidTransactionFailure = '[VoidTransaction/API] Failure';
export const voidTransactionSuccess = '[VoidTransaction/API] Success';
export const voidTransactionClear = '[VoidTransaction/API] Clear';
export const stripeDashboardRequest = '[StripeDashboard/API] Send Request';
export const stripeDashboardFailure = '[StripeDashboard/API] Failure';
export const stripeDashboardSuccess = '[StripeDashboard/API] Success';
export const stripeDashboardClear = '[StripeDashboard/API] Clear';

export const clearEmployerProperty = createAction(ClearEmployerPropertyState);
export const getEmployerList = createAction(EmployerListRequest, props<{ query: GetListEmployerQuery }>());
export const getEmployerListSuccess = createAction(EmployerListSuccess, props<{ employerList: EmployerList }>());
export const getEmployerListFailure = createAction(EmployerListFailure, props<{ error?: any }>());
export const setEmployerFilter = createAction(SetEmployerFilters, props<{ filters: EmployerFilter[] }>());

export const searchEmployer = createAction(SearchEmployerRequest, props<{ searchValue: string; screenId: string }>());
export const searchEmployerSuccess = createAction(SearchEmployerSuccess, props<{ response: SearchEmployerResponse }>());
export const searchEmployerFailure = createAction(SearchEmployerFailure, props<{ error?: any }>());

export const clearSearchEmployer = createAction(ClearSearchEmployer);

export const getEmployerOverview = createAction(EmployerOverviewRequest, props<{ query: GetEmployerOverviewQuery }>());
export const getEmployerOverviewSuccess = createAction(
  EmployerOverviewSuccess,
  props<{ employerOverview: EmployerOverview; sectionType?: SectionType }>(),
);
export const getEmployerOverviewFailure = createAction(
  EmployerOverviewFailure,
  props<{ error?: any; sectionType?: SectionType }>(),
);
export const getOverViewConfig = createAction(GetOverViewConfigRequest);
export const getOverViewConfigSuccess = createAction(
  GetOverViewConfigSuccess,
  props<{ overviewConfigurations: SectionConfig[] }>(),
);
export const getOverViewConfigFailure = createAction(GetOverViewConfigFailure, props<{ error?: any }>());
export const clearOverViewConfig = createAction(ClearOverViewConfig);
export const saveOverViewConfig = createAction(SaveOverViewConfigRequest, props<{ body: OverviewConfigurations }>());
export const saveOverViewConfigSuccess = createAction(SaveOverViewConfigSuccess);
export const saveOverViewConfigFailure = createAction(SaveOverViewConfigFailure, props<{ error?: any }>());
export const getEmployerInfoPropertiesRequest = createAction(
  GetEmployerInfoPropertiesRequest,
  props<{ screenId?: string }>(),
);
export const getEmployerInfoPropertiesSuccess = createAction(
  GetEmployerInfoPropertiesSuccess,
  props<{ employerInfoProperties: EmployerInfoProperty[] }>(),
);
export const getEmployerInfoPropertiesFailure = createAction(GetEmployerInfoPropertiesFailure);

export const getListEmployerProperty = createAction(GetListEmployerProperty);
export const getListEmployerPropertySuccess = createAction(
  GetListEmployerPropertySuccess,
  props<{ properties: EmployerListProperty }>(),
);
export const getListEmployerPropertyFailure = createAction(GetListEmployerPropertyFailure, props<{ error?: any }>());
export const orderTable = createAction(PropertyOrder, props<{ body: BodyChangePropertyKey }>());

export const addEmployerProperty = createAction(
  AddEmployerPropertyRequest,
  props<{
    body: PropertyRequest;
    isContinue: boolean;
  }>(),
);
export const addEmployerPropertySuccess = createAction(AddEmployerPropertySuccess, props<{ propertyName: string }>());
export const addEmployerPropertyFailure = createAction(AddEmployerPropertyFailure, props<{ errorMsg: string }>());

export const editEmployerProperty = createAction(
  EditEmployerPropertyRequest,
  props<{ propertyKey: string; body: EditEmployerPropertyRequestDto }>(),
);
export const editEmployerPropertySuccess = createAction(EditEmployerPropertySuccess, props<{ propertyName: string }>());
export const editEmployerPropertyFailure = createAction(
  EditEmployerPropertyFailure,
  props<{ propertyName: string; errorMsg: string }>(),
);

export const removeEmployerProperty = createAction(
  RemoveEmployerPropertyRequest,
  props<{ propertyKey: string; propertyName: string }>(),
);
export const removeEmployerPropertySuccess = createAction(
  RemoveEmployerPropertySuccess,
  props<{ propertyName: string }>(),
);
export const removeEmployerPropertyFailure = createAction(
  RemoveEmployerPropertyFailure,
  props<{ propertyName: string; errorMsg: string }>(),
);
export const getLookupTableOptions = createAction(GetLookupTableOptionsRequest, props<{ screenId?: string }>());
export const getLookupTableOptionsSuccess = createAction(
  GetLookupTableOptionsSuccess,
  props<{ defaultLookupTableOptions: DefaultLookupTableOptions }>(),
);
export const getLookupTableOptionsFailure = createAction(GetLookupTableOptionsFailure, props<{ errorMsg: string }>());

export const addEmployerContact = createAction(
  AddEmployerContactRequest,
  props<{ employerId: string; employerContact: EmployerContact }>(),
);
export const addEmployerContactSuccess = createAction(AddEmployerContactSuccess);
export const addEmployerContactFailure = createAction(AddEmployerContactFailure, props<{ errorMsg: string }>());

export const updateEmployerContact = createAction(
  UpdateEmployerContactRequest,
  props<{ employerId: string; employerContact: EmployerContact }>(),
);
export const updateEmployerContactSuccess = createAction(UpdateEmployerContactSuccess);
export const updateEmployerContactFailure = createAction(UpdateEmployerContactFailure, props<{ errorMsg: string }>());

export const getPropertyList = createAction(GetPropertyListRequest);
export const getPropertyListSuccess = createAction(
  GetPropertyListSuccess,
  props<{ employerInformationConfiguration: EmployerInformationConfiguration }>(),
);
export const getPropertyListFailure = createAction(GetPropertyListFailure);
export const clearPropertyList = createAction(ClearPropertyList);

export const getPropertyListConfiguration = createAction(GetPropertyListConfigurationRequest);
export const getPropertyListConfigurationSuccess = createAction(
  GetPropertyListConfigurationSuccess,
  props<{ propertyListConfiguration: EmployerPropertyConfiguration }>(),
);
export const getPropertyListConfigurationFailure = createAction(GetPropertyListConfigurationFailure);

export const getPropertyListFilter = createAction(GetPropertyListFilterRequest);
export const getPropertyListFilterSuccess = createAction(
  GetPropertyListFilterSuccess,
  props<{ propertyListFilter: any }>(),
);
export const getPropertyListFilterFailure = createAction(GetPropertyListFilterFailure);

export const getEmployerListConfiguration = createAction(GetEmployerListConfigurationRequest);

export const clearEmployerListConfiguration = createAction(ClearEmployerListConfiguration);
export const getEmployerListConfigurationSuccess = createAction(
  GetEmployerListConfigurationSuccess,
  props<{ propertyDisplayConfigurations: PropertyDisplayConfiguration[] }>(),
);
export const getEmployerListConfigurationFailure = createAction(GetEmployerListConfigurationFailure);

export const setEmployerListConfiguration = createAction(SetEmployerListConfigurationRequest, props<{ body: any }>());
export const setEmployerListConfigurationSuccess = createAction(SetEmployerListConfigurationSuccess);
export const setEmployerListConfigurationFailure = createAction(SetEmployerListConfigurationFailure);
export const clearSetEmployerListConfigurationState = createAction(ClearSetEmployerListConfigurationState);

export const setEmployerListViewConfiguration = createAction(
  SetEmployerListViewConfigurationRequest,
  props<{ body: any }>(),
);
export const setEmployerListViewConfigurationSuccess = createAction(SetEmployerListViewConfigurationSuccess);
export const setEmployerListViewConfigurationFailure = createAction(SetEmployerListViewConfigurationFailure);
export const clearSetEmployerListViewConfigurationState = createAction(ClearSetEmployerListViewConfigurationState);

export const clearEmployerFileTemplatesActionState = createAction(ClearEmployerFileTemplatesActionState);

export const getEmployerFileTemplates = createAction(GetEmployerFileTemplatesRequest, props<{ employerId: string }>());
export const getEmployerFileTemplatesSuccess = createAction(
  GetEmployerFileTemplatesSuccess,
  props<{ employerFileTemplates: { fileTemplates: EmployerFileTemplate[] } }>(),
);
export const getEmployerFileTemplatesFailure = createAction(
  GetEmployerFileTemplatesFailure,
  props<{ errorMsg: string }>(),
);

export const getEmployerFileProperties = createAction(
  GetEmployerFilePropertiesRequest,
  props<{ employerId: string; query: GetEmployerFilePropertiesRequestDto }>(),
);
export const getEmployerFilePropertiesSuccess = createAction(
  GetEmployerFilePropertiesSuccess,
  props<{ total: number; properties: EmployerFileProperty[] }>(),
);
export const getEmployerFilePropertiesFailure = createAction(
  GetEmployerFilePropertiesFailure,
  props<{ errorMsg: string }>(),
);

export const getMunicipalityCheckDisable = createAction(
  GetMunicipalityCheckDisable,
  props<{ body: EmployerCheckDisableRequest }>(),
);
export const getMunicipalityCheckDisableSuccess = createAction(GetMunicipalityCheckDisableSuccess);

export const getMunicipalityCheckDisableFailure = createAction(
  GetMunicipalityCheckDisableFailure,
  props<{ error?: any }>(),
);

export const clearEmployerFilePropetyActionState = createAction(ClearEmployerFilePropertyActionState);

export const getMappingFieldSections = createAction(
  GetMappingFieldSectionsRequest,
  props<{ employerId: string; filePropertyId?: string }>(),
);
export const getMappingFieldSectionsSuccess = createAction(
  GetMappingFieldSectionsSuccess,
  props<{ sections: MappingFieldSection[] }>(),
);
export const getMappingFieldSectionsFailure = createAction(
  GetMappingFieldSectionsFailure,
  props<{ errorMsg: string }>(),
);

export const createEmployerFileProperty = createAction(
  CreateEmployerFilePropertyRequest,
  props<{ employerId: string; property: EmployerFileProperty }>(),
);
export const createEmployerFilePropertySuccess = createAction(CreateEmployerFilePropertySuccess);
export const createEmployerFilePropertyFailure = createAction(
  CreateEmployerFilePropertyFailure,
  props<{ errorMsg: string }>(),
);

export const updateEmployerFileProperty = createAction(
  UpdateEmployerFilePropertyRequest,
  props<{
    employerId: string;
    filePropertyId: string;
    property: EmployerFileProperty;
  }>(),
);
export const updateEmployerFilePropertySuccess = createAction(UpdateEmployerFilePropertySuccess);
export const updateEmployerFilePropertyFailure = createAction(
  UpdateEmployerFilePropertyFailure,
  props<{ errorMsg: string }>(),
);

export const getFilePropertiesForPostingFields = createAction(
  GetFilePropertiesForPostingFieldsRequest,
  props<{ employerId: string }>(),
);
export const getFilePropertiesForPostingFieldsSuccess = createAction(
  GetFilePropertiesForPostingFieldsSuccess,
  props<{ employerFilePostingField: EmployerFilePostingField }>(),
);
export const getFilePropertiesForPostingFieldsFailure = createAction(
  GetFilePropertiesForPostingFieldsFailure,
  props<{ errorMsg: string }>(),
);

export const setConfigurePostingFields = createAction(
  SetConfigurePostingFieldsRequest,
  props<{
    employerId: string;
    participantKeyId?: string;
    wagePeriodId?: string;
  }>(),
);
export const setConfigurePostingFieldsSuccess = createAction(SetConfigurePostingFieldsSuccess);
export const setConfigurePostingFieldsFailure = createAction(
  SetConfigurePostingFieldsFailure,
  props<{ errorMsg: string }>(),
);

export const removeEmployerFileProperty = createAction(
  RemoveEmployerFilePropertyRequest,
  props<{ employerId: string; filePropertyId: string }>(),
);
export const removeEmployerFilePropertySuccess = createAction(RemoveEmployerFilePropertySuccess);
export const removeEmployerFilePropertyFailure = createAction(
  RemoveEmployerFilePropertyFailure,
  props<{ errorMsg: string }>(),
);

export const setEmployerFileFormat = createAction(
  SetEmployerFileFormatRequest,
  props<{ employerId: string; employerFileTemplate: EmployerFileTemplate }>(),
);
export const setEmployerFileFormatSuccess = createAction(SetEmployerFileFormatSuccess);
export const setEmployerFileFormatFailure = createAction(SetEmployerFileFormatFailure, props<{ errorMsg: string }>());

export const getEmployerFileLayoutConfiguration = createAction(
  GetEmployerFileLayoutConfigurationRequest,
  props<{ employerId: string; templateId: string }>(),
);
export const getEmployerFileLayoutConfigurationSuccess = createAction(
  GetEmployerFileLayoutConfigurationSuccess,
  props<{ fileLayoutData: FileLayoutData }>(),
);
export const getEmployerFileLayoutConfigurationFailure = createAction(
  GetEmployerFileLayoutConfigurationFailure,
  props<{ errorMsg: string }>(),
);

export const setEmployerFileLayoutConfiguration = createAction(
  SetEmployerFileLayoutConfigurationRequest,
  props<{
    employerId: string;
    fileTemplateId: string;
    employerFileLayouts: FileLayoutConfiguration[];
    templateName: string;
  }>(),
);
export const setEmployerFileLayoutConfigurationSuccess = createAction(
  SetEmployerFileLayoutConfigurationSuccess,
  props<{ templateName: string }>(),
);
export const setEmployerFileLayoutConfigurationFailure = createAction(
  SetEmployerFileLayoutConfigurationFailure,
  props<{ errorMsg: string; templateName: string }>(),
);

export const clearEmployerSectionAction = createAction(ClearEmployerSectionActionState);
export const editEmployerInformation = createAction(
  EditEmployerInformationRequest,
  props<{ employerId: string; body: EditEmployerInformationRequestDto }>(),
);
export const editEmployerInformationSuccess = createAction(EditEmployerInformationSuccess);
export const editEmployerInformationFailure = createAction(
  EditEmployerInformationFailure,
  props<{ errorMsg: string }>(),
);
export const editEmployerPaymentPreferences = createAction(
  EditEmployerPaymentPreferencesRequest,
  props<{
    employerId: string;
    body: EditEmployerPaymentPreferencesRequestDto;
  }>(),
);
export const editEmployerPaymentPreferencesSuccess = createAction(EditEmployerPaymentPreferencesSuccess);
export const editEmployerPaymentPreferencesFailure = createAction(
  EditEmployerPaymentPreferencesFailure,
  props<{ errorMsg: string }>(),
);
export const removeEmployerContact = createAction(
  RemoveEmployerContactRequest,
  props<{ employerId: string; contactId: string }>(),
);
export const removeEmployerContactSuccess = createAction(RemoveEmployerContactSuccess);
export const removeEmployerContactFailure = createAction(RemoveEmployerContactFailure, props<{ errorMsg: string }>());

export const addEmployerPortalUser = createAction(
  AddEmployerPortalUserRequest,
  props<{ employerId: string; employerPortalUser: EmployerPortalUser }>(),
);
export const addEmployerPortalUserSuccess = createAction(AddEmployerPortalUserSuccess);
export const addEmployerPortalUserFailure = createAction(AddEmployerPortalUserFailure, props<{ errorMsg: string }>());

export const updateEmployerPortalUser = createAction(
  UpdateEmployerPortalUserRequest,
  props<{ employerId: string; employerPortalUser: EmployerPortalUser }>(),
);
export const updateEmployerPortalUserSuccess = createAction(UpdateEmployerPortalUserSuccess);
export const updateEmployerPortalUserFailure = createAction(
  UpdateEmployerPortalUserFailure,
  props<{ errorMsg: string }>(),
);

export const checkEmployerPortalUser = createAction(
  generateActionName('CheckEmployerPortalUser/Api').Send,
  props<{ body: PayloadCheckEmail }>(),
);
export const checkEmployerPortalUserSuccess = createAction(
  generateActionName('CheckEmployerPortalUser/Api').Success,
  props<{ res: CheckExistResponse }>(),
);
export const checkEmployerPortalUserFailure = createAction(
  generateActionName('CheckEmployerPortalUser/Api').Failure,
  props<{ errorMsg: string }>(),
);
export const clearCheckEmployerPortalUser = createAction(generateActionName('CheckEmployerPortalUser/Api').Clear);

export const removeEmployerPortalUser = createAction(
  RemoveEmployerPortalUserRequest,
  props<{ employerId: string; contactId: string }>(),
);
export const removeEmployerPortalUserSuccess = createAction(RemoveEmployerPortalUserSuccess);
export const removeEmployerPortalUserFailure = createAction(
  RemoveEmployerPortalUserFailure,
  props<{ errorMsg: string }>(),
);

//For filter

export const createEmployerFilterRequest = createAction(
  CreateEmployerFilterRequest,
  props<{ body: EmployerFilterConfig }>(),
);
export const createEmployerFilterSuccess = createAction(CreateEmployerFilterSuccess);
export const createEmployerFilterFailure = createAction(CreateEmployerFilterFailure);
export const getEmployerFilterRequest = createAction(GetEmployerFilterRequest);
export const getEmployerFilterSuccess = createAction(
  GetEmployerFilterSuccess,
  props<{ listFilterInfo: EmployerFilterInformation[] }>(),
);
export const getEmployerFilterFailure = createAction(GetEmployerFilterFailure);
export const updateEmployerFilterNameRequest = createAction(
  UpdateEmployerFilterNameRequest,
  props<{ employerFilterName: EmployerFilterName }>(),
);
export const updateEmployerFilterNameSuccess = createAction(
  UpdateEmployerFilterNameSuccess,
  props<{ id: string; listFilterInfo: EmployerFilterInformation[] }>(),
);
export const updateEmployerFilterNameFailure = createAction(
  UpdateEmployerFilterNameFailure,
  props<{ id: string; listFilterInfo: EmployerFilterInformation[] }>(),
);
export const removeEmployerFilterRequest = createAction(RemoveEmployerFilterRequest, props<{ id: string }>());
export const removeEmployerFilterSuccess = createAction(RemoveEmployerFilterSuccess, props<{ id: string }>());
export const removeEmployerFilterFailure = createAction(RemoveEmployerFilterFailure);
export const clearFilterState = createAction(ClearFilterState);

export const exportEmployersRequest = createAction(
  ExportEmployersRequest,
  props<{ request: any }>(),
);
export const exportEmployersRequestSuccess = createAction(
  ExportEmployersRequestSuccess,
  props<{ data: any }>(),
);
export const exportEmployersRequestFailure = createAction(
  ExportEmployersRequestFailure,
);

export const downloadEmployersRequest = createAction(DownloadEmployersRequest, props<{ fileName: string }>());
export const downloadEmployersRequestSuccess = createAction(DownloadEmployersRequestSuccess);
export const downloadEmployersRequestFailure = createAction(DownloadEmployersRequestFailure);

export const createEmployersAction = createAction(
  '[CreateEmployers/API] Send Request',
  props<{ request: CreateEmployersRequest }>(),
);
export const createEmployersSuccessAction = createAction(
  '[CreateEmployers/API] Success',
  props<{ newEmployerId: string }>(),
);
export const createEmployersFailureAction = createAction('[CreateCEmployers/API] Failure', props<{ error?: any }>());
export const clearCreateEmployersStateAction = createAction('[CreateEmployers] Clear');

export const setEmployersAction = createAction(
  '[SetEmployers/API] Send Request',
  props<{ request: SetEmployersRequest; entityLabel: string }>(),
);
export const setEmployersSuccessAction = createAction('[SetEmployers/API] Success', props<{ entityLabel: string }>());
export const setEmployersFailureAction = createAction(
  '[SetEmployers/API] Failure',
  props<{ error?: any; entityLabel: string }>(),
);
export const clearSetEmployersStateAction = createAction('[SetEmployers] Clear');

export const removeDocumentsEmployer = createAction(
  '[RemoveDocumentsEmployer/API] Send Request',
  props<{ employerId: string; documentId: string }>(),
);
export const removeDocumentsEmployerSuccess = createAction('[RemoveDocumentsEmployer/API] Success');
export const removeDocumentsEmployerFailure = createAction(
  '[RemoveDocumentsEmployer/API] Failure',
  props<{ errorMsg?: string }>(),
);
export const clearRemoveDocumentsEmployerState = createAction(
  '[RemoveDocumentsEmployer] Clear'
);

export const getPermissionForMuniPortalUserAction = createAction(
  '[GetPermissionForMuniPortalUser/API] Send Request',
  props<{ employerId: string }>()
);
export const getPermissionForMuniPortalUserSuccessAction = createAction(
  '[GetPermissionForMuniPortalUser/API] Success',
  props<{ response: any }>()
);
export const getPermissionForMuniPortalUserFailureAction = createAction(
  '[GetPermissionForMuniPortalUser/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetPermissionForMuniPortalUserStateAction = createAction(
  '[GetPermissionForMuniPortalUser] Clear'
);


export const getListColumnSetEmployersAction = createAction(
  '[GetListColumnSetEmployers/API] Send Request',
  props<{ isDragDrop: boolean; entityId: string }>(),
);
export const getListColumnSetEmployersSuccessAction = createAction(
  '[GetListColumnSetEmployers/API] Success',
  props<{ employerColumnConfigs: EmployerColumnConfigs }>(),
);
export const getListColumnSetEmployersFailureAction = createAction(
  '[GetListColumnSetEmployers/API] Failure',
  props<{ errorMsg?: string }>(),
);

export const createColumnSetEmployersAction = createAction(
  '[CreateColumnSetEmployers/API] Send Request',
  props<{ body: EmployerColumnSetConfigRequest }>(),
);

export const createColumnSetEmployersSuccess = createAction(
  '[CreateColumnSetEmployers/API] Success',
  props<{ id: string }>(),
);

export const createColumnSetEmployersFailure = createAction(
  '[CreateColumnSetEmployers/API] Failure',
  props<{ error?: any }>(),
);

export const editColumnSetEmployersAction = createAction(
  '[EditColumnSetEmployers/API] Send Request',
  props<{ id: string; body: EmployerColumnSetConfigRequest }>(),
);

export const editColumnSetEmployersSuccess = createAction(
  '[EditColumnSetEmployers/API] Success',
  props<{ id: string }>(),
);

export const editColumnSetEmployersFailure = createAction(
  '[EditColumnSetEmployers/API] Failure',
  props<{ error?: any }>(),
);

export const removeColumnSetEmployersAction = createAction(
  '[RemoveColumnSetEmployers/API] Send Request',
  props<{ id: string }>(),
);
export const removeColumnSetEmployerSuccess = createAction(
  '[RemoveColumnSetEmployers/API] Success',
  props<{ id: string }>(),
);
export const removeColumnSetEmployerFailure = createAction(
  '[RemoveColumnSetEmployers/API] Failure',
  props<{ error?: any }>(),
);

export const reorderColumnSetEmployersAction = createAction(
  '[ReorderColumnSetEmployers/API] Send Request',
  props<{
    body: ReorderEmployerColumnSetConfigRequest;
  }>(),
);
export const reorderColumnSetEmployersSuccess = createAction('[ReorderColumnSetEmployers/API] Success');
export const reorderColumnSetEmployersFailure = createAction(
  'ReorderColumnSetEmployers/API] Failure',
  props<{ error?: any }>(),
);

export const clearColumnSetEmployersState = createAction('[ClearColumnSetEmployersState] Clear');

export const voidTransactionAction = createAction(
  voidTransactionRequest,
  props<{ municipalityId: string; remittanceId: string; transactionId: string }>(),
);
export const voidTransactionActionSuccess = createAction(voidTransactionSuccess);
export const voidTransactionActionFailure = createAction(voidTransactionFailure, props<{ error: any }>());
export const clearVoidTransactionState = createAction(voidTransactionClear);
export const stripeDashboardAction = createAction(stripeDashboardRequest, props<{ accountId: string }>());
export const stripeDashboardActionSuccess = createAction(
  stripeDashboardSuccess,
  props<{ paymentDashboardState: any }>(),
);

export const getValidateEditPaymentMethodAction = createAction(
  '[GetValidateEditPaymentMethod/API] Send Request',
  props<{ employerId: string; method: PaymentMethod }>()
);

export const getValidateEditPaymentMethodSuccessAction = createAction(
  '[GetValidateEditPaymentMethod/API] Success',
  props<{ response: boolean }>()
);
export const getValidateEditPaymentMethodFailureAction = createAction(
  '[GetValidateEditPaymentMethod/API] Failure',
  props<{ error?: any }>()
);

export const clearGetValidateEditPaymentMethodStateAction = createAction(
  '[GetValidateEditPaymentMethod] Clear'
);

//check employerId has connected with stripe
export const checkStripeCustomerExistsAction = createAction(
  '[CheckStripeCustomerExists/API] Send Request',
  props<{ employerId: string }>(),
);
export const checkStripeCustomerExistsSuccess = createAction(
  '[CheckStripeCustomerExists/API] Success',
  props<{ response: any }>(),
);
export const checkStripeCustomerExistsFailure = createAction(
  '[CheckStripeCustomerExists/API] Failure',
  props<{ error?: any }>(),
);
export const clearCheckStripeCustomerExistsAction = createAction(
  '[CheckStripeCustomerExists] Clear',
);

//register customer stripe
export const registerCustomerStripeAction = createAction(
  '[RegisterCustomerStripeAction/API] Send Request',
  props<{ request: RegisterCustomerStripeRequest }>(),
);
export const registerCustomerStripeSuccess = createAction(
  '[RegisterCustomerStripeAction/API] Success',
  props<{ response: RegisterCustomerStripeResponse }>(),
);
export const registerCustomerStripeFailure = createAction(
  '[RegisterCustomerStripeAction/API] Failure',
  props<{ error?: any }>(),
);
export const clearRegisterCustomerStripeAction = createAction(
  '[RegisterCustomerStripeAction] Clear',
);
