import { createAction, props } from "@ngrx/store";
import { DeleteFeeAmountsBody, FeeAmounts, GetEmployerInfoFeeResponse, GetFeeAmountsListQuery, GetFeeAmountsListResponse } from "../models/employer-setting.model";
import { MunicipalityType } from "../constance/employer-setting.const";

export const GetEmployerInfoFeesRequest = '[GetEmployerInfoFees/API] Send Request';
export const GetEmployerInfoFeesSuccess = '[GetEmployerInfoFees/API] Success';
export const GetEmployerInfoFeesFailure = '[GetEmployerInfoFees/API] Failure';

export const GetFireDepartmentFeesRequest = '[GetFireDepartmentFees/API] Send Request';
export const GetFireDepartmentFeesSuccess = '[GetFireDepartmentFees/API] Success';
export const GetFireDepartmentFeesFailure = '[GetFireDepartmentFees/API] Failure';

export const GetReserveOfficerFeesRequest = '[GetReserveOfficerFees/API] Send Request';
export const GetReserveOfficerFeesSuccess = '[GetReserveOfficerFees/API] Success';
export const GetReserveOfficerFeesFailure = '[GetReserveOfficerFees/API] Failure';

export const GetEMSDFeesRequest = '[GetEMSDFees/API] Send Request';
export const GetEMSDFeesSuccess = '[GetEMSDFees/API] Success';
export const GetEMSDFeesFailure = '[GetEMSDFees/API] Failure';

export const AddFeeAmountsRequest = '[AddFeeAmounts/API] Send Request';
export const AddFeeAmountsSuccess = '[AddFeeAmounts/API] Success';
export const AddFeeAmountsFailure = '[AddFeeAmounts/API] Failure';

export const EditFeeAmountsRequest = '[EditFeeAmounts/API] Send Request';
export const EditFeeAmountsSuccess = '[EditFeeAmounts/API] Success';
export const EditFeeAmountsFailure = '[EditFeeAmounts/API] Failure';

export const ClearFeeAmountsState = '[ClearFeeAmountsState] Clear';

export const DeleteFeeAmountsRequest = '[DeleteFeeAmounts/API] Send Request';
export const DeleteFeeAmountsSuccess = '[DeleteFeeAmounts/API] Success';
export const DeleteFeeAmountsFailure = '[DeleteFeeAmounts/API] Failure';

export const getEmployerInfoFeesRequest = createAction(
  GetEmployerInfoFeesRequest,
  props<{ employerId: string }>()
);
export const getEmployerInfoFeesSuccess = createAction(
  GetEmployerInfoFeesSuccess,
  props<{ response: GetEmployerInfoFeeResponse }>()
);
export const getEmployerInfoFeesFailure = createAction(
  GetEmployerInfoFeesFailure,
  props<{ error?: any }>()
);

export const getFireDepartmentFeesRequest = createAction(
    GetFireDepartmentFeesRequest,
    props<{ query: GetFeeAmountsListQuery }>()
);
export const getFireDepartmentFeesSuccess = createAction(
    GetFireDepartmentFeesSuccess,
    props<{ response: GetFeeAmountsListResponse }>()
);
export const getFireDepartmentFeesFailure = createAction(
    GetFireDepartmentFeesFailure
);

export const getReserveOfficerFeesRequest = createAction(
    GetReserveOfficerFeesRequest,
    props<{ query: GetFeeAmountsListQuery }>()
);
export const getReserveOfficerFeesSuccess = createAction(
    GetReserveOfficerFeesSuccess,
    props<{ response: GetFeeAmountsListResponse }>()
);
export const getReserveOfficerFeesFailure = createAction(
    GetReserveOfficerFeesFailure
);

export const getEMSDFeesRequest = createAction(
    GetEMSDFeesRequest,
    props<{ query: GetFeeAmountsListQuery }>()
);
export const getEMSDFeesSuccess = createAction(
    GetEMSDFeesSuccess,
    props<{ response: GetFeeAmountsListResponse }>()
);
export const getEMSDFeesFailure = createAction(
    GetEMSDFeesFailure
);

export const addFeeAmountsRequest = createAction(
    AddFeeAmountsRequest,
    props<{ body: FeeAmounts }>()
);
export const addFeeAmountsSuccess = createAction(
    AddFeeAmountsSuccess,
    props<{ municipalityType: MunicipalityType }>()
);
export const addFeeAmountsFailure = createAction(
    AddFeeAmountsFailure,
    props<{ municipalityType: MunicipalityType }>()
);

export const editFeeAmountsRequest = createAction(
    EditFeeAmountsRequest,
    props<{ body: FeeAmounts }>()
);
export const editFeeAmountsSuccess = createAction(
    EditFeeAmountsSuccess,
    props<{ municipalityType: MunicipalityType }>()
);
export const editFeeAmountsFailure = createAction(
    EditFeeAmountsFailure,
    props<{ municipalityType: MunicipalityType }>()
);

export const clearFeeAmountsState = createAction(
    ClearFeeAmountsState
);

export const deleteFeeAmountsRequest = createAction(
    DeleteFeeAmountsRequest,
    props<{ body: DeleteFeeAmountsBody }>()
);
export const deleteFeeAmountsSuccess = createAction(
    DeleteFeeAmountsSuccess,
    props<{ municipalityType: MunicipalityType }>()
);
export const deleteFeeAmountsFailure = createAction(
    DeleteFeeAmountsFailure,
    props<{ municipalityType: MunicipalityType }>()
);
