import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CreateTransactionBody,
  CreateValidateServiceHistoryRecordBeginDateTransactionRequest,
  EditMunicipalityServiceHistoryRequest,
  GetListStatusHistoryQuery,
  MunicipalityNameList,
  MunicipalityServiceRecordList,
  MunicipalityServiceRecordListEntity,
  ValidateServiceHistoryRecordBeginDateRequest,
  ValidateTerminatedMemberBody,
  ValidateTerminatedMemberResponse,
} from '../types/models';
import { FixedSection } from '@ptg-member/constance/metadata.const';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityServiceHistoryService {
  constructor(private httpClient: HttpClient) {}

  getMunicipalityServiceHistoryList(
    query: GetListStatusHistoryQuery
  ): Observable<MunicipalityServiceRecordList> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<MunicipalityServiceRecordList>(
      `${environment.apiUrl}/member-portal/ServiceRecord`,
      { params }
    );
  }

  getMunicipalityServiceHistoryEntityList(
    query: any, memberId: string,
  ): Observable<MunicipalityServiceRecordListEntity> {
    let params = new HttpParams();
    if (query) {
      params = params.append('Masked', true);
      params = params.append('PageSize', query.pageSize);
      params = params.append('PageIndex', query.pageIndex);
    }
    return this.httpClient.get<MunicipalityServiceRecordListEntity>(
      `${environment.apiUrl}/member-portal/${memberId}/${FixedSection.ServiceHistory}`,
      { params }
    );
  }

  getMunicipalityList(): Observable<MunicipalityNameList> {
    return this.httpClient.get<MunicipalityNameList>(
      `${environment.apiUrl}/employer-portal/get-employers`
    );
  }

  editMunicipalityServiceHistory(body: EditMunicipalityServiceHistoryRequest) {
    let bodyRequest = {
      id: body.id,
      memberId: body.memberId,
      municipalityId: body.municipalityId,
      beginDate: body.beginDate,
      endDate: body.endDate ? body.endDate : null,
    };
    return this.httpClient.post(
      `${environment.apiUrl}/member-portal/service-history/edit-service-history`,
      bodyRequest,
    );
  }

  checkExits = (bodyCheck: any) => {
    return this.httpClient.post(
      `${ environment.apiUrl }/member-portal/service-history/check-exist-service-record`,
      bodyCheck,
    );
  };

  validateTerminatedMember(body: ValidateTerminatedMemberBody): Observable<ValidateTerminatedMemberResponse> {
    return this.httpClient.post<ValidateTerminatedMemberResponse>(`${ environment.apiUrl }/member-portal/service-history/validate-terminated-member`, body);
  }

  createTransaction(body: CreateTransactionBody): Observable<void> {
    return this.httpClient.post<void>(`${ environment.apiUrl }/annual-certification/no-activity-transaction`, body);
  }

  validateServiceHistoryRecordBeginDate(
    request: ValidateServiceHistoryRecordBeginDateRequest,
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/member-portal/service-history/validate-service-begin-date`,
      request,
    );
  }

  createValidateServiceHistoryRecordBeginDateTransaction(
    request: CreateValidateServiceHistoryRecordBeginDateTransactionRequest,
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/member-portal/service-history/create-validate-service-begin-date-transaction`,
      request,
    );
  }
}
