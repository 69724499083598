<div class="address-history-container" id="address-history-page">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [isDisabledGear]="true"
  >
  </ptg-breadcrumb>
  <div>
    <ptg-overview-header-entity
      #overViewHeaderEntity
      (changeMemberDetailEvent)="onChangeMemberDetail()"
      [shouldHideStatusDateProperty]="true"
      [shouldHideStatusEventProperty]="true"
    ></ptg-overview-header-entity>
  </div>
  <div class="address-history-content flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="message"
      [(bannerType)]="bannerType"
      [message]="message"
      (closeBanner)="closeBanner()"
    ></ptg-banner>

    <div *ngIf="addressHistoriesData[currentRowIndex]" class="box">
      <div class="title">
        <span class="title-name">Selected Address</span>
        <div class="separator"></div>
        <div class="button-group" *ngIf="addressHistoriesData[currentRowIndex]?.canEdit">
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditMemberAddress(addressHistoriesData[currentRowIndex])"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>

        </div>
        <div class="button-group">
          <span class="state active" *ngIf="addressHistoriesData[currentRowIndex]?.isActive">Active</span>
          <span class="state inactive" *ngIf="!addressHistoriesData[currentRowIndex]?.isActive">Inactive</span>
        </div>
      </div>
      <div class="detail-container">
        <div class="detail">
          <span class="label">Address Type</span>
          <span class="value">
            {{
              addressHistoriesData[currentRowIndex]?.entityPropertyName ?? '-'
            }}
          </span>
        </div>

        <div class="detail">
          <span class="label">Effective From</span>
          <span class="value">
            {{
              (addressHistoriesData[currentRowIndex]?.effectFrom | date : "MM/dd/yyyy") ?? '-'
            }}
          </span>
        </div>

        <div class="detail">
          <span class="label">Effective To</span>
          <span class="value">
            {{
              (addressHistoriesData[currentRowIndex]?.effectTo | date : "MM/dd/yyyy") ?? '-'
            }}
          </span>
        </div>

        <div class="detail">
          <span class="label">Address</span>
          <span id="address-view-detail" class="value">
            <ptg-view-address
              *ngIf="addressHistoriesData[currentRowIndex]?.addressBlank"
              [value]="addressHistoriesData[currentRowIndex]"
              [options]="addressHistoriesData[currentRowIndex]?.options"
            ></ptg-view-address>
            <ng-container *ngIf="!addressHistoriesData[currentRowIndex].addressBlank">-</ng-container>
          </span>
        </div>

        <div class="detail">
          <span class="label">Last Modified At</span>
          <span class="value">
            <ng-container>
              {{
               ( addressHistoriesData[currentRowIndex]?.dateUpdated | date : "MM/dd/yyyy hh:mm a") ?? '-'
              }}
            </ng-container>
          </span>
        </div>
        <div class="detail">
          <span class="label">Modified By</span>
          <span class="value">
            <ng-container>
              {{ addressHistoriesData[currentRowIndex]?.updatedBy ?? '-'}}
            </ng-container>
          </span>
        </div>
      </div>
    </div>

    <div class="flex-grow overflow-hidden">
      <ptg-list-display-box
        title="Address History"
        pageName="Address"
        btnAddTitle="New Address"
        [isLoading]="isLoading"
        [dataTable]="addressHistoriesData"
        [columns]="columns"
        [sortInfo]="sortInfo"
        [lengthPg]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [isAddressHistory]="true"
        [currentRowIndex]="currentRowIndex"
        (changePage)="changePage($event)"
        (sortChange)="sortChange($event)"
        (selectRow)="selectRow($event)"
        (addItem)="onEditMemberAddress()"
      >
        <ng-container>
          <ptg-select
            placeholder="Address Type"
            class="input-field"
            [controlField]="addressType"
            [listData]="listOptionAddressType"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            (changeOptionValue)="onChangeAddressProperty($event)"
          ></ptg-select>
        </ng-container>
      </ptg-list-display-box>
    </div>
  </div>
</div>
