import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LayoutComponent } from '@ptg-shared/layout/layout.component';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { SharedModule } from '@ptg-shared/shared.module';
import { PaymentDeductionsService } from '@ptg-processing/features/payroll/services';

import * as fromLayout from './reducers';
import { SearchMemberListEffects } from './effects/search.effect';
import { MiddleComponent } from './components/middle/middle.component';
import { LayoutEffects } from './effects/layout.effect';
import { PermissionComponent } from './components/permission/permission.component';
import { CheckPermissionModule } from './directive/check-permission/check-permission.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CaptionbarComponent } from './components/captionbar/captionbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ReleaseVersionComponent } from './components/release-version/release-version.component';
import { LoginErrorPageComponent } from './components/login-error-page/login-error-page.component';

export const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  CaptionbarComponent,
  SidebarComponent,
  MiddleComponent,
  PermissionComponent,
  LayoutComponent,
  ReleaseVersionComponent,
  LoginErrorPageComponent
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    SharedModule,
    ControlsModule,
    RouterModule,
    StoreModule.forFeature(
      fromLayout.LayoutModuleFeatureKey,
      fromLayout.reducers
    ),
    EffectsModule.forFeature([SearchMemberListEffects, LayoutEffects]),
    CheckPermissionModule,
  ],
  providers: [PaymentDeductionsService],
  exports: COMPONENTS,
})
export class LayoutModule {}
