<div class="dialog-container" id="add-participant-accident-report-dialog">
  <div class="header-title">{{ data.accidentReport ? 'Edit' : 'Add New' }} Accident Report</div>
  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
    <div class="form-container">
      <div class="input-container">
        <div class="input-group day-off-from-work">
          <ptg-textbox 
            [controlField]="editForm.get('dayOffFromWork')"
            placeholder="Days Off From Work"
            [maxLength]="250"
            inputType="Number"
            customError="errorMessage"
            [isPositive]="true"
            [isDecimal]="false"
            [hasLabel]="true"
            (onOutFocus)="validateHospitalizedDayOffFromWork()"
          ></ptg-textbox>
          <div class="checkbox-group">
            <ptg-checkbox
              [controlField]="editForm.get('isDrSeen')"
              label="Dr Seen"
            ></ptg-checkbox>
            <ptg-checkbox
              [controlField]="editForm.get('isHospitalized')"
              label="Hospitalized"
              (changeValue)="validateHospitalizedDayOffFromWork()"
            ></ptg-checkbox>
            <ptg-checkbox
              [controlField]="editForm.get('isFatal')"
              label="Fatal"
            ></ptg-checkbox>
          </div>
        </div>
        <div class="input-group col-3">
          <ptg-textbox
            [controlField]="editForm.get('drName')"
            placeholder="Dr Name"
            [maxLength]="250"
            [hasLabel]="true"
          ></ptg-textbox>
          <ptg-textbox
            [controlField]="editForm.get('sheriffName')"
            placeholder="Chief/Sheriff Name"
            [maxLength]="250"
            [hasLabel]="true"
          ></ptg-textbox>
          <ptg-timepicker
              [controlField]="editForm.get('accidentTime')"
              placeholder='Accident Time'
              [isRequired]="true"
              errorInvalid="Invalid Accident Time format."
              class="input-time" 
              [enableKeyboardInput]="false">
          </ptg-timepicker>
        </div>
        <ptg-input-area
          [controlField]="editForm.get('natureOfInjury')"
          [hasLabel]="true"
          placeholder="Nature of Injury"
          [maxLength]="255"
          class="input-description"
          errorRequire="Nature of Injury is required."
          errorMaxLength="Exceed the 255 character limit."
        ></ptg-input-area>
        <div class="input-group location">
          <ptg-select 
            [placeholder]="'Location of Accident'"
            [controlField]="editForm.get('locationOfAccident')"
            [listData]="listLocation"
            [isOptionObj]="true"
            [isRequired]="true"
            [isSetMaxWidthSelect]="true"
            [isMultipleLineOption]="true"
            [onlyTruncateTitle]="true"
            class="select-injury"
            (changeControlValue)="onChangeValueLocationOfAccident()"
          ></ptg-select>
          <ptg-textbox
            *ngIf="editForm.get('locationOfAccident')?.value === 'Other'"
            [controlField]="editForm.get('otherLocation')"
            placeholder="Other Location"
            [maxLength]="250"
            [isRequired]="true"
            [hasLabel]="true"
          ></ptg-textbox>
        </div>
      </div>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
</div>
