import { createReducer, on } from '@ngrx/store';
import {
  clearGetBuybackMakeupRemittanceCalculationResultStateAction,
  clearGetRemittanceStatusStateAction,
  getBuybackMakeupRemittanceCalculationResultAction,
  getBuybackMakeupRemittanceCalculationResultFailureAction,
  getBuybackMakeupRemittanceCalculationResultSuccessAction,
  getRemittanceStatusAction,
  getRemittanceStatusFailureAction,
  getRemittanceStatusSuccessAction,
  getCustomerInfoStripeRequest,
  getCustomerInfoStripeSuccess,
  getCustomerInfoStripeFailure,
  clearGetCustomerInfoStripeState,
} from '@ptg-employer/actions/remittance-submission.actions';
import { STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';
import { RemittanceSubmissionActions } from '../actions';
import {
  BuybackMakeupRemittanceCalculatonResultResponse,
  CustomerInfoStripeResponse,
  RecordPaymentResponse,
  RemittanceFeesPayment,
  RemittanceSubmission,
  RemittanceUpdateResponse,
  ValidateRemittanceResponse,
  ValidateRemittanceSubmissionResponse,
} from '../models/remittance-submission.model';

import { ECheckStatus } from '@ptg-employer/constance/transactions.const';

export const remittanceSubmissionFeatureKey = 'remittanceSubmission';

export interface State {
  remittanceSubmission?: BaseActionState<RemittanceSubmission>;
  editRemittance: {
    editRemittanceState: string;
    editType?: number;
    remittanceUpdateResponse?: RemittanceUpdateResponse;
    errorMsg?: string;
  };
  editRemittanceYear: string;
  editRemittanceParticipantState?: {
    state: string;
    remittanceParticipantId: string;
  };
  validateRemittanceResponse?: ValidateRemittanceResponse;
  removeRemittanceState: string;
  remittanceFeesPayment?: BaseActionState<RemittanceFeesPayment>;
  recordPaymentState: {
    state: string;
    eCheckPayment?: RecordPaymentResponse[];
    errorMsg?: string;
  };
  searchRemittanceParticipantResultState?: {
    isLoading: boolean;
    isError: boolean;
    searchRemittanceParticipantResult: any;
  };
  refundCreditState: string;
  recordPaymentForMunicipalityState: string;
  validateRemittanceSubmission?: BaseActionState<ValidateRemittanceSubmissionResponse>;
  getBuybackMakeupRemittanceCalculationResultState?: BaseActionState<BuybackMakeupRemittanceCalculatonResultResponse>;
  createPaymentIntent?: BaseActionState<{ clientSecret: string }>;
  checkTransactionsResult?: {
    state: boolean;
    eCheckStatus?: ECheckStatus;
  };
  customerInfoStripe?: BaseActionState<CustomerInfoStripeResponse>;
  getRemittanceStatus?: BaseActionState<RemittanceSubmission>;
}

const initialState: State = {
  remittanceSubmission: undefined,
  editRemittance: {
    editRemittanceState: '',
    remittanceUpdateResponse: {} as RemittanceUpdateResponse,
    errorMsg: '',
  },
  editRemittanceYear: '',
  removeRemittanceState: '',
  recordPaymentState: {
    state: '',
    eCheckPayment: undefined,
    errorMsg: undefined,
  },
  refundCreditState: '',
  recordPaymentForMunicipalityState: '',
  validateRemittanceSubmission: undefined,
  getBuybackMakeupRemittanceCalculationResultState: undefined,
  checkTransactionsResult: {
    state: false,
    eCheckStatus: undefined,
  },
};

export const reducer = createReducer(
  initialState,
  on(RemittanceSubmissionActions.getRemittanceSubmissionRequest, (state, {}) => ({
    ...state,
    remittanceSubmission: {
      isLoading: true,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceSubmissionSuccess, (state, { remittanceSubmission, remittanceId }) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: false,
      payload: {
        ...remittanceSubmission,
        id: remittanceId,
      },
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceSubmissionFailure, (state, { error }) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: false,
      error,
      payload: undefined,
    },
  })),

  on(RemittanceSubmissionActions.clearGetRemittanceSubmissionState, (state) => ({
    ...state,
    remittanceSubmission: undefined,
  })),

  on(RemittanceSubmissionActions.editRemittanceSuccess, (state, { editType, remittanceUpdateResponse }) => ({
    ...state,
    editRemittance: {
      editRemittanceState: STATE.SUCCESS,
      remittanceUpdateResponse,
      editType,
    },
  })),

  on(RemittanceSubmissionActions.editRemittanceFailure, (state, { error, editType }) => {
    let errorMsg = '';
    if (error) {
      errorMsg = error;
    }
    return {
      ...state,
      editRemittance: {
        editRemittanceState: STATE.FAIL,
        errorMsg,
        editType,
      },
    };
  }),

  on(RemittanceSubmissionActions.clearRemittanceSubmissionState, (state) => ({
    ...state,
    editRemittance: {
      editRemittanceState: '',
      remittanceUpdateResponse: {} as RemittanceUpdateResponse,
    },
    removeRemittanceState: '',
  })),

  on(RemittanceSubmissionActions.editRemittanceParticipantSuccess, (state, { remittanceParticipantId }) => ({
    ...state,
    editRemittanceParticipantState: {
      state: STATE.SUCCESS,
      remittanceParticipantId,
    },
  })),
  on(RemittanceSubmissionActions.editRemittanceParticipantFailure, (state, { remittanceParticipantId }) => ({
    ...state,
    editRemittanceParticipantState: {
      state: STATE.FAIL,
      remittanceParticipantId,
    },
  })),
  on(RemittanceSubmissionActions.clearRemittanceParticipantState, (state) => ({
    ...state,
    editRemittanceParticipantState: undefined,
  })),

  on(RemittanceSubmissionActions.validateRemittanceParticipant, (state, {}) => ({
    ...state,
    validateRemittanceResponse: undefined,
  })),
  on(RemittanceSubmissionActions.validateRemittanceParticipantSuccess, (state, { validateRemittanceResponse }) => ({
    ...state,
    validateRemittanceResponse,
  })),
  on(RemittanceSubmissionActions.validateRemittanceParticipantFailure, (state, { errorMsg }) => ({
    ...state,
    validateRemittanceResponse: undefined,
  })),

  on(RemittanceSubmissionActions.clearValidateRemittanceParticipant, (state) => ({
    ...state,
    validateRemittanceResponse: undefined,
  })),

  on(RemittanceSubmissionActions.removeRemittanceSuccess, (state, {}) => ({
    ...state,
    removeRemittanceState: STATE.SUCCESS,
  })),
  on(RemittanceSubmissionActions.removeRemittanceFailure, (state, { errorMsg }) => ({
    ...state,
    removeRemittanceState: STATE.FAIL,
  })),

  on(RemittanceSubmissionActions.clearRemittanceFeesPaymentState, (state) => ({
    ...state,
    remittanceFeesPayment: undefined,
  })),
  on(RemittanceSubmissionActions.getRemittanceFeesPaymentRequest, (state) => ({
    ...state,
    remittanceFeesPayment: {
      isLoading: true,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceFeesPaymentSuccess, (state, { remittanceFeesPayment }) => ({
    ...state,
    remittanceFeesPayment: {
      isLoading: false,
      success: true,
      payload: remittanceFeesPayment,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceFeesPaymentFailure, (state) => ({
    ...state,
    remittanceFeesPayment: {
      isLoading: false,
      success: false,
    },
  })),
  on(RemittanceSubmissionActions.recordPaymentSuccess, (state, { recordPaymentData }) => ({
    ...state,
    recordPaymentState: {
      state: STATE.SUCCESS,
      eCheckPayment: recordPaymentData,
    },
  })),
  on(RemittanceSubmissionActions.recordPaymentFailure, (state, { errorMsg }) => ({
    ...state,
    recordPaymentState: {
      state: STATE.FAIL,
      eCheckPayment: undefined,
      errorMsg,
    },
  })),
  on(RemittanceSubmissionActions.clearRecordPaymentState, (state) => ({
    ...state,
    recordPaymentState: {
      state: '',
      eCheckPayment: undefined,
    },
  })),
  on(RemittanceSubmissionActions.searchRemittanceMuniParticipantRequest, (state, {}) => ({
    ...state,
    searchRemittanceParticipantResultState: {
      isLoading: true,
      isError: false,
      searchRemittanceParticipantResult: undefined,
    },
  })),
  on(
    RemittanceSubmissionActions.searchRemittanceMuniParticipantSuccess,
    (state, { searchRemittanceParticipantResult }) => ({
      ...state,
      searchRemittanceParticipantResultState: {
        isLoading: false,
        isError: false,
        searchRemittanceParticipantResult,
      },
    }),
  ),
  on(RemittanceSubmissionActions.searchRemittanceMuniParticipantFailure, (state) => ({
    ...state,
    searchRemittanceParticipantResultState: {
      isLoading: false,
      isError: true,
      searchRemittanceParticipantResult: undefined,
    },
  })),
  on(RemittanceSubmissionActions.clearSearchRemittanceMuniParticipantState, (state) => ({
    ...state,
    searchRemittanceParticipantResultState: {
      isLoading: false,
      isError: false,
      searchRemittanceParticipantResult: undefined,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceMuniParticipantRequest, (state, {}) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: true,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceMuniParticipantSuccess, (state, { remittanceSubmissionParticipant }) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: false,
      payload: {
        ...state.remittanceSubmission?.payload,
        remittance: {
          ...state.remittanceSubmission?.payload?.remittance,
          remittanceParticipants: [remittanceSubmissionParticipant],
        } as any,
      } as any,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceMuniParticipantFailure, (state) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: false,
      payload: {
        ...state.remittanceSubmission?.payload,
        remittance: {
          ...state.remittanceSubmission?.payload?.remittance,
          remittanceParticipants: [],
        } as any,
      } as any,
    },
  })),
  on(RemittanceSubmissionActions.refundCreditSuccess, (state) => ({
    ...state,
    refundCreditState: STATE.SUCCESS,
  })),
  on(RemittanceSubmissionActions.refundCreditFailure, (state) => ({
    ...state,
    refundCreditState: STATE.FAIL,
  })),
  on(RemittanceSubmissionActions.clearRefundCreditState, (state) => ({
    ...state,
    refundCreditState: '',
  })),
  on(RemittanceSubmissionActions.recordPaymentForMunicipalitySuccess, (state) => ({
    ...state,
    recordPaymentForMunicipalityState: STATE.SUCCESS,
  })),
  on(RemittanceSubmissionActions.recordPaymentForMunicipalityFailure, (state) => ({
    ...state,
    recordPaymentForMunicipalityState: STATE.FAIL,
  })),
  on(RemittanceSubmissionActions.clearRecordPaymentForMunicipalityState, (state) => ({
    ...state,
    recordPaymentForMunicipalityState: '',
  })),
  on(RemittanceSubmissionActions.validateRemittanceSubmission, (state) => ({
    ...state,
    validateRemittanceSubmission: {
      isLoading: true,
    },
  })),
  on(RemittanceSubmissionActions.validateRemittanceSubmissionSuccess, (state: State, { response }) => ({
    ...state,
    validateRemittanceSubmission: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(RemittanceSubmissionActions.validateRemittanceSubmissionFailure, (state: State, { error }) => ({
    ...state,
    validateRemittanceSubmission: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(RemittanceSubmissionActions.clearValidateRemittanceSubmissionState, (state: State) => ({
    ...state,
    validateRemittanceSubmission: undefined,
  })),

  // Get Buyback Makeup Remittance Calculation
  on(getBuybackMakeupRemittanceCalculationResultAction, (state) => ({
    ...state,
    getBuybackMakeupRemittanceCalculationResultState: {
      isLoading: true,
    },
  })),
  on(getBuybackMakeupRemittanceCalculationResultSuccessAction, (state, { response }) => ({
    ...state,
    getBuybackMakeupRemittanceCalculationResultState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getBuybackMakeupRemittanceCalculationResultFailureAction, (state, { error }) => ({
    ...state,
    getBuybackMakeupRemittanceCalculationResultState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetBuybackMakeupRemittanceCalculationResultStateAction, (state) => ({
    ...state,
    getBuybackMakeupRemittanceCalculationResultState: undefined,
  })),
  on(RemittanceSubmissionActions.createPaymentIntentRequest, (state) => ({
    ...state,
    createPaymentIntent: {
      isLoading: true,
    },
  })),
  on(RemittanceSubmissionActions.createPaymentIntentSuccess, (state, { paymentIntentData }) => ({
    ...state,
    createPaymentIntent: {
      success: true,
      isLoading: false,
      payload: paymentIntentData,
    },
  })),
  on(RemittanceSubmissionActions.createPaymentIntentFailure, (state, { error }) => ({
    ...state,
    createPaymentIntent: {
      success: false,
      isLoading: false,
      error: error,
    },
  })),
  on(RemittanceSubmissionActions.clearCreatePaymentIntentState, (state) => ({
    ...state,
    createPaymentIntent: undefined,
  })),
  on(RemittanceSubmissionActions.checkRemittanceTransactionsRequest, (state) => ({
    ...state,
    isLoading: true,
    checkTransactionsResult: {
      state: false,
      eCheckStatus: undefined,
    },
  })),
  on(RemittanceSubmissionActions.checkRemittanceTransactionsSuccess, (state, { status }) => ({
    ...state,
    isLoading: false,
    success: true,
    checkTransactionsResult: {
      state: true,
      eCheckStatus: status,
    },
  })),
  on(RemittanceSubmissionActions.checkRemittanceTransactionsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    success: false,
    error: error,
    checkTransactionsResult: {
      state: false,
      eCheckStatus: undefined,
    },
  })),
  on(RemittanceSubmissionActions.clearCheckRemittanceTransactionsState, (state) => ({
    ...state,
    isLoading: false,
    checkTransactionsResult: undefined,
    error: undefined,
  })),
  //get customer info stripe
  on(getCustomerInfoStripeRequest, (state) => ({
    ...state,
    customerInfoStripe: {
      isLoading: true,
    },
  })),
  on(getCustomerInfoStripeSuccess, (state, { response }) => ({
    ...state,
    customerInfoStripe: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),

  on(getRemittanceStatusAction, (state) => ({
    ...state,
    getRemittanceStatus: {
      isLoading: true,
    },
  })),
  on(getRemittanceStatusSuccessAction, (state, { response }) => ({
    ...state,
    getRemittanceStatus: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getCustomerInfoStripeFailure, (state, { error }) => ({
    ...state,
    customerInfoStripe: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(getRemittanceStatusFailureAction, (state, { error }) => ({
    ...state,
    getRemittanceStatus: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCustomerInfoStripeState, (state) => ({
    ...state,
    customerInfoStripe: undefined,
  })),
  on(clearGetRemittanceStatusStateAction, (state) => ({
    ...state,
    getRemittanceStatus: undefined,
  })),
);
