<div class="dialog-container" id="add-participant-accident-post-card-dialog">
  <div class="header-title">{{ data.accidentPostCard ? 'Edit' : 'Add New' }} Accident Post Card</div>
  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()" *ngIf="!isFormLoading; else loadingTemplate">
    <div class="form-container">
      <div class="input-container">
        <div class="input-group">
          <ptg-datepicker
              [controlField]="editForm.get('accidentDate')"
              placeholder="Accident Date"
              class="input-date"
              [maxDate]="accidentDateMax"
              errorMaxDate="Accident Date cannot be later than current date."
              (changeValue)="onChangeAccidentDate()"
          ></ptg-datepicker>
          <ptg-datepicker
              [controlField]="editForm.get('receiveDate')"
              placeholder="Receive Date"
              class="input-date"
              [maxDate]="receiveDateMax"
              [isDisabled]="isPortalUser"
              errorMaxDate="Receive Date cannot be later than current date."
          ></ptg-datepicker>
        </div>
        <mat-error *ngIf="isShowError" class="error-text">
          Accident Date cannot be later than Receive Date.
        </mat-error>
        <div [ngClass]="{'container-select-muni' : listMunicipality && listMunicipality.length > 0}">
          <ptg-select
              [placeholder]="'Municipality'"
              [controlField]="editForm.get('municipalityId')"
              [listData]="listMunicipality"
              [isOptionObj]="true"
              [isRequired]="true"
              [isSetMaxWidthSelect]="true"
              [isMultipleLineOption]="true"
              [onlyTruncateTitle]="true"
              [isDisabled]="isPortalUser"
              class="select-muni"
          ></ptg-select>
          <mat-error
            *ngIf="isMunicipalityError"
            class="municipality-error"
          >Member did not work for our Municipality when the accident occurred.</mat-error>
        </div>
        <ptg-select
            [placeholder]="'Injury Code - Injury'"
            [controlField]="editForm.get('injuryCode')"
            [listData]="listInjury"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [isMultipleLineOption]="true"
            [onlyTruncateTitle]="true"
            [hasNoneValue]="true"
            class="select-injury"
        ></ptg-select>
        <ptg-input-area
          [controlField]="editForm.get('injuryDescription')"
          [hasLabel]="true"
          placeholder="Injury Description"
          class="input-description"
          errorRequire="Injury description is required."
        ></ptg-input-area>

        <div class="submitter-title">
          Submitter Information
        </div>
        <div class="input-group submitter">
          <ptg-textbox
            [controlField]="editForm.get('submitterName')"
            placeholder="Name"
            [maxLength]="250"
            [hasLabel]="true"
            [isRequired]="true"
          ></ptg-textbox>
          <ptg-textbox
            [controlField]="editForm.get('submitterEmail')"
            placeholder="Email"
            inputType="Email"
            [isRequired]="true"
            [maxLength]="250"
            [hasLabel]="true"
          ></ptg-textbox>
          <ptg-textbox
            [controlField]="editForm.get('submitterPhone')"
            placeholder="Phone"
            [maxLength]="250"
            inputType="Phone"
            [isRequired]="true"
            [hasLabel]="true"
          ></ptg-textbox>
        </div>
      </div>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
  <ng-template #loadingTemplate>
    <div class="flex justify-center w-full my-24">
      <ptg-spinner [isLoading]="isFormLoading"></ptg-spinner>
    </div>
  </ng-template>
</div>
