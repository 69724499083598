import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { DateTime } from 'luxon';

import { environment } from 'src/environments/environment';
import { getValueWithoutFormat, isEmpty } from '@ptg-shared/utils/string.util';
import { BodyChangePropertyKey, PropertyRequest } from '@ptg-member/types/models';
import * as fromReducer from '@ptg-reducers';

import {
  CheckEmployerExistsRequest,
  GetListEmployerQuery,
  OverviewConfigurations,
  EmployerInfoProperties,
  GetEmployerOverviewQuery,
  EmployerOverview,
  EmployerListProperty,
  EditEmployerPropertyRequestDto,
  ListConfig,
  Bank,
  EditEmployerPaymentPreferencesRequestDto,
  DefaultLookupTableOptions,
  EmployerContact,
  EditEmployerInformationRequestDto,
  SectionType,
  EmployerFileTemplate,
  EmployerFileProperty,
  SearchEmployerResponse,
  GetEmployerFilePropertiesRequestDto,
  MappingFieldSection,
  EmployerPortalUser,
  EmployerFilterName,
  EmployerFilterConfig,
  EmployerFilterInformationList,
  FileLayoutConfiguration,
  FileLayoutData,
  FileFormatValidateNumberQuery,
  ExportEmployerFileRequest,
  EmployerFilePostingField,
  EmployerCheckDisableRequest,
  CreateEmployersRequest,
  SetEmployersRequest,
  GetReportCategorySidebarResponse,
  PensionReportInfo,
  GetReportCategorySidebarRequest,
  EmployerColumnConfigs,
  EmployerColumnSetConfigRequest,
  ReorderEmployerColumnSetConfigRequest,
  CheckExistResponse,
  EmployerSystemInfo,
  PaymentMethod,
  RegisterCustomerStripeResponse,
  RegisterCustomerStripeRequest,
  CheckStripeCustomerExistsExistResponse,
} from '../models/employer.model';
import { PropertyType } from '../constance/employer.const';
import { EmployerFilterModal } from '../models/employer-filter.model';
import {
  ParametersQuery,
  TaxStatusList,
  PlanList,
  QueryTaxExistResult,
  ContributionTaxExistsParams,
  QueryPlanExistResult,
  ContributionPlanExistsParams,
  CreateEmployerContributionTaxRequest,
  AddContributionDetailPlanRequest,
  ContributionItemExistParams,
  ContributionItemExisResult,
  AddContributionDetailPlanResult,
  ContributionDetailPlanTypeDto,
  CreateEmployerContributionPlanRequest,
  PlanDetail,
  EditEmployerContributionTaxRequest,
  ContributionPlanConfigurations,
  ContributionPlanConfiguration,
} from '../models/parameters.model';
import { SHOW_LOADING, SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { SortType } from '@ptg-shared/constance';
import { transformEntityToObject } from '@ptg-entity-management/helpers';

@Injectable({
  providedIn: 'root',
})
export class EmployerService {
  activeFund: any;
  searchValue: string = '';
  currentFilter: EmployerFilterModal | undefined;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>,
  ) {
    this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe((el) => (this.activeFund = el));
  }

  getEmployerList(query: GetListEmployerQuery): Observable<any> {
    let params = new HttpParams();
    if (query?.sortNames?.length) {
      params = params.append('SortNames', query.sortNames);
      params = params.append('SortType', query?.sortType ?? SortType.ASC);
    }
    if (query?.pageSize && query?.pageIndex) {
      params = params.append('PageSize', query.pageSize);
      params = params.append('PageIndex', query.pageIndex);
    }
    let body: any = {
      filters: query.filters,
    };
    if (query.screenId) {
      body.screenId = query.screenId;
    }
    if (query.columnSetId) {
      body.columnSetId = query.columnSetId;
    }
    return this.httpClient.post<any>(`${environment.apiUrl}/employer-portal/employers/filter`, body, { params });
  }

  searchEmployer(searchValue: string, screenId: string): Observable<SearchEmployerResponse> {
    this.searchValue = searchValue;
    return this.httpClient.post<SearchEmployerResponse>(`${environment.apiUrl}/employer-portal/employer/searches`, {
      searchValue,
      screenId,
    });
  }

  checkUnique = (body: CheckEmployerExistsRequest) => {
    switch (body.propertyType) {
      case PropertyType.Date:
        const datepipe = new DatePipe('en-US');
        body.value = body.value ? datepipe.transform(body.value, 'yyyy-MM-dd') : '';
        break;
      case PropertyType.Phone:
        body.value = getValueWithoutFormat(body.value);
        break;
      default:
        break;
    }
    return this.httpClient.post(`${environment.apiUrl}/Organization/Employers/CheckUnique`, body);
  };

  // new by entity
  getEmployerOverviewEntity(query: GetEmployerOverviewQuery): Observable<EmployerOverview> {
    let params = new HttpParams();
    if (!isEmpty(query.sectionType)) {
      params = params.append('type', query.sectionType ?? '');
    }
    return this.httpClient.get<EmployerOverview>(`${environment.apiUrl}/employer-portal/employer/${query.id}`, { params });
  }

  getEmployerOverview(query: GetEmployerOverviewQuery): Observable<EmployerOverview> {
    const queryString =
      query.sectionType || query.sectionType === SectionType.EmployerInformation
        ? `?sectionType=${query.sectionType}`
        : '';
    return this.httpClient.get<EmployerOverview>(
      `${environment.apiUrl}/Organization/Employers/${query.id}/Overview${queryString}`,
    );
  }

  getOverViewConfig(): Observable<OverviewConfigurations> {
    return this.httpClient.get<OverviewConfigurations>(
      `${environment.apiUrl}/entities/employers/overview-configurations`,
    );
  }

  saveOverViewConfig(body: OverviewConfigurations) {
    return this.httpClient.post(`${environment.apiUrl}/entities/employers/overview-configurations`, body);
  }

  getEmployerProperty() {
    return this.httpClient.get<EmployerListProperty>(
      `${environment.apiUrl}/Organization/Employers/GetEmployerInfomationProperty`,
    );
  }

  orderTable(body: BodyChangePropertyKey) {
    return this.httpClient.put(`${environment.apiUrl}/Organization/Employers/Property/Order`, body);
  }

  addEmployerProperty(body: PropertyRequest) {
    return this.httpClient.post(`${environment.apiUrl}/Organization/Employers/Property`, body);
  }

  checkPropertyExits = (request: any) => {
    return this.httpClient.post(`${environment.apiUrl}/Organization/Employers/Property/Exists`, request);
  };

  getEmployerInfoProperties(screenId?: string): Observable<EmployerInfoProperties> {
    let params = new HttpParams();
    if (screenId) {
      params = params.append('screenId', screenId);
    }
    return this.httpClient.get<EmployerInfoProperties>(
      `${environment.apiUrl}/Organization/Employers/GetEmployerInfomationProperty`,
      { params },
    );
  }

  editEmployerProperty(propertyKey: string, body: EditEmployerPropertyRequestDto) {
    return this.httpClient.put(`${environment.apiUrl}/Organization/Employers/Property/${propertyKey}`, body);
  }

  removeEmployerProperty(propertyKey: string) {
    return this.httpClient.delete(`${environment.apiUrl}/Organization/Employers/Property/${propertyKey}`);
  }

  getLookupTableOptions(screenId?: string) {
    let params = new HttpParams();
    if (screenId) {
      params = params.append('screenId', screenId);
    }
    return this.httpClient.get<DefaultLookupTableOptions>(
      `${environment.apiUrl}/employer-portal/employer/Contacts/DefaultLookupTableOptions`,
      { params },
    );
  }

  createEmployerContact(employerId: string, employerContact: EmployerContact) {
    return this.httpClient.post(`${environment.apiUrl}/employer-portal/employer/${employerId}/Contacts`, employerContact);
  }

  updateEmployerContact(employerId: string, employerContact: EmployerContact) {
    return this.httpClient.put(
      `${environment.apiUrl}/employer-portal/employer/${employerId}/Contacts/${employerContact.id}`,
      employerContact,
    );
  }

  getPropertyListFilter(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/employer-portal/employers/filters/properties`);
  }

  getPropertyList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/entities/employers/information-configuration-properties`);
  }

  getPropertyListConfiguration(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/entities/employers/properties/list-view-configuration`);
  }

  getEmployerListConfiguration(): Observable<ListConfig> {
    return this.httpClient.get<ListConfig>(`${environment.apiUrl}/entities/employers/list-view-configuration`);
  }

  getEmployerFileTemplates(employerId: string): Observable<{ fileTemplates: EmployerFileTemplate[] }> {
    return this.httpClient.get<{ fileTemplates: EmployerFileTemplate[] }>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileTemplates`,
    );
  }

  setEmployerFileFormat(employerId: string, employerFileTemplate: EmployerFileTemplate) {
    return this.httpClient.put(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileTemplates/${employerFileTemplate.id}/FileFormat`,
      employerFileTemplate,
    );
  }

  getEmployerFileProperties(
    employerId: string,
    query: GetEmployerFilePropertiesRequestDto,
  ): Observable<{ total: number; properties: EmployerFileProperty[] }> {
    let body: any = {
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
    };
    if (query.sortNames) {
      body = {
        ...body,
        sortNames: [query?.sortNames],
        sortType: query.sortType || 0,
      };
    }
    if (!query.pageSize) {
      delete body.pageSize;
    }

    const params = new HttpParams({ fromObject: body as any });
    return this.httpClient.get<{
      total: number;
      properties: EmployerFileProperty[];
    }>(`${environment.apiUrl}/Organization/Employers/${employerId}/FileProperties`, { params });
  }

  getMappingFieldSections(
    employerId: string,
    filePropertyId?: string,
  ): Observable<{ sections: MappingFieldSection[] }> {
    const query = filePropertyId ? `?filePropertyId=${filePropertyId}` : '';
    return this.httpClient.get<{ sections: MappingFieldSection[] }>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileProperties/MappingFieldSections${query}`,
    );
  }

  createEmployerFileProperty(employerId: string, property: EmployerFileProperty) {
    return this.httpClient.post(`${environment.apiUrl}/Organization/Employers/${employerId}/FileProperties`, property);
  }

  updateEmployerFileProperty(employerId: string, filePropertyId: string, property: EmployerFileProperty) {
    return this.httpClient.put(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileProperties/${filePropertyId}`,
      property,
    );
  }

  checkFilePropertyInUsed(employerId: string, filePropertyId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileProperties/${filePropertyId}/Using`,
    );
  }

  removeEmployerFileProperty(employerId: string, filePropertyId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileProperties/${filePropertyId}`,
    );
  }

  getFilePropertiesForPostingFields(employerId: string): Observable<EmployerFilePostingField> {
    return this.httpClient.get<EmployerFilePostingField>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileProperties/ConfigurePostingFields`,
    );
  }

  configurePostingFields(employerId: string, participantKeyId?: string, wagePeriodId?: string) {
    return this.httpClient.put(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileProperties/ConfigurePostingFields`,
      { participantKeyId, wagePeriodId },
    );
  }

  checkEmployerFilePropertyExists = (request: any) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Organization/Employers/${request.employerId}/FileProperties/Exists`,
      request,
    );
  };

  setEmployerListConfiguration(body: any) {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/entities/employers/information-configuration-properties`,
      body,
    );
  }

  setEmployerListViewConfiguration(body: any) {
    return this.httpClient.post<any>(`${environment.apiUrl}/entities/employers/list-view-configuration`, body);
  }

  getMunicipalityCheckDisable(body: EmployerCheckDisableRequest) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    return this.httpClient.get<EmployerCheckDisableRequest>(
      `${environment.apiUrl}/municipalities/${body.employerId}/check-disabled/${body.inactivationDate}`,
      { context },
    );
  }

  getBankInfo(routingNumber: number): Observable<Bank> {
    return this.httpClient.post<Bank>(`${environment.apiUrl}/payroll-portal/Banks/Detail/${routingNumber}`, {
      boardInformationId: null,
      boardBankId: null,
    });
  }

  getMunicipalityUnposedRemittance(employerId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/municipalities/${employerId}/remittances/unposted-remittances/existing`,
    );
  }

  editEmployerInformation(employerId: string, body: EditEmployerInformationRequestDto) {
    return this.httpClient.put(`${environment.apiUrl}/Organization/Employers/${employerId}/EmployerInformation`, body);
  }

  editEmployerPaymentPreferences(employerId: string, body: EditEmployerPaymentPreferencesRequestDto) {
    return this.httpClient.put(`${environment.apiUrl}/employer-portal/employer/${employerId}/payment-preferences`, body);
  }

  removeEmployerContact(employerId: string, contactId: string) {
    return this.httpClient.delete(`${environment.apiUrl}/employer-portal/employer/${employerId}/Contacts/${contactId}`);
  }

  createEmployerPortalUser(employerId: string, employerContact: EmployerPortalUser) {
    return this.httpClient.post(
      `${environment.apiUrl}/Organization/Employers/${employerId}/PortalUser`,
      employerContact,
    );
  }

  updateEmployerPortalUser(employerId: string, employerContact: EmployerPortalUser) {
    return this.httpClient.put(
      `${environment.apiUrl}/Organization/Employers/${employerId}/PortalUser/${employerContact.id}`,
      employerContact,
    );
  }
  checkEmployerFilterExist = (body: EmployerFilterName) => {
    return this.httpClient.post(`${environment.apiUrl}/employer-portal/employers/filters/existing`, body);
  };

  createEmployerFilter(body: EmployerFilterConfig): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/employer-portal/employers/filters`, body);
  }

  getEmployerFilter(): Observable<EmployerFilterInformationList> {
    return this.httpClient.get<EmployerFilterInformationList>(`${environment.apiUrl}/employer-portal/employers/filters`);
  }

  updateEmployerFilterName(employerFilterName: EmployerFilterName) {
    return this.httpClient.put(
      `${environment.apiUrl}/employer-portal/employers/filters/${employerFilterName.id}`,
      employerFilterName,
    );
  }

  removeEmployerFilter(id: string): Observable<void> {
    if (id === this.currentFilter?.id) {
      this.currentFilter = undefined;
    }
    return this.httpClient.delete<void>(`${environment.apiUrl}/employer-portal/employers/filters/${id}`);
  }

  getEmployerFileLayoutConfiguration(employerId: string, templateId: string): Observable<FileLayoutData> {
    return this.httpClient.get<FileLayoutData>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileTemplates/${templateId}/FileLayout`,
    );
  }

  setEmployerFileLayoutConfiguration(
    employerId: string,
    fileTemplateId: string,
    employerFileLayouts: FileLayoutConfiguration[],
  ) {
    let body = {
      employerFileLayouts: employerFileLayouts,
    };
    return this.httpClient.put(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileTemplates/${fileTemplateId}/FileLayout`,
      body,
    );
  }

  validateValueNumber(employerId: string, fileTemplateId: string, query: FileFormatValidateNumberQuery) {
    return this.httpClient.post(
      `${environment.apiUrl}/Organization/Employers/${employerId}/FileTemplates/${fileTemplateId}/FileFormat/Validate`,
      query,
    );
  }

  //Begin Employer Parameters

  getContributionPlanConfigurations(employerId: string): Observable<ContributionPlanConfigurations> {
    return this.httpClient.get<ContributionPlanConfigurations>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/ContributionPlanConfigurations`,
    );
  }

  setContributionPlanConfigurations(
    employerId: string,
    contributionPlanConfigurations: ContributionPlanConfiguration[],
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/ContributionPlanConfigurations`,
      { contributionPlanConfigurations: contributionPlanConfigurations },
    );
  }

  getEmployerTaxStatus(employerId: string, query: ParametersQuery): Observable<TaxStatusList> {
    let body: any = {
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
    };
    if (query.sortNames) {
      body = {
        ...body,
        sortNames: [query?.sortNames],
        sortType: query.sortType || 0,
      };
    }
    let params = new HttpParams({ fromObject: body as any });
    return this.httpClient.get<TaxStatusList>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/contribution-tax-status`,
      { params },
    );
  }

  getEmployerPlan(employerId: string, query: ParametersQuery): Observable<PlanList> {
    let body: any = {
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
    };
    if (query.sortNames) {
      body = {
        ...body,
        sortNames: [query?.sortNames],
        sortType: query.sortType || 0,
      };
    }
    let params = new HttpParams({ fromObject: body as any });
    return this.httpClient.get<PlanList>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/contribution-plan`,
      { params },
    );
  }

  getEmployerPlanDetail(contributionPlanId: string): Observable<PlanDetail> {
    return this.httpClient.get<PlanDetail>(`${environment.apiUrl}/employer/contribution-plan/${contributionPlanId}`);
  }

  checkContributionTaxExists<TData = QueryTaxExistResult>(
    employerId: string,
    contributionTaxExistsParams?: ContributionTaxExistsParams,
  ): Observable<TData> {
    let params = new HttpParams({
      fromObject: contributionTaxExistsParams as any,
    });
    return this.httpClient.get<TData>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/Contribution/Tax/Exists`,
      { params },
    );
  }

  checkContributionPlanExists<TData = QueryPlanExistResult>(
    employerId: string,
    planExistsParams?: ContributionPlanExistsParams,
  ): Observable<TData> {
    let params = new HttpParams({ fromObject: planExistsParams as any });
    if (!planExistsParams?.effectiveEndDate) {
      params = params.delete('effectiveEndDate');
    }
    if (!planExistsParams?.id) {
      params = params.delete('id');
    }
    return this.httpClient.get<TData>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/Contribution/Plan/Exists`,
      { params },
    );
  }

  exportEmployerList(request: ExportEmployerFileRequest) {
    let body: any = {
      pageSize: request.totalPerPage,
      pageIndex: request.pageIndex,
      fileName: request.fileName,
      listFilter: request.listFilter,
      timeZone: DateTime.now().toFormat('ZZ'),
    };
    if (request.sortField) {
      body = {
        ...body,
        sortNames: [request?.sortField?.[0]?.toUpperCase() + (request?.sortField?.substring(1) || '')],
        sortType: request?.sortType || 0,
      };
    }
    if (request.columnSetId) {
      body.columnSetId = request.columnSetId;
    }
    return this.httpClient.post(`${environment.apiUrl}/employers/data-export`, body);
  }

  downloadExportFile(fileName: string) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/ExportMembers/Download`,
      { fileName },
      { responseType: 'blob' },
    );
  }

  /**
   * @summary Add new contribution plan
   */
  createNewContributionPlan<TData = void>(
    employerId: string,
    request: CreateEmployerContributionPlanRequest,
  ): Observable<TData> {
    return this.httpClient.post<TData>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/Contribution/Plan`,
      request,
    );
  }

  /**
   * @summary edit contribution plan
   */
  editContributionPlan<TData = void>(
    contributionPlanId: string,
    request: CreateEmployerContributionPlanRequest,
  ): Observable<TData> {
    return this.httpClient.put<TData>(
      `${environment.apiUrl}/employers/contribution-plan/${contributionPlanId}`,
      request,
    );
  }

  /**
   * @summary Add new contribution tax
   */
  createNewContributionTax<TData = void>(
    employerId: string,
    request: CreateEmployerContributionTaxRequest,
  ): Observable<TData> {
    return this.httpClient.post<TData>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/Contribution/Tax`,
      request,
    );
  }

  /**
   * @summary Update contribution tax
   */
  editContributionTax<TData = void>(
    employerId: string,
    request: EditEmployerContributionTaxRequest,
  ): Observable<TData> {
    return this.httpClient.put<TData>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/contribution-tax-status/${request.id}`,
      request,
    );
  }

  /**
   * @summary Delete contribution tax
   */
  removeContributionTax<TData = void>(employerId: string, taxId: string): Observable<TData> {
    return this.httpClient.delete<TData>(
      `${environment.apiUrl}/Organization/Employers/${employerId}/contribution-tax-status/${taxId}`,
    );
  }

  /**
   * @summary Delete contribution item
   */
  removeContributionItem<TData = void>(id: string): Observable<TData> {
    return this.httpClient.delete<TData>(`${environment.apiUrl}/employers/contribution-item/${id}`);
  }

  /**
   * @summary Check exist contribution plan item
   */
  getContributionItemExist<TData = ContributionItemExisResult>(
    contributionPlanId: string,
    planExistsParams?: ContributionItemExistParams,
  ): Observable<TData> {
    let params = new HttpParams({ fromObject: planExistsParams as any });
    return this.httpClient.get<TData>(
      `${environment.apiUrl}/employers/contribution-plan/${contributionPlanId}/contribution-item/exist`,
      { params },
    );
  }

  /**
   * @summary Create contribution plan item
   */
  AddContributionItem<TData = AddContributionDetailPlanResult>(
    contributionPlanId: string,
    body: AddContributionDetailPlanRequest,
  ): Observable<TData> {
    return this.httpClient.post<TData>(
      `${environment.apiUrl}/employers/contribution-plan/${contributionPlanId}/contribution-item`,
      body,
    );
  }

  /**
   * @summary Edit contribution plan item
   */
  EditContributionItem<TData = AddContributionDetailPlanResult>(
    contributionPlanId: string,
    contributionItemId: string,
    body: AddContributionDetailPlanRequest,
  ): Observable<TData> {
    return this.httpClient.put<TData>(
      `${environment.apiUrl}/employers/contribution-plan/${contributionPlanId}/contribution-item/${contributionItemId}`,
      body,
    );
  }

  /**
   * @summary Get available type to create new contribution item
   */
  GetContributionItemAvailableType<TData = ContributionDetailPlanTypeDto>(
    contributionPlanId: string,
  ): Observable<TData> {
    return this.httpClient.get<TData>(
      `${environment.apiUrl}/employers/contribution-plan/${contributionPlanId}/contribution-item/available-type`,
    );
  }

  //End Employer Parameters

  // check exist email for employer portal user
  checkEmailExists = (bodyCheck: any) => {
    return this.httpClient.post<CheckExistResponse>(
      `${environment.apiUrl}/Organization/Employers/${bodyCheck.employerId}/PortalUser/Exists`,
      { email: bodyCheck.email },
    );
  };

  createEmployers(request: CreateEmployersRequest): Observable<{ id: string }> {
    return this.httpClient.post<{ id: string }>(`${environment.apiUrl}/entities/employers`, transformEntityToObject(request));
  }

  setEmployers(request: SetEmployersRequest): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiUrl}/entities/employers/${request.employerId}`, transformEntityToObject(request));
  }

  checkEmployerPrimaryContactExists(employerId: string) {
    return this.httpClient.get(`${environment.apiUrl}/employer-portal/${employerId}/primary-contact/exists`);
  }

  getReportCategorySideBar(request: GetReportCategorySidebarRequest): Observable<GetReportCategorySidebarResponse> {
    if (this.activeFund?.id) {
      let params = new HttpParams({ fromObject: { ...request } });
      return this.httpClient.get<GetReportCategorySidebarResponse>(`${environment.apiUrl}/report/category-sidebar`, {
        params,
      });
    }
    return of();
  }

  getReportInfo(muniId: string): Observable<PensionReportInfo> {
    return this.httpClient.get<PensionReportInfo>(`${environment.apiUrl}/municipalities/${muniId}/pension-report`);
  }

  removeDocumentsEmployer(employerId: string, documentId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/employer-portal/employer/${employerId}/documents/${documentId}`
    );
  }

  getPermissionForMuniPortalUser(employerId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/AccessControl/permission-portal/${employerId}`,
    );
  }

  getColumnSetConfigurationEntity( isDragDrop: boolean, entityId: string ): Observable<EmployerColumnConfigs> {
    let params = new HttpParams();
    if (isDragDrop) {
      params = params.append('isDragDrop', isDragDrop);
    }
    if (entityId) {
      params = params.append('entityId', entityId);
    }

    return this.httpClient.get<EmployerColumnConfigs>(
      `${environment.apiUrl}/entities/employers/column-set-configuration`, {params},
    );
  }

  createColumnSetConfiguration( body: EmployerColumnSetConfigRequest): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/entities/employers/column-set-configuration`, body
    );
  }

  editColumnSetConfiguration( id: string, body: EmployerColumnSetConfigRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entities/employers/column-set-configuration/${id}`, body
    );
  }

  removeColumnSet(id: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/entities/employers/column-set-configuration/${id}`
    );
  }

  reorderColumnSetConfiguration( body: ReorderEmployerColumnSetConfigRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entities/members/manage-column-set/reorder-column-set`, body
    )
  }

  getEmployerSystemInfoById(employerId: string, clientKey?: string): Observable<EmployerSystemInfo> {
    const context = new HttpContext().set(SKIP_ERRORS, '404');
    let params = new HttpParams();
    if (clientKey) {
      params = params.append('ClientKey', clientKey);
    }
    return this.httpClient.get<EmployerSystemInfo>(`${environment.apiUrl}/employer-portal/${employerId}/system-info`, { params, context });
  }

  getValidateEditPaymentMethod(employerId: string, method: PaymentMethod): Observable<boolean> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    const params = new HttpParams({fromObject: { method: method.toString() }});
    return this.httpClient.get<boolean>(
      `${environment.apiUrl}/employer-portal/employer/${employerId}/payment-preferences/validate-edit`,
      { params, context },
    );
  }

  checkStripeCustomerExists(employerId: string): Observable<CheckStripeCustomerExistsExistResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.post<CheckStripeCustomerExistsExistResponse>(`${environment.apiUrl}/api/stripe/customer/exists`, { employerId }, { context });
  }

  registerCustomerStripe(request: RegisterCustomerStripeRequest): Observable<RegisterCustomerStripeResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.post<RegisterCustomerStripeResponse>(
      `${environment.apiUrl}/api/stripe/customer`,
      { ...request },
      { context },
    );
  }
}
