import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { filter, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import * as fromReducer from '@ptg-reducers';

import { ACTION_COLUMN, Column, ColumnType, Row } from '@ptg-shared/controls/grid';

import { ACTION, BUTTON_LABEL_CLOSE, DEFAULT_PAGE_SIZE, SORT_TYPE, STATE, SortType } from '@ptg-shared/constance';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import {
  CalculationState,
  getRetirementBenefitHistoriesAction,
  getRetirementBenefitHistorySelector,
  removeCalculationBenefitSelector,
  clearRemoveCalculationBenefitAction,
  getInitiateBenefitValidationsAction,
  createInitiateRefundCalculationInformationAction,
  clearCreateInitiateRefundCalculationInformationStateAction,
  removeCalculationBenefitAction,
  clearGetInitiateBenefitValidationsStateAction,
  getValidateBeforeRemoveCalculationBenefitAction,
  clearGetValidateBeforeRemoveCalculationBenefitStateAction,
  clearCheckExceptionConfigurationStateAction,
  checkExceptionConfigurationAction,
  checkExceptionSelector,
} from '../../store';
import {
  GetRetirementBenefitRequest,
  RetirementBenefitHistory,
  InitiatedBenefitButton,
  GetInitiateBenefitValidationsRequest,
  CreateInitiateRefundCalculationInformationRequest,
  GetRefundCalculationRecordsRequest,
  CheckExceptionRequest,
} from '../../services/models';
import { showBanner } from '@ptg-shared/utils/common.util';
import { Sort } from '@angular/material/sort';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { AccidentParticipantsState } from '@ptg-member/features/accident-claims/store/reducers/accident-participants.reducer';
import { BenefitType, POPUP_MESSAGE } from '../../constants';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { combineLatest } from 'rxjs';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import {
  createInitiateRefundCalculationInformationSelector,
  getInitiateBenefitValidationsSelector,
  getValidateBeforeRemoveCalculationBenefitSelector,
} from '../../store/selectors/benefit-refund.selectors';
import { CalculationBenefitHistoryStatus, CalculationType } from '../../types/enums';
import { MenuItemTitle, MenuItemSubTitle } from '@ptg-member/constants';

const PAGE_SIZE_CONST = '-ptg-refunds-overview-pageSize';

@Component({
  selector: 'ptg-refunds-overview',
  templateUrl: './refunds-overview.component.html',
  styleUrls: ['./refunds-overview.component.scss'],
})
export class RefundsOverviewComponent extends BaseListComponent {
  listBreadcrumbs: Breadcrumb[] = [];
  memberId: string = '';

  message = '';
  bannerType: BannerType = BannerType.Hidden;
  bannerTypeSurvivor: BannerType = BannerType.Hidden;

  sortInfo?: Sort;
  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber = FIRST_PAGE;
  sortType = SortType.ASC;
  pageHistoryIndex: number = FIRST_PAGE;
  pageHistorySize: number = DEFAULT_PAGE_SIZE;

  totalBenefitHistories: number = 0;
  isHistoryLoading?: boolean = false;
  benefitCalculationHistories?: RetirementBenefitHistory[] = [];
  noDataHistories: string = 'No Refund History to Display';

  refundCalculationType = CalculationType.Refund;
  currentFund: any = {};

  removedHistoryId: string = '';
  isShowGearIcon: boolean = false;
  listInitiatedButton: InitiatedBenefitButton[] = [];
  canInitiateRefunds: boolean = true;

  isDisabledInitiateButton: boolean = false;

  menuItemTitle: string = MenuItemTitle.Refunds;
  menuItemSubTitle: string = MenuItemSubTitle.ReturnsProcessing;

  InitiatedStatus = CalculationBenefitHistoryStatus.Initiated;
  private initiatedCalculationBenefit = {
    benefitEntityId: '',
    calculationBenefitId: '',
    calculationRecordId: '',
  };

  columnsBenefitHistory: Column[] = [
    {
      name: 'initiateDate',
      header: {
        title: 'Created Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'netRefund',
      header: {
        title: 'Net Refund',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'displayStatus',
      header: {
        title: 'Status',
      },
      truncate: true,
      sortable: true,
      cell: (row) => {
        return CalculationBenefitHistoryStatus[row.status];
      },
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      truncate: true,
    },
  ];

  constructor(
    public layoutService: LayoutService,
    public route: ActivatedRoute,
    private router: Router,
    private calculationStore: Store<CalculationState>,
    private dialog: MatDialog,
    private store: Store<AccidentParticipantsState>,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listBreadcrumbs.push({ name: 'Refunds Overview' });
    this.getCurrentFundAndRouteData();
    this.showBannerFunction();
    this.selectorValidateBeforeRemoveCalculationBenefit();
  }

  showBannerFunction() {
    this.calculationStore
      .select(removeCalculationBenefitSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.store.dispatch(clearRemoveCalculationBenefitAction());
          // Keep 'Benefit' is common message
          showBanner.call(this, state.state, 'Refund', state.action);
          if (state.action === ACTION.REMOVE && state?.state === STATE.SUCCESS) {
            if (
              this.pageHistoryIndex !== 1 &&
              this.benefitCalculationHistories &&
              this.benefitCalculationHistories.length <= 1
            ) {
              this.pageHistoryIndex = this.pageHistoryIndex - 1;
            }
            this.getBenefitCalculationHistories();
          }
        }
      });
  }

  getCurrentFundAndRouteData() {
    combineLatest([this.route.params, this.store.select(fromReducer.selectCurrentFundState)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, currentFund]) => {
        this.defaultPageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
        this.currentFund = currentFund;
        this.memberId = params.memberId;
        this.setPageSize();
        this.getBenefitCalculationHistories();
        this.selectBenefitCalculationHistories();

        this.selectorInitiateRefundValidation();
        this.selectorInitiateRefunds();

        this.selectCheckExceptionState();
      });
  }

  setPageSize() {
    const benefitHistoryPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.refundCalculationType + BenefitType.BenefitHistory + PAGE_SIZE_CONST,
      ),
    );
    this.pageHistorySize =
      benefitHistoryPageSizeInSession === 0 ? this.defaultPageSize : benefitHistoryPageSizeInSession;
  }

  getBenefitCalculationHistories() {
    const requestDocument: GetRetirementBenefitRequest = this.setBenefitRequest(
      { name: 'InitiateDate', direction: SortType.DESC },
      this.pageHistorySize,
      this.pageHistoryIndex,
    );
    this.calculationStore.dispatch(
      getRetirementBenefitHistoriesAction({
        request: requestDocument,
        memberId: this.memberId,
        calculationType: this.refundCalculationType,
      }),
    );
  }

  selectBenefitCalculationHistories() {
    this.calculationStore
      .select(getRetirementBenefitHistorySelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.isHistoryLoading = state?.isLoading;
        if (!this.isHistoryLoading && state?.success && state.payload) {
          this.benefitCalculationHistories = state?.payload?.benefitHistories?.map((benefitHistory: any) => {
            return {
              ...benefitHistory,
              initiateDate: getDateString(benefitHistory.initiateDate),
            };
          });
          this.totalBenefitHistories = state?.payload?.total ?? 0;
          this.isDisabledInitiateButton = state?.payload?.initiateBenefitValidate[0]?.isDisabled;
        }
      });
  }

  onClickInitiateBenefit() {
    const request: GetInitiateBenefitValidationsRequest = {
      memberId: this.memberId,
      calculationType: this.refundCalculationType,
    };
    this.calculationStore.dispatch(getInitiateBenefitValidationsAction({ request }));
  }

  selectorInitiateRefundValidation() {
    this.calculationStore
      .select(getInitiateBenefitValidationsSelector)
      .pipe(
        filter((res) => !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response?.success && !response?.isLoading && response.payload) {
          const { isValidCalculationInitiation, isValidMemberPaidPension } = response.payload;
          this.calculationStore.dispatch(clearGetInitiateBenefitValidationsStateAction());

          if (!isValidCalculationInitiation) {
            const errorMessage =
              'This member has a refund with status is "Initiated" or "Pending Approval". Cannot initiate a refund.';
            const title = 'Error';
            this.errorPopup(errorMessage, title);
            return;
          }
          if (!isValidMemberPaidPension) {
            const errorMessage = 'Total "Member Paid Pension" of member = 0. Cannot initiate a refund.';
            const title = 'Error';
            this.errorPopup(errorMessage, title);
            return;
          }

          const request: CreateInitiateRefundCalculationInformationRequest = {
            memberId: this.memberId,
          };

          this.calculationStore.dispatch(createInitiateRefundCalculationInformationAction({ request }));
        }
      });
  }

  selectorInitiateRefunds() {
    this.calculationStore
      .select(createInitiateRefundCalculationInformationSelector)
      .pipe(
        filter((res) => !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response) {
          this.calculationStore.dispatch(clearCreateInitiateRefundCalculationInformationStateAction());
          if (response?.success && !response?.isLoading && response?.payload) {
            const {
              benefitEntityId = '',
              calculationBenefitId = '',
              calculationRecordId = '',
            } = response?.payload ?? {};
            this.initiatedCalculationBenefit = {
              benefitEntityId,
              calculationBenefitId,
              calculationRecordId,
            };
            this.getCheckExceptionData(benefitEntityId, calculationRecordId);
          }
          if (!response?.success) {
            showBanner.call(this, BannerType.Fail, '', '', {
              customMessage: `Error occurred initiating Refund Benefit. Please try again.`,
            });
          }
        }
      });
  }

  private getCheckExceptionData(benefitEntityId: string, calculationRecordId: string): void {
    this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());

    const request: CheckExceptionRequest = {
      memberId: this.memberId,
      benefitEntityId,
      targetId: calculationRecordId,
      targetType: this.refundCalculationType,
    };
    this.calculationStore.dispatch(checkExceptionConfigurationAction({ request }));
  }

  private selectCheckExceptionState(): void {
    this.calculationStore
      .pipe(
        select(checkExceptionSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response?.success) {
          this.router.navigateByUrl(
            `member/refunds-overview/${this.memberId}/calculation-detail/${this.initiatedCalculationBenefit.calculationBenefitId}/${this.initiatedCalculationBenefit.calculationRecordId}`,
          );
        }
      });
  }

  onRemoveHistory(row: Row) {
    this.removedHistoryId = row.id;

    const request: GetRefundCalculationRecordsRequest = {
      memberId: this.memberId,
      calculationBenefitId: this.removedHistoryId,
    };
    this.calculationStore.dispatch(getValidateBeforeRemoveCalculationBenefitAction({ request }));
  }

  selectorValidateBeforeRemoveCalculationBenefit() {
    this.calculationStore
      .select(getValidateBeforeRemoveCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response?.payload) {
          const isValidRemoveBenefit = response?.payload?.isValidRemoveBenefit ?? false;
          this.calculationStore.dispatch(clearGetValidateBeforeRemoveCalculationBenefitStateAction());
          if (!isValidRemoveBenefit) {
            this.errorPopup(POPUP_MESSAGE.RemoveRefundBenefitNotice, 'Error');
            return;
          }

          const confirmRemove = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              title: 'Remove',
              text: 'Are you sure to remove this item?',
              type: ConfirmType.Delete,
              cancelButtonTitle: 'No',
              hideConfirmButton: false,
            },
          });

          confirmRemove.afterClosed().subscribe((result) => {
            if (result) {
              const request = {
                id: this.removedHistoryId,
                memberId: this.memberId,
              };
              this.calculationStore.dispatch(removeCalculationBenefitAction(request));
            }
          });
        }
      });
  }

  onChangeSortHistories(event: Sort) {
    if (event.active === 'displayStatus') {
      this.sortInfo = {
        active: 'status',
        direction: event.direction,
      };
    } else if (event.active === 'benefitBeginDate') {
      this.sortInfo = {
        active: 'calcAsOfDate',
        direction: event.direction,
      };
    } else {
      this.sortInfo = event;
    }
    this.getBenefitCalculationHistories();
  }

  onChangeHistoriesPage(event: PageEvent) {
    super.onChangePage(event);
    this.pageHistorySize = event.pageSize;
    this.pageHistoryIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.refundCalculationType + BenefitType.BenefitHistory + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getBenefitCalculationHistories();
  }

  openBenefitCalculationDetail(row: RetirementBenefitHistory) {
    this.router.navigateByUrl(`member/refunds-overview/${this.memberId}/detail/${row?.id}`);
  }

  private setBenefitRequest(
    sortParam: { name: string; direction: number },
    pageSize: number,
    pageNumber: number,
  ) {
    let sortType = sortParam.direction;
    let sortNames = sortParam.name;

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    const requestDocument: GetRetirementBenefitRequest = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };

    return requestDocument;
  }

  private errorPopup(errorMessage: string, title: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: errorMessage,
        type: ConfirmType.Warning,
        title: title,
        cancelButtonTitle: BUTTON_LABEL_CLOSE,
        hideConfirmButton: true,
      },
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());
  }
}
