import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FixedSection } from '@ptg-member/constance/metadata.const';
import { TotalServicesReponse } from '@ptg-member/types/models/total-services.model';

@Injectable({
  providedIn: 'root'
})
export class TotalServiceService {

  constructor(private httpClient: HttpClient) {}

  getTotalService(
    query: any, memberId: string,
  ): Observable<TotalServicesReponse> {
    let params = new HttpParams();
    if (query) {
      params = params.append('Masked', true);
      params = params.append('PageSize', query.pageSize);
      params = params.append('PageIndex', query.pageIndex);
    }
    return this.httpClient.get<TotalServicesReponse>(
      `${environment.apiUrl}/member-portal/${memberId}/${FixedSection.MuniTotal}`,
      { params }
    );
  }
}
