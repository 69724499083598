export enum EntityPropertyType {
  Fixed = 0,
  Text = 1,
  Email = 2,
  Phone = 3,
  'Whole Number' = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  'Date Time' = 8,
  Binary = 9,
  Percentage = 10,
  SSN = 11,
  RichText = 12,
  'Elapsed Time' = 14,
  Lookup = 50,
  'Person Name' = 101,
  Employer = 51,
  Department = 52,
  Benefit = 53,
  'Entity Reference' = 54,
  Tier = 55,
  Address = 100,
  Status = 102,
  Aggregation = 103,
  Calculation = 104,
  Register = 200,
  SmsOptedIn = 201,
  PaperlessOptedIn = 202,
  System = 203,
  Data = 204,
  Identifier = 205,
}

export enum FixedPropertyKey {
  RelateTo = 'RelateTo',
  RelationshipType = 'RelationshipType',
}

export enum StaticPropertyType {
  None,
  Percentage,
  IsPrimary
}
