import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ACTION_COLUMN, Align, ReorderInfo } from '@ptg-shared/controls/grid';
import { Store } from '@ngrx/store';
import * as fromMember from '../../store/reducers';
import { MemberListActions } from '@ptg-member/store/actions';
import { filter, first, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ColumnDetail } from '@ptg-member/types/models/member-list';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ptg-column-set-list',
  templateUrl: './column-set-list.component.html',
  styleUrls: ['./column-set-list.component.scss']
})
export class ColumnSetListComponent extends BaseComponent {
  ACTION_COLUMN = ACTION_COLUMN;
  columns = [
    {
      name: 'columnName',
      editable: true,
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];
  listColumnConfig: ColumnDetail[] = [];
  isLoading: boolean = true;
  constructor(  
    public dialog: MatDialog,
    public dialogRefColumn: MatDialogRef<ColumnSetListComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDetail: {
      columnConfig: ColumnDetail[],
      entityId: string
    },
    private memberStore: Store<fromMember.MemberState>,
    public datePipe: DatePipe
    ) { 
      super();
    }

  ngOnInit(): void {
    this.isLoading = true;
    this.memberStore
      .select(fromMember.selectListColumnConfigState)
      .pipe(
        filter((state: any) => state?.payload?.listColumnConfig?.length),
        first(),
        takeUntil(this.unsubscribe$)
      ).subscribe((state: any) => {
        this.isLoading = false;
        const coverColumnConfig = state?.payload?.listColumnConfig.filter((item: ColumnDetail) => !item?.isDragDrop);
        this.listColumnConfig = coverColumnConfig.map((item: ColumnDetail) => {
          return {
            ...item,
            value: item.columnSetName,
            valueDescription: item.auditTrail ? `${item.auditTrail} ${this.datePipe.transform(new Date(item.dateUpdated + ' UTC'), 'MM/dd/yyyy hh:mm a')}`  : '',
            displayValue: item.columnSetName,
          };
        });
    });
  }

  
  onCancel() {
    this.dialogRefColumn.close();
  }

  changeItemIndex(event: ReorderInfo) {
    if(!event.currentIndex) {
      const dataProperties = JSON.parse(JSON.stringify(this.dataDetail.columnConfig));
      const oldIndex = Number(dataProperties.findIndex((el: any) => el.id === event.reorderItemKey));
      const element = dataProperties.splice(oldIndex, 1)[0];
      dataProperties.splice(1, 0, element);
      this.dataDetail.columnConfig = dataProperties;
      this.listColumnConfig = dataProperties;
    }
    const body = {
      entityId: this.dataDetail.entityId,
      upperAdjacentKey: event.upperAdjacentKey ? event.upperAdjacentKey : null,
      reorderColumnSetId: event.reorderItemKey
    };
    this.memberStore.dispatch(MemberListActions.reorderColumnSet({ body }));
  }

  onUpdateColumSet(data?: any) {
    const body = {
      columSelected: data,
      listColumnConfig: this.dataDetail.columnConfig,
    };
    this.dialogRefColumn.close(body);
  }

  removeColumSet(data: ColumnDetail) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        type: ConfirmType.Warning,
        title: 'Confirmation',
        cancelButtonTitle: 'No',
        text: `Are you sure you want to remove this Column Set?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(
          MemberListActions.removeColumnSetConfigurationRequest({ id: data.id })
        );
        this.dialogRefColumn.close();
      }
    });
  }
}
