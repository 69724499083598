import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Auth0Service } from './shared/auth/services/auth0.service';
import { CheckPermissionService } from './shared/services/check-permission.service';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { CheckVersionFlagService } from '@ptg-shared/services/check-version-flag.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'ptg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'pension-admin';
  isLogin = false;
  isNotDisplayLayout = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    public authService: Auth0Service,
    public router: Router,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public checkPermissionService: CheckPermissionService,
    public layoutService: LayoutService,
    public checkVersionFlagService: CheckVersionFlagService,
  ) {
  }

  ngOnInit(): void {
    this.checkAuthenticationState();
    this.routerChange();
  }

  checkAuthenticationState() {
    this.authService.initializeAuth();
    this.authService.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
      this.isLogin = isAuthenticated;
      if (!this.isLogin) {
        localStorage.clear();
        if (this.router.url !== '/not-authorized') {
          location.reload();
        } 
        return;
      }
      if (this.authService.Role) {
        this.checkVersionFlagService.getAppSettings();
      }
    });
  }

  routerChange() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => (event as NavigationEnd)?.urlAfterRedirects)
    ).subscribe((url) => {
      this.isNotDisplayLayout = url === '/not-authorized' || (url === '/permission' && this.authService.isMunicipalityPortal$.value);
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
