import { NgModule } from '@angular/core';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DynamicInputComponent } from '@ptg-shared/controls/dynamic-input/dynamic-input.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SharedModule } from '../shared.module';
import { CustomRichTextEditorDirective } from '../directive/custom-rich-text-editor.directive';
import { NumberOnlyModule } from '../layout/directive/number-only/number-only.module';
import { DetailDisplayComponent } from './detail-display/detail-display.component';
import { DetailValueContentDirective } from './detail-display/directives/detail-value-content.directive';
import { SpinnerComponent } from './spinner/spinner.component';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { ViewAddressComponent } from './view-address/view-address.component';
import { ViewRichTextDialogComponent } from './view-rich-text/view-rich-text-dialog/view-rich-text-dialog.component';
import { ViewRichTextComponent } from './view-rich-text/view-rich-text.component';
import { AutocompleteComponent, CellContentSearch } from './autocomplete';
import {
  AddressColumnComponent,
  BinaryColumnComponent,
  CellContent,
  CellEdit,
  DatetimeColumnComponent,
  GridComponent,
  EntityReferenceColumnComponent,
  HyperlinkColumnComponent,
  IconColumnComponent,
  NumberDecimalColumnComponent,
  PersonNameColumnComponent,
  PhoneColumnComponent,
  StopPropagation,
} from './grid';
import { EditorComponent } from './editor/editor.component';
import { GridviewComponent } from './gridview/gridview.component';
import { PaginationComponent } from './pagination';
import { ProgressMaskComponent } from './progress-mask/progress-mask.component';
import { ToastComponent } from './toast/toast.component';
import { ButtonComponent } from './button/button.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { InputTextComponent } from './input-text/input-text.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { TitleComponent } from './title/title.component';
import { MessageResultComponent } from './message-result/message-result.component';
import { InputDatetimeComponent } from './input-datetime/input-datetime.component';
import { InputColorComponent } from './input-color/input-color.component';
import { InputAreaComponent } from './input-area/input-area.component';
import { SelectComponent } from './select/select.component';
import { ThreeDotsComponent } from './three-dots/three-dots.component';
import { FormTableComponent } from './form-table/form-table.component';
import { GridviewTransferringComponent } from './gridview_transferring/gridview_transferring.component';
import { TitleButtonComponent } from './title-button/title-button.component';
import { BannerComponent } from './banner/banner.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputCurrencyComponent } from './input-currency/input-currency.component';
import { InputNumberMaskComponent } from './input-number-mask/input-number-mask.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { UploadPopupComponent } from './upload-popup/upload-popup.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TextboxComponent } from './textbox/textbox.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { UploadComponent } from './upload/upload.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DatepickerMonthComponent } from './checkbox/datepicker-month/datepicker-month.component';
import { ListDisplayComponent } from './list-display/list-display.component';
import { MaskedEyeComponent } from './masked-eye/masked-eye.component';
import { GridExpandableRowsComponent } from './grid-expandable-rows/grid-expandable-rows.component';
import { DialogBody, DialogComponent, DialogContentHeader, DialogFooter } from './dialog';
import { PanelBody, PanelComponent, PanelExtraHeader } from './panel';
import { PanelDetailDisplayBoxComponent } from './panel-detail-display-box/panel-detail-display-box.component';
import { CardDescriptionComponent } from './card-description/card-description.component';
import { FieldDisplayComponent } from './card-description/field-display/field-display.component';
import { GranulationOptions } from './granulation-option/granulation-options.component';
import { InputAddressComponent } from './input-address/input-address.component';
import { UploadMultipleComponent } from './upload-multiple/upload-multiple.component';
import { NumberOnlySpecModule } from '@ptg-shared/layout/directive/number-only-spec/number-only-spec.module';

export const COMPONENTS = [
  //#region Autocomplete declarations
  AutocompleteComponent,
  CellContentSearch,
  //#endregion

  //#region Dialog declarations
  DialogComponent,
  DialogBody,
  DialogContentHeader,
  DialogFooter,
  //#endregion

  EditorComponent,
  GridviewComponent,

  //#region Grid declarations
  GridComponent,
  CellContent,
  CellEdit,
  StopPropagation,
  DatetimeColumnComponent,
  NumberDecimalColumnComponent,
  PhoneColumnComponent,
  IconColumnComponent,
  AddressColumnComponent,
  EntityReferenceColumnComponent,
  HyperlinkColumnComponent,
  BinaryColumnComponent,
  PersonNameColumnComponent,
  //#endregion

  //#region Panel declarations
  PanelComponent,
  PanelBody,
  PanelExtraHeader,
  //#endregion

  PaginationComponent,

  ProgressMaskComponent,
  ToastComponent,
  ButtonComponent,
  ConfirmPopupComponent,
  ToggleButtonComponent,
  InputTextComponent,
  TitleBarComponent,
  TitleComponent,
  MessageResultComponent,
  InputDatetimeComponent,
  InputColorComponent,
  InputAreaComponent,
  SelectComponent,
  ThreeDotsComponent,
  FormTableComponent,
  GridExpandableRowsComponent,
  GridviewTransferringComponent,
  TitleButtonComponent,
  BannerComponent,
  InputNumberComponent,
  InputCurrencyComponent,
  InputNumberMaskComponent,
  CheckboxComponent,
  UploadPopupComponent,
  RadioButtonComponent,
  BreadcrumbComponent,
  CheckboxComponent,
  UploadPopupComponent,
  TextboxComponent,
  DatepickerComponent,
  TimepickerComponent,
  UploadComponent,
  DialogHeaderComponent,
  DatepickerMonthComponent,
  ListDisplayComponent,
  MaskedEyeComponent,
  ViewRichTextDialogComponent,
  ViewRichTextComponent,
  DatetimePickerComponent,
  CustomRichTextEditorDirective,
  ProgressSpinnerComponent,
  ViewAddressComponent,
  SpinnerComponent,
  DetailDisplayComponent,
  DetailValueContentDirective,
  PanelDetailDisplayBoxComponent,
  DynamicInputComponent,
  CardDescriptionComponent,
  FieldDisplayComponent,
  GranulationOptions,
  InputAddressComponent,
  UploadMultipleComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    SharedModule,
    FontAwesomeModule,
    DragDropModule,
    TooltipModule,
    TextFieldModule,
    NumberOnlyModule,
    MatTreeModule,
    NumberOnlySpecModule
  ],
  exports: COMPONENTS,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ControlsModule {}
