<div
  class="participant-relationship-container"
  id="participant-relationship-page"
>

  <ptg-breadcrumb
    *ngIf="isEntityView"
    [listBreadcrumbs]="listBreadcrumbs"
    [isDisabledGear]="true"
  >
  </ptg-breadcrumb>
  <div *ngIf="!isEntityView; else entityView">
    <ptg-overview-header
      #overViewHeader
      (changeMemberDetailEvent)="onChangeMemberDetail()"
    ></ptg-overview-header>
  </div>
  <ng-template #entityView>
    <ptg-overview-header-entity></ptg-overview-header-entity>
  </ng-template>
  
  <ptg-progress-spinner *ngIf="isLoading"></ptg-progress-spinner>
  <div [ngClass]="{ 'entity-view': isEntityView  }">
    <ptg-organization-chart
      [participantRelationships]="participantRelationships"
      [screenId]="screenId"
    ></ptg-organization-chart>
  </div>
</div>
