export enum SidebarOpenedStatus {
  Hidden,
  Opened,
  Closed,
}

export const OVERVIEW_URLS = [
  '/member/detail/',
  '/member/summary-view/',
  '/member/detail-view/',
  '/member/system-view/',
  '/member/opt-in',
  '/member/individual-document/',
  '/member/retiree/',
  'member/profile-header-configuration',
  'member/profile-overview-configuration',
  'member/profile-navigation-configuration',
  'member/navigation',
  '/member/retirement-benefit/',
  '/member/benefit-overview/',
  '/member/accident/',
  '/member/calculation/retirement-benefit-calculation-list/',
  '/member/calculation/retirement-benefit-calculation/',
  '/member/calculation/retirement-benefit-input-outputs/',
  '/member/calculation/step-configuration/',
  '/member/calculation/calculation-parameter-configuration/',
  '/member/calculation/benefit-calculation-configuration/',
  '/member/calculation/exception-configuration/',
  '/member/calculation/sub-header-configuration/',
  '/member/disability-overview/',
  '/member/buyback-makeup-overview/',
  '/member/refunds-overview/',
  '/member/distribution-code-mapping/'
];

export enum DisplayedTabName {
  Employers = 'employers',
  Admin = 'admin',
  Participants = 'participants',
  IndividualParticipant = 'individualParticipant',
  SearchedParticipant = 'searchedParticipant',
  Processing = 'processing',
  AppContent = 'app',
}

export enum HiddenTabName {
  Hidden = '',
  ParticipantOverviewGroup = 'participantOverviewGroup',
}

export const TAB_URLS: Record<DisplayedTabName, string[]> = {
  employers: ['employer/'],
  participants: ['/fund-management/notification', '/member', '/estimator', '/entity-management'],
  admin: [
    'access-management',
    'organization/',
    '/fund-management/resource-center',
    '/fund-management/theme',
    'admin/',
  ],
  processing: ['processing'],
  individualParticipant: OVERVIEW_URLS,
  searchedParticipant: OVERVIEW_URLS,
  app: [
    'fund-management/navigation',
    'fund-management/preferences',
    '/page/mixed-bulk-add',
    'page',
    'fund-management/app-content',
  ],
};

export const DEFAULT_TAB_URL: Record<DisplayedTabName, string> = {
  employers: '/employer/employer-list',
  participants: '/member',
  admin: '/access-management/roles',
  processing: '/processing',
  individualParticipant: '',
  searchedParticipant: '',
  app: '/fund-management/navigation',
};

export const TAB_PERMISSION: Record<DisplayedTabName, string> = {
  employers: 'EMPLOYERS',
  participants: 'PARTICIPANT',
  admin: 'ADMIN',
  processing: '',
  individualParticipant: 'PARTICIPANT',
  searchedParticipant: 'PARTICIPANT',
  app: 'APP_CONTENT',
};

export enum ItemMenuType {
  NextPayment,
  PaymentHistory,
  MunicipalityServiceRecord,
}

export const STRING_QUERY_PARAM = {
  SEARCH: 'search=true',
  PROFILE_NAVIGATE: 'profileNavigate=true'
};

export enum NAVIGATION_CONST {
  memberNavigation = 'member/navigation/',
  employerParametersNavigation = 'employer/parameters/',
}

export const MUNICIPALITY_PARTICIPANTS_MENU_TITLE = 'Participants';

export const IS_SHOW_SMS_KEY = 'isShowSms';

export const IGNOTE_URL = [
  '/entity-management/entities',
  '/fund-list',
  '/employer/employer-list',
];

export const IGNOTE_URL_SEARCH = [
  '/entity-management/entities',
  '/member',
  '/fund-list',
  '/employer/employer-list',
];

export const SHOULD_NOT_REDIRECT_TO_EMPLOYER_TAB_URL_LIST = [
  '/employer/individual-document/',
];
