import { createAction, props } from '@ngrx/store';
import { SearchResult } from '../models/search.models';

export const SearchRequest = '[Search/API] Send Request';
export const SearchRequestSuccess = '[Search/API] Success';
export const SearchRequestFailure = '[Search/API] Failure';
export const ClearSearchList = '[Search/API] Clear';

export const getSearchRequest = createAction(
	SearchRequest,
	props<{ entityId?:string, searchValue?: string, searchType?: number, idClient?: string, searchMemberType?: number, benefitTypeId?: any, screenId?: string, employerId?: string }>()
);
export const getSearchRequestSuccess = createAction(
	SearchRequestSuccess,
	props<{ searchResult: SearchResult }>()
);
export const getSearchRequestFailure = createAction(
	SearchRequestFailure,
	props<{ errorMsg: string }>()
);

export const clearSearchList = createAction(ClearSearchList);
