import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as EmployerNavigationActions from 'src/app/employer/actions/employer-navigation';
import { EmployerNavigationState } from 'src/app/employer/models/employer-detail.model';
import {
  GetListEmployerQuery,
  SectionType,
} from 'src/app/employer/models/employer.model';
import * as fromEmployer from 'src/app/employer/reducers';
import { STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { LayoutActions } from '../../../shared/layout/actions';
import * as fromLayoutReducer from '../../../shared/layout/reducers';
import * as fromReducer from 'src/app/reducers';
import { AddEmployerContactComponent } from '../add-employer-contact/add-employer-contact.component';
import { MatDialog } from '@angular/material/dialog';
import { EmployerSection } from '../../models/employer.model';
import { EMPLOYER_FIXED_PROPERTIES } from '../../constance/employer.const';
import { BaseComponent } from '@ptg-shared/components';
import { AddEmployerPortalUserComponent } from '../add-employer-portal-user/add-employer-portal-user.component';
import { LayoutService } from '@ptg-shared/services/layout.service';

@Component({
  selector: 'ptg-employer-overview-header',
  templateUrl: './employer-overview-header.component.html',
  styleUrls: ['./employer-overview-header.component.scss'],
})
export class EmployerOverviewHeaderComponent extends BaseComponent implements OnChanges {
  @Output() editSectionEvent = new EventEmitter();
  @Output() addNoteEvent = new EventEmitter();
  @Output() uploadDocumentEvent = new EventEmitter();
  @Output() lockAccountEvent = new EventEmitter();
  @Output() changeEmployerDetailEvent = new EventEmitter();

  @Input() data: any = {};
  @Input() detail: any = {};
  @Input() lstSection: any = [];
  @Input() employerNavigationState!: EmployerNavigationState;
  @Input() employerInformation!: EmployerSection;
  @Input() isShowActionList: any = false;
  @Input() isShowCloseButton: boolean = true;
  @Input() employerSections: any;
  @Input() employerName!: string;
  @Input() targetIdFromDialog?: string;
  @Input() isShowChangeEmployerDetailBtn?: boolean = true;

  unsubscribe$ = new Subject<void>();
  navigationDisabled: {
    previous: boolean;
    next: boolean;
  } = {
      previous: true,
      next: true,
    };
  fundId!: string;
  employerId: string = '';
  selectUrlBeforeSearch: string = '';
  employerInformationHeader: any = {};

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private store: Store<fromLayoutReducer.State>,
    private employerStore: Store<fromEmployer.EmployerState>,
    private dialog: MatDialog,
    private layoutService: LayoutService
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employerName) {
      this.employerInformationHeader.value = changes.employerName.currentValue;
    }
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.employerId = params.id || params.employerId || this.targetIdFromDialog;
    });

    const label = `${this.layoutService.employerLabel} Name`;
    this.employerInformationHeader = { label, value: this.employerName };

    combineLatest([
      this.employerStore.select(
        fromEmployer.selectEmployerInfoPropertiesState
      ),
      this.employerStore.select(fromEmployer.selectEmployerOverviewState),
    ]).subscribe((data) => {
      const employerProperties = data[0];
      const employerOverview = data[1];
      if (employerOverview?.sections) {
        const sections = employerOverview.sections;
        let employerInformationSection = sections
          .map((section, index) => {
            return {
              ...section,
              order: index * 2 + 1,
              properties: employerProperties,
            };
          })
          .find(
            (section) => section.type === SectionType.EmployerInformation
          );
        if (employerInformationSection) {
          this.employerInformation = employerInformationSection;
        }
      }
    });

    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => {
        this.fundId = el.id;
      });

    this.employerStore
      .pipe(
        select(fromEmployer.selectEmployerNavigationState),
        debounceTime(300),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((employerNavigationState) => {
        this.employerNavigationState = employerNavigationState;
        this.checkEmployerIndex();
        this.checkNavigateStatus();
      });
  }

  checkEmployerIndex() {
    if (!this.employerNavigationState || !this.employerId) {
      return;
    }
    const currentIndex = this.employerNavigationState.employerIndex;
    const employerIndex = this.employerNavigationState.employers.findIndex(
      (employer) => this.employerId === employer.id
    );
    if (currentIndex === employerIndex) {
      return;
    }
    this.employerStore.dispatch(
      EmployerNavigationActions.setEmployerIndex({ employerIndex })
    );
    this.checkCurrentPage(
      employerIndex,
      this.employerNavigationState.query as GetListEmployerQuery
    );
  }

  checkNavigateStatus() {
    if (!this.employerNavigationState?.employers?.length) {
      this.navigationDisabled = { previous: true, next: true };
      return;
    }
    const previous = this.employerNavigationState?.employerIndex <= 0;
    const next =
      this.employerNavigationState?.employerIndex === -1 ||
      this.employerNavigationState?.employerIndex >=
      this.employerNavigationState?.employers?.length - 1;
    this.navigationDisabled = { previous, next };
  }

  close() {
    this.router.navigateByUrl('/employer');
  }

  changeEmployerDetail(isNext: boolean = false) {
    this.changeEmployerDetailEvent.emit();
    const employerIndex =
      this.employerNavigationState.employerIndex + (isNext ? +1 : -1);
    const query = this.employerNavigationState.query as GetListEmployerQuery;
    this.checkCurrentPage(employerIndex, query);
    this.employerStore.dispatch(
      EmployerNavigationActions.setEmployerIndex({ employerIndex })
    );
    const id = this.employerNavigationState.employers[employerIndex]
      ?.id as string;
    const path = this.router.url.substr(0, this.router.url.lastIndexOf('/'));
    if (id) {
      const url = document.location.search.includes(STRING_QUERY_PARAM.SEARCH)
        ? `${path}/${id}?${STRING_QUERY_PARAM.SEARCH}`
        : `${path}/${id}`;
      this.router.navigateByUrl(url);
      this.store.dispatch(
        LayoutActions.employerNavigationItemRequest({ employerId: id })
      );
    }
  }

  addNewPortalUser() {
    this.dialog.open(AddEmployerPortalUserComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        portalUser: null,
        configSection: this.employerSections?.find((employerSection: { type: SectionType; }) => employerSection.type === SectionType.PortalUsers),
        employerId: this.employerId,
      },
    });
  }

  addNewContact() {
    this.dialog.open(AddEmployerContactComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        contact: null,
        configSection: this.employerSections?.find((employerSection: { type: SectionType; }) => employerSection.type === SectionType.Contacts),
        employerId: this.employerId,
      },
    });
  }

  checkCurrentPage(
    employerIndex: number,
    employerListQuery: GetListEmployerQuery
  ) {
    const query = JSON.parse(JSON.stringify(employerListQuery));
    let isAppend = false;
    if (employerIndex === 0 && this.employerNavigationState?.startPage > 1) {
      query.pageIndex -= 1;
    }
    if (
      employerIndex === this.employerNavigationState?.employers?.length - 1 &&
      this.employerNavigationState.endPage <
      this.employerNavigationState.totalPage
    ) {
      query.pageIndex += 1;
      isAppend = true;
    }
    if (query.pageIndex !== employerListQuery.pageIndex) {
      this.employerStore.dispatch(
        EmployerNavigationActions.getMoreEmployers({
          query,
          idClient: this.fundId,
          isAppend,
        })
      );
    }
  }

  onUploadDocument() {
    this.uploadDocumentEvent.emit();
  }
}
