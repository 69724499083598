<ng-container
  *ngIf="
    !dataCardList?.cardNoData ||
    (dataCardList?.cardNoData &&
      dataCardNoneList?.cardNoData &&
      !configDataless?.isHideEmptyCard &&
      configDataless?.listDisplayPreferenceType !== LIST_DISPLAY_PREFERENCE_TYPE.Hide) ||
    (!dataCardNoneList?.cardNoData && configDataless?.nonRecordListDisplayType !== NON_RECORD_LIST_DISPLAY_TYPE.Hide)
  "
>
  <ptg-grid
    class="update-header"
    [data]="data"
    [columns]="columns"
    [totalRecords]="lengthPg"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    [hideScrollbar]="false"
    [isLoading]="isLoading"
    [notFoundMessage]="
      configDataless?.listDisplayPreferenceType === LIST_DISPLAY_PREFERENCE_TYPE.DisplayColumnHeadersAndMessage ||
      (!dataCardNoneList?.cardNoData &&
        configDataless?.nonRecordListDisplayType === NON_RECORD_LIST_DISPLAY_TYPE.DisplayColumnHeadersAndMessage)
        ? 'No Data to Display'
        : ''
    "
    [isMuniPortalBenefic]="isMuniPortalBenefic"
    (pageChange)="onChangePage($event)"
    (sortChange)="onSortChange($event)"
    (rowClick)="onRowClick($event)"
  >
  <ng-template cellContent columnName="noOfAttachments" let-row>
    <a
      (click)="viewAttachment(row)"
      href="javascript:void(0)"
      class="link"
      >{{ row.noOfAttachments }}</a
    >
  </ng-template>
  
</ptg-grid>

</ng-container>
