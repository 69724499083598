import { createAction, props } from "@ngrx/store";
import { ToleranceParameterDto } from "../models/tolerance-parameter.model";

export const ToleranceParameterRequest = '[ToleranceParameter/API] Send Request';
export const ToleranceParameterRequestSuccess = '[ToleranceParameter/API] Success';
export const ToleranceParameterRequestFailure = '[ToleranceParameter/API] Failure';

export const getToleranceParameterRequest = createAction(
    ToleranceParameterRequest,
);
export const getToleranceParameterRequestSuccess = createAction(
    ToleranceParameterRequestSuccess,
    props<{ tolerance: ToleranceParameterDto }>()
);
export const getToleranceParameterRequestFailure = createAction(
    ToleranceParameterRequestFailure,
    props<{ error?: any }>()
);

