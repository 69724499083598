import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  ViewCreditConfirmationDialogComponent
} from '@ptg-member/components/view-credit-confirmation-dialog/view-credit-confirmation-dialog.component';
import { MunicipalityPaymentService } from '@ptg-member/services/municipality-payment.service';
import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { ACTION, STATE } from '@ptg-shared/constance';
import { DataType } from '@ptg-shared/constance/data-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { PaginationComponent } from '@ptg-shared/controls/pagination/pagination.component';
import { Option } from '@ptg-shared/controls/select/select.component';

import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { DetailDisplay } from '@ptg-shared/types/models/detail-display.model';
import { deepEqual, downloadFile, showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter, getConcatString } from '@ptg-shared/utils/string.util';
import { DateTime } from 'luxon';
import { of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import * as MunicipalityPaymentActions from '../../store/actions/municipality-payment.action';
import { OverviewHeaderComponent } from '../../components/overview-header/overview-header.component';
import * as fromMember from '../../store/reducers';
import * as ProfileHeaderConfigurationActions from '../../store/actions/profile-header-configuration.actions';
import { EditMetRequirementResponse, GetListStatusHistoryQuery, History, MemberNavigationResponse } from '../../types/models';
import { NumberLocalDecimalPipe } from '@ptg-shared/pipes/number-local-decimal.pipe';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { CheckPermissionService } from '@ptg-shared/services/check-permission.service';
import { EmployerSubmoduleKey } from '@ptg-shared/constance/employer-portal-permission.const';

@Component({
  selector: 'ptg-municipality-payment',
  templateUrl: './municipality-payment.component.html',
  styleUrls: ['./municipality-payment.component.scss'],
})
export class MunicipalityPaymentComponent extends BaseComponent {
  @Input() currentPageName?: string;
  isEntityView: boolean = false;
  viewId: string = '';
  memberName: string = '';
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Payments',
    },
  ];
  columns: Column[] = [
    {
      name: 'year',
      header: {
        title: 'Year',
      },
      sortable: true,
    },
    {
      name: 'municipalityName',
      header: {
        title: 'Municipality Name (ID)',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'disabilityFees',
      header: {
        title: 'Disability Fees',
      },
      type: ColumnType.Decimal,
      sortable: true,
      templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
      align: Align.Right,
    },
    {
      name: 'muniPaidPension',
      header: {
        title: 'Municipality Paid Pension',
      },
      type: ColumnType.Decimal,
      sortable: true,
      templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
      align: Align.Right,
    },
    {
      name: 'memberPaidPension',
      header: {
        title: 'Member Paid Pension',
      },
      type: ColumnType.Decimal,
      sortable: true,
      templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
      align: Align.Right,
    },
    {
      name: 'sumOfPensionPayments',
      header: {
        title: 'Sum Of Pension Payments',
      },
      type: ColumnType.Decimal,
      sortable: true,
      templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
      align: Align.Right,
    },
    {
      name: 'metRequirement',
      header: {
        title: 'Met Requirements',
      },
      sortable: true,
    },
    {
      name: 'lastEvent',
      header: {
        title: 'Last Event',
      },
      sortable: true,
      truncate: true,
    },
  ];
  currentFund: any = {};
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isLoading = false;
  dataTable: History[] = [];
  listArregations: any[] = [];
  memberId: string = '';
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  paginator?: PaginationComponent;
  propertyDisplayed: DetailDisplay[] = [
    { label: 'Year', propertyName: 'year', type: DataType.TYPE_NUMBER },
    {
      label: 'Disability Fees',
      propertyName: 'disabilityFees',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Member Paid Pension',
      propertyName: 'memberPaidPension',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Met Requirements',
      propertyName: 'metRequirement',
      type: DataType.TYPE_LIST,
    },
    {
      label: 'Municipality',
      propertyName: 'municipalityName',
      type: DataType.TYPE_TEXT,
    },
    {
      label: 'Payment Date',
      propertyName: 'paymentDate',
      type: DataType.TYPE_DATE,
    },
    {
      label: 'Municipality Paid Pension',
      propertyName: 'muniPaidPension',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Sum Of Pension Payments',
      propertyName: 'sumOfPensionPayments',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Last Event',
      propertyName: 'lastEvent',
      type: DataType.TYPE_TEXT,
    },
  ];
  isGenerateReport = false;
  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;
  metRequirementControl: FormControl = new FormControl(null);
  metRequirementValues: Option[] = [
    {
      displayValue: 'Yes',
      value: true,
    }, {
      displayValue: 'No',
      value: false,
    },
  ];

  menuName: string = '';
  menuItems?: any;
  entityId: string = '';
  isMuniPortal: boolean = false;

  isViewPaymentScreen: boolean = false;
  isShowEditMetRequirementBtnByPermission: boolean = false;

  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    public municipalityPaymentService: MunicipalityPaymentService,
    public authService: Auth0Service,
    private checkPermissionService: CheckPermissionService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isMuniPortal = this.authService.isMunicipalityPortal$.value;

    this.isViewPaymentScreen = !!this.checkPermissionService.checkPermission(EmployerSubmoduleKey.ViewPayment)
      && !!this.checkPermissionService.checkPermission(EmployerSubmoduleKey.PaymentsCard);
    this.isShowEditMetRequirementBtnByPermission = !!this.checkPermissionService.checkPermission(EmployerSubmoduleKey.EditPayment);
    if (this.isViewPaymentScreen === false) {
      return;
    }

    this.checkEditMetRequirementState();
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.isEntityView = this.route.snapshot.url.some(path => path.path === 'system-view');
      this.viewId = params.viewId;
      this.memberId = params.id;
      this.getData();
    });
    this.getMenuItemName();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.currentFund = el;
        this.pageSize = el.defaultPageSize ?? 50;
      });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + '-ptg-members-list-pageSize')) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + '-ptg-members-list-pageSize'));

    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityPaymentState),
        filter(municipalityPayment => !!municipalityPayment),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.lengthPg = state?.payments?.total;
        this.isLoading = state?.isLoading;
        this.dataTable = state?.payments?.payments.map(
          (item, index: number) => {
            return {
              ...item,
              index: index,
              metRequirementValue: (item.metRequirement as any),
              metRequirement: this.getMetRequirementLabel(item.metRequirement),
            };
          }
        );
        this.metRequirementControl.setValue(this.dataTable[this.currentRowIndex]?.metRequirementValue);
        this.listArregations = [
          {
            items: [
              {
                name: 'Total Service',
                value: state?.payments?.totalService,
              },
            ],
          },
        ];
      });
      this.getMemberInfoState();
  }

  getMenuItemName() {
    this.store.select(fromLayoutReducer.selectProfileNavigationState)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(menuItem => {
      if (!menuItem.menu.length) {
        return;
      }
      this.entityId = (menuItem?.memberNavigationList as any).entityId;
        const memberNavigationMenu: MemberNavigationResponse[] =
        menuItem.memberNavigationList?.memberNavigationMenu;
        this.menuItems = memberNavigationMenu
          ?.map((item) => {
            return item.menuItems;
          })
          ?.reduce((a, b) => {
            return a.concat(b);
          });

        this.menuName = this.menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name;
        this.listBreadcrumbs = [
          {
            name: this.menuName || '',
            url: '',
          },
        ];
    });
  }


  getData() {
    let sortType = 1;
    let sortNames = ['Year'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListStatusHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      MunicipalityPaymentActions.getMunicipalityPaymentList({ query })
    );
  }

  reloadOverviewHeader() {
    this.overViewHeader.memberId = this.memberId;
    this.overViewHeader.getMemberProfileData();
    this.overViewHeader.checkMemberIndex();
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + '-ptg-members-list-pageNumber', this.pageNumber.toString());
    sessionStorage.setItem(this.currentFund.key + '-ptg-members-list-pageSize', this.pageSize.toString());
    this.getData();
  }

  selectRow(event: any) {
    this.currentRowIndex = this.dataTable.findIndex(
      (item) => item.id === event.id
    );
    this.metRequirementControl.setValue(this.dataTable[this.currentRowIndex].metRequirementValue);
  }

  onClickGenerateRecord() {
    this.isGenerateReport = true;
    const date = DateTime.now().toFormat('yyyy-MM-dd');
    this.municipalityPaymentService.generateServiceRecordReport(this.memberId, date)
    .pipe(
      catchError(() => {
        this.isGenerateReport = false;
        return of(null);
      }),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((data: any) => {
      if (data) {
        const fileName = `${ this.memberName.replace(/[^a-zA-Z0-9 ]/g, '') }_ServiceRecordReport_${ DateTime.now().toFormat('yyyyMMddhhmmss') }`;
        downloadFile.call(this, data, fileName);
      }
      this.isGenerateReport = false;
    });
  }

  getMemberInfoState() {
    if (this.memberId) {
      this.memberStore.dispatch(
        ProfileHeaderConfigurationActions.getMemberDetailAction({
          memberId: this.memberId
        })
      );
    }
    this.store.pipe(
      select(fromMember.selectMemberDetail),
      takeUntil(this.unsubscribe$)
    ).subscribe(memberInfo => {
      this.memberName = memberInfo
        ? getConcatString([memberInfo?.payload?.firstName, memberInfo?.payload?.middleName, memberInfo?.payload?.lastName], '')
        : '';
    });
  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }

  getMetRequirementLabel(metRequirement: string | boolean | undefined) {
    if (metRequirement === null) {
      return '';
    }
    if (metRequirement) {
      return 'Yes';
    }
    return 'No';
  }

  checkEditMetRequirementState() {
    this.memberStore
      .select(fromMember.selectEditMetRequirementState)
      .pipe(
        distinctUntilChanged((previous, current) => deepEqual(previous, current)),
        filter(({ editMetRequirementState }) => !!editMetRequirementState),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(({ editMetRequirementState, editMetRequirementResponse }) => {
        showBanner.call(this,editMetRequirementState || '', 'Met Requirement', ACTION.EDIT);
        if (editMetRequirementState === STATE.SUCCESS) {
          this.getData();
        }
        this.showConfirmDialog(editMetRequirementResponse);
        this.memberStore.dispatch(MunicipalityPaymentActions.clearMunicipalityPaymentState());
      });
  }

  showConfirmDialog(editMetRequirementResponse?: EditMetRequirementResponse) {
    if (!editMetRequirementResponse?.isShowConfirmPopup) {
      return;
    }
    let messages = [
      `${ editMetRequirementResponse.name } did not meet requirements${ editMetRequirementResponse.credit ? '' : ' and no credits will be refunded' }.`
    ];
    if (editMetRequirementResponse.credit !== 0) {
      const decimalPipe = new NumberLocalDecimalPipe();
      messages.push(`${ decimalPipe.transform(editMetRequirementResponse.credit, '$') } will be credited back to Municipality.`);
    }
    const dialogRef = this.dialog.open(ViewCreditConfirmationDialogComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      width: '800px',
      data: {
        messages,
        cashJournalEntry: editMetRequirementResponse.nextCashJournalEntry,
        hideCashJournal: !editMetRequirementResponse.credit,
        saveButtonLabel: !editMetRequirementResponse.credit ? 'Confirm' : 'Save',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result?.cashJournalEntry) {
        return;
      }
      const customBody = {
        isCreateTransaction: !!editMetRequirementResponse.credit,
        cashJournalEntry: result?.cashJournalEntry,
        metRequirement: false,
        validateToShowPopup: false,
      }
      this.updateMetRequirement(customBody);
    });
  }

  updateMetRequirement(customBody?: any) {
    const currentPayment = this.dataTable[this.currentRowIndex];
    let body = {
      municipalityId: currentPayment.municipalityId,
      year: currentPayment.year,
      memberId: this.memberId,
      metRequirement: !!currentPayment.metRequirementValue,
      validateToShowPopup: !currentPayment.metRequirementValue,
      isCreateTransaction: false,
    }
    if (customBody) {
      body = {
        ...body,
        ...customBody,
      }
    }
    this.memberStore.dispatch(MunicipalityPaymentActions.editMunicipalityPaymentMetRequirement({ body }));
  }

  onEditMetRequirement(isSubmit: boolean = false) {
    this.dataTable[this.currentRowIndex].isEditingMetRequirement = false;
    if (isSubmit && this.metRequirementControl.value != null) {
      this.dataTable[this.currentRowIndex].metRequirementValue = this.metRequirementControl.value;
      this.dataTable[this.currentRowIndex].metRequirement = this.getMetRequirementLabel(this.metRequirementControl.value);
      this.updateMetRequirement();
    } else {
      this.metRequirementControl.setValue(this.dataTable[this.currentRowIndex].metRequirementValue);
    }
  }
}
