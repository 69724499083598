<div class="ptg-sidebar"
     [ngClass]="{'sidebar-opened': sidebarOpenedStatus === SIDE_BAR_OPENED_STATUS_ENUM.Opened, 'disabled' : isDisabled}"
     *ngIf="sidebarOpenedStatus !== SIDE_BAR_OPENED_STATUS_ENUM.Hidden"
>
  <div class="control-bar">
    <ng-container *ngFor="let menuGroup of currentMenuGroups; let index = index">
      <ng-container *ptgCheckPermission="menuGroup?.permissionKey ?? ''">
        <div [ngClass]="{ selected: menuGroup?.isSelected }" class="item-bar" *ngIf="!menuGroup?.isHidden">
          <button mat-icon-button (click)="selectMenuGroup(index)">
            <mat-icon *ngIf="!menuGroup.isSVG; else svgIconTemplate">{{ menuGroup.icon }}</mat-icon>
            <ng-template #svgIconTemplate>
              <mat-icon [svgIcon]="menuGroup.name" aria-hidden="false"></mat-icon>
            </ng-template>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <mat-drawer-container class="toggle-container">
    <mat-drawer #drawer class="toggle-content" mode="side"
                [opened]="sidebarOpenedStatus === SIDE_BAR_OPENED_STATUS_ENUM.Opened" disableClose>

      <div class="menu-list" *ngIf="currentMenuGroups?.[currentMenuGroupIndex]?.menu?.length">
        <div class="top-menu">
          <ng-container *ngFor="let menu of currentMenuGroups[currentMenuGroupIndex].menu">
            <h3 class="flex items-center sub-title" [ngClass]="{'no-title': !menu?.title, 'single-view': menu?.isSingleView,
                 'non-single-view': !menu?.isSingleView && !menu?.menuItems?.length}" [style]="menu?.style">
              <mat-icon *ngIf="menu?.icon" class="menu-icon">{{ menu.icon }}</mat-icon>
              {{ menu.title }}
            </h3>
            <ng-container *ngFor="let menuItem of menu?.menuItems">
              <ng-container *ngIf="menuItem?.url; else internalLink">
                <!-- External Link -->
                <a [href]="menuItem.url" target="_blank" rel="noopener noreferrer">
                  {{ menuItem.name }}
                </a>
              </ng-container>
              <ng-template #internalLink>
                <!-- Internal Link -->
              <ng-container *ptgCheckPermission="menuItem.permissionKey ?? ''">
                <ng-template ptgCheckVersionFlag [ptgCheckVersionFlag]="menuItem.versionFlag || ''">
                  <a (click)="onClickMenuItem(menuItem)"
                     [id]="menuItem?.name"
                     [ngClass]="{ 'selected-screen': router.url.split('?')[0] === (menuItem.routerLink || '').split('?')[0]
                                || (menuItem.routerLinkExpand && checkSelectedMenuLinkExpand(menuItem.routerLinkExpand))
                                || (menuItem.type !== undefined && (router.url.includes(menuItem.routerLink))
                                || (router.url.includes(menuItem.routerLink) && menuItem.routerLink.includes(NAVIGATION_CONST.employerParametersNavigation))
                                || (router.url.includes(menuItem.routerLink) && menuItem.routerLink.includes(NAVIGATION_CONST.memberNavigation)
                                )
                                )}"
                     [routerLink]="menuItem?.routerLink"
                     [queryParams]="menuItem?.queryParams"
                     class="sub-item"
                     routerLinkActive="active">
                    {{ menuItem?.name }}
                  </a>
                </ng-template>
              </ng-container>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>

        <p class="release-version" [class.disable-version]="authService.Role === ADMIN_FUND" (click)="onViewVersionDetail()">Release Version: {{ dataVersion?.release_version }}</p>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>
