import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialUIModule } from './material-ui.module';
import { NgxUIModule } from './ngx-ui.module';
import { SearchBoldDirective } from './layout/directive/search-bold.directive';
import { PipeModule } from './pipes/pipe.module';
import { TruncateTextDirective } from './layout/directive/truncate-text/truncate-text.directive';
import { CheckVersionFlagDirective } from '@ptg-shared/directive/check-version-flag.directive';
import { InputZipCodeDirective } from './layout/directive/input-zip-code.directive';

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialUIModule,
  NgxUIModule,
  PipeModule,
];
const COMPONENTS: any = [];
const DIRECTIVES = [SearchBoldDirective, TruncateTextDirective, CheckVersionFlagDirective, InputZipCodeDirective];

@NgModule({
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  imports: [...MODULES],
})
export class SharedModule {}
