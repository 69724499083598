import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, startWith, takeUntil, filter, tap } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { Option } from '@ptg-shared/controls/select/select.component';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { getConcatString, getDateFormatISO } from '@ptg-shared/utils/string.util';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import * as ProfileHeaderConfigurationActions from '../../store/actions/profile-header-configuration.actions';
import * as fromMember from '../../store/reducers';
import { MunicipalityServiceHistoryService } from '../../services/municipality-service-history.service';
import {
  CreateValidateServiceHistoryRecordBeginDateTransactionRequest,
  EditMunicipalityServiceHistoryRequest,
  History,
  MetadataSection,
  Municipality,
  ValidateServiceHistoryRecordBeginDateRequest,
  ValidateServiceHistoryRecordBeginDateResponse,
} from '../../types/models';
import * as MunicipalityServiceHistoryAction from '../../store/actions/municipality-service-history.action';
import * as fromReducer from '@ptg-reducers';

@Component({
  selector: 'ptg-edit-municipality-service-history',
  templateUrl: './edit-municipality-service-history.component.html',
  styleUrls: ['./edit-municipality-service-history.component.scss'],
})
export class EditMemberServiceHistoryComponent extends BaseComponent {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  listOptionMunicipality: Option[] = [];
  dateOfDeath: DateTime | null = null;
  isLoading: boolean = true;
  isEditForm: boolean = false;
  municipality = new FormControl('');
  filteredOptions!: Observable<Option[]>;
  oldBeginDate: string = this.data.serviceHistory?.beginDate ? getDateFormatISO(this.data.serviceHistory?.beginDate as string) : '';
  memberName: string = '';
  muniName: string = '';
  validateServiceHistoryRecordBeginDateResponse: ValidateServiceHistoryRecordBeginDateResponse | null = null;
  isFormLoading: boolean = false;
  municipalityName: string = this.data?.serviceHistory?.municipalityName ?? '';
  memberId = this.data.memberId;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberId: string;
      viewName: string;
      serviceHistory: History;
      dateOfDeath?: string;
      isRedirectFromOverview?: boolean;
      sectionData?: MetadataSection;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditMemberServiceHistoryComponent>,
    private serviceHistoryService: MunicipalityServiceHistoryService,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
  ) {
    super();
  }

  get beginDate(): FormControl {
    return this.editForm.get('beginDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.editForm.get('endDate') as FormControl;
  }

  ngOnInit(): void {
    this.dateOfDeath = this.data.dateOfDeath ? DateTime.fromISO(this.data.dateOfDeath) : null;

    this.getMemberInfoState();
    this.registerValidateServiceHistoryRecordBeginDateSelector();
    this.registerCreateValidateServiceHistoryRecordBeginDateTransactionSelector();
    this.registerGetMemberParticipantNameSelector();

    this.memberStore.dispatch(
      MunicipalityServiceHistoryAction.getMunicipalityList()
    );
    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityNameListState),
        filter(res => !!res),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((listMunicipality) => {
        this.memberStore.dispatch(MunicipalityServiceHistoryAction.clearMunicipalityListState());
        if (!listMunicipality) {
          return;
        }

        this.setListOption(listMunicipality);
        this.initFormGroup(this.data.serviceHistory);
        this.filteredOptions = this.municipality.valueChanges.pipe(
          startWith(''),
          map((value) => {
            const muniName =
              typeof value === 'string' ? value : value?.displayValue;
            return muniName
              ? this._filter(muniName as string)
              : this.listOptionMunicipality.slice();
          })
        );
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityLoadingState),
        tap((isLoading) => (this.isLoading = isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((isLoading) => {
        if (!isLoading) {
          this.editForm.markAllAsTouched();
          this.onChangeBeginValue();
          this.onChangeEndValue();
        }
      });
  }

  ngOnDestroy(): void {
      super.ngOnDestroy();
      this.memberStore.dispatch(MunicipalityServiceHistoryAction.clearMunicipalityListState());
  }

  initFormGroup(formData: History) {
    const serviceHistoryBeginDate = this.data.serviceHistory?.beginDate;
    const serviceHistoryEndDate = this.data.serviceHistory?.endDate;

    const initBeginDate = serviceHistoryBeginDate ? DateTime.fromISO(serviceHistoryBeginDate) : DateTime.now();
    const initEndDate = serviceHistoryEndDate ? DateTime.fromISO(serviceHistoryEndDate) : null;

    this.municipality.setValue({
      value: formData?.municipalityId,
      displayValue: formData?.municipalityName + ' ' + formData?.municipalityId,
      valueDescription: formData?.municipalityName,
    });

    this.editForm = this.fb.group({
      id: this.fb.control(formData?.id),
      beginDate: this.fb.control(initBeginDate, [Validators.required, this.beginDateCustomValidator()]),
      endDate: this.fb.control(initEndDate, [this.endDateCustomValidator()]),
      municipality: this.fb.control(this.municipality.value),
    });
  }

  onSubmit() {
    this.municipality.setErrors(null);
    if (
      (this.municipality.value.value === undefined &&
        typeof this.municipality.value !== 'string') ||
      this.municipality.value === ''
    ) {
      this.municipality.setErrors({ required: true });
      return;
    } else if (
        this.municipality.enabled &&
        typeof this.municipality.value === 'string'
      ) {
        this.municipality.setErrors({ inValidAsync: true });
        return;
      }
    let formData = this.editForm.value;
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }
    let bodyCheck = {
      beginDate: getDateFormatISO(new Date(formData.beginDate).toString()),
      endDate: formData.endDate
        ? getDateFormatISO(new Date(formData.endDate).toString())
        : null,
      municipalityId: this.municipality.value.value,
      memberId: this.data.memberId,
      serviceId: formData.id,
    };
    this.serviceHistoryService
      .checkExits(bodyCheck)
      .pipe(
        catchError((err) => {
          return of(
            MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryFailure(err)
          );
        })
      )
      .subscribe((el: any) => {
        if (el?.exists) {
          this.endDate.setErrors({ inValidAsync: true });
          return;
        }
        if (this.editForm.pending) {
          let sub = this.editForm.statusChanges.subscribe(() => {
            if (this.editForm.valid) {
              if (this.oldBeginDate && this.oldBeginDate !== getDateFormatISO(new Date(formData.beginDate).toString())) {
                this.muniName = this.municipality.value?.valueDescription ?? '';
                setTimeout(() => this.isFormLoading = true, 0);
                this.validateServiceHistoryRecordBeginDate(formData);
              } else {
                this.saveData(this.editForm.value);
              }
            }
            sub.unsubscribe();
          });
        } else if (this.editForm.valid) {
          if (this.oldBeginDate) {
            this.muniName = this.municipality.value?.valueDescription ?? '';
            setTimeout(() => this.isFormLoading = true, 0);
            this.validateServiceHistoryRecordBeginDate(formData);
          } else {
            this.saveData(this.editForm.value);
          }
        }
      });
  }

  saveData(formData: any) {
    const body: EditMunicipalityServiceHistoryRequest = {
      id: formData.id,
      memberId: this.data.memberId,
      beginDate: getDateFormatISO(new Date(formData.beginDate).toString()),
      endDate: formData.endDate
        ? getDateFormatISO(new Date(formData.endDate).toString())
        : undefined,
      municipalityId: this.municipality.value.value,
    };
    formData.id
      ? this.memberStore.dispatch(
          MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryRequest(
            { body }
          )
        )
      : this.memberStore.dispatch(
          MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistoryRequest(
            { body }
          )
        );

    this.dialogRef.close(body);
  }

  setListOption(listData: Municipality[]) {
    let listDataDisplay = this.isEditForm
      ? listData
      : listData.filter((x) => x.disabled === false);
    this.listOptionMunicipality = listDataDisplay.map((item) => {
      return {
        value: item.value,
        displayValue: `${item.description} (${item.value})`,
        valueDescription: item.description
      } as Option;
    });
  }

  onChangeBeginValue(): void {
    this.beginDate.valueChanges.subscribe(_ => {
      this.endDate.updateValueAndValidity({ emitEvent: false });
    });
  }

  onChangeEndValue(): void {
    this.endDate.valueChanges.subscribe(_ => {
      this.beginDate.updateValueAndValidity({ emitEvent: false });
    });
  }

  displayFn(value: Option): string {
    return value?.valueDescription ?? '';
  }

  private _filter(name: string): Option[] {
    const filterValue = name.toLowerCase();

    return this.listOptionMunicipality.filter((option) =>
      option.displayValue.toLowerCase().includes(filterValue)
    );
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  validateMuni(): void {
    if (this.endDate.errors?.inValidAsync) {
      this.endDate.setErrors(null);
    }
    if (this.municipality.value) {
      if (
        this.municipality.enabled &&
        typeof this.municipality.value === 'string'
      ) {
        this.municipality.setErrors({ inValidAsync: true });
        return;
      }
    if (this.municipality.value.value === undefined) {
        this.municipality.setErrors({ required: true });
      }
    }
    if (this.municipality.value === '') {
      this.municipality.setErrors({ required: true });
    }
  }

  private beginDateCustomValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.get('beginDate')) {
        return null;
      }

      const { value: beginDateTime } = control.parent.get('beginDate') as FormControl;
      const endDateTime = this.endDate?.value;
      const currentDate = DateTime.now().startOf('day');

      const beginDate = beginDateTime?.startOf('day');
      const endDate = endDateTime?.startOf('day');
      const dateOfDeath = this.dateOfDeath?.startOf('day');

      if (beginDate > currentDate) {
        return { beginDateErrorMessage: 'Begin Date must not be a future date.' };
      }

      if (endDate && beginDate > endDate) {
        return { beginDateErrorMessage: 'Begin Date must be earlier or equal to End Date.' };
      }

      if (dateOfDeath && beginDate && beginDate > dateOfDeath) {
        return { beginDateErrorMessage: 'Begin Date cannot be later than Date of Death.' };
      }

      return null;
    };
  }

  private endDateCustomValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.get('endDate')) {
        return null;
      }

      const { value: endDateTime } = control.parent.get('endDate') as FormControl;
      const beginDateTime = this.beginDate?.value;
      const currentDate = DateTime.now().startOf('day');

      const beginDate = beginDateTime?.startOf('day');
      const endDate = endDateTime?.startOf('day');
      const dateOfDeath = this.dateOfDeath?.startOf('day');

      if (endDate > currentDate) {
        return { endDateErrorMessage: 'End Date must not be a future date' };
      }

      if (beginDate && endDate && beginDate > endDate) {
        return { endDateErrorMessage: 'Begin Date must be earlier or equal to End Date.' };
      }

      if (dateOfDeath && endDate && endDate > dateOfDeath) {
        return { endDateErrorMessage: 'End Date cannot be later than Date of Death.' };
      }

      if (dateOfDeath && !endDate) {
        return { endDateErrorMessage: 'End Date cannot be blank and must be prior or equal to Date of Death' };
      }

      return null;
    };
  }

  validateServiceHistoryRecordBeginDate(formData: any) {
    const request: ValidateServiceHistoryRecordBeginDateRequest = {
      memberId: this.data.memberId,
      oldBeginDate: this.oldBeginDate,
      newBeginDate: getDateFormatISO(new Date(formData.beginDate).toString()),
      municipalityId: this.data.serviceHistory?.municipalityId as string,
    };
    this.memberStore.dispatch(
      MunicipalityServiceHistoryAction.validateServiceHistoryRecordBeginDateAction(
        { request }
      )
    );
  }

  registerValidateServiceHistoryRecordBeginDateSelector() {
    this.memberStore
      .pipe(select(fromMember.validateServiceHistoryRecordBeginDateSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isLoading = false;
          this.isFormLoading = false;
          this.memberStore.dispatch(MunicipalityServiceHistoryAction.clearValidateServiceHistoryRecordBeginDateStateAction());
          if (data?.success) {
            this.validateServiceHistoryRecordBeginDateResponse = data?.payload as ValidateServiceHistoryRecordBeginDateResponse;
          
            if (this.validateServiceHistoryRecordBeginDateResponse.isPortalValidServiceBeginDate === false) {
              this.triggerWarningPopup('Member Service Begin Date cannot be prior to your last submitted Annual Certification. Please contact BVFF if you need to change the date.');
              return;
            }

            if (this.validateServiceHistoryRecordBeginDateResponse.isPortalValidServiceBeginDate === true 
              && this.validateServiceHistoryRecordBeginDateResponse.totalCreditAmount as number > 0) {
              let gapYears = this.validateServiceHistoryRecordBeginDateResponse.gapYears as string;
              gapYears = gapYears.replace(/,/g, ', ');
              this.triggerWarningPopup(`${gapYears} has been certified and cannot be out of service. Please contact BVFF if you need to change the date.`);
              return;
            }
            
            this.saveData(this.editForm.value);
          } 
          if (!data?.success) {
            this.triggerErrorPopup();
          }
        }
      });
  }

  createValidateServiceHistoryRecordBeginDateTransaction(cashJournalEntry: string) {
    setTimeout(() => this.isFormLoading = true, 0);
    const request: CreateValidateServiceHistoryRecordBeginDateTransactionRequest = {
      createDate: DateTime.utc().toFormat('yyyy-MM-dd_HH:mm:ss').replace('_', 'T'),
      gapYears: this.validateServiceHistoryRecordBeginDateResponse?.gapYears as string,
      totalCreditAmount: this.validateServiceHistoryRecordBeginDateResponse?.totalCreditAmount as number,
      municipalityId: this.municipality.value.value,
      memberId: this.data.memberId,
      cashJournalEntry: cashJournalEntry,
    };
    this.memberStore.dispatch(MunicipalityServiceHistoryAction.createValidateServiceHistoryRecordBeginDateTransactionAction({ request }));
  }

  registerCreateValidateServiceHistoryRecordBeginDateTransactionSelector() {
    this.memberStore
      .pipe(select(fromMember.createValidateServiceHistoryRecordBeginDateTransactionSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isLoading = false;
          this.isFormLoading = false;
          if (data?.success) {
            this.saveData(this.editForm.value);
          } 
          if (!data?.success) {
            this.triggerErrorPopup();
          }
          this.clearValidateServiceHistoryRecordBeginDateData();
          this.memberStore.dispatch(MunicipalityServiceHistoryAction.clearCreateValidateServiceHistoryRecordBeginDateTransactionStateAction());
        }
      });
  }

  getMemberInfoState() {
    if (this.data.memberId) {
      this.memberStore.dispatch(
        ProfileHeaderConfigurationActions.getMemberDetailAction({
          memberId: this.data.memberId
        })
      );
    }
  }

  registerGetMemberParticipantNameSelector() {
    this.store.pipe(
      select(fromMember.selectMemberDetail),
      takeUntil(this.unsubscribe$)
    ).subscribe(memberInfo => {
      this.memberName = memberInfo
        ? getConcatString([memberInfo?.payload?.firstName, memberInfo?.payload?.middleName, memberInfo?.payload?.lastName], ' ')
        : '';
    });
  }

  triggerErrorPopup() {
    const msg = 'An unexpected error occurred. Please try again later.';
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: msg,
          type: ConfirmType.Warning,
          title: 'Error',
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        }
      });
  }

  triggerWarningPopup(msg: string) {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: msg,
          type: ConfirmType.Warning,
          title: 'Warning',
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        }
      });
  }

  clearValidateServiceHistoryRecordBeginDateData() {
    this.muniName = '';
    this.validateServiceHistoryRecordBeginDateResponse = null;
  }
}
