import { createAction, props } from '@ngrx/store';

import {
  ColumnSetConfig,
  ColumnSetConfigRequest,
  ColumnSetPropertyDisplayConfig,
  Employer,
  FilterByPropertyConfig,
  FilterInformation,
  MemberFilter,
  MemberFilterConfig,
  MemberFilterName,
  MemberList,
  MemberListQuery,
  PropertyDisplayResponse,
  QueryProperty,
} from '../../types/models';

export const MemberListRequest = '[MemberList/API] Send Request';
export const MemberListFailure = '[MemberList/API] Failure';
export const MemberListSuccess = '[MemberList/API] Success';
export const RemoveMemberRequest = '[RemoveMemberList/API] Send Request';
export const RemoveMemberSuccess = '[RemoveMemberList/API] Success';
export const RemoveMemberFailure = '[RemoveMemberList/API] Failure';
export const RemoveMemberClear = '[RemoveMemberList] Clear';
export const LockMemberRequest = '[LockMemberList/API] Send Request';
export const LockMemberSuccess = '[LockMemberList/API] Success';
export const LockMemberFailure = '[LockMemberList/API] Failure';
export const UnlockMemberRequest = '[UnlockMemberList/API] Send Request';
export const UnlockMemberSuccess = '[UnlockMemberList/API] Success';
export const UnlockMemberFailure = '[UnlockMemberList/API] Failure';
export const GetFilterByPropertyListRequest =
  '[GetFilterByPropertyList/API] Send Request';
export const GetFilterByPropertyListSuccess =
  '[GetFilterByPropertyList/API] Success';
export const GetFilterByPropertyListFailure =
  '[GetFilterByPropertyList/API] Failure';
export const SetMemberFilters = '[SetMemberFilters] Set';
export const CreateMemberFilterRequest =
  '[CreateMemberFilter/API] Send Request';
export const CreateMemberFilterSuccess = '[CreateMemberFilter/API] Success';
export const CreateMemberFilterFailure = '[CreateMemberFilter/API] Failure';
export const GetMemberFilterRequest = '[GetMemberFilter/API] Send Request';
export const GetMemberFilterSuccess = '[GetMemberFilter/API] Success';
export const GetMemberFilterFailure = '[GetMemberFilter/API] Failure';
export const UpdateFilterNameRequest = '[UpdateFilterName/API] Send Request';
export const UpdateFilterNameSuccess = '[UpdateFilterName/API] Success';
export const UpdateFilterNameFailure = '[UpdateFilterName/API] Failure';
export const RemoveMemberFilterRequest =
  '[RemoveMemberFilter/API] Send Request';
export const RemoveMemberFilterSuccess = '[RemoveMemberFilter/API] Success';
export const RemoveMemberFilterFailure = '[RemoveMemberFilter/API] Failure';

export const ExportMembersRequest = '[ExportMembersRequest/API] Send Request';
export const ExportMembersRequestSuccess = '[ExportMembersRequest/API] Success';
export const ExportMembersRequestFailure = '[ExportMembersRequest/API] Failure';

export const GetEmployerDetailRequest = '[GetEmployerDetail/API] Send Request';
export const GetEmployerDetailSuccess = '[GetEmployerDetail/API] Success';
export const GetEmployerDetailFailure = '[GetEmployerDetail/API] Failure';

export const DownloadMembersRequest =
  '[DownloadMembersRequest/API] Send Request';
export const DownloadMembersRequestSuccess =
  '[DownloadMembersRequest/API] Success';
export const DownloadMembersRequestFailure =
  '[DownloadMembersRequest/API] Failure';

export const GetListColumnSetConfigurationRequest = '[GetListColumnSetConfiguration/API] Send Request';
export const GetListColumnSetConfigurationSuccess = '[GetListColumnSetConfiguration/API] Success';
export const GetListColumnSetConfigurationFailure = '[GetListColumnSetConfiguration/API] Failure';

export const CreateColumnSetConfigurationRequest = '[CreateColumnSetConfiguration/API] Send Request';
export const CreateColumnSetConfigurationSuccess = '[CreateColumnSetConfiguration/API] Success';
export const CreateColumnSetConfigurationFailure = '[CreateColumnSetConfiguration/API] Failure';

export const ReorderColumnSetRequest = '[ReorderColumnSet/API] Send Request';
export const ReorderColumnSetSuccess = '[ReorderColumnSet/API] Success';
export const ReorderColumnSetFailure = '[ReorderColumnSet/API] Failure';

export const RemoveColumnSetConfigurationRequest =
  '[RemoveColumnSetConfiguration/API] Send Request';
export const RemoveColumnSetConfigurationSuccess = '[RemoveColumnSetConfiguration/API] Success';
export const RemoveColumnSetConfigurationFailure = '[RemoveColumnSetConfiguration/API] Failure';

export const EditColumnSetConfigurationRequest = '[EditColumnSetConfiguration/API] Send Request';
export const EditColumnSetConfigurationSuccess = '[EditColumnSetConfiguration/API] Success';
export const EditColumnSetConfigurationFailure = '[EditColumnSetConfiguration/API] Failure';

export const GetMemberListRequest = '[GetMemberList/API] Send Request';
export const GetMemberListFailure = '[GetMemberList/API] Failure';
export const GetMemberListSuccess = '[GetMemberList/API] Success';


export const GetListColumnSetConfigurationEntityRequest = '[GetListColumnSetConfigurationEntity/API] Send Request';
export const GetListColumnSetConfigurationEntitySuccess = '[GetListColumnSetConfigurationEntity/API] Success';
export const GetListColumnSetConfigurationEntityFailure = '[GetListColumnSetConfigurationEntity/API] Failure';

export const GetPropertyOfParticipantsRequest = '[GetPropertyOfParticipants/API] Send Request';
export const GetPropertyOfParticipantsSuccess = '[GetPropertyOfParticipants/API] Success';
export const GetPropertyOfParticipantsFailure = '[GetPropertyOfParticipants/API] Failure';


export const getMemberList = createAction(
  MemberListRequest,
  props<{ query: MemberListQuery; idClient: string }>()
);
export const getMemberListSuccess = createAction(
  MemberListSuccess,
  props<{ memberList: MemberList }>()
);
export const getMemberListFailure = createAction(
  MemberListFailure,
  props<{ error?: any }>()
);

export const lockMember = createAction(
  LockMemberRequest,
  props<{ id: string }>()
);
export const lockMemberSuccess = createAction(
  LockMemberSuccess,
  props<{ id: string }>()
);
export const lockMemberFailure = createAction(
  LockMemberFailure,
  props<{ id: string; errorMsg: string }>()
);

export const unlockMember = createAction(
  UnlockMemberRequest,
  props<{ id: string }>()
);
export const unlockMemberSuccess = createAction(
  UnlockMemberSuccess,
  props<{ id: string }>()
);
export const unlockMemberFailure = createAction(
  UnlockMemberFailure,
  props<{ id: string; errorMsg: string }>()
);

export const removeMember = createAction(
  RemoveMemberRequest,
  props<{ id: string; memberKey: string }>()
);
export const removeMemberSuccess = createAction(
  RemoveMemberSuccess,
  props<{ memberKey: string }>()
);
export const removeMemberFailure = createAction(
  RemoveMemberFailure,
  props<{ id: string; errorMsg: string }>()
);
export const removeMemberClear = createAction(RemoveMemberClear);
export const getFilterByPropertyListRequest = createAction(
  GetFilterByPropertyListRequest
);
export const getFilterByPropertyListSuccess = createAction(
  GetFilterByPropertyListSuccess,
  props<{ listSectionProperty: FilterByPropertyConfig[] }>()
);
export const getFilterByPropertyListFailure = createAction(
  GetFilterByPropertyListFailure,
  props<{ errorMsg: string }>()
);
export const setMemberFilter = createAction(
  SetMemberFilters,
  props<{ filters: MemberFilter[] }>()
);
export const createMemberFilterRequest = createAction(
  CreateMemberFilterRequest,
  props<{ body: MemberFilterConfig }>()
);
export const createMemberFilterSuccess = createAction(
  CreateMemberFilterSuccess
);
export const createMemberFilterFailure = createAction(
  CreateMemberFilterFailure
);
export const getMemberFilterRequest = createAction(GetMemberFilterRequest);
export const getMemberFilterSuccess = createAction(
  GetMemberFilterSuccess,
  props<{ listFilterInfo: FilterInformation[] }>()
);
export const getMemberFilterFailure = createAction(GetMemberFilterFailure);
export const updateFilterNameRequest = createAction(
  UpdateFilterNameRequest,
  props<{ memberFilterName: MemberFilterName }>()
);
export const updateFilterNameSuccess = createAction(
  UpdateFilterNameSuccess,
  props<{ id: string; listFilterInfo: FilterInformation[] }>()
);
export const updateFilterNameFailure = createAction(
  UpdateFilterNameFailure,
  props<{ id: string; listFilterInfo: FilterInformation[] }>()
);
export const removeMemberFilterRequest = createAction(
  RemoveMemberFilterRequest,
  props<{ id: string }>()
);
export const removeMemberFilterSuccess = createAction(
  RemoveMemberFilterSuccess,
  props<{ id: string }>()
);
export const removeMemberFilterFailure = createAction(
  RemoveMemberFilterFailure
);

export const exportMembersRequest = createAction(
  ExportMembersRequest,
  props<{ request: any }>()
);
export const exportMembersRequestSuccess = createAction(
  ExportMembersRequestSuccess,
  props<{ data: any }>()
);
export const exportMembersRequestFailure = createAction(
  ExportMembersRequestFailure
);

export const downloadMembersRequest = createAction(
  DownloadMembersRequest,
  props<{ fileName: string }>()
);
export const downloadMembersRequestSuccess = createAction(
  DownloadMembersRequestSuccess
);
export const downloadMembersRequestFailure = createAction(
  DownloadMembersRequestFailure
);

export const getEmployerDetailRequest = createAction(
  GetEmployerDetailRequest,
  props<{ employerId: string, clientId: string }>()
);
export const getEmployerDetailSuccess = createAction(
  GetEmployerDetailSuccess,
  props<{ employer: Employer }>()
);
export const getEmployerDetailFailure = createAction(
  GetEmployerDetailFailure
);


export const getListColumnSetConfigurationRequest = createAction(
  GetListColumnSetConfigurationRequest,
  props<{ IsDragDrop: boolean }>()
);
export const getListColumnSetConfigurationSuccess = createAction(
  GetListColumnSetConfigurationSuccess,
  props<{ listColumnConfig: ColumnSetConfig }>()
);
export const getListColumnSetConfigurationFailure = createAction(
  GetListColumnSetConfigurationFailure
);

export const createColumnSetConfigurationRequest = createAction(
  CreateColumnSetConfigurationRequest,
  props<{ body: ColumnSetConfigRequest, entityId: string }>(),
);

export const createColumnSetConfigurationSuccess = createAction(
  CreateColumnSetConfigurationSuccess,
  props<{ id: string }>(),
);

export const createColumnSetConfigurationFailure = createAction(
  CreateColumnSetConfigurationFailure,
  props<{ error?: any }>()
);


export const reorderColumnSet = createAction(
  ReorderColumnSetRequest,
  props<{
    body: any;
  }>()
);
export const reorderColumnSetSuccess = createAction(ReorderColumnSetSuccess);
export const reorderColumnSetFailure = createAction(
  ReorderColumnSetFailure,
  props<{ error?: any }>()
);

export const removeColumnSetConfigurationRequest = createAction(
  RemoveColumnSetConfigurationRequest,
  props<{ id: string }>()
);
export const removeColumnSetConfigurationSuccess = createAction(
  RemoveColumnSetConfigurationSuccess,
  props<{ id: string }>()
);
export const removeColumnSetConfigurationFailure = createAction(
  RemoveColumnSetConfigurationFailure
);

export const editColumnSetConfigurationRequest = createAction(
  EditColumnSetConfigurationRequest,
  props<{ id: string, body: ColumnSetConfigRequest }>(),
);

export const editColumnSetConfigurationSuccess = createAction(
  EditColumnSetConfigurationSuccess,
  props<{ id: string }>(),
);

export const editColumnSetConfigurationFailure = createAction(
  EditColumnSetConfigurationFailure,
  props<{ error?: any }>()
);

export const getMemberListConfiguration = createAction(
  GetMemberListRequest,
  props<{ query: MemberListQuery; entityId: string }>(),
);
export const getMemberListConfigurationSuccess = createAction(
  GetMemberListSuccess,
  props<{ memberList: MemberList }>()
);
export const getMemberListConfigurationFailure = createAction(
  GetMemberListFailure,
  props<{ error?: any }>()
);


export const getListColumnSetConfigurationEntityRequest = createAction(
  GetListColumnSetConfigurationEntityRequest,
  props<{ IsDragDrop: boolean, entityId: string }>()
);
export const getListColumnSetConfigurationEntitySuccess = createAction(
  GetListColumnSetConfigurationEntitySuccess,
  props<{ listColumnConfig: ColumnSetConfig }>()
);
export const getListColumnSetConfigurationEntityFailure = createAction(
  GetListColumnSetConfigurationEntityFailure,
  props<{ error?: any }>()
);

export const getPropertyOfParticipantsRequestRequest = createAction(
  GetPropertyOfParticipantsRequest,
  props<{ entityId: string, query: QueryProperty }>()
);
export const getPropertyOfParticipantsRequestSuccess = createAction(
  GetPropertyOfParticipantsSuccess,
  props<{ propertyOfParticipants: PropertyDisplayResponse }>()
);
export const getPropertyOfParticipantsRequestFailure = createAction(
  GetPropertyOfParticipantsFailure,
  props<{ error?: any }>()
);


export const getPermissionForMuniPortalUserAction = createAction(
  '[GetPermissionForMuniPortalUserInMember/API] Send Request',
  props<{ employerId: string }>()
);
export const getPermissionForMuniPortalUserSuccessAction = createAction(
  '[GetPermissionForMuniPortalUserInMember/API] Success',
  props<{ response: any }>()
);
export const getPermissionForMuniPortalUserFailureAction = createAction(
  '[GetPermissionForMuniPortalUserInMember/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetPermissionForMuniPortalUserStateAction = createAction(
  '[GetPermissionForMuniPortalUserInMember] Clear'
);
