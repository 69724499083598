import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  clearGetEmployerDocumentsStateAction,
  clearGetGeneratedDocumentStateAction,
  getEmployerDocumentsAction,
  getEmployerDocumentsFailureAction,
  getEmployerDocumentsSuccessAction,
  getGeneratedDocumentAction,
  getGeneratedDocumentFailureAction,
  getGeneratedDocumentSuccessAction,
  removeEmployerDocumentsAction,
  removeEmployerDocumentsFailureAction,
  removeEmployerDocumentsSuccessAction,
  uploadEmployerDocumentsAction,
  uploadEmployerDocumentsFailureAction,
  uploadEmployerDocumentsSuccessAction,
  clearRemoveEmployerDocumentsStateAction,
  clearUploadEmployerDocumentsStateAction,
  editEmployerDocumentsAction,
  editEmployerDocumentsSuccessAction,
  editEmployerDocumentsFailureAction,
  clearEditEmployerDocumentsStateAction,
  getEmployerDocumentOverviewAction,
  getEmployerDocumentOverviewSuccessAction,
  clearGetEmployerDocumentOverviewStateAction,
  getEmployerDocumentOverviewFailureAction,
  uploadDocumentEmployerOverviewAction,
  uploadDocumentEmployerOverviewSuccessAction,
  uploadDocumentEmployerOverviewFailureAction,
  clearUploadDocumentEmployerOverviewStateAction,
} from '../actions/employer-document.action';
import { Document, GeneratedDocument } from '@ptg-employer/models/employer-document.model';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';


export const employerDocumentFeatureKey = 'employerDocument';
export interface DocumentState {
  getDocuments?: BaseActionState<Document[]>;
  getGeneratedDocument?: BaseActionState<GeneratedDocument[]>;
  removeDocuments?: ActionState;
  uploadDocuments?: ActionState;
  editDocuments?: ActionState;
  getEmployerDocumentOverview?: BaseActionState<any[]>;
  uploadDocumentEmployerOverview?: ActionState;
}

const initialState: DocumentState = {};

export const documentListReducer = createReducer(
  initialState,
  on(getEmployerDocumentsAction, (state) => ({
    ...state,
    getDocuments: {
      isLoading: true,
    },
  })),
  on(getEmployerDocumentsSuccessAction, (state, { response }) => ({
    ...state,
    getDocuments: {
      isLoading: false,
      success: true,
      payload: response.documents,
      total: response.total,
    },
  })),
  on(getEmployerDocumentsFailureAction, (state, { error }) => ({
    ...state,
    getDocuments: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEmployerDocumentsStateAction, (state) => ({
    ...state,
    getDocuments: undefined,
  })),

  on(getGeneratedDocumentAction, (state) => ({
    ...state,
    getGeneratedDocument: {
      isLoading: true,
    },
  })),
  on(getGeneratedDocumentSuccessAction, (state, { response }) => ({
    ...state,
    getGeneratedDocument: {
      isLoading: false,
      success: true,
      payload: response.generatedDocument,
      total: response.total,
    },
  })),
  on(getGeneratedDocumentFailureAction, (state, { error }) => ({
    ...state,
    getGeneratedDocument: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetGeneratedDocumentStateAction, (state) => ({
    ...state,
    getGeneratedDocument: undefined,
  })),

  // Remove document
  on(removeEmployerDocumentsAction, (state) => ({
    ...state,
    removeDocuments: undefined
  })),
  on(removeEmployerDocumentsSuccessAction, (state) => ({
    ...state,
    removeDocuments: {
      state: STATE.SUCCESS,
      action: ACTION.REMOVE,
    },
  })),
  on(removeEmployerDocumentsFailureAction, (state, { error }) => ({
    ...state,
    removeDocuments: {
      state: STATE.FAIL,
      action: ACTION.REMOVE,
    },
  })),
  on(clearRemoveEmployerDocumentsStateAction, (state) => ({
    ...state,
    removeDocuments: undefined,
  })),

  // Upload Document
  on(uploadEmployerDocumentsAction, (state) => ({
    ...state,
    uploadDocuments: undefined
  })),
  on(uploadEmployerDocumentsSuccessAction, (state) => ({
    ...state,
    uploadDocuments: {
      state: STATE.SUCCESS,
      action: ACTION.UPLOAD,
    },
  })),
  on(uploadEmployerDocumentsFailureAction, (state, { error }) => ({
    ...state,
    uploadDocuments: {
      state: STATE.FAIL,
      action: ACTION.UPLOAD,
    },
  })),
  on(clearUploadEmployerDocumentsStateAction, (state) => ({
    ...state,
    uploadDocuments: undefined,
  })),

  //Edit document
  on(editEmployerDocumentsAction, (state) => ({
    ...state,
    editDocuments: undefined
  })),
  on(editEmployerDocumentsSuccessAction, (state) => ({
    ...state,
    editDocuments: {
      state: STATE.SUCCESS,
      action: ACTION.UPLOAD,
    },
  })),
  on(editEmployerDocumentsFailureAction, (state, { error }) => ({
    ...state,
    editDocuments: {
      state: STATE.FAIL,
      action: ACTION.UPLOAD,
    },
  })),
  on(clearEditEmployerDocumentsStateAction, (state) => ({
    ...state,
    editDocuments: undefined,
  })),

  // getEmployerDocumentOverview
  on(getEmployerDocumentOverviewAction, (state) => ({
    ...state,
    getEmployerDocumentOverview: {
      isLoading: true,
    },
  })),
  on(getEmployerDocumentOverviewSuccessAction, (state, { response }) => ({
    ...state,
    getEmployerDocumentOverview: {
      isLoading: false,
      success: true,
      payload: response.documentOverviewDetails,
      total: response.total,
    },
  })),
  on(getEmployerDocumentOverviewFailureAction, (state, { error }) => ({
    ...state,
    getEmployerDocumentOverview: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEmployerDocumentOverviewStateAction, (state) => ({
    ...state,
    getEmployerDocumentOverview: undefined,
  })),

  //Upload document employer overview
  on(uploadDocumentEmployerOverviewAction, (state) => ({
    ...state,
    uploadDocumentEmployerOverview: {
      state: 'loading',
      action: ACTION.UPLOAD
    }
  })),
  on(uploadDocumentEmployerOverviewSuccessAction, (state) => ({
    ...state,
    uploadDocumentEmployerOverview: {
      state: STATE.SUCCESS,
      action: ACTION.UPLOAD,
    },
  })),
  on(uploadDocumentEmployerOverviewFailureAction, (state, { error }) => ({
    ...state,
    uploadDocumentEmployerOverview: {
      state: STATE.FAIL,
      action: ACTION.UPLOAD,
      error
    },
  })),
  on(clearUploadDocumentEmployerOverviewStateAction, (state) => ({
    ...state,
    uploadDocumentEmployerOverview: undefined,
  })),
);
