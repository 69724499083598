<div
  class="member-list-container"
  id="member-list-page"
  [class.show-banner]="bannerType"
  *ngIf="isViewMemberListComponentScreen"
>
  <div class="header-container">
    <div class="filter-select-container" *ngIf="false">
      <mat-select [(ngModel)]="selectedFilter">
        <mat-select-trigger>
          <mat-icon>filter_alt</mat-icon>
        </mat-select-trigger>
        <mat-option *ngFor="let option of filterOptions" [value]="option.value">
          <div (click)="onFilterSelect(option.value)">
            <mat-icon>{{ option.iconName }}</mat-icon>
            <span>{{ option.label }}</span>
          </div>
        </mat-option>
      </mat-select>
    </div>
    <ptg-breadcrumb
      [listBreadcrumbs]="listBreadcrumbs"
      [functionButtons]="functionButtons"
      (emitFunction)="emitFunction($event)"
    ></ptg-breadcrumb>
  </div>
  <div class="member-management-content flex flex-col" *ngIf="isViewMemberListComponentScreen">
    <div class="flex flex-col">
      <div class="filter-container" *ngIf="currentFilters.length && false">
        <div class="current-filter-container">
          <ptg-view-current-filters [currentFilters]="currentFilters"> </ptg-view-current-filters>
        </div>
        <div class="filter-action-button-container">
          <button
            *ngIf="!isApplyMunicipalityFilter"
            mat-raised-button
            type="button"
            class="secondary"
            (click)="openFilterDialog(true)"
          >
            Edit
          </button>
          <button
            *ngIf="!isApplyMunicipalityFilter"
            mat-raised-button
            type="button"
            class="submit-button"
            (click)="saveFilter()"
          >
            Save
          </button>
          <button mat-raised-button type="button" class="cancel-button" (click)="clearFilter()">Clear filter</button>
        </div>
      </div>
      <div class="member-list-banner" *ngIf="bannerType">
        <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
      </div>
    </div>

    <div class="flex-grow overflow-hidden" *ngIf="memberData?.memberList?.columns?.length || isLoading || isError">
      <ptg-grid
        #gridMemberList
        [ngClass]="{ 'has-error': isError }"
        [data]="membersData"
        [columns]="columns"
        [isLoading]="isLoading"
        [totalRecords]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="changePagging($event)"
        (sortChange)="sortChange($event)"
        (rowClick)="onRowClick($event)"
        [errorMessage]="errorMsg"
        [fitToParent]="true"
        [hideScrollbar]="false"
        notFoundMessage="No Participants to Display"
      >
        <ng-template cellContent columnName="_isLocked" let-row>
          <mat-icon *ngIf="row.Locked" class="icon-locked">lock</mat-icon>
        </ng-template>

        <!-- <ng-template cellContent columnName="entity" let-row>
          <ptg-button
            classInput="action-button"
            (clickButton)="onClickReworkOverviewEntity(row)"
          >
            <mat-icon>settings</mat-icon>
          </ptg-button>
        </ng-template> -->
      </ptg-grid>
    </div>
    <div *ngIf="!isError && !isLoading && !memberData?.memberList?.columns?.length">
      <div class="data-not-found" [ngClass]="{ 'no-columns-display': !memberData?.memberList?.columns?.length }">
        <mat-icon *ngIf="!memberData?.memberList?.columns?.length">grid_off</mat-icon>
        <div class="message">
          {{ !memberData?.memberList?.columns?.length ? 'List not yet configured' : 'No Participants to Display' }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isViewMemberListComponentScreen || !isViewMemberListScreen" class="no-permission">
  <p>You don't have permission to view this screen.</p>
</div>

<ng-template #municipalityPortal> </ng-template>
