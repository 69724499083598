import { createAction, props } from '@ngrx/store';

import {
  EditMemberEventBody,
  MemberAttachmentsListSectionQuery,
  MemberDetail,
  MemberDetailWithPaging,
  MemberListAttachments,
  MemberStatus,
  MetadataOverView,
  MetadataSection,
  ReorderSectionRequestModel,
  SectionOverviewModel,
  PayrollConfigurationInfo,
  MetadataSectionsNonListResponse,
  LookupTableQuery,
  ClientSetting,
  CheckAttachmentResponsive,
} from '../../types/models';
import { EntityViewItems, GetCardsByViewRequest, GetCardsByViewResponse, GetListPropertyForCardsRequest, GetListPropertyForCardsResponse, GetMemberEntityAttachmentsRequest, GetMemberEntityAttachmentsResponse, GetStandAlonePropertyOnlyCardDataRequest, GetStandAlonePropertyOnlyCardDataResponse } from '@ptg-entity-management/services/models';

export const MemberDetailRequest = '[MemberDetail/API] Send Request';
export const MemberDetailFailure = '[MemberDetail/API] Failure';
export const MemberDetailSuccess = '[MemberDetail/API] Success';
export const ClearMemberDetail = '[MemberDetail] Clear';
export const RemoveMemberRequest = '[RemoveMember/API] Send Request';
export const RemoveMemberSuccess = '[RemoveMember/API] Success';
export const RemoveMemberFailure = '[RemoveMember/API] Failure';
export const ResetStateRemoveMember = '[ResetRemoveMember] Reset';
export const LockEntityMemberRequest = '[LockEntityMember/API] Send Request';
export const LockEntityMemberSuccess = '[LockEntityMember/API] Success';
export const LockEntityMemberFailure = '[LockEntityMember/API] Failure';
export const UnLockEntityMemberRequest = '[UnLockEntityMember/API] Send Request';
export const UnLockEntityMemberSuccess = '[UnLockEntityMember/API] Success';
export const UnLockEntityMemberFailure = '[UnLockEntityMember/API] Failure';
export const LockMemberRequest = '[LockMember/API] Send Request';
export const LockMemberSuccess = '[LockMember/API] Success';
export const LockMemberFailure = '[LockMember/API] Failure';
export const UnlockMemberRequest = '[UnlockMember/API] Send Request';
export const UnlockMemberSuccess = '[UnlockMember/API] Success';
export const UnlockMemberFailure = '[UnlockMember/API] Failure';
export const RemoveMemberSectionRequest =
  '[RemoveMemberSection/API] Send Request';
export const RemoveMemberSectionSuccess = '[RemoveMemberSection/API] Success';
export const RemoveMemberSectionFailure = '[RemoveMemberSection/API] Failure';
export const AddSectionTableRequest = '[AddSectionTable/API] Send Request';
export const AddSectionTableSuccess = '[AddSectionTable/API] Success';
export const AddSectionTableFailure = '[AddSectionTable/API] Failure';
export const ResetAddSectionState = '[ResetAddSectionState] Reset';
export const GetSectionDataRequest = '[GetSectionData/API] Send Request';
export const GetSectionDataSuccess = '[GetSectionData/API] Success';
export const GetSectionDataFailure = '[GetSectionData/API] Failure';
export const AddSectionDataFixedStatusSectionRequest =
  '[AddSectionDataFixedStatusSectionRequest/API] Send Request';
export const AddSectionDataListViewRequest =
  '[AddSectionDataListView/API] Send Request';
export const AddSectionDataListViewSuccess =
  '[AddSectionDataListView/API] Success';
export const AddSectionDataListViewFailure =
  '[AddSectionDataListView/API] Failure';
export const ReorderSectionRequest = '[ReorderSection/API] Send Request';
export const ReorderSectionSuccess = '[ReorderSection/API] Success';
export const ReorderSectionFailure = '[ReorderSection/API] Failure';
export const EditSectionDataFixedStatusSectionRequest =
  '[EditSectionDataFixedStatusSectionRequest/API] Send Request';
export const EditSectionDataListViewRequest =
  '[EditSectionDataListView/API] Send Request';
export const EditSectionDataListViewSuccess =
  '[EditSectionDataListView/API] Success';
export const EditSectionDataListViewFailure =
  '[EditSectionDataListView/API] Failure';
export const GetSectionOverviewRequest =
  '[GetSectionOverview/API] Send Request';
export const GetSectionOverviewSuccess = '[GetSectionOverview/API] Success';
export const GetSectionOverviewFailure = '[GetSectionOverview/API] Failure';
export const EditSectionTableRequest = '[EditSectionTable/API] Send Request';
export const EditSectionTableSuccess = '[EditSectionTable/API] Success';
export const EditSectionTableFailure = '[EditSectionTable/API] Failure';
export const RemoveSectionTableRequest =
  '[RemoveSectionTable/API] Send Request';
export const RemoveSectionTableSuccess = '[RemoveSectionTable/API] Success';
export const RemoveSectionTableFailure = '[RemoveSectionTable/API] Failure';
export const UploadFileSectionTableRequest =  '[UploadFileSectionTable/API] Send Request';
export const UploadFileSectionTableSuccess =  '[UploadFileSectionTable/API] Success';
export const UploadFileSectionTableFailure =  '[UploadFileSectionTable/API] Failure';

export const GetMetaDataRequest = '[GetMetaDataRequest/API] Send Request';
export const GetMetaDataSuccess = '[GetMetaDataSuccess/API] Success';
export const GetMetaDataFailure = '[GetMetaDataFailure/API] Failure';
export const ClearMetaDataRequest = '[ClearMetaDataRequest] Action Clear';

export const GetMemberById = '[GetMemberById/API]  Send Request';
export const GetMemberByIdSuccess = '[GetMemberById/API] Success';
export const GetMemberByIdFailure = '[GetMemberById/API] Failure';
export const RemoveSectionReset = '[RemoveSection/API] Reset';

export const SetMemberEventRequest = '[SetMemberEvent/API] Send Request';
export const SetMemberEventSuccess = '[SetMemberEvent/API] Success';
export const SetMemberEventFailure = '[SetMemberEvent/API] Failure';
export const ClearSetMemberEventState = '[SetMemberEventState] Clear';

export const ConfirmRetireRehireRequest = '[ConfirmRetireRehire/API] Send Request';
export const ConfirmRetireRehireSuccess = '[ConfirmRetireRehire/API] Success';
export const ConfirmRetireRehireFailure = '[ConfirmRetireRehire/API] Failure';

export const MemberDetailWithPagingRequest =
  '[MemberDetailWithPaging/API] Send Request';
export const MemberDetailWithPagingSuccess =
  '[MemberDetailWithPaging/API] Success';
export const MemberDetailWithPagingFailure =
  '[MemberDetailWithPaging/API] Failure';

export const CreateMemberUploadAttachments =
  '[CreateMemberUploadAttachments/API] Send Request';
export const CreateMemberUploadAttachmentsSuccess =
  '[CreateMemberUploadAttachments/API] Success';
export const CreateMemberUploadAttachmentsFailure =
  '[CreateMemberUploadAttachments/API] Failure';

export const UpdateMemberUploadAttachments =
  '[UpdateMemberUploadAttachments/API] Send Request';
export const UpdateMemberUploadAttachmentsSuccess =
  '[UpdateCreateMemberUploadAttachments/API] Success';
export const UpdateMemberUploadAttachmentsFailure =
  '[UpdateCreateMemberUploadAttachments/API] Failure';

export const RemoveMemberUploadAttachments =
  '[RemoveMemberUploadAttachments/API] Send Request';
export const RemoveMemberUploadAttachmentsSuccess =
  '[RemoveMemberUploadAttachments/API] Success';
export const RemoveMemberUploadAttachmentsFailure =
  '[RemoveMemberUploadAttachments/API] Failure';

export const ResetMemberMetadataAttachmentState =
  '[MemberMetadataAttachmentState] Reset';

export const GetMetaDataBasedOnProfileOverviewConfigRequest =
  '[GetMetaDataBasedOnProfileOverviewConfigRequest/API] Send Request';
export const GetMetaDataBasedOnProfileOverviewConfigSuccess =
  '[GetMetaDataBasedOnProfileOverviewConfigSuccess/API] Success';
export const GetMetaDataBasedOnProfileOverviewConfigFailure =
  '[GetMetaDataBasedOnProfileOverviewConfigFailure/API] Failure';

export const GetMemberDetailUnMaskedRequest =
  '[GetMemberDetailUnMasked/API] Send Request';
export const GetMemberDetailUnMaskedFailure =
  '[GetMemberDetailUnMasked/API] Failure';
export const GetMemberDetailUnMaskedSuccess =
  '[GetMemberDetailUnMasked/API] Success';

export const GetMemberConfiguredIdNameRequest =
  '[GetMemberConfiguredIdName/API] Send Request';
export const GetMemberConfiguredIdNameSuccess =
  '[GetMemberConfiguredIdName/API] Success';
export const GetMemberConfiguredIdNameFailure =
  '[GetMemberConfiguredIdName/API] Failure';

export const GetMemberAttachmentsListSectionRequest =
  '[GetMemberAttachmentsListSection/API] Send Request';
export const GetMemberAttachmentsListSectionSuccess =
  '[GetMemberAttachmentsListSection/API] Success';
export const GetMemberAttachmentsListSectionFailure =
  '[GetMemberAttachmentsListSection] Failure';

export const GetMetadataNonListRequestAction =
  '[GetMetadataNonList/API] Send Request';
export const GetMetadataNonListFailureAction =
  '[GetMetadataNonList/API] Failure';
export const GetMetadataNonListSuccessAction =
  '[GetMetadataNonList/API] Success';
export const ClearGetMetadataNonListStateAction = '[GetMetadataNonList] Clear';

export const DeactivateMemberRequest = '[DeactivateMember/API] Send Request';
export const DeactivateMemberSuccess = '[DeactivateMember/API] Success';
export const DeactivateMemberFailure = '[DeactivateMember/API] Failure';
export const ClearDeactivateMember = '[ClearDeactivateMember/API] Failure';

export const getMemberAttachmentsListSection = createAction(
  GetMemberAttachmentsListSectionRequest,
  props<{
    memberId: string;
    itemKey: string;
    query: MemberAttachmentsListSectionQuery;
  }>()
);
export const getMemberAttachmentsListSectionSuccess = createAction(
  GetMemberAttachmentsListSectionSuccess,
  props<{ memberAttachmentsListSection: MemberListAttachments }>()
);
export const getMemberAttachmentsListSectionFailure = createAction(
  GetMemberAttachmentsListSectionFailure,
  props<{ errorMsg: string }>()
);

export const getMemberDetail = createAction(
  MemberDetailRequest,
  props<{ id: string; listSection: string[]; menuId?: string; pageSize?: number }>()
);
export const getMemberDetailSuccess = createAction(
  MemberDetailSuccess,
  props<{ memberDetail: MemberDetail }>()
);
export const getMemberDetailFailure = createAction(
  MemberDetailFailure,
  props<{ errorMsg: string }>()
);

export const clearMemberDetail = createAction(ClearMemberDetail);

export const removeMember = createAction(
  RemoveMemberRequest,
  props<{ id: string }>()
);
export const resetStateRemoveMember = createAction(ResetStateRemoveMember);
export const resetMemberMetadataAttachmentState = createAction(
  ResetMemberMetadataAttachmentState
);
export const removeMemberFailure = createAction(
  RemoveMemberFailure,
  props<{ errorMsg: string }>()
);
export const removeMemberSuccess = createAction(RemoveMemberSuccess);

export const lockEntityMember = createAction(
  LockEntityMemberRequest,
  props<{ id: string }>()
);
export const lockEntityMemberSuccess = createAction(LockEntityMemberSuccess);
export const lockEntityMemberFailure = createAction(
  LockEntityMemberFailure,
  props<{ errorMsg: string }>()
);

export const unLockEntityMember = createAction(
  UnLockEntityMemberRequest,
  props<{ id: string }>()
);
export const unLockEntityMemberSuccess = createAction(UnLockEntityMemberSuccess);
export const unLockEntityMemberFailure = createAction(
  UnLockEntityMemberFailure,
  props<{ errorMsg: string }>()
);

export const lockMember = createAction(
  LockMemberRequest,
  props<{ id: string }>()
);
export const lockMemberSuccess = createAction(LockMemberSuccess);
export const lockMemberFailure = createAction(
  LockMemberFailure,
  props<{ errorMsg: string }>()
);

export const unlockMember = createAction(
  UnlockMemberRequest,
  props<{ id: string }>()
);
export const unlockMemberSuccess = createAction(UnlockMemberSuccess);
export const unlockMemberFailure = createAction(
  UnlockMemberFailure,
  props<{ errorMsg: string }>()
);
export const removeMemberSection = createAction(
  RemoveMemberSectionRequest,
  props<{ id: string; sectionKey: string }>()
);
export const removeMemberSectionSuccess = createAction(
  RemoveMemberSectionSuccess,
  props<{ sectionKey: string }>()
);
export const removeMemberSectionFailure = createAction(
  RemoveMemberSectionFailure,
  props<{ errorMsg: string; sectionKey: string }>()
);
export const addSectionTable = createAction(
  AddSectionTableRequest,
  props<{
    memberId: string;
    metadataItem: string;
    body: any;
    sectionData: MetadataSection;
  }>()
);
export const addSectionTableSuccess = createAction(
  AddSectionTableSuccess,
  props<{ sectionData: MetadataSection; memberId: string }>()
);
export const addSectionTableFailure = createAction(
  AddSectionTableFailure,
  props<{ error?: any }>()
);
export const resetAddSectionState = createAction(ResetAddSectionState);
export const getSectionData = createAction(
  GetSectionDataRequest,
  props<{
    memberId: string;
    sectionKey: string;
    query?: MemberDetailWithPaging;
  }>()
);
export const getSectionDataSuccess = createAction(
  GetSectionDataSuccess,
  props<{ memberId: string; sectionKey: string; metaDataPropertyValues: any }>()
);
export const getSectionDataFailure = createAction(
  GetSectionDataFailure,
  props<{ error?: any; sectionKey: string }>()
);
export const addSectionDataFixedStatusSection = createAction(
  AddSectionDataFixedStatusSectionRequest,
  props<{ body: any; sectionData: MetadataSection }>()
);
export const addSectionDataListView = createAction(
  AddSectionDataListViewRequest,
  props<{ body: any; sectionData: MetadataSection }>()
);
export const addSectionDataListViewSuccess = createAction(
  AddSectionDataListViewSuccess,
  props<{ sectionData: MetadataSection }>()
);
export const addSectionDataListViewFailure = createAction(
  AddSectionDataListViewFailure,
  props<{ sectionData: MetadataSection }>()
);
export const reorderSectionTable = createAction(
  ReorderSectionRequest,
  props<{
    memberId: string;
    metadataItem: string;
    body: ReorderSectionRequestModel;
  }>()
);
export const reorderSectionTableSuccess = createAction(ReorderSectionSuccess);
export const reorderSectionTableFailure = createAction(
  ReorderSectionFailure,
  props<{ error?: any }>()
);
export const editSectionDataFixedStatusSection = createAction(
  EditSectionDataFixedStatusSectionRequest,
  props<{ body: any; sectionData: MetadataSection }>()
);
export const editSectionDataListView = createAction(
  EditSectionDataListViewRequest,
  props<{ body: any; sectionData: MetadataSection }>()
);
export const editSectionDataListViewSuccess = createAction(
  EditSectionDataListViewSuccess,
  props<{ sectionData: MetadataSection }>()
);
export const editSectionDataListViewFailure = createAction(
  EditSectionDataListViewFailure,
  props<{ error?: any; sectionData: MetadataSection }>()
);

export const getSectionOverview = createAction(
  GetSectionOverviewRequest,
  props<{ memberId: string; sectionKey: string; isMetadataSection: boolean }>()
);
export const getSectionOverviewSuccess = createAction(
  GetSectionOverviewSuccess,
  props<{ sectionOverview: SectionOverviewModel }>()
);
export const getSectionOverviewFailure = createAction(
  GetSectionOverviewFailure,
  props<{ errorMsg: string }>()
);
export const editSectionTable = createAction(
  EditSectionTableRequest,
  props<{
    memberId: string;
    metadataItem: string;
    body: any;
    sectionData: MetadataSection;
  }>()
);
export const editSectionTableSuccess = createAction(
  EditSectionTableSuccess,
  props<{ sectionData: MetadataSection; memberId: string }>()
);
export const editSectionTableFailure = createAction(
  EditSectionTableFailure,
  props<{ error?: any }>()
);
export const removeSectionTable = createAction(
  RemoveSectionTableRequest,
  props<{ sectionData: MetadataSection; body: any }>()
);
export const removeSectionTableSuccess = createAction(
  RemoveSectionTableSuccess,
  props<{ sectionData: MetadataSection }>()
);
export const removeSectionTableFailure = createAction(
  RemoveSectionTableFailure,
  props<{ error?: any; sectionData: MetadataSection }>()
);
export const uploadFileSectionTable = createAction(
  UploadFileSectionTableRequest,
  props<{ memberId: string; body: any }>()
);
export const uploadFileSectionTableSuccess = createAction(
  UploadFileSectionTableSuccess
);
export const uploadFileSectionTableFailure = createAction(
  UploadFileSectionTableFailure,
  props<{ error?: any }>()
);

export const getMetadata = createAction(
  GetMetaDataRequest,
  props<{ query?: LookupTableQuery }>()
);
export const getMetaDataSuccess = createAction(
  GetMetaDataSuccess,
  props<{ metadata: MetadataSection[]; listStatus: MemberStatus[] }>()
);
export const getMetaDataFailure = createAction(GetMetaDataFailure);
export const clearMetadata = createAction(ClearMetaDataRequest);

export const getMetadataNonListAction = createAction(
  GetMetadataNonListRequestAction,
  props<{ memberNavigationItemId?: string }>()
);
export const getMetadataNonListSuccessAction = createAction(
  GetMetadataNonListSuccessAction,
  props<{ response: MetadataSectionsNonListResponse }>()
);
export const getMetadataNonListFailureAction = createAction(
  GetMetadataNonListFailureAction,
  props<{ error?: any }>()
);

export const getMemberById = createAction(
  GetMemberById,
  props<{ id: string, screenId?: string }>()
);

export const getMemberByIdSuccess = createAction(
  GetMemberByIdSuccess,
  props<{ memberInfo: any }>()
);

export const getMemberByIdFailure = createAction(GetMemberByIdFailure);
export const removeSectionReset = createAction(RemoveSectionReset);

export const setMemberEvent = createAction(
  SetMemberEventRequest,
  props<{ body: EditMemberEventBody }>()
);
export const setMemberEventSuccess = createAction(SetMemberEventSuccess);
export const setMemberEventFailure = createAction(SetMemberEventFailure);
export const clearSetMemberEventState = createAction(ClearSetMemberEventState);

export const getMemberDetailWithPaging = createAction(
  MemberDetailWithPagingRequest,
  props<{
    query: MemberDetailWithPaging;
    id: string;
    itemKey: string;
    menuId?: string;
  }>()
);
export const getMemberDetailWithPagingSuccess = createAction(
  MemberDetailWithPagingSuccess,
  props<{ memberDetailList: any }>()
);
export const getMemberDetailWithPagingFailure = createAction(
  MemberDetailWithPagingFailure,
  props<{ error?: any }>()
);
export const createMemberUploadAttachments = createAction(
  CreateMemberUploadAttachments,
  props<{ body: any }>()
);
export const createMemberUploadAttachmentsSuccess = createAction(
  CreateMemberUploadAttachmentsSuccess
);
export const createMemberUploadAttachmentsFailure = createAction(
  CreateMemberUploadAttachmentsFailure,
  props<{ errorMsg: string }>()
);
export const updateMemberUploadAttachments = createAction(
  UpdateMemberUploadAttachments,
  props<{ body: any }>()
);
export const updateMemberUploadAttachmentsSuccess = createAction(
  UpdateMemberUploadAttachmentsSuccess
);
export const updateMemberUploadAttachmentsFailure = createAction(
  UpdateMemberUploadAttachmentsFailure,
  props<{ errorMsg: string }>()
);
export const removeMemberUploadAttachments = createAction(
  RemoveMemberUploadAttachments,
  props<{ body: any }>()
);
export const removeMemberUploadAttachmentsSuccess = createAction(
  RemoveMemberUploadAttachmentsSuccess,
  props<{ body: any }>()
);
export const removeMemberUploadAttachmentsFailure = createAction(
  RemoveMemberUploadAttachmentsFailure,
  props<{ errorMsg: string; documentName: string }>()
);

export const getMetadataBasedOnProfileOverviewConfig = createAction(
  GetMetaDataBasedOnProfileOverviewConfigRequest,
  props<{ memberId: string, screenId?: string }>()
);

export const getMetadataBasedOnProfileOverviewConfigSuccess = createAction(
  GetMetaDataBasedOnProfileOverviewConfigSuccess,
  props<{
    metadataOverview: MetadataSection[];
    listStatus: MemberStatus[];
    profileOverviewName: string;
    profileOverviewConfig: MetadataOverView;
  }>()
);
export const getMetadataBasedOnProfileOverviewConfigFailure = createAction(
  GetMetaDataBasedOnProfileOverviewConfigFailure
);

export const getViewConfigsAction = createAction(
  '[GetViewConfigs/API] Send Request',
  props<{ viewId: string }>()
);

export const getViewConfigsActionSuccess = createAction(   
  '[GetViewConfigs/API] Success',
  props<EntityViewItems>()
);
export const getViewConfigsActionFailure = createAction(
  '[GetViewConfigs/API] Failure',
);

export const getMemberDataSummaryAction = createAction(
  '[GetMemberDataSummary/API] Send Request',
  props<{ memberId: string; entityId: string; listCard: string[]; isSummary: boolean, pageSize?: number , screenId?: string}>()
);
export const getMemberDataSummarySuccess = createAction(
  '[GetMemberDataSummary/API] Success',
  props<{ memberDataSummary: any }>()
);
export const getMemberDataSummaryFailure = createAction(
  '[GetMemberDataSummary/API] Failure',
  props<{ errorMsg: string }>()
);

export const getMemberDataCardAction = createAction(
  '[GetMemberDataCard/API] Send Request',
  props<{ memberId: string; cardId: string; entityId: string; isSummary: boolean, query?: any, screenId?: string }>()
);
export const getMemberDataCardSuccess = createAction(
  '[GetMemberDataCard/API] Success',
  props<{ memberDataCard: any; cardId: string; currentPageIndex: number }>()
);
export const getMemberDataCardFailure = createAction(
  '[GetMemberDataCard/API] Failure',
  props<{ cardId: string }>()
);

export const getMemberDetailUnMaskedRequest = createAction(
  GetMemberDetailUnMaskedRequest,
  props<{
    memberId: string;
    listSection: string[];
    isAllData: boolean;
    menuId?: string;
  }>()
);
export const getMemberDetailUnMaskedSuccess = createAction(
  GetMemberDetailUnMaskedSuccess,
  props<{ memberDetailUnMasked: MemberDetail }>()
);
export const getMemberDetailUnMaskedFailure = createAction(
  GetMemberDetailUnMaskedFailure
);

export const getMemberConfiguredIdNameRequest = createAction(
  GetMemberConfiguredIdNameRequest
);
export const getMemberConfiguredIdNameSuccess = createAction(
  GetMemberConfiguredIdNameSuccess,
  props<{ payrollConfigurationInfo: PayrollConfigurationInfo }>()
);
export const getMemberConfiguredIdNameFailure = createAction(
  GetMemberConfiguredIdNameFailure
);

export const confirmRetireRehireRequest = createAction(
  ConfirmRetireRehireRequest,
  props<{ participantId: string, isRetireRehire: boolean }>()
);
export const confirmRetireRehireSuccess = createAction(
  ConfirmRetireRehireSuccess,
  props<{ isRetireRehire: boolean, isShowRetireRehireConfirmPopup: boolean }>()
);
export const confirmRetireRehireFailure = createAction(
  ConfirmRetireRehireFailure
);

// TODO cuongnh20: getCardsByView in member is replaced. pls use getCardsByView in entity
export const getCardsByViewAction = createAction(
  '[GetCardsByView_/API] Send Request',
  props<{ request: GetCardsByViewRequest }>()
);
export const getCardsByViewSuccessAction = createAction(
  '[GetCardsByView_/API] Success',
  props<{ response: GetCardsByViewResponse }>()
);
export const getCardsByViewFailureAction = createAction(
  '[GetCardsByView_/API] Failure',
  props<{ error?: any }>()
);
export const clearGetCardsByViewStateAction = createAction(
  '[GetCardsByView_] Clear'
);

export const getListPropertyForCardsAction = createAction(
  '[GetListPropertyForCards/API] Send Request',
  props<{ request: GetListPropertyForCardsRequest }>()
);

export const getListPropertyForCardsSuccessAction = createAction(
  '[GetListPropertyForCards/API] Success',
  props<{ response: GetListPropertyForCardsResponse }>()
);

export const getListPropertyForCardsFailureAction = createAction(
  '[GetListPropertyForCards/API] Failure',
  props<{ error?: any }>()
);

export const clearGetListPropertyForCardsStateAction = createAction(
  '[GetListPropertyForCards] Clear'
);

export const getListDataSnapshotAction = createAction(
  '[GetListDataSnapshot/API] Send Request',
  props<{ request: GetListPropertyForCardsRequest }>()
);

export const getListDataSnapshotSuccessAction = createAction(
  '[GetListDataSnapshot/API] Success',
  props<{ response: GetListPropertyForCardsResponse }>()
);

export const getListDataSnapshotFailureAction = createAction(
  '[GetListDataSnapshot/API] Failure',
  props<{ error?: any }>()
);

export const clearListDataSnapshotStateAction = createAction(
  '[GetLListDataSnapshot] Clear'
);

export const getStandAlonePropertyOnlyCardDataAction = createAction(
  '[GetStandAlonePropertyOnlyCardData/API] Send Request',
  props<{ request: GetStandAlonePropertyOnlyCardDataRequest }>()
);

export const getStandAlonePropertyOnlyCardDataSuccessAction = createAction(
  '[GetStandAlonePropertyOnlyCardData/API] Success',
  props<{ response: GetStandAlonePropertyOnlyCardDataResponse }>()
);

export const getStandAlonePropertyOnlyCardDataFailureAction = createAction(
  '[GetStandAlonePropertyOnlyCardData/API] Failure',
  props<{ error?: any }>()
);

export const clearGetStandAlonePropertyOnlyCardDataStateAction = createAction(
  '[GetStandAlonePropertyOnlyCardData] Clear'
);

export const getMemberEntityAttachmentsAction = createAction(
  '[GetMemberEntityAttachments/API] Send Request',
  props<{ request: GetMemberEntityAttachmentsRequest }>()
);

export const getMemberEntityAttachmentsSuccessAction = createAction(
  '[GetMemberEntityAttachments/API] Success',
  props<{ response: GetMemberEntityAttachmentsResponse }>()
);

export const getMemberEntityAttachmentsFailureAction = createAction(
  '[GetMemberEntityAttachments/API] Failure',
  props<{ error?: any }>()
);

export const clearGetMemberEntityAttachmentsStateAction = createAction(
  '[GetMemberEntityAttachments] Clear'
);

export const createUploadMemberEntityAttachmentsAction = createAction(
  '[CreateUploadMemberEntityAttachments/API] Send Request',
  props<{ body: any }>()
);

export const createUploadMemberEntityAttachmentsSuccessAction = createAction(
  '[CreateUploadMemberEntityAttachments/API] Success'
);

export const createUploadMemberEntityAttachmentsFailureAction = createAction(
  '[CreateUploadMemberEntityAttachments/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateUploadMemberEntityAttachmentsStateAction = createAction(
  '[CreateUploadMemberEntityAttachments] Clear'
);

export const removeMemberEntityAttachmentsAction = createAction(
  '[RemoveMemberEntityAttachments/API] Send Request',
  props<{ memberId: string; attachmentId: string }>()
);

export const removeMemberEntityAttachmentsSuccessAction = createAction(
  '[RemoveMemberEntityAttachments/API] Success'
);

export const removeMemberEntityAttachmentsFailureAction = createAction(
  '[RemoveMemberEntityAttachments/API] Failure',
  props<{ error?: any }>()
);

export const clearRemoveMemberEntityAttachmentsStateAction = createAction(
  '[RemoveMemberEntityAttachments] Clear'
);

export const getClientSettingAction = createAction(
  '[GetClientSetting/API] Send Request',
  props<{ clientSettingKey: string }>()
);

export const getClientSettingSuccessAction = createAction(
  '[GetClientSetting/API] Success',
  props<{ response: ClientSetting }>()
);

export const getClientSettingFailureAction = createAction(
  '[GetClientSetting/API] Failure',
  props<{ error?: any }>()
);

export const deactivateMember = createAction(DeactivateMemberRequest, props<{ id: string, actionCode: number }>());

export const deactivateMemberFailure = createAction(
  DeactivateMemberFailure,
  props<{ errorMsg: string }>()
);
export const deactivateMemberSuccess = createAction(DeactivateMemberSuccess);

export const clearDeactivateMember = createAction(
  ClearDeactivateMember
);


export const checkRemoveExitAttachmentAction = createAction(
  '[CheckRemoveExitAttachment/API] Send Request',
  props<{ body: string[] }>()
);
export const checkRemoveExitAttachmentSuccess = createAction(
  '[CheckRemoveExitAttachment/API] Send Success',
  props<{ response: CheckAttachmentResponsive}>()
);
export const checkRemoveExitAttachmentFailure = createAction(
  '[CheckRemoveExitAttachment/API] Failure',
  props<{ error?: any }>()
);

export const clearRemoveExitAttachmentFailure = createAction(
  '[CheckRemoveExitAttachment/API] Clear',
);