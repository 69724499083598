import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchMemberService {
  searchValue: string = '';
  constructor(private httpClient: HttpClient) { }

  getSearchMember(searchValue?: string, searchType?: number, idClient?: string, searchMemberType?: number, benefitTypeId?: any, screenId?: string, entityId?:string, employerId?: string) {
    this.searchValue = searchValue ?? '';
    let body: any = {
      clientId: idClient,
      searchValue: searchValue,
      searchType: searchType,
      timeZoneOffset: new Date().getTimezoneOffset(),
      searchMemberType: searchMemberType,
      benefitTypeId: benefitTypeId,
      entityId: entityId,
    };
    if (screenId) {
      body.screenId = screenId;
    }
    if (employerId) {
      body.employerId = employerId;
    }
    return this.httpClient.post<any>(`${environment.apiUrl}/member-portal/${entityId}/search`, body);
  }
}
