import { AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { RadioOption } from '../radio-button/radio-button.component';
import { FormControlDetail } from '@ptg-shared/types/models';
import { BaseComponent } from '@ptg-shared/components';
import { isEmpty } from '@ptg-shared/utils/string.util';
import { GranulationOptionsType } from '@ptg-member/features/calculation/types/enums';

@Component({
  selector: 'ptg-granulation-options',
  templateUrl: './granulation-options.component.html',
  styleUrls: ['./granulation-options.component.scss'],
})
export class GranulationOptions extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() formControlType!: number;
  // =1: is config type
  // =2: is input type
  // =3: display type
  @Input() formControlDetail?: FormControlDetail;
  @Input() controlField!: AbstractControl | any;
  @Input() isResetValue!: boolean;
  @Input() isDisabled?: boolean;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  form!: FormGroup;
  optionsGranulation: RadioOption[] = [
    {
      label: 'Year',
      value: 1,
    },
    {
      label: 'Years/Months',
      value: 2,
    },
    {
      label: 'Years/Months/Days',
      value: 3,
    },
    {
      label: 'None',
      value: 0,
    },
  ];

  isNone: boolean = true;
  isHaveYears: boolean = false;
  isHaveMonths: boolean = false;
  isHaveDays: boolean = false;
  classGranulation: string = '';
  data = {
    years: '',
    months: '',
    days: '',
  };

  constructor(public readonly fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    if (this.formControlDetail?.option) {
      this.isNone = this.formControlDetail.option === GranulationOptionsType.None.toString();
      this.isHaveYears = this.formControlDetail.option !== GranulationOptionsType.None.toString();
      this.isHaveMonths =
        this.formControlDetail.option === GranulationOptionsType.YearsMonths.toString() ||
        this.formControlDetail.option === GranulationOptionsType.YearsMonthsDays.toString();
      this.isHaveDays = this.formControlDetail.option === GranulationOptionsType.YearsMonthsDays.toString();
      this.classGranulation = `form-${this.formControlDetail.option}`;

      const value = this.controlField.value?.replace(/\//gi, '|');
      const listValue = value?.split('|');
      if (this.isHaveYears && listValue && listValue[0]) {
        this.data.years = listValue[0].replace('Y', '');
      }
      if (this.isHaveMonths && listValue && listValue[1]) {
        this.data.months = listValue[1].replace('M', '');
      }
      if (this.isHaveDays && listValue && listValue[2]) {
        this.data.days = listValue[2].replace('D', '');
      }
    }

    if (this.isResetValue) {
      this.controlField.reset();
      this.form.get('granulationOptions')?.setValue(null);
    }
    this.initFormGroup();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  initFormGroup() {
    const optionData = this.isResetValue ? null : this.controlField;

    this.form = this.fb.group({
      granulationOptions: this.fb.control(optionData),
      fieldText: this.fb.control(''),
      fieldYears: this.fb.control(this.data.years ?? null),
      fieldMonths: this.fb.control(this.data.months ?? null),
      fieldDays: this.fb.control(this.data.days ?? null),
    });
  }

  changeGranulation(event: any) {
    this.valueChange.emit(event);
  }

  changeData(event: any) {
    const formValue = this.form.value;

    if (this.form.get('fieldMonths')?.errors || this.form.get('fieldDays')?.errors) {
      this.valueChange.emit({ invalid: true });
      return;
    }
    const yearValue = `${this.handleInputData(formValue.fieldYears) ?? 0}Y`;
    const monthsValue = this.isHaveMonths ? `${this.handleInputData(formValue.fieldMonths) ?? 0}M` : null;
    const daysValue = this.isHaveDays ? `${this.handleInputData(formValue.fieldDays) ?? 0}D` : null;
    const returnValue = [yearValue, monthsValue, daysValue].filter((it) => !!it).join('|');
    this.valueChange.emit(returnValue);
  }

  handleInputData(data: string) {
    if (isEmpty(data) || data === '') {
      return null;
    }
    return data;
  }
}
