import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const retirementBenefitDialogSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.retirementBenefitDialog,
);

export const getRetirementBenefitDialogSelector = createSelector(
  retirementBenefitDialogSelector,
  (state) => state?.createRetirementBenefit,
);

export const getBenefitOptionsSelector = createSelector(
  retirementBenefitDialogSelector,
  (state) => state?.getBenefitOptions,
);

export const stepConfigurationDetailByBenefitOptionSelector = createSelector(
  retirementBenefitDialogSelector,
  (state) => state?.getStepConfigurationDetailByBenefitOption
);

export const getDetectRecalculationBenefitSelector = createSelector(
  retirementBenefitDialogSelector,
  (state) => state?.getDetectRecalculationBenefit,
);

export const getValidateBeforeRecalculationBenefitSelector = createSelector(
  retirementBenefitDialogSelector,
  (state) => state?.getValidateBeforeRecalculationBenefit,
);

