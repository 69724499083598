import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
} from '../actions';
import { MemberService } from '@ptg-member/services/member.service';
import { 
  createMemberAction, 
  createMemberFailureAction, 
  createMemberSuccessAction, 
  setMemberEventAction, 
  setMemberEventFailureAction, 
  setMemberEventSuccessAction, 
  updateMemberCardAction, 
  updateMemberCardFailureAction, 
  updateMemberCardSuccessAction 
} from '../actions/member.action';

@Injectable()
export class MemberEffect {
  constructor(
    private actions$: Actions,
    private memberService: MemberService
  ) {}

  createMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMemberAction),
      switchMap(({ request }) =>
        this.memberService.createMember(request)
        .pipe(
          map((memberId) => {
            return createMemberSuccessAction({ memberId });
          }),
          catchError((error) => {
            return of(createMemberFailureAction({ error }));
          })
        )
      )
    )
  );

  updateMemberCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMemberCardAction),
      switchMap(({ memberId, cardName, request }) =>
        this.memberService.updateMember(memberId, cardName, request)
        .pipe(
          map(() => {
            return updateMemberCardSuccessAction({ cardName: cardName});
          }),
          catchError((error) => {
            return of(updateMemberCardFailureAction({cardName: cardName, error }));
          })
        )
      )
    )
  );

  setMemberEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setMemberEventAction),
      switchMap(({ memberId, cardName, request }) =>
        this.memberService.updateMember(memberId, cardName, request)
        .pipe(
          map(() => {
            return setMemberEventSuccessAction();
          }),
          catchError((error) => {
            return of(setMemberEventFailureAction({ error }));
          })
        )
      )
    )
  );
}
